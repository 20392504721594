import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import axios from 'axios';
import { Card, CardContent, CardFooter } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "./components/ui/avatar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./components/ui/dialog";
import { Crown, BarChart2, Gem, Users, Share2, Copy, ChevronDown, ChevronUp, ChevronRight, Star, X, Smartphone, Globe, Ticket, ListOrdered, Gamepad2, HandMetal, Magnet, Lollipop, PawPrint, Pill, Spade, Zap, Rocket, Shell, Bitcoin, Cookie, Banana, Bone, Dices, Ghost, Heart, Puzzle, Skull, Swords, AudioWaveform, Drama, Brain, Radiation, Webhook, Donut, CircleCheckBig } from "lucide-react";
import * as LucideIcons from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Input } from "./components/ui/input";
import './BrandBattle.css';
import ReactCountryFlag from "react-country-flag";
import BrandAvatar from './components/BrandAvatar';
import toast from './components/CustomToast';
import AnimatedBackground from './components/AnimatedBackground';
import BrandGrowthGame from './components/BrandGrowthGame';
import UserProfile from './components/UserProfile';
import TicketDisplay from './components/TicketDisplay';
import BattleTimer from './components/BattleTimer';
import BoringAvatar from 'boring-avatars';
import UserAvatar from './components/UserAvatar';
import StoriesContainer from './components/Stories/StoriesContainer';
import OptimizedImage, { PreloadImages } from './components/ui/OptimizedImage';
import { SiApplemusic, SiBehance, SiDiscord, SiDribbble, SiFacebook, SiGithub, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiQuora, SiReddit, SiSnapchat, SiSoundcloud, SiSpotify, SiTelegram, SiTencentqq, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiWhatsapp, SiX, SiYoutube, SiSinaweibo } from 'react-icons/si';

const BrandBattle = ({ user: initialUser, setUser: setGlobalUser }) => {
  console.log('BrandBattle component started', { initialUser });
  const [userData, setUserData] = useState(initialUser || {});
  const [stats, setStats] = useState({ battlesWon: initialUser?.stats?.battlesWon || 0, brandsFaced: [] });
  const [score, setScore] = useState(initialUser?.coins || 0);
  const [battlesWon, setBattlesWon] = useState(initialUser?.stats?.battlesWon || 0);
  const [availableBattles, setAvailableBattles] = useState(0);
  const [battleLimit, setBattleLimit] = useState(initialUser?.battleLimit || 50);
  const [remainingBattles, setRemainingBattles] = useState(initialUser?.availableBattles || 0);
  const [level, setLevel] = useState(initialUser?.level || 1);
  const [brandsLoaded, setBrandsLoaded] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState({});
  const [ranks, setRanks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('Global');
  const [countries, setCountries] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [referralLink, setReferralLink] = useState(userData.referralLink || '');
  const [currentRank, setCurrentRank] = useState(initialUser?.rank || 'Kettle');
  const [backgroundImage, setBackgroundImage] = useState(initialUser?.backgroundImage || '/default-background.webp');
  const [selectedBrandIndex, setSelectedBrandIndex] = useState(null);
  const [openFaqId, setOpenFaqId] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedMainCategory, setSelectedMainCategory] = useState('');
  const [isCategoryDialogOpen, setIsCategoryDialogOpen] = useState(false);
  const [isCountryDialogOpen, setIsCountryDialogOpen] = useState(false);
  const [isReferralDialogOpen, setIsReferralDialogOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [showUpgradeAnimation, setShowUpgradeAnimation] = useState(false);
  const [levelProgress, setLevelProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [referrals, setReferrals] = useState([]);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [selectedBrandInfo, setSelectedBrandInfo] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const [videoTasks, setVideoTasks] = useState([]);
  const [socialTasks, setSocialTasks] = useState([]);
  const [specialTasks, setSpecialTasks] = useState([]);
  const [taskCodes, setTaskCodes] = useState({});
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardPeriod, setLeaderboardPeriod] = useState('week');
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [brandStats, setBrandStats] = useState({});
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showGo, setShowGo] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState('');
  const [userLocation, setUserLocation] = useState(null);
  const [showCompletedVideoTasks, setShowCompletedVideoTasks] = useState(false);
  const [showCompletedSocialTasks, setShowCompletedSocialTasks] = useState(false);
  const [showCompletedSpecialTasks, setShowCompletedSpecialTasks] = useState(false);
  const [showStories, setShowStories] = useState(false);
  const [storiesLoaded, setStoriesLoaded] = useState(false);
  const [activeStoryIndex, setActiveStoryIndex] = useState(0);
  const [stories, setStories] = useState([]);
  const [showStoriesReel, setShowStoriesReel] = useState(false);
  const [userInteractionHappened, setUserInteractionHappened] = useState(false);
  const [userInteraction, setUserInteraction] = useState(false);

  const getSocialIcon = (platform) => {
    const Icon = socialIcons[platform];
    return Icon ? <Icon className="w-6 h-6 text-white" /> : null;
  };

  const socialIcons = {
    AppleMusic: SiApplemusic,
    Behance: SiBehance,
    Discord: SiDiscord,
    Dribbble: SiDribbble,
    Facebook: SiFacebook,
    GitHub: SiGithub,
    Instagram: SiInstagram,
    KakaoTalk: SiKakaotalk,
    Line: SiLine,
    LinkedIn: SiLinkedin,
    Medium: SiMedium,
    Ok: SiOdnoklassniki,
    Onlyfans: SiOnlyfans,
    Patreon: SiPatreon,
    Pinterest: SiPinterest,
    QQ: SiTencentqq,
    Quora: SiQuora,
    Reddit: SiReddit,
    Snapchat: SiSnapchat,
    SoundCloud: SiSoundcloud,
    Spotify: SiSpotify,
    Telegram: SiTelegram,
    Threads: SiThreads,
    TikTok: SiTiktok,
    Tumblr: SiTumblr,
    Twitch: SiTwitch,
    Viber: SiViber,
    Vk: SiVk,
    WeChat: SiWechat,
    Weibo: SiSinaweibo,
    WhatsApp: SiWhatsapp,
    X: SiX,
    YouTube: SiYoutube,
  };

  useEffect(() => {
    if (initialUser?.remainingBattles !== undefined) {
      setRemainingBattles(initialUser.remainingBattles);
    }
  }, [initialUser]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const telegramInitData = window.Telegram?.WebApp?.initData || localStorage.getItem('telegramInitData');
        if (!telegramInitData) {
          throw new Error('No Telegram init data found');
        }
        console.log('Telegram init data:', telegramInitData);
        localStorage.setItem('telegramInitData', telegramInitData);
        const parsedInitData = Object.fromEntries(new URLSearchParams(telegramInitData));
        let startParam = parsedInitData.start_param || window.Telegram?.WebApp?.initDataUnsafe?.start_param || new URLSearchParams(window.location.search).get('start');

        // Проверка на случай, если startParam - это строковый литерал '${startParam}'
        if (!startParam || startParam === '${startParam}') {
          // Ищем startParam в localStorage или других источниках
          startParam = localStorage.getItem('start_param') || localStorage.getItem('referralCode') || '';
          console.log('Using fallback start parameter:', startParam);
        }

        // Сохраняем параметр для будущего использования
        if (startParam && startParam !== '${startParam}') {
          localStorage.setItem('start_param', startParam);
          localStorage.setItem('referralCode', startParam);
        }

        console.log('Start parameter:', startParam);
        const response = await axios.post('/api/users/auth', { telegramInitData, start: startParam });
        console.log('Auth response:', response.data);
        const { user, token } = response.data;
        localStorage.setItem('token', token);
        updateUserState(user);
        setAvailableBattles(user.availableBattles);
        if (user.referralLink) {
          console.log('Setting referral link:', user.referralLink);
          setReferralLink(user.referralLink);
        } else {
          console.error('User data does not contain referralLink');
        }
        // Добавляем установку ранга и фона
        setCurrentRank(user.rank);
        setBackgroundImage(user.backgroundImage);
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (initialUser && initialUser.referralLink) {
          console.log('Using initial user referral link:', initialUser.referralLink);
          setReferralLink(initialUser.referralLink);
        } else {
          console.error('No referral link available');
        }
      } finally {
        setIsUserDataLoaded(true);
      }
    };
    fetchUserData();
  }, []);

  const getOrderedBrands = useCallback((allBrands, count = 2) => {
    const filteredBrands = allBrands.filter(brand => {
      const countryMatch = selectedCountry === 'Global'
        ? brand.global
        : (!selectedCountry || (brand.countries && brand.countries.some(c => c.code === selectedCountry)));
      const categoryMatch = !selectedMainCategory || brand.category === selectedMainCategory;
      const subCategoryMatch = !selectedSubCategory || brand.subCategory === selectedSubCategory;
      return countryMatch && categoryMatch && subCategoryMatch;
    });

    const shuffledBrands = filteredBrands.sort(() => 0.5 - Math.random());
    return shuffledBrands.slice(0, count);
  }, [selectedCountry, selectedMainCategory, selectedSubCategory]);

  // Функция для получения брендов с учетом выбранной страны и категории
  const fetchBrands = useCallback(async () => {
    try {
      console.log('Fetching brands...');

      if (brandsLoaded) {
        console.log('Brands already loaded, skipping fetch');
        return brands;
      }

      // Увеличим лимит до 1000 брендов для полного списка
      const timestamp = new Date().getTime();
      const response = await axios.get(`/api/brands?limit=1000&page=1&t=${timestamp}`);

      const brandsData = response.data;

      if (!brandsData.brands || !Array.isArray(brandsData.brands)) {
        console.error('Invalid brands data format:', brandsData);
        setBrands([]);
        return [];
      }

      // Фильтруем только активные бренды
      const validBrands = brandsData.brands.filter(brand => {
        if (!brand || !brand.name) {
          console.warn('Skipping invalid brand:', brand);
          return false;
        }

        if (brand.status !== 'active') {
          console.log(`Skipping inactive brand: ${brand.name} (status: ${brand.status})`);
          return false;
        }

        if (!brand.categories || !Array.isArray(brand.categories) || brand.categories.length === 0) {
          console.warn(`Brand ${brand.name} has invalid categories field:`, brand.categories);
          return false;
        }

        return true;
      });

      console.log(`Valid brands: ${validBrands.length} of ${brandsData.brands.length}`);
      console.log('Brands names:', validBrands.map(b => b.name).join(', '));

      // Обновляем состояние брендов
      setBrands(validBrands);
      setBrandsLoaded(true);

      // Загружаем категории
      const categoriesResponse = await axios.get('/api/categories');
      setCategories(categoriesResponse.data.reduce((acc, category) => {
        if (category && category._id) {
          acc[category._id] = {
            ...category,
            subCategories: Array.isArray(category.subCategories) ? category.subCategories : []
          };
        }
        return acc;
      }, {}));

      return validBrands;
    } catch (error) {
      console.error('Error fetching brands:', error);
      setBrands([]);
      setCategories({});
      return [];
    }
  }, [brands, brandsLoaded]);

  // Также добавьте эту функцию для принудительного обновления брендов
  const forceRefreshBrands = async () => {
    console.log('Force refreshing brands...');
    try {
      const timestamp = new Date().getTime();
      // Добавим больше параметров для обхода кэширования
      const response = await axios.get(`/api/brands?force=${timestamp}&limit=1000&nocache=${Math.random()}`);

      if (!response.data || !response.data.brands) {
        console.error('Invalid response format:', response.data);
        return [];
      }

      const freshBrands = response.data.brands;
      console.log(`Received ${freshBrands.length} brands from force refresh`);

      // Фильтруем только активные бренды и проверяем наличие категорий
      const activeBrands = freshBrands.filter(brand => {
        if (!brand || !brand.name) {
          console.warn('Skipping invalid brand:', brand);
          return false;
        }

        const isActive = brand.status === 'active';
        if (!isActive) {
          console.log(`Filtering out inactive brand: ${brand.name}`);
          return false;
        }

        if (!brand.categories || !Array.isArray(brand.categories) || brand.categories.length === 0) {
          console.warn(`Brand ${brand.name} has invalid categories field:`, brand.categories);
          return false;
        }

        return true;
      });

      console.log(`After filtering: ${activeBrands.length} active brands`);

      // Обновляем состояние
      setBrands(activeBrands);

      // Обновляем выбранные бренды
      if (activeBrands.length >= 2) {
        updateSelectedBrands();
      }

      return activeBrands;
    } catch (error) {
      console.error('Error in force refresh brands:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      return [];
    }
  };

  const getFilteredBrands = useCallback(() => {
    console.log('Начало фильтрации брендов с параметрами:', {
      selectedCountry,
      selectedMainCategory,
      selectedSubCategory,
      selectedSubSubCategory,
      totalBrands: brands.length
    });

    // Проверяем корректность массива брендов
    if (!Array.isArray(brands) || brands.length === 0) {
      console.log('Нет доступных брендов для фильтрации');
      return [];
    }

    console.log(`Начинаем фильтрацию из ${brands.length} брендов...`);

    // Оптимизированная фильтрация с ясной логикой и лучшей производительностью
    const filteredBrands = brands.filter(brand => {
      // Базовые проверки
      if (!brand || !brand.name) {
        return false;
      }

      // Проверка активности бренда
      if (brand.status !== 'active') {
        return false;
      }

      // Проверка страны (если выбрана страна)
      if (selectedCountry && selectedCountry !== 'Global') {
        const countryMatch = brand.global ||
          (brand.countries && Array.isArray(brand.countries) &&
            brand.countries.some(c => c.code === selectedCountry));

        if (!countryMatch) {
          return false;
        }
      }

      // Если категория не выбрана, включаем бренд
      if (!selectedMainCategory) {
        return true;
      }

      // Проверяем наличие категорий у бренда
      if (!brand.categories || !Array.isArray(brand.categories)) {
        return false;
      }

      // Ищем хотя бы одну категорию, соответствующую всем фильтрам
      return brand.categories.some(cat => {
        // Извлекаем ID категории в зависимости от формата данных
        let categoryId;

        if (!cat.category) return false;

        if (typeof cat.category === 'object' && cat.category._id) {
          categoryId = cat.category._id.toString();
        } else if (typeof cat.category === 'string') {
          categoryId = cat.category;
        } else if (cat.category.toString) {
          categoryId = cat.category.toString();
        } else {
          return false;
        }

        // Сначала проверяем совпадение основной категории
        if (categoryId !== selectedMainCategory) {
          return false;
        }

        // Проверяем совпадение подкатегории, если она указана
        if (selectedSubCategory && cat.subCategory !== selectedSubCategory) {
          return false;
        }

        // Проверяем совпадение под-подкатегории, если она указана
        if (selectedSubSubCategory && cat.subSubCategory !== selectedSubSubCategory) {
          return false;
        }

        // Если все проверки пройдены, бренд соответствует фильтрам
        console.log(`Бренд ${brand.name} прошел все проверки фильтрации`);
        return true;
      });
    });

    console.log(`Результат фильтрации: ${filteredBrands.length} из ${brands.length} брендов`);
    console.log('Отфильтрованные бренды:', filteredBrands.map(b => b.name));
    return filteredBrands;
  }, [brands, selectedCountry, selectedMainCategory, selectedSubCategory, selectedSubSubCategory]);

  const getTwoRandomBrands = useCallback((filteredBrands) => {
    // Проверяем, что достаточно брендов для формирования пары
    if (!filteredBrands || filteredBrands.length < 2) {
      console.error('Недостаточно брендов для формирования пары:', filteredBrands?.length);
      return [];
    }

    // Проверяем, что все элементы в filteredBrands действительно являются брендами
    const validBrands = filteredBrands.filter(brand => brand && brand._id && brand.name);
    if (validBrands.length < 2) {
      console.error('Недостаточно валидных брендов для формирования пары:', validBrands.length);
      return [];
    }

    console.log('Формирование пары брендов из:', validBrands.map(b => b.name).join(', '));

    // Формируем список доступных для сравнения пар (тех, которые пользователь еще не сравнивал)
    const availablePairs = [];
    const comparedPairsSet = new Set((userData.comparedBrandPairs || []).map(pair => pair.pairId));

    // Полный список сравненных пользователем пар
    console.log('Список сравненных пар:', Array.from(comparedPairsSet));

    // Собираем все возможные пары брендов, которые еще не сравнивались
    for (let i = 0; i < validBrands.length; i++) {
      for (let j = i + 1; j < validBrands.length; j++) {
        // Формируем уникальный ID пары (сортируем для однозначности)
        const pairId = [validBrands[i]._id, validBrands[j]._id].sort().join('_');

        // Если пара еще не была сравнена, добавляем ее в доступные
        if (!comparedPairsSet.has(pairId)) {
          console.log(`Доступная пара: ${validBrands[i].name} vs ${validBrands[j].name} (ID: ${pairId})`);
          availablePairs.push([validBrands[i], validBrands[j]]);
        } else {
          console.log(`Пропущена уже сравненная пара: ${validBrands[i].name} vs ${validBrands[j].name} (ID: ${pairId})`);
        }
      }
    }

    console.log(`Доступно несравненных пар брендов: ${availablePairs.length} из ${validBrands.length * (validBrands.length - 1) / 2} возможных`);

    // Если есть доступные пары для сравнения, выбираем случайную
    if (availablePairs.length > 0) {
      // Выбираем случайную пару из доступных
      const randomIndex = Math.floor(Math.random() * availablePairs.length);
      const selectedPair = availablePairs[randomIndex];
      console.log(`Выбрана случайная несравненная пара: ${selectedPair[0].name} vs ${selectedPair[1].name}`);
      return selectedPair;
    }

    // Если все пары уже сравнены, выбираем случайную пару
    console.log('Все пары брендов в категории уже сравнивались, выбираем случайную пару');
    const shuffled = [...validBrands].sort(() => 0.5 - Math.random());
    const randomPair = [shuffled[0], shuffled[1]];
    console.log(`Выбрана случайная пара: ${randomPair[0].name} vs ${randomPair[1].name}`);
    return randomPair;
  }, [userData.comparedBrandPairs]);

  // Создаем useRef вне useEffect для отслеживания запуска историй
  const hasAutoLaunchedStory = useRef(false);

  // Загрузка историй при монтировании компонента
  useEffect(() => {
    // Проверяем, загружены ли данные пользователя
    if (!isUserDataLoaded) return;

    const loadStories = async () => {
      try {
        // Проверяем, был ли уже запуск истории
        if (hasAutoLaunchedStory.current) {
          console.log('Stories already launched in this component instance, skipping auto-launch');
          return;
        }

        // Получаем токен и telegram данные для аутентификации
        const token = localStorage.getItem('token');
        const telegramInitData = localStorage.getItem('telegramInitData');

        const headers = {};
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }
        if (telegramInitData) {
          headers['X-Telegram-Init-Data'] = telegramInitData;
        }

        console.log('Fetching stories with user data:', userData);

        const storiesResponse = await axios.get('/api/stories/launch', {
          headers: headers
        });

        if (!storiesResponse.data.stories || storiesResponse.data.stories.length === 0) {
          console.log('No stories available');
          setStoriesLoaded(true);
          return;
        }

        // Фильтруем истории, удаляя те, для которых пользователь достиг лимита просмотров
        const availableStories = storiesResponse.data.stories.filter(story => {
          // Проверяем, не достиг ли пользователь лимита просмотров для этой истории
          if (story.maxViewsPerUser > 0) {
            const viewCount = story.viewedByCurrentUser ?
              (userData?.storyViews?.find(v => (v.storyId === story._id || v.storyId === story.id))?.viewCount || 0) : 0;

            if (viewCount >= story.maxViewsPerUser) {
              console.log(`Story ${story._id || story.id} filtered out: user reached view limit (${viewCount}/${story.maxViewsPerUser})`);
              return false;
            }
          }

          // Проверяем общий лимит просмотров
          if (story.maxTotalViews > 0 && story.views >= story.maxTotalViews) {
            console.log(`Story ${story._id || story.id} filtered out: total views limit reached (${story.views}/${story.maxTotalViews})`);
            return false;
          }

          // Проверяем ограничения по кликам
          if (story.maxLinkClicks > 0 && story.linkClickCount >= story.maxLinkClicks) {
            console.log(`Story ${story._id || story.id} filtered out: link click limit reached (${story.linkClickCount}/${story.maxLinkClicks})`);
            return false;
          }

          // Проверяем статус активности
          if (story.status !== 'active') {
            console.log(`Story ${story._id || story.id} filtered out: story is not active`);
            return false;
          }

          return true;
        });

        console.log(`Filtered stories: ${availableStories.length} of ${storiesResponse.data.stories.length} are available`);
        setStories(availableStories);

        // Ищем истории, которые нужно показать при запуске
        const storiesForLaunch = availableStories.filter(story =>
          story.showOnLaunch === true && (
            // Если нет ограничений по просмотрам или пользователь не достиг лимита
            story.maxViewsPerUser === 0 ||
            !story.viewedByCurrentUser ||
            // Проверяем, что пользователь не превысил лимит просмотров для этой истории
            (userData?.storyViews?.find(v => (v.storyId === story._id || v.storyId === story.id))?.viewCount || 0) < story.maxViewsPerUser
          )
        );

        console.log('Stories available for auto-launch:', storiesForLaunch.length);

        // Запускаем первую подходящую историю только один раз
        if (storiesForLaunch.length > 0 && !hasAutoLaunchedStory.current) {
          console.log('Auto-launching story on first load');

          // Находим историю с наивысшим приоритетом
          const sortedStories = [...storiesForLaunch].sort((a, b) => (b.priority || 0) - (a.priority || 0));
          const highestPriorityStory = sortedStories[0];

          // Находим индекс этой истории в общем массиве
          const storyIndex = availableStories.findIndex(story =>
            story._id === highestPriorityStory._id || story.id === highestPriorityStory.id
          );

          if (storyIndex !== -1) {
            console.log(`Auto-launching story at index ${storyIndex} with priority ${highestPriorityStory.priority || 0}`);

            // Устанавливаем флаг запуска, чтобы избежать повторного автозапуска
            hasAutoLaunchedStory.current = true;

            // Отложенный показ чтобы избежать конфликтов состояния
            setTimeout(() => {
              setActiveStoryIndex(storyIndex);
              setShowStories(true);
            }, 500);
          }
        }

        setStoriesLoaded(true);
      } catch (error) {
        console.error('Error loading stories:', error);
        setStoriesLoaded(true);
      }
    };

    loadStories();

    // Функция очистки
    return () => {
      console.log('Cleaning up stories effect');
    };
  }, [isUserDataLoaded, userData]); // Добавляем зависимость userData для актуализации данных

  useEffect(() => {
    console.log('State changed:', {
      selectedMainCategory,
      selectedSubCategory,
      selectedSubSubCategory,
      brandsCount: brands.length,
      selectedBrandsCount: selectedBrands.length
    });
  }, [selectedMainCategory, selectedSubCategory, selectedSubSubCategory, brands.length, selectedBrands.length]);

  // Добавляем useEffect для отслеживания страны пользователя
  useEffect(() => {
    const getUserLocation = async () => {
      try {
        // Проверяем наличие страны в userData
        if (userData?.country) {
          console.log('Setting user location from userData:', userData.country);
          setUserLocation(userData.country);
          return;
        }

        // Проверяем наличие страны в localStorage
        const savedCountry = localStorage.getItem('userCountry');
        if (savedCountry) {
          console.log('Setting user location from localStorage:', savedCountry);
          setUserLocation(savedCountry);
          return;
        }

        // Если нет данных о стране, используем IP геолокацию через публичный API
        console.log('No country data, using geolocation API...');
        const response = await axios.get('https://ipapi.co/json/');
        const country = response.data.country_code;
        console.log('Got location from geolocation API:', country);

        // Сохраняем страну в localStorage
        localStorage.setItem('userCountry', country);
        setUserLocation(country);

      } catch (error) {
        console.error('Error getting user location:', error);
        // В случае ошибки используем Global
        setUserLocation('Global');
        localStorage.setItem('userCountry', 'Global');
      }
    };

    getUserLocation();
  }, [userData]);

  // Новый эффект для автоматического выбора категории при запуске
  useEffect(() => {
    // Проверяем, загружены ли бренды и определена ли локация пользователя
    if (brands.length > 0 && userLocation && !selectedMainCategory) {
      console.log('Автоматический выбор категории при запуске...');

      // Ищем категории с самыми глубокими конечными подкатегориями
      const categoriesWithDepths = [];
      brands.forEach(brand => {
        if (brand.categories && Array.isArray(brand.categories)) {
          brand.categories.forEach(cat => {
            if (cat.category && cat.category._id) {
              const categoryId = cat.category._id.toString();
              const subCategory = cat.subCategory;
              const subSubCategory = cat.subSubCategory;
              let depth = 1; // Глубина категории (1 - главная, 2 - подкатегория, 3 - подподкатегория)

              if (subSubCategory) {
                depth = 3;
              } else if (subCategory) {
                depth = 2;
              }

              categoriesWithDepths.push({
                mainCategoryId: categoryId,
                subCategory,
                subSubCategory,
                depth,
                category: cat.category
              });
            }
          });
        }
      });

      // Сортируем сначала по глубине (чтобы выбрать самую глубокую категорию), затем по частоте
      const sortedCategories = categoriesWithDepths
        .sort((a, b) => b.depth - a.depth);

      if (sortedCategories.length > 0) {
        // Выбираем категорию с максимальной глубиной
        const selectedCat = sortedCategories[0];
        console.log('Выбрана категория:', selectedCat.mainCategoryId, 'с глубиной:', selectedCat.depth);

        // Устанавливаем выбранную категорию
        setSelectedMainCategory(selectedCat.mainCategoryId);

        // Если есть подкатегория, выбираем ее
        if (selectedCat.depth >= 2 && selectedCat.subCategory) {
          setSelectedSubCategory(selectedCat.subCategory);
        }

        // Если есть подподкатегория, выбираем ее
        if (selectedCat.depth >= 3 && selectedCat.subSubCategory) {
          setSelectedSubSubCategory(selectedCat.subSubCategory);
        }

        // НЕ открываем диалог категорий автоматически
        // setIsCategoryDialogOpen(true);
      } else {
        console.log('Не найдены категории для автоматического выбора');
      }
    }
  }, [brands, userLocation, selectedMainCategory]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get('/api/brands');
        const allBrands = response.data.brands || [];
        console.log('Loaded brands:', allBrands);

        // Фильтруем только активные бренды
        const filteredBrands = allBrands.filter(brand => brand.status === 'active');

        console.log('Filtered active brands:', filteredBrands);
        setBrands(prevBrands => {
          // Объединяем существующие бренды с новыми активными брендами
          const updatedBrands = [...prevBrands];
          filteredBrands.forEach(newBrand => {
            if (!updatedBrands.some(existingBrand => existingBrand._id === newBrand._id)) {
              updatedBrands.push(newBrand);
            }
          });
          return updatedBrands;
        });

        if (selectedBrands.length === 0) {
          setSelectedBrands(getTwoRandomBrands(filteredBrands));
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    if (brands.length === 0) {
      fetchBrands();
    }
  }, [brands.length, selectedBrands.length, getTwoRandomBrands]);

  const startTimer = useCallback(() => {
    setTimer(5);
    setIsTimerRunning(true);
    setShowGo(false);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          setShowGo(true);
          setTimeout(() => {
            setIsTimerRunning(false);
            setShowGo(false);
          }, 1000);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  }, []);

  const prevSelectedBrandsRef = useRef([]);

  const updateSelectedBrands = useCallback(() => {
    console.log('Updating selected brands');
    try {
      // Получаем отфильтрованные бренды
      const filteredBrands = getFilteredBrands();
      console.log('Filtered brands count:', filteredBrands.length);

      // Проверяем, что текущие selectedBrands отличаются от предыдущих
      const currentSelectedBrandNames = selectedBrands.map(b => b.name).sort().join(',');
      const prevSelectedBrandNames = prevSelectedBrandsRef.current.map(b => b.name).sort().join(',');

      // Проверяем результаты фильтрации
      if (filteredBrands.length < 2) {
        console.warn('Not enough filtered brands, skipping update');
        return;
      }

      // Если уже есть выбранные бренды и они все из отфильтрованных брендов, не меняем их
      if (selectedBrands.length === 2 &&
        selectedBrands.every(sb => filteredBrands.some(fb => fb._id === sb._id))) {

        // Проверяем, не обновляем ли мы повторно те же самые бренды
        if (currentSelectedBrandNames === prevSelectedBrandNames) {
          console.log('Same brands already selected, skipping update');
          return;
        }
      }

      // Выбираем случайные 2 бренда
      const shuffled = [...filteredBrands].sort(() => 0.5 - Math.random());
      const newSelectedBrands = shuffled.slice(0, 2);

      // Проверяем, что новые бренды отличаются от предыдущих
      const newSelectedBrandNames = newSelectedBrands.map(b => b.name).sort().join(',');

      if (newSelectedBrandNames === currentSelectedBrandNames) {
        console.log('New selected brands are the same as current, skipping update');
        return;
      }

      console.log('New selected brands:', newSelectedBrands.map(b => b.name));

      // Сохраняем текущие бренды в ref перед обновлением
      prevSelectedBrandsRef.current = selectedBrands;

      // Обновляем выбранные бренды
      setSelectedBrands(newSelectedBrands);

    } catch (error) {
      console.error('Error updating selected brands:', error);
    }
  }, [getFilteredBrands, selectedBrands]);

  // Добавляем useEffect для отладки выбранной категории
  useEffect(() => {
    console.log('MainCategory changed to:', selectedMainCategory);

    if (selectedMainCategory === '0') {
      setSelectedMainCategory('');
    } else if (selectedMainCategory && userInteractionHappened) {
      // Открываем диалог ТОЛЬКО если это действие пользователя и
      // пользователь явно нажал на кнопку выбора категорий
      if (userInteraction) {
        setIsCategoryDialogOpen(true);
      }
    }
  }, [selectedMainCategory, userInteractionHappened, userInteraction]);

  const lightenColor = (color, percent) => {
    try {
      // Проверка, что color - это строка и начинается с #
      if (typeof color !== 'string' || !color.startsWith('#')) {
        return color;
      }

      // Конвертируем hex в rgb
      let r = parseInt(color.substring(1, 3), 16);
      let g = parseInt(color.substring(3, 5), 16);
      let b = parseInt(color.substring(5, 7), 16);

      // Осветляем
      r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
      g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
      b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

      // Конвертируем обратно в hex
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    } catch (error) {
      console.error('Error in lightenColor:', error);
      return color; // Возвращаем исходный цвет в случае ошибки
    }
  };

  // Модифицируем обработчик кнопки выбора категории
  const handleCategoryButtonClick = () => {
    // Отмечаем, что это действие пользователя
    setUserInteraction(true);
    setIsCategoryDialogOpen(true);
  };

  // Функция для получения имени категории по ID
  const getCategoryName = useCallback((categoryId) => {
    if (!categoryId) return 'Select Category';

    // Ищем категорию по ID
    for (const key in categories) {
      if (categories[key]._id === categoryId) {
        return categories[key].name;
      }
    }

    return 'Select Category';
  }, [categories]);

  // Новый эффект для автоматического выбора категории при запуске
  useEffect(() => {
    // Проверяем, загружены ли бренды и нет ли выбранной категории
    if (brands.length > 0 && !selectedMainCategory) {
      console.log('Автоматический выбор категории при запуске...');

      // Собираем все уникальные главные категории из брендов
      const mainCategoriesMap = {};
      brands.forEach(brand => {
        if (brand.categories && Array.isArray(brand.categories)) {
          brand.categories.forEach(cat => {
            if (cat.category && cat.category._id) {
              const categoryId = cat.category._id.toString();
              if (!mainCategoriesMap[categoryId]) {
                mainCategoriesMap[categoryId] = {
                  id: categoryId,
                  count: 0,
                  category: cat.category
                };
              }
              mainCategoriesMap[categoryId].count += 1;
            }
          });
        }
      });

      // Преобразуем в массив и сортируем по количеству брендов
      const mainCategoriesArray = Object.values(mainCategoriesMap)
        .sort((a, b) => b.count - a.count);

      if (mainCategoriesArray.length > 0) {
        // Выбираем первую категорию с наибольшим количеством брендов
        const firstCategory = mainCategoriesArray[0].id;
        console.log('Выбрана категория:', firstCategory, 'с количеством брендов:', mainCategoriesArray[0].count);

        // Устанавливаем выбранную категорию
        setSelectedMainCategory(firstCategory);

        // НЕ открываем диалог автоматически
        // setIsCategoryDialogOpen(true);
      } else {
        console.log('Не найдены категории для автоматического выбора');
      }
    }
  }, [brands, selectedMainCategory]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get('/api/tickets');
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, []);

  // Функция для предварительной загрузки изображений брендов
  const preloadBrandImages = useCallback((brandsToPreload) => {
    if (!Array.isArray(brandsToPreload) || brandsToPreload.length === 0) return;

    // Создаем ограниченный список брендов для предзагрузки (не более 20)
    const limitedBrands = brandsToPreload.slice(0, 20);

    console.log(`Preloading images for ${limitedBrands.length} brands`);

    // Создаем изображения в памяти, чтобы браузер их загрузил
    limitedBrands.forEach(brand => {
      if (brand.logo) {
        const img = new Image();
        img.src = brand.logo;
      }
    });
  }, []);

  // Используйте эту функцию после загрузки брендов
  useEffect(() => {
    if (brands.length > 0) {
      preloadBrandImages(brands);
    }
  }, [brands, preloadBrandImages]);

  useEffect(() => {
    console.log('Effect triggered to update brands');

    // Ранний выход, если данные еще не загружены
    if (!brands.length) {
      console.log('Brands not loaded yet, skipping brand update');
      return;
    }

    // Если категория не выбрана, просто возвращаемся
    if (!selectedMainCategory) {
      console.log('No category selected, skipping brand update');
      return;
    }

    // Получаем отфильтрованные бренды для текущих выбранных категорий
    const filteredBrands = getFilteredBrands();
    console.log('Filtered brands by category:', filteredBrands.map(b => b.name));

    // Проверяем, достаточно ли отфильтрованных брендов
    if (filteredBrands.length < 2) {
      console.log('Not enough brands in selected category/subcategory');
      return;
    }

    // Если у нас уже есть выбранные бренды, проверяем, соответствуют ли они текущим фильтрам
    if (selectedBrands.length === 2) {
      const selectedBrandsValid = selectedBrands.every(sb =>
        filteredBrands.some(fb => fb._id === sb._id)
      );

      if (selectedBrandsValid) {
        console.log('Selected brands are valid for current filters, no update needed');
        return;
      }
    }

    // Выбираем случайную пару несравненных брендов
    const comparedPairs = new Set((userData.comparedBrandPairs || []).map(pair => pair.pairId));
    const uncomparablePairs = [];

    // Находим пары брендов, которые еще не сравнивались
    for (let i = 0; i < filteredBrands.length; i++) {
      for (let j = i + 1; j < filteredBrands.length; j++) {
        const pairId = [filteredBrands[i]._id, filteredBrands[j]._id].sort().join('_');
        if (!comparedPairs.has(pairId)) {
          uncomparablePairs.push([filteredBrands[i], filteredBrands[j]]);
        }
      }
    }

    // Если есть несравненные пары, выбираем одну из них случайным образом
    if (uncomparablePairs.length > 0) {
      const randomIndex = Math.floor(Math.random() * uncomparablePairs.length);
      const newBrands = uncomparablePairs[randomIndex];
      console.log('New selected brands:', newBrands.map(b => b.name));
      setSelectedBrands(newBrands);
    } else {
      // Если все пары были сравнены, выбираем случайную пару
      const shuffled = [...filteredBrands].sort(() => 0.5 - Math.random());
      const newBrands = [shuffled[0], shuffled[1]];
      console.log('Selected brands from all filtered (all compared):', newBrands.map(b => b.name));
      setSelectedBrands(newBrands);
    }

  }, [brands, selectedMainCategory, selectedSubCategory, selectedSubSubCategory, getFilteredBrands, userData.comparedBrandPairs]);

  const updateUserDataCallback = useCallback(async (updatedData) => {
    try {
      const response = await axios.put('/api/users/me', updatedData, {
        headers: {
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });
      setGlobalUser(response.data);
      setUserData(prevData => ({ ...prevData, ...response.data }));
      setScore(response.data.coins);
      setBattlesWon(response.data.battles);
      setRemainingBattles(response.data.availableBattles);
      setLevel(response.data.level);
    } catch (error) {
      console.error('Error updating user data:', error);
      toast.error("Failed to update user data. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  }, [setGlobalUser]);

  useEffect(() => {
    const loadBrandsWithLazyLoading = async () => {
      if (brandsLoaded) {
        console.log('Brands already loaded, skipping initial load');
        return;
      }

      setIsLoading(true);
      try {
        console.log('Initial brands loading...');

        // 1. Сначала загружаем небольшое количество брендов для быстрого отображения
        const timestamp = new Date().getTime();
        const initialResponse = await axios.get(`/api/brands?limit=30&page=1&t=${timestamp}`);

        if (!initialResponse.data || !initialResponse.data.brands) {
          console.error('Invalid response format:', initialResponse.data);

          // Добавляем таймаут перед скрытием экрана загрузки
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
          return;
        }

        // Фильтруем только активные бренды
        const initialBrands = initialResponse.data.brands.filter(brand =>
          brand && brand.name && brand.status === 'active' &&
          brand.categories && Array.isArray(brand.categories) && brand.categories.length > 0
        );

        console.log(`Loaded initial ${initialBrands.length} brands`);

        // Устанавливаем начальный набор брендов
        setBrands(initialBrands);

        // 2. Выбираем начальную категорию и бренды для отображения
        if (!selectedMainCategory && initialBrands.length >= 2) {
          // Находим категории, в которых есть достаточно брендов
          const categoriesMap = new Map();

          initialBrands.forEach(brand => {
            if (brand.categories) {
              brand.categories.forEach(cat => {
                if (!cat.category) return;

                let categoryId;
                if (typeof cat.category === 'object' && cat.category._id) {
                  categoryId = cat.category._id.toString();
                } else if (typeof cat.category === 'string') {
                  categoryId = cat.category;
                } else {
                  return;
                }

                const key = `${categoryId}|${cat.subCategory || ''}|${cat.subSubCategory || ''}`;

                if (!categoriesMap.has(key)) {
                  categoriesMap.set(key, {
                    mainCategory: categoryId,
                    subCategory: cat.subCategory || '',
                    subSubCategory: cat.subSubCategory || '',
                    brands: []
                  });
                }

                // Добавляем бренд в категорию, если его там еще нет
                const entry = categoriesMap.get(key);
                if (!entry.brands.some(b => b._id === brand._id)) {
                  entry.brands.push(brand);
                }
              });
            }
          });

          // Фильтруем категории с хотя бы 2 брендами и сортируем по количеству
          const categoriesWithMultipleBrands = Array.from(categoriesMap.values())
            .filter(entry => entry.brands.length >= 2)
            .sort((a, b) => b.brands.length - a.brands.length);

          if (categoriesWithMultipleBrands.length > 0) {
            // Выбираем категорию с наибольшим количеством брендов
            const selectedCategory = categoriesWithMultipleBrands[0];
            console.log(`Выбрана категория: ${selectedCategory.mainCategory} с количеством брендов: ${selectedCategory.brands.length}`);

            // Устанавливаем выбранную категорию и подкатегории
            setSelectedMainCategory(selectedCategory.mainCategory);
            if (selectedCategory.subCategory) {
              setSelectedSubCategory(selectedCategory.subCategory);
            }
            if (selectedCategory.subSubCategory) {
              setSelectedSubSubCategory(selectedCategory.subSubCategory);
            }

            // Выбираем два случайных бренда из категории
            const shuffledBrands = [...selectedCategory.brands].sort(() => 0.5 - Math.random());
            const selectedPair = [shuffledBrands[0], shuffledBrands[1]];
            setSelectedBrands(selectedPair);

            console.log(`Selected brands: ${selectedPair[0].name} vs ${selectedPair[1].name}`);
          }
        }

        // 3. Загружаем оставшиеся бренды асинхронно после короткой задержки
        setTimeout(async () => {
          try {
            console.log('Loading remaining brands...');
            const fullResponse = await axios.get(`/api/brands?limit=1000&page=1&t=${Date.now()}&nocache=true`);

            if (!fullResponse.data || !fullResponse.data.brands) {
              console.error('Invalid response format for full brands:', fullResponse.data);
              return;
            }

            // Фильтруем только активные бренды
            const allBrands = fullResponse.data.brands.filter(brand =>
              brand && brand.name && brand.status === 'active' &&
              brand.categories && Array.isArray(brand.categories) && brand.categories.length > 0
            );

            console.log(`Loaded all ${allBrands.length} brands (added ${allBrands.length - initialBrands.length} more)`);

            // Обновляем только если получили больше брендов
            if (allBrands.length > initialBrands.length) {
              setBrands(allBrands);
            }

            setBrandsLoaded(true);

            // Добавляем задержку перед скрытием экрана загрузки
            // Это обеспечит плавную загрузку и улучшит UX
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);

          } catch (error) {
            console.error('Error loading remaining brands:', error);
            // Завершаем загрузку даже при ошибке
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          }
        }, 2000); // Задержка в 2 секунды перед загрузкой остальных брендов

      } catch (error) {
        console.error('Error during initial brands loading:', error);
        // Завершаем загрузку даже при ошибке
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    };

    loadBrandsWithLazyLoading();
  }, [brandsLoaded, selectedMainCategory]);

  // Вспомогательная функция для получения случайных брендов
  const getRandomBrands = (brands, count) => {
    const shuffled = [...brands].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const handleAuth = async (authData) => {
    try {
      console.log('Attempting authentication with data:', authData);
      const response = await axios.post('/api/users/auth', { telegramInitData: authData }, {
        headers: { 'Content-Type': 'application/json' }
      });
      const { user, token } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('telegramInitData', authData);
      updateUserData(user);
      setGlobalUser(user);
      console.log('Authentication successful, token saved:', token);
      return token;
    } catch (error) {
      console.error('Authentication error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      throw error;
    }
  };

  const refreshUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await axios.get('/api/users/me', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const updatedUser = response.data;
        setUserData(updatedUser);
        setGlobalUser(updatedUser);
        updateUserData({
          coins: updatedUser.coins,
          battles: updatedUser.battles,
          battleLimit: updatedUser.totalBattles - updatedUser.currentBattles,
          level: updatedUser.level
        });
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  }, [setGlobalUser]);

  const updateUserState = (user) => {
    console.log('Updating user state with:', user);
    if (user && Object.keys(user).length > 0) {
      setUserData(user);
      setScore(user.coins || 0);
      setBattlesWon(user.battles || 0);
      setStats({ battlesWon: user.battles || 0, brandsFaced: user.stats?.brandsFaced || [] });
      setRemainingBattles(user.availableBattles || 0);
      setBattleLimit(user.battleLimit || 50);
      setLevel(user.level || 1);
      setGlobalUser(user);
      console.log('Updated user state:', {
        coins: user.coins,
        battles: user.battles,
        availableBattles: user.availableBattles,
        battleLimit: user.battleLimit,
        level: user.level
      });
    } else {
      console.warn('Attempted to update user state with invalid data:', user);
    }
  };

  useEffect(() => {
    if (!isUserDataLoaded) {
      return;
    }

    const initAuth = async () => {
      const telegramInitData = window.Telegram?.WebApp?.initData || localStorage.getItem('telegramInitData');
      if (telegramInitData) {
        try {
          console.log('Initializing auth with telegramInitData');
          const response = await axios.post('/api/users/auth', { telegramInitData });
          console.log('Auth response:', response.data);
          const { user, token } = response.data;
          localStorage.setItem('token', token);
          localStorage.setItem('telegramInitData', telegramInitData);
          setUserData(prevData => ({
            ...prevData,
            ...user,
            avatar: user.avatar || null,
            defaultAvatar: null
          }));
        } catch (error) {
          console.error('Error during authentication:', error);
        }
      } else {
        console.warn('No telegramInitData available');
      }
    };

    initAuth();
  }, [isUserDataLoaded]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        // Получаем страну и язык пользователя из userData
        const userCountry = userData?.country || null;
        const userLanguage = userData?.languageCode || null;

        console.log('Fetching tasks for user:', {
          country: userCountry,
          language: userLanguage
        });

        const [videoResponse, socialResponse, specialResponse] = await Promise.all([
          axios.get('/api/video-tasks', {
            params: { userCountry, userLanguage }
          }),
          axios.get('/api/social-tasks', {
            params: { userCountry, userLanguage }
          }),
          axios.get('/api/special-tasks', {
            params: { userCountry, userLanguage }
          })
        ]);

        // Фильтруем задания на клиентской стороне
        const filterTasksByTargeting = (tasks) => {
          return tasks.filter(task => {
            if (task.targeting === 'all') return true;

            if (task.targeting === 'specific') {
              const countryMatch = !task.allowedCountries.length ||
                task.allowedCountries.includes(userCountry);
              const languageMatch = !task.allowedLanguages.length ||
                task.allowedLanguages.includes(userLanguage);
              return countryMatch && languageMatch;
            }

            return false;
          });
        };

        const filteredVideoTasks = filterTasksByTargeting(videoResponse.data);
        const filteredSocialTasks = filterTasksByTargeting(socialResponse.data);
        const filteredSpecialTasks = filterTasksByTargeting(specialResponse.data);

        console.log('Filtered tasks:', {
          video: filteredVideoTasks.length,
          social: filteredSocialTasks.length,
          special: filteredSpecialTasks.length
        });

        // Определяем дневной лимит для рекламных заданий
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const updatedVideoTasks = filteredVideoTasks.map(task => ({
          ...task,
          status: userData.completedVideoTasks?.includes(task._id) ? 'completed' : task.status || 'not_started'
        }));

        const updatedSocialTasks = filteredSocialTasks.map(task => ({
          ...task,
          status: userData.completedSocialTasks?.includes(task._id) ? 'completed' : task.status || 'not_started'
        }));

        const updatedSpecialTasks = filteredSpecialTasks.map(task => {
          // Для рекламных заданий проверяем дневной лимит
          let dailyLimitReached = false;
          if (task.taskType === 'ad' && userData.adViewHistory) {
            const todayViewsCount = userData.adViewHistory.filter(view =>
              view.taskId === task._id &&
              new Date(view.viewedAt) >= today
            ).length;

            dailyLimitReached = task.dailyAdClickLimit > 0 && todayViewsCount >= task.dailyAdClickLimit;

            console.log(`Task ${task._id} ${task.name} daily views:`, {
              count: todayViewsCount,
              limit: task.dailyAdClickLimit,
              limitReached: dailyLimitReached
            });
          }

          return {
            ...task,
            status: userData.completedSpecialTasks?.includes(task._id) ? 'completed' : task.status || 'not_started',
            adWatched: false,
            dailyLimitReached, // Новое поле, указывающее, достигнут ли дневной лимит
            todayViewsCount: userData.adViewHistory ? userData.adViewHistory.filter(view =>
              view.taskId === task._id &&
              new Date(view.viewedAt) >= today
            ).length : 0 // Добавляем счетчик просмотров за сегодня
          };
        });

        setVideoTasks(updatedVideoTasks);
        setSocialTasks(updatedSocialTasks);
        setSpecialTasks(updatedSpecialTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        toast.error("Failed to load tasks", {
          duration: 3000,
          position: 'top-center',
        });
      }
    };

    if (userData) {
      fetchTasks();
    }
  }, [userData]);

  useEffect(() => {
    const handleUserUpdate = (event) => {
      if (!event.detail) return;

      // Обработка полного объекта обновления
      if (typeof event.detail === 'object') {
        // Если у нас есть полный объект пользователя
        if (event.detail._id) {
          if (userData && userData._id === event.detail._id) {
            setUserData(event.detail);
          }
        }
        // Если у нас есть частичное обновление с userId
        else if (event.detail.userId) {
          if (userData && userData._id === event.detail.userId) {
            setUserData(prevData => ({
              ...prevData,
              ...event.detail
            }));
          }
        }
        // Если нет ID, но есть другие поля, применяем их к текущему пользователю
        else {
          setUserData(prevData => ({
            ...prevData,
            ...event.detail
          }));
        }
      }
    };

    window.addEventListener('userUpdate', handleUserUpdate);

    return () => {
      window.removeEventListener('userUpdate', handleUserUpdate);
    };
  }, [userData]);

  const handleStartVideoTask = async (taskId) => {
    try {
      const response = await axios.post(`/api/video-tasks/${taskId}/start`);
      setVideoTasks(prevTasks =>
        prevTasks.map(task =>
          task._id === taskId ? { ...task, status: 'in_progress' } : task
        )
      );
      setTimeout(() => {
        setVideoTasks(prevTasks =>
          prevTasks.map(task =>
            task._id === taskId ? { ...task, status: 'ready_to_claim' } : task
          )
        );
      }, response.data.timerDuration * 1000);
    } catch (error) {
      console.error('Error starting video task:', error);
      toast.error("Failed to start video task. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleCodeInput = (taskId, code) => {
    setTaskCodes(prevCodes => ({ ...prevCodes, [taskId]: code }));
    setVideoTasks(prevTasks =>
      prevTasks.map(task =>
        task._id === taskId ? { ...task, codeError: false } : task
      )
    );
  };

  const handleClaimVideoTask = async (taskId) => {
    try {
      const code = taskCodes[taskId];
      const task = videoTasks.find(t => t._id === taskId);
      if (!task) {
        console.error('Task not found');
        toast.error("Task not found. Please try again.", {
          duration: 3000,
          position: 'top-center',
        });
        return;
      }
      if (!code) {
        toast.error("Please enter a code.", {
          duration: 3000,
          position: 'top-center',
        });
        return;
      }
      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');
      if (!token || !telegramInitData) {
        toast.error("Authentication error. Please refresh the page and try again.", {
          duration: 3000,
          position: 'top-center',
        });
        return;
      }
      console.log('Sending claim request with:', { taskId, code, token: token.substring(0, 10) + '...' });
      const response = await axios.post(`/api/video-tasks/${taskId}/claim`,
        { code },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-Telegram-Init-Data': telegramInitData
          }
        }
      );
      console.log('Claim response:', response.data);
      if (response.data.success) {
        setVideoTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'completed', codeError: false } : t
          )
        );
        const updatedUserData = {
          ...userData,
          coins: response.data.updatedUser.coins,
          completedVideoTasks: response.data.updatedUser.completedVideoTasks
        };
        setUserData(updatedUserData);
        setGlobalUser(updatedUserData);
        setScore(updatedUserData.coins);
        toast.success(`Task completed successfully! You earned ${task.coinsReward} coins.`, {
          duration: 3000,
          position: 'top-center',
        });
      } else {
        console.error('Claim failed:', response.data);
        switch (response.data.errorType) {
          case 'INVALID_CODE':
            setVideoTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, codeError: true } : t
              )
            );
            toast.error("Invalid code. Please try again.", {
              duration: 3000,
              position: 'top-center',
            });
            break;
          case 'TASK_COMPLETED':
            toast.info("You have already completed this task.", {
              duration: 3000,
              position: 'top-center',
            });
            setVideoTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, status: 'completed' } : t
              )
            );
            break;
          case 'TASK_EXPIRED':
            toast.error("This task has expired.", {
              duration: 3000,
              position: 'top-center',
            });
            setVideoTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, status: 'expired' } : t
              )
            );
            break;
          default:
            toast.error(response.data.message || "Failed to claim task. Please try again.", {
              duration: 3000,
              position: 'top-center',
            });
        }
      }
    } catch (error) {
      console.error('Error claiming video task:', error);
      if (error.response && error.response.data) {
        console.error('Error response:', error.response.data);
        toast.error(error.response.data.message || "Failed to claim task. Please try again.", {
          duration: 3000,
          position: 'top-center',
        });
      } else {
        toast.error("Network error. Please check your connection and try again.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    }
  };

  const handleWatchVideo = async (taskId, videoUrl) => {
    try {
      const task = videoTasks.find(t => t._id === taskId);
      if (!task) {
        console.error('Task not found');
        return;
      }

      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');

      if (!token || !telegramInitData) {
        toast.error("Authentication error. Please refresh the page and try again.", {
          duration: 3000,
          position: 'top-center',
        });
        return;
      }

      // Отправляем запрос на сервер для регистрации клика
      const response = await axios.post(`/api/video-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Telegram-Init-Data': telegramInitData
        }
      });

      if (response.data.success) {
        // Открываем видео
        window.open(videoUrl, '_blank');

        setVideoTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'in_progress' } : t
          )
        );

        toast.success("Video opened. Watching in progress...", {
          duration: 3000,
          position: 'top-center',
        });

        // Используем таймер из задания
        const timerDuration = task.timerDuration * 1000;
        setTimeout(() => {
          setVideoTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, status: 'ready_to_claim' } : t
            )
          );
          toast.success("Video watched. You can now enter the code.", { duration: 3000, position: 'top-center' });
        }, timerDuration);
      } else {
        toast.info("You've already clicked this video.", { duration: 3000, position: 'top-center' });
      }
    } catch (error) {
      console.error('Error registering video click:', error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || "Failed to register video click. Please try again.", { duration: 3000, position: 'top-center' });
      } else {
        toast.error("Failed to register video click. Please try again.", { duration: 3000, position: 'top-center' });
      }
    }
  };

  const handleSubscribe = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      // Отправляем запрос на сервер для регистрации клика
      const response = await axios.post(`/api/social-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      if (response.data.success) {
        // Открываем ссылку на канал в новом окне
        window.open(task.socialUrl, '_blank');

        // Меняем статус задачи на 'subscribed', чтобы показать кнопку Claim
        setSocialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'subscribed' } : t
          )
        );
      } else {
        toast.info("No brands in this subcategory.", { duration: 3000, position: 'top-center' });
      }
    } catch (error) {
      console.error('Error subscribing to social task:', error);
      toast.error("Authentication error. Please refresh the page and try again.", { duration: 3000, position: 'top-center' });
    }
  };

  const handleWebsiteClick = async (brandId, websiteUrl) => {
    try {
      await axios.post(`/api/brands/${brandId}/click/website`);
      window.open(websiteUrl, '_blank');
    } catch (error) {
      console.error('Error registering website click:', error);
      toast.error("Failed to register click", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleSocialMediaClick = async (brandId, platform, socialUrl) => {
    try {
      await axios.post(`/api/brands/${brandId}/click/social`, { platform });
      window.open(socialUrl, '_blank');
    } catch (error) {
      console.error('Error registering social media click:', error);
      toast.error("Failed to register click", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleClaimSocialTask = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      // Проверяем подписку через бота
      const checkSubscriptionResponse = await axios.post('/api/social-tasks/check-subscription', {
        userId: userData.telegramId,
        channelUsername: new URL(task.socialUrl).pathname.slice(1) // Извлекаем username из URL
      });

      if (checkSubscriptionResponse.data.isSubscribed) {
        const response = await axios.post(`/api/social-tasks/${taskId}/claim`);
        if (response.data.success) {
          setSocialTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, status: 'completed' } : t
            )
          );
          const updatedUserData = {
            ...userData,
            coins: response.data.updatedUser.coins,
            completedSocialTasks: response.data.updatedUser.completedSocialTasks
          };
          setUserData(updatedUserData);
          setGlobalUser(updatedUserData);
          setScore(updatedUserData.coins);
          toast.success(`Task completed successfully! You earned ${task.coinsReward} coins.`, {
            duration: 3000,
            position: 'top-center',
          });
        } else {
          toast.error(response.data.message || "Failed to claim task. Please try again.", {
            duration: 3000,
            position: 'top-center',
          });
        }
      } else {
        toast.error("You are not subscribed to the channel. Please subscribe and try again.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    } catch (error) {
      console.error('Error claiming social task:', error);
      toast.error(error.response?.data?.message || "Failed to claim task. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleSpecialTask = async (taskId) => {
    try {
      const task = specialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      const response = await axios.post(`/api/special-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      if (response.data.success) {
        window.open(task.specialUrl, '_blank');
        setSpecialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'in_progress' } : t
          )
        );
        toast.success("Task started successfully!", {
          duration: 3000,
          position: 'top-center',
        });
      } else {
        toast.error(response.data.message || "Failed to start special task.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    } catch (error) {
      console.error('Error handling special task:', error);
      toast.error("Failed to start special task. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleClaimSpecialTask = async (taskId) => {
    try {
      const task = specialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      let requestData = {};
      if (task.taskType === 'web') {
        const code = taskCodes[taskId];
        if (!code) {
          toast.error("Please enter a code.", {
            duration: 3000,
            position: 'top-center',
          });
          return;
        }
        requestData = { code };
      }

      const response = await axios.post(`/api/special-tasks/${taskId}/claim`,
        requestData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
          }
        }
      );

      if (response.data.success) {
        const updatedUserData = {
          ...userData,
          coins: response.data.updatedUser.coins,
          completedSpecialTasks: response.data.updatedUser.completedSpecialTasks
        };
        setUserData(updatedUserData);
        setGlobalUser(updatedUserData);
        setScore(updatedUserData.coins);

        const updatedTasksResponse = await axios.get('/api/special-tasks', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
          }
        });

        const updatedSpecialTasks = updatedTasksResponse.data.map(task => ({
          ...task,
          status: updatedUserData.completedSpecialTasks.includes(task._id) ? 'completed' : task.status
        }));
        setSpecialTasks(updatedSpecialTasks);

        toast.success(`Task completed successfully! You earned ${task.coinsReward} coins.`, {
          duration: 3000,
          position: 'top-center',
        });
      } else {
        if (task.taskType === 'web') {
          setSpecialTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, codeError: true } : t
            )
          );
          toast.error("Invalid code. Please try again.", {
            duration: 3000,
            position: 'top-center',
          });
        } else {
          toast.error("Failed to claim task. Please try again.", {
            duration: 3000,
            position: 'top-center',
          });
        }
      }
    } catch (error) {
      console.error('Error claiming special task:', error);
      toast.error(error.response?.data?.message || "Failed to claim task. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleOtherSocialPlatforms = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }
      window.open(task.socialUrl, '_blank');
      setSocialTasks(prevTasks =>
        prevTasks.map(t =>
          t._id === taskId ? { ...t, status: 'ready_to_claim' } : t
        )
      );
    } catch (error) {
      console.error('Error opening social link:', error);
      toast.error("Failed to open social link. Please try again.", { duration: 3000, position: 'top-center' });
    }
  };

  const handleClaimOtherSocialTask = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }
      const response = await axios.post(`/api/social-tasks/${taskId}/claim`);
      if (response.data.success) {
        setSocialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'completed' } : t
          )
        );
        setUserData(prevUserData => ({
          ...prevUserData,
          coins: response.data.updatedUser.coins,
          completedSocialTasks: response.data.updatedUser.completedSocialTasks
        }));
        setScore(response.data.updatedUser.coins);
        toast.success("Video opened. Watching in progress...", { duration: 3000, position: 'top-center' });
      } else {
        toast.error(response.data.message || "Failed to claim task. Please try again.", { duration: 3000, position: 'top-center' });
      }
    } catch (error) {
      console.error('Error claiming social task:', error);
      toast.error(error.response?.data?.message || "Failed to claim task. Please try again.", { duration: 3000, position: 'top-center' });
    }
  };

  const getOrderedBrandsFunc = useCallback((allBrands, count = 2, country, category, subCategory) => {
    if (!Array.isArray(allBrands) || allBrands.length === 0) {
      return [];
    }
    let filteredBrands = allBrands;
    if (country && country !== 'Global') {
      filteredBrands = filteredBrands.filter(brand =>
        brand.countries && Array.isArray(brand.countries) && brand.countries.some(c => c.code === country)
      );
    }
    if (category) {
      filteredBrands = filteredBrands.filter(brand => brand.category === category);
      if (subCategory) {
        filteredBrands = filteredBrands.filter(brand => brand.subCategory === subCategory);
      }
    }
    if (filteredBrands.length < count) {
      const otherBrands = allBrands.filter(brand => !filteredBrands.includes(brand));
      filteredBrands = [...filteredBrands, ...otherBrands];
    }
    const sortedBrands = filteredBrands.sort((a, b) => {
      if (country && country !== 'Global') {
        const aInCountry = a.countries && Array.isArray(a.countries) && a.countries.some(c => c.code === country);
        const bInCountry = b.countries && Array.isArray(b.countries) && b.countries.some(c => c.code === country);
        if (aInCountry && !bInCountry) return -1;
        if (!aInCountry && bInCountry) return 1;
      }
      return (b.frequency || 0) - (a.frequency || 0);
    });
    return sortedBrands.slice(0, count);
  }, []);

  // Функция для обновления данных пользователя на сервере
  const updateUserData = useCallback(async (updatedData) => {
    try {
      const response = await axios.put('/api/users/me', updatedData, {
        headers: {
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });
      setUserData(response.data);
      setGlobalUser(response.data);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  }, [setGlobalUser]);

  // Эффект для загрузки начальных данных
  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const [categoriesRes, ranksRes, tasksRes, faqsRes, countriesRes, referralsRes, brandsRes] = await Promise.all([
        axios.get('/api/categories'),
        axios.get('/api/ranks'),
        axios.get('/api/tasks'),
        axios.get('/api/faq'),
        axios.get('/api/countries'),
        axios.get('/api/referrals'),
        axios.get('/api/brands'),
        axios.get('/api/stories/launch')
      ]);

      setCategories(categoriesRes.data);
      setRanks(ranksRes.data);
      setTasks(tasksRes.data);
      setFaqs(faqsRes.data);
      setCountries(countriesRes.data);
      setReferrals(referralsRes.data);
      if (brandsRes.data && Array.isArray(brandsRes.data.brands)) {
        setBrands(brandsRes.data.brands);
        const initialBrands = getOrderedBrands(brandsRes.data.brands, 2);
        setSelectedBrands(initialBrands);
      } else {
        console.error('Unexpected brands data structure:', brandsRes.data);
        setBrands([]);
        setSelectedBrands([]);
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Добавить этот useEffect в компонент BrandBattle
  useEffect(() => {
    const handleUserDataUpdate = (event) => {
      const { type, storyId, userData } = event.detail;

      // Обновляем userState если получены новые данные пользователя
      if (userData) {
        // Обновляем локальные данные пользователя
        setUserData(prevUserData => ({
          ...prevUserData,
          ...userData
        }));

        // Обновляем глобальное состояние пользователя
        setGlobalUser(prevUser => ({
          ...prevUser,
          ...userData
        }));
      }

      // Обновляем состояние историй, чтобы отметить просмотренные
      if (type === 'storyViewed' && storyId) {
        // Обновляем локальное состояние историй, отмечая просмотренные
        setStories(prevStories =>
          prevStories.map(story => {
            const storyHasId = story.id === storyId || story._id === storyId;
            return storyHasId
              ? { ...story, viewedByCurrentUser: true }
              : story;
          })
        );
      }
    };

    // Регистрируем обработчик события обновления данных пользователя
    window.addEventListener('userDataUpdate', handleUserDataUpdate);

    // Очищаем обработчик при размонтировании компонента
    return () => {
      window.removeEventListener('userDataUpdate', handleUserDataUpdate);
    };
  }, [setGlobalUser]); // Зависимости

  // Функция для получения стиля текста на основе эффекта ранга
  const getNameEffectStyle = (effect) => {
    if (!effect?.enabled) return {};

    const style = {
      color: effect.textColor || '#FFFFFF'
    };

    switch (effect.type) {
      case 'shadow':
        style.textShadow = `0 0 ${effect.intensity}px ${effect.color}`;
        break;
      case 'glow':
        style.textShadow = `0 0 ${effect.intensity * 2}px ${effect.color}, 0 0 ${effect.intensity}px ${effect.color}`;
        break;
      case 'gradient':
        if (effect.gradientColors?.length >= 2) {
          style.background = `linear-gradient(to right, ${effect.gradientColors.join(', ')})`;
        } else {
          style.background = `linear-gradient(to right, ${effect.textColor || 'white'}, ${effect.color})`;
        }
        style.WebkitBackgroundClip = 'text';
        style.WebkitTextFillColor = 'transparent';
        style.backgroundClip = 'text';
        style.textFillColor = 'transparent';
        break;
      case 'highlight':
        style.backgroundColor = effect.color;
        style.color = effect.textColor || 'black';
        style.padding = '0 8px';
        style.borderRadius = '4px';
        break;
    }

    return style;
  };

  // Функция для получения стилей из customTitle
  const getCustomTitleStyle = (customTitle) => {
    if (!customTitle?.style) return {};

    const titleStyle = customTitle.style;
    const style = {
      color: titleStyle.color || '#FFFFFF'
    };

    // Добавление эффектов для названия
    switch (titleStyle.effect) {
      case 'shadow':
        style.textShadow = `0 0 ${titleStyle.intensity || 3}px ${titleStyle.effectColor || '#000000'}`;
        break;
      case 'glow':
        style.textShadow = `0 0 ${titleStyle.intensity * 2 || 6}px ${titleStyle.effectColor || '#e0ff89'}, 0 0 ${titleStyle.intensity || 3}px ${titleStyle.effectColor || '#e0ff89'}`;
        break;
      case 'gradient':
        if (titleStyle.gradientColors?.length >= 2) {
          style.background = `linear-gradient(to right, ${titleStyle.gradientColors.join(', ')})`;
        } else {
          style.background = `linear-gradient(to right, ${titleStyle.color || '#FFFFFF'}, ${titleStyle.effectColor || '#e0ff89'})`;
        }
        style.WebkitBackgroundClip = 'text';
        style.WebkitTextFillColor = 'transparent';
        style.backgroundClip = 'text';
        style.textFillColor = 'transparent';
        break;
    }

    // Добавление анимации для названия
    if (titleStyle.animation && titleStyle.animation !== 'none') {
      if (titleStyle.animation === 'pulse') {
        style.animation = 'titlePulse 2s infinite';
      } else if (titleStyle.animation === 'wave') {
        style.animation = 'titleWave 2s infinite';
      } else if (titleStyle.animation === 'shine') {
        style.animation = 'titleShine 2s linear infinite';
        style.backgroundSize = '200% auto';
        if (!style.background) {
          style.background = `linear-gradient(to right, ${titleStyle.color || '#FFFFFF'} 0%, ${titleStyle.effectColor || '#e0ff89'} 50%, ${titleStyle.color || '#FFFFFF'} 100%)`;
          style.WebkitBackgroundClip = 'text';
          style.WebkitTextFillColor = 'transparent';
          style.backgroundClip = 'text';
          style.textFillColor = 'transparent';
        }
      }
    }

    return style;
  };

  const toggleFaq = (id) => {
    setOpenFaqId(openFaqId === id ? null : id);
  };

  const renderCategoryButton = (category, isSubCategory = false) => (
    <Button
      key={category}
      onClick={() => handleCategorySelect(category, isSubCategory)}
      className={`justify-start w-full mb-2 rounded-[30px] ${(isSubCategory ? selectedSubCategory : selectedCategory) === category
        ? 'bg-[#e0ff89] text-black'
        : 'bg-[#1a1a1a] text-white hover:text-[#e0ff89]'
        } ${isSubCategory ? 'text-sm' : ''}`}
    >
      {category}
    </Button>
  );

  const handleCategorySelect = useCallback((category) => {
    console.log('Selecting category:', category);
    const categoryId = category._id; // Получаем ObjectId категории

    // Отмечаем, что произошло взаимодействие с пользователем
    setUserInteractionHappened(true);

    // Сбрасываем подкатегории при изменении основной категории
    setSelectedMainCategory(categoryId);
    setSelectedSubCategory('');
    setSelectedSubSubCategory('');

    console.log(`Выбрана основная категория: ${categoryId}`);

    // Получаем отфильтрованные бренды для новой категории
    setTimeout(() => {
      const filteredBrands = getFilteredBrands();
      console.log('Filtered brands after category select:', filteredBrands);
      console.log('Filtered brand names:', filteredBrands.map(b => b.name).join(', '));

      if (filteredBrands.length >= 2) {
        const newBrands = getTwoRandomBrands(filteredBrands);
        if (newBrands.length === 2) {
          console.log('New selected brands:', newBrands.map(b => b.name).join(' vs '));
          setSelectedBrands(newBrands);
        } else {
          console.error('Failed to get two random brands');
        }
      } else {
        console.log('No brands match filters, trying to find brands in other subcategories');

        // Если в выбранной категории нет брендов, ищем бренды с той же основной категорией,
        // но без учета подкатегорий
        const brandsInMainCategory = brands.filter(brand =>
          brand.status === 'active' &&
          brand.categories &&
          brand.categories.some(cat => {
            let categoryId;
            if (cat.category) {
              if (typeof cat.category === 'object' && cat.category._id) {
                categoryId = cat.category._id.toString();
              } else if (typeof cat.category === 'string') {
                categoryId = cat.category;
              } else if (cat.category.toString) {
                categoryId = cat.category.toString();
              }
            }
            return categoryId === selectedMainCategory;
          })
        );

        console.log('Brands in main category:', brandsInMainCategory.map(b => b.name).join(', '));

        if (brandsInMainCategory.length >= 2) {
          const newBrands = getTwoRandomBrands(brandsInMainCategory);
          console.log('Selected brands from main category:', newBrands.map(b => b.name).join(' vs '));
          setSelectedBrands(newBrands);
        } else {
          // Если все еще нет брендов, показываем уведомление
          toast.info("No brands available in this category. Please select another category.", {
            duration: 3000,
            position: 'top-center',
          });
        }
      }
    }, 0);
  }, [getFilteredBrands, getTwoRandomBrands, brands, selectedMainCategory]);

  const handleSubCategorySelect = useCallback((subCategory) => {
    console.log('Selecting subcategory:', subCategory);

    // Устанавливаем выбранную подкатегорию и сбрасываем подподкатегорию
    setSelectedSubCategory(subCategory.name);
    setSelectedSubSubCategory('');

    console.log(`Выбрана подкатегория: ${subCategory.name}`);

    // Проверяем наличие под-подкатегорий
    const hasSubSubCategories = subCategory.subSubCategories &&
      subCategory.subSubCategories.length > 0;

    console.log(`Наличие под-подкатегорий: ${hasSubSubCategories}. Количество: ${hasSubSubCategories ? subCategory.subSubCategories.length : 0}`);

    // Проверка по логам, что делалось здесь и улучшаем
    setTimeout(() => {
      // Если нет под-подкатегорий или их меньше 1, сразу фильтруем
      if (!hasSubSubCategories) {
        console.log('Нет под-подкатегорий, фильтруем бренды по категории и подкатегории');

        // Сначала сохраняем выбранную подкатегорию
        const filteredBrands = brands.filter(brand =>
          brand.status === 'active' &&
          brand.categories &&
          brand.categories.some(cat => {
            // Проверяем наличие категории
            let categoryId;
            if (cat.category) {
              if (typeof cat.category === 'object' && cat.category._id) {
                categoryId = cat.category._id.toString();
              } else if (typeof cat.category === 'string') {
                categoryId = cat.category;
              } else if (cat.category.toString) {
                categoryId = cat.category.toString();
              }
            }

            const mainCategoryMatch = categoryId === selectedMainCategory;
            const subCategoryMatch = cat.subCategory === subCategory.name;

            return mainCategoryMatch && subCategoryMatch;
          })
        );

        console.log('Отфильтрованные бренды по подкатегории:', filteredBrands.map(b => b.name).join(', '));

        if (filteredBrands.length >= 2) {
          // Если достаточно брендов, выбираем случайные два
          const newBrands = getTwoRandomBrands(filteredBrands);
          console.log('Выбранные бренды:', newBrands.map(b => b.name).join(' vs '));
          setSelectedBrands(newBrands);

          // Закрываем диалог после выбора
          setIsCategoryDialogOpen(false);
        } else {
          // Если брендов недостаточно, показываем сообщение
          console.log('Недостаточно брендов в подкатегории:', subCategory.name);
          toast.info(`No brands available in the ${subCategory.name} subcategory.`, {
            duration: 3000,
            position: 'top-center',
          });

          // Сбрасываем выбор подкатегории
          setSelectedSubCategory('');
        }
      }
      // Если есть подподкатегории, оставляем диалог открытым для выбора
    }, 0);
  }, [selectedMainCategory, brands, getTwoRandomBrands]);

  const handleSubSubCategorySelect = useCallback((subSubCategory) => {
    console.log('Selecting sub-subcategory:', subSubCategory);

    // Устанавливаем выбранную под-подкатегорию
    setSelectedSubSubCategory(subSubCategory.name);

    console.log(`Selected main category: ${selectedMainCategory}`);
    console.log(`Selected subcategory: ${selectedSubCategory}`);
    console.log(`Selected sub-subcategory: ${subSubCategory.name}`);

    // Закрываем диалог сразу
    setIsCategoryDialogOpen(false);

    // Используем таймаут, чтобы состояние успело обновиться
    setTimeout(() => {
      // Фильтруем бренды по всем трем уровням категорий
      const filteredBrands = brands.filter(brand =>
        brand.status === 'active' &&
        brand.categories &&
        brand.categories.some(cat => {
          // Проверяем наличие категории
          let categoryId;
          if (cat.category) {
            if (typeof cat.category === 'object' && cat.category._id) {
              categoryId = cat.category._id.toString();
            } else if (typeof cat.category === 'string') {
              categoryId = cat.category;
            } else if (cat.category.toString) {
              categoryId = cat.category.toString();
            }
          }

          const mainCategoryMatch = categoryId === selectedMainCategory;
          const subCategoryMatch = cat.subCategory === selectedSubCategory;
          const subSubCategoryMatch = cat.subSubCategory === subSubCategory.name;

          return mainCategoryMatch && subCategoryMatch && subSubCategoryMatch;
        })
      );

      console.log('Filtered brands by sub-subcategory:',
        filteredBrands.map(b => ({
          name: b.name,
          categories: b.categories.map(c => ({
            category: c.category && c.category._id ? c.category._id : c.category,
            subCategory: c.subCategory,
            subSubCategory: c.subSubCategory
          }))
        })));

      console.log('Filtered brand names:', filteredBrands.map(b => b.name).join(', '));

      if (filteredBrands.length >= 2) {
        // Если достаточно брендов, выбираем случайные два
        const newBrands = getTwoRandomBrands(filteredBrands);
        console.log('Selected brands:', newBrands.map(b => b.name).join(' vs '));
        setSelectedBrands(newBrands);
      } else if (filteredBrands.length === 1) {
        console.log('Only one brand found:', filteredBrands[0].name);

        // Ищем другой бренд из той же категории и подкатегории
        const otherBrands = brands.filter(b =>
          b._id !== filteredBrands[0]._id &&
          b.status === 'active' &&
          b.categories &&
          b.categories.some(cat => {
            let categoryId;
            if (cat.category) {
              if (typeof cat.category === 'object' && cat.category._id) {
                categoryId = cat.category._id.toString();
              } else if (typeof cat.category === 'string') {
                categoryId = cat.category;
              } else if (cat.category.toString) {
                categoryId = cat.category.toString();
              }
            }

            const mainCategoryMatch = categoryId === selectedMainCategory;
            const subCategoryMatch = cat.subCategory === selectedSubCategory;

            return mainCategoryMatch && subCategoryMatch;
          })
        );

        console.log('Other brands in subcategory:', otherBrands.map(b => b.name).join(', '));

        if (otherBrands.length > 0) {
          // Выбираем случайный второй бренд из подкатегории
          const randomBrand = otherBrands[Math.floor(Math.random() * otherBrands.length)];
          setSelectedBrands([filteredBrands[0], randomBrand]);
          console.log(`Selected pair: ${filteredBrands[0].name} vs ${randomBrand.name}`);
        } else {
          // Если нет другого бренда, используем единственный найденный и случайный из всех
          const randomBrand = brands.filter(b =>
            b._id !== filteredBrands[0]._id && b.status === 'active'
          )[Math.floor(Math.random() * (brands.length - 1))];

          setSelectedBrands([filteredBrands[0], randomBrand]);
          console.log(`Selected pair with random brand: ${filteredBrands[0].name} vs ${randomBrand.name}`);
        }
      } else {
        // Если совсем нет брендов, показываем уведомление
        console.log('No brands in sub-subcategory:', subSubCategory.name);

        toast.info(`No brands available in ${subSubCategory.name}.`, {
          duration: 3000,
          position: 'top-center',
        });

        // Пробуем найти бренды в подкатегории
        const subCategoryBrands = brands.filter(brand =>
          brand.status === 'active' &&
          brand.categories &&
          brand.categories.some(cat => {
            let categoryId;
            if (cat.category) {
              if (typeof cat.category === 'object' && cat.category._id) {
                categoryId = cat.category._id.toString();
              } else if (typeof cat.category === 'string') {
                categoryId = cat.category;
              } else if (cat.category.toString) {
                categoryId = cat.category.toString();
              }
            }

            const mainCategoryMatch = categoryId === selectedMainCategory;
            const subCategoryMatch = cat.subCategory === selectedSubCategory;

            return mainCategoryMatch && subCategoryMatch;
          })
        );

        console.log('Brands in subcategory:', subCategoryBrands.map(b => b.name).join(', '));

        if (subCategoryBrands.length >= 2) {
          const newBrands = getTwoRandomBrands(subCategoryBrands);
          setSelectedBrands(newBrands);
          console.log('Selected brands from subcategory:', newBrands.map(b => b.name).join(' vs '));
        } else {
          // Если все еще нет брендов, показываем еще одно уведомление
          toast.error("No brands available in this category. Please select another category.", {
            duration: 3000,
            position: 'top-center',
          });
        }
      }
    }, 0);
  }, [selectedMainCategory, selectedSubCategory, brands, getTwoRandomBrands]);

  const closeDialog = () => {
    setIsCategoryDialogOpen(false);
  };

  const toggleCategory = (categoryId) => {
    setSelectedMainCategory(prevCategory =>
      prevCategory === categoryId ? '' : categoryId
    );
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(prevMenu => {
      if (prevMenu === menu) {
        setTranslateY(0);
        return null;
      } else {
        setTranslateY(0);
        return menu;
      }
    });
  };

  const handleTouchStart = (e) => {
    if (e.target.closest('.drag-handle')) {
      setIsDragging(true);
      setTouchStart(e.targetTouches[0].clientY);
    }
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentTouch = e.targetTouches[0].clientY;
    const diff = currentTouch - touchStart;
    if (diff > 0) {
      setTranslateY(diff);
    }
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    if (translateY > 100) {
      handleMenuClick(null);
    }
    setTranslateY(0);
    setIsDragging(false);
  };


  const closeMenu = () => {
    setActiveMenu(null);
  };

  const vibrate = () => {
    if (navigator.vibrate) {
      navigator.vibrate(50);
    }
  };

  const handleWithVibration = (handler) => {
    return (...args) => {
      vibrate();
      handler(...args);
    };
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const canActivateRank = (rank) => {
    // Проверяем, достаточно ли ресурсов для активации ранга
    return score >= rank.requiredCoins &&
      battlesWon >= rank.battlesRequired &&
      level >= rank.level &&
      rank.name !== userData.rank;
  };

  // Функция проверяет, ниже ли данный ранг, чем текущий ранг пользователя
  const isRankLowerThanCurrent = (rank) => {
    // Получаем текущий ранг пользователя
    const currentRankObj = ranks.find(r => r.name === userData.rank);

    // Если текущий ранг не найден, возвращаем false
    if (!currentRankObj) return false;

    // Если уровень проверяемого ранга ниже уровня текущего ранга, значит он ниже
    return rank.level < currentRankObj.level;
  };

  const availableCountries = useMemo(() => {
    const countryCodes = new Set(brands.flatMap(brand =>
      brand.countries && Array.isArray(brand.countries) ? brand.countries.map(c => c.code) : []
    ));
    countryCodes.add('Global'); // Добавляем 'Global' в любом случае
    return countries.filter(country => countryCodes.has(country.code));
  }, [brands, countries]);

  const handleCountryChange = useCallback((newCountry) => {
    console.log('Changing country to:', newCountry);
    setSelectedCountry(newCountry);

    // Обновляем бренды после изменения страны
    setTimeout(() => {
      const filteredBrands = getFilteredBrands();
      console.log('Filtered brands after country change:', filteredBrands);
      console.log('Filtered brands details:', filteredBrands.map(b => ({
        name: b.name,
        countries: b.countries,
        global: b.global
      })));
      if (filteredBrands.length > 0) {
        const newBrands = getTwoRandomBrands(filteredBrands);
        setSelectedBrands(newBrands);
      } else {
        toast.info("No brands in this country.", {
          duration: 3000,
          position: 'top-center',
        });
        setSelectedBrands([]); // Очищаем выбранные бренды, если нет подходящих
      }
      setIsCountryDialogOpen(false);
    }, 0);
  }, [getFilteredBrands, getTwoRandomBrands]);

  const UpgradeAnimation = ({ rankName }) => (
    <div className="absolute inset-0 flex items-center justify-center overflow-hidden z-[999]">
      <div className="absolute inset-0 bg-black bg-opacity-90"></div>

      <div className="matrix-background">
        {[...Array(200)].map((_, i) => (
          <div
            key={i}
            className="matrix-symbol"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`
            }}
          >
            {String.fromCharCode(0x30A0 + Math.random() * 96)}
          </div>
        ))}
      </div>

      <div className="hologram-container">
        <div className="data-stream top"></div>
        <div className="data-stream bottom"></div>

        <div className="rank-display">
          <div className="rank-hexagon">
            <div className="rank-hexagon-inner"></div>
            <div className="rank-hexagon-scan"></div>
          </div>
          <div className="rank-info">
            <div className="rank-title">NEW RANK</div>
            <div className="rank-details">{rankName}</div>
          </div>
        </div>
      </div>

      <style jsx>{`
      .matrix-background {
        position: absolute;
        inset: 0;
        overflow: hidden;
      }

      .matrix-symbol {
        position: absolute;
        color: #e0ff89;
        font-size: 20px;
        opacity: 0;
        animation: matrixRain 5s linear infinite;
      }

      .hologram-container {
        position: relative;
        width: 300px;
        height: 300px;
        perspective: 1000px;
      }

      .data-stream {
        position: absolute;
        width: 2px;
        height: 100vh;
        background: linear-gradient(180deg, transparent, #00ffff, transparent);
        animation: dataStream 2s ease-out infinite;
        opacity: 0.5;
      }

      .data-stream.top {
        transform: translateX(-150px);
      }

      .data-stream.bottom {
        transform: translateX(150px);
      }

      .rank-display {
        position: relative;
        transform-style: preserve-3d;
        animation: floatHologram 4s ease-in-out infinite;
      }

      .rank-hexagon {
        width: 200px;
        height: 200px;
        position: relative;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        background: linear-gradient(45deg, #00ffff33, #ff00ff33);
        animation: rotateHexagon 10s linear infinite;
      }

      .rank-hexagon-inner {
        position: absolute;
        inset: 10px;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        background: linear-gradient(-45deg, #00ffff22, #ff00ff22);
        animation: pulseHexagon 2s ease-in-out infinite;
      }

      .rank-hexagon-scan {
        position: absolute;
        width: 100%;
        height: 4px;
        background: linear-gradient(90deg, transparent, #00ffff, transparent);
        animation: hexagonScan 2s linear infinite;
      }

      .rank-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;
        z-index: 1;
      }

      .rank-title {
        font-size: 2em;
        font-weight: bold;
        color: #00ffff;
        text-shadow: 0 0 10px #00ffff;
        animation: glitchText 0.3s infinite;
      }

      .rank-details {
        font-size: 1.2em;
        color: #ff00ff;
        text-shadow: 0 0 10px #ff00ff;
      }

      @keyframes matrixRain {
        0% { transform: translateY(-100%); opacity: 1; }
        100% { transform: translateY(100%); opacity: 0; }
      }

      @keyframes dataStream {
        0% { transform: translateY(-100vh); opacity: 0; }
        50% { opacity: 1; }
        100% { transform: translateY(100vh); opacity: 0; }
      }

      @keyframes floatHologram {
        0%, 100% { transform: translateY(0) rotateX(20deg); }
        50% { transform: translateY(-20px) rotateX(25deg); }
      }

      @keyframes rotateHexagon {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      @keyframes pulseHexagon {
        0%, 100% { opacity: 0.5; transform: scale(0.95); }
        50% { opacity: 1; transform: scale(1.05); }
      }

      @keyframes hexagonScan {
        0% { top: 0; opacity: 0; }
        50% { opacity: 1; }
        100% { top: 100%; opacity: 0; }
      }

      @keyframes glitchText {
        0% { transform: translate(0); }
        25% { transform: translate(-2px, 2px); }
        50% { transform: translate(2px, -2px); }
        75% { transform: translate(-2px, -2px); }
        100% { transform: translate(0); }
      }
    `}</style>
    </div>
  );

  const updateUser = async (updatedData) => {
    try {
      const response = await axios.put(`/api/user/${userData._id}`, updatedData);
      setGlobalUser(response.data);
      setUserData(response.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  // Обработчик выбора бренда
  const handleBrandSelect = useCallback(async (selectedBrand, index) => {
    console.log('Handling brand selection:', {
      selectedBrand,
      index,
      userLocation,
      userDemographics: {
        gender: userData.gender,
        ageRange: userData.ageRange
      }
    });

    if (remainingBattles > 0 && !isTimerRunning) {
      setSelectedBrandIndex(index);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }

        const otherBrandIndex = index === 0 ? 1 : 0;
        const otherBrand = selectedBrands[otherBrandIndex];

        if (!otherBrand) {
          throw new Error('No competitor brand found');
        }

        // Создаем brandPairId, сортируя ID брендов для обеспечения консистентности
        const brandPairId = [selectedBrand._id, otherBrand._id].sort().join('_');

        // Проверяем, сравнивал ли пользователь эту пару брендов ранее
        const pairExists = userData.comparedBrandPairs && userData.comparedBrandPairs.some(pair =>
          pair.pairId === brandPairId
        );

        if (pairExists) {
          console.log('User has already compared this brand pair:', brandPairId);

          // Получаем все бренды в текущей категории
          const filteredBrands = getFilteredBrands();

          // Создаем массив для всех возможных пар брендов
          const allPossiblePairs = [];
          const comparedPairs = new Set((userData.comparedBrandPairs || []).map(pair => pair.pairId));

          // Перебираем все возможные пары брендов в текущей категории
          for (let i = 0; i < filteredBrands.length; i++) {
            for (let j = i + 1; j < filteredBrands.length; j++) {
              // Формируем ID пары
              const pairId = [filteredBrands[i]._id, filteredBrands[j]._id].sort().join('_');

              // Если пара еще не была сравнена, добавляем ее в доступные
              if (!comparedPairs.has(pairId)) {
                allPossiblePairs.push([filteredBrands[i], filteredBrands[j]]);
              }
            }
          }

          // Показываем, сколько пар найдено
          console.log(`Found ${allPossiblePairs.length} uncomparable pairs out of ${filteredBrands.length * (filteredBrands.length - 1) / 2} possible combinations`);

          // Если есть доступные пары для сравнения, выбираем случайную
          if (allPossiblePairs.length > 0) {
            const randomIndex = Math.floor(Math.random() * allPossiblePairs.length);
            setSelectedBrands(allPossiblePairs[randomIndex]);
            return; // Выходим из функции
          } else {
            // Если все пары в текущей категории сравнены, находим новую категорию
            console.log('All possible brand pairs in current category have been compared. Switching to a different category');

            // Собираем все доступные категории с подкатегориями, в которых есть бренды
            const availableCategories = [];

            // Первый проход - собираем информацию о категориях и подкатегориях
            Object.entries(categories).forEach(([categoryId, category]) => {
              // Пропускаем текущую основную категорию
              if (categoryId === selectedMainCategory) return;

              // Проверяем наличие брендов в этой категории
              const brandsInCategory = brands.filter(brand => {
                if (!brand.categories || !Array.isArray(brand.categories)) return false;

                return brand.categories.some(cat => {
                  return (cat.category?._id === categoryId) || (cat.category === categoryId);
                });
              });

              if (brandsInCategory.length >= 2) {
                // Если в категории есть подкатегории, проверяем их
                if (category.subCategories && category.subCategories.length > 0) {
                  // Проходим по подкатегориям
                  category.subCategories.forEach(subCategory => {
                    // Проверяем наличие брендов в подкатегории
                    const brandsInSubCategory = brandsInCategory.filter(brand =>
                      brand.categories.some(cat => cat.subCategory === subCategory.name)
                    );

                    if (brandsInSubCategory.length >= 2) {
                      // Если есть под-подкатегории, проверяем их
                      if (subCategory.subSubCategories && subCategory.subSubCategories.length > 0) {
                        subCategory.subSubCategories.forEach(subSubCategory => {
                          // Проверяем наличие брендов в под-подкатегории
                          const brandsInSubSubCategory = brandsInSubCategory.filter(brand =>
                            brand.categories.some(cat => cat.subSubCategory === subSubCategory.name)
                          );

                          if (brandsInSubSubCategory.length >= 2) {
                            availableCategories.push({
                              mainCategory: categoryId,
                              mainCategoryName: category.name,
                              subCategory: subCategory.name,
                              subSubCategory: subSubCategory.name,
                              displayName: `${category.name} > ${subCategory.name} > ${subSubCategory.name}`,
                              brands: brandsInSubSubCategory
                            });
                          }
                        });
                      } else {
                        // Если нет под-подкатегорий, добавляем подкатегорию
                        availableCategories.push({
                          mainCategory: categoryId,
                          mainCategoryName: category.name,
                          subCategory: subCategory.name,
                          subSubCategory: '',
                          displayName: `${category.name} > ${subCategory.name}`,
                          brands: brandsInSubCategory
                        });
                      }
                    }
                  });
                } else {
                  // Если нет подкатегорий, добавляем основную категорию
                  availableCategories.push({
                    mainCategory: categoryId,
                    mainCategoryName: category.name,
                    subCategory: '',
                    subSubCategory: '',
                    displayName: category.name,
                    brands: brandsInCategory
                  });
                }
              }
            });

            console.log('Available categories with brands:', availableCategories);

            // Если нет доступных категорий с брендами
            if (availableCategories.length === 0) {
              toast.info("You've compared all available brand pairs! Great job!", {
                duration: 3000,
                position: 'top-center',
                icon: '🏆'
              });
              return;
            }

            // Выбираем случайную категорию из доступных
            const randomIndex = Math.floor(Math.random() * availableCategories.length);
            const selectedCategory = availableCategories[randomIndex];

            console.log('Selected new category:', selectedCategory);

            // Устанавливаем новую категорию со всеми подкатегориями
            setSelectedMainCategory(selectedCategory.mainCategory);
            setSelectedSubCategory(selectedCategory.subCategory);
            setSelectedSubSubCategory(selectedCategory.subSubCategory);

            // Выбираем два случайных бренда из выбранной категории
            const randomBrands = [];
            const brandPool = [...selectedCategory.brands];
            while (randomBrands.length < 2 && brandPool.length > 0) {
              const randomBrandIndex = Math.floor(Math.random() * brandPool.length);
              randomBrands.push(brandPool[randomBrandIndex]);
              brandPool.splice(randomBrandIndex, 1); // Удаляем выбранный бренд из пула
            }

            setSelectedBrands(randomBrands);

            // Позитивное уведомление вместо сообщения об ошибке
            toast.success(`All brand pairs in the previous category compared! Moving to ${selectedCategory.displayName}`, {
              duration: 3000,
              position: 'top-center',
              icon: '🔄'
            });

            return;
          }
        }

        // Получаем IP пользователя
        const userIp = await fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => data.ip)
          .catch(() => null);

        console.log('Sending comparison request:', {
          winnerBrandId: selectedBrand._id,
          loserBrandId: otherBrand._id,
          userCountry: userLocation,
          ipCountry: userLocation,
          brandPairId: brandPairId,
          demographicData: {
            gender: userData.gender,
            ageRange: userData.ageRange
          }
        });

        const response = await axios.post('/api/brands/compare', {
          winnerBrandId: selectedBrand._id,
          loserBrandId: otherBrand._id,
          userCountry: userLocation,
          ipCountry: userLocation,
          brandPairId: brandPairId,
          demographicData: {
            gender: userData.gender,
            ageRange: userData.ageRange
          }
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
          }
        });

        console.log('Brand comparison response:', response.data);

        const { winnerBrand, loserBrand, updatedUser } = response.data;

        // Обновляем локальный массив сравненных пар
        const updatedComparedPairs = [...(userData.comparedBrandPairs || []), {
          pairId: brandPairId,
          timestamp: new Date()
        }];

        // Получаем отфильтрованные бренды в текущей категории
        const filteredBrands = getFilteredBrands();

        // Формируем список доступных для сравнения пар
        const availablePairs = [];
        const comparedPairsSet = new Set(updatedComparedPairs.map(pair => pair.pairId));

        // Собираем все возможные пары, которые еще не сравнивались
        for (let i = 0; i < filteredBrands.length; i++) {
          for (let j = i + 1; j < filteredBrands.length; j++) {
            const pairId = [filteredBrands[i]._id, filteredBrands[j]._id].sort().join('_');
            if (!comparedPairsSet.has(pairId)) {
              availablePairs.push([filteredBrands[i], filteredBrands[j]]);
            }
          }
        }

        // Рассчитываем общее количество возможных пар для текущей категории
        const totalPossiblePairs = filteredBrands.length * (filteredBrands.length - 1) / 2;
        const comparedPairsInCategory = totalPossiblePairs - availablePairs.length;

        console.log(`Progress: ${comparedPairsInCategory}/${totalPossiblePairs} pairs compared in this category`);

        if (availablePairs.length > 0) {
          // Выбираем случайную несравненную пару
          const randomIndex = Math.floor(Math.random() * availablePairs.length);
          setSelectedBrands(availablePairs[randomIndex]);
          startTimer();
        } else {
          // Если все пары в текущей категории сравнены, ищем новую категорию
          console.warn('All pairs in current category have been compared. Switching to a different category');

          // Собираем все доступные категории с подкатегориями, в которых есть бренды
          const availableCategories = [];

          // Первый проход - собираем информацию о категориях и подкатегориях
          Object.entries(categories).forEach(([categoryId, category]) => {
            // Пропускаем текущую основную категорию
            if (categoryId === selectedMainCategory) return;

            // Проверяем наличие брендов в этой категории
            const brandsInCategory = brands.filter(brand => {
              if (!brand.categories || !Array.isArray(brand.categories)) return false;

              return brand.categories.some(cat => {
                return (cat.category?._id === categoryId) || (cat.category === categoryId);
              });
            });

            if (brandsInCategory.length >= 2) {
              // Если в категории есть подкатегории, проверяем их
              if (category.subCategories && category.subCategories.length > 0) {
                // Проходим по подкатегориям
                category.subCategories.forEach(subCategory => {
                  // Проверяем наличие брендов в подкатегории
                  const brandsInSubCategory = brandsInCategory.filter(brand =>
                    brand.categories.some(cat => cat.subCategory === subCategory.name)
                  );

                  if (brandsInSubCategory.length >= 2) {
                    // Если есть под-подкатегории, проверяем их
                    if (subCategory.subSubCategories && subCategory.subSubCategories.length > 0) {
                      subCategory.subSubCategories.forEach(subSubCategory => {
                        // Проверяем наличие брендов в под-подкатегории
                        const brandsInSubSubCategory = brandsInSubCategory.filter(brand =>
                          brand.categories.some(cat => cat.subSubCategory === subSubCategory.name)
                        );

                        if (brandsInSubSubCategory.length >= 2) {
                          availableCategories.push({
                            mainCategory: categoryId,
                            mainCategoryName: category.name,
                            subCategory: subCategory.name,
                            subSubCategory: subSubCategory.name,
                            displayName: `${category.name} > ${subCategory.name} > ${subSubCategory.name}`,
                            brands: brandsInSubSubCategory
                          });
                        }
                      });
                    } else {
                      // Если нет под-подкатегорий, добавляем подкатегорию
                      availableCategories.push({
                        mainCategory: categoryId,
                        mainCategoryName: category.name,
                        subCategory: subCategory.name,
                        subSubCategory: '',
                        displayName: `${category.name} > ${subCategory.name}`,
                        brands: brandsInSubCategory
                      });
                    }
                  }
                });
              } else {
                // Если нет подкатегорий, добавляем основную категорию
                availableCategories.push({
                  mainCategory: categoryId,
                  mainCategoryName: category.name,
                  subCategory: '',
                  subSubCategory: '',
                  displayName: category.name,
                  brands: brandsInCategory
                });
              }
            }
          });

          console.log('Available categories with brands:', availableCategories);

          // Если нет доступных категорий с брендами
          if (availableCategories.length === 0) {
            toast.info("You've compared all available brand pairs! Great job!", {
              duration: 3000,
              position: 'top-center',
              icon: '🏆'
            });
            return;
          }

          // Выбираем случайную категорию из доступных
          const randomIndex = Math.floor(Math.random() * availableCategories.length);
          const selectedCategory = availableCategories[randomIndex];

          console.log('Selected new category:', selectedCategory);

          // Устанавливаем новую категорию со всеми подкатегориями
          setSelectedMainCategory(selectedCategory.mainCategory);
          setSelectedSubCategory(selectedCategory.subCategory);
          setSelectedSubSubCategory(selectedCategory.subSubCategory);

          // Выбираем два случайных бренда из выбранной категории
          const randomBrands = [];
          const brandPool = [...selectedCategory.brands];
          while (randomBrands.length < 2 && brandPool.length > 0) {
            const randomBrandIndex = Math.floor(Math.random() * brandPool.length);
            randomBrands.push(brandPool[randomBrandIndex]);
            brandPool.splice(randomBrandIndex, 1); // Удаляем выбранный бренд из пула
          }

          setSelectedBrands(randomBrands);

          // Позитивное уведомление
          toast.success(`All brand pairs in ${categories[selectedMainCategory]?.name || 'the previous category'} compared! Moving to ${selectedCategory.displayName}`, {
            duration: 3000,
            position: 'top-center',
            icon: '🔄'
          });
        }

        // Сбрасываем индекс выбранного бренда
        setSelectedBrandIndex(null);

        // Обновляем статистику бренда
        setBrandStats(prevStats => ({
          ...prevStats,
          [selectedBrand._id]: {
            ...prevStats[selectedBrand._id],
            totalBattles: winnerBrand.totalComparisons,
            wins: winnerBrand.totalWins,
            rating: winnerBrand.rating,
            demographics: winnerBrand.demographics
          }
        }));

        // Обновляем состояния пользователя
        if (updatedUser) {
          // Рассчитываем новый уровень на основе обновленного количества битв
          const newBattlesWon = battlesWon + 1;
          const newLevel = calculateLevel(newBattlesWon);

          // Если уровень изменился, включаем его в обновление данных
          if (newLevel !== userData.level) {
            console.log(`Level increased from ${userData.level} to ${newLevel}`);

            // Показываем toast уведомление о повышении уровня
            toast.success(`Level up! You've reached Level ${newLevel}!`, {
              duration: 5000,
              position: 'top-center',
              icon: '🎉'
            });

            try {
              // Обновляем локальное состояние
              setUserData(prevData => ({
                ...prevData,
                level: newLevel
              }));

              setGlobalUser(prevUser => ({
                ...prevUser,
                level: newLevel
              }));

              setLevel(newLevel);

              // Отправляем событие для обновления всех компонентов
              window.dispatchEvent(new CustomEvent('userUpdate', {
                detail: {
                  level: newLevel,
                  battles: userData.battles || battlesWon,
                  availableBattles: userData.availableBattles || remainingBattles
                }
              }));

              // Используем ТОЛЬКО новый эндпоинт для обновления на сервере
              axios.post('/api/users/update-level',
                { level: newLevel },
                {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
                  }
                }
              ).then(response => {
                console.log('Level updated successfully via API:', response.data);
              }).catch(error => {
                console.error('Error updating level via API:', error);
              });

            } catch (error) {
              console.error('Error updating user level:', error);
            }
          }

          setUserData(prevData => ({
            ...prevData,
            coins: updatedUser.coins,
            battles: updatedUser.battles,
            availableBattles: updatedUser.availableBattles,
            level: newLevel, // Используем рассчитанный уровень
            comparedBrandPairs: updatedComparedPairs
          }));

          setGlobalUser(prevUser => ({
            ...prevUser,
            coins: updatedUser.coins,
            battles: updatedUser.battles,
            availableBattles: updatedUser.availableBattles,
            level: newLevel // Используем рассчитанный уровень
          }));

          setRemainingBattles(updatedUser.availableBattles);
          setScore(updatedUser.coins);
          setBattlesWon(newBattlesWon);
          setLevel(newLevel); // Непосредственно обновляем состояние уровня

          // Добавим уровень в событие обновления баланса
          window.dispatchEvent(new CustomEvent('balanceUpdate', {
            detail: {
              coins: updatedUser.coins,
              level: newLevel
            }
          }));
        }

        // Обновляем общую статистику
        setStats(prevStats => ({
          battlesWon: (prevStats.battlesWon || 0) + 1,
          brandsFaced: [...(prevStats.brandsFaced || []), selectedBrand],
          demographics: {
            ...(prevStats.demographics || {}),
            gender: {
              ...(prevStats.demographics?.gender || {}),
              [userData.gender]: (prevStats.demographics?.gender?.[userData.gender] || 0) + 1
            },
            ageRange: {
              ...(prevStats.demographics?.ageRange || {}),
              [userData.ageRange]: (prevStats.demographics?.ageRange?.[userData.ageRange] || 0) + 1
            }
          }
        }));

      } catch (error) {
        console.error('Error in brand selection:', error);

        // Проверяем, является ли это ошибкой о том, что пара уже сравнивалась
        if (error.response?.data?.errorType === 'ALREADY_COMPARED') {
          // Пытаемся загрузить новую пару из текущей категории
          const filteredBrands = getFilteredBrands();

          // Собираем все возможные пары и исключаем уже сравненные
          const availablePairs = [];
          const comparedPairs = new Set((userData.comparedBrandPairs || []).map(pair => pair.pairId));

          for (let i = 0; i < filteredBrands.length; i++) {
            for (let j = i + 1; j < filteredBrands.length; j++) {
              const pairId = [filteredBrands[i]._id, filteredBrands[j]._id].sort().join('_');
              if (!comparedPairs.has(pairId)) {
                availablePairs.push([filteredBrands[i], filteredBrands[j]]);
              }
            }
          }

          // Рассчитываем общее количество возможных пар для текущей категории
          const totalPossiblePairs = filteredBrands.length * (filteredBrands.length - 1) / 2;
          const comparedPairsInCategory = totalPossiblePairs - availablePairs.length;

          console.log(`Progress: ${comparedPairsInCategory}/${totalPossiblePairs} pairs compared in this category`);

          if (availablePairs.length > 0) {
            // Выбираем случайную несравненную пару
            const randomIndex = Math.floor(Math.random() * availablePairs.length);
            setSelectedBrands(availablePairs[randomIndex]);

            toast.info("Loading new brand pair...", {
              duration: 2000,
              position: 'top-center',
            });
          } else {
            // Если в текущей категории нет доступных пар, собираем категории с брендами
            const availableCategories = [];

            // Первый проход - собираем информацию о категориях и подкатегориях
            Object.entries(categories).forEach(([categoryId, category]) => {
              // Пропускаем текущую основную категорию
              if (categoryId === selectedMainCategory) return;

              // Проверяем наличие брендов в этой категории
              const brandsInCategory = brands.filter(brand => {
                if (!brand.categories || !Array.isArray(brand.categories)) return false;

                return brand.categories.some(cat => {
                  return (cat.category?._id === categoryId) || (cat.category === categoryId);
                });
              });

              if (brandsInCategory.length >= 2) {
                // Если в категории есть подкатегории, проверяем их
                if (category.subCategories && category.subCategories.length > 0) {
                  // Проходим по подкатегориям
                  category.subCategories.forEach(subCategory => {
                    // Проверяем наличие брендов в подкатегории
                    const brandsInSubCategory = brandsInCategory.filter(brand =>
                      brand.categories.some(cat => cat.subCategory === subCategory.name)
                    );

                    if (brandsInSubCategory.length >= 2) {
                      availableCategories.push({
                        mainCategory: categoryId,
                        mainCategoryName: category.name,
                        subCategory: subCategory.name,
                        subSubCategory: '',
                        displayName: `${category.name} > ${subCategory.name}`,
                        brands: brandsInSubCategory
                      });
                    }
                  });
                } else {
                  // Если нет подкатегорий, добавляем основную категорию
                  availableCategories.push({
                    mainCategory: categoryId,
                    mainCategoryName: category.name,
                    subCategory: '',
                    subSubCategory: '',
                    displayName: category.name,
                    brands: brandsInCategory
                  });
                }
              }
            });

            if (availableCategories.length > 0) {
              // Выбираем случайную категорию
              const randomIndex = Math.floor(Math.random() * availableCategories.length);
              const selectedCategory = availableCategories[randomIndex];

              // Устанавливаем выбранную категорию со всеми подкатегориями
              setSelectedMainCategory(selectedCategory.mainCategory);
              setSelectedSubCategory(selectedCategory.subCategory);
              setSelectedSubSubCategory(selectedCategory.subSubCategory);

              // Выбираем два случайных бренда
              const randomBrands = [];
              const brandPool = [...selectedCategory.brands];
              while (randomBrands.length < 2 && brandPool.length > 0) {
                const randomBrandIndex = Math.floor(Math.random() * brandPool.length);
                randomBrands.push(brandPool[randomBrandIndex]);
                brandPool.splice(randomBrandIndex, 1);
              }

              setSelectedBrands(randomBrands);

              toast.success(`All brand pairs in ${categories[selectedMainCategory]?.name || 'the previous category'} compared! Moving to ${selectedCategory.displayName}`, {
                duration: 3000,
                position: 'top-center',
                icon: '🔄'
              });
            } else {
              toast.info("You've compared all available brand pairs! Great job!", {
                duration: 3000,
                position: 'top-center',
                icon: '🏆'
              });
            }
          }
        } else {
          // Обрабатываем другие типы ошибок
          console.error('Error details:', error.response?.data);
          toast.error(error.response?.data?.message || "An error occurred during brand comparison. Please try again.", {
            duration: 3000,
            position: 'top-center',
          });
        }

        setSelectedBrandIndex(null);
      }
    } else if (isTimerRunning) {
      toast.error("Please wait for the timer to finish before selecting a brand.", {
        duration: 3000,
        position: 'top-center',
      });
    } else {
      toast.error("No battles available. Please wait for battles to reset.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  }, [remainingBattles, userLocation, getFilteredBrands, selectedBrands, isTimerRunning, startTimer, setGlobalUser, userData, selectedMainCategory, brands, categories]);

  // Добавляем функцию для переключения на конечную категорию
  const switchToRandomEndCategory = useCallback(() => {
    // Собираем все возможные конечные категории
    const endCategories = [];

    // Обход всех категорий
    Object.entries(categories).forEach(([categoryId, category]) => {
      // Пропускаем текущую категорию
      if (categoryId === selectedMainCategory) return;

      // Проверяем наличие подкатегорий
      if (category.subCategories && category.subCategories.length > 0) {
        category.subCategories.forEach(subcategory => {
          // Проверяем наличие подподкатегорий
          if (subcategory.subSubCategories && subcategory.subSubCategories.length > 0) {
            // Добавляем каждую подподкатегорию как конечную
            subcategory.subSubCategories.forEach(subSubCategory => {
              endCategories.push({
                mainCategory: categoryId,
                mainCategoryName: category.name,
                subCategory: subcategory.name,
                subSubCategory: subSubCategory.name,
                displayName: `${category.name} > ${subcategory.name} > ${subSubCategory.name}`
              });
            });
          } else {
            // Если нет подподкатегорий, добавляем подкатегорию как конечную
            endCategories.push({
              mainCategory: categoryId,
              mainCategoryName: category.name,
              subCategory: subcategory.name,
              subSubCategory: '',
              displayName: `${category.name} > ${subcategory.name}`
            });
          }
        });
      } else {
        // Если нет подкатегорий, добавляем основную категорию как конечную
        endCategories.push({
          mainCategory: categoryId,
          mainCategoryName: category.name,
          subCategory: '',
          subSubCategory: '',
          displayName: category.name
        });
      }
    });

    console.log('Available end categories:', endCategories);

    // Если нет доступных категорий
    if (endCategories.length === 0) {
      toast.error("No more categories available with uncomparable brands", {
        duration: 3000,
        position: 'top-center',
      });
      return;
    }

    // Выбираем случайную конечную категорию
    const randomIndex = Math.floor(Math.random() * endCategories.length);
    const selectedEndCategory = endCategories[randomIndex];

    console.log('Selected end category:', selectedEndCategory);

    // Устанавливаем выбранную категорию
    setSelectedMainCategory(selectedEndCategory.mainCategory);
    setSelectedSubCategory(selectedEndCategory.subCategory);
    setSelectedSubSubCategory(selectedEndCategory.subSubCategory);

    // Информируем пользователя
    toast.error(`Switched to ${selectedEndCategory.displayName} category as you've compared all brands in current category`, {
      duration: 3000,
      position: 'top-center',
    });

    // Ищем бренды в новой категории
    let newCategoryBrands = [];

    // Фильтруем бренды на основе выбранной категории
    newCategoryBrands = brands.filter(brand => {
      if (!brand.categories || !Array.isArray(brand.categories)) return false;

      return brand.categories.some(cat => {
        // Проверяем соответствие основной категории
        const mainCategoryMatch = (cat.category?._id === selectedEndCategory.mainCategory) ||
          (cat.category === selectedEndCategory.mainCategory);

        // Если есть подкатегория, проверяем и её
        const subCategoryMatch = !selectedEndCategory.subCategory ||
          cat.subCategory === selectedEndCategory.subCategory;

        // Если есть подподкатегория, проверяем и её
        const subSubCategoryMatch = !selectedEndCategory.subSubCategory ||
          cat.subSubCategory === selectedEndCategory.subSubCategory;

        return mainCategoryMatch && subCategoryMatch && subSubCategoryMatch;
      });
    });

    console.log('Filtered brands for new category:', newCategoryBrands);

    if (newCategoryBrands.length >= 2) {
      const newPair = getTwoRandomBrands(newCategoryBrands);
      setSelectedBrands(newPair);
    } else {
      // Если в выбранной категории недостаточно брендов, рекурсивно пытаемся найти другую
      console.warn('Not enough brands in selected category, trying another one');
      switchToRandomEndCategory();
    }
  }, [brands, categories, selectedMainCategory, getTwoRandomBrands]);

  useEffect(() => {
    console.log('remainingBattles updated:', remainingBattles);
  }, [remainingBattles]);

  // Эффект для расчета уровня
  useEffect(() => {
    // Рассчитываем текущий уровень
    const currentLevel = calculateLevel(battlesWon);

    // Рассчитываем границы для отображения прогресса
    const { lowerBoundary, upperBoundary } = getLevelBoundaries(currentLevel);

    // Рассчитываем прогресс
    const battlesInCurrentLevel = battlesWon - lowerBoundary;
    const totalBattlesForCurrentLevel = upperBoundary - lowerBoundary;
    const progress = Math.min(100, Math.max(0, (battlesInCurrentLevel / totalBattlesForCurrentLevel) * 100));

    // Обновляем состояние
    setLevelProgress(progress);
    setLevel(currentLevel);

    console.log(`Текущий уровень: ${currentLevel}, Прогресс: ${progress.toFixed(1)}%`);
    console.log(`Битвы: ${battlesWon}, Граница текущего уровня: ${lowerBoundary}, Граница следующего уровня: ${upperBoundary}`);

    // Обновляем уровень на сервере, если он изменился
    if (userData && userData.level !== currentLevel) {
      console.log(`Обновляем уровень с ${userData.level} на ${currentLevel}`);

      // Используем ТОЛЬКО новый эндпоинт для обновления уровня
      axios.post('/api/users/update-level', {
        level: currentLevel
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      }).then(response => {
        console.log(`Уровень успешно обновлен на сервере: ${currentLevel}`);

        // Обновляем локальные данные
        setUserData(prevData => ({
          ...prevData,
          level: currentLevel
        }));

        setGlobalUser(prevUser => ({
          ...prevUser,
          level: currentLevel
        }));

        // Отправляем событие для синхронизации компонентов
        window.dispatchEvent(new CustomEvent('userUpdate', {
          detail: {
            level: currentLevel,
            battles: battlesWon,
            availableBattles: userData.availableBattles
          }
        }));
      }).catch(error => {
        console.error('Ошибка обновления уровня на сервере:', error);
      });
    }
  }, [battlesWon, userData, setGlobalUser]);

  // Функция расчета уровня остается прежней
  const calculateLevel = (battles) => {
    if (battles < 1000) return 1;

    // Уровень 1: 0-1000
    // Уровень 2: 1001-2200 (требуется 1200 битв)
    // Уровень 3: 2201-3600 (требуется 1400 битв)
    // Уровень 4: 3601-5200 (требуется 1600 битв)

    let level = 1;
    let totalBattles = 0;

    // Используем формулу для вычисления
    while (true) {
      const battlesForNextLevel = level === 1 ? 1000 : 1000 + (level - 1) * 200;
      totalBattles += battlesForNextLevel;

      if (battles <= totalBattles) {
        return level;
      }

      level++;
    }
  };

  const getLevelBoundaries = (level) => {
    let lowerBoundary = 0;
    let upperBoundary = 0;

    for (let i = 1; i <= level; i++) {
      const battlesForLevel = i === 1 ? 1000 : 1000 + (i - 1) * 200;
      upperBoundary += battlesForLevel;

      if (i < level) {
        lowerBoundary = upperBoundary;
      }
    }

    return { lowerBoundary, upperBoundary };
  };

  useEffect(() => {
    localStorage.setItem('remainingBattles', remainingBattles.toString());
  }, [remainingBattles]);

  useEffect(() => {
    const savedState = localStorage.getItem('userState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setScore(parsedState.score);
      setBattlesWon(parsedState.battlesWon);
      setRemainingBattles(parsedState.remainingBattles);
      setLevel(parsedState.level);
    }
  }, []);

  const handleRankUpgrade = async (rank) => {
    console.log('Attempting to upgrade rank:', rank);
    if (canActivateRank(rank)) {
      try {
        console.log('Sending request to update rank:', {
          rank: rank.name,
          coins: score - rank.requiredCoins,
          battleLimit: rank.battleLimit
        });
        const response = await axios.put('/api/users/me/update-rank', {
          rank: rank.name,
          coins: score - rank.requiredCoins,
          battleLimit: rank.battleLimit
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('Received response:', response.data);

        if (response.data.success) {
          const updatedUser = response.data.user;
          console.log('Rank upgrade successful, updating state with:', updatedUser);

          // Обновляем все состояния пользователя
          setUserData(updatedUser);
          setScore(updatedUser.coins);
          setRemainingBattles(updatedUser.availableBattles);
          setBattleLimit(updatedUser.battleLimit);
          setCurrentRank(rank.name);

          // Обновляем фоновое изображение
          if (rank.backgroundImage) {
            console.log('Updating background image to:', rank.backgroundImage);
            setBackgroundImage(rank.backgroundImage);
          } else {
            console.warn('No background image found for rank:', rank.name);
          }

          // Анимация и уведомление
          setShowUpgradeAnimation(true);
          setTimeout(() => setShowUpgradeAnimation(false), 5000);
          toast.success("Rank upgraded successfully!", {
            duration: 3000,
            position: 'top-center',
          });
        } else {
          console.log('Rank upgrade not successful:', response.data);
          toast.error(response.data.message || "Failed to upgrade rank", {
            duration: 3000,
            position: 'top-center',
          });
        }
      } catch (error) {
        console.error('Error upgrading rank:', error);
        console.error('Error details:', error.response ? error.response.data : 'No response data');
        toast.error(error.response?.data?.message || "Failed to upgrade rank. Please try again.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    } else {
      console.log('Cannot activate rank:', rank);
      toast.error("Cannot activate this rank at the moment.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [categoriesResponse, ranksResponse, tasksResponse, faqsResponse, countriesResponse, referralsResponse] = await Promise.all([
          axios.get('/api/categories'),
          axios.get('/api/ranks'),
          axios.get('/api/tasks'),
          axios.get('/api/faq'),
          axios.get('/api/countries'),
          axios.get('/api/referrals')
        ]);

        console.log('Fetched referrals:', referralsResponse.data);
        const sortedCategories = categoriesResponse.data.sort((a, b) => a.order - b.order);
        setCategories(sortedCategories);
        setRanks(ranksResponse.data);
        setTasks(tasksResponse.data);
        setFaqs(faqsResponse.data);
        setCountries(countriesResponse.data);
        setReferrals(referralsResponse.data);

      } catch (error) {
        console.error('Ошибка получения данных:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Функция получения категорий и фильтрации по наличию брендов
  const fetchCategories = useCallback(async () => {
    try {
      const [categoriesResponse, brandsResponse] = await Promise.all([
        axios.get('/api/categories'),
        axios.get('/api/brands')
      ]);

      const brandsList = brandsResponse.data.brands || [];
      const activesBrands = brandsList.filter(brand => brand.status === 'active');

      // Получаем ID категорий, в которых есть активные бренды
      const categoryIdsWithBrands = new Set();
      const subCategoriesWithBrands = new Map();

      activesBrands.forEach(brand => {
        if (brand.categories && Array.isArray(brand.categories)) {
          brand.categories.forEach(cat => {
            if (cat.category && cat.category._id) {
              categoryIdsWithBrands.add(cat.category._id.toString());

              // Сохраняем также подкатегории
              if (cat.subCategory) {
                if (!subCategoriesWithBrands.has(cat.category._id.toString())) {
                  subCategoriesWithBrands.set(cat.category._id.toString(), new Set());
                }
                subCategoriesWithBrands.get(cat.category._id.toString()).add(cat.subCategory);
              }
            }
          });
        }
      });

      // Фильтруем категории, оставляя только те, у которых есть активные бренды
      const categoriesWithBrands = categoriesResponse.data.filter(category =>
        categoryIdsWithBrands.has(category._id.toString())
      );

      // Фильтруем подкатегории для каждой категории
      const filteredCategories = categoriesWithBrands.map(category => {
        const activeSubCategories = subCategoriesWithBrands.get(category._id.toString());

        if (!activeSubCategories) {
          return category; // Возвращаем категорию без изменений
        }

        // Фильтруем подкатегории
        const filteredSubCategories = category.subCategories.filter(subCat =>
          activeSubCategories.has(subCat.name)
        );

        // Фильтруем под-подкатегории для каждой подкатегории
        const filteredSubCatsWithSubSub = filteredSubCategories.map(subCat => {
          if (!subCat.subSubCategories || subCat.subSubCategories.length === 0) {
            return subCat;
          }

          // Здесь можно добавить фильтрацию под-подкатегорий, если это необходимо
          // Но для простоты пока оставим все под-подкатегории

          return subCat;
        });

        return {
          ...category,
          subCategories: filteredSubCatsWithSubSub
        };
      });

      // Сортируем категории по полю order
      const sortedCategories = filteredCategories.sort((a, b) => a.order - b.order);

      // Преобразуем массив в объект для удобства использования
      const categoriesObject = sortedCategories.reduce((acc, category) => {
        if (category && category._id) {
          acc[category._id] = {
            ...category,
            subCategories: Array.isArray(category.subCategories) ? category.subCategories : []
          };
        }
        return acc;
      }, {});

      setCategories(categoriesObject);
    } catch (error) {
      console.error('Error fetching categories and brands:', error);
      setCategories({});
    }
  }, []);

  // Вызываем функцию при загрузке компонента
  useEffect(() => {
    fetchCategories();

    // Также загружаем остальные данные
    const fetchOtherData = async () => {
      setIsLoading(true);
      try {
        const [ranksResponse, tasksResponse, faqsResponse, countriesResponse, referralsResponse] = await Promise.all([
          axios.get('/api/ranks'),
          axios.get('/api/tasks'),
          axios.get('/api/faq'),
          axios.get('/api/countries'),
          axios.get('/api/referrals')
        ]);

        console.log('Fetched referrals:', referralsResponse.data);
        setRanks(ranksResponse.data);
        setTasks(tasksResponse.data);
        setFaqs(faqsResponse.data);
        setCountries(countriesResponse.data);
        setReferrals(referralsResponse.data);
      } catch (error) {
        console.error('Ошибка получения данных:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOtherData();
  }, [fetchCategories]);

  useEffect(() => {
    console.log('Current referrals state:', referrals);
  }, [referrals]);

  useEffect(() => {
    const checkAndUpdateBattles = async () => {
      try {
        const response = await axios.post('/api/users/update-battles', {}, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        if (response.data.success) {
          setRemainingBattles(response.data.availableBattles);
          setBattleLimit(response.data.battleLimit);
        }
      } catch (error) {
        console.error('Error updating battles:', error);
      }
    };

    checkAndUpdateBattles();

    // Проверяем обновление каждый час
    const interval = setInterval(checkAndUpdateBattles, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const savedRemainingBattles = localStorage.getItem('remainingBattles');
    if (savedRemainingBattles) {
      setRemainingBattles(parseInt(savedRemainingBattles, 10));
    }
  }, []);

  const getTopBrands = (limit = 5) => {
    const brandCounts = stats.brandsFaced.reduce((acc, brand) => {
      const brandName = brand.name;
      acc[brandName] = (acc[brandName] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(brandCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, limit)
      .map(([brand, count]) => ({ brand, count }));
  };

  const handleTaskCompletion = (task) => {
    if (task.type === 'referral') {
      setIsReferralDialogOpen(true);
    } else {
      setRemainingBattles(prev => prev + task.battleReward);
    }
  };

  const copyReferralLink = () => {
    console.log('copyReferralLink function called');
    console.log('Current referralLink:', referralLink);

    if (!referralLink) {
      console.log('Referral link is not available');
      toast.error("Referral link is not available", {
        duration: 3000,
        position: 'top-center',
      });
      return;
    }

    const textArea = document.createElement("textarea");
    textArea.value = referralLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      console.log('Referral link copied successfully');
      toast.success("Referral link copied!", {
        duration: 3000,
        position: 'top-center',
      });
    } catch (err) {
      console.error('Error during copy operation:', err);
      toast.error("Failed to copy link", {
        duration: 3000,
        position: 'top-center',
      });
    }
    document.body.removeChild(textArea);
  };

  const handleBrandInfo = (brand) => {
    setSelectedBrandInfo(brand);
  };

const LeaderboardTable = ({ data, period, handlePeriodChange }) => {
  // Используем useRef вместо useState для кеширования аватаров
  const avatarCacheRef = useRef({});
  
  // Мемоизируем обработанные данные вместо использования useState и useEffect
  const loadedData = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }
    
    // Ограничиваем и обогащаем данные только один раз
    const enhancedData = data.slice(0, 100).map(user => {
      if (!user) return user;
      
      // Создаем копию пользователя
      const enhancedUser = { ...user };
      
      // Если этот пользователь - текущий, обновляем данные из userData
      if (userData && user._id === userData._id) {
        enhancedUser.displayName = userData.displayName; 
        enhancedUser.level = userData.level;
        enhancedUser.rank = userData.rank || currentRank;
      }
      
      return enhancedUser;
    });
    
    return enhancedData;
    // Зависим только от необходимых данных
  }, [data, userData, currentRank]);
  
  // Мемоизируем обработчик смены вкладки, чтобы не создавать новую функцию при каждом рендере
  const handleTabChange = useCallback((value) => {
    // Вибрация только если период действительно изменился
    if (value !== period && navigator.vibrate) {
      navigator.vibrate(50);
    }
    
    // Вызываем обработчик только если период действительно изменился
    if (value !== period) {
      handlePeriodChange(value);
    }
  }, [period, handlePeriodChange]);

    // Функция получения имени пользователя с подробной проверкой и fallback-вариантами
    const getUserDisplayName = (user) => {
      if (!user) return 'User';

      // Логирование данных для отладки (можно убрать в production)
      if (process.env.NODE_ENV === 'development') {
        console.log('User data for display name:', {
          id: user._id,
          fields: Object.keys(user),
          displayName: user.displayName,
          name: user.name
        });
      }

      // Проверяем все возможные поля имени в порядке приоритета
      if (user.displayName) return user.displayName;

      // Собираем имя из firstName и lastName, если они есть
      const firstName = user.firstName || user.first_name;
      const lastName = user.lastName || user.last_name;

      if (firstName && lastName) return `${firstName} ${lastName}`;
      if (firstName) return firstName;

      // Проверяем различные варианты username
      if (user.telegramUsername) return user.telegramUsername;
      if (user.username) return user.username;
      if (user.name) return user.name;

      // Абсолютный fallback
      return 'User';
    };

    // Добавляем модификацию данных лидерборда при их получении
     useEffect(() => {
    if (data && data.length > 0 && process.env.NODE_ENV === 'development') {
      console.log('Leaderboard data loaded:', data.length);
    }
  }, []);

    // Рендеринг пользовательской строки выделен в отдельную функцию
    const renderUserRow = (user, index) => {
      if (!user) return null;

      // Получаем имя для отображения
      const displayName = getUserDisplayName(user);

      // Проверяем, является ли это текущим пользователем
      const isCurrentUser = userData && user._id === userData._id;

      // Всегда используем данные из объекта пользователя
      const userLevel = user.level || 1;
      const userRank = user.rank || 'Kettle';

      return (
        <div
          key={user._id || index}
          className={`flex items-center justify-between py-2 border-b border-black border-opacity-20 ${user.userLink ? 'cursor-pointer' : ''} ${isCurrentUser ? 'bg-yellow-100 bg-opacity-20' : ''}`}
          onClick={() => {
            if (user.userLink) {
              window.open(user.userLink, '_blank', 'noopener,noreferrer,nofollow');
            }
          }}
        >
          <div className="flex items-center flex-1 min-w-0 overflow-hidden">
            <span className="w-6 text-black font-bold mr-2 flex-shrink-0">
              {index + 1}
            </span>
            <Avatar className="w-8 h-8 mr-2 bg-gray-200 flex-shrink-0">
              {user.avatar ? (
                <AvatarImage
                  src={user.avatar}
                  alt={displayName}
                  className="object-cover"
                />
              ) : (
                <BoringAvatar
                  size={32}
                  name={displayName}
                  variant="beam"
                  colors={["#a1a6aa", "#bd928b", "#de7571", "#ff4e44", "#282634"]}
                />
              )}
              <AvatarFallback>
                <BoringAvatar
                  size={32}
                  name={displayName}
                  variant="beam"
                  colors={["#a1a6aa", "#bd928b", "#de7571", "#ff4e44", "#282634"]}
                />
              </AvatarFallback>
            </Avatar>
            <div className="flex flex-col min-w-0 overflow-hidden">
              <div className="font-medium text-black truncate" title={displayName}>
                {displayName}
              </div>
              <div className="text-xs text-gray-600 flex items-center">
                <span>Level {userLevel}</span>
                <span className="mx-1">•</span>
                <span>{userRank}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-shrink-0 ml-2">
            <span className="text-black font-bold mr-1">{user.coins.toLocaleString()}</span>
            <img src="/images/general/coin.svg" alt="coin" className="w-4 h-4" />
          </div>
        </div>
      );
    };
    // Проверяем данные перед рендерингом
    const renderLeaderboard = (users) => {
      if (!Array.isArray(users) || users.length === 0) {
        return <div className="p-4 text-center">No data available</div>;
      }

      return (
        <div className="space-y-2">
          {users.map((user, index) => renderUserRow(user, index))}
        </div>
      );
    };

    // Убедимся, что данные существуют перед рендерингом
    if (!Array.isArray(data) || data.length === 0) {
      return (
        <div className="p-4 text-center text-black">
          Loading leaderboard data...
        </div>
      );
    }

    return (
      <Tabs defaultValue={period} onValueChange={handleTabChange}>
        <div className="space-y-2">
          <TabsList className="w-full grid grid-cols-3 bg-black rounded-[30px] p-1">
            <TabsTrigger
              value="week"
              className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
            >
              Week
            </TabsTrigger>
            <TabsTrigger
              value="month"
              className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
            >
              Month
            </TabsTrigger>
            <TabsTrigger
              value="allTime"
              className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
            >
              All Time
            </TabsTrigger>
          </TabsList>
          <TabsContent value="week" className="mt-2">
            {renderLeaderboard(loadedData)}
          </TabsContent>
          <TabsContent value="month" className="mt-2">
            {renderLeaderboard(loadedData)}
          </TabsContent>
          <TabsContent value="allTime" className="mt-2">
            {renderLeaderboard(loadedData)}
          </TabsContent>
        </div>
      </Tabs>
    );
  };

  const fetchLeaderboardData = async (period) => {
    try {
      const response = await axios.get(`/api/users/leaderboard/${period}`);
      setLeaderboardData(response.data);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      toast.error("Failed to fetch leaderboard data", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

const handlePeriodChange = (period) => {
  // Проверяем, изменился ли период, чтобы избежать лишних запросов
  if (period !== leaderboardPeriod) {
    setLeaderboardPeriod(period);
    fetchLeaderboardData(period);
  }
};

  useEffect(() => {
    fetchLeaderboardData(leaderboardPeriod);
  }, []);

  const handleAddBrand = () => {
    // Логика добавления бренда
    console.log('Adding brand');
  };

  const handleAddTask = () => {
    // Логика добавления задачи
    console.log('Adding task');
  };

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handleTicketPurchase = async (ticketId) => {
    try {
      console.log('Attempting to purchase ticket:', ticketId);
      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');
      console.log('Token:', token ? 'Present' : 'Missing');
      console.log('Telegram Init Data:', telegramInitData ? 'Present' : 'Missing');

      const response = await axios.post(`/api/tickets/${ticketId}/purchase`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Telegram-Init-Data': telegramInitData
        }
      });

      console.log('Purchase response:', response.data);

      if (response.data.success) {
        const { user, ticket } = response.data;
        setUserData(prevData => ({
          ...prevData,
          coins: user.coins,
          tickets: user.tickets
        }));
        setScore(user.coins);

        setTickets(prevTickets => prevTickets.map(t =>
          t._id === ticket._id ? { ...t, soldTickets: ticket.soldTickets, totalTickets: ticket.totalTickets } : t
        ));

        toast.success('Ticket successfully purchased!', {
          duration: 3000,
          position: 'top-center',
        });
        return true;
      } else {
        console.error('Unexpected response structure:', response.data);
        throw new Error(response.data.message || 'Failed to purchase ticket');
      }
    } catch (error) {
      console.error('Error purchasing ticket:', error);

      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message === 'Insufficient coins') {
          toast.error('Not enough coins to buy a ticket', {
            duration: 3000,
            position: 'top-center',
          });
        } else if (error.response.data.message === 'All tickets are sold out') {
          toast.error('All tickets are sold out', {
            duration: 3000,
            position: 'top-center',
          });
        } else {
          console.error('Unexpected error:', error.response.data.message);
        }
      } else {
        console.error('Unexpected error:', error);
      }

      return false;
    }
  };

  useEffect(() => {
    const handleBalanceUpdate = async (event) => {
      console.log('Balance update event received:', event);
      if (!event.detail) return;

      try {
        console.log('Processing user update data...');

        // Получаем данные из event.detail
        const detailData = event.detail || {};

        // Обновляем локальный state с учетом всех возможных полей
        setUserData(prevData => {
          // Проверяем, если detailData - полный объект пользователя
          if (detailData._id || detailData.username) {
            return { ...prevData, ...detailData };
          }

          // Иначе обновляем только указанные поля
          const newData = {
            ...prevData,
            coins: detailData.coins !== undefined ? detailData.coins : prevData.coins,
            battles: detailData.battles !== undefined ? detailData.battles : prevData.battles,
            availableBattles: detailData.availableBattles !== undefined ? detailData.availableBattles : prevData.availableBattles,
            level: detailData.level !== undefined ? detailData.level : prevData.level,
            gender: detailData.gender !== undefined ? detailData.gender : prevData.gender,
            ageRange: detailData.ageRange !== undefined ? detailData.ageRange : prevData.ageRange,
            avatar: detailData.avatar || prevData.avatar
          };
          return newData;
        });

        // Проверяем, что setGlobalUser - функция, перед вызовом
        if (typeof setGlobalUser === 'function') {
          setGlobalUser(prevUser => {
            // Избегаем вызова null/undefined объектов
            if (!prevUser) prevUser = {};

            // Если у нас полный объект пользователя
            if (detailData._id || detailData.username) {
              const updatedUser = { ...prevUser, ...detailData };

              // Обновляем локальные состояния
              if (updatedUser.coins !== undefined) setScore(updatedUser.coins);
              if (updatedUser.battles !== undefined) setBattlesWon(updatedUser.battles || 0);
              if (updatedUser.availableBattles !== undefined) setRemainingBattles(updatedUser.availableBattles || 0);
              if (updatedUser.level !== undefined) setLevel(updatedUser.level || 1);

              return updatedUser;
            }

            // Обновляем только указанные поля
            const updatedUser = {
              ...prevUser,
              coins: detailData.coins !== undefined ? detailData.coins : prevUser.coins,
              battles: detailData.battles !== undefined ? detailData.battles : prevUser.battles,
              availableBattles: detailData.availableBattles !== undefined ? detailData.availableBattles : prevUser.availableBattles,
              level: detailData.level !== undefined ? detailData.level : prevUser.level,
              gender: detailData.gender !== undefined ? detailData.gender : prevUser.gender,
              ageRange: detailData.ageRange !== undefined ? detailData.ageRange : prevUser.ageRange,
              avatar: detailData.avatar || prevUser.avatar
            };

            // Обновляем локальные состояния
            if (updatedUser.coins !== undefined) setScore(updatedUser.coins);
            if (updatedUser.battles !== undefined) setBattlesWon(updatedUser.battles || 0);
            if (updatedUser.availableBattles !== undefined) setRemainingBattles(updatedUser.availableBattles || 0);
            if (updatedUser.level !== undefined) setLevel(updatedUser.level || 1);

            return updatedUser;
          });
        } else {
          console.warn('setGlobalUser is not a function:', typeof setGlobalUser);
        }
      } catch (error) {
        console.error('Error processing user update:', error);
      }
    };

    // Подписываемся на оба события
    window.addEventListener('balanceUpdate', handleBalanceUpdate);
    window.addEventListener('userUpdate', handleBalanceUpdate);

    return () => {
      window.removeEventListener('balanceUpdate', handleBalanceUpdate);
      window.removeEventListener('userUpdate', handleBalanceUpdate);
    };
  }, [setGlobalUser, setUserData, setScore, setBattlesWon, setRemainingBattles, setLevel]);

  useEffect(() => {
    // Собираем все URL изображений
    const imagesToPreload = [
      ...(ranks?.map(rank => rank.backgroundImage) || []),
      ...(videoTasks?.map(task => task.backgroundImage) || []),
      ...(socialTasks?.map(task => task.backgroundImage) || []),
      ...(specialTasks?.map(task => task.backgroundImage) || []),
      ...(referrals?.map(referral => referral.backgroundImage) || []),
      ...(tickets?.map(ticket => ticket.backgroundImage) || []), // Добавляем изображения билетов
      '/images/backgrounds/pass-background.jpg', // Добавляем фоновое изображение для Pass
      '/images/backgrounds/ticket-default.jpg' // Добавляем дефолтное изображение для билетов
    ].filter(Boolean);

    // Предзагружаем первые 10 изображений для оптимизации
    const priorityImages = imagesToPreload.slice(0, 10);

    return () => {
      // Cleanup если нужен
    };
  }, [ranks, videoTasks, socialTasks, specialTasks, referrals, tickets]);

  const handleAdTask = async (taskId) => {
    try {
      console.log('Starting ad task:', taskId);
      const task = specialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      // Проверяем дневной лимит для рекламных заданий перед отправкой запроса
      if (task.taskType === 'ad') {
        // Получаем историю просмотров этого задания за сегодня
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Фильтруем просмотры для текущего задания за сегодня
        const todayViews = userData.adViewHistory ? userData.adViewHistory.filter(view =>
          view.taskId === taskId &&
          new Date(view.viewedAt) >= today
        ) : [];

        console.log('Daily ad views count:', {
          taskId,
          totalViews: todayViews.length,
          dailyLimit: task.dailyAdClickLimit
        });

        // Проверяем, не превышен ли дневной лимит
        if (task.dailyAdClickLimit > 0 && todayViews.length >= task.dailyAdClickLimit) {
          console.log('Daily ad view limit reached:', {
            currentViews: todayViews.length,
            limit: task.dailyAdClickLimit
          });

          toast.error("You've reached your daily reward limit. Come back tomorrow for more battles", {
            duration: 3000,
            position: 'top-center',
            icon: '🕒'
          });

          return; // Прекращаем выполнение функции
        }
      }

      // Проверяем доступность контроллера рекламы
      const hasAdsController = typeof window.TelegramAdsController !== 'undefined';
      console.log('TelegramAdsController available:', hasAdsController);

      // Проверяем доступность методов
      if (hasAdsController) {
        console.log('TelegramAdsController initialized:', typeof window.TelegramAdsController.initialize === 'function');
        console.log('triggerInterstitialBanner available:', typeof window.TelegramAdsController.triggerInterstitialBanner === 'function');
        console.log('triggerNativeNotification available:', typeof window.TelegramAdsController.triggerNativeNotification === 'function');

        if (typeof window.TelegramAdsController.initialize === 'function') {
          try {
            window.TelegramAdsController.initialize({
              pubId: "962094",
              appId: "1554",
              debug: true,
              disableBanner: true // Отключаем баннерную рекламу
            });
            console.log('TelegramAdsController re-initialized successfully');
          } catch (initError) {
            console.error('TelegramAdsController re-initialization error:', initError);
          }
        }
      }

      // Регистрируем клик на задание
      const response = await axios.post(`/api/special-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      if (response.data.success) {
        // Обновляем статус задания на "ad_showing"
        setSpecialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'ad_showing', adWatched: false } : t
          )
        );

        if (task.taskType === 'ad') {
          if (hasAdsController) {
            try {
              // Глобальная переменная для отслеживания статуса награды
              window.rewardGiven = false;

              // Создаем единую функцию обработки закрытия
              const handleAdClosed = () => {
                if (window.rewardGiven) return;
                window.rewardGiven = true;

                console.log('Ad event detected - waiting 12 seconds before giving reward');

                // Отдельная функция для выдачи награды с задержкой в 12 секунд
                const giveReward = () => {
                  // Показываем индикатор загрузки
                  setSpecialTasks(prevTasks =>
                    prevTasks.map(t =>
                      t._id === taskId ? { ...t, status: 'ad_complete', adWatched: true } : t
                    )
                  );

                  // Задержка в 12 секунд перед выдачей награды
                  setTimeout(() => {
                    console.log('Giving reward after 12 seconds delay');

                    // Обновляем данные пользователя перед выдачей награды
                    axios.get('/api/users/me', {
                      headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
                      }
                    }).then(userResponse => {
                      setUserData(userResponse.data);

                      // Выдаем награду
                      setSpecialTasks(prevTasks =>
                        prevTasks.map(t =>
                          t._id === taskId ? { ...t, status: 'not_started', adWatched: false } : t
                        )
                      );

                      // Вызываем API для получения награды
                      handleClaimAdTask(taskId);
                    }).catch(error => {
                      console.error('Error refreshing user data:', error);

                      // Выдаем награду даже при ошибке обновления данных
                      setSpecialTasks(prevTasks =>
                        prevTasks.map(t =>
                          t._id === taskId ? { ...t, status: 'not_started', adWatched: false } : t
                        )
                      );

                      handleClaimAdTask(taskId);
                    });
                  }, 12000);
                };

                // Запускаем процесс выдачи награды
                giveReward();
              };

              // Функция для установки обработчиков событий
              const setupEventListeners = () => {
                // Используем единый обработчик для всех событий с задержкой
                const eventHandler = () => {
                  handleAdClosed();
                };

                // Удаляем предыдущие обработчики, если есть
                window.removeEventListener('focus', eventHandler);
                document.removeEventListener('visibilitychange', eventHandler);

                // Устанавливаем новые обработчики
                window.addEventListener('focus', eventHandler, { once: true });

                // Отложенный запуск проверки на visibilitychange
                setTimeout(() => {
                  if (document.visibilityState === 'visible' && !window.rewardGiven) {
                    handleAdClosed();
                  }
                }, 1000);

                // Страховочный таймер - выдаем награду через 15 секунд, если ничего не произошло
                setTimeout(() => {
                  if (!window.rewardGiven) {
                    console.log('Backup timer triggered - giving reward after 15 seconds');
                    handleAdClosed();
                  }
                }, 15000);
              };

              if (typeof window.TelegramAdsController.triggerInterstitialBanner === 'function') {
                console.log('Showing interstitial ad...');

                setupEventListeners();

                window.TelegramAdsController.triggerInterstitialBanner()
                  .then((result) => {
                    console.log('Ad displayed successfully:', result);

                    // Запускаем таймер для автоматической обработки через 2 секунды после успешного показа
                    setTimeout(() => {
                      if (!window.rewardGiven) {
                        console.log('Auto-triggering reward after ad displayed');
                        handleAdClosed();
                      }
                    }, 2000);
                  })
                  .catch((error) => {
                    console.error('Error displaying ad:', error);

                    // Reset task status
                    setSpecialTasks(prevTasks =>
                      prevTasks.map(t =>
                        t._id === taskId ? { ...t, status: 'not_started' } : t
                      )
                    );

                    toast.error("Failed to load ad. Please try again later.", {
                      duration: 3000,
                      position: 'top-center',
                    });
                  });
              } else {
                console.log('Trying native notification method...');

                setupEventListeners();

                window.TelegramAdsController.triggerNativeNotification()
                  .then((result) => {
                    console.log('Native ad displayed successfully:', result);

                    // Запускаем таймер для автоматической обработки через 2 секунды после успешного показа
                    setTimeout(() => {
                      if (!window.rewardGiven) {
                        console.log('Auto-triggering reward after native ad displayed');
                        handleAdClosed();
                      }
                    }, 2000);
                  })
                  .catch((error) => {
                    console.error('Error displaying native ad:', error);

                    // Reset task status
                    setSpecialTasks(prevTasks =>
                      prevTasks.map(t =>
                        t._id === taskId ? { ...t, status: 'not_started' } : t
                      )
                    );

                    toast.error("Failed to load ad. Please try again later.", {
                      duration: 3000,
                      position: 'top-center',
                    });
                  });
              }
            } catch (adError) {
              console.error('Error calling ad:', adError);

              // Reset task status
              setSpecialTasks(prevTasks =>
                prevTasks.map(t =>
                  t._id === taskId ? { ...t, status: 'not_started' } : t
                )
              );

              toast.error("An error occurred while displaying the ad. Please try again later.", {
                duration: 3000,
                position: 'top-center',
              });
            }
          } else {
            // If controller is unavailable, reset status and show error
            setSpecialTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, status: 'not_started' } : t
              )
            );

            toast.error("Ad controller is unavailable. Please try again later.", {
              duration: 3000,
              position: 'top-center',
            });
          }
        } else {
          // For non-ad tasks, continue normal flow
          window.open(task.specialUrl, '_blank');
          setSpecialTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, status: 'in_progress' } : t
            )
          );
          toast.success("Task started successfully!", {
            duration: 3000,
            position: 'top-center',
          });
        }
      } else if (response.data.errorType === 'DAILY_LIMIT_REACHED') {
        // Отображаем специальное сообщение для превышения дневного лимита
        toast.error("You've reached your daily reward limit. Come back tomorrow for more battles", {
          duration: 3000,
          position: 'top-center',
          icon: '🕒'
        });

        // Обновляем UI, чтобы показать, что лимит достигнут
        setSpecialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, dailyLimitReached: true } : t
          )
        );
      } else {
        toast.error(response.data.message || "Failed to start task. Please try again later.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    } catch (error) {
      console.error('Error processing ad task:', error);

      // Проверяем на ошибку лимита в ответе сервера
      if (error.response && error.response.data && error.response.data.errorType === 'DAILY_LIMIT_REACHED') {
        toast.error("You've reached your daily reward limit. Come back tomorrow for more battles", {
          duration: 3000,
          position: 'top-center',
          icon: '🕒'
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    }
  };

  // Функция для получения награды после просмотра рекламы
  const handleClaimAdTask = async (taskId) => {
    try {
      console.log('Получение награды за рекламное задание:', taskId);

      // Обновляем статус на ad_complete, если еще не обновлен
      setSpecialTasks(prevTasks =>
        prevTasks.map(t =>
          t._id === taskId && (t.status === 'ad_showing' || t.status === 'in_progress')
            ? { ...t, status: 'ad_complete' }
            : t
        )
      );

      const task = specialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      // Получаем текущие значения
      const battlesReward = task.battlesReward || 0;
      const currentBattles = userData.availableBattles || 0;
      const battleLimit = userData.battleLimit || 50;

      // Рассчитываем, сколько битв можно добавить, не превышая лимит
      const actualBattlesReward = Math.min(battlesReward, battleLimit - currentBattles);

      console.log('Расчёт начисления битв:', {
        battlesReward,
        currentBattles,
        battleLimit,
        actualBattlesReward
      });

      const response = await axios.post(`/api/special-tasks/${taskId}/claim`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      if (response.data.success) {
        const { updatedUser, reward } = response.data;

        // ВАЖНО: используем свои расчёты, а не данные с сервера
        const correctBattlesCount = Math.min(currentBattles + actualBattlesReward, battleLimit);

        console.log('Обновление битв:', {
          currentBattles,
          serverReturned: updatedUser.availableBattles,
          correctValue: correctBattlesCount,
          addedBattles: actualBattlesReward
        });

        // Обновляем данные пользователя с правильным значением
        setUserData(prevData => ({
          ...prevData,
          coins: updatedUser.coins,
          availableBattles: correctBattlesCount, // Используем локальный расчёт
          completedSpecialTasks: updatedUser.completedSpecialTasks
        }));

        // Обновляем глобальное состояние пользователя
        setGlobalUser(prevUser => ({
          ...prevUser,
          coins: updatedUser.coins,
          availableBattles: correctBattlesCount, // Используем локальный расчёт
          completedSpecialTasks: updatedUser.completedSpecialTasks
        }));

        // Обновляем локальное состояние для UI
        setScore(updatedUser.coins);
        setRemainingBattles(correctBattlesCount);

        // Отмечаем задание как завершенное
        setSpecialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'completed' } : t
          )
        );

        // Используем только toast, не показываем диалог
        toast.success(`Battles received: +${actualBattlesReward}`, {
          duration: 3000,
          position: 'top-center',
          icon: '⚡'
        });

        // Отправляем событие обновления баланса с корректным значением
        window.dispatchEvent(new CustomEvent('balanceUpdate', {
          detail: {
            coins: updatedUser.coins,
            battles: updatedUser.battles,
            availableBattles: updatedUser.availableBattles,
            level: updatedUser.level
          }
        }));

        // Исправляем ошибку с toast уведомлением
        if (actualBattlesReward < battlesReward && correctBattlesCount >= battleLimit) {
          setTimeout(() => {
            toast.info(`Your battles are now at maximum capacity (${battleLimit}/${battleLimit})`, {
              duration: 3000,
              position: 'top-center',
            });
          }, 3000);
        }
      } else {
        console.error('Failed to get reward:', response.data);
        toast.error("Failed to get reward. Please try again.", {
          duration: 3000,
          position: 'top-center',
        });

        // Сбрасываем статус задания
        setSpecialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'not_started' } : t
          )
        );
      }
    } catch (error) {
      console.error('Error getting reward for ad task:', error);
      toast.error("Failed to get reward. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });

      // Сбрасываем статус задания
      setSpecialTasks(prevTasks =>
        prevTasks.map(t =>
          t._id === taskId ? { ...t, status: 'not_started' } : t
        )
      );
    }
  };

  return (
    <div className="app-container flex flex-col min-h-screen overflow-x-hidden overflow-y-auto text-white relative pb-0">
      {/* Интеграция StoriesContainer прямо здесь, на верхнем уровне */}
      {storiesLoaded && (
        <StoriesContainer
          isOpen={showStories}
          initialStoryIndex={activeStoryIndex}
          onClose={() => setShowStories(false)}
          userData={userData} // Передаем пользователя в компонент
        />
      )}
      <PreloadImages
        images={[
          ...(ranks?.map(rank => rank.backgroundImage) || []),
          ...(videoTasks?.map(task => task.backgroundImage) || []),
          ...(referrals?.map(referral => referral.backgroundImage) || [])
        ].filter(Boolean).slice(0, 10)} // Берем только первые 10 изображений
      />
      <AnimatedBackground />

      {/* Stories Reel - отображение ленты с аватарами историй */}
      {showStoriesReel && storiesLoaded && stories && stories.length > 0 && (
        <div className="fixed top-0 left-0 right-0 z-[9998] bg-black bg-opacity-90 transform-gpu animate-slideDown">
          <div className="w-full overflow-x-auto py-4 no-scrollbar">
            <div className="flex space-x-4 px-4">
              {stories.map((story, index) => (
                <div
                  key={story.id || index}
                  onClick={() => {
                    setActiveStoryIndex(index);
                    setShowStories(true);
                    setShowStoriesReel(false);
                  }}
                  className="flex flex-col items-center cursor-pointer"
                >
                  <div className={`w-16 h-16 rounded-full p-1 ${story.viewedByCurrentUser ? 'bg-zinc-400' : 'bg-gradient-to-tr from-yellow-400 to-pink-500'}`}>
                    <img
                      src={story.items[0]?.url || '/images/general/default-ticket-bg.jpg'}
                      alt={story.title}
                      className="w-full h-full object-cover rounded-full border-2 border-white"
                      onError={(e) => { e.target.src = '/images/general/default-ticket-bg.jpg' }}
                    />
                  </div>
                  <span className="text-xs mt-1 text-zinc-200 truncate w-16 text-center">
                    {story.title}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => setShowStoriesReel(false)}
            className="absolute top-2 right-2 text-white p-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}

      <div className="w-full relative p-4" style={{ zIndex: 1 }}>
        <div
          className="rounded-[30px] relative"
          style={{
            backgroundImage: `url(${backgroundImage || '/default-background.webp'})`,
            backgroundSize: 'cover',
            padding: '1rem',
            position: 'relative',
            zIndex: 1
          }}
        >

          {/* Эффекты ранга - рендерятся напрямую */}
          {(() => {
            const currentRankObj = ranks.find(r => r.name === currentRank);
            if (!currentRankObj) return null;

            return (
              <>
                {/* Фоновые частицы - с ОЧЕНЬ высоким z-index */}
                {currentRankObj.effects?.backgroundParticles?.enabled && (
                  <div className="absolute inset-0 z-[15] overflow-hidden rounded-[30px]" style={{ pointerEvents: 'none' }}>
                    {Array.from({ length: currentRankObj.effects.backgroundParticles.density * 3 || 10 }).map((_, i) => (
                      <div
                        key={`bg-particle-${i}`}
                        className="absolute"
                        style={{
                          left: `${Math.random() * 80 + 10}%`,
                          top: `${Math.random() * 80 + 10}%`,
                          color: currentRankObj.effects.backgroundParticles.color || '#FFFFFF',
                          opacity: Math.random() * 0.7 + 0.3,
                          animation: `float${i % 3} ${Math.random() * 3 + 2}s infinite ease-in-out`,
                          transform: `translateY(0px) scale(${Math.random() * 0.4 + 0.3})`,
                          zIndex: 15,
                          pointerEvents: 'none',
                          width: '12px',
                          height: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {currentRankObj.effects.backgroundParticles.type === 'sparkles' && (
                          <svg width="10" height="10" viewBox="0 0 24 24" fill="currentColor" className="animate-spin-slow">
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                          </svg>
                        )}
                        {currentRankObj.effects.backgroundParticles.type === 'bubbles' && (
                          <svg width="8" height="8" viewBox="0 0 24 24" fill="currentColor" className="animate-pulse-slow">
                            <circle cx="12" cy="12" r="10" />
                          </svg>
                        )}
                        {currentRankObj.effects.backgroundParticles.type === 'stars' && (
                          <svg width="12" height="12" viewBox="0 0 24 24" fill="currentColor" className="animate-twinkle">
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                          </svg>
                        )}
                        {currentRankObj.effects.backgroundParticles.type === 'fire' && (
                          <svg width="12" height="12" viewBox="0 0 24 24" fill="currentColor" className="animate-flicker">
                            <path d="M12 2C10.97 2 9.94 2.16 8.92 2.5C9.61 3.43 10 4.63 10 5.9C10 8.15 8.21 10 6 10C5.07 10 4.21 9.72 3.5 9.22C3.18 10.13 3 11.05 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 6.42 16.97 2 12 2Z" />
                          </svg>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {/* Фоновый эффект */}
                {currentRankObj.effects?.backgroundEffect?.enabled && (
                  <div className="absolute inset-0 z-[3] rounded-[30px]" style={(() => {
                    const effect = currentRankObj.effects.backgroundEffect;
                    const style = { pointerEvents: 'none' };

                    switch (effect.type) {
                      case 'blur':
                        style.backdropFilter = `blur(${effect.intensity}px)`;
                        style.WebkitBackdropFilter = `blur(${effect.intensity}px)`;
                        break;
                      case 'overlay':
                        if (effect.useGradient && effect.gradientColors?.length >= 2) {
                          style.background = `linear-gradient(${effect.gradientDirection || 'to bottom'}, ${effect.gradientColors.join(', ')})`;
                        } else {
                          style.backgroundColor = effect.color || 'rgba(0,0,0,0.5)';
                          style.opacity = effect.intensity / 10;
                        }
                        break;
                      case 'glow':
                        style.boxShadow = `inset 0 0 ${effect.intensity * 5}px ${effect.color || '#FFFFFF'}`;
                        style.opacity = 0.8;
                        break;
                      case 'tint':
                        style.backgroundColor = effect.color || 'rgba(0,0,0,0.5)';
                        style.opacity = effect.intensity / 10;
                        style.mixBlendMode = 'color';
                        break;
                      case 'gradient':
                        if (effect.gradientColors?.length >= 2) {
                          style.background = `linear-gradient(${effect.gradientDirection || 'to bottom'}, ${effect.gradientColors.join(', ')})`;
                        } else {
                          style.background = `linear-gradient(to bottom, transparent, ${effect.color || 'rgba(0,0,0,0.8)'})`;
                        }
                        break;
                    }

                    // Добавляем анимацию при необходимости
                    if (effect.animation?.enabled) {
                      style.animation = `${effect.animation.type || 'pulse'}-bg ${effect.animation.duration || 3}s infinite`;
                    }

                    return style;
                  })()}>
                  </div>
                )}
              </>
            );
          })()}

          {/* Основной контент */}
          <div className="relative z-[20] flex justify-between items-start w-full mb-4">
            <div className="flex items-center space-x-4 flex-1 mr-2 pr-2 overflow-hidden pl-2.5">
              <div className="relative flex-shrink-0" style={{ zIndex: 20 }}>
                <UserAvatar
                  user={userData}
                  currentRank={currentRank}
                  ranks={ranks}
                  size="default"
                />
              </div>
              <div className="min-w-0 flex-1 overflow-hidden">
                <div
                  className="w-full overflow-hidden text-ellipsis whitespace-nowrap"
                  title={userData.displayName || userData.firstName || userData.username || 'User'}
                >
                  <h1
                    className="text-2xl font-bold text-white inline-block max-w-full overflow-hidden text-ellipsis"
                    style={{
                      ...(ranks.find(r => r.name === currentRank)?.effects?.nameEffect?.enabled
                        ? getNameEffectStyle(ranks.find(r => r.name === currentRank)?.effects?.nameEffect)
                        : {}),
                      maxWidth: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {userData.displayName || userData.firstName || userData.username || 'User'}
                  </h1>
                </div>
                <p className="text-sm font-bold text-gray-300 mt-1">{currentRank || 'Kettle'}</p>
                <p className="text-xl font-bold text-white flex items-center mt-1">
                  {score !== undefined ? (
                    <>
                      {Math.floor(score)}
                      <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                    </>
                  ) : '0'}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-end flex-shrink-0">
              <Button className="w-10 h-10 rounded-full p-0 overflow-hidden bg-transparent" onClick={toggleProfile}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </Button>

              <Button
                className="w-10 h-10 rounded-full p-0 overflow-hidden bg-transparent relative -mt-1"
                onClick={() => {
                  setShowStories(true);
                  setActiveStoryIndex(0);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className="absolute -top-1 -right-1 w-3 h-3 bg-[#e0ff89] rounded-full animate-pulse"></span>
              </Button>
            </div>
          </div>

          {/* Прогресс-бар уровня */}
          <div className="relative z-[20] w-full bg-black bg-opacity-70 rounded-full h-3 mt-2">
            <div
              className="absolute left-0 top-0 bottom-0 rounded-full transition-all duration-300 ease-in-out"
              style={{
                width: `${levelProgress}%`,
                backgroundColor: ranks.find(r => r.name === currentRank)?.ui?.progressBarColor || '#e0ff89'
              }}
            ></div>
            <div className="absolute inset-0 flex items-center justify-center text-xs font-medium">
              <span className="text-white mix-blend-difference">Level {userData.level}: {Math.floor(levelProgress)}%</span>
            </div>
          </div>
        </div>

        {/* Стили анимации */}
        <style jsx global>{`
        @keyframes shine {
  100% {
    transform: translateX(100%) skew-x-[-15deg];
  }
}

.animate-shine {
  animation: shine 2s infinite;
}

@keyframes pulse-infinite {
    0%, 100% { opacity: 1; transform: scale(1); }
    50% { opacity: 0.7; transform: scale(1.1); }
  }
  
  @keyframes wave-infinite {
    0%, 100% { transform: scale(1.0); }
    25% { transform: scale(1.05) rotate(5deg); }
    50% { transform: scale(1.1); }
    75% { transform: scale(1.05) rotate(-5deg); }
  }
  
  @keyframes rings-infinite {
    0% { transform: scale(1.0); opacity: 1; }
    50% { transform: scale(1.2); opacity: 0; }
    51% { transform: scale(1.0); opacity: 1; }
    100% { transform: scale(1.0); opacity: 1; }
  }
  
  .animate-pulse-infinite {
    animation: pulse-infinite 2s infinite;
  }
  
  .animate-wave-infinite {
    animation: wave-infinite 3s infinite;
  }
  
  .animate-rings-infinite {
    animation: rings-infinite 3s infinite;
  }
  
  @keyframes float0 {
    0%, 100% { transform: translateY(0px); }
    50% { transform: translateY(-10px) rotate(5deg); }
  }
  
  @keyframes float1 {
    0%, 100% { transform: translateY(0px); }
    50% { transform: translateY(-15px) rotate(-5deg); }
  }
  
  @keyframes float2 {
    0%, 100% { transform: translateY(0px) rotate(0deg); }
    50% { transform: translateY(-8px) rotate(10deg); }
  }
  
  @keyframes pulse-bg {
    0%, 100% { opacity: 0.7; }
    50% { opacity: 0.4; }
  }
  
  @keyframes fade-bg {
    0%, 100% { opacity: 0.7; }
    50% { opacity: 0; }
  }
  
  @keyframes wave-bg {
    0% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
  }
  
  @keyframes twinkle {
    0%, 100% { opacity: 1; transform: scale(1); }
    50% { opacity: 0.7; transform: scale(1.2); }
  }
  
  @keyframes flicker {
    0%, 100% { opacity: 1; }
    25% { opacity: 0.8; }
    50% { opacity: 0.9; }
    75% { opacity: 0.7; }
  }
  
  @keyframes spin-slow {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  @keyframes pulse-slow {
    0%, 100% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.1); opacity: 0.8; }
  }
  
  @keyframes shine-bg {
    0%, 100% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
  }
  
  .animate-twinkle {
    animation: twinkle 3s infinite ease-in-out;
  }
  
  .animate-flicker {
    animation: flicker 2s infinite;
  }
  
  .animate-spin-slow {
    animation: spin-slow 10s linear infinite;
  }
  
  .animate-pulse-slow {
    animation: pulse-slow 3s infinite ease-in-out;
  }
  
  .animate-particle {
    will-change: transform;
    transform-style: preserve-3d;
  }
`}</style>
      </div>
      <div className={`fixed inset-0 bg-black z-[60] transition-transform duration-300 ease-in-out ${isProfileOpen ? 'translate-y-0' : 'translate-y-full'}`}>
        <div className="h-full overflow-y-auto scrollbar-hide">
          <div className="flex justify-between items-center p-4">
            <h2 className="text-xl font-bold text-white">My Account</h2>
            <Button onClick={toggleProfile} className="text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Button>
          </div>
          <UserProfile
            user={userData}
            onProfileUpdate={(updatedUser) => {
              setUserData(updatedUser);
              // Создаем событие для обновления UI
              window.dispatchEvent(new CustomEvent('userUpdate', {
                detail: {
                  avatar: updatedUser.avatar
                }
              }));
            }}
          />
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-4 mb-24">
        <CardContent className="space-y-4 pt-0">
          <Dialog open={isCountryDialogOpen} onOpenChange={setIsCountryDialogOpen}>
            <DialogContent className="bg-black rounded-[30px] p-6">
              <DialogHeader>
                <DialogTitle className="text-white">Select Country</DialogTitle>
              </DialogHeader>
              <div className="space-y-4">
                <Button
                  className={`w-full h-6 flex items-center justify-center space-x-2 rounded-[30px] transition-colors ${selectedCountry === 'Global'
                    ? 'bg-[#e0ff89] text-black'
                    : 'bg-zinc-800 text-white hover:bg-[#e0ff89] hover:text-black'
                    }`}
                  onClick={() => {
                    handleCountryChange('Global');
                    setIsCountryDialogOpen(false);
                  }}
                >
                  <span role="img" aria-label="Globe">🌐</span>
                  <span>Global</span>
                </Button>
                <div className="grid grid-cols-2 gap-4">
                  {availableCountries.map((country) => (
                    <Button
                      key={country.code}
                      className={`flex items-center space-x-2 rounded-[30px] transition-colors h-6 ${selectedCountry === country.code
                        ? 'bg-[#e0ff89] text-black'
                        : 'bg-zinc-800 text-white hover:bg-[#e0ff89] hover:text-black'
                        }`}
                      onClick={() => {
                        handleCountryChange(country.code);
                        setIsCountryDialogOpen(false);
                      }}
                    >
                      <ReactCountryFlag countryCode={country.code} svg />
                      <span>{country.name}</span>
                    </Button>
                  ))}
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <div
            className={`fixed inset-0 z-50 ${isCategoryDialogOpen ? '' : 'pointer-events-none'}`}
          >
            <div
              className={`fixed bottom-0 left-0 right-0 bg-black rounded-t-3xl overflow-hidden flex flex-col`}
              style={{
                transform: isCategoryDialogOpen ? 'translateY(0)' : 'translateY(100%)',
                transition: 'transform 0.3s ease-in-out',
                height: 'calc(100% - 4rem)'
              }}
            >
              <div className="h-6 w-full bg-black cursor-grab active:cursor-grabbing drag-handle">
                <div className="h-1 w-16 bg-white rounded-full mx-auto mt-2"></div>
              </div>
              <div className="flex-1 overflow-y-auto px-4 py-2 scrollbar-hide">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold text-white">Categories</h2>
                  <button
                    onClick={() => setIsCategoryDialogOpen(false)}
                    className="text-white cursor-pointer focus:outline-none"
                  >
                    <X size={20} />
                  </button>
                </div>
                <div className="relative pb-24">
                  <div className="space-y-[-60px]">
                    {Object.entries(categories)
                      .sort(([, a], [, b]) => a.order - b.order)
                      // Добавляем фильтр: показываем только категории с брендами
                      .filter(([categoryId, category]) => {
                        // Проверяем, есть ли у этой категории подкатегории с брендами
                        return category.subCategories && category.subCategories.length > 0;
                      })
                      .map(([categoryId, category]) => {
                        const isExpanded = selectedMainCategory === category._id;
                        return (
                          <div
                            key={category._id}
                            className="relative transition-all duration-300 ease-in-out"
                            style={{
                              zIndex: category.order + 1,
                            }}
                          >
                            <div
                              className={`rounded-[30px] transition-all duration-300 ease-in-out cursor-pointer ${isExpanded ? '' : 'pb-12'}`}
                              style={{
                                backgroundColor: category.color,
                                color: category.textColor,
                                boxShadow: '0 -7px 20px 0 rgba(0, 0, 0, 0.3)',
                              }}
                            >
                              <div
                                className="flex justify-between items-center p-6"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCategorySelect(category);
                                }}
                              >
                                <div className="flex items-center space-x-3">
                                  {(() => {
                                    const IconComponent = category.icon ? LucideIcons[category.icon] : LucideIcons.Folder;
                                    return <IconComponent size={48} />;
                                  })()}
                                  <div>
                                    <h3 className="font-bold text-2xl">{category.name}</h3>
                                    <p className="text-base">
                                      {category.subCategories.length} Subcategories
                                    </p>
                                  </div>
                                </div>
                                <ChevronRight
                                  size={20}
                                  className={`transition-transform duration-300 ${isExpanded ? 'rotate-90' : ''}`}
                                />
                              </div>
                              <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px]' : 'max-h-0'}`}
                              >
                                <div className="space-y-2 p-4 pt-0 pb-20">
                                  {category.subCategories.map((subCategory, subIndex) => (
                                    <div key={subIndex} className="space-y-2">
                                      <div
                                        className="bg-white bg-opacity-50 rounded-[30px] pl-4 pb-2 pr-4 pt-2 text-base cursor-pointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleSubCategorySelect(subCategory);
                                        }}
                                      >
                                        <div className="flex justify-between items-center">
                                          <span>{subCategory.name}</span>
                                          {subCategory.subSubCategories &&
                                            subCategory.subSubCategories.length > 0 && (
                                              <ChevronDown
                                                size={16}
                                                className={`transition-transform duration-300 ${selectedSubCategory === subCategory.name ? 'rotate-180' : ''}`}
                                              />
                                            )}
                                        </div>
                                      </div>

                                      {selectedSubCategory === subCategory.name &&
                                        subCategory.subSubCategories &&
                                        subCategory.subSubCategories.length > 0 && (
                                          <div className="pl-4 space-y-1">
                                            {subCategory.subSubCategories.map((subSubCategory, subSubIndex) => (
                                              <div
                                                key={subSubIndex}
                                                className={`bg-white bg-opacity-30 rounded-[30px] pl-4 pb-2 pr-4 pt-2 text-sm cursor-pointer ${selectedSubSubCategory === subSubCategory.name ? 'bg-opacity-70' : ''}`}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleSubSubCategorySelect(subSubCategory);
                                                }}
                                              >
                                                {subSubCategory.name}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-0">
            <h2 className="text-2xl font-bold text-white">Battle Brands</h2>
          </div>
          <div className="flex flex-col items-center mt-2">
            <div className="flex justify-center items-center w-full mb-4">
              {selectedBrands.slice(0, 2).map((brand, index) => (
                <React.Fragment key={brand._id}>
                  {index === 1 && (
                    <div className="flex flex-col items-center mx-4" key="vs-container">
                      <img src="/images/general/vs.svg" alt="VS" className="w-10 h-10" />
                      <div className="h-8 flex items-center justify-center">
                        {isTimerRunning && (
                          <div className={`text-lg font-bold text-white bg-black rounded-full w-6 h-6 flex items-center justify-center transition-opacity duration-500 ${showGo ? 'opacity-0' : 'opacity-100'}`}>
                            {timer > 0 ? timer : 'GO!'}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col items-center relative" style={{ width: 'calc(50% - 2rem)' }}>
                    <div className="relative">
                      <BrandAvatar
                        brand={brand}
                        isSelected={selectedBrandIndex === index}
                        onClick={() => handleBrandSelect(brand, index)}
                        remainingBattles={remainingBattles}
                      >
                        <Avatar className="w-24 h-24">
                          <AvatarImage
                            src={brand.logo || `https://logo.clearbit.com/${brand.name.toLowerCase().replace(/\s+/g, '')}.com`}
                            alt={brand.name}
                          />
                          <AvatarFallback>{brand.name.charAt(0)}</AvatarFallback>
                        </Avatar>
                      </BrandAvatar>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleBrandInfo(brand);
                        }}
                        className={`absolute ${index === 0 ? 'left-0' : 'right-0'} bottom-0 bg-zinc-900 rounded-full w-8 h-8 flex items-center justify-center shadow-zinc-100`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      className="cursor-pointer h-12 flex items-start justify-center w-full mt-2"
                    >
                      <p className="text-center font-medium text-white leading-tight line-clamp-2">
                        {brand.name}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="w-full max-w-md">
              <BattleTimer
                remainingBattles={remainingBattles}
                battleLimit={battleLimit}
              />
              <div className="flex items-center mt-6">
                <Button
                  className="flex-grow bg-[#e0ff89] text-black rounded-[30px] px-4 py-2"
                  onClick={handleCategoryButtonClick}
                >
                  {selectedSubSubCategory ? selectedSubSubCategory :
                    selectedSubCategory ? selectedSubCategory :
                      selectedMainCategory ? getCategoryName(selectedMainCategory) :
                        'Select Category'}
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
        <Dialog open={isReferralDialogOpen} onOpenChange={setIsReferralDialogOpen}>
          <DialogContent className="bg-gray-900 text-white">
            <DialogHeader>
              <DialogTitle>Invite a Friend</DialogTitle>
              <DialogDescription className="text-gray-400">
                Share this link with your friends to invite them to Battle Brands!
              </DialogDescription>
            </DialogHeader>
            <div className="flex items-center space-x-2">
              <Input value={userData?.referralLink} readOnly className="bg-gray-800 text-white" />
              <Button onClick={copyReferralLink} className="flex items-center space-x-2 bg-gray-700 text-white hover:bg-gray-600">
                <Copy size={16} />
                <span>Copy</span>
              </Button>
            </div>
            <Button
              className="w-full mt-4 bg-white text-black hover:bg-gray-200"
              onClick={() => {
                // Логика для отправки в Telegram
              }}
            >
              <Share2 className="mr-2" size={16} />
              Share on Telegram
            </Button>
          </DialogContent>
        </Dialog>
        {showUpgradeAnimation && <UpgradeAnimation rankName={currentRank} />}
      </div>
      <div
        className={`fixed inset-0 z-40 ${activeMenu ? '' : 'pointer-events-none'}`}
      >
        <div
          className={`fixed bottom-0 left-0 right-0 ${activeMenu === 'Game' ? 'bg-black top-0' : 'bg-[#e1ff8a] rounded-t-3xl'} overflow-hidden flex flex-col`}
          style={{
            transform: activeMenu ? (isDragging ? `translateY(${translateY}px)` : 'translateY(0)') : 'translateY(100%)',
            height: activeMenu === 'Game' ? '100%' : 'calc(100% - 4rem)',
            transition: isDragging ? 'none' : 'transform 0.3s ease-in-out'
          }}
        >
          <div
            className={`${activeMenu === 'Game' ? 'h-0' : 'h-6'} w-full ${activeMenu === 'Game' ? 'bg-black' : 'bg-[#e1ff8a]'} cursor-grab active:cursor-grabbing drag-handle`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {activeMenu !== 'Game' && <div className="h-1 w-16 bg-black rounded-full mx-auto mt-2"></div>}
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="flex justify-between items-center mb-2 px-2">
              <h2 className={`text-2xl font-bold ${activeMenu === 'Game' ? 'text-white' : 'text-black'}`}>{activeMenu}</h2>
              <button
                onClick={() => setActiveMenu(null)}
                className={`${activeMenu === 'Game' ? 'text-white' : 'text-black'} cursor-pointer focus:outline-none`}
              >
                <X size={20} />
              </button>
            </div>
            <div className={`flex-1 overflow-y-auto px-2 pb-24 ${activeMenu === 'Game' ? 'text-white' : 'text-black'} scrollbar-hide`}>
              {activeMenu === 'Rank' && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {ranks.map((rank) => {
                    const isActivated = rank.name === userData.rank || rank.level < userData.level;
                    const canUpgrade = !isActivated && canActivateRank(rank);

                    // Получаем цветовую схему ранга
                    const colorScheme = rank.colorScheme || {
                      primary: '#e0ff89',
                      secondary: '#1a1a1a',
                      text: '#FFFFFF'
                    };

                    // Стиль текста имени ранга - передаем colorScheme как параметр
                    const getNameStyle = (currentRank, colorScheme) => {
                      const effect = currentRank?.effects?.nameEffect;
                      if (!effect?.enabled) return { color: colorScheme.text };

                      const style = { color: effect.textColor || colorScheme.text };

                      switch (effect.type) {
                        case 'shadow':
                          style.textShadow = `0 0 ${effect.intensity}px ${effect.color}`;
                          break;
                        case 'glow':
                          style.textShadow = `0 0 ${effect.intensity * 2}px ${effect.color}, 0 0 ${effect.intensity}px ${effect.color}`;
                          break;
                        case 'gradient':
                          if (effect.gradientColors?.length >= 2) {
                            style.background = `linear-gradient(to right, ${effect.gradientColors.join(', ')})`;
                          } else {
                            style.background = `linear-gradient(to right, ${style.color}, ${effect.color})`;
                          }
                          style.WebkitBackgroundClip = 'text';
                          style.WebkitTextFillColor = 'transparent';
                          style.backgroundClip = 'text';
                          style.textFillColor = 'transparent';
                          break;
                        case 'highlight':
                          style.backgroundColor = effect.color;
                          style.color = effect.textColor || 'black';
                          style.padding = '0 8px';
                          style.borderRadius = '4px';
                          break;
                      }

                      return style;
                    };

                    // И добавляем новую функцию getTitleStyle - передаем colorScheme как параметр
                    const getTitleStyle = (currentRank, colorScheme) => {
                      if (!currentRank?.customTitle?.style) {
                        return { color: colorScheme.text };
                      }

                      const titleStyle = currentRank.customTitle.style;
                      const style = {
                        fontSize: `${currentRank.customTitle.fontSize || 18}px`,
                        fontWeight: currentRank.customTitle.fontWeight || 'bold',
                        fontFamily: currentRank.customTitle.fontFamily || 'Inter',
                        letterSpacing: `${currentRank.customTitle.letterSpacing || 0}px`,
                        color: titleStyle.color || '#FFFFFF'
                      };

                      // Добавление эффектов для названия
                      if (titleStyle.effect === 'shadow') {
                        style.textShadow = `0 0 ${titleStyle.intensity || 3}px ${titleStyle.effectColor || '#000000'}`;
                      } else if (titleStyle.effect === 'glow') {
                        style.textShadow = `0 0 ${titleStyle.intensity * 2 || 6}px ${titleStyle.effectColor || '#e0ff89'}, 0 0 ${titleStyle.intensity || 3}px ${titleStyle.effectColor || '#e0ff89'}`;
                      } else if (titleStyle.effect === 'gradient') {
                        if (titleStyle.gradientColors?.length >= 2) {
                          style.background = `linear-gradient(to right, ${titleStyle.gradientColors.join(', ')})`;
                        } else {
                          style.background = `linear-gradient(to right, ${titleStyle.color || '#FFFFFF'}, ${titleStyle.effectColor || '#e0ff89'})`;
                        }
                        style.WebkitBackgroundClip = 'text';
                        style.WebkitTextFillColor = 'transparent';
                        style.backgroundClip = 'text';
                        style.textFillColor = 'transparent';
                      }

                      // Добавление анимации для названия
                      if (titleStyle.animation && titleStyle.animation !== 'none') {
                        if (titleStyle.animation === 'pulse') {
                          style.animation = 'titlePulse 2s infinite';
                        } else if (titleStyle.animation === 'wave') {
                          style.animation = 'titleWave 2s infinite';
                        } else if (titleStyle.animation === 'shine') {
                          style.animation = 'titleShine 2s linear infinite';
                          style.backgroundSize = '200% auto';
                          if (!style.background) {
                            style.background = `linear-gradient(to right, ${titleStyle.color || '#FFFFFF'} 0%, ${titleStyle.effectColor || '#e0ff89'} 50%, ${titleStyle.color || '#FFFFFF'} 100%)`;
                            style.WebkitBackgroundClip = 'text';
                            style.WebkitTextFillColor = 'transparent';
                            style.backgroundClip = 'text';
                            style.textFillColor = 'transparent';
                          }
                        }
                      }

                      return style;
                    };

                    // Определяем стиль прогресс-бара
                    const progressBarColor = rank.ui?.progressBarColor || colorScheme.primary;

                    // Стиль кнопки из настроек ранга
                    const getButtonStyle = () => {
                      const buttonStyle = rank.ui?.buttonStyle || 'standard';
                      const baseStyle = {
                        backgroundColor: colorScheme.primary,
                        color: 'black',
                      };

                      switch (buttonStyle) {
                        case 'gradient':
                          return {
                            ...baseStyle,
                            background: `linear-gradient(to right, ${colorScheme.primary}, ${lightenColor(colorScheme.primary, 20)})`,
                          };
                        case 'glow':
                          return {
                            ...baseStyle,
                            boxShadow: `0 0 15px ${colorScheme.primary}`,
                          };
                        case 'animated':
                          return {
                            ...baseStyle,
                            animation: 'pulse 2s infinite',
                          };
                        default: // 'standard'
                          return baseStyle;
                      }
                    };

                    // Функция для получения компонента иконки бейджа
                    const getBadgeIcon = (type, props) => {
                      switch (type) {
                        case 'crown': return <Crown {...props} />;
                        case 'star': return <Star {...props} />;
                        case 'gem': return <Gem {...props} />;
                        case 'gamepad-2': return <Gamepad2 {...props} />;
                        case 'hand-metal': return <HandMetal {...props} />;
                        case 'magnet': return <Magnet {...props} />;
                        case 'lollipop': return <Lollipop {...props} />;
                        case 'paw-print': return <PawPrint {...props} />;
                        case 'pill': return <Pill {...props} />;
                        case 'spade': return <Spade {...props} />;
                        case 'zap': return <Zap {...props} />;
                        case 'rocket': return <Rocket {...props} />;
                        case 'shell': return <Shell {...props} />;
                        case 'bitcoin': return <Bitcoin {...props} />;
                        case 'cookie': return <Cookie {...props} />;
                        case 'donut': return <Donut {...props} />;
                        case 'banana': return <Banana {...props} />;
                        case 'bone': return <Bone {...props} />;
                        case 'dices': return <Dices {...props} />;
                        case 'ghost': return <Ghost {...props} />;
                        case 'heart': return <Heart {...props} />;
                        case 'puzzle': return <Puzzle {...props} />;
                        case 'skull': return <Skull {...props} />;
                        case 'swords': return <Swords {...props} />;
                        case 'audio-waveform': return <AudioWaveform {...props} />;
                        case 'drama': return <Drama {...props} />;
                        case 'brain': return <Brain {...props} />;
                        case 'radiation': return <Radiation {...props} />;
                        case 'webhook': return <Webhook {...props} />;
                        default: return <Star {...props} />;
                      }
                    };

                    return (
                      <div
                        key={rank.level}
                        className="relative overflow-hidden rounded-[20px]"
                        style={{
                          boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                          height: '190px',
                          backgroundColor: colorScheme.secondary
                        }}
                      >
                        {/* Контейнер для изображения */}
                        <div className="absolute inset-0 w-full h-full overflow-hidden">
                          <OptimizedImage
                            src={rank.backgroundImage}
                            alt={rank.name}
                            className="absolute inset-0 w-full h-full object-cover"
                          />
                        </div>

                        {/* Темный оверлей */}
                        <div className="absolute inset-0 bg-transparent"></div>

                        {/* Фоновые частицы, если они включены */}
                        {rank.effects?.backgroundParticles?.enabled && (
                          <div className="absolute inset-0 overflow-hidden pointer-events-none z-1">
                            {Array.from({ length: rank.effects.backgroundParticles.density * 3 }).map((_, i) => (
                              <div
                                key={`bg-particle-${i}`}
                                className="absolute animate-float"
                                style={{
                                  left: `${Math.random() * 100}%`,
                                  top: `${Math.random() * 100}%`,
                                  color: rank.effects.backgroundParticles.color,
                                  opacity: Math.random() * 0.7 + 0.3,
                                  animation: `float${i % 3} ${Math.random() * 2 + 1}s infinite ease-in-out`,
                                  transform: `translateY(0px) scale(${Math.random() * 0.5 + 0.5})`,
                                  zIndex: 1,
                                  pointerEvents: 'none',
                                }}
                              >
                                {rank.effects.backgroundParticles.type === 'sparkles' && (
                                  <svg width="10" height="10" viewBox="0 0 24 24" fill="currentColor">
                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                  </svg>
                                )}
                                {rank.effects.backgroundParticles.type === 'bubbles' && (
                                  <svg width="8" height="8" viewBox="0 0 24 24" fill="currentColor">
                                    <circle cx="12" cy="12" r="10" />
                                  </svg>
                                )}
                                {rank.effects.backgroundParticles.type === 'stars' && (
                                  <svg width="12" height="12" viewBox="0 0 24 24" fill="currentColor">
                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                  </svg>
                                )}
                                {rank.effects.backgroundParticles.type === 'fire' && (
                                  <svg width="12" height="12" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M12 2C10.97 2 9.94 2.16 8.92 2.5C9.61 3.43 10 4.63 10 5.9C10 8.15 8.21 10 6 10C5.07 10 4.21 9.72 3.5 9.22C3.18 10.13 3 11.05 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 6.42 16.97 2 12 2Z" />
                                  </svg>
                                )}
                              </div>
                            ))}
                          </div>
                        )}

                        {/* Эффект фона, если включен */}
                        {rank.effects?.backgroundEffect?.enabled && (
                          <div
                            className="absolute inset-0 z-2"
                            style={(() => {
                              if (!rank.effects?.backgroundEffect?.enabled) {
                                return {};
                              }

                              const effect = rank.effects.backgroundEffect;
                              const style = {};

                              switch (effect.type) {
                                case 'blur':
                                  style.backdropFilter = `blur(${effect.intensity}px)`;
                                  style.WebkitBackdropFilter = `blur(${effect.intensity}px)`;
                                  break;
                                case 'overlay':
                                  if (effect.useGradient && effect.gradientColors?.length >= 2) {
                                    style.background = `linear-gradient(${effect.gradientDirection || 'to bottom'}, ${effect.gradientColors.join(', ')})`;
                                  } else {
                                    style.backgroundColor = effect.color;
                                    style.opacity = effect.intensity / 10;
                                  }
                                  break;
                                case 'glow':
                                  style.boxShadow = `inset 0 0 ${effect.intensity * 5}px ${effect.color}`;
                                  style.opacity = 0.8;
                                  break;
                                case 'tint':
                                  style.backgroundColor = effect.color;
                                  style.opacity = effect.intensity / 10;
                                  style.mixBlendMode = 'color';
                                  break;
                                case 'gradient':
                                  if (effect.gradientColors?.length >= 2) {
                                    style.background = `linear-gradient(${effect.gradientDirection || 'to bottom'}, ${effect.gradientColors.join(', ')})`;
                                  } else {
                                    style.background = `linear-gradient(to bottom, transparent, ${effect.color})`;
                                  }
                                  break;
                              }

                              if (effect.animation?.enabled) {
                                style.animation = `${effect.animation.type}-bg ${effect.animation.duration}s infinite`;
                              }

                              return style;
                            })()}
                          />
                        )}

                        {/* Контент */}
                        <div className="relative z-10 p-6 flex flex-col h-full">
                          <div className="flex justify-between mb-3">
                            {/* Левая колонка с аватаром, названием и уровнем */}
                            <div className="flex items-center">
                              {/* Миниатюрный аватар с эффектами ранга */}
                              <div className="mr-3">
                                {/* Аватар ранга */}
                                <div className="relative">
                                  {/* Ореол (если включен) */}
                                  {rank.avatarBorder?.halo?.enabled && (
                                    <div
                                      className={`absolute inset-0 rounded-full -z-10 ${rank.avatarBorder.halo.animation === 'pulse' ? 'animate-pulse-infinite' :
                                        rank.avatarBorder.halo.animation === 'wave' ? 'animate-wave-infinite' :
                                          rank.avatarBorder.halo.animation === 'rings' ? 'animate-rings-infinite' : ''
                                        }`}
                                      style={{
                                        boxShadow: `0 0 ${rank.avatarBorder.halo.intensity * 3}px ${rank.avatarBorder.halo.intensity}px ${rank.avatarBorder.halo.color || '#e0ff89'}`,
                                        transform: 'scale(1.0)',
                                      }}
                                    ></div>
                                  )}

                                  {/* Аватарка с обводкой */}
                                  <div
                                    className={`rounded-full ${rank.avatarBorder?.animation?.enabled ?
                                      rank.avatarBorder.animation.type === 'pulse' ? 'animate-pulse-infinite' :
                                        rank.avatarBorder.animation.type === 'rotate' ? 'animate-rotate-infinite' :
                                          rank.avatarBorder.animation.type === 'shine' ? 'animate-shine-infinite' : ''
                                      : ''
                                      } flex items-center justify-center overflow-hidden`}
                                    style={
                                      rank.avatarBorder?.style === 'gradient' && rank.avatarBorder?.gradient?.colors?.length
                                        ? {
                                          backgroundImage: `linear-gradient(${rank.avatarBorder.gradient.direction || 'to right'}, ${rank.avatarBorder.gradient.colors.join(', ')})`,
                                          padding: rank.avatarBorder.width || 2,
                                          ...(rank.avatarBorder.glow?.enabled ? {
                                            boxShadow: `0 0 ${rank.avatarBorder.glow.intensity}px ${rank.avatarBorder.glow.color || '#FFFFFF'}`
                                          } : {})
                                        }
                                        : {
                                          border: `${rank.avatarBorder?.width || 2}px ${rank.avatarBorder?.style || 'solid'} ${rank.avatarBorder?.color || '#FFFFFF'}`,
                                          ...(rank.avatarBorder?.glow?.enabled ? {
                                            boxShadow: `0 0 ${rank.avatarBorder.glow.intensity}px ${rank.avatarBorder.glow.color || '#FFFFFF'}`
                                          } : {})
                                        }
                                    }
                                  >
                                    <div className="w-10 h-10 rounded-full flex items-center justify-center bg-zinc-800 text-white font-bold">
                                      {rank.name[0]}
                                    </div>
                                  </div>

                                  {/* Бейдж (если включен) */}
                                  {rank.badge?.enabled && (
                                    <div
                                      className={`absolute ${rank.badge.position.includes('top') ? 'top-0' : 'bottom-0'
                                        } ${rank.badge.position.includes('right') ? 'right-0' : 'left-0'
                                        } ${rank.badge.animation?.enabled ?
                                          rank.badge.animation.type === 'pulse' ? 'animate-pulse-infinite' :
                                            rank.badge.animation.type === 'rotate' ? 'animate-rotate-infinite' :
                                              rank.badge.animation.type === 'bounce' ? 'animate-bounce-infinite' : ''
                                          : ''
                                        } z-20 transform ${rank.badge.position.includes('right') ? 'translate-x-1/4' : '-translate-x-1/4'
                                        } ${rank.badge.position.includes('top') ? '-translate-y-1/4' : 'translate-y-1/4'
                                        }`}
                                      style={{
                                        color: rank.badge.color || '#e0ff89',
                                        ...(rank.badge.background?.enabled ? {
                                          backgroundColor: rank.badge.background.color,
                                          padding: '3px',
                                          borderRadius: '50%'
                                        } : {}),
                                        ...(rank.badge.shadow?.enabled ? {
                                          boxShadow: `0 0 5px ${rank.badge.shadow.color}`
                                        } : {})
                                      }}
                                    >
                                      {rank.badge.type === 'custom' && rank.badge.customImage ? (
                                        <img
                                          src={rank.badge.customImage}
                                          alt="Badge"
                                          style={{
                                            width: rank.badge.size || 16,
                                            height: rank.badge.size || 16
                                          }}
                                        />
                                      ) : (
                                        getBadgeIcon(rank.badge.type, { size: rank.badge.size || 16 })
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="flex flex-col">
                                <h3
                                  className="text-xl font-bold mb-0.5"
                                  style={getTitleStyle(rank, colorScheme)}
                                >
                                  {rank?.customTitle?.text || rank?.name}
                                </h3>
                                <span className="text-base text-gray-300">Level {rank?.level}</span>
                              </div>
                            </div>

                            {/* Правая колонка с монетами и лимитом */}
                            <div className="flex flex-col items-end">
                              <div className="flex items-start mb-0.5">
                                <span className="text-xl font-bold text-white mr-1">{rank.requiredCoins}</span>
                                <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5 translate-y-[4px]" />
                              </div>
                              <span className="text-base text-gray-300">Limit: {rank.battleLimit}</span>
                            </div>
                          </div>

                          <div className="mt-auto">
                            <div className="w-full bg-zinc-400 rounded-[30px] h-4 relative">
                              <div
                                className="h-4 rounded-[30px] transition-all duration-300"
                                style={{
                                  width: `${isActivated ? 100 : Math.min((battlesWon / rank.battlesRequired) * 100, 100)}%`,
                                  backgroundColor: progressBarColor
                                }}
                              ></div>
                              <div className="absolute inset-0 flex items-center justify-center text-xs text-black font-medium">
                                {isActivated ? 'Completed' : `${battlesWon}/${rank.battlesRequired} battles`}
                              </div>
                            </div>
                          </div>

                          {/* Кнопка активации ранга */}
                          <button
                            onClick={() => canActivateRank(rank) ? handleRankUpgrade(rank) : null}
                            className={`mt-3 py-2 px-4 rounded-[30px] text-center font-bold transition-colors w-full ${rank.name === userData.rank || rank.level < userData.level || isRankLowerThanCurrent(rank)
                                ? 'opacity-50 cursor-not-allowed'
                                : !canActivateRank(rank)
                                  ? 'opacity-50 cursor-not-allowed'
                                  : ''
                              }`}
                            style={{
                              backgroundColor: rank.colorScheme?.primary || '#e0ff89',
                              color: 'black',
                              border: 'none',
                              position: 'relative',
                              overflow: 'hidden',
                              ...(rank.ui?.buttonStyle === 'gradient' && {
                                background: `linear-gradient(to right, ${rank.colorScheme?.primary || '#e0ff89'}, ${lightenColor(rank.colorScheme?.primary || '#e0ff89', 20)})`
                              }),
                              ...(rank.ui?.buttonStyle === 'glow' && {
                                boxShadow: `0 0 15px ${rank.colorScheme?.primary || '#e0ff89'}`
                              }),
                              ...(rank.ui?.buttonStyle === 'animated' && canActivateRank(rank) && {
                                animation: 'pulse 2s infinite'
                              })
                            }}
                            disabled={!canActivateRank(rank) || rank.name === userData.rank || rank.level < userData.level || isRankLowerThanCurrent(rank)}
                          >
                            {rank.name === userData.rank || rank.level < userData.level || isRankLowerThanCurrent(rank)
                              ? 'Activated'
                              : canActivateRank(rank)
                                ? 'Activate Rank'
                                : (level < rank.level
                                  ? `Need level ${rank.level}`
                                  : (battlesWon < rank.battlesRequired
                                    ? `Need ${Math.max(0, rank.battlesRequired - battlesWon)} more battles`
                                    : (score < rank.requiredCoins
                                      ? `Need ${Math.max(0, rank.requiredCoins - score)} more coins`
                                      : 'Need more resources')))}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {activeMenu === 'Tasks' && (
                <Tabs defaultValue="referrals" className="w-full">
                  <TabsList className="w-full grid grid-cols-4 bg-black rounded-[30px] p-1">
                    <TabsTrigger
                      value="referrals"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Referrals
                    </TabsTrigger>
                    <TabsTrigger
                      value="video"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Video
                    </TabsTrigger>
                    <TabsTrigger
                      value="social"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Social
                    </TabsTrigger>
                    <TabsTrigger
                      value="special"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Special
                    </TabsTrigger>
                  </TabsList>
                  <div className="overflow-y-auto scrollbar-hide">
                    <TabsContent value="referrals" className="w-full">
                      <div className="flex justify-between mb-4">
                        <Button
                          className="flex-1 mr-2 bg-black text-white hover:bg-zinc-800 rounded-[30px] shadow-md px-4 py-2 text-sm font-medium"
                          onClick={() => {
                            console.log('Copy Link button clicked');
                            copyReferralLink();
                          }}
                        >
                          <Copy size={16} className="mr-2" />
                          <span>Copy Link</span>
                        </Button>
                        <Button
                          className="flex-1 ml-2 bg-black text-white hover:bg-zinc-800 rounded-[30px] shadow-md px-4 py-2 text-sm font-medium"
                          onClick={() => {
                            if (window.Telegram && window.Telegram.WebApp) {
                              window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(userData.referralLink)}&text=${encodeURIComponent('Join Battle Brands with my referral link!')}`);
                            } else {
                              toast.error("Telegram Web App is not available", { duration: 3000, position: 'top-center' });
                            }
                          }}
                        >
                          <Users size={16} className="mr-2" />
                          <span>Share</span>
                        </Button>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {referrals.map((referral) => (
                          <div
                            key={referral._id}
                            className="relative overflow-hidden rounded-[20px] bg-gray-800"
                            style={{
                              boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                              height: '130px',
                              backgroundColor: '#1a1a1a'
                            }}
                          >
                            {/* Контейнер для изображения */}
                            <div className="absolute inset-0 w-full h-full overflow-hidden">
                              <OptimizedImage
                                src={referral.backgroundImage}
                                alt={referral.name}
                                className="absolute inset-0 w-full h-full object-cover"
                              />
                            </div>

                            {/* Темный оверлей */}
                            <div className="absolute inset-0 bg-black bg-opacity-50"></div>

                            {/* Контент */}
                            <div className="relative z-10 p-6 flex flex-col h-full">
                              <div className="mb-3">
                                <div className="flex justify-between items-start">
                                  <div className="flex items-center">
                                    <div className="mr-2">
                                      <Users className="w-6 h-6 text-white" />
                                    </div>
                                    <h3 className="text-xl font-bold text-white">
                                      {referral.name}
                                    </h3>
                                  </div>
                                  <div className="flex items-center shrink-0 ml-2">
                                    <span className="text-xl font-bold text-white mr-1">{referral.coinsReward}</span>
                                    <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                  </div>
                                </div>
                                <p className="text-sm text-gray-300 mt-1 ml-8">
                                  {referral.description}
                                </p>
                              </div>

                              <div className="mt-auto">
                                <div className="w-full bg-zinc-400 rounded-[30px] h-4 relative">
                                  <div
                                    className="h-4 rounded-[30px] transition-all duration-300 bg-white"
                                    style={{ width: `${Math.min((userData.referrals.length || 0) / referral.requiredReferrals, 1) * 100}%` }}
                                  ></div>
                                  <div className="absolute inset-0 flex items-center justify-center text-xs text-black font-medium">
                                    {(userData.referrals.length || 0) >= referral.requiredReferrals ? (
                                      <span className="font-bold">Complete</span>
                                    ) : (
                                      `${userData.referrals.length || 0} / ${referral.requiredReferrals} referrals`
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabsContent>
                    <TabsContent value="video" className="w-full">
                      {(() => {
                        const completedTasks = videoTasks.filter(task => task.status === 'completed');
                        const activeTasks = videoTasks.filter(task => task.status !== 'completed');

                        return (
                          <>
                            {/* Активные задания - всегда видимы */}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                              {activeTasks.map((task) => (
                                <div
                                  key={task._id}
                                  className="relative overflow-hidden rounded-[20px] bg-gray-800"
                                  style={{
                                    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                                    height: '150px',
                                    backgroundColor: '#1a1a1a'
                                  }}
                                >
                                  {/* Контейнер для изображения */}
                                  <div className="absolute inset-0 w-full h-full overflow-hidden">
                                    <OptimizedImage
                                      src={task.backgroundImage}
                                      alt={task.name}
                                      className="absolute inset-0 w-full h-full object-cover"
                                    />
                                  </div>

                                  {/* Темный оверлей */}
                                  <div className="absolute inset-0 bg-black bg-opacity-50"></div>

                                  {/* Контент */}
                                  <div className="relative z-10 p-6 flex flex-col h-full">
                                    <div className="mb-3">
                                      <div className="flex justify-between items-start">
                                        <div className="flex items-center">
                                          <div className="mr-2">
                                            {socialIcons.YouTube && <socialIcons.YouTube className="w-6 h-6 text-white" />}
                                          </div>
                                          <h3 className="text-xl font-bold text-white">
                                            {task.name}
                                          </h3>
                                        </div>
                                        <div className="flex items-center shrink-0 ml-2">
                                          <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                          <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                        </div>
                                      </div>
                                      <p className="text-sm text-gray-300 mt-1 ml-8">
                                        {task.description}
                                      </p>
                                    </div>
                                    <div className="mt-auto">
                                      {task.status && !['not_started', 'in_progress', 'ready_to_claim', 'completed'].includes(task.status) && (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                          onClick={() => handleWatchVideo(task._id, task.videoUrl)}
                                        >
                                          Watch Video
                                        </Button>
                                      )}
                                      {task.status === 'in_progress' && (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                          disabled
                                        >
                                          <div className="flex items-center justify-center">
                                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-black mr-2"></div>
                                            Watching...
                                          </div>
                                        </Button>
                                      )}
                                      {task.status === 'ready_to_claim' && (
                                        <div className="flex items-center space-x-2">
                                          <Input
                                            type="text"
                                            placeholder="Enter Code"
                                            className={`flex-grow bg-black text-white placeholder-gray-500 rounded-[30px] py-2 px-4 h-10 ${task.codeError ? 'border-2 border-red-500' : ''}`}
                                            onChange={(e) => handleCodeInput(task._id, e.target.value)}
                                            value={taskCodes[task._id] || ''}
                                          />
                                          <Button
                                            className="bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 h-10"
                                            onClick={() => handleClaimVideoTask(task._id)}
                                          >
                                            Claim
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>

                            {/* Переключатель для отображения выполненных заданий */}
                            {completedTasks.length > 0 && (
                              <div className="mb-4">
                                <Button
                                  className="w-full bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-2 px-4 flex items-center justify-center"
                                  onClick={() => setShowCompletedVideoTasks(!showCompletedVideoTasks)}
                                >
                                  <span className="mr-2">Completed Tasks ({completedTasks.length})</span>
                                  {showCompletedVideoTasks ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                                </Button>
                              </div>
                            )}

                            {/* Выполненные задания */}
                            {showCompletedVideoTasks && completedTasks.length > 0 && (
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 opacity-75">
                                {completedTasks.map((task) => (
                                  <div
                                    key={task._id}
                                    className="relative overflow-hidden rounded-[20px] bg-gray-800"
                                    style={{
                                      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                                      height: '150px',
                                      backgroundColor: '#1a1a1a'
                                    }}
                                  >
                                    {/* Контейнер для изображения */}
                                    <div className="absolute inset-0 w-full h-full overflow-hidden">
                                      <OptimizedImage
                                        src={task.backgroundImage}
                                        alt={task.name}
                                        className="absolute inset-0 w-full h-full object-cover"
                                      />
                                    </div>

                                    {/* Темный оверлей */}
                                    <div className="absolute inset-0 bg-black bg-opacity-70"></div>

                                    {/* Иконка выполнения задания */}
                                    <div className="absolute top-2 right-2 bg-[#e0ff89] rounded-full p-1 z-20">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>

                                    {/* Контент */}
                                    <div className="relative z-10 p-6 flex flex-col h-full">
                                      <div className="mb-3">
                                        <div className="flex justify-between items-start">
                                          <div className="flex items-center">
                                            <div className="mr-2">
                                              {socialIcons.YouTube && <socialIcons.YouTube className="w-6 h-6 text-white" />}
                                            </div>
                                            <h3 className="text-xl font-bold text-white">
                                              {task.name}
                                            </h3>
                                          </div>
                                          <div className="flex items-center shrink-0 ml-2">
                                            <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                            <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                          </div>
                                        </div>
                                        <p className="text-sm text-gray-300 mt-1 ml-8">
                                          {task.description}
                                        </p>
                                      </div>
                                      <div className="mt-auto">
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4 opacity-60"
                                          disabled
                                        >
                                          Completed
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        );
                      })()}
                    </TabsContent>
                    <TabsContent value="social" className="w-full">
                      {(() => {
                        const completedTasks = socialTasks.filter(task => task.status === 'completed');
                        const activeTasks = socialTasks.filter(task => task.status !== 'completed');

                        return (
                          <>
                            {/* Активные задания - всегда видимы */}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                              {activeTasks.map((task) => (
                                <div
                                  key={task._id}
                                  className="relative overflow-hidden rounded-[20px] bg-gray-800"
                                  style={{
                                    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                                    height: '150px',
                                    backgroundColor: '#1a1a1a'
                                  }}
                                >
                                  {/* Контейнер для изображения */}
                                  <div className="absolute inset-0 w-full h-full overflow-hidden">
                                    <OptimizedImage
                                      src={task.backgroundImage}
                                      alt={task.name}
                                      className="absolute inset-0 w-full h-full object-cover"
                                    />
                                  </div>

                                  {/* Темный оверлей */}
                                  <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                                  {/* Контент */}
                                  <div className="relative z-10 p-6 flex flex-col h-full">
                                    <div className="mb-3">
                                      <div className="flex justify-between items-start">
                                        <div className="flex items-center">
                                          <div className="mr-2">
                                            {getSocialIcon(task.socialPlatform) && (
                                              <div className="w-6 h-6 text-white">
                                                {getSocialIcon(task.socialPlatform)}
                                              </div>
                                            )}
                                          </div>
                                          <h3 className="text-xl font-bold text-white">
                                            {task.name}
                                          </h3>
                                        </div>
                                        <div className="flex items-center shrink-0 ml-2">
                                          <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                          <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                        </div>
                                      </div>
                                      <p className="text-sm text-gray-300 mt-1 ml-8">
                                        {task.description}
                                      </p>
                                    </div>
                                    <div className="mt-auto">
                                      {task.status && !['not_started', 'subscribed', 'completed'].includes(task.status) && (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                          onClick={() => handleSubscribe(task._id)}
                                        >
                                          Subscribe to {task.socialPlatform}
                                        </Button>
                                      )}
                                      {task.status === 'subscribed' && (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                          onClick={() => handleClaimSocialTask(task._id)}
                                        >
                                          Claim
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>

                            {/* Переключатель для отображения выполненных заданий */}
                            {completedTasks.length > 0 && (
                              <div className="mb-4">
                                <Button
                                  className="w-full bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-2 px-4 flex items-center justify-center"
                                  onClick={() => setShowCompletedSocialTasks(!showCompletedSocialTasks)}
                                >
                                  <span className="mr-2">Completed Tasks ({completedTasks.length})</span>
                                  {showCompletedSocialTasks ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                                </Button>
                              </div>
                            )}

                            {/* Выполненные задания */}
                            {showCompletedSocialTasks && completedTasks.length > 0 && (
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 opacity-75">
                                {completedTasks.map((task) => (
                                  <div
                                    key={task._id}
                                    className="relative overflow-hidden rounded-[20px] bg-gray-800"
                                    style={{
                                      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                                      height: '150px',
                                      backgroundColor: '#1a1a1a'
                                    }}
                                  >
                                    {/* Контейнер для изображения */}
                                    <div className="absolute inset-0 w-full h-full overflow-hidden">
                                      <OptimizedImage
                                        src={task.backgroundImage}
                                        alt={task.name}
                                        className="absolute inset-0 w-full h-full object-cover"
                                      />
                                    </div>

                                    {/* Темный оверлей */}
                                    <div className="absolute inset-0 bg-black bg-opacity-70"></div>

                                    {/* Иконка выполнения задания */}
                                    <div className="absolute top-2 right-2 bg-[#e0ff89] rounded-full p-1 z-20">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>

                                    {/* Контент */}
                                    <div className="relative z-10 p-6 flex flex-col h-full">
                                      <div className="mb-3">
                                        <div className="flex justify-between items-start">
                                          <div className="flex items-center">
                                            <div className="mr-2">
                                              {getSocialIcon(task.socialPlatform) && (
                                                <div className="w-6 h-6 text-white">
                                                  {getSocialIcon(task.socialPlatform)}
                                                </div>
                                              )}
                                            </div>
                                            <h3 className="text-xl font-bold text-white">
                                              {task.name}
                                            </h3>
                                          </div>
                                          <div className="flex items-center shrink-0 ml-2">
                                            <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                            <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                          </div>
                                        </div>
                                        <p className="text-sm text-gray-300 mt-1 ml-8">
                                          {task.description}
                                        </p>
                                      </div>
                                      <div className="mt-auto">
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4 opacity-60"
                                          disabled
                                        >
                                          Completed
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        );
                      })()}
                    </TabsContent>
                    <TabsContent value="special" className="w-full">
                      {(() => {
                        // Рекламные задания всегда видимы
                        const adTasks = specialTasks.filter(task => task.taskType === 'ad');

                        // Не-рекламные задания разделяем на выполненные и невыполненные
                        const nonAdTasks = specialTasks.filter(task => task.taskType !== 'ad');
                        const completedNonAdTasks = nonAdTasks.filter(task => task.status === 'completed');
                        const activeNonAdTasks = nonAdTasks.filter(task => task.status !== 'completed');

                        return (
                          <>
                            {/* Рекламные и активные не-рекламные задания - всегда видимы */}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                              {/* Сначала показываем рекламные задания */}
                              {adTasks.map((task) => (
                                <div
                                  key={task._id}
                                  className="relative overflow-hidden rounded-[20px] bg-gray-800"
                                  style={{
                                    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                                    height: '150px',
                                    backgroundColor: '#1a1a1a'
                                  }}
                                >
                                  {/* Контейнер для изображения */}
                                  <div className="absolute inset-0 w-full h-full overflow-hidden">
                                    <OptimizedImage
                                      src={task.backgroundImage}
                                      alt={task.name}
                                      className="absolute inset-0 w-full h-full object-cover"
                                    />
                                  </div>

                                  {/* Темный оверлей */}
                                  <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                                  {/* Контент */}
                                  <div className="relative z-10 p-6 flex flex-col h-full">
                                    <div className="mb-3">
                                      <div className="flex justify-between items-start">
                                        <div className="flex items-center">
                                          <div className="mr-2">
                                            {task.taskType === 'ad' ? (
                                              <div className="w-6 h-6 flex items-center justify-center text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                  <circle cx="12" cy="12" r="10"></circle>
                                                  <polygon points="10 8 16 12 10 16 10 8"></polygon>
                                                </svg>
                                              </div>
                                            ) : task.taskType === 'app' ? (
                                              <Smartphone className="w-6 h-6 text-white" />
                                            ) : (
                                              <Globe className="w-6 h-6 text-white" />
                                            )}
                                          </div>
                                          <h3 className="text-xl font-bold text-white">
                                            {task.name}
                                          </h3>
                                        </div>
                                        <div className="flex items-center shrink-0 ml-2">
                                          {task.taskType === 'ad' ? (
                                            <span className="text-xl font-bold text-white mr-1">{task.battlesReward} battles</span>
                                          ) : (
                                            <>
                                              <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                              <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <p className="text-sm text-gray-300 mt-1 ml-8">
                                        {task.description}
                                      </p>
                                    </div>
                                    <div className="mt-auto">
                                      {task.status === 'completed' && task.taskType === 'ad' ? (
                                        <Button
                                          className={`w-full py-2 px-4 rounded-[30px] ${task.dailyLimitReached
                                            ? 'bg-gray-500 text-gray-300 cursor-not-allowed'
                                            : 'bg-[#e0ff89] text-black hover:bg-[#d1ef7a]'}`}
                                          onClick={() => !task.dailyLimitReached && handleAdTask(task._id)}
                                          disabled={task.dailyLimitReached}
                                        >
                                          {task.dailyLimitReached
                                            ? `Daily limit reached (${task.todayViewsCount}/${task.dailyAdClickLimit})`
                                            : `Watch Ad Again for ${task.battlesReward} Battles`}
                                        </Button>
                                      ) : task.status === 'ad_showing' ? (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                          disabled
                                        >
                                          <div className="flex items-center justify-center">
                                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-black mr-2"></div>
                                            Watching ad...
                                          </div>
                                        </Button>
                                      ) : task.status === 'ad_complete' ? (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                          disabled
                                        >
                                          <div className="flex items-center justify-center">
                                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-black mr-2"></div>
                                            Getting reward...
                                          </div>
                                        </Button>
                                      ) : task.status === 'in_progress' && task.taskType === 'web' ? (
                                        <div className="flex items-center space-x-2">
                                          <Input
                                            type="text"
                                            placeholder="Enter Code"
                                            className={`flex-grow bg-black text-white placeholder-gray-500 rounded-[30px] py-2 px-4 h-10 ${task.codeError ? 'border-2 border-red-500' : ''}`}
                                            onChange={(e) => handleCodeInput(task._id, e.target.value)}
                                            value={taskCodes[task._id] || ''}
                                          />
                                          <Button
                                            className="bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 h-10"
                                            onClick={() => handleClaimSpecialTask(task._id)}
                                          >
                                            Claim
                                          </Button>
                                        </div>
                                      ) : task.status === 'in_progress' && task.taskType === 'ad' ? (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                          disabled
                                        >
                                          <div className="flex items-center justify-center">
                                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-black mr-2"></div>
                                            Loading Ad...
                                          </div>
                                        </Button>
                                      ) : task.status === 'in_progress' && task.taskType === 'app' ? (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                          onClick={() => handleClaimSpecialTask(task._id)}
                                        >
                                          Claim
                                        </Button>
                                      ) : task.taskType === 'ad' ? (
                                        <Button
                                          className={`w-full py-2 px-4 rounded-[30px] ${task.dailyLimitReached
                                            ? 'bg-gray-500 text-gray-300 cursor-not-allowed'
                                            : 'bg-[#e0ff89] text-black hover:bg-[#d1ef7a]'}`}
                                          onClick={() => !task.dailyLimitReached && handleAdTask(task._id)}
                                          disabled={task.dailyLimitReached}
                                        >
                                          {task.dailyLimitReached
                                            ? `Daily limit reached (${task.todayViewsCount}/${task.dailyAdClickLimit})`
                                            : task.adWatched
                                              ? 'Watch Ad Again'
                                              : `Watch Ad for ${task.battlesReward} Battles`}
                                        </Button>
                                      ) : (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                          onClick={() => handleSpecialTask(task._id)}
                                        >
                                          {task.taskType === 'app' ? 'Open App' : 'Go to Website'}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}

                              {/* Затем показываем активные не-рекламные задания */}
                              {activeNonAdTasks.map((task) => (
                                <div
                                  key={task._id}
                                  className="relative overflow-hidden rounded-[20px] bg-gray-800"
                                  style={{
                                    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                                    height: '150px',
                                    backgroundColor: '#1a1a1a'
                                  }}
                                >
                                  {/* Контейнер для изображения */}
                                  <div className="absolute inset-0 w-full h-full overflow-hidden">
                                    <OptimizedImage
                                      src={task.backgroundImage}
                                      alt={task.name}
                                      className="absolute inset-0 w-full h-full object-cover"
                                    />
                                  </div>

                                  {/* Темный оверлей */}
                                  <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                                  {/* Контент */}
                                  <div className="relative z-10 p-6 flex flex-col h-full">
                                    <div className="mb-3">
                                      <div className="flex justify-between items-start">
                                        <div className="flex items-center">
                                          <div className="mr-2">
                                            {task.taskType === 'app' ? (
                                              <Smartphone className="w-6 h-6 text-white" />
                                            ) : (
                                              <Globe className="w-6 h-6 text-white" />
                                            )}
                                          </div>
                                          <h3 className="text-xl font-bold text-white">
                                            {task.name}
                                          </h3>
                                        </div>
                                        <div className="flex items-center shrink-0 ml-2">
                                          <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                          <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                        </div>
                                      </div>
                                      <p className="text-sm text-gray-300 mt-1 ml-8">
                                        {task.description}
                                      </p>
                                    </div>
                                    <div className="mt-auto">
                                      {task.status === 'in_progress' && task.taskType === 'web' ? (
                                        <div className="flex items-center space-x-2">
                                          <Input
                                            type="text"
                                            placeholder="Enter Code"
                                            className={`flex-grow bg-black text-white placeholder-gray-500 rounded-[30px] py-2 px-4 h-10 ${task.codeError ? 'border-2 border-red-500' : ''}`}
                                            onChange={(e) => handleCodeInput(task._id, e.target.value)}
                                            value={taskCodes[task._id] || ''}
                                          />
                                          <Button
                                            className="bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 h-10"
                                            onClick={() => handleClaimSpecialTask(task._id)}
                                          >
                                            Claim
                                          </Button>
                                        </div>
                                      ) : task.status === 'in_progress' && task.taskType === 'app' ? (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                          onClick={() => handleClaimSpecialTask(task._id)}
                                        >
                                          Claim
                                        </Button>
                                      ) : (
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                          onClick={() => handleSpecialTask(task._id)}
                                        >
                                          {task.taskType === 'app' ? 'Open App' : 'Go to Website'}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>

                            {/* Переключатель для отображения выполненных не-рекламных заданий */}
                            {completedNonAdTasks.length > 0 && (
                              <div className="mb-4">
                                <Button
                                  className="w-full bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-2 px-4 flex items-center justify-center"
                                  onClick={() => setShowCompletedSpecialTasks(!showCompletedSpecialTasks)}
                                >
                                  <span className="mr-2">Completed Tasks ({completedNonAdTasks.length})</span>
                                  {showCompletedSpecialTasks ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                                </Button>
                              </div>
                            )}

                            {/* Выполненные не-рекламные задания */}
                            {showCompletedSpecialTasks && completedNonAdTasks.length > 0 && (
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 opacity-75">
                                {completedNonAdTasks.map((task) => (
                                  <div
                                    key={task._id}
                                    className="relative overflow-hidden rounded-[20px] bg-gray-800"
                                    style={{
                                      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                                      height: '150px',
                                      backgroundColor: '#1a1a1a'
                                    }}
                                  >
                                    {/* Контейнер для изображения */}
                                    <div className="absolute inset-0 w-full h-full overflow-hidden">
                                      <OptimizedImage
                                        src={task.backgroundImage}
                                        alt={task.name}
                                        className="absolute inset-0 w-full h-full object-cover"
                                      />
                                    </div>

                                    {/* Темный оверлей */}
                                    <div className="absolute inset-0 bg-black bg-opacity-70"></div>

                                    {/* Иконка выполнения задания */}
                                    <div className="absolute top-2 right-2 bg-[#e0ff89] rounded-full p-1 z-20">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>

                                    {/* Контент */}
                                    <div className="relative z-10 p-6 flex flex-col h-full">
                                      <div className="mb-3">
                                        <div className="flex justify-between items-start">
                                          <div className="flex items-center">
                                            <div className="mr-2">
                                              {task.taskType === 'app' ? (
                                                <Smartphone className="w-6 h-6 text-white" />
                                              ) : (
                                                <Globe className="w-6 h-6 text-white" />
                                              )}
                                            </div>
                                            <h3 className="text-xl font-bold text-white">
                                              {task.name}
                                            </h3>
                                          </div>
                                          <div className="flex items-center shrink-0 ml-2">
                                            <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                            <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                          </div>
                                        </div>
                                        <p className="text-sm text-gray-300 mt-1 ml-8">
                                          {task.description}
                                        </p>
                                      </div>
                                      <div className="mt-auto">
                                        <Button
                                          className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4 opacity-60"
                                          disabled
                                        >
                                          Completed
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        );
                      })()}
                    </TabsContent>
                  </div>
                </Tabs>
              )}
              {activeMenu === 'Account' && (
                <div className="space-y-4 text-black">
                  <p className="font-medium">Your Statistics:</p>
                  <ul className="text-sm space-y-1">
                    <li>Battles won: {stats.battlesWon}</li>
                    <li>Coins earned: {score}</li>
                    <li>Referrals invited: {userData?.referralCount || 0}</li>
                  </ul>
                  <div className="h-60">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={getTopBrands(5)}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#888" />
                        <XAxis dataKey="brand" stroke="#000" />
                        <YAxis stroke="#000" />
                        <Tooltip
                          contentStyle={{ backgroundColor: '#f0f0f0', border: 'none' }}
                          labelStyle={{ color: '#000' }}
                          itemStyle={{ color: '#000' }}
                        />
                        <Legend wrapperStyle={{ color: '#000' }} />
                        <Bar dataKey="count" fill="#000000" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}
              {activeMenu === 'Game' && (
                <div className="fixed inset-0 z-50 bg-black">
                  <div className="h-full flex flex-col">
                    <div className="flex justify-between items-center p-4">
                      <h2 className="text-2xl font-bold text-white">Brand Growth</h2>
                      <button onClick={() => setActiveMenu(null)} className="text-white">
                        <X size={24} />
                      </button>
                    </div>
                    <div className="flex-grow px-4 pb-4">
                      <BrandGrowthGame
                        user={userData}
                        setUser={setUserData}
                        setGlobalUser={setGlobalUser}
                        onClose={() => setActiveMenu(null)}
                        updateUserData={updateUserDataCallback}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeMenu === 'Pass' && (
                <div className="relative overflow-hidden rounded-[30px]">
                  {/* Контейнер для фона */}
                  <div className="absolute inset-0 w-full h-full overflow-hidden">
                    <OptimizedImage
                      src="/images/backgrounds/pass-background.jpg" // Здесь укажите путь к вашему фоновому изображению
                      alt="Pass background"
                      className="absolute inset-0 w-full h-full object-cover"
                      priority={false}
                    />
                    {/* Добавляем оверлей для лучшей читаемости контента */}
                    <div className="absolute inset-0 bg-[#e0ff89] bg-opacity-90"></div>
                  </div>

                  {/* Контент */}
                  <div className="relative z-10">
                    <TicketDisplay
                      user={userData}
                      tickets={tickets}
                      onPurchase={handleTicketPurchase}
                    />
                  </div>
                </div>
              )}
              {activeMenu === 'Top' && (
                <div className="bg-[#e0ff89] rounded-[30px]">
                  <LeaderboardTable
                    data={leaderboardData}
                    period={leaderboardPeriod}
                    handlePeriodChange={handlePeriodChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`fixed bottom-4 left-4 right-4 ${activeMenu === 'Game' ? 'z-40' : 'z-50'}`} style={{ marginBottom: "env(safe-area-inset-bottom, 0)" }}>
        <div className="max-w-screen-xl mx-auto main-menu rounded-[20px] p-1 flex items-center justify-between relative overflow-hidden">
          {[
            { icon: Crown, label: 'Ranks', menu: 'Rank' },
            { icon: CircleCheckBig, label: 'Tasks', menu: 'Tasks' },
            { icon: BarChart2, label: 'Game', menu: 'Game' },
            { icon: Ticket, label: 'Pass', menu: 'Pass' },
            { icon: ListOrdered, label: 'Top', menu: 'Top' }
          ].map(({ icon: Icon, label, menu }) => (
            <div key={menu} className="relative flex-1">
              <Button
                variant="ghost"
                onClick={() => handleMenuClick(activeMenu === menu ? null : menu)}
                className={`
      main-menu-item w-full flex flex-col items-center justify-center py-2 px-4 transition-all
      ${activeMenu === menu ? 'active' : ''}
    `}
              >
                <Icon size={24} className="menu-icon" />
                <span className="text-xs menu-text">{label}</span>
              </Button>
            </div>
          ))}
        </div>
      </div>
      <Dialog
        open={!!selectedBrandInfo}
        onOpenChange={() => setSelectedBrandInfo(null)}
      >
        <DialogContent className="bg-black rounded-[30px] p-6">
          <DialogHeader>
            <DialogTitle className="text-white flex items-center justify-between">
              <div className="flex items-center">
                <Avatar className="w-12 h-12 mr-4">
                  <AvatarImage src={selectedBrandInfo?.logo} alt={selectedBrandInfo?.name} />
                  <AvatarFallback>{selectedBrandInfo?.name[0]}</AvatarFallback>
                </Avatar>
                <div>
                  <h2 className="text-2xl font-bold text-left">{selectedBrandInfo?.name}</h2>
                  <div className="flex items-center mt-1">
                    {[1, 2, 3, 4, 5].map((star) => {
                      const rating = selectedBrandInfo?.rating || 0;
                      const fillPercentage = Math.min(100, Math.max(0, (rating - (star - 1)) * 100));
                      return (
                        <div key={star} className="relative w-4 h-4">
                          <Star className="w-4 h-4 text-gray-400" />
                          <div className="absolute top-0 left-0 overflow-hidden" style={{ width: `${fillPercentage}%` }}>
                            <Star className="w-4 h-4 text-yellow-400 fill-current" />
                          </div>
                        </div>
                      );
                    })}
                    <span className="ml-2 text-sm text-gray-400">
                      ({selectedBrandInfo?.rating?.toFixed(1) || '0.0'})
                    </span>
                  </div>
                </div>
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className="text-white mt-2 space-y-4">
            <div className="flex">
              <span className="font-semibold w-20">Country:</span>
              {selectedBrandInfo?.countries && selectedBrandInfo.countries.length > 0 && (
                <span>
                  {selectedBrandInfo.countries.map((country, index) => (
                    <React.Fragment key={country.code}>
                      <ReactCountryFlag countryCode={country.code} svg className="mr-1" />
                      <span>{country.name}</span>
                      {index < selectedBrandInfo.countries.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <span className="font-semibold mb-1">Category:</span>
              <div className="flex flex-wrap">
                {selectedBrandInfo?.categories && selectedBrandInfo.categories.length > 0 && selectedBrandInfo.categories.map((cat, index) => (
                  <div key={cat.category._id} className="flex flex-wrap items-center mb-1 mr-2">
                    <span className="text-[#e0ff89] break-words">{cat.category.name}</span>
                    {cat.subCategory && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 mx-1 text-gray-400 flex-shrink-0"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                        <span className="text-[#e0ff89] break-words">{cat.subCategory}</span>
                      </>
                    )}
                    {cat.subSubCategory && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 mx-1 text-gray-400 flex-shrink-0"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                        <span className="text-[#e0ff89] break-words">{cat.subSubCategory}</span>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div>
              <span className="font-semibold">Description: </span>
              <p className="text-gray-300">{selectedBrandInfo?.description}</p>
            </div>

            {selectedBrandInfo?.socialMedia && selectedBrandInfo.socialMedia.length > 0 && (
              <div className="pb-8">
                <span className="font-semibold block mb-2">Social: </span>
                <div className="flex space-x-6">
                  {selectedBrandInfo.socialMedia.map(social => {
                    const Icon = socialIcons[social.platform];
                    return Icon ? (
                      <Button
                        key={social.platform}
                        onClick={() => handleSocialMediaClick(selectedBrandInfo._id, social.platform, social.url)}
                        className="text-gray-400 hover:text-white transition-colors"
                      >
                        <Icon size={24} />
                      </Button>
                    ) : null;
                  })}
                </div>
              </div>
            )}

            {selectedBrandInfo?.website && (
              <div>
                <Button
                  onClick={() => handleWebsiteClick(selectedBrandInfo._id, selectedBrandInfo.website)}
                  className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 text-center font-bold transition-colors"
                >
                  Go to {selectedBrandInfo.name}
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BrandBattle;