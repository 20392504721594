import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { addDays, format } from "date-fns";
import { getBrandStatistics } from '../services/brandService';
import { Star, StarHalf, BarChart2, Globe, LineChart, Share2, Users, Calendar } from 'lucide-react';
import { COUNTRIES } from '../constants/countries';
import { SiApplemusic, SiBehance, SiDiscord, SiDribbble, SiFacebook, SiGithub, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiQuora, SiReddit, SiSnapchat, SiSoundcloud, SiSpotify, SiTelegram, SiTencentqq, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';

const socialIcons = {
  AppleMusic: SiApplemusic,
  Behance: SiBehance,
  Discord: SiDiscord,
  Dribbble: SiDribbble,
  Facebook: SiFacebook,
  GitHub: SiGithub,
  Instagram: SiInstagram,
  KakaoTalk: SiKakaotalk,
  Line: SiLine,
  LinkedIn: SiLinkedin,
  Medium: SiMedium,
  Odnoklassniki: SiOdnoklassniki,
  OnlyFans: SiOnlyfans,
  Patreon: SiPatreon,
  Pinterest: SiPinterest,
  QQ: SiTencentqq,
  Quora: SiQuora,
  Reddit: SiReddit,
  Snapchat: SiSnapchat,
  SoundCloud: SiSoundcloud,
  Spotify: SiSpotify,
  Telegram: SiTelegram,
  Threads: SiThreads,
  TikTok: SiTiktok,
  Tumblr: SiTumblr,
  Twitch: SiTwitch,
  Viber: SiViber,
  Vk: SiVk,
  WeChat: SiWechat,
  Weibo: SiSinaweibo,
  WhatsApp: SiWhatsapp,
  X: SiX,
  YouTube: SiYoutube
};

const getCountryFlag = (countryCode) => {
  return countryCode === 'all_countries' ? (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.6 9H20.4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.6 15H20.4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 3C14.0833 5.04 15.2222 7.38 15.2222 12C15.2222 16.62 14.0833 18.96 12 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 3C9.91667 5.04 8.77778 7.38 8.77778 12C8.77778 16.62 9.91667 18.96 12 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ) : (
    <img
      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode.toUpperCase()}.svg`}
      alt={countryCode}
      className="w-6 h-4 object-cover rounded"
    />
  );
};

const getCountryLabel = (countryCode) => {
  if (countryCode === 'all_countries') return 'All Countries';
  const country = COUNTRIES.find(c => c.value === countryCode);
  return country ? country.label : countryCode;
};

const BrandStatistics = ({ brandId }) => {
  // Основные состояния
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Состояния для дат
  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [endDate, setEndDate] = useState(new Date());

  // Состояния для сравнения брендов
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('all_countries');
  const [selectedGender, setSelectedGender] = useState('all_genders');
  const [selectedAge, setSelectedAge] = useState('all_ages');
  const [comparisonTab, setComparisonTab] = useState('overview');

  const getMonthIndex = (date) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months.indexOf(date.split(' ')[0]);
  };

  // Добавить в начало компонента
  const getPlatformColor = (platform) => {
    const colors = {
      AppleMusic: "#FC3C44",
      Behance: "#1769FF",
      Discord: "#5865F2",
      Dribbble: "#EA4C89",
      Facebook: "#1877F2",
      GitHub: "#181717",
      Instagram: "#E4405F",
      KakaoTalk: "#FAE100",
      Line: "#00B900",
      LinkedIn: "#0A66C2",
      Medium: "#000000",
      Odnoklassniki: "#EE8208",
      OnlyFans: "#00AFF0",
      Patreon: "#FF424D",
      Pinterest: "#BD081C",
      QQ: "#EB1923",
      Quora: "#B92B27",
      Reddit: "#FF4500",
      Snapchat: "#FFFC00",
      SoundCloud: "#FF3300",
      Spotify: "#1DB954",
      Telegram: "#26A5E4",
      Threads: "#000000",
      TikTok: "#000000",
      Tumblr: "#36465D",
      Twitch: "#9146FF",
      Viber: "#7360F2",
      Vk: "#0077FF",
      WeChat: "#07C160",
      Weibo: "#E6162D",
      WhatsApp: "#25D366",
      X: "#000000",
      YouTube: "#FF0000"
    };

    return colors[platform] || "#808080"; // Серый цвет по умолчанию
  };

  // Получение данных
  useEffect(() => {
    const fetchStats = async () => {
      if (!brandId) return;

      try {
        // Set default date range to last 7 days if not set
        const defaultEndDate = new Date();
        const defaultStartDate = addDays(defaultEndDate, -7);

        const data = await getBrandStatistics(brandId, {
          startDate: (startDate || defaultStartDate).toISOString(),
          endDate: (endDate || defaultEndDate).toISOString()
        });

        if (!data) throw new Error('No data received');

        // Создаем массив для хранения ежедневной статистики
        const dailyStats = [];
        const battleHistory = data.battleHistory || [];

        // Создаем Map для группировки данных по дням
        const statsMap = new Map();

        // Обрабатываем историю битв
        battleHistory.forEach(battle => {
          const date = new Date(battle.timestamp);
          const dateKey = format(date, 'yyyy-MM-dd');

          if (!statsMap.has(dateKey)) {
            statsMap.set(dateKey, {
              date: dateKey,
              totalBattles: 0,
              wins: 0,
              losses: 0,
              websiteClicks: 0,
              socialClicks: 0
            });
          }

          const dayStats = statsMap.get(dateKey);
          dayStats.totalBattles++;
          if (battle.result === 'win') {
            dayStats.wins++;
          } else {
            dayStats.losses++;
          }
        });

        // Обрабатываем клики по веб-сайту
        (data.websiteClicks?.history || []).forEach(click => {
          const date = new Date(click.timestamp);
          const dateKey = format(date, 'yyyy-MM-dd');

          if (!statsMap.has(dateKey)) {
            statsMap.set(dateKey, {
              date: dateKey,
              totalBattles: 0,
              wins: 0,
              losses: 0,
              websiteClicks: 0,
              socialClicks: 0
            });
          }

          statsMap.get(dateKey).websiteClicks++;
        });

        // Обрабатываем клики по социальным сетям
        (data.socialMediaClicks || []).forEach(platform => {
          platform.history?.forEach(click => {
            const date = new Date(click.timestamp);
            const dateKey = format(date, 'yyyy-MM-dd');

            if (!statsMap.has(dateKey)) {
              statsMap.set(dateKey, {
                date: dateKey,
                totalBattles: 0,
                wins: 0,
                losses: 0,
                websiteClicks: 0,
                socialClicks: 0
              });
            }

            statsMap.get(dateKey).socialClicks++;
          });
        });

        // Заполняем пропущенные даты между startDate и endDate
        let currentDate = startDate || defaultStartDate;
        const endDateToUse = endDate || defaultEndDate;

        while (currentDate <= endDateToUse) {
          const dateKey = format(currentDate, 'yyyy-MM-dd');
          if (!statsMap.has(dateKey)) {
            statsMap.set(dateKey, {
              date: dateKey,
              totalBattles: 0,
              wins: 0,
              losses: 0,
              websiteClicks: 0,
              socialClicks: 0
            });
          }
          currentDate = addDays(currentDate, 1);
        }

        // Преобразуем Map в отсортированный массив
        const sortedDailyStats = Array.from(statsMap.entries())
          .map(([date, stats]) => ({
            name: format(new Date(date), 'MMM dd'),
            battles: stats.totalBattles,
            wins: stats.wins,
            losses: stats.losses,
            websiteClicks: stats.websiteClicks,
            socialClicks: stats.socialClicks
          }))
          .sort((a, b) => new Date(a.name) - new Date(b.name));

        // Обработка демографической статистики
        const processedDemographics = {
          genderStats: (data.demographics?.genderStats || []).map(stat => ({
            gender: stat.gender,
            totalBattles: Number(stat.totalBattles) || 0,
            wins: Number(stat.wins) || 0,
            losses: Number(stat.losses) || 0,
            percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
          })).filter(stat => stat.totalBattles > 0),
          ageStats: (data.demographics?.ageStats || []).map(stat => ({
            ageRange: stat.ageRange,
            totalBattles: Number(stat.totalBattles) || 0,
            wins: Number(stat.wins) || 0,
            losses: Number(stat.losses) || 0,
            percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
          })).filter(stat => stat.totalBattles > 0)
        };

        // Обработка статистики по странам
        const processedCountryStats = (data.countryStats || []).map(country => ({
          country: country.country,
          totalBattles: Number(country.totalBattles) || 0,
          wins: Number(country.wins) || 0,
          losses: Number(country.losses) || 0,
          winRate: country.totalBattles > 0 ? ((country.wins / country.totalBattles) * 100).toFixed(1) : '0',
          demographics: {
            genderStats: (country.demographics?.genderStats || []).map(stat => ({
              gender: stat.gender,
              totalBattles: Number(stat.totalBattles) || 0,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0
            })).filter(stat => stat.totalBattles > 0),
            ageStats: (country.demographics?.ageStats || []).map(stat => ({
              ageRange: stat.ageRange,
              totalBattles: Number(stat.totalBattles) || 0,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0
            })).filter(stat => stat.totalBattles > 0)
          }
        })).filter(country => country.totalBattles > 0);

        setStats({
          totalBattles: data.totalBattles || 0,
          totalWins: data.totalWins || 0,
          rating: data.rating || 0,
          dailyStats: sortedDailyStats,
          demographics: processedDemographics,
          countryStats: processedCountryStats,
          socialMediaClicks: data.socialMediaClicks || [],
          battleHistory: data.battleHistory || [],
          socialMedia: {
            websiteClicks: data.websiteClicks?.total || 0,
            platforms: (data.socialMediaClicks || []).map(platform => ({
              name: platform.platform,
              clicks: Number(platform.total) || 0
            }))
          },
          comparisons: (data.comparisons || []).map(comparison => ({
            competitorBrandId: comparison.competitorBrandId,
            competitorBrand: comparison.competitorBrand,
            competitorLogo: comparison.competitorLogo,
            wins: Number(comparison.wins) || 0,
            losses: Number(comparison.losses) || 0,
            totalBattles: Number(comparison.wins + comparison.losses) || 0,
            winRate: comparison.wins > 0 ? ((comparison.wins / (comparison.wins + comparison.losses)) * 100).toFixed(1) : '0',
            countryStats: comparison.countryStats?.map(stat => ({
              country: stat.country,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0,
              totalBattles: Number(stat.wins + stat.losses) || 0,
              winRate: stat.wins > 0 ? ((stat.wins / (stat.wins + stat.losses)) * 100).toFixed(1) : '0',
              demographics: {
                genderStats: (stat.demographics?.genderStats || []).map(g => ({
                  gender: g.gender,
                  wins: Number(g.wins) || 0,
                  losses: Number(g.losses) || 0,
                  totalBattles: Number(g.totalBattles) || 0
                })).filter(g => g.totalBattles > 0),
                ageStats: (stat.demographics?.ageStats || []).map(a => ({
                  ageRange: a.ageRange,
                  wins: Number(a.wins) || 0,
                  losses: Number(a.losses) || 0,
                  totalBattles: Number(a.totalBattles) || 0
                })).filter(a => a.totalBattles > 0)
              }
            })).filter(stat => stat.totalBattles > 0),
            dailyStats: (comparison.dailyStats || []).map(stat => ({
              date: stat.date,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0,
              totalBattles: Number(stat.totalBattles) || 0,
              winRate: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
            })).sort((a, b) => new Date(a.date) - new Date(b.date)),
            demographics: {
              genderStats: (comparison.demographics?.genderStats || []).map(stat => ({
                gender: stat.gender,
                wins: Number(stat.wins) || 0,
                losses: Number(stat.losses) || 0,
                totalBattles: Number(stat.totalBattles) || 0,
                percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
              })).filter(stat => stat.totalBattles > 0),
              ageStats: (comparison.demographics?.ageStats || []).map(stat => ({
                ageRange: stat.ageRange,
                wins: Number(stat.wins) || 0,
                losses: Number(stat.losses) || 0,
                totalBattles: Number(stat.totalBattles) || 0,
                percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
              })).filter(stat => stat.totalBattles > 0)
            }
          }))
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError('Failed to fetch statistics');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [brandId, startDate, endDate]);

  // Вспомогательная функция для форматирования возрастных диапазонов
  const formatAgeRange = (ageRange) => {
    if (ageRange === "55_plus") return "55+";
    if (ageRange === "under_18") return "Under 18";
    return ageRange;
  };

  // Функция для получения статистики по стране
  const getCountryStats = () => {
    console.log('=== getCountryStats START ===');
    console.log('Selected country:', selectedCountry);
    console.log('Stats object:', stats);

    if (!selectedCountry || selectedCountry === 'all_countries' || !stats?.countryStats) {
      console.log('Early return conditions met:');
      console.log('- selectedCountry:', selectedCountry);
      console.log('- stats?.countryStats exists:', !!stats?.countryStats);
      return null;
    }

    const countryData = stats.countryStats.find(stat => stat.country === selectedCountry);
    console.log('Found country data:', countryData);

    if (!countryData) {
      console.log('No country data found');
      return null;
    }

    // Проверяем наличие battleHistory
    if (!stats?.battleHistory) {
      console.log('No battle history found');
      return {
        totalBattles: countryData.totalBattles || 0,
        wins: countryData.wins || 0,
        demographics: {
          genderStats: countryData.demographics?.genderStats || [],
          ageStats: countryData.demographics?.ageStats || []
        },
        dailyStats: []
      };
    }

    // Фильтруем битвы для выбранной страны
    const countryBattles = stats.battleHistory.filter(battle =>
      (battle.userCountry === selectedCountry || battle.ipCountry === selectedCountry) &&
      new Date(battle.timestamp) >= startDate &&
      new Date(battle.timestamp) <= endDate
    );
    console.log('Filtered country battles:', countryBattles);

    // Создаем массив для хранения статистики по дням
    const statsMap = new Map();

    // Заполняем все даты в диапазоне
    let currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
      const dateKey = format(currentDate, 'yyyy-MM-dd');
      statsMap.set(dateKey, {
        date: dateKey,
        totalBattles: 0,
        wins: 0,
        losses: 0
      });
      currentDate = addDays(currentDate, 1);
    }

    // Заполняем статистику из отфильтрованных битв
    countryBattles.forEach(battle => {
      const dateKey = format(new Date(battle.timestamp), 'yyyy-MM-dd');
      const dayStats = statsMap.get(dateKey);

      if (dayStats) {
        dayStats.totalBattles++;
        if (battle.result === 'win') {
          dayStats.wins++;
        } else {
          dayStats.losses++;
        }
        console.log('Updated stats for date:', dateKey, dayStats);
      }
    });

    // Конвертируем Map в массив и сортируем по дате
    const sortedDailyStats = Array.from(statsMap.values())
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    console.log('Daily stats object:', Object.fromEntries(statsMap));
    console.log('Sorted daily stats:', sortedDailyStats);

    const result = {
      totalBattles: countryData.totalBattles || 0,
      wins: countryData.wins || 0,
      demographics: {
        genderStats: countryData.demographics?.genderStats || [],
        ageStats: countryData.demographics?.ageStats || []
      },
      dailyStats: sortedDailyStats
    };

    console.log('=== getCountryStats RESULT ===', result);
    return result;
  };
  // Функция для получения статистики сравнения
  const getComparisonStats = () => {
    if (!selectedBrand || !stats?.comparisons) {
      return null;
    }

    const comparison = stats.comparisons.find(c => c.competitorBrandId === selectedBrand);
    if (!comparison) {
      return null;
    }

    // Преобразуем даты начала и конца в UTC для корректного сравнения
    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();

    // Получаем битвы только с выбранным брендом из истории и в диапазоне дат
    const relevantBattles = (stats.battleHistory || []).filter(battle => {
      const battleTime = new Date(battle.timestamp).getTime();
      return battle.competitorId === selectedBrand &&
        battleTime >= startDateTime &&
        battleTime <= endDateTime;
    });

    // Создаем Map для группировки по датам
    const battlesMap = new Map();

    // Обрабатываем каждую битву
    relevantBattles.forEach(battle => {
      const dateKey = format(new Date(battle.timestamp), 'yyyy-MM-dd');
      if (!battlesMap.has(dateKey)) {
        battlesMap.set(dateKey, {
          wins: 0,
          losses: 0
        });
      }

      const stats = battlesMap.get(dateKey);
      if (battle.result === 'win') {
        stats.wins++;
      } else {
        stats.losses++;
      }
    });

    // Создаем массив дат между startDate и endDate
    const dates = [];
    let currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
      const dateKey = format(currentDate, 'yyyy-MM-dd');
      const dayStats = battlesMap.get(dateKey) || { wins: 0, losses: 0 };

      dates.push({
        date: dateKey,
        name: format(currentDate, 'MMM dd'),
        wins: dayStats.wins,
        losses: dayStats.losses
      });

      currentDate = addDays(currentDate, 1);
    }

    const hasData = dates.some(date => date.wins > 0 || date.losses > 0);
    return {
      ...comparison,
      dailyStats: dates,
      hasData
    };
  };

  // Вспомогательные функции
  const renderStars = (rating) => {
    const stars = [];

    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        // Полная звезда
        stars.push(<Star key={i} className="text-[#e0ff89] inline w-4 h-4" fill="#e0ff89" />);
      } else if (i === Math.floor(rating) && rating % 1 > 0) {
        // Частично заполненная звезда
        const decimal = rating % 1;
        stars.push(
          <div key={i} className="relative inline-block w-4 h-4">
            <Star className="absolute text-zinc-400 w-4 h-4" fill="#374151" />
            <div className="absolute overflow-hidden" style={{ width: `${decimal * 100}%` }}>
              <Star className="text-[#e0ff89] w-4 h-4" fill="#e0ff89" />
            </div>
          </div>
        );
      } else {
        // Пустая звезда
        stars.push(<Star key={i} className="text-zinc-400 inline w-4 h-4" fill="#374151" />);
      }
    }
    return stars;
  };

  if (loading) return <div className="text-center py-4 text-white">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">{error}</div>;
  if (!stats) return <div className="text-center py-4 text-white">No statistics available.</div>;

  return (
    <Card className="bg-zinc-900 text-white rounded-[20px] overflow-hidden mt-6">
      <CardHeader className="bg-zinc-800 px-4 py-3">
        <div className="flex flex-col items-center">
          <CardTitle className="text-2xl font-bold flex items-center gap-2 mb-2">
            <BarChart2 className="w-5 h-5 text-[#e0ff89]" />
            Brand Statistics
          </CardTitle>
          <div className="flex items-center gap-2">
            {renderStars(stats.rating)}
            <span className="text-xl font-bold">{stats.rating.toFixed(2)}</span>
          </div>
        </div>
      </CardHeader>

      <CardContent className="p-4 space-y-6">
        {/* Основные показатели и график по дням */}
        <div>
          {/* Дата пикер */}
          <div className="flex gap-4 mb-4">
            <div className="flex-1 relative">
              <label className="text-sm text-zinc-400 mb-1 block">Start Date</label>
              <div className="relative">
                <input
                  type="date"
                  value={format(startDate || addDays(new Date(), -30), 'yyyy-MM-dd')}
                  onChange={(e) => {
                    const date = e.target.value ? new Date(e.target.value) : addDays(new Date(), -30);
                    setStartDate(date);
                  }}
                  className="w-full bg-zinc-950 text-white border border-zinc-700 rounded-md p-2 [color-scheme:dark]"
                  style={{
                    '::-webkit-calendar-picker-indicator': {
                      filter: 'invert(1)'
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex-1 relative">
              <label className="text-sm text-zinc-400 mb-1 block">End Date</label>
              <div className="relative">
                <input
                  type="date"
                  value={format(endDate || new Date(), 'yyyy-MM-dd')} // <-- Исправлено на endDate
                  onChange={(e) => {
                    const date = e.target.value ? new Date(e.target.value) : new Date();
                    setEndDate(date); // <-- Исправлено на setEndDate
                  }}
                  className="w-full bg-black text-white border border-zinc-700 rounded-md p-2 [color-scheme:dark]"
                  style={{
                    '::-webkit-calendar-picker-indicator': {
                      filter: 'invert(1)'
                    }
                  }}
                />
              </div>
            </div>
          </div>

          {/* Основные показатели в строку */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="bg-zinc-800 p-4 rounded-xl">
              <div className="mb-4">
                <p className="text-[#e0ff89] text-sm">Total Battles</p>
              </div>
              <p className="text-2xl font-bold">{stats.totalBattles}</p>

              <div className="mt-4 pt-4 border-t border-zinc-700">
                <p className="text-[#e0ff89] text-sm mb-2">Selected Period</p>
                <p className="text-xl font-bold">
                  {stats.dailyStats.reduce((sum, day) => sum + day.battles, 0)}
                </p>
              </div>
            </div>

            <div className="bg-zinc-800 p-4 rounded-xl">
              <div className="mb-4">
                <p className="text-[#e0ff89] text-sm">Total Wins</p>
              </div>
              <p className="text-2xl font-bold">{stats.totalWins}</p>

              <div className="mt-4 pt-4 border-t border-gray-700">
                <p className="text-[#e0ff89] text-sm mb-2">Selected Period</p>
                <p className="text-xl font-bold">
                  {stats.dailyStats.reduce((sum, day) => sum + day.wins, 0)}
                </p>
              </div>
            </div>

            <div className="bg-zinc-800 p-4 rounded-xl col-span-2">
              <div className="flex justify-between items-center mb-4">
                <p className="text-[#e0ff89] text-sm">Win Rate</p>
                <p className="text-sm text-zinc-400">All Time</p>
              </div>
              <p className="text-2xl font-bold">
                {stats.totalBattles > 0
                  ? ((stats.totalWins / stats.totalBattles) * 100).toFixed(1)
                  : '0'}%
              </p>

              <div className="mt-4 pt-4 border-t border-gray-700">
                <div className="flex justify-between items-center">
                  <p className="text-[#e0ff89] text-sm">Selected Period</p>
                  <p className="text-lg font-bold">
                    {(() => {
                      const periodBattles = stats.dailyStats.reduce((sum, day) => sum + day.battles, 0);
                      const periodWins = stats.dailyStats.reduce((sum, day) => sum + day.wins, 0);
                      return periodBattles > 0
                        ? ((periodWins / periodBattles) * 100).toFixed(1)
                        : '0';
                    })()}%
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* График по дням */}
          <div className="bg-zinc-800 p-4 rounded-xl">
            <h4 className="text-sm mb-4">Daily Statistics</h4>
            <div className="h-64">
              {(() => {
                if (!stats || !stats.dailyStats) {
                  return (
                    <div className="flex items-center justify-center h-full text-zinc-400">
                      <p>No data for selected date range</p>
                    </div>
                  );
                }

                // Преобразуем массив в данные для графика
                const chartData = stats.dailyStats.map(day => ({
                  name: day.name,
                  wins: day.wins || 0,
                  losses: day.battles ? (day.battles - (day.wins || 0)) : 0
                }));

                // Отфильтруем по диапазону дат
                const filteredData = chartData.filter(item => {
                  try {
                    const [month, day] = item.name.split(' ');
                    const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(month);
                    const currentYear = startDate.getFullYear();
                    const itemDate = new Date(currentYear, monthIndex, parseInt(day));
                    return itemDate >= startDate && itemDate <= endDate;
                  } catch (error) {
                    console.error("Error processing date:", item.name, error);
                    return false;
                  }
                });

                // Фильтруем дни где есть хотя бы одна битва
                const dataWithBattles = filteredData.filter(day => (day.wins + day.losses) > 0);

                if (dataWithBattles.length === 0) {
                  return (
                    <div className="flex items-center justify-center h-full text-zinc-400">
                      <p>No data for selected date range</p>
                    </div>
                  );
                }

                return (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={dataWithBattles}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 10,
                        bottom: 20,
                      }}
                    >
                      <XAxis
                        dataKey="name"
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: '#9ca3af' }}
                      />
                      <Tooltip
                        cursor={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                        content={({ active, payload }) => {
                          if (!active || !payload?.length) return null;
                          const data = payload[0].payload;
                          return (
                            <div className="bg-black p-3 rounded-xl border border-zinc-800">
                              <p className="text-sm font-bold mb-2">{data.name}</p>
                              <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
                              <p className="text-sm text-[#ef4444]">Losses: {data.losses}</p>
                              <div className="mt-2 pt-2 border-t border-zinc-800">
                                <p className="text-sm text-zinc-400">Total: {data.wins + data.losses}</p>
                              </div>
                            </div>
                          );
                        }}
                      />
                      <Bar
                        dataKey="losses"
                        stackId="stack"
                        fill="#ef4444"
                        radius={[0, 0, 0, 0]}
                      />
                      <Bar
                        dataKey="wins"
                        stackId="stack"
                        fill="#e0ff89"
                        radius={[0, 0, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                );
              })()}
            </div>
          </div>
        </div>

{/* Сравнение брендов */}
<div className="space-y-4">
  <div className="flex items-center justify-between">
    <div className="text-[#e0ff89] text-sm flex items-center gap-2">
      <LineChart className="w-4 h-4" />
      <span className="text-[#e0ff89] text-2xl font-bold">Brand Comparison</span>
    </div>
  </div>

  <div className="relative w-full">
    {/* Мобильный селект */}
    <div className="block md:hidden relative">
      <select
        value={selectedBrand}
        onChange={(e) => {
          const value = e.target.value;
          setSelectedBrand(value);
          setComparisonTab('overview'); // Сбрасываем таб при смене бренда
        }}
        className="bg-zinc-950 text-white border-zinc-800 border-2 placeholder-zinc-500 w-full p-2 appearance-none rounded"
      >
        <option value="" disabled>Select Brand to Compare</option>
        {stats?.comparisons && stats.comparisons.length > 0 ? (
          stats.comparisons.map((comp) => (
            <option key={comp.competitorBrandId} value={comp.competitorBrandId}>
              {comp.competitorBrand}
            </option>
          ))
        ) : (
          <option value="no_data" disabled>No comparisons available</option>
        )}
      </select>
      
      {/* Стрелка вниз для мобильного селекта */}
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <svg className="w-5 h-5 text-zinc-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </div>
    </div>

    {/* Десктопный селект с иконками */}
    <div className="hidden md:block">
      <Select
        value={selectedBrand}
        onValueChange={(value) => {
          setSelectedBrand(value);
          setComparisonTab('overview'); // Сбрасываем таб при смене бренда
        }}
      >
        <SelectTrigger className="bg-zinc-950 text-white border-zinc-800 border-2">
          <SelectValue placeholder="Select Brand to Compare">
            {selectedBrand && stats?.comparisons && (
              <div className="flex items-center gap-2">
                {(() => {
                  const selected = stats.comparisons.find(c => c.competitorBrandId === selectedBrand);
                  if (selected?.competitorLogo) {
                    return (
                      <img
                        src={selected.competitorLogo}
                        alt={selected.competitorBrand}
                        className="w-6 h-6 rounded-full object-cover"
                      />
                    );
                  }
                  return null;
                })()}
                <span>
                  {stats.comparisons.find(c => c.competitorBrandId === selectedBrand)?.competitorBrand || "Select Brand to Compare"}
                </span>
              </div>
            )}
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="bg-zinc-900 border-zinc-800 z-[999]">
          {stats?.comparisons && stats.comparisons.length > 0 ? (
            stats.comparisons.map((comp) => (
              <SelectItem
                key={comp.competitorBrandId}
                value={comp.competitorBrandId}
                className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
              >
                <div className="flex items-center gap-2">
                  {comp.competitorLogo && (
                    <img
                      src={comp.competitorLogo}
                      alt={comp.competitorBrand}
                      className="w-6 h-6 rounded-full object-cover"
                    />
                  )}
                  <span>{comp.competitorBrand}</span>
                </div>
              </SelectItem>
            ))
          ) : (
            <SelectItem value="no_data" disabled>
              <span className="text-zinc-500">No comparisons available</span>
            </SelectItem>
          )}
        </SelectContent>
      </Select>
    </div>
  </div>

  {selectedBrand && (
    <>
      {/* Табы для переключения видов статистики */}
      <div className="border-b border-zinc-700 mb-4">
        <div className="flex space-x-2 overflow-x-auto">
          <button 
            className={`px-4 py-2 ${comparisonTab === 'overview' ? 'border-b-2 border-[#e0ff89] text-[#e0ff89]' : 'text-zinc-400'}`}
            onClick={() => setComparisonTab('overview')}
          >
            Overview
          </button>
          <button 
            className={`px-4 py-2 ${comparisonTab === 'demographics' ? 'border-b-2 border-[#e0ff89] text-[#e0ff89]' : 'text-zinc-400'}`}
            onClick={() => setComparisonTab('demographics')}
          >
            Demographics
          </button>
          <button 
            className={`px-4 py-2 ${comparisonTab === 'countries' ? 'border-b-2 border-[#e0ff89] text-[#e0ff89]' : 'text-zinc-400'}`}
            onClick={() => setComparisonTab('countries')}
          >
            Countries
          </button>
        </div>
      </div>
      
      {/* Контент для первого таба - Overview */}
      {comparisonTab === 'overview' && (
        <div className="bg-zinc-800 p-4 rounded-xl">
          <div className="mb-4">
            <h4 className="text-lg font-bold">Comparison Results</h4>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <div className="bg-zinc-900 p-4 rounded-xl">
                <div className="mb-4">
                  <p className="text-[#e0ff89] text-sm">Total Wins</p>
                </div>
                <p className="text-2xl font-bold">
                  {(() => {
                    const stats = getComparisonStats();
                    if (!stats || !stats.dailyStats) return 0;

                    return stats.dailyStats
                      .filter(day => {
                        const dayDate = new Date(day.date);
                        return dayDate >= startDate && dayDate <= endDate;
                      })
                      .reduce((sum, day) => sum + day.wins, 0);
                  })()}
                </p>
              </div>
              <div className="bg-zinc-900 p-4 rounded-xl">
                <div className="mb-4">
                  <p className="text-red-400 text-sm">Total Losses</p>
                </div>
                <p className="text-2xl font-bold">
                  {(() => {
                    const stats = getComparisonStats();
                    if (!stats || !stats.dailyStats) return 0;

                    return stats.dailyStats
                      .filter(day => {
                        const dayDate = new Date(day.date);
                        return dayDate >= startDate && dayDate <= endDate;
                      })
                      .reduce((sum, day) => sum + day.losses, 0);
                  })()}
                </p>
              </div>
            </div>
          </div>

          {/* График сравнения */}
          <div className="h-64">
            {(() => {
              const comparisonStats = stats.comparisons?.find(c =>
                c.competitorBrandId === selectedBrand
              );

              if (!comparisonStats || !selectedBrand) {
                console.log("No data to render chart:", {
                  comparisonStats: !!comparisonStats,
                  selectedBrand: !!selectedBrand
                });
                return (
                  <div className="flex items-center justify-center h-full text-zinc-400">
                    <p>Please select a brand to compare</p>
                  </div>
                );
              }

              const chartData = comparisonStats.dailyStats.map(day => {
                const dataPoint = {
                  name: format(new Date(day.date), 'MMM dd'),
                  total: day.wins + day.losses,
                  // Сохраняем для тултипа
                  wins: day.wins,
                  losses: day.losses
                };
                console.log("Generated chart data point:", dataPoint);
                return dataPoint;
              });

              // Проверяем, есть ли данные для отображения
              const hasData = chartData.some(data => data.wins > 0 || data.losses > 0);
              if (!hasData) {
                return (
                  <div className="flex items-center justify-center h-full text-zinc-400">
                    <p>No comparison data available for selected date range</p>
                  </div>
                );
              }

              return (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 10,
                      left: 10,
                      bottom: 20,
                    }}
                  >
                    <XAxis
                      dataKey="name"
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: '#9ca3af' }}
                    />
                    <Tooltip
                      cursor={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                      content={({ active, payload }) => {
                        if (!active || !payload?.length) return null;
                        const data = payload[0].payload;
                        return (
                          <div className="bg-zinc-950 p-3 rounded-xl border border-zinc-800">
                            <p className="text-sm font-bold mb-2">{data.name}</p>
                            <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
                            <p className="text-sm text-[#ef4444]">Losses: {data.losses}</p>
                            <div className="mt-2 pt-2 border-t border-zinc-800">
                              <p className="text-sm text-zinc-400">Total: {data.wins + data.losses}</p>
                            </div>
                          </div>
                        );
                      }}
                    />
                    <Bar
                      dataKey="losses"
                      stackId="stack"
                      fill="#ef4444"
                      radius={[0, 0, 0, 0]}
                    />
                    <Bar
                      dataKey="wins"
                      stackId="stack"
                      fill="#e0ff89"
                      radius={[0, 0, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              );
            })()}
          </div>
        </div>
      )}
      
      {/* Контент для второго таба - Demographics */}
      {comparisonTab === 'demographics' && (
        <div className="bg-zinc-800 p-4 rounded-xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Гендерная статистика */}
            <div className="bg-zinc-900 p-4 rounded-xl">
              <h4 className="text-sm mb-4">Gender Win Rates</h4>
              <div className="space-y-3">
                {(() => {
                  const comparisonStats = getComparisonStats();
                  if (!comparisonStats || !comparisonStats.demographics?.genderStats?.length) {
                    return (
                      <div className="text-center text-zinc-500 py-4">
                        No gender data available
                      </div>
                    );
                  }
                  
                  return comparisonStats.demographics.genderStats.map(stat => (
                    <div key={stat.gender} className="flex justify-between items-center">
                      <span className="capitalize">{stat.gender}</span>
                      <div className="flex items-center gap-2">
                        <span className="text-[#e0ff89]">{((stat.wins / stat.totalBattles) * 100).toFixed(1)}%</span>
                        <span className="text-zinc-500 text-xs">({stat.totalBattles} battles)</span>
                      </div>
                    </div>
                  ));
                })()}
              </div>
              
              {/* Гендерный график */}
              <div className="h-64 mt-4">
                {(() => {
                  const comparisonStats = getComparisonStats();
                  if (!comparisonStats || !comparisonStats.demographics?.genderStats?.length) {
                    return null;
                  }
                  
                  const chartData = comparisonStats.demographics.genderStats.map(stat => ({
                    name: stat.gender,
                    wins: stat.wins,
                    losses: stat.losses,
                    percentage: ((stat.wins / stat.totalBattles) * 100).toFixed(1)
                  }));
                  
                  return (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={chartData}
                        margin={{
                          top: 20,
                          right: 10,
                          left: 10,
                          bottom: 20,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          axisLine={false}
                          tickLine={false}
                          tick={{ fill: '#9ca3af' }}
                        />
                        <Tooltip
                          cursor={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                          content={({ active, payload }) => {
                            if (!active || !payload?.length) return null;
                            const data = payload[0].payload;
                            return (
                              <div className="bg-zinc-950 p-3 rounded-xl border border-zinc-800">
                                <p className="text-sm font-bold mb-2 capitalize">{data.name}</p>
                                <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
                                <p className="text-sm text-[#ef4444]">Losses: {data.losses}</p>
                                <div className="mt-2 pt-2 border-t border-zinc-800">
                                  <p className="text-sm text-zinc-400">Win Rate: {data.percentage}%</p>
                                </div>
                              </div>
                            );
                          }}
                        />
                        <Bar
                          dataKey="losses"
                          stackId="stack"
                          fill="#ef4444"
                          radius={[0, 0, 0, 0]}
                        />
                        <Bar
                          dataKey="wins"
                          stackId="stack"
                          fill="#e0ff89"
                          radius={[0, 0, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  );
                })()}
              </div>
            </div>
            
            {/* Возрастная статистика */}
            <div className="bg-zinc-900 p-4 rounded-xl">
              <h4 className="text-sm mb-4">Age Win Rates</h4>
              <div className="space-y-3">
                {(() => {
                  const comparisonStats = getComparisonStats();
                  if (!comparisonStats || !comparisonStats.demographics?.ageStats?.length) {
                    return (
                      <div className="text-center text-zinc-500 py-4">
                        No age data available
                      </div>
                    );
                  }
                  
                  return comparisonStats.demographics.ageStats.map(stat => (
                    <div key={stat.ageRange} className="flex justify-between items-center">
                      <span>{formatAgeRange(stat.ageRange)}</span>
                      <div className="flex items-center gap-2">
                        <span className="text-[#e0ff89]">{((stat.wins / stat.totalBattles) * 100).toFixed(1)}%</span>
                        <span className="text-zinc-500 text-xs">({stat.totalBattles} battles)</span>
                      </div>
                    </div>
                  ));
                })()}
              </div>
              
              {/* Возрастной график */}
              <div className="h-64 mt-4">
                {(() => {
                  const comparisonStats = getComparisonStats();
                  if (!comparisonStats || !comparisonStats.demographics?.ageStats?.length) {
                    return null;
                  }
                  
                  const chartData = comparisonStats.demographics.ageStats.map(stat => ({
                    name: formatAgeRange(stat.ageRange),
                    wins: stat.wins,
                    losses: stat.losses,
                    percentage: ((stat.wins / stat.totalBattles) * 100).toFixed(1)
                  }));
                  
                  return (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={chartData}
                        margin={{
                          top: 20,
                          right: 10,
                          left: 10,
                          bottom: 20,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          axisLine={false}
                          tickLine={false}
                          tick={{ fill: '#9ca3af' }}
                        />
                        <Tooltip
                          cursor={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                          content={({ active, payload }) => {
                            if (!active || !payload?.length) return null;
                            const data = payload[0].payload;
                            return (
                              <div className="bg-zinc-950 p-3 rounded-xl border border-zinc-800">
                                <p className="text-sm font-bold mb-2">{data.name}</p>
                                <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
                                <p className="text-sm text-[#ef4444]">Losses: {data.losses}</p>
                                <div className="mt-2 pt-2 border-t border-zinc-800">
                                  <p className="text-sm text-zinc-400">Win Rate: {data.percentage}%</p>
                                </div>
                              </div>
                            );
                          }}
                        />
                        <Bar
                          dataKey="losses"
                          stackId="stack"
                          fill="#ef4444"
                          radius={[0, 0, 0, 0]}
                        />
                        <Bar
                          dataKey="wins"
                          stackId="stack"
                          fill="#e0ff89"
                          radius={[0, 0, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  );
                })()}
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Контент для третьего таба - Countries */}
      {comparisonTab === 'countries' && (
        <div className="bg-zinc-800 p-4 rounded-xl">
          <h4 className="text-sm mb-4">Country Statistics</h4>
          {(() => {
            const comparisonStats = getComparisonStats();
            if (!comparisonStats || !comparisonStats.countryStats?.length) {
              return (
                <div className="text-center text-zinc-500 py-4">
                  No country data available
                </div>
              );
            }
            
            // Отсортируем страны по количеству битв (от большего к меньшему)
            const sortedCountries = [...comparisonStats.countryStats].sort(
              (a, b) => b.totalBattles - a.totalBattles
            );
            
            return (
              <div className="space-y-4">
                {/* Таблица со статистикой по странам */}
<div className="overflow-x-auto max-h-64 scrollbar-thin scrollbar-thumb-zinc-700 scrollbar-track-zinc-900">
  <table className="w-full text-sm">
    <thead>
      <tr className="border-b border-zinc-700">
        <th className="py-2 text-left sticky top-0 bg-zinc-800 z-10">Country</th>
        <th className="py-2 text-right sticky top-0 bg-zinc-800 z-10">Battles</th>
        <th className="py-2 text-right sticky top-0 bg-zinc-800 z-10">Wins</th>
        <th className="py-2 text-right sticky top-0 bg-zinc-800 z-10">Win Rate</th>
      </tr>
    </thead>
    <tbody>
      {sortedCountries.map(country => (
        <tr key={country.country} className="border-b border-zinc-700">
          <td className="py-2">
            <div className="flex items-center gap-2">
              <div className="relative group">
                {getCountryFlag(country.country)}
                <div className="absolute hidden group-hover:block left-0 bottom-full mb-2 px-2 py-1 bg-zinc-900 text-white text-xs rounded whitespace-nowrap z-20">
                  {getCountryLabel(country.country)}
                </div>
              </div>
              <span className="whitespace-nowrap hidden md:inline">{getCountryLabel(country.country)}</span>
            </div>
          </td>
          <td className="py-2 text-right">{country.totalBattles}</td>
          <td className="py-2 text-right text-[#e0ff89]">{country.wins}</td>
          <td className="py-2 text-right">{((country.wins / country.totalBattles) * 100).toFixed(1)}%</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
                
{/* График по странам */}
<div className="h-64 mt-4">
  <ResponsiveContainer width="100%" height="100%">
    <BarChart
      data={sortedCountries.slice(0, 10).map(country => ({
        name: country.country,
        countryName: getCountryLabel(country.country),
        wins: country.wins,
        losses: country.totalBattles - country.wins,
        winRate: ((country.wins / country.totalBattles) * 100).toFixed(1)
      }))}
      margin={{
        top: 20,
        right: 10,
        left: 10,
        bottom: 20,
      }}
    >
      <XAxis 
        dataKey="countryName" 
        axisLine={false} 
        tickLine={false} 
        tick={{ fill: '#9ca3af' }}
      />
      <Tooltip
        cursor={{ fill: 'rgba(0, 0, 0, 0.5)' }}
        content={({ active, payload }) => {
          if (!active || !payload?.length) return null;
          const data = payload[0].payload;
          return (
            <div className="bg-zinc-950 p-3 rounded-xl border border-zinc-800">
              <div className="flex items-center gap-2 mb-2">
                {getCountryFlag(data.name)}
                <p className="text-sm font-bold">{data.countryName}</p>
              </div>
              <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
              <p className="text-sm text-[#ef4444]">Losses: {data.losses}</p>
              <div className="mt-2 pt-2 border-t border-zinc-800">
                <p className="text-sm text-zinc-400">Win Rate: {data.winRate}%</p>
              </div>
            </div>
          );
        }}
      />
      <Bar
        dataKey="losses"
        stackId="stack"
        fill="#ef4444"
        radius={[0, 0, 0, 0]}
      />
      <Bar
        dataKey="wins"
        stackId="stack"
        fill="#e0ff89"
        radius={[0, 0, 0, 0]}
      />
    </BarChart>
  </ResponsiveContainer>
</div>
              </div>
            );
          })()}
        </div>
      )}
    </>
  )}
</div>

        {/* Социальная активность */}
        <div className="space-y-4">
          <p className="text-[#e0ff89] text-sm flex items-center gap-2">
            <Share2 className="w-4 h-4" />
            <p className="text-[#e0ff89] text-2xl font-bold">Social Media</p>
          </p>

          <div className="bg-zinc-800 p-4 rounded-xl">
            {/* Статистика кликов */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              {/* Website */}
              <div
                className="relative group flex items-center space-x-2 bg-zinc-900 p-3 rounded-xl cursor-pointer"
              >
                <Globe className="w-6 h-6 text-[#e0ff89]" />
                <span className="text-xl font-bold">
                  {(() => {
                    const websiteClicks = stats.dailyStats
                      .filter(day => {
                        const [month, dayNum] = day.name.split(' ');
                        const monthIndex = getMonthIndex(day.name);
                        const dayDate = new Date(startDate.getFullYear(), monthIndex, parseInt(dayNum));
                        return dayDate >= startDate && dayDate <= endDate;
                      })
                      .reduce((sum, day) => sum + (day.websiteClicks || 0), 0);
                    return websiteClicks;
                  })()}
                </span>
                <div className="absolute hidden group-hover:block bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-zinc-950 rounded-xl border border-zinc-800 text-sm whitespace-nowrap">
                  Website
                </div>
              </div>

              {/* Социальные сети */}
              {stats.socialMedia?.platforms?.map((platform) => {
                const Icon = socialIcons[platform.name];
                const platformClicks = stats.dailyStats
                  .filter(day => {
                    const [month, dayNum] = day.name.split(' ');
                    const monthIndex = getMonthIndex(day.name);
                    const dayDate = new Date(startDate.getFullYear(), monthIndex, parseInt(dayNum));
                    return dayDate >= startDate && dayDate <= endDate;
                  })
                  .reduce((sum, day) => {
                    const platformKey = `${platform.name.toLowerCase()}Clicks`;
                    return sum + (day[platformKey] || 0);
                  }, 0) || (stats.socialMediaClicks?.find(p => p.platform === platform.name)?.total || 0);

                return (
                  <div
                    key={platform.name}
                    className="relative group flex items-center space-x-2 bg-zinc-900 p-3 rounded-xl cursor-pointer"
                  >
                    <Icon className="w-6 h-6 text-zinc-400" /> {/* Фирменный зеленый цвет для всех иконок */}
                    <span className="text-xl font-bold">{platformClicks}</span>
                    <div className="absolute hidden group-hover:block bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-zinc-950 rounded-xl border border-zinc-700 text-zinc-300 text-sm whitespace-nowrap">
                      {platform.name}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* График активности */}
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={(() => {
                    const filteredData = stats.dailyStats.filter(day => {
                      const [month, dayNum] = day.name.split(' ');
                      const monthIndex = getMonthIndex(day.name);
                      const dayDate = new Date(startDate.getFullYear(), monthIndex, parseInt(dayNum));
                      return dayDate >= startDate && dayDate <= endDate;
                    });

                    const transformedData = filteredData.map(day => {
                      const [month, dayNum] = day.name.split(' ');
                      const monthIndex = getMonthIndex(day.name);
                      const formattedDate = new Date(startDate.getFullYear(), monthIndex, parseInt(dayNum));

                      const dayData = {
                        name: day.name,
                        websiteClicks: day.websiteClicks || 0
                      };

                      if (stats.socialMediaClicks) {
                        stats.socialMediaClicks.forEach(platform => {
                          const platformKey = `${platform.platform.toLowerCase()}Clicks`;
                          const platformClicks = platform.history.filter(click => {
                            const clickDate = new Date(click.timestamp);
                            return format(clickDate, 'yyyy-MM-dd') === format(formattedDate, 'yyyy-MM-dd');
                          }).length;

                          if (platformClicks > 0) {
                            dayData[platformKey] = platformClicks;
                          }
                        });
                      }
                      return dayData;
                    });

                    return transformedData.filter(day => {
                      const totalClicks = Object.values(day).reduce((sum, value) => {
                        return typeof value === 'number' ? sum + value : sum;
                      }, 0) - (day.name ? 1 : 0);
                      return totalClicks > 0;
                    });
                  })()}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#9ca3af' }}
                  />
                  <Tooltip
                    cursor={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                    content={({ active, payload, label }) => {
                      if (!active || !payload?.length) return null;

                      const nonZeroClicks = payload.filter(p => p.value > 0);
                      const total = nonZeroClicks.reduce((sum, entry) => sum + entry.value, 0);

                      return (
                        <div className="bg-zinc-950 p-3 rounded-xl border border-zinc-700">
                          <p className="text-sm text-zinc-300 font-bold mb-2">{label}</p>
                          {nonZeroClicks.map((entry, index) => (
                            <p key={index} className="text-sm text-zinc-300"> {/* Унифицированный серый цвет для всех */}
                              <span className="text-[#e0ff89]">{entry.name.replace('Clicks', '')}</span>: {entry.value}
                            </p>
                          ))}
                          <div className="mt-2 pt-2 border-t border-zinc-700">
                            <p className="text-sm text-zinc-400">Total: {total}</p>
                          </div>
                        </div>
                      );
                    }}
                  />
                  {(() => {
                    const allBars = [
                      { key: 'websiteClicks', name: 'Website', fill: '#e0ff89' },
                      ...stats.socialMediaClicks.map(platform => ({
                        key: `${platform.platform.toLowerCase()}Clicks`,
                        name: platform.platform,
                        fill: getPlatformColor(platform.platform)
                      }))
                    ];

                    return allBars.map(bar => (
                      <Bar
                        key={bar.key}
                        dataKey={bar.key}
                        name={bar.name}
                        fill={bar.fill}
                        stackId="a"
                        radius={[0, 0, 0, 0]}
                      />
                    ));
                  })()}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Демографическая статистика */}
        <div className="space-y-4">
          <p className="text-[#e0ff89] text-sm flex items-center gap-2">
            <Users className="w-4 h-4" />
            <p className="text-[#e0ff89] text-2xl font-bold">Demographics</p>
          </p>

          {/* Статистика по полу */}
          <div className="bg-zinc-800 p-4 rounded-xl">
            <h4 className="text-sm mb-4">Gender Statistics</h4>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {stats.demographics.genderStats.map((stat) => (
                <div key={stat.gender} className="bg-zinc-900 p-3 rounded-lg">
                  <p className="text-sm capitalize">{stat.gender}</p>
                  <p className="text-2xl font-bold">
                    {((stat.wins / stat.totalBattles) * 100).toFixed(1)}%
                  </p>
                  <p className="text-sm text-zinc-400">
                    {stat.totalBattles} battles
                  </p>
                </div>
              ))}
            </div>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={stats.demographics.genderStats.map(stat => ({
                    name: stat.gender,
                    wins: stat.wins,
                    losses: stat.losses,
                    total: stat.totalBattles
                  }))}
                  margin={{
                    top: 20,
                    right: 10,
                    left: 10,
                    bottom: 20,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#9ca3af' }}
                  />
                  <Tooltip
                    cursor={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                    content={({ active, payload }) => {
                      if (!active || !payload?.length) return null;
                      const data = payload[0].payload;
                      return (
                        <div className="bg-black p-3 rounded-xl border border-zinc-800">
                          <p className="text-sm font-bold mb-2">{data.name}</p>
                          <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
                          <p className="text-sm text-[#ef4444]">Losses: {data.losses}</p>
                          <div className="mt-2 pt-2 border-t border-zinc-800">
                            <p className="text-sm text-zinc-400">Total: {data.wins + data.losses}</p>
                          </div>
                        </div>
                      );
                    }}
                  />
                  <Bar
                    dataKey="losses"
                    stackId="stack"
                    fill="#ef4444"
                    radius={[0, 0, 0, 0]}
                  />
                  <Bar
                    dataKey="wins"
                    stackId="stack"
                    fill="#e0ff89"
                    radius={[0, 0, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Статистика по возрасту */}
          <div className="bg-zinc-800 p-4 rounded-xl">
            <h4 className="text-sm mb-4">Age Statistics</h4>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {stats.demographics.ageStats.map((stat) => (
                <div key={stat.ageRange} className="bg-zinc-900 p-3 rounded-lg">
                  <p className="text-sm">{formatAgeRange(stat.ageRange)}</p>
                  <p className="text-2xl font-bold text-white">
                    {((stat.wins / stat.totalBattles) * 100).toFixed(1)}%
                  </p>
                  <p className="text-sm text-zinc-400">
                    {stat.totalBattles} battles
                  </p>
                </div>
              ))}
            </div>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={stats.demographics.ageStats.map(stat => ({
                    name: formatAgeRange(stat.ageRange),
                    wins: stat.wins,
                    losses: stat.losses,
                    total: stat.totalBattles
                  }))}
                  margin={{
                    top: 20,
                    right: 10,
                    left: 10,
                    bottom: 20,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#9ca3af' }}
                  />
                  <Tooltip
                    cursor={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                    content={({ active, payload }) => {
                      if (!active || !payload?.length) return null;
                      const data = payload[0].payload;
                      return (
                        <div className="bg-black p-3 rounded-xl border border-zinc-800">
                          <p className="text-sm font-bold mb-2">{data.name}</p>
                          <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
                          <p className="text-sm text-[#ef4444]">Losses: {data.losses}</p>
                          <div className="mt-2 pt-2 border-t border-zinc-8000">
                            <p className="text-sm text-zinc-400">Total: {data.wins + data.losses}</p>
                          </div>
                        </div>
                      );
                    }}
                  />
                  <Bar
                    dataKey="losses"
                    stackId="stack"
                    fill="#ef4444"
                    radius={[0, 0, 0, 0]}
                  />
                  <Bar
                    dataKey="wins"
                    stackId="stack"
                    fill="#e0ff89"
                    radius={[0, 0, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Статистика по странам */}
        <div className="space-y-4">
          <p className="text-[#e0ff89] text-sm flex items-center gap-2">
            <Globe className="w-4 h-4" />
            <p className="text-[#e0ff89] text-2xl font-bold">Country Statistics</p>
          </p>

          <div className="relative w-full">
            {/* Мобильный селект */}
            <div className="block md:hidden relative">
              <select
                value={selectedCountry}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedCountry(value);
                }}
                className="bg-zinc-950 text-white border-zinc-800 border-2 placeholder-zinc-500 w-full p-2 appearance-none rounded"
              >
                <option value="all_countries">All Countries</option>
                {stats?.countryStats?.length > 0 ? (
                  stats.countryStats.map((stat) => (
                    <option key={stat.country} value={stat.country || 'undefined'}>
                      {getCountryLabel(stat.country)}
                    </option>
                  ))
                ) : (
                  <option value="no_data" disabled>No countries available</option>
                )}
              </select>

              {/* Стрелка вниз для мобильного селекта */}
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg className="w-5 h-5 text-zinc-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </div>
            </div>

            {/* Десктопный селект с флагами */}
            <div className="hidden md:block">
              <Select value={selectedCountry} onValueChange={setSelectedCountry}>
                <SelectTrigger className="bg-zinc-950 text-white border-zinc-800 border-2">
                  <SelectValue placeholder="Select Country">
                    {selectedCountry && (
                      <div className="flex items-center gap-2">
                        {getCountryFlag(selectedCountry)}
                        <span>{getCountryLabel(selectedCountry)}</span>
                      </div>
                    )}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-zinc-900 border-zinc-800 z-[999] max-h-[200px] overflow-y-auto">
                  <SelectItem
                    value="all_countries"
                    className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
                  >
                    <div className="flex items-center gap-2">
                      {getCountryFlag('all_countries')}
                      <span>All Countries</span>
                    </div>
                  </SelectItem>
                  {stats?.countryStats?.length > 0 ? (
                    stats.countryStats.map((stat) => (
                      <SelectItem
                        key={stat.country}
                        value={stat.country || 'undefined'}
                        className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
                      >
                        <div className="flex items-center gap-2">
                          {getCountryFlag(stat.country)}
                          <span>{getCountryLabel(stat.country)}</span>
                        </div>
                      </SelectItem>
                    ))
                  ) : (
                    <SelectItem value="no_data">No countries available</SelectItem>
                  )}
                </SelectContent>
              </Select>
            </div>
          </div>

          {selectedCountry !== 'all' && (
            <div className="space-y-4">
              {/* Основные показатели страны */}
              {getCountryStats() ? (
                <>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div className="bg-zinc-800 p-4 rounded-xl">
                      <p className="text-sm text-zinc-400">Total Battles</p>
                      <p className="text-2xl font-bold">
                        {getCountryStats()?.totalBattles || 0}
                      </p>
                    </div>
                    <div className="bg-zinc-800 p-4 rounded-xl">
                      <p className="text-sm text-zinc-400">Total Wins</p>
                      <p className="text-2xl font-bold text-[#e0ff89]">
                        {getCountryStats()?.wins || 0}
                      </p>
                    </div>
                  </div>
                  <div className="bg-zinc-800 p-4 rounded-xl mb-4">
                    <div className="flex justify-between items-center">
                      <p className="text-[#e0ff89] text-2xl">Win Rate</p>
                      <p className="text-2xl font-bold">
                        {getCountryStats()?.totalBattles ?
                          ((getCountryStats().wins / getCountryStats().totalBattles) * 100).toFixed(1)
                          : '0'}%
                      </p>
                    </div>
                  </div>

                  {/* Демографическая статистика страны */}
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-zinc-800 p-4 rounded-xl">
                      <h4 className="text-sm mb-3">Gender</h4>
                      <div className="space-y-2">
                        {getCountryStats()?.demographics?.genderStats?.map((stat) => (
                          <div key={stat.gender} className="flex justify-between items-center">
                            <span className="text-sm capitalize">{stat.gender}</span>
                            <span className="text-sm text-[#e0ff89]">
                              {((stat.wins / (stat.totalBattles || 1)) * 100).toFixed(1)}%
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="bg-zinc-800 p-4 rounded-xl">
                      <h4 className="text-sm mb-3">Age</h4>
                      <div className="space-y-2">
                        {getCountryStats()?.demographics?.ageStats?.map((stat) => (
                          <div key={stat.ageRange} className="flex justify-between items-center">
                            <span className="text-sm">{formatAgeRange(stat.ageRange)}</span>
                            <span className="text-sm text-[#e0ff89]">
                              {((stat.wins / (stat.totalBattles || 1)) * 100).toFixed(1)}%
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {/* График по дням */}
              {getCountryStats() && (
                <div className="bg-zinc-800 p-4 rounded-xl">
                  <h4 className="text-sm mb-4">Daily Statistics</h4>
                  <div className="h-64">
                    {(() => {
                      console.log('=== Rendering country chart ===');

                      if (!selectedCountry || selectedCountry === 'all_countries') {
                        return (
                          <div className="flex items-center justify-center h-full text-zinc-400">
                            <p>Please select a country to view statistics</p>
                          </div>
                        );
                      }

                      const countryStats = getCountryStats();
                      if (!countryStats || !countryStats.dailyStats) {
                        return (
                          <div className="flex items-center justify-center h-full text-zinc-400">
                            <p>No data available for this country</p>
                          </div>
                        );
                      }

                      // Фильтруем дни только с данными
                      const chartData = countryStats.dailyStats
                        .filter(day => day.totalBattles > 0)
                        .map(day => ({
                          name: format(new Date(day.date), 'MMM dd'),
                          wins: day.wins,
                          losses: day.losses,
                          total: day.totalBattles
                        }));

                      if (chartData.length === 0) {
                        return (
                          <div className="flex items-center justify-center h-full text-zinc-400">
                            <p>No battles data for selected period</p>
                          </div>
                        );
                      }

                      return (
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={chartData}
                            margin={{
                              top: 20,
                              right: 10,
                              left: 10,
                              bottom: 20,
                            }}
                          >
                            <XAxis
                              dataKey="name"
                              axisLine={false}
                              tickLine={false}
                              tick={{ fill: '#9ca3af' }}
                            />
                            <Tooltip
                              cursor={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                              content={({ active, payload }) => {
                                if (!active || !payload?.length) return null;
                                const data = payload[0].payload;
                                return (
                                  <div className="bg-black p-3 rounded-xl border border-zinc-800">
                                    <p className="text-sm font-bold mb-2">{data.name}</p>
                                    <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
                                    <p className="text-sm text-[#ef4444]">Losses: {data.losses}</p>
                                    <div className="mt-2 pt-2 border-t border-zinc-800">
                                      <p className="text-sm text-zinc-400">Total: {data.total}</p>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Bar
                              dataKey="losses"
                              stackId="stack"
                              fill="#ef4444"
                              radius={[0, 0, 0, 0]}
                            />
                            <Bar
                              dataKey="wins"
                              stackId="stack"
                              fill="#e0ff89"
                              radius={[0, 0, 0, 0]}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      );
                    })()}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default BrandStatistics;