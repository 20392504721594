import React, { useState, useEffect } from 'react';
import { Avatar, AvatarImage, AvatarFallback } from "./ui/avatar";
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import AddBrand from './AddBrand';
import toast from './CustomToast';
import axios from 'axios';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./ui/dialog";
import BrandStatistics from './BrandStatistics';
import TonWallet from './TonWallet';
import { TonConnectButton, useTonAddress, useTonWallet } from '@tonconnect/ui-react';
import ProfileSettings from './ProfileSettings';
import BoringAvatar from 'boring-avatars';
import { Camera, RotateCcw } from 'lucide-react';
import UserAvatar from './UserAvatar';

// Создаем основной компонент
const UserProfile = ({ user: initialUser, onProfileUpdate }) => {
  const [user, setUser] = useState(initialUser?.user || initialUser); // Исправляем здесь
  const [showAddBrandForm, setShowAddBrandForm] = useState(false);
  const [userBrands, setUserBrands] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deletingBrandId, setDeletingBrandId] = useState(null);
  const [editingBrandId, setEditingBrandId] = useState(null);
  const [error, setError] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [levelProgress, setLevelProgress] = useState(0);
  const [isAvatarUploadOpen, setIsAvatarUploadOpen] = useState(false);
  const [ranks, setRanks] = useState([]);

  // Получаем данные кошелька
  const address = useTonAddress();
  const wallet = useTonWallet();
  const walletAddress = useTonAddress();

  const colors = ["#c5ff89", "#b8fcff", "#63526e", "#5db5ff", "#fdb9ff"];

  useEffect(() => {
    const fetchUserBrands = async () => {
      try {
        setError(null);
        const response = await axios.get(`/api/brands/user/${user._id}`);

        // Обработка ответа
        if (response.data && Array.isArray(response.data)) {
          // Добавляем id если его нет
          const brandsWithIds = response.data.map(brand => ({
            ...brand,
            id: brand._id || brand.id // Убедимся что у каждого бренда есть id
          }));
          setUserBrands(brandsWithIds);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching user brands:', error);
        setError(error.message);
        setUserBrands([]);
      }
    };

    if (user && user._id) {
      fetchUserBrands();
    }
  }, [user]);

  useEffect(() => {
    console.log('Current brands:', userBrands);
  }, [userBrands]);

useEffect(() => {
  const calculateLevelProgress = () => {
    const battles = user?.battles || 0;
    const currentLevel = user?.level || 1;
    
    // Получаем границы для текущего уровня
    const { lowerBoundary, upperBoundary } = getLevelBoundaries(currentLevel);
    
    // Рассчитываем прогресс
    const battlesInCurrentLevel = battles - lowerBoundary;
    const totalBattlesForCurrentLevel = upperBoundary - lowerBoundary;
    const progress = Math.min(100, Math.max(0, (battlesInCurrentLevel / totalBattlesForCurrentLevel) * 100));
    
    setLevelProgress(progress);
  };

  calculateLevelProgress();
}, [user]);

// Вспомогательная функция для определения границ уровня
const getLevelBoundaries = (level) => {
  let lowerBoundary = 0;
  let upperBoundary = 0;
  
  for (let i = 1; i <= level; i++) {
    const battlesForLevel = i === 1 ? 1000 : 1000 + (i - 1) * 200;
    upperBoundary += battlesForLevel;
    
    if (i < level) {
      lowerBoundary = upperBoundary;
    }
  }
  
  return { lowerBoundary, upperBoundary };
};

  useEffect(() => {
    const fetchRanks = async () => {
      try {
        const response = await axios.get('/api/ranks');
        if (response.data && Array.isArray(response.data)) {
          setRanks(response.data);
        }
      } catch (error) {
        console.error('Error fetching ranks:', error);
      }
    };

    fetchRanks();
  }, []);

  const handleAddBrand = async (brandData) => {
    try {
      // Проверяем email
      if (!user.email || !user.isEmailVerified) {
        toast.error('Please verify your email first');
        return;
      }

      const response = await axios.post('/api/brands/request', {
        ...brandData,
        brandData: {
          ...brandData.brandData,
          owner: user._id
        },
        emailData: {
          ...brandData.emailData,
          userEmail: user.email,
          userName: user.displayName || user.firstName || user.username
        }
      });

      if (response.data.success) {
        // Форматируем новый бренд для отображения
        const newBrand = {
          ...response.data.brand,
          id: response.data.brand._id,
          name: brandData.brandData.name,
          logo: brandData.brandData.logo,
          description: brandData.brandData.description,
          categories: brandData.brandData.categories,
          countries: brandData.brandData.countries,
          website: brandData.brandData.website,
          socialMedia: brandData.brandData.socialMedia,
          status: 'pending'
        };

        // Обновляем список брендов
        setUserBrands(prevBrands => [...prevBrands, newBrand]);

        // Закрываем форму
        setShowAddBrandForm(false);

        toast.success('Your brand request has been sent successfully!');
      }

    } catch (error) {
      console.error('Error adding brand:', error);
      toast.error(error.response?.data?.message || 'Error adding brand');
    }
  };

  // Добавляем функцию для закрытия формы
  const handleCancelAddBrand = () => {
    setShowAddBrandForm(false);
  };

  // Добавим проверку на возможность добавления нового бренда
  const canAddNewBrand = () => {
    return !userBrands.some(brand => brand.status === 'pending');
  };

  const handleDeleteBrand = (brandId) => {
    setDeletingBrandId(brandId);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (deletingBrandId) {
      try {
        // Получаем текущую информацию о бренде
        const brand = userBrands.find(b => b.id === deletingBrandId);
        const brandLogo = brand?.logo;
        const isLocalLogo = brandLogo && !brandLogo.startsWith('http');

        // Удаляем бренд
        await axios.delete(`/api/brands/${deletingBrandId}`);

        // Обновляем список брендов
        setUserBrands(prevBrands => prevBrands.filter(brand => brand.id !== deletingBrandId));
        toast.success('Brand deleted successfully!');
      } catch (error) {
        console.error('Error deleting brand:', error);
        toast.error(error.response?.data?.message || 'Error deleting brand');
      }
      setIsDeleteDialogOpen(false);
      setDeletingBrandId(null);
    }
  };

  const handleEditBrand = async (brandId, updatedBrandData) => {
    if (!updatedBrandData) {
      setEditingBrandId(brandId);
      return;
    }

    try {
      console.log('Updating brand with data:', updatedBrandData);

      // Форматируем данные для API
      const brandToUpdate = {
        name: updatedBrandData.brandData.name,
        logo: updatedBrandData.brandData.logo,
        description: updatedBrandData.brandData.description,
        website: updatedBrandData.brandData.website,
        socialMedia: updatedBrandData.brandData.socialMedia,
        global: updatedBrandData.brandData.global,
        // Сохраняем текущие данные о стране
        countries: updatedBrandData.brandData.countries ||
          [updatedBrandData.brandData.country] ||
          userBrands.find(brand => brand.id === brandId)?.countries,
        // Добавляем категории с сохранением subSubCategory
        categories: updatedBrandData.brandData.categories
      };

      console.log('Sending brand update data:', brandToUpdate);

      const response = await axios.put(`/api/brands/${brandId}`, brandToUpdate);
      if (response.data) {
        setUserBrands(prevBrands =>
          prevBrands.map(brand =>
            brand.id === brandId ? {
              ...brand,
              name: response.data.name,
              logo: response.data.logo,
              description: response.data.description,
              website: response.data.website,
              socialMedia: response.data.socialMedia,
              global: response.data.global,
              // Сохраняем countries в локальном состоянии
              countries: brand.countries
            } : brand
          )
        );
        setEditingBrandId(null);
        toast.success('Brand updated successfully!');
      }
    } catch (error) {
      console.error('Error updating brand:', error);
      toast.error(error.response?.data?.message || 'Error updating brand');
    }
  };

  const handleShowStatistics = (brandId) => {
    setSelectedBrandId(prevId => prevId === brandId ? null : brandId);
  };

  const handleAddBrandClick = () => {
    try {
      // Используем существующие данные пользователя из props
      const userData = user;

      // Проверяем только заполненность профиля и подтверждение email
      if (!userData.displayName || !userData.email || !userData.isEmailVerified) {
        toast.error('Please complete your profile and verify your email before adding a brand');
        const profileSection = document.querySelector('#profile-settings');
        if (profileSection) {
          profileSection.scrollIntoView({ behavior: 'smooth' });
        }
        return;
      }

      // Если профиль заполнен и email подтверждён, показываем форму
      setShowAddBrandForm(true);

    } catch (error) {
      console.error('Error checking user data:', error);
      toast.error('Error checking profile data. Please try again.');
    }
  };

  // Добавим функцию для обработки загрузки аватара
  const handleAvatarUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('avatar', file);

      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('Authentication required');
        return;
      }

      console.log('Uploading avatar...', {
        fileSize: file.size,
        fileType: file.type
      });

      const response = await axios.post(`/api/users/${user._id}/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      console.log('Upload response:', response.data);

      if (response.data.success) {
        const newAvatarUrl = response.data.avatarUrl;
        console.log('New avatar URL:', newAvatarUrl);

        // Обновляем локальное состояние с новым URL
        setUser(prev => ({
          ...prev,
          avatar: newAvatarUrl
        }));

        // Если есть функция обновления профиля, вызываем её
        if (typeof onProfileUpdate === 'function') {
          onProfileUpdate({
            ...user,
            avatar: newAvatarUrl
          });
        }

        // Создаем событие для обновления UI во всем приложении
        window.dispatchEvent(new CustomEvent('userUpdate', {
          detail: {
            userId: user._id,
            avatar: newAvatarUrl
          }
        }));

        toast.success('Avatar updated successfully');
      } else {
        throw new Error(response.data.message || 'Failed to update avatar');
      }
    } catch (error) {
      console.error('Error uploading avatar:', error);
      toast.error(error.message || 'Failed to upload avatar');
    } finally {
      setIsAvatarUploadOpen(false);
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  useEffect(() => {
    // Отладочный вывод
    console.log('User Profile - Full user object:', user);
    console.log('User Profile - DefaultShipping:', user?.defaultShipping);
  }, [user]);

  useEffect(() => {
    if (initialUser) {
      console.log('UserProfile: получены данные initialUser:', initialUser);

      // Проверяем различные структуры initialUser
      let userData;

      if (initialUser.user) {
        // Если передана структура {user: {...}}
        userData = initialUser.user;
      } else if (initialUser._id) {
        // Если передан объект пользователя напрямую
        userData = initialUser;
      } else {
        // Пытаемся получить из localStorage
        try {
          userData = JSON.parse(localStorage.getItem('user') || '{}');
          console.log('UserProfile: получены данные из localStorage:', userData);
        } catch (e) {
          console.error('UserProfile: ошибка чтения данных из localStorage', e);
          userData = initialUser;
        }
      }

      // Обновление в состоянии и localStorage
      if (userData && (userData._id || Object.keys(userData).length > 0)) {
        console.log('UserProfile: устанавливаем пользователя в состояние:', userData);
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
      }
    }
  }, [initialUser]);

  // Добавляем слушатель для глобальных обновлений пользователя
  useEffect(() => {
    const handleUserUpdate = (event) => {
      if (!event.detail) return;

      console.log('UserProfile получил событие userUpdate:', event.detail);

      // Обновляем состояние пользователя полностью, а не только отдельные поля
      setUser(prev => {
        // Если получен полный объект пользователя
        if (typeof event.detail === 'object') {
          // Проверяем, что это тот же пользователь (если есть _id)
          if (prev && prev._id && event.detail._id && prev._id !== event.detail._id) {
            return prev; // Пропускаем обновление, если это другой пользователь
          }

          // Если это userId без полного объекта
          if (event.detail.userId && !event.detail._id) {
            // Проверяем, что это тот же пользователь
            if (prev && prev._id && prev._id !== event.detail.userId) {
              return prev;
            }

            // Обновляем только указанные поля
            const updatedUser = { ...prev };

            Object.keys(event.detail).forEach(key => {
              if (key !== 'userId') {
                updatedUser[key] = event.detail[key];
              }
            });

            // Особое внимание полям gender и ageRange
            if (event.detail.gender !== undefined) {
              updatedUser.gender = event.detail.gender;
            }

            if (event.detail.ageRange !== undefined) {
              updatedUser.ageRange = event.detail.ageRange;
            }

            console.log('UserProfile: обновлены данные пользователя (частично):', updatedUser);
            return updatedUser;
          }

          // Полное обновление данных пользователя
          console.log('UserProfile: полное обновление данных пользователя:', event.detail);
          return event.detail;
        }

        return prev;
      });
    };

    window.addEventListener('userUpdate', handleUserUpdate);

    return () => {
      window.removeEventListener('userUpdate', handleUserUpdate);
    };
  }, []);

  console.log('Current user state:', user); // Отладочный лог

  const StatCard = ({ title, value }) => (
    <Card className="bg-zinc-900 rounded-[20px]">
      <CardContent className="p-4">
        <h3 className="text-sm font-semibold text-[#e0ff89]">{title}</h3>
        <p className="text-xl font-bold text-white">{value}</p>
      </CardContent>
    </Card>
  );

  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      <div className="flex-1 overflow-y-auto p-6">
        <div className="max-w-3xl mx-auto pb-20">
          {/* Профиль пользователя */}
          <div className="flex flex-col justify-between p-4 bg-cover bg-center relative rounded-[30px] overflow-hidden mb-8" style={{
            backgroundImage: `url(${user.backgroundImage || '/default-background.webp'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
<div className="relative z-10 flex justify-between items-start w-full mb-4">
  <div className="flex items-center space-x-4 flex-1 overflow-hidden pl-2.5">
    <div className="relative flex-shrink-0" style={{ zIndex: 20 }}>
      <div className="group">
        <UserAvatar
          user={user}
          currentRank={user.rank || 'Kettle'}
          ranks={ranks}
          size="default"
        />
        
        {/* Оверлей для загрузки фото - добавляем поверх UserAvatar, делаем группировку */}
        <div
          className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
          onClick={() => setIsAvatarUploadOpen(true)}
        >
          <div className="w-8 h-8 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
            <Camera className="w-4 h-4 text-white" />
          </div>
        </div>
      </div>
    </div>

    <div className="min-w-0 flex-1 overflow-hidden">
      <div 
        className="w-full overflow-hidden text-ellipsis whitespace-nowrap"
        title={user.displayName || user.firstName || user.username || 'User'}
      >
        <h1 
          className="text-2xl font-bold text-white inline-block max-w-full overflow-hidden text-ellipsis"
          style={{
            maxWidth: '100%',
            overflow: 'hidden', 
            textOverflow: 'ellipsis'
          }}
        >
          {user.displayName || user.firstName || user.username || 'User'}
        </h1>
      </div>
      <p className="text-sm text-gray-300 mt-1">{user.rank || 'Kettle'}</p>
      <p className="text-xl font-bold text-white flex items-center mt-1">
        {user.coins !== undefined ? (
          <>
            {Math.floor(user.coins)}
            <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
          </>
        ) : '0'}
      </p>
    </div>
  </div>
</div>
<div className="relative z-10 w-full bg-black bg-opacity-70 rounded-full h-3 mt-2">
  <div 
    className="absolute left-0 top-0 bottom-0 rounded-full transition-all duration-300 ease-in-out"
    style={{
      width: `${levelProgress}%`,
      backgroundColor: ranks.find(r => r.name === (user.rank || 'Kettle'))?.ui?.progressBarColor || '#e0ff89'
    }}
  ></div>
  <div className="absolute inset-0 flex items-center justify-center text-xs font-medium">
    <span className="text-white mix-blend-difference">Level {user.level}: {Math.floor(levelProgress)}%</span>
  </div>
</div>
          </div>

          {/* Статистика */}
          <div className="grid grid-cols-2 gap-4 mb-8">
            <StatCard title="Referral Users" value={user.referrals?.length || 0} />
            <StatCard title="Total Battles" value={user.battles || 0} />

            {!user.profileCompletionReward?.claimed && (
              <div className="col-span-2">
                <Card className="bg-gradient-to-r from-[#e0ff89] to-[#b8fcff] text-black rounded-[20px]">
                  <CardContent className="p-4">
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className="text-lg font-bold">Complete Your Profile</h3>
                        <p className="text-sm">Fill out your profile to earn a reward!</p>
                      </div>
                      <div className="flex items-center bg-black text-[#e0ff89] px-3 py-1 rounded-full">
                        <span className="font-bold mr-1">200</span>
                        <img src="/images/general/coin.svg" alt="coin" className="w-4 h-4" />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>

          {/* Настройки профиля */}
          <div className="mb-8" id="profile-settings">
            <ProfileSettings
              user={user}
              onProfileUpdate={(updatedUser) => {
                console.log('Profile updated with data:', updatedUser);

                // Полностью заменяем объект пользователя для гарантированного обновления UI
                setUser(updatedUser);

                // Если есть функция от родительского компонента, вызываем и её
                if (onProfileUpdate) {
                  onProfileUpdate(updatedUser);
                }
              }}
            />
          </div>

          {/* Кошелек TON */}
          <div className="mb-8">
            <TonWallet userId={user._id} />
          </div>

          {/* Секция брендов */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-4">My Brands</h3>
            {error ? (
              <p className="text-red-500">{error}</p>
            ) : userBrands.length > 0 ? (
              <div className="space-y-4">
                {userBrands.map((brand) => (
                  <div key={brand.id}>
                    <div className="bg-zinc-900 rounded-[20px] p-4 flex items-center justify-between">                      <div className="flex flex-col items-center mr-4">
                      <div className="w-16 h-16 rounded-full overflow-hidden mb-2">
                        <img
                          src={brand.logo || '/default-brand-logo.png'}
                          alt={brand.name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <h4 className="font-semibold text-sm text-white text-center">{brand.name}</h4>
                    </div>
                      <div className="space-y-2 flex-grow">
                        <div className="flex space-x-2 mb-2">
                          <Button
                            className="flex-1 bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
                            onClick={() => handleEditBrand(brand.id)}
                          >
                            Edit
                          </Button>
                          <Button
                            className="flex-1 bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
                            onClick={() => handleDeleteBrand(brand.id)}
                          >
                            Delete
                          </Button>
                        </div>
                        <Button
                          className={`w-full ${selectedBrandId === brand.id ? 'bg-[#d1ef7a]' : 'bg-[#e0ff89]'} text-black hover:bg-[#d1ef7a] rounded-[30px] py-1 px-4`}
                          disabled={brand.status === 'pending'}
                          onClick={() => handleShowStatistics(brand.id)}
                        >
                          {selectedBrandId === brand.id ? 'Hide Statistics' : 'Statistics'}
                        </Button>
                        <p className="text-sm text-gray-400 text-center mt-1">
                          {brand.status === 'pending' ? 'Pending Approval' :
                            brand.status === 'active' && brand.approvedAt && brand.activeDays ?
                              `Active until ${new Date(new Date(brand.approvedAt).getTime() + (brand.activeDays * 24 * 60 * 60 * 1000)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}` :
                              brand.status === 'expired' ? 'Expired' :
                                brand.status || 'Status: Unknown'}
                        </p>
                      </div>
                    </div>
                    {selectedBrandId === brand.id && (
                      <BrandStatistics brandId={brand.id} />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400">No brands added yet.</p>
            )}
            {/* Кнопка добавления бренда */}
            {!userBrands.some(brand =>
              brand.status === 'pending' ||
              brand.status === 'review'
            ) && (
                <Button
                  onClick={handleAddBrandClick}
                  className="mt-4 w-full bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#c0d866] rounded-[30px] text-sm"
                >
                  Add Brand
                </Button>
              )}
          </div>

          {/* Форма добавления бренда */}
          {showAddBrandForm && (
            <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-start justify-center overflow-y-auto pt-10 pb-10">
              <div className="bg-black p-6 rounded-[30px] w-full max-w-2xl relative">
                <AddBrand
                  onSave={handleAddBrand}
                  onCancel={handleCancelAddBrand}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Диалог подтверждения удаления */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="bg-zinc-950 text-white rounded-[30px] p-6">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">Confirm Deletion</DialogTitle>
          </DialogHeader>
          <p className="text-[#d3d3d3]">
            Are you sure you want to delete this brand? This action cannot be undone.
          </p>
          <div className="mt-6 flex flex-row justify-between items-center">
            <Button
              onClick={confirmDelete}
              className="w-[48%] bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-2 px-4"
            >
              Delete
            </Button>
            <Button
              onClick={() => setIsDeleteDialogOpen(false)}
              className="w-[48%] bg-white text-black hover:bg-gray-200 rounded-[30px] py-2 px-4"
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={isAvatarUploadOpen} onOpenChange={setIsAvatarUploadOpen}>
        <DialogContent className="bg-black text-white rounded-[30px] p-4 z-[999]">
          <DialogHeader className="mb-2">
            <DialogTitle className="text-xl font-bold">Update Profile Photo</DialogTitle>
            <DialogDescription className="text-gray-400">
              Choose a new photo for your profile
              <br />
              <span className="text-sm">Max 300x300px, 100kb, jpeg/webP</span>
            </DialogDescription>
          </DialogHeader>

          <div className="flex flex-col items-center">
            <div className="w-32 h-32 mb-4">
              {user?.avatar ? (
                <Avatar className="w-32 h-32">
                  <AvatarImage src={user.avatar} className="object-cover" />
                  <AvatarFallback>
                    <BoringAvatar
                      size={128}
                      name={user?.displayName || user?.firstName || user?.username || 'User'}
                      variant="beam"
                      colors={["#a1a6aa", "#bd928b", "#de7571", "#ff4e44", "#282634"]}
                    />
                  </AvatarFallback>
                </Avatar>
              ) : (
                <BoringAvatar
                  size={128}
                  name={user?.displayName || user?.firstName || user?.username || 'User'}
                  variant="beam"
                  colors={["#a1a6aa", "#bd928b", "#de7571", "#ff4e44", "#282634"]}
                />
              )}
            </div>

            <div className="flex gap-2 w-full">
              <label className="flex-1">
                <div className="bg-[#e0ff89] text-black px-4 py-2 rounded-[30px] text-center cursor-pointer hover:bg-[#d1ef7a] transition-colors">
                  Choose Photo
                </div>
                <input
                  type="file"
                  className="hidden"
                  accept="image/jpeg,image/webp"
                  onChange={handleAvatarUpload}
                />
              </label>
              {user?.avatar && (
                <button
                  className="flex-1 bg-white text-black border border-white hover:bg-zinc-900 px-4 py-2 rounded-[30px] transition-colors"
                  onClick={async () => {
                    try {
                      const response = await axios.post(
                        `/api/users/${user._id}/avatar/reset`,
                        {},
                        {
                          headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
                          }
                        }
                      );

                      if (response.data.success) {
                        const updatedUser = {
                          ...user,
                          avatar: null,
                          defaultAvatar: null
                        };
                        setUser(updatedUser);

                        if (typeof onProfileUpdate === 'function') {
                          onProfileUpdate(updatedUser);
                        }

                        // Отправляем событие обновления для всех компонентов
                        window.dispatchEvent(new CustomEvent('userUpdate', {
                          detail: {
                            userId: user._id,
                            avatar: null,
                            defaultAvatar: null
                          }
                        }));

                        setIsAvatarUploadOpen(false);
                        toast.success('Avatar reset successfully');
                      }
                    } catch (error) {
                      console.error('Error resetting avatar:', error);
                      toast.error('Failed to reset avatar');
                    }
                  }}
                >
                  Reset to Default
                </button>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Диалог подтверждения удаления */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="bg-black text-white rounded-[30px] p-6 z-[9999]">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">Confirm Deletion</DialogTitle>
          </DialogHeader>
          <p className="text-[#d3d3d3]">
            Are you sure you want to delete this brand? This action cannot be undone.
          </p>
          <div className="mt-6 flex flex-row justify-between items-center">
            <Button
              onClick={confirmDelete}
              className="w-[48%] bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-2 px-4"
            >
              Delete
            </Button>
            <Button
              onClick={() => setIsDeleteDialogOpen(false)}
              className="w-[48%] bg-white text-black hover:bg-gray-200 rounded-[30px] py-2 px-4"
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Диалог редактирования бренда */}
      {editingBrandId && (
        <div className="fixed inset-0 z-[9999] bg-black bg-opacity-50 flex items-start justify-center overflow-y-auto pt-10 pb-10">
          <div className="bg-zinc-950 p-6 rounded-[30px] w-full max-w-2xl relative">
            <AddBrand
              brand={(() => {
                const brandData = userBrands.find(brand => brand.id === editingBrandId);
                console.log('Preparing brand data for editing:', brandData);

                // Преобразуем данные для корректной передачи в компонент
                if (brandData) {
                  // Подготавливаем данные о стране
                  let preparedCountry = null;
                  if (brandData.country) {
                    preparedCountry = brandData.country;
                  } else if (Array.isArray(brandData.countries) && brandData.countries.length > 0) {
                    preparedCountry = brandData.countries[0];
                  }

                  // Подготавливаем данные о категориях
                  let preparedCategories = [];
                  if (Array.isArray(brandData.categories) && brandData.categories.length > 0) {
                    preparedCategories = brandData.categories.map(cat => {
                      return {
                        category: typeof cat.category === 'object' ? cat.category._id : cat.category,
                        subCategory: cat.subCategory || '',
                        subSubCategory: cat.subSubCategory || ''
                      };
                    });
                  } else {
                    // Если категорий нет, создаем пустую
                    preparedCategories = [{ category: '', subCategory: '', subSubCategory: '' }];
                  }

                  // Отладочный вывод
                  console.log('Prepared categories:', JSON.stringify(preparedCategories, null, 2));

                  return {
                    ...brandData,
                    country: preparedCountry,
                    categories: preparedCategories
                  };
                }
                return brandData;
              })()}
              onSave={(updatedData) => handleEditBrand(editingBrandId, updatedData)}
              onCancel={() => setEditingBrandId(null)}
              disableCategories={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;