import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog";
import { Label } from "../ui/label";
import { Pencil, Trash2, Upload } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Progress } from "../ui/progress";
import { useDropzone } from 'react-dropzone';

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
                ${isDragActive ? 'border-zinc-500 bg-zinc-900' : 'border-zinc-700 hover:border-zinc-600'}
            `}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-zinc-500" size={48} />
            <p className="text-sm text-zinc-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
        </div>
    );
};

const ReferralForm = ({ referral, onSave, onCancel }) => {
    const [formData, setFormData] = useState(referral || {
        name: '',
        description: '',
        backgroundImage: '',
        coinsReward: '',
        requiredReferrals: '',
    });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(referral?.backgroundImage || '');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData, imageFile);
    };

const inputClasses = "bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500 placeholder-zinc-500";

    return (
       <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="name" className="text-zinc-300">Name</Label>
                    <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="description" className="text-zinc-300">Description</Label>
                    <Input id="description" name="description" value={formData.description} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="coinsReward" className="text-zinc-300">Coins Reward</Label>
                    <Input id="coinsReward" name="coinsReward" type="number" value={formData.coinsReward} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="requiredReferrals" className="text-zinc-300">Required Referrals</Label>
                    <Input id="requiredReferrals" name="requiredReferrals" type="number" value={formData.requiredReferrals} onChange={handleChange} required min="1" className={inputClasses} />
                </div>
            </div>
            <div>
                <Label className="text-zinc-300">Background Image</Label>
                <div className="flex flex-col space-y-2">
                    <ImageUpload onImageUpload={handleImageUpload} />
                </div>
                {imagePreview && (
                    <div className="relative mt-2">
                        <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => {
                                setImagePreview('');
                                setFormData(prev => ({ ...prev, backgroundImage: '' }));
                            }}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                        >
                            X
                        </Button>
                    </div>
                )}
            </div>
            <div className="flex justify-end space-x-2">
                <Button 
                    type="button" 
                    onClick={onCancel} 
                    className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                >
                    Cancel
                </Button>
                <Button 
                    type="submit" 
                    className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                >
                    Save Referral Task
                </Button>
            </div>
        </form>
    );
};

const ReferralsManagement = () => {
    const [referrals, setReferrals] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingReferral, setEditingReferral] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletingReferralId, setDeletingReferralId] = useState(null);

    const fetchReferrals = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get('/api/referrals');
            console.log('Fetched referrals:', response.data);
            setReferrals(response.data);
        } catch (error) {
            console.error('Error fetching referrals:', error);
            setError('Failed to load referral tasks. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchReferrals();
    }, [fetchReferrals]);

    const handleSave = async (referralData, imageFile) => {
        try {
            const formData = new FormData();
            Object.keys(referralData).forEach(key => {
                if (key !== 'backgroundImage') {
                    formData.append(key, referralData[key]);
                }
            });

            if (imageFile) {
                formData.append('backgroundImage', imageFile);
            }

            let response;
            if (editingReferral) {
                response = await axios.put(`/api/referrals/${editingReferral._id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else {
                response = await axios.post('/api/referrals', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            console.log('Referral saved:', response.data);
            fetchReferrals();
            setIsDialogOpen(false);
            setEditingReferral(null);
        } catch (error) {
            console.error('Error saving referral:', error);
            setError(`Error saving referral task: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleDelete = (referralId) => {
        setDeletingReferralId(referralId);
        setIsDeleteDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (deletingReferralId) {
            try {
                await axios.delete(`/api/referrals/${deletingReferralId}`);
                fetchReferrals();
            } catch (error) {
                console.error('Error deleting referral:', error);
                setError('Failed to delete referral task. Please try again.');
            }
            setIsDeleteDialogOpen(false);
            setDeletingReferralId(null);
        }
    };

    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(referrals);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setReferrals(items);

        try {
            await axios.post('/api/referrals/reorder', { referrals: items.map(item => item._id) });
        } catch (error) {
            console.error('Error reordering referrals:', error);
            setError('Failed to reorder referral tasks. Please try again.');
            fetchReferrals();
        }
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold text-zinc-200">Referrals</h2>
                    <Button 
                        onClick={() => setIsDialogOpen(true)} 
                        className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                    >
                        Add New Referral Task
                    </Button>
                </div>

                {/* Сообщение об ошибке */}
                {error && (
                    <div className="mb-4 p-4 bg-red-500 bg-opacity-10 border border-red-500 text-red-400 rounded-md">
                        {error}
                    </div>
                )}

                {/* Список задач */}
                <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
                    {isLoading ? (
                        <div className="flex items-center justify-center py-8">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-zinc-500"></div>
                            <span className="ml-2 text-zinc-400">Loading tasks...</span>
                        </div>
                    ) : (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="referrals">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                                        {referrals.length > 0 ? (
                                            referrals.map((referral, index) => (
                                                <Draggable key={referral._id} draggableId={referral._id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="relative overflow-hidden rounded-lg shadow-lg bg-zinc-900 border border-zinc-800"
                                                        >
                                                            <div className="relative z-10 p-6 bg-zinc-900 bg-opacity-80 h-full flex flex-col justify-between">
                                                                <div className="flex justify-between items-start">
                                                                    <div>
                                                                        <h3 className="text-xl font-bold text-zinc-300">{referral.name}</h3>
                                                                        <p className="text-sm text-zinc-400 mt-1">{referral.description}</p>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        <p className="text-2xl font-bold text-zinc-200 flex items-center">
                                                                            {referral.coinsReward}
                                                                            <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <div className="w-full bg-zinc-800 rounded-full h-4 relative mt-2">
                                                                        <Progress
                                                                            value={(referral.currentReferrals || 0) / referral.requiredReferrals * 100}
                                                                            className="h-4 rounded-full bg-zinc-300"
                                                                            indicatorClassName="bg-[#e0ff89]"
                                                                        />
                                                                        <div className="absolute inset-0 flex items-center justify-center text-xs text-zinc-900 font-medium">
                                                                            {referral.currentReferrals || 0} / {referral.requiredReferrals} referrals
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-end space-x-2 mt-4">
                                                                    <Button 
                                                                        onClick={() => { setEditingReferral(referral); setIsDialogOpen(true); }} 
                                                                        className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                                                    >
                                                                        <Pencil size={16} />
                                                                    </Button>
                                                                    <Button 
                                                                        onClick={() => handleDelete(referral._id)} 
                                                                        className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                                                    >
                                                                        <Trash2 size={16} />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {referral.backgroundImage && (
                                                                <img
                                                                    src={referral.backgroundImage}
                                                                    alt={referral.name}
                                                                    className="absolute inset-0 w-full h-full object-cover opacity-20"
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        ) : (
                                            <div className="text-zinc-400 text-center py-8">
                                                No referral tasks available. Add your first task using the button above.
                                            </div>
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </div>

                {/* Диалог добавления/редактирования задачи */}
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogContent className="max-h-[80vh] overflow-y-auto bg-zinc-950 border border-zinc-800">
                        <DialogHeader>
                            <DialogTitle className="text-zinc-100">{editingReferral ? 'Edit Referral Task' : 'Add New Referral Task'}</DialogTitle>
                            <DialogDescription className="text-zinc-400">
                                {editingReferral ? 'Edit the details of the referral task.' : 'Enter the details of the new referral task.'}
                            </DialogDescription>
                        </DialogHeader>
                        <ReferralForm
                            referral={editingReferral}
                            onSave={handleSave}
                            onCancel={() => setIsDialogOpen(false)}
                        />
                    </DialogContent>
                </Dialog>

                {/* Диалог подтверждения удаления */}
                <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                    <DialogContent className="bg-zinc-950 border border-zinc-800 text-zinc-200 rounded-lg p-6">
                        <DialogHeader>
                            <DialogTitle className="text-zinc-100">Confirm Deletion</DialogTitle>
                            <DialogDescription className="text-zinc-400">
                                Are you sure you want to delete this referral task? This action cannot be undone.
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter className="mt-6 flex justify-end space-x-4">
                            <Button
                                onClick={() => setIsDeleteDialogOpen(false)}
                                className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={confirmDelete}
                                className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
                            >
                                Delete
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default ReferralsManagement;