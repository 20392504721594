import React, { useState, useEffect, useRef } from 'react';
import Stories from './Stories';
import axios from 'axios';

/**
 * Контейнер для отображения историй Instagram-стиля
 * @param {Object} props - свойства компонента
 * @param {boolean} props.isOpen - открыт ли контейнер историй
 * @param {Function} props.onClose - функция закрытия контейнера
 * @param {number} props.initialStoryIndex - начальный индекс истории для отображения
 */
const StoriesContainer = ({ isOpen, onClose, initialStoryIndex = 0, userData = {} }) => {
  // Состояние для хранения историй
  const [stories, setStories] = useState([]);
  // Загрузка данных
  const [loading, setLoading] = useState(false); // Изменено с true на false
  // Ошибки загрузки
  const [error, setError] = useState(null);
  // Открыт ли компонент историй
  const [isStoriesOpen, setIsStoriesOpen] = useState(isOpen);
  // Индекс активной истории
  const [activeStoryIndex, setActiveStoryIndex] = useState(initialStoryIndex);
  // Создаем useRef для отслеживания предыдущего состояния и запуска историй
  const prevOpenState = useRef(false);
  const hasAutoLaunchedStory = useRef(false);

  // Синхронизируем состояние с пропсами, предотвращая дублирование открытия
  useEffect(() => {
    console.log(`Stories container state change: isOpen=${isOpen}, initialIndex=${initialStoryIndex}`);

    // Проверяем флаг в localStorage для предотвращения повторного автозапуска
    const isStoryAutoLaunched = localStorage.getItem('storyShownInSession') === 'true';

    // Предотвращаем повторное открытие, если история уже открыта и это не было вызвано из вне
    if (isOpen && prevOpenState.current && !isStoryAutoLaunched) {
      console.log('Story already open, preventing duplicate open');
      return;
    }

    setIsStoriesOpen(isOpen);

    if (isOpen) {
      setActiveStoryIndex(initialStoryIndex);
      prevOpenState.current = true;
    } else {
      // Сбрасываем флаг только когда истории закрыты
      prevOpenState.current = false;
    }
  }, [isOpen, initialStoryIndex]);

// Добавляем обработчик событий для userDataUpdate
useEffect(() => {
  const handleUserDataUpdate = (event) => {
    const { type, storyId, limitReached, userData } = event.detail;
    
    // Если достигнут лимит, закрываем истории
    if (limitReached) {
      setIsStoriesOpen(false);
      if (onClose) {
        onClose(false);
      }
      
      // Обновляем локальное состояние историй
      setStories(prevStories => 
        prevStories.filter(story => {
          const storyHasId = (story.id !== storyId) && (story._id !== storyId);
          return storyHasId;
        })
      );
      
      return;
    }
    
    // Обновляем userState если получены новые данные пользователя
    if (userData) {
      // Обновляем состояние историй, чтобы отметить просмотренные
      setStories(prevStories => 
        prevStories.map(story => {
          const storyHasId = story.id === storyId || story._id === storyId;
          return storyHasId 
            ? { ...story, viewedByCurrentUser: true } 
            : story;
        })
      );
    }
  };

  // Регистрируем обработчик события обновления данных пользователя
  window.addEventListener('userDataUpdate', handleUserDataUpdate);
  
  const handleStoryStatusUpdate = (event) => {
    const { storyId, status } = event.detail;
    
    // Если история истекла, удаляем её из списка доступных
    if (status === 'expired') {
      setStories(prevStories => 
        prevStories.filter(story => {
          const storyHasId = (story.id === storyId) || (story._id === storyId);
          return !storyHasId; // Оставляем только истории с другими ID
        })
      );
      
      // Если текущая история истекла, закрываем просмотрщик
      const currentStoryId = stories[activeStoryIndex]?.id || stories[activeStoryIndex]?._id;
      if (currentStoryId === storyId) {
        setIsStoriesOpen(false);
        if (onClose) {
          onClose(false);
        }
      }
    }
  };
  
  window.addEventListener('storyStatusUpdate', handleStoryStatusUpdate);

  // Очищаем обработчик при размонтировании компонента
  return () => {
    window.removeEventListener('userDataUpdate', handleUserDataUpdate);
    window.removeEventListener('storyStatusUpdate', handleStoryStatusUpdate);
  };
}, [stories, activeStoryIndex, onClose]);

// Загрузка историй при монтировании компонента - УБИРАЕМ АВТОЗАПУСК ОТСЮДА
useEffect(() => {
  const fetchStories = async () => {
    try {
      // Получаем токен и telegram данные для аутентификации
      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');
      
      const headers = {};
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      if (telegramInitData) {
        headers['X-Telegram-Init-Data'] = telegramInitData;
      }
      
      const storiesResponse = await axios.get('/api/stories/launch', {
        headers: headers
      });
      
      if (storiesResponse.data.stories && storiesResponse.data.stories.length > 0) {
        // Фильтруем истории, удаляя те, для которых пользователь достиг лимита просмотров
        const availableStories = storiesResponse.data.stories.filter(story => {
          // Проверяем, не достиг ли пользователь лимита просмотров для этой истории
          if (story.maxViewsPerUser > 0) {
            const viewCount = story.viewedByCurrentUser ? 
              (userData?.storyViews?.find(v => (v.storyId === story._id || v.storyId === story.id))?.viewCount || 0) : 0;
            
            if (viewCount >= story.maxViewsPerUser) {
              console.log(`Story ${story._id || story.id} filtered out: user reached view limit (${viewCount}/${story.maxViewsPerUser})`);
              return false;
            }
          }
          
          // Проверяем общий лимит просмотров
          if (story.maxTotalViews > 0 && story.views >= story.maxTotalViews) {
            console.log(`Story ${story._id || story.id} filtered out: total views limit reached (${story.views}/${story.maxTotalViews})`);
            return false;
          }
          
          // Остальные проверки...
          
          return true;
        });
        
        console.log(`Filtered stories: ${availableStories.length} of ${storiesResponse.data.stories.length} are available`);
        setStories(availableStories);
        
        // Автозапуск историй удален, управление запуском перенесено в BrandBattle.js
      } else {
        console.log('No stories available or server returned empty list');
        setStories([]);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error loading stories:', error);
      setLoading(false);
    }
  };
  
  fetchStories();
}, [isOpen, userData]);

  // Функция для закрытия контейнера историй
  const handleCloseStories = (shouldReopen = false) => {
    setIsStoriesOpen(false);
    // Добавляем небольшую задержку перед вызовом onClose для надежности
    setTimeout(() => {
      if (onClose) {
        // Передаем параметр shouldReopen в onClose, по умолчанию false
        onClose(shouldReopen);
      }
    }, 50);
  };

  // Функция для отметки истории как просмотренной
  const markStoryAsViewed = async (storyId) => {
    if (!storyId) {
      console.error('No storyId provided to markStoryAsViewed');
      return;
    }

    console.log(`Marking story as viewed: ${storyId}`);

    try {
      // Получаем токен аутентификации для идентификации пользователя
      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');

      if (!token && !telegramInitData) {
        console.error('No authentication token or Telegram data available');
        return;
      }

      // Находим историю, которую просматриваем
      const story = stories.find(s => (s._id === storyId || s.id === storyId));
      if (!story) {
        console.error(`Story with ID ${storyId} not found in state`);
        return;
      }

      // Отправляем запрос на сервер с правильными заголовками аутентификации
      const headers = {};
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      if (telegramInitData) {
        headers['X-Telegram-Init-Data'] = telegramInitData;
      }

      console.log('Sending view request with headers:', headers);

      const response = await axios.post(`/api/stories/${storyId}/view`, {}, { headers });

      console.log('View response:', response.data);

      if (response.data.success === false) {
        console.error('Error response:', response.status, response.data);

        // Если достигнут лимит просмотров, закрываем истории и обновляем состояние
        if (response.data.message && (
          response.data.message.includes('User view limit reached') ||
          response.data.message.includes('Total views limit reached') ||
          response.data.message.includes('Story is expired')
        )) {
          console.warn(`View limit reached for story: ${response.data.message}`);

          // Обновляем состояние историй, помечая эту историю как недоступную
          setStories(prevStories =>
            prevStories.map(s => {
              const storyHasId = (s.id && s.id === storyId) || (s._id && s._id === storyId);
              if (storyHasId) {
                return {
                  ...s,
                  status: 'expired',
                  viewLimitReached: true
                };
              }
              return s;
            })
          );

          // Закрываем текущую историю и переходим к следующей доступной
          setTimeout(() => {
            handleCloseStories();
          }, 300);
        }

        return;
      }

      // Обновляем локальное состояние, отмечая историю как просмотренную
      setStories(prevStories =>
        prevStories.map(story => {
          // Проверяем оба формата ID (id и _id)
          const storyHasId = (story.id && story.id === storyId) || (story._id && story._id === storyId);

          if (storyHasId) {
            console.log(`Marking story ${storyId} as viewed in local state`);
            return {
              ...story,
              id: story.id || storyId,
              _id: story._id || storyId,
              viewedByCurrentUser: true,
              views: response.data.views || story.views + 1,
              status: response.data.status || story.status
            };
          }
          return story;
        })
      );

      // Обновляем данные пользователя после просмотра
      if (response.data.success && response.data.user) {
        // Если есть функция для обновления данных пользователя, вызываем её
        if (window.dispatchEvent) {
          console.log('Dispatching userDataUpdate event with user data:', response.data.user);
          window.dispatchEvent(new CustomEvent('userDataUpdate', {
            detail: {
              type: 'storyViewed',
              storyId: storyId,
              userData: response.data.user
            }
          }));
        }
      }
    } catch (error) {
      console.error('Error marking story as viewed:', error);
      if (error.response) {
        console.error('Error response:', error.response.status, error.response.data);

        // Обрабатываем ошибки от сервера
        if (error.response.data && error.response.data.message) {
          // Если достигнут лимит просмотров
          if (error.response.data.message.includes('view limit') ||
            error.response.data.message.includes('expired')) {
            // Закрываем текущую историю
            setTimeout(() => {
              handleCloseStories();
            }, 300);
          }
        }
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };

  // Если нет историй, возвращаем null
  if (error || stories.length === 0) {
    return null;
  }

  // Полноэкранный режим историй
  return (
    <div
      className={`fixed inset-0 z-[9999] bg-black ${isStoriesOpen ? 'block' : 'hidden'}`}
      style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999 }}
    >
      <Stories
        stories={stories}
        isOpen={isStoriesOpen}
        onClose={handleCloseStories}
        onStoryView={markStoryAsViewed}
        initialIndex={activeStoryIndex}
      />
    </div>
  );
};

export default StoriesContainer;