import React, { useState, useEffect } from 'react';
import StoryItem from './StoryItem';
import { X } from 'lucide-react';

/**
 * Компонент Stories - виджет Instagram-стиля для отображения историй
 * @param {Object} props - свойства компонента
 * @param {Array} props.stories - массив историй
 * @param {Function} props.onClose - функция закрытия виджета
 * @param {boolean} props.isOpen - открыт ли виджет
 * @param {Function} props.onStoryView - коллбэк при просмотре истории
 * @param {number} props.initialIndex - начальный индекс истории
 */
const Stories = ({ 
  stories = [], 
  onClose, 
  isOpen = false, 
  onStoryView,
  initialIndex = 0 
}) => {
  // Индекс текущей активной истории
  const [activeStoryIndex, setActiveStoryIndex] = useState(initialIndex);
  // Индекс текущего элемента в истории
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  // Пауза для историй (при удержании)
  const [isPaused, setIsPaused] = useState(false);

  // Получаем текущую историю
  const currentStory = stories[activeStoryIndex] || { items: [] };
  
  // Рассчитываем общее количество историй
  const totalStories = stories.length;

  // Устанавливаем начальный индекс при открытии
  useEffect(() => {
    if (isOpen) {
      setActiveStoryIndex(initialIndex);
      setActiveItemIndex(0);
    }
  }, [isOpen, initialIndex]);

  // Переход к следующему элементу или истории
const goToNextItem = () => {
  console.log('Going to next item:', {currentIndex: activeItemIndex, totalItems: currentStory.items.length});
  
  if (activeItemIndex < currentStory.items.length - 1) {
    // Если есть следующий элемент в текущей истории
    console.log('Moving to next item in current story');
    setActiveItemIndex(activeItemIndex + 1);
  } else if (activeStoryIndex < totalStories - 1) {
    // Если есть следующая история
    console.log('Moving to first item in next story');
    
    // Отмечаем историю как просмотренную
    if (onStoryView) {
      // Используем любой доступный ID истории (id или _id)
      const storyId = currentStory.id || currentStory._id;
      console.log('Story ID to mark as viewed:', storyId, 'Story object:', currentStory);
      
      if (storyId) {
        onStoryView(storyId);
      } else {
        console.error('Story has no ID, cannot mark as viewed', currentStory);
      }
    }
    
    setActiveStoryIndex(activeStoryIndex + 1);
    setActiveItemIndex(0);
  } else {
    // Если это последний элемент последней истории - закрываем
    console.log('Last item in last story - closing');
    
    // Отмечаем историю как просмотренную
    if (onStoryView) {
      // Используем любой доступный ID истории (id или _id)
      const storyId = currentStory.id || currentStory._id;
      console.log('Story ID to mark as viewed (last story):', storyId, 'Story object:', currentStory);
      
      if (storyId) {
        onStoryView(storyId);
      } else {
        console.error('Story has no ID, cannot mark as viewed', currentStory);
      }
    }
    
    // Принудительно закрываем историю с небольшой задержкой
    setTimeout(() => {
      console.log('Executing delayed close');
      if (onClose) onClose(false); // Передаем false, чтобы просто закрыть, а не открыть снова
    }, 300);
  }
};

  // Переход к предыдущему элементу или истории
  const goToPrevItem = () => {
    if (activeItemIndex > 0) {
      // Если есть предыдущий элемент в текущей истории
      setActiveItemIndex(activeItemIndex - 1);
    } else if (activeStoryIndex > 0) {
      // Если есть предыдущая история
      setActiveStoryIndex(activeStoryIndex - 1);
      // Переходим к последнему элементу предыдущей истории
      const prevStory = stories[activeStoryIndex - 1];
      setActiveItemIndex(prevStory.items.length - 1);
    }
  };

  // Обработка клика на аватар истории
  const handleStoryClick = (index) => {
    setActiveStoryIndex(index);
    setActiveItemIndex(0);
  };

// Добавляем useEffect для автоматического перехода между слайдами
useEffect(() => {
  // Пропускаем, если истории не открыты или пауза активна
  if (!isOpen || isPaused || !currentStory || !currentStory.items) {
    return;
  }
  
  // Получаем текущий элемент
  const currentItem = currentStory.items[activeItemIndex];
  if (!currentItem) {
    console.log('No current item found for index', activeItemIndex);
    console.log('Current story items:', currentStory.items);
    return;
  }
  
  // Логируем информацию о текущем элементе
  console.log('Current item:', {
    index: activeItemIndex, 
    type: currentItem.type, 
    url: currentItem.url && currentItem.url.slice(0, 30) + '...'
  });
  
  // Получаем длительность текущего элемента
  const duration = currentItem.duration || currentStory.duration || 5;
  console.log(`Setting timer for ${duration} seconds for slide ${activeItemIndex}`);
  
  // Устанавливаем таймер для автоматического перехода
  const timer = setTimeout(() => {
    console.log('Timer expired, going to next item');
    goToNextItem();
  }, duration * 1000);
  
  // Очищаем таймер при размонтировании или изменении условий
  return () => {
    console.log('Clearing timer');
    clearTimeout(timer);
  };
}, [isOpen, isPaused, activeStoryIndex, activeItemIndex, currentStory]);

  // Обработка навигации с клавиатуры
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isOpen) return;
      
      switch (e.key) {
        case 'ArrowRight':
          goToNextItem();
          break;
        case 'ArrowLeft':
          goToPrevItem();
          break;
        case 'Escape':
          onClose();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, activeStoryIndex, activeItemIndex]);

  // Если виджет не открыт, показываем только полосу с аватарами
  if (!isOpen) {
    return (
      <div className="w-full overflow-x-auto py-2 no-scrollbar">
        <div className="flex space-x-4 px-4">
          {stories.map((story, index) => (
            <div 
              key={story.id || index} 
              onClick={() => {
                handleStoryClick(index);
                // Вызываем onClose с параметром true, чтобы открыть истории
                if (onClose) onClose(true);
              }}
              className="flex flex-col items-center cursor-pointer"
            >
              <div className={`w-16 h-16 rounded-full p-1 ${story.viewedByCurrentUser ? 'bg-zinc-400' : 'bg-gradient-to-tr from-yellow-400 to-pink-500'}`}>
                <img
  src={story.items[0]?.url || '/images/general/default-ticket-bg.jpg'}
  alt={story.title}
  className="w-full h-full object-cover rounded-full border-2 border-white"
  onError={(e) => {e.target.src = '/images/general/default-ticket-bg.jpg'}}
/>
              </div>
              <span className="text-xs mt-1 text-zinc-800 truncate w-16 text-center">
                {story.title || 'Story'}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  // Полноэкранный режим историй
return (
  <div className="fixed inset-0 flex items-center justify-center h-screen w-screen">
    {/* Черный фон с градиентом для эффекта */}
    <div className="absolute inset-0 bg-gradient-to-b from-black to-zinc-900"></div>
    
    {/* Стильный фоновый узор */}
    <div className="absolute inset-0 opacity-20 pointer-events-none">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 40 40">
        <pattern id="pattern-circles" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
          <circle cx="20" cy="20" r="1" fill="white" fillOpacity="0.2" />
        </pattern>
        <rect width="100%" height="100%" fill="url(#pattern-circles)" />
      </svg>
    </div>
    
    {/* Кнопка закрытия */}
    <button 
      onClick={() => onClose(false)} // Явно передаем false
      className="absolute top-4 right-4 z-50 text-white p-2 bg-black bg-opacity-30 rounded-full hover:bg-opacity-50 transition-all"
    >
      <X className="h-6 w-6" />
    </button>

    {/* Индикаторы прогресса историй - только для активной истории */}
    <div className="absolute top-4 left-0 right-0 px-4 z-10">
      <div className="flex space-x-1">
        {/* Показываем только индикатор для текущей истории */}
        <div key={currentStory.id || activeStoryIndex} className="flex-1">
          <div className="flex space-x-0.5">
            {currentStory.items.map((_, itemIdx) => {
              // Определяем состояние индикатора
              const isCompleted = itemIdx < activeItemIndex;
              const isActive = itemIdx === activeItemIndex;
              
              return (
                <div 
                  key={itemIdx} 
                  className="h-1 flex-1 rounded-full relative overflow-hidden"
                  style={{
                    backgroundColor: isCompleted ? 'white' : 'rgba(255, 255, 255, 0.3)'
                  }}
                >
                  {isActive && !isPaused && (
                    <div 
                      className="absolute top-0 left-0 h-full bg-white" 
                      style={{
                        animation: `progress-animation ${currentStory.items[activeItemIndex]?.duration || currentStory.duration || 5}s linear forwards`
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>

      {/* Невидимые зоны для навигации без стрелочек - ИЗМЕНЕНО: исключена нижняя часть экрана */}
      <div 
        className="absolute left-4 top-0 bottom-32 w-1/4 z-10"
        onClick={goToPrevItem}
      >
      </div>

      <div 
        className="absolute right-4 top-0 bottom-32 w-1/4 z-10"
        onClick={goToNextItem}
      >
      </div>

      {/* Содержимое истории */}
      <div 
        className="w-full h-full md:w-96 md:h-screen md:max-h-[80vh]"
        onTouchStart={() => setIsPaused(true)}
        onTouchEnd={() => setIsPaused(false)}
        onMouseDown={() => setIsPaused(true)}
        onMouseUp={() => setIsPaused(false)}
        onMouseLeave={() => setIsPaused(false)}
      >
        {currentStory && currentStory.items && currentStory.items[activeItemIndex] && (
          <StoryItem 
            item={currentStory.items[activeItemIndex]} 
            story={currentStory}
            onEnd={goToNextItem}
            isPaused={isPaused}
          />
        )}
      </div>
    </div>
  );
};

// Добавляем стили для анимации прогресса
const styles = document.createElement('style');
styles.innerHTML = `
  @keyframes progress-animation {
    from { width: 0%; }
    to { width: 100%; }
  }
`;
document.head.appendChild(styles);

export default Stories;