import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";
import { Label } from "../ui/label";
import { Pencil, Trash2, Upload, ChevronLeft, ChevronRight, Globe, Smartphone } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useDropzone } from 'react-dropzone';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Switch } from "../ui/switch";
import { MultiSelect } from "../ui/MultiSelect";
import { COUNTRIES, groupCountriesByRegion, getPopularCountries } from '../../constants/countries';
import { LANGUAGES, groupLanguagesByFirstLetter, getCommonLanguages } from '../../constants/languages';

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
                ${isDragActive ? 'border-zinc-500 bg-zinc-900' : 'border-zinc-700 hover:border-zinc-600'}
            `}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-zinc-500" size={48} />
            <p className="text-sm text-zinc-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
        </div>
    );
};

const SpecialTaskForm = ({ task, onSave, onCancel }) => {
    const [formData, setFormData] = useState(task || {
        name: '',
        description: '',
        backgroundImage: '',
        coinsReward: '',
        battlesReward: '5',
        taskType: 'app',
        specialUrl: '',
        confirmationCode: '',
        limitClicks: -1,
        limitClaims: -1,
        dailyAdClickLimit: 20,  // Добавляем дневной лимит для рекламы
        targeting: 'all',
        allowedCountries: [],
        allowedLanguages: [],
        adConfig: {
            adType: 'rewarded',
            adId: ''
        }
    });

    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(task?.backgroundImage || '');

    // Сгруппированные данные для селектов
    const groupedCountries = groupCountriesByRegion();
    const popularCountries = getPopularCountries();
    const commonLanguages = getCommonLanguages();

    const handleAdConfigChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            adConfig: {
                ...prev.adConfig,
                [field]: value
            }
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleTargetingChange = (checked) => {
        setFormData(prev => ({
            ...prev,
            targeting: checked ? 'specific' : 'all',
            ...(checked ? {} : { allowedCountries: [], allowedLanguages: [] })
        }));
    };

    const handleCountriesChange = (selectedOptions) => {
        console.log('Selected countries:', selectedOptions);
        const countryValues = selectedOptions
            .filter(option => option && option.value)
            .map(option => option.value);
        console.log('Country values to save:', countryValues);
        setFormData(prev => ({
            ...prev,
            allowedCountries: countryValues
        }));
    };

    const handleLanguagesChange = (selectedOptions) => {
        console.log('Selected languages:', selectedOptions);
        const languageValues = selectedOptions
            .filter(option => option && option.value)
            .map(option => option.value);
        console.log('Language values to save:', languageValues);
        setFormData(prev => ({
            ...prev,
            allowedLanguages: languageValues
        }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Создаем FormData для отправки
        const formDataToSend = new FormData();

        // Добавляем основные поля
        formDataToSend.append('name', formData.name);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('coinsReward', formData.taskType === 'ad' ? 0 : formData.coinsReward);
        formDataToSend.append('taskType', formData.taskType);
        formDataToSend.append('specialUrl', formData.specialUrl || '');
        formDataToSend.append('confirmationCode', formData.confirmationCode || '');
        formDataToSend.append('limitClicks', formData.limitClicks || -1);
        formDataToSend.append('limitClaims', formData.limitClaims || -1);
        formDataToSend.append('limitCodeEntry', formData.limitCodeEntry || -1);
        formDataToSend.append('targeting', formData.targeting);

        // Для рекламных заданий добавляем награду в виде боев и дневной лимит
        if (formData.taskType === 'ad') {
            formDataToSend.append('battlesReward', formData.battlesReward || 5);
            formDataToSend.append('dailyAdClickLimit', formData.dailyAdClickLimit || 5);
            // Добавляем информацию о типе рекламы и ID
            formDataToSend.append('adType', formData.adConfig?.adType || 'rewarded');
            formDataToSend.append('adId', formData.adConfig?.adId || '');
        }

        // Обработка таргетинга и связанных массивов
        if (formData.targeting === 'specific') {
            formDataToSend.append('allowedCountries', JSON.stringify(formData.allowedCountries || []));
            formDataToSend.append('allowedLanguages', JSON.stringify(formData.allowedLanguages || []));
        } else {
            formDataToSend.append('allowedCountries', JSON.stringify([]));
            formDataToSend.append('allowedLanguages', JSON.stringify([]));
        }

        // Добавляем изображение, если оно есть
        if (imageFile) {
            formDataToSend.append('backgroundImage', imageFile);
        }

        console.log('Form data being sent:', Object.fromEntries(formDataToSend));
        onSave(formDataToSend);
    };

const inputClasses = "bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500 placeholder-zinc-500";

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            {/* Существующие поля формы */}
            <div className="space-y-4">
                <div>
                    <Label htmlFor="taskType" className="text-zinc-300">Task Type</Label>
<Select name="taskType" value={formData.taskType} onValueChange={(value) => handleChange({ target: { name: 'taskType', value } })}>
    <SelectTrigger className={`${inputClasses} w-full`}>
        <SelectValue placeholder="Select task type" />
    </SelectTrigger>
    <SelectContent className="bg-zinc-950 text-white border-zinc-800">
                            <SelectItem value="app">App</SelectItem>
                            <SelectItem value="web">Web</SelectItem>
                            <SelectItem value="ad">Ad (Watch Advertisement)</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="name" className="text-zinc-300">Name</Label>
                        <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="description" className="text-zinc-300">Description</Label>
                        <Input id="description" name="description" value={formData.description} onChange={handleChange} required className={inputClasses} />
                    </div>

                    {formData.taskType !== 'ad' && (
                        <div>
                            <Label htmlFor="coinsReward" className="text-zinc-300">Coins Reward</Label>
                            <Input id="coinsReward" name="coinsReward" type="number" value={formData.coinsReward} onChange={handleChange} className={inputClasses} />
                        </div>
                    )}

                    {formData.taskType === 'ad' && (
                        <div>
                            <Label htmlFor="battlesReward" className="text-zinc-300">Battles Reward</Label>
                            <Input id="battlesReward" name="battlesReward" type="number" value={formData.battlesReward} onChange={handleChange} required className={inputClasses} />
                        </div>
                    )}

                    <div>
                        <Label htmlFor="specialUrl" className="text-zinc-300">
                            {formData.taskType === 'ad' ? 'Fallback URL (Optional)' : 'Special URL'}
                        </Label>
                        <Input
                            id="specialUrl"
                            name="specialUrl"
                            value={formData.specialUrl}
                            onChange={handleChange}
                            required={formData.taskType !== 'ad'}
                            className={inputClasses}
                        />
                        {formData.taskType === 'ad' && (
                            <p className="text-xs text-zinc-500 mt-1">URL to redirect if ad fails to load</p>
                        )}
                    </div>

                    <div>
                        <Label htmlFor="limitClicks" className="text-zinc-300">Limit Clicks</Label>
                        <Input id="limitClicks" name="limitClicks" type="number" value={formData.limitClicks} onChange={handleChange} className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="limitClaims" className="text-zinc-300">Limit Claims</Label>
                        <Input id="limitClaims" name="limitClaims" type="number" value={formData.limitClaims} onChange={handleChange} className={inputClasses} />
                    </div>

                    {formData.taskType === 'web' && (
                        <>
                            <div>
                                <Label htmlFor="confirmationCode" className="text-zinc-300">Confirmation Code</Label>
                                <Input id="confirmationCode" name="confirmationCode" value={formData.confirmationCode} onChange={handleChange} className={inputClasses} />
                            </div>
                        </>
                    )}

                    {formData.taskType === 'ad' && (
                        <>
                            <div>
                                <Label htmlFor="adType" className="text-zinc-300">Ad Type</Label>
                                <Select 
                                    value={formData.adConfig?.adType || 'rewarded'} 
                                    onValueChange={(value) => handleAdConfigChange('adType', value)}
                                >
                                    <SelectTrigger className={`${inputClasses} w-full`}>
                                        <SelectValue placeholder="Select ad type" />
                                    </SelectTrigger>
                                    <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                                        <SelectItem value="rewarded">Rewarded</SelectItem>
                                        <SelectItem value="interstitial">Interstitial</SelectItem>
                                        <SelectItem value="push">Push</SelectItem>
                                        <SelectItem value="embedded">Embedded</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div>
                                <Label htmlFor="adId" className="text-zinc-300">Ad ID (Optional)</Label>
                                <Input 
                                    id="adId" 
                                    value={formData.adConfig?.adId || ''} 
                                    onChange={(e) => handleAdConfigChange('adId', e.target.value)} 
                                    className={inputClasses} 
                                    placeholder="Leave empty for default"
                                />
                            </div>
                            <div>
                                <Label htmlFor="dailyAdClickLimit" className="text-zinc-300">Daily Ad Views Limit</Label>
                                <Input
                                    id="dailyAdClickLimit"
                                    name="dailyAdClickLimit"
                                    type="number"
                                    value={formData.dailyAdClickLimit || 10}
                                    onChange={handleChange}
                                    className={inputClasses}
                                    required
                                />
                                <p className="text-xs text-zinc-500 mt-1">Maximum number of times a user can view this ad per day (-1 for unlimited)</p>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Секция загрузки изображения */}
            <div>
                <Label className="text-zinc-300">Background Image</Label>
                <div className="flex flex-col space-y-2">
                    <ImageUpload onImageUpload={handleImageUpload} />
                </div>
                {imagePreview && (
                    <div className="relative mt-2">
                        <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => {
                                setImagePreview('');
                                setFormData(prev => ({ ...prev, backgroundImage: '' }));
                            }}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                        >
                            X
                        </Button>
                    </div>
                )}
            </div>

            {/* Секция таргетинга */}
            <div className="border-t border-zinc-800 mt-6 pt-6">
                <div className="flex items-center justify-between mb-4">
                    <div>
                        <h3 className="text-lg font-medium text-zinc-200">Targeting Settings</h3>
                        <p className="text-sm text-zinc-400">Enable specific targeting for countries and languages</p>
                    </div>
                    <Switch
                        checked={formData.targeting === 'specific'}
                        onCheckedChange={handleTargetingChange}
                        className="data-[state=checked]:bg-[#e0ff89]"
                    />
                </div>

                {formData.targeting === 'specific' && (
                    <div className="space-y-4">
                        {/* Countries Selection */}
                        <div>
                            <Label htmlFor="allowedCountries" className="text-zinc-300">
                                Allowed Countries
                            </Label>
                            <MultiSelect
                                id="allowedCountries"
                                placeholder="Select countries (empty = all countries)"
                                selected={formData.allowedCountries.map(code => ({
                                    value: code,
                                    label: COUNTRIES.find(c => c.value === code)?.label || code,
                                    icon: COUNTRIES.find(c => c.value === code)?.icon
                                }))}
                                options={{
                                    'Popular Countries': getPopularCountries(),
                                    'Europe': COUNTRIES.filter(c => c.region === 'Europe'),
                                    'Asia': COUNTRIES.filter(c => c.region === 'Asia'),
                                    'Africa': COUNTRIES.filter(c => c.region === 'Africa'),
                                    'North America': COUNTRIES.filter(c => c.region === 'North America'),
                                    'South America': COUNTRIES.filter(c => c.region === 'South America'),
                                    'Oceania': COUNTRIES.filter(c => c.region === 'Oceania'),
                                    'Antarctica': COUNTRIES.filter(c => c.region === 'Antarctica')
                                }}
                                onChange={handleCountriesChange}
                                className="bg-zinc-950 text-zinc-300 border-zinc-800"
                            />
                        </div>

                        {/* Languages Selection */}
                        <div>
                            <Label htmlFor="allowedLanguages" className="text-zinc-300">
                                Allowed Languages
                            </Label>
                            <MultiSelect
                                id="allowedLanguages"
                                placeholder="Select languages (empty = all languages)"
                                selected={formData.allowedLanguages.map(code => ({
                                    value: code,
                                    label: `${LANGUAGES.find(l => l.value === code)?.label} (${LANGUAGES.find(l => l.value === code)?.nativeName})`,
                                }))}
                                options={{
                                    'Common Languages': getCommonLanguages(),
                                    'All Languages': LANGUAGES.map(lang => ({
                                        ...lang,
                                        label: `${lang.label} (${lang.nativeName})`
                                    }))
                                }}
                                onChange={handleLanguagesChange}
                                className="bg-zinc-950 text-zinc-300 border-zinc-800"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Кнопки формы */}
            <div className="flex justify-end space-x-2">
                <Button 
                    type="button" 
                    onClick={onCancel} 
                    className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                >
                    Cancel
                </Button>
                <Button 
                    type="submit" 
                    className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                >
                    Save Special Task
                </Button>
            </div>
        </form>
    );
};

const SpecialTasksManagement = () => {
    const [tasks, setTasks] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingTask, setEditingTask] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [tasksPerPage, setTasksPerPage] = useState(10);
    const [totalTasks, setTotalTasks] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletingTaskId, setDeletingTaskId] = useState(null);
    const [error, setError] = useState(null);

    const fetchTasks = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get(`/api/special-tasks?page=${currentPage}&limit=${tasksPerPage}`);
            console.log('Fetched special tasks:', response.data);
            if (Array.isArray(response.data)) {
                setTasks(response.data);
                setTotalTasks(response.data.length);
            } else if (response.data && Array.isArray(response.data.tasks)) {
                setTasks(response.data.tasks);
                setTotalTasks(response.data.totalTasks || 0);
            } else {
                console.error('Invalid response format:', response.data);
                setError('Received invalid data format from server');
                setTasks([]);
                setTotalTasks(0);
            }
        } catch (error) {
            console.error('Error fetching special tasks:', error);
            setError('Failed to load special tasks. Please try again.');
            setTasks([]);
            setTotalTasks(0);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, tasksPerPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleTasksPerPageChange = (value) => {
        setTasksPerPage(Number(value));
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    const handleSave = async (formData) => {
        try {
            console.log('Attempting to save special task with data:', formData);

            // Проверяем, что formData это экземпляр FormData
            if (!(formData instanceof FormData)) {
                throw new Error('Expected FormData instance');
            }

            let response;
            if (editingTask) {
                console.log('Updating existing special task:', editingTask._id);
                response = await axios.put(`/api/special-tasks/${editingTask._id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Update response:', response.data);
            } else {
                console.log('Creating new special task');
                response = await axios.post('/api/special-tasks', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Create response:', response.data);
            }

            fetchTasks();
            setIsDialogOpen(false);
            setEditingTask(null);
        } catch (error) {
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                data: error.response?.data?.details
            });
            setError(`Error saving special task: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleDelete = (taskId) => {
        setDeletingTaskId(taskId);
        setIsDeleteDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (deletingTaskId) {
            try {
                await axios.delete(`/api/special-tasks/${deletingTaskId}`);
                fetchTasks();
            } catch (error) {
                console.error('Error deleting special task:', error);
                setError('Failed to delete special task. Please try again.');
            }
            setIsDeleteDialogOpen(false);
            setDeletingTaskId(null);
        }
    };

    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(tasks);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setTasks(items);

        try {
            await axios.post('/api/special-tasks/reorder', { tasks: items.map(item => item._id) });
        } catch (error) {
            console.error('Error reordering special tasks:', error);
            setError('Failed to reorder special tasks. Please try again.');
            fetchTasks();
        }
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold text-zinc-200">Special Tasks</h2>
                    <Button 
                        onClick={() => setIsDialogOpen(true)} 
                        className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                    >
                        Add New Special Task
                    </Button>
                </div>

                {/* Сообщение об ошибке */}
                {error && (
                    <div className="mb-4 p-4 bg-red-500 bg-opacity-10 border border-red-500 text-red-400 rounded-md">
                        {error}
                    </div>
                )}

                {/* Список задач */}
                <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
                    {isLoading ? (
                        <div className="flex items-center justify-center py-8">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-zinc-500"></div>
                            <span className="ml-2 text-zinc-400">Loading tasks...</span>
                        </div>
                    ) : (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="tasks">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                                        {tasks && tasks.length > 0 ? (
                                            tasks.map((task, index) => (
                                                <Draggable key={task._id} draggableId={task._id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="relative overflow-hidden rounded-lg shadow-lg bg-zinc-900 border border-zinc-800"
                                                        >
                                                            <div className="relative z-10 p-6 bg-zinc-900 bg-opacity-80 h-full flex flex-col justify-between">
                                                                <div className="flex justify-between items-start">
                                                                    <div>
                                                                        <div className="flex items-center gap-2">
                                                                            {task.taskType === 'app' ? 
                                                                                <Smartphone className="w-6 h-6 text-zinc-300" /> : 
                                                                                (task.taskType === 'web' ? 
                                                                                    <Globe className="w-6 h-6 text-zinc-300" /> : 
                                                                                    <div className="w-6 h-6 bg-zinc-800 rounded-full flex items-center justify-center">
                                                                                        <span className="text-zinc-300 text-xs">AD</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <h3 className="text-xl font-bold text-zinc-300">{task.name}</h3>
                                                                        </div>
                                                                        <p className="text-sm text-zinc-400 mt-1">{task.description}</p>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        {task.taskType === 'ad' ? (
                                                                            <p className="text-2xl font-bold text-zinc-200 flex items-center">
                                                                                {task.battlesReward || 5}
                                                                                <span className="ml-1 text-sm text-zinc-400">battles</span>
                                                                            </p>
                                                                        ) : (
                                                                            <p className="text-2xl font-bold text-zinc-200 flex items-center">
                                                                                {task.coinsReward}
                                                                                <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                                                                            </p>
                                                                        )}
                                                                        <div className="flex items-center justify-end mt-1">
                                                                            <p className={`text-sm ${task.status === 'active' ? 'text-[#e0ff89]' : 'text-red-500'}`}>
                                                                                {task.status === 'active' ? 'Active' : 'Expired'}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <Button className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4">
                                                                        {task.taskType === 'app' 
                                                                            ? 'Open App' 
                                                                            : (task.taskType === 'web' 
                                                                                ? 'Visit Website' 
                                                                                : 'Watch Advertisement'
                                                                            )}
                                                                    </Button>
                                                                </div>
                                                                <div className="flex justify-between items-center mt-4">
                                                                    <div className="text-sm text-zinc-400">
                                                                        <p>Clicks: {task.clickCount}/{task.limitClicks === -1 ? '∞' : task.limitClicks}</p>
                                                                        <p>Claims: {task.claimCount}/{task.limitClaims === -1 ? '∞' : task.limitClaims}</p>
                                                                    </div>
                                                                    <div className="text-sm text-zinc-400">
                                                                        {task.targeting === 'specific' && (
                                                                            <div className="text-right">
                                                                                {task.allowedCountries && task.allowedCountries.length > 0 ? (
                                                                                    <p>Countries: {task.allowedCountries.join(', ')}</p>
                                                                                ) : (
                                                                                    <p>Countries: All</p>
                                                                                )}
                                                                                {task.allowedLanguages && task.allowedLanguages.length > 0 ? (
                                                                                    <p>Languages: {task.allowedLanguages.join(', ')}</p>
                                                                                ) : (
                                                                                    <p>Languages: All</p>
                                                                               )}
                                                                           </div>
                                                                       )}
                                                                   </div>
                                                                   <div className="flex space-x-2">
                                                                       <Button 
                                                                           onClick={() => { setEditingTask(task); setIsDialogOpen(true); }} 
                                                                           className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                                                       >
                                                                           <Pencil size={16} />
                                                                       </Button>
                                                                       <Button 
                                                                           onClick={() => handleDelete(task._id)} 
                                                                           className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                                                       >
                                                                           <Trash2 size={16} />
                                                                       </Button>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           {task.backgroundImage && (
                                                               <img
                                                                   src={task.backgroundImage}
                                                                   alt={task.name}
                                                                   className="absolute inset-0 w-full h-full object-cover opacity-20"
                                                               />
                                                           )}
                                                       </div>
                                                   )}
                                               </Draggable>
                                           ))
                                       ) : (
                                           <div className="text-zinc-400 text-center py-8">
                                               No special tasks available. Add your first task using the button above.
                                           </div>
                                       )}
                                       {provided.placeholder}
                                   </div>
                               )}
                           </Droppable>
                       </DragDropContext>
                   )}
               </div>

               {/* Пагинация и статистика */}
               <div className="mt-4 flex justify-between items-center">
                   <div className="flex items-center space-x-2">
                       <span className="text-zinc-400 text-sm">Tasks per page:</span>
                       <Select value={tasksPerPage.toString()} onValueChange={handleTasksPerPageChange}>
                           <SelectTrigger className="w-[70px] h-8 bg-zinc-950 text-zinc-300 border-zinc-800">
                               <SelectValue />
                           </SelectTrigger>
                           <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                               {[10, 20, 50, 100].map(value => (
                                   <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                               ))}
                           </SelectContent>
                       </Select>
                       <span className="text-zinc-400 text-sm">Total Special Tasks: {totalTasks}</span>
                   </div>
                   
                   <div className="flex items-center space-x-2">
                       <Button
                           onClick={() => handlePageChange(currentPage - 1)}
                           disabled={currentPage === 1}
                           className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
                       >
                           <ChevronLeft size={16} />
                       </Button>
                       <span className="text-zinc-400 text-sm">Page {currentPage} of {Math.max(1, Math.ceil(totalTasks / tasksPerPage))}</span>
                       <Button
                           onClick={() => handlePageChange(currentPage + 1)}
                           disabled={currentPage === Math.ceil(totalTasks / tasksPerPage) || totalTasks === 0}
                           className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
                       >
                           <ChevronRight size={16} />
                       </Button>
                   </div>
               </div>
           </CardContent>

           {/* Диалог добавления/редактирования задачи */}
           <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
               <DialogContent className="max-h-[80vh] overflow-y-auto bg-zinc-950 border border-zinc-800">
                   <DialogHeader>
                       <DialogTitle className="text-zinc-100">{editingTask ? 'Edit Special Task' : 'Add New Special Task'}</DialogTitle>
                       <DialogDescription className="text-zinc-400">
                           {editingTask ? 'Edit the details of the special task.' : 'Enter the details of the new special task.'}
                       </DialogDescription>
                   </DialogHeader>
                   <SpecialTaskForm
                       task={editingTask}
                       onSave={handleSave}
                       onCancel={() => setIsDialogOpen(false)}
                   />
               </DialogContent>
           </Dialog>

           {/* Диалог подтверждения удаления */}
           <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
               <DialogContent className="bg-zinc-950 border border-zinc-800 text-zinc-200 rounded-lg p-6">
                   <DialogHeader>
                       <DialogTitle className="text-zinc-100">Confirm Deletion</DialogTitle>
                       <DialogDescription className="text-zinc-400">
                           Are you sure you want to delete this special task? This action cannot be undone.
                       </DialogDescription>
                   </DialogHeader>
                   <DialogFooter className="mt-6 flex justify-end space-x-4">
                       <Button
                           onClick={() => setIsDeleteDialogOpen(false)}
                           className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                       >
                           Cancel
                       </Button>
                       <Button
                           onClick={confirmDelete}
                           className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
                       >
                           Delete
                       </Button>
                   </DialogFooter>
               </DialogContent>
           </Dialog>
       </Card>
   );
};

export default SpecialTasksManagement;