import React, { useState, useEffect } from 'react';
import { Pencil, Trash2, Upload, ChevronLeft, ChevronRight, Plus, Image, AlertCircle, CheckCircle2 } from 'lucide-react';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Switch } from "../ui/switch";
import axios from 'axios';
import toast from '../CustomToast';
import { useDropzone } from 'react-dropzone';
import { MultiSelect } from "../ui/MultiSelect";
import {
    COUNTRIES,
    groupCountriesByRegion,
    getPopularCountries
} from '../../constants/countries';
import {
    LANGUAGES,
    groupLanguagesByFirstLetter,
    getCommonLanguages
} from '../../constants/languages';

/**
 * Компонент для загрузки изображений историй
 * @param {Function} onImageUpload - функция обработки загруженного изображения
 */
const ImageUpload = ({ onImageUpload }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles && acceptedFiles.length > 0) {
                onImageUpload(acceptedFiles[0]);
            }
        },
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.webp']
        },
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
                ${isDragActive ? 'border-zinc-500 bg-zinc-900' : 'border-zinc-700 hover:border-zinc-600'}
            `}
        >
            <input {...getInputProps()} />
            <Image className="mx-auto mb-4 text-zinc-500" size={48} />
            <p className="text-sm text-zinc-500">
                {isDragActive ? 'Отпустите изображение' : 'Перетащите изображение или нажмите для выбора файла'}
            </p>
        </div>
    );
};

/**
 * Компонент формы для создания/редактирования истории
 * @param {Object} props - свойства компонента
 * @param {Object} props.story - данные истории для редактирования
 * @param {Function} props.onSave - функция сохранения
 * @param {Function} props.onCancel - функция отмены
 */
const StoryForm = ({ story, onSave, onCancel }) => {
    // Form state
    const [formData, setFormData] = useState({
        title: story?.title || '',
        description: story?.description || '',
        duration: story?.duration || 5,
        active: story?.active ?? true,
        maxViewsPerUser: story?.maxViewsPerUser || 0,
        maxTotalViews: story?.maxTotalViews || 0,
        maxLinkClicks: story?.maxLinkClicks || 0,
        maxLinkClicksPerUser: story?.maxLinkClicksPerUser || 0,
        showOnLaunch: story?.showOnLaunch ?? false,
        priority: story?.priority || 0,
        startDate: story?.startDate ? new Date(story.startDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
        endDate: story?.endDate ? new Date(story.endDate).toISOString().split('T')[0] : '',
        items: story?.items || [],
        status: story?.status || 'active',
        views: story?.views || 0,
        linkClickCount: story?.linkClickCount || 0,
        targeting: story?.targeting || 'all',
        allowedCountries: story?.allowedCountries || [],
        allowedLanguages: story?.allowedLanguages || []
    });

    // State for the item being edited
    const [currentItem, setCurrentItem] = useState(null);
    const [itemFormVisible, setItemFormVisible] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');

    // Handle change for main fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    // Handle change for switch fields
    const handleSwitchChange = (name, checked) => {
        setFormData(prev => ({ ...prev, [name]: checked }));
    };

    // Handle image upload
    const handleImageUpload = (file) => {
        // Проверка размера файла (максимум 5MB)
        if (file.size > 5 * 1024 * 1024) {
            toast.error('Файл слишком большой. Максимальный размер: 5MB');
            return;
        }

        // Проверка типа файла
        const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        if (!validTypes.includes(file.type)) {
            toast.error('Неподдерживаемый формат файла. Используйте JPG, PNG, GIF или WEBP');
            return;
        }

        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    // Handle adding a new story item
    const handleAddItem = () => {
        setCurrentItem({
            type: 'image',
            url: '',
            caption: '',
            duration: formData.duration || 5,
            externalLink: ''
        });
        setImageFile(null);
        setImagePreview('');
        setItemFormVisible(true);
    };

    // Handle editing a story item
    const handleEditItem = (item, index) => {
        setCurrentItem({ ...item, index });
        setImagePreview(item.url);
        setItemFormVisible(true);
    };

    // Handle deleting a story item
    const handleDeleteItem = (index) => {
        setFormData(prev => ({
            ...prev,
            items: prev.items.filter((_, i) => i !== index)
        }));
    };

    // Handle saving a story item
    const handleSaveItem = () => {
        if (!currentItem) return;

        console.log('Saving story item:', currentItem);

        // Проверка данных перед сохранением
        if (currentItem.type === 'image' && !imagePreview && !currentItem.url) {
            toast.error('Пожалуйста, загрузите изображение');
            return;
        }

        if (currentItem.type === 'video' && !currentItem.url) {
            toast.error('Пожалуйста, укажите URL видео');
            return;
        }

        // Проверяем URL для видео
        if (currentItem.type === 'video' && currentItem.url) {
            try {
                // Простая проверка формата URL
                const url = new URL(currentItem.url);
                if (!url.protocol.includes('http')) {
                    toast.error('Некорректный URL видео. Используйте полный URL с http:// или https://');
                    return;
                }
            } catch (e) {
                toast.error('Пожалуйста, введите корректный URL видео');
                return;
            }
        }

        // Максимальная длительность для элемента истории
        const maxDuration = 60;
        const minDuration = 1;

        // Нормализуем значение длительности
        let itemDuration = parseInt(currentItem.duration) || parseInt(formData.duration) || 5;
        if (itemDuration > maxDuration) {
            itemDuration = maxDuration;
            toast.warning(`Максимальная длительность ограничена ${maxDuration} секундами`);
        } else if (itemDuration < minDuration) {
            itemDuration = minDuration;
            toast.warning(`Минимальная длительность должна быть не менее ${minDuration} секунды`);
        }

        // Создаем уникальный идентификатор для элемента, если его нет
        const itemId = currentItem.id || `item_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

        // Создаем обновленный объект элемента
        const updatedItem = {
            ...currentItem,
            id: itemId,
            url: imagePreview || currentItem.url,
            caption: currentItem.caption || '',
            duration: itemDuration,
            externalLink: currentItem.externalLink || '',
            // Добавляем временную метку для дебаггинга
            timestamp: new Date().toISOString()
        };

        console.log('Updated item ready to save:', updatedItem);

        const newItems = [...formData.items];

        if (currentItem.index !== undefined) {
            console.log(`Updating existing item at index ${currentItem.index}`);
            // Update existing item
            newItems[currentItem.index] = updatedItem;
        } else {
            console.log('Adding new item to story');
            // Add new item
            newItems.push(updatedItem);
        }

        console.log('New items array:', newItems);

        // Обновляем состояние формы
        setFormData(prev => {
            const updated = { ...prev, items: newItems };
            console.log('Updated form data:', updated);
            return updated;
        });

        // Очищаем состояние элемента
        setItemFormVisible(false);
        setCurrentItem(null);
        setImagePreview('');
        setImageFile(null);

        // Уведомляем пользователя
        toast.success(currentItem.index !== undefined ? 'Элемент обновлен' : 'Элемент добавлен');
    };

    // Handle change for story item fields
    const handleItemChange = (e) => {
        const { name, value } = e.target;
        setCurrentItem(prev => ({ ...prev, [name]: value }));
    };

// Handle form submission
const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.title || !formData.title.trim()) {
        toast.error('Title is required');
        return;
    }

    if (formData.items.length === 0) {
        toast.error('Add at least one item to the story');
        return;
    }

    // Создаем объект FormData для отправки данных истории и файлов вместе
    const formDataToSend = new FormData();
    
    // Добавляем основные поля
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description || '');
    formDataToSend.append('duration', formData.duration);
    formDataToSend.append('active', formData.active.toString());
    formDataToSend.append('maxViewsPerUser', formData.maxViewsPerUser);
    formDataToSend.append('maxTotalViews', formData.maxTotalViews);
    formDataToSend.append('maxLinkClicks', formData.maxLinkClicks);
    formDataToSend.append('maxLinkClicksPerUser', formData.maxLinkClicksPerUser);
    formDataToSend.append('showOnLaunch', formData.showOnLaunch.toString());
    formDataToSend.append('priority', formData.priority);
    formDataToSend.append('startDate', formData.startDate);
    
    if (formData.endDate) {
        formDataToSend.append('endDate', formData.endDate);
    }
    
    // Добавляем настройки таргетинга
    formDataToSend.append('targeting', formData.targeting);
    
    if (formData.targeting === 'specific') {
        formDataToSend.append('allowedCountries', JSON.stringify(formData.allowedCountries || []));
        formDataToSend.append('allowedLanguages', JSON.stringify(formData.allowedLanguages || []));
    }
    
    // Добавляем информацию о элементах истории
    formDataToSend.append('storyItems', JSON.stringify(formData.items));
    
    // Для отладки - вывести содержимое FormData перед отправкой
    console.log('FormData contents before sending:');
    for (let pair of formDataToSend.entries()) {
        if (pair[0] === 'storyItems') {
            console.log(`${pair[0]}: ${pair[1].substring(0, 100)}...`);
        } else {
            console.log(`${pair[0]}: ${pair[1]}`);
        }
    }
    
    onSave(formDataToSend);
};

    const inputClasses = "bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500 placeholder-zinc-500";

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            {/* Main story information */}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                    <Label htmlFor="title" className="text-zinc-300">Title</Label>
                    <Input
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                        className={inputClasses}
                    />
                </div>
                <div>
                    <Label htmlFor="description" className="text-zinc-300">Description</Label>
                    <Input
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className={inputClasses}
                    />
                </div>
                <div>
                    <Label htmlFor="duration" className="text-zinc-300">Standard duration (seconds)</Label>
                    <Input
                        id="duration"
                        name="duration"
                        type="number"
                        min="1"
                        max="60"
                        value={formData.duration}
                        onChange={handleChange}
                        className={inputClasses}
                    />
                </div>
                <div>
                    <Label htmlFor="maxViewsPerUser" className="text-zinc-300">Max views per user (0 = ∞)</Label>
                    <Input
                        id="maxViewsPerUser"
                        name="maxViewsPerUser"
                        type="number"
                        min="0"
                        value={formData.maxViewsPerUser}
                        onChange={handleChange}
                        className={inputClasses}
                    />
                </div>
                <div>
                    <Label htmlFor="maxTotalViews" className="text-zinc-300">Max total views (0 = ∞)</Label>
                    <Input
                        id="maxTotalViews"
                        name="maxTotalViews"
                        type="number"
                        min="0"
                        value={formData.maxTotalViews || 0}
                        onChange={handleChange}
                        className={inputClasses}
                    />
                </div>
                <div>
                    <Label htmlFor="maxLinkClicks" className="text-zinc-300">Max total link clicks (0 = ∞)</Label>
                    <Input
                        id="maxLinkClicks"
                        name="maxLinkClicks"
                        type="number"
                        min="0"
                        value={formData.maxLinkClicks || 0}
                        onChange={handleChange}
                        className={inputClasses}
                    />
                </div>
                <div>
                    <Label htmlFor="maxLinkClicksPerUser" className="text-zinc-300">Max link clicks per user (0 = ∞)</Label>
                    <Input
                        id="maxLinkClicksPerUser"
                        name="maxLinkClicksPerUser"
                        type="number"
                        min="0"
                        value={formData.maxLinkClicksPerUser || 0}
                        onChange={handleChange}
                        className={inputClasses}
                    />
                </div>
                <div>
                    <Label htmlFor="priority" className="text-zinc-300">Priority (higher = shown first)</Label>
                    <Input
                        id="priority"
                        name="priority"
                        type="number"
                        min="0"
                        value={formData.priority}
                        onChange={handleChange}
                        className={inputClasses}
                    />
                </div>
                <div>
                    <Label htmlFor="startDate" className="text-zinc-300">Start date</Label>
                    <Input
                        id="startDate"
                        name="startDate"
                        type="date"
                        value={formData.startDate}
                        onChange={handleChange}
                        className={inputClasses}
                    />
                </div>
                <div>
                    <Label htmlFor="endDate" className="text-zinc-300">End date (optional)</Label>
                    <Input
                        id="endDate"
                        name="endDate"
                        type="date"
                        value={formData.endDate}
                        onChange={handleChange}
                        className={inputClasses}
                    />
                </div>
                <div className="flex items-center justify-between pt-8">
                    <Label htmlFor="active" className="text-zinc-300">Active Story</Label>
                    <Switch
                        id="active"
                        checked={formData.active}
                        onCheckedChange={(checked) => handleSwitchChange('active', checked)}
                        className="data-[state=checked]:bg-[#e0ff89]"
                    />
                </div>
                <div className="flex items-center justify-between pt-8">
                    <Label htmlFor="showOnLaunch" className="text-zinc-300">Show on app launch</Label>
                    <Switch
                        id="showOnLaunch"
                        checked={formData.showOnLaunch}
                        onCheckedChange={(checked) => handleSwitchChange('showOnLaunch', checked)}
                        className="data-[state=checked]:bg-[#e0ff89]"
                    />
                </div>
            </div>

            {/* Targeting section */}
            <div className="border-t border-zinc-800 mt-6 pt-6">
                <div className="flex items-center justify-between mb-4">
                    <div>
                        <h3 className="text-lg font-medium text-zinc-200">Targeting Settings</h3>
                        <p className="text-sm text-zinc-400">Enable specific targeting for countries and languages</p>
                    </div>
                    <Switch
                        id="targeting"
                        checked={formData.targeting === 'specific'}
                        onCheckedChange={(checked) => setFormData(prev => ({
                            ...prev,
                            targeting: checked ? 'specific' : 'all',
                            ...(checked ? {} : { allowedCountries: [], allowedLanguages: [] })
                        }))}
                        className="data-[state=checked]:bg-[#e0ff89]"
                    />
                </div>

                {formData.targeting === 'specific' && (
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="allowedCountries" className="text-zinc-300">
                                Allowed Countries
                            </Label>
                            <MultiSelect
                                id="allowedCountries"
                                placeholder="Select countries (empty = all countries)"
                                selected={formData.allowedCountries ? formData.allowedCountries.map(code => ({
                                    value: code,
                                    label: COUNTRIES.find(c => c.value === code)?.label || code,
                                    icon: COUNTRIES.find(c => c.value === code)?.icon
                                })) : []}
                                options={{
                                    'Popular Countries': getPopularCountries(),
                                    'Europe': COUNTRIES.filter(c => c.region === 'Europe'),
                                    'Asia': COUNTRIES.filter(c => c.region === 'Asia'),
                                    'Africa': COUNTRIES.filter(c => c.region === 'Africa'),
                                    'North America': COUNTRIES.filter(c => c.region === 'North America'),
                                    'South America': COUNTRIES.filter(c => c.region === 'South America'),
                                    'Oceania': COUNTRIES.filter(c => c.region === 'Oceania'),
                                    'Antarctica': COUNTRIES.filter(c => c.region === 'Antarctica')
                                }}
                                onChange={(selectedOptions) => {
                                    const countryValues = selectedOptions
                                        .filter(option => option && option.value)
                                        .map(option => option.value);
                                    setFormData(prev => ({
                                        ...prev,
                                        allowedCountries: countryValues
                                    }));
                                }}
                                className="bg-zinc-950 text-zinc-300 border-zinc-800"
                            />
                        </div>

                        <div>
                            <Label htmlFor="allowedLanguages" className="text-zinc-300">
                                Allowed Languages
                            </Label>
                            <MultiSelect
                                id="allowedLanguages"
                                placeholder="Select languages (empty = all languages)"
                                selected={formData.allowedLanguages ? formData.allowedLanguages.map(code => ({
                                    value: code,
                                    label: `${LANGUAGES.find(l => l.value === code)?.label || code} (${LANGUAGES.find(l => l.value === code)?.nativeName || code})`
                                })) : []}
                                options={{
                                    'Common Languages': getCommonLanguages(),
                                    'All Languages': LANGUAGES.map(lang => ({
                                        ...lang,
                                        label: `${lang.label} (${lang.nativeName})`
                                    }))
                                }}
                                onChange={(selectedOptions) => {
                                    const languageValues = selectedOptions
                                        .filter(option => option && option.value)
                                        .map(option => option.value);
                                    setFormData(prev => ({
                                        ...prev,
                                        allowedLanguages: languageValues
                                    }));
                                }}
                                className="bg-zinc-950 text-zinc-300 border-zinc-800"
                            />
                        </div>
                    </div>
                )}
            </div>


            {/* Story items */}
            <div className="mt-6">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium text-zinc-200">Story Elements</h3>
                    <Button
                        type="button"
                        onClick={handleAddItem}
                        className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4 flex items-center"
                    >
                        <Plus className="w-4 h-4 mr-1" />
                        Add Item
                    </Button>
                </div>

                {formData.items.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {formData.items.map((item, index) => (
                            <div key={index} className="relative bg-zinc-900 rounded-lg overflow-hidden border border-zinc-800">
                                <div className="aspect-[9/16] bg-zinc-800">
                                    <img
                                        src={item.url}
                                        alt={item.caption || `Story item ${index + 1}`}
                                        className="w-full h-full object-cover"
                                        onLoad={() => console.log(`Image loaded successfully: ${item.url}`)}
                                        onError={(e) => {
                                            console.error(`Error loading image: ${item.url}`);
                                            console.error('Image element:', e.target);
                                            e.target.onerror = null; // Предотвращаем бесконечный цикл ошибок
                                            e.target.src = 'https://via.placeholder.com/400x600?text=Image+not+found';
                                        }}
                                    />
                                </div>
                                <div className="p-3">
                                    <p className="text-zinc-300 text-sm truncate">{item.caption || 'No caption'}</p>
                                    <p className="text-zinc-500 text-xs">{item.type} • {item.duration}s</p>
                                    {item.externalLink && (
                                        <p className="text-zinc-300 text-xs truncate mt-1">
                                            <a href={item.externalLink} target="_blank" rel="noopener noreferrer" className="text-[#e0ff89]">
                                                {item.externalLink}
                                            </a>
                                        </p>
                                    )}
                                </div>
                                <div className="absolute top-2 right-2 flex space-x-1">
                                    <Button
                                        type="button"
                                        onClick={() => handleEditItem(item, index)}
                                        className="p-1 h-7 w-7 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                    >
                                        <Pencil size={14} />
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => handleDeleteItem(index)}
                                        className="p-1 h-7 w-7 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                    >
                                        <Trash2 size={14} />
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center py-8 bg-zinc-900 rounded-lg border border-zinc-800">
                        <AlertCircle className="mx-auto h-10 w-10 text-zinc-500 mb-2" />
                        <p className="text-zinc-400">No elements in this story</p>
                        <p className="text-zinc-500 text-sm">Click "Add Item" to create story content</p>
                    </div>
                )}
            </div>

            {/* Form buttons */}
            <div className="flex justify-end space-x-2 pt-4 border-t border-zinc-800 mt-6">
                <Button
                    type="button"
                    onClick={onCancel}
                    className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                >
                    Save Story
                </Button>
            </div>

            {/* Dialog for adding/editing story items */}
            <Dialog open={itemFormVisible} onOpenChange={setItemFormVisible}>
                <DialogContent className="bg-zinc-950 border border-zinc-800 max-w-md max-h-[90vh] overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle className="text-zinc-100">
                            {currentItem?.index !== undefined ? 'Edit Item' : 'Add Item'}
                        </DialogTitle>
                        <DialogDescription className="text-zinc-400">
                            Upload an image or video and add information about the story element.
                        </DialogDescription>
                    </DialogHeader>

                    <div className="space-y-4">
                        <div>
                            <Label className="text-zinc-300">Content Type</Label>
                            <Select
                                value={currentItem?.type || 'image'}
                                onValueChange={(value) => setCurrentItem(prev => ({ ...prev, type: value }))}
                            >
                                <SelectTrigger className="w-full bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                                    <SelectItem value="image">Image</SelectItem>
                                    <SelectItem value="video">Video</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                        <div>
                            <Label className="text-zinc-300">Media file</Label>
                            {currentItem?.type === 'video' ? (
                                <Input
                                    placeholder="Video URL"
                                    value={currentItem?.url || ''}
                                    onChange={(e) => setCurrentItem(prev => ({ ...prev, url: e.target.value }))}
                                    className={inputClasses}
                                />
                            ) : (
                                <div className="space-y-2">
                                    <ImageUpload onImageUpload={handleImageUpload} />
                                    {imagePreview && (
                                        <div className="aspect-video relative mt-2 bg-zinc-900 rounded overflow-hidden">
                                            <img
                                                src={imagePreview}
                                                alt="Preview"
                                                className="w-full h-full object-contain"
                                            />
                                        </div>
                                    )}
                                    {!imageFile && currentItem?.url && (
                                        <div className="aspect-video relative mt-2 bg-zinc-900 rounded overflow-hidden">
                                            <img
                                                src={currentItem.url}
                                                alt="Current"
                                                className="w-full h-full object-contain"
                                            />
                                            <p className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded">
                                                Current image
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <div>
                            <Label htmlFor="caption" className="text-zinc-300">Caption</Label>
                            <Input
                                id="caption"
                                name="caption"
                                value={currentItem?.caption || ''}
                                onChange={handleItemChange}
                                className={inputClasses}
                            />
                        </div>

                        <div>
                            <Label htmlFor="externalLink" className="text-zinc-300">External Link (optional)</Label>
                            <Input
                                id="externalLink"
                                name="externalLink"
                                value={currentItem?.externalLink || ''}
                                onChange={handleItemChange}
                                placeholder="https://example.com"
                                className={inputClasses}
                            />
                        </div>

                        <div>
                            <Label htmlFor="duration" className="text-zinc-300">Duration (seconds)</Label>
                            <Input
                                id="duration"
                                name="duration"
                                type="number"
                                min="1"
                                max="60"
                                value={currentItem?.duration || formData.duration}
                                onChange={handleItemChange}
                                className={inputClasses}
                            />
                        </div>
                    </div>

                    <DialogFooter className="mt-4">
                        <Button
                            type="button"
                            onClick={() => setItemFormVisible(false)}
                            className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={handleSaveItem}
                            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                            disabled={currentItem?.type === 'image' && !imagePreview && !currentItem?.url}
                        >
                            Save Item
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </form>
    );
};

/**
 * Компонент управления историями в админ-панели
 */
const StoriesManagement = () => {
    const [stories, setStories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingStory, setEditingStory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletingStoryId, setDeletingStoryId] = useState(null);
    const [error, setError] = useState(null);

    // Загрузка списка историй
    const fetchStories = async () => {
        setIsLoading(true);
        setError(null);

        try {
            // Запрашиваем истории с сервера с пагинацией
            console.log(`Fetching stories, page: ${currentPage}, limit: ${itemsPerPage}`);
            const response = await axios.get(`/api/stories?page=${currentPage}&limit=${itemsPerPage}`);

            console.log('Stories API response:', response.data);

            // Получаем данные из ответа API
            const storiesData = response.data.stories || [];
            const totalCount = response.data.totalCount || 0;

            console.log(`Received ${storiesData.length} stories, total: ${totalCount}`);

            // Если стория пустые, проверим прямой запрос без пагинации
            if (storiesData.length === 0 && currentPage === 1) {
                console.log('Trying to fetch all stories without pagination...');
                const allResponse = await axios.get('/api/stories');
                console.log('All stories response:', allResponse.data);

                // Если получили массив, используем его
                if (Array.isArray(allResponse.data)) {
                    console.log(`Received ${allResponse.data.length} stories with direct request`);
                    setStories(allResponse.data);
                    setTotalItems(allResponse.data.length);
                }
                // Если получили объект с полем stories, используем его
                else if (allResponse.data && Array.isArray(allResponse.data.stories)) {
                    console.log(`Received ${allResponse.data.stories.length} stories from object`);
                    setStories(allResponse.data.stories);
                    setTotalItems(allResponse.data.totalCount || allResponse.data.stories.length);
                }
                // Иначе используем пустой массив
                else {
                    console.log('No stories found with direct request either');
                    setStories([]);
                    setTotalItems(0);
                }
            } else {
                setStories(storiesData);
                setTotalItems(totalCount);
            }
        } catch (error) {
            console.error('Error fetching stories:', error);
            console.error('Error details:', error.response?.data || error.message);
            setError('Не удалось загрузить истории. Пожалуйста, попробуйте позже.');
            setStories([]);
            setTotalItems(0);
        } finally {
            setIsLoading(false);
        }
    };

    // Загрузка историй при монтировании компонента и изменении пагинации
    useEffect(() => {
        fetchStories();
    }, [currentPage, itemsPerPage]);

    // Обработчик изменения страницы
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    // Обработчик изменения количества элементов на странице
    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(Number(value));
        setCurrentPage(1);
    };

// Обработчик добавления/редактирования истории
const handleSaveStory = async (formData) => {
    console.log('Starting save story process. Form data:', formData);
    
    // Проверяем, что formData существует и содержит необходимые данные
    if (!formData) {
        toast.error('Ошибка: данные формы отсутствуют');
        return;
    }
    
    // Проверка для данных формы
    let title;
    let items = [];
    
    // Проверяем, является ли formData объектом FormData или обычным объектом
    if (formData instanceof FormData) {
        title = formData.get('title');
        const storyItemsStr = formData.get('storyItems');
        if (storyItemsStr) {
            try {
                items = JSON.parse(storyItemsStr);
            } catch (e) {
                console.error('Ошибка парсинга элементов истории:', e);
                items = [];
            }
        }
    } else {
        title = formData.title;
        items = formData.items || [];
    }
    
    if (!title || (typeof title === 'string' && !title.trim())) {
        toast.error('Название истории не может быть пустым');
        return;
    }
    
    if (!Array.isArray(items) || items.length === 0) {
        toast.error('Добавьте хотя бы один элемент в историю');
        return;
    }

    // Отображаем индикатор загрузки
    const loadingToast = toast.loading('Сохранение истории...');
    
    try {
        // Создаем объект FormData для отправки данных истории и файлов вместе
        const apiFormData = new FormData();
        
        // Добавляем основные данные истории
        apiFormData.append('title', formData.get('title') || formData.title);
        apiFormData.append('description', formData.get('description') || formData.description || '');
        apiFormData.append('duration', formData.get('duration') || formData.duration);
        apiFormData.append('active', (formData.get('active') || formData.active).toString());
        apiFormData.append('maxViewsPerUser', formData.get('maxViewsPerUser') || formData.maxViewsPerUser);
        apiFormData.append('maxTotalViews', formData.get('maxTotalViews') || formData.maxTotalViews);
        apiFormData.append('maxLinkClicks', formData.get('maxLinkClicks') || formData.maxLinkClicks);
        apiFormData.append('maxLinkClicksPerUser', formData.get('maxLinkClicksPerUser') || formData.maxLinkClicksPerUser);
        apiFormData.append('showOnLaunch', (formData.get('showOnLaunch') || formData.showOnLaunch).toString());
        apiFormData.append('priority', formData.get('priority') || formData.priority);
        apiFormData.append('startDate', formData.get('startDate') || formData.startDate);
        
        if (formData.get('endDate') || formData.endDate) {
            apiFormData.append('endDate', formData.get('endDate') || formData.endDate);
        }
        
        // Добавляем настройки таргетинга
        const targeting = formData.get('targeting') || formData.targeting;
        apiFormData.append('targeting', targeting);
        
        if (targeting === 'specific') {
            const allowedCountries = formData.get('allowedCountries') || 
                (formData.allowedCountries ? JSON.stringify(formData.allowedCountries) : '[]');
            const allowedLanguages = formData.get('allowedLanguages') || 
                (formData.allowedLanguages ? JSON.stringify(formData.allowedLanguages) : '[]');
            
            apiFormData.append('allowedCountries', allowedCountries);
            apiFormData.append('allowedLanguages', allowedLanguages);
            
            console.log('Target settings:', {
                targeting,
                allowedCountries,
                allowedLanguages
            });
        }
        
        // Создаем массив для хранения информации об элементах истории
        const storyItems = [];
        
        // Получаем элементы истории
        let itemsData = [];
        if (formData instanceof FormData) {
            const storyItemsStr = formData.get('storyItems');
            if (storyItemsStr) {
                try {
                    itemsData = JSON.parse(storyItemsStr);
                } catch (e) {
                    console.error('Error parsing storyItems:', e);
                    itemsData = [];
                }
            }
        } else if (formData.items) {
            itemsData = formData.items;
        }
        
        // Индекс для именования файлов
        let fileIndex = 0;
        
        // Обрабатываем каждый элемент истории
        for (let i = 0; i < itemsData.length; i++) {
            const item = itemsData[i];
            console.log(`Processing item ${i + 1}/${itemsData.length}: ${item.type}`);
            
            const storyItem = {
                type: item.type,
                caption: item.caption || '',
                duration: parseInt(item.duration) || parseInt((formData.get('duration') || formData.duration)) || 5,
                externalLink: item.externalLink || ''
            };
            
            // Если это видео или изображение с уже существующим URL (не dataURL)
            if (item.type === 'video' || !item.url.startsWith('data:')) {
                console.log(`Item ${i + 1} uses existing URL: ${item.url.substring(0, 30)}...`);
                storyItem.url = item.url;
                storyItems.push(storyItem);
            } 
            // Если это новое изображение с dataURL
            else if (item.type === 'image' && item.url.startsWith('data:')) {
                try {
                    console.log(`Item ${i + 1} is a new image with dataURL`);
                    
                    // Конвертируем dataURL в Blob для браузерной среды
                    const response = await fetch(item.url);
                    if (!response.ok) throw new Error('Failed to fetch dataURL');
                    
                    const blob = await response.blob();
                    
                    // Определяем тип контента из blob
                    const contentType = blob.type;
                    
                    // Определяем расширение файла
                    let fileExt = 'jpg';
                    if (contentType) {
                        const parts = contentType.split('/');
                        if (parts.length > 1) {
                            fileExt = parts[1] === 'jpeg' ? 'jpg' : parts[1];
                        }
                    }
                    
                    // Создаем уникальное имя файла
                    const timestamp = new Date().getTime();
                    const fileName = `story_${timestamp}_${fileIndex}.${fileExt}`;
                    fileIndex++;
                    
                    // Создаем файл из blob
                    const file = new File([blob], fileName, { type: contentType });
                    
                    // Добавляем файл в FormData с соответствующим именем
                    console.log(`Adding file to FormData: image_files as ${fileName}, size: ${Math.round(blob.size / 1024)}KB`);
                    apiFormData.append('image_files', file);
                    
                    // Сохраняем информацию о файле для связывания с элементом истории
                    storyItem.fileIndex = i;
                    storyItem.fileName = fileName;
                    storyItem.url = `pending_upload_${fileName}`;
                    
                    storyItems.push(storyItem);
                } catch (error) {
                    console.error(`Error processing image ${i + 1}:`, error);
                    toast.error(`Ошибка обработки изображения ${i + 1}. ${error.message}`);
                    toast.dismiss(loadingToast);
                    return;
                }
            }
        }
        
        // Добавляем информацию о элементах истории в FormData
        apiFormData.append('storyItems', JSON.stringify(storyItems));
        
        // Логгируем добавленные элементы для отладки
        console.log('Story items prepared:', storyItems);
        
        // Логируем содержимое FormData для отладки
        console.log('FormData contents:');
        for (let pair of apiFormData.entries()) {
            if (pair[0] === 'storyItems') {
                console.log(`${pair[0]}: ${pair[1].substring(0, 100)}...`);
            } else if (pair[0] === 'image_files') {
                console.log(`${pair[0]}: [File]`);
            } else {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
        }
        
        // Пробуем основной эндпоинт
        let response;
        let success = false;
        
        try {
            // Пробуем сначала первый подход с FormData с файлами и расширенными эндпоинтами
            if (editingStory) {
                console.log(`Updating story with ID: ${editingStory.id}`);
                response = await axios.post(`/api/stories/${editingStory.id}/update`, apiFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Story updated successfully with custom endpoint. Response:', response.data);
                success = true;
            } else {
                console.log('Creating new story with custom endpoint');
                response = await axios.post('/api/stories/create', apiFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Story created successfully with custom endpoint. Response:', response.data);
                success = true;
            }
        } catch (customEndpointError) {
            console.error('Custom endpoint failed:', customEndpointError);
            
            // Пробуем стандартные эндпоинты с FormData
            try {
                console.log('Trying standard endpoints with FormData...');
                
                if (editingStory) {
                    response = await axios.put(`/api/stories/${editingStory.id}`, apiFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log('Story updated successfully with standard endpoint and FormData. Response:', response.data);
                    success = true;
                } else {
                    response = await axios.post('/api/stories', apiFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log('Story created successfully with standard endpoint and FormData. Response:', response.data);
                    success = true;
                }
            } catch (standardEndpointError) {
                console.error('Standard endpoint with FormData failed:', standardEndpointError);
                
                // Последняя попытка: стандартные эндпоинты с обычным JSON (без файлов)
                try {
                    console.log('Trying standard endpoints with JSON (no files)...');
                    
                    const jsonData = {
                        title: formData.get('title') || formData.title,
                        description: formData.get('description') || formData.description || '',
                        duration: parseInt(formData.get('duration') || formData.duration) || 5,
                        active: (formData.get('active') || formData.active) === 'true',
                        maxViewsPerUser: parseInt(formData.get('maxViewsPerUser') || formData.maxViewsPerUser) || 0,
                        maxTotalViews: parseInt(formData.get('maxTotalViews') || formData.maxTotalViews) || 0,
                        maxLinkClicks: parseInt(formData.get('maxLinkClicks') || formData.maxLinkClicks) || 0,
                        maxLinkClicksPerUser: parseInt(formData.get('maxLinkClicksPerUser') || formData.maxLinkClicksPerUser) || 0,
                        showOnLaunch: (formData.get('showOnLaunch') || formData.showOnLaunch) === 'true',
                        priority: parseInt(formData.get('priority') || formData.priority) || 0,
                        startDate: formData.get('startDate') || formData.startDate,
                        endDate: formData.get('endDate') || formData.endDate || null,
                        targeting: formData.get('targeting') || formData.targeting || 'all',
                        allowedCountries: JSON.parse(formData.get('allowedCountries') || JSON.stringify(formData.allowedCountries || [])),
                        allowedLanguages: JSON.parse(formData.get('allowedLanguages') || JSON.stringify(formData.allowedLanguages || [])),
                        items: itemsData.map(item => ({
                            type: item.type,
                            // Для новых изображений используем placeholder URL
                            url: item.type === 'image' && item.url.startsWith('data:')
                                ? 'https://via.placeholder.com/800x1400'
                                : item.url,
                            caption: item.caption || '',
                            duration: parseInt(item.duration) || parseInt(formData.get('duration') || formData.duration) || 5,
                            externalLink: item.externalLink || ''
                        }))
                    };
                    
                    if (editingStory) {
                        response = await axios.put(`/api/stories/${editingStory.id}`, jsonData);
                        console.log('Story updated with fallback JSON method (without images):', response.data);
                    } else {
                        response = await axios.post('/api/stories', jsonData);
                        console.log('Story created with fallback JSON method (without images):', response.data);
                    }
                    
                    success = true;
                    toast.warning('История сохранена, но изображения заменены на плейсхолдеры. Обратитесь к администратору.');
                } catch (fallbackError) {
                    console.error('All fallback attempts failed:', fallbackError);
                    throw fallbackError; // Пробрасываем ошибку для обработки в общем блоке catch
                }
            }
        }
        
        if (success) {
            // История успешно сохранена
            console.log('Refreshing stories list');
            fetchStories();
            
            // Закрываем диалог и сбрасываем редактируемую историю
            setIsDialogOpen(false);
            setEditingStory(null);
            
            toast.success(editingStory ? 'История успешно обновлена' : 'История успешно создана');
        }
    } catch (error) {
        console.error('Error in handleSaveStory:', error);
        
        let errorMessage = 'Ошибка при сохранении истории. Пожалуйста, попробуйте позже.';
        
        if (error.response) {
            console.error('Error response data:', error.response.data);
            console.error('Error response status:', error.response.status);
            
            if (error.response.data && error.response.data.message) {
                errorMessage = `Ошибка: ${error.response.data.message}`;
            } else if (error.response.status === 500) {
                errorMessage = 'Ошибка на сервере. Пожалуйста, попробуйте позже или обратитесь к администратору.';
            } else if (error.response.status === 413) {
                errorMessage = 'Ошибка: Размер загружаемых файлов слишком большой.';
            } else if (error.response.status === 404) {
                errorMessage = 'Ошибка: API эндпоинт не найден. Обратитесь к администратору.';
            }
        } else if (error.request) {
            console.error('No response received:', error.request);
            errorMessage = 'Ошибка: Сервер не отвечает. Проверьте подключение к интернету.';
        } else {
            console.error('Error message:', error.message);
            errorMessage = `Ошибка: ${error.message}`;
        }
        
        toast.error(errorMessage);
    } finally {
        // Завершаем индикатор загрузки
        toast.dismiss(loadingToast);
    }
};

    // Обработчик удаления истории
    const handleDelete = (storyId) => {
        setDeletingStoryId(storyId);
        setIsDeleteDialogOpen(true);
    };

    // Подтверждение удаления истории
    const confirmDelete = async () => {
        if (deletingStoryId) {
            try {
                // Отправляем запрос на удаление истории
                await axios.delete(`/api/stories/${deletingStoryId}`);

                // Обновляем список историй после успешного запроса
                setStories(prev => prev.filter(story => story.id !== deletingStoryId));
                setTotalItems(prev => prev - 1);

                toast.success('История успешно удалена');
            } catch (error) {
                console.error('Error deleting story:', error);
                toast.error('Ошибка при удалении истории. Пожалуйста, попробуйте позже.');
            }

            // Закрываем диалог и сбрасываем ID удаляемой истории
            setIsDeleteDialogOpen(false);
            setDeletingStoryId(null);
        }
    };

    // Форматирование даты
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString();
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold text-zinc-200">Story Management</h2>
                    <Button
                        onClick={() => { setEditingStory(null); setIsDialogOpen(true); }}
                        className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4 flex items-center"
                    >
                        <Plus className="w-4 h-4 mr-1" />
                        New Story
                    </Button>
                </div>

                {/* Error message */}
                {error && (
                    <div className="mb-4 p-4 bg-red-500 bg-opacity-10 border border-red-500 text-red-400 rounded-md">
                        {error}
                    </div>
                )}

                {/* Stories table */}
                <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
                    {isLoading ? (
                        <div className="flex items-center justify-center py-8">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-zinc-500"></div>
                            <span className="ml-2 text-zinc-400">Loading stories...</span>
                        </div>
                    ) : stories.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {stories.map(story => (
                                <div
                                    key={story.id}
                                    className="relative overflow-hidden rounded-lg shadow-lg bg-zinc-950 border border-zinc-800"
                                >
                                    <div className="p-4">
                                        <div className="flex justify-between items-start">
                                            <div>
                                                <h3 className="text-xl font-bold text-zinc-300">{story.title}</h3>
                                                <p className="text-sm text-zinc-400 mt-1">{story.description}</p>
                                            </div>
                                            <div>
                                                <span className={`px-3 py-1 rounded-full text-xs ${story.active
                                                    ? 'bg-[#e0ff89] bg-opacity-20 text-[#e0ff89]'
                                                    : 'bg-red-500 bg-opacity-20 text-red-400'
                                                    }`}>
                                                    {story.active ? 'Active' : 'Inactive'}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-3 gap-2 mt-4">
                                            {story.items.slice(0, 3).map((item, idx) => (
                                                <div
                                                    key={idx}
                                                    className="aspect-[9/16] bg-zinc-900 rounded overflow-hidden relative group"
                                                >
                                                    <img
                                                        src={item.url}
                                                        alt={item.caption || `Story item ${idx + 1}`}
                                                        className="w-full h-full object-cover"
                                                    />
                                                    {item.caption && (
                                                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-1 text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity">
                                                            {item.caption}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            {story.items.length > 3 && (
                                                <div className="aspect-[9/16] bg-zinc-900 rounded overflow-hidden flex items-center justify-center">
                                                    <span className="text-zinc-400">+{story.items.length - 3}</span>
                                                </div>
                                            )}
                                        </div>

                                        <div className="mt-4 grid grid-cols-2 gap-2 text-sm text-zinc-400">
                                            <div>
                                                <p>Created: {formatDate(story.createdAt)}</p>
                                                <p>Elements: {story.items.length}</p>
                                                <p>Status:
                                                    <span className={story.status === 'active' ? 'text-[#e0ff89]' : story.status === 'expired' ? 'text-red-400' : 'text-yellow-400'}>
                                                        {' '}{story.status || 'active'}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-right">
                                                <p>Views: {story.views}{story.maxTotalViews > 0 ? `/${story.maxTotalViews}` : ''}</p>
                                                <p>Link Clicks: {story.linkClickCount || 0}{story.maxLinkClicks > 0 ? `/${story.maxLinkClicks}` : ''}</p>
                                                <p>Max Views/User: {story.maxViewsPerUser || '∞'}</p>
                                                <p>Max Clicks/User: {story.maxLinkClicksPerUser || '∞'}</p>
                                            </div>
                                        </div>

                                        <div className="mt-4 flex justify-end space-x-2">
                                            <Button
                                                onClick={() => { setEditingStory(story); setIsDialogOpen(true); }}
                                                className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4 flex items-center"
                                            >
                                                <Pencil className="w-4 h-4 mr-1" />
                                                Edit
                                            </Button>
                                            <Button
                                                onClick={() => handleDelete(story.id)}
                                                className="bg-red-500 bg-opacity-20 text-red-400 hover:bg-red-500 hover:bg-opacity-30 rounded-[30px] py-1 px-4 flex items-center"
                                            >
                                                <Trash2 className="w-4 h-4 mr-1" />
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-zinc-400 text-center py-8 bg-zinc-900 rounded-lg border border-zinc-800">
                            <AlertCircle className="mx-auto h-10 w-10 text-zinc-500 mb-2" />
                            <p>No stories available. Create your first story using the button above.</p>
                        </div>
                    )}
                </div>

                {/* Pagination and items per page */}
                <div className="mt-4 flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <span className="text-zinc-400 text-sm">Items per page:</span>
                        <Select value={itemsPerPage.toString()} onValueChange={handleItemsPerPageChange}>
                            <SelectTrigger className="w-[70px] h-8 bg-zinc-950 text-zinc-300 border-zinc-800">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                                {[5, 10, 20, 50].map(value => (
                                    <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <span className="text-zinc-400 text-sm">Total stories: {totalItems}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
                        >
                            <ChevronLeft size={16} />
                        </Button>
                        <span className="text-zinc-400 text-sm">Page {currentPage} of {Math.max(1, Math.ceil(totalItems / itemsPerPage))}</span>
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === Math.ceil(totalItems / itemsPerPage) || totalItems === 0}
                            className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
                        >
                            <ChevronRight size={16} />
                        </Button>
                    </div>
                </div>
            </CardContent>

            {/* Create/Edit story dialog */}
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="max-h-[90vh] overflow-y-auto bg-zinc-950 border border-zinc-800">
                    <DialogHeader>
                        <DialogTitle className="text-zinc-100">{editingStory ? 'Edit Story' : 'Create New Story'}</DialogTitle>
                        <DialogDescription className="text-zinc-400">
                            {editingStory ? 'Modify story details and elements.' : 'Enter information to create a new story.'}
                        </DialogDescription>
                    </DialogHeader>
                    <StoryForm
                        story={editingStory}
                        onSave={handleSaveStory}
                        onCancel={() => setIsDialogOpen(false)}
                    />
                </DialogContent>
            </Dialog>

            {/* Confirm delete dialog */}
            <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                <DialogContent className="bg-zinc-950 border border-zinc-800 text-zinc-200 rounded-lg p-6">
                    <DialogHeader>
                        <div className="flex items-center gap-3 mb-2">
                            <div className="bg-red-500 bg-opacity-20 p-2 rounded-full">
                                <AlertCircle className="w-5 h-5 text-red-400" />
                            </div>
                            <DialogTitle className="text-lg font-semibold text-zinc-100">
                                Confirm Deletion
                            </DialogTitle>
                        </div>
                        <DialogDescription className="text-zinc-400 pl-10">
                            Are you sure you want to delete this story? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="mt-6 flex justify-end space-x-4">
                        <Button
                            onClick={() => setIsDeleteDialogOpen(false)}
                            className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={confirmDelete}
                            className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
                        >
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </Card>
    );
};
export default StoriesManagement;