import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Pencil, Trash2, GripVertical } from "lucide-react";

const ItemTypes = {
  COUNTRY: 'country',
};

const CountryForm = React.memo(({ country, onSave, onCancel }) => {
  const [name, setName] = useState(country ? country.name : '');
  const [code, setCode] = useState(country ? country.code : '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ name, code, _id: country?._id });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Country Name"
        required
        className="bg-zinc-950 text-zinc-300 border-zinc-800 focus:border-zinc-700 focus:ring-zinc-700 placeholder-zinc-500"
      />
      <Input
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder="Country Code (e.g., US, GB)"
        required
        className="bg-zinc-950 text-zinc-300 border-zinc-800 focus:border-zinc-700 focus:ring-zinc-700 placeholder-zinc-500"
      />
      <div className="flex justify-end space-x-2">
        <Button 
          type="button" 
          onClick={onCancel} 
          className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
        >
          Cancel
        </Button>
        <Button 
          type="submit" 
          className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
        >
          Save Country
        </Button>
      </div>
    </form>
  );
});

const CountryItem = React.memo(({ country, index, moveCountry, onEdit, onDelete }) => {
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.COUNTRY,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCountry(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.COUNTRY,
    item: () => ({ id: country._id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  
  return (
    <div 
      ref={ref} 
      style={{ opacity }} 
      data-handler-id={handlerId} 
      className="flex items-center justify-between bg-zinc-900 border border-zinc-800 p-3 mb-2 rounded-md hover:bg-zinc-800 transition-colors"
    >
      <div className="flex items-center space-x-3">
        <GripVertical size={16} className="text-zinc-400 cursor-move" />
        <span className="text-zinc-300">{country.name}</span>
        <span className="text-zinc-500">({country.code})</span>
        <img 
          src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`} 
          alt={country.name} 
          className="w-5 h-auto ml-2" 
        />
      </div>
      <div className="flex space-x-2">
        <Button 
          onClick={() => onEdit(country)} 
          className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
        >
          <Pencil size={16} />
        </Button>
        <Button 
          onClick={() => onDelete(country._id)} 
          className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
        >
          <Trash2 size={16} />
        </Button>
      </div>
    </div>
  );
});

const CountriesManagement = () => {
  const [countries, setCountries] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingCountry, setEditingCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCountries = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/countries');
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setError('Failed to load countries. Please try again.');
      setCountries([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const handleSave = async (countryData) => {
    try {
      if (countryData._id) {
        await axios.put(`/api/countries/${countryData._id}`, countryData);
      } else {
        await axios.post('/api/countries', countryData);
      }
      await fetchCountries();
      setIsDialogOpen(false);
      setEditingCountry(null);
    } catch (error) {
      console.error('Error saving country:', error.response ? error.response.data : error.message);
      setError('Failed to save country. Please try again.');
    }
  };

  const handleDelete = useCallback(async (countryId) => {
    try {
      await axios.delete(`/api/countries/${countryId}`);
      await fetchCountries();
    } catch (error) {
      console.error('Error deleting country:', error);
      setError('Failed to delete country. Please try again.');
    }
  }, [fetchCountries]);

  const moveCountry = useCallback(
    async (dragIndex, hoverIndex) => {
      const dragCountry = countries[dragIndex];
      const newCountries = [...countries];
      newCountries.splice(dragIndex, 1);
      newCountries.splice(hoverIndex, 0, dragCountry);
      
      setCountries(newCountries);

      try {
        const response = await axios.put('/api/countries/reorder', { 
          countries: newCountries.map(country => ({ _id: country._id }))
        });
        if (response.status === 200) {
          setCountries(response.data);
        } else {
          throw new Error('Failed to reorder countries');
        }
      } catch (error) {
        console.error('Error reordering countries:', error.response ? error.response.data : error.message);
        setError('Failed to reorder countries. Please try again.');
        await fetchCountries();
      }
    },
    [countries, fetchCountries],
  );

  const renderCountry = useCallback((country, index) => {
    return (
      <CountryItem
        key={country._id}
        index={index}
        country={country}
        moveCountry={moveCountry}
        onEdit={(country) => {
          setEditingCountry(country);
          setIsDialogOpen(true);
        }}
        onDelete={handleDelete}
      />
    );
  }, [moveCountry, handleDelete]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-6">
        {/* Заголовок и кнопка добавления */}
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-zinc-200">Countries</h2>
          <Button 
            onClick={() => { setEditingCountry(null); setIsDialogOpen(true); }} 
            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
          >
            Add New Country
          </Button>
        </div>

        {/* Сообщение об ошибке */}
        {error && (
          <div className="mb-4 p-4 bg-red-500 bg-opacity-10 border border-red-500 text-red-400 rounded-md">
            {error}
          </div>
        )}

        {/* Список стран */}
        <div className="rounded-md border border-zinc-800 p-4 bg-zinc-950">
          <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 240px)' }}>
            {isLoading ? (
              <div className="flex items-center justify-center py-8">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-zinc-500"></div>
                <span className="ml-2 text-zinc-400">Loading countries...</span>
              </div>
            ) : countries.length > 0 ? (
              countries.map((country, index) => renderCountry(country, index))
            ) : (
              <div className="text-zinc-400 text-center py-8">
                No countries available. Add your first country using the button above.
              </div>
            )}
          </div>
        </div>

        {/* Диалог добавления/редактирования страны */}
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="bg-zinc-950 border border-zinc-800 text-zinc-200 max-w-md">
            <DialogHeader>
              <DialogTitle className="text-zinc-100">
                {editingCountry ? 'Edit Country' : 'Add New Country'}
              </DialogTitle>
              <DialogDescription className="text-zinc-400">
                {editingCountry 
                  ? 'Edit the details of the country.' 
                  : 'Enter the details of the new country.'}
              </DialogDescription>
            </DialogHeader>
            <CountryForm
              country={editingCountry}
              onSave={handleSave}
              onCancel={() => setIsDialogOpen(false)}
            />
          </DialogContent>
        </Dialog>
      </div>
    </DndProvider>
  );
};

export default CountriesManagement;