import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import toast from './CustomToast';
import axios from 'axios';
import { Mail, User, AtSign, Globe } from 'lucide-react';
import { COUNTRIES } from '../constants/countries';
import ProfileRewardAnimation from './ProfileRewardAnimation';

const ProfileSettings = ({ user, onProfileUpdate }) => {
    // Состояния для управления формой
    const [isEditing, setIsEditing] = useState(false);
const [formData, setFormData] = useState({
    displayName: '',
    username: '',
    email: '',
    userCountry: '',
    gender: '',
    ageRange: '',
    userLink: ''
});
    const [errors, setErrors] = useState({});
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [showVerificationInput, setShowVerificationInput] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [canClaimReward, setCanClaimReward] = useState(false);
    const [showRewardAnimation, setShowRewardAnimation] = useState(false);

// Инициализация данных из пропсов
useEffect(() => {
    if (user) {
        setFormData({
            displayName: user.displayName || user.firstName || '',
            username: user.username || '',
            email: user.email || '',
            userCountry: user.userCountry || '',
            gender: user.gender || '',
            ageRange: user.ageRange || '',
            userLink: user.userLink || ''
        });
        setIsEmailVerified(user.isEmailVerified || false);
    }
}, [user, isEditing]);

    // Эффект для проверки возможности получения награды
    useEffect(() => {
        const checkProfileCompletion = () => {
            const isComplete =
                formData.displayName &&
                formData.username &&
                formData.email &&
                isEmailVerified &&
                formData.userCountry &&
                formData.gender &&
                formData.ageRange;

            // Проверяем, что профиль заполнен и награда еще не получена
            setCanClaimReward(isComplete && !user?.profileCompletionReward?.claimed);
        };

        checkProfileCompletion();
    }, [formData, isEmailVerified, user]);

    const GENDER_OPTIONS = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' }
    ];

    const AGE_RANGE_OPTIONS = [
        { value: 'under_18', label: 'Under 18' },
        { value: '18-24', label: '18-24' },
        { value: '25-34', label: '25-34' },
        { value: '35-44', label: '35-44' },
        { value: '45-54', label: '45-54' },
        { value: '55_plus', label: '55+' }
    ];

    // Валидация email
    const validateEmail = (email) => {
        // Проверяем только наличие @ в email
        return email && email.includes('@');
    };

    // Обработка изменений в форме
    const handleInputChange = (e, field) => {
        const value = e.target.value;

        setFormData(prev => ({
            ...prev,
            [field]: value
        }));

        // Очищаем ошибки при вводе
        setErrors(prev => ({
            ...prev,
            [field]: ''
        }));
    };

    // Отправка кода верификации
    const handleSendVerification = async () => {
        try {
            console.log('Starting verification process');
            console.log('Current user:', user);
            console.log('Form data:', formData);

            if (!validateEmail(formData.email)) {
                console.log('Invalid email format:', formData.email);
                setErrors(prev => ({
                    ...prev,
                    email: 'Please enter a valid email address'
                }));
                return;
            }

            console.log('Sending verification request with:', {
                userId: user._id,
                email: formData.email
            });

            const response = await axios.post('/api/users/send-verification', {
                userId: user._id,
                email: formData.email
            });

            console.log('Server response:', response.data);

            setShowVerificationInput(true);
            toast.success('Verification code sent to your email');
        } catch (error) {
            console.error('Verification error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            toast.error(`Error: ${error.response?.data?.message || 'Failed to send verification code'}`);
        }
    };

// Проверка кода верификации
const handleVerifyEmail = async () => {
    try {
        console.log('Verifying code:', {
            userId: user._id,
            code: verificationCode,
            email: formData.email
        });

        const response = await axios.post('/api/users/verify-email', {
            userId: user._id,
            code: verificationCode,
            email: formData.email
        });

        console.log('Verification response:', response.data);

        if (response.data.success) {
            setIsEmailVerified(true);
            setShowVerificationInput(false);

            // Обновляем локальное состояние формы
            setFormData(prev => ({
                ...prev,
                email: formData.email
            }));

            // Обновляем локальное состояние пользователя
            if (onProfileUpdate) {
                const updatedUser = {
                    ...user,
                    isEmailVerified: true,
                    email: formData.email
                };
                
                onProfileUpdate(updatedUser);
                
                // Отправляем событие глобального обновления
                window.dispatchEvent(new CustomEvent('userUpdate', {
                    detail: { 
                        userId: user._id, 
                        isEmailVerified: true,
                        email: formData.email 
                    }
                }));
            }

            toast.success('Email verified successfully');
        }
    } catch (error) {
        console.error('Verification error:', error.response?.data || error.message);
        toast.error(error.response?.data?.message || 'Invalid verification code');
    }
};

    // Сохранение изменений профиля
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Валидация
        const newErrors = {};
        if (!formData.displayName) {
            newErrors.displayName = 'Display name is required';
        }
        if (!formData.username) {
            newErrors.username = 'Username is required';
        }
        if (!formData.email || !validateEmail(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }
        if (!formData.gender) {
            newErrors.gender = 'Gender is required';
        }
        if (!formData.ageRange) {
            newErrors.ageRange = 'Age range is required';
        }
        if (!formData.userCountry) {
            newErrors.userCountry = 'Country is required';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

try {
    const dataToUpdate = {
        ...formData,
        firstName: formData.displayName,
        // Добавляем обязательные поля явно
        displayName: formData.displayName,
        username: formData.username,
        email: formData.email,
        userCountry: formData.userCountry,
        gender: formData.gender,
        ageRange: formData.ageRange,
        userLink: formData.userLink
    };

            const response = await axios.put(`/api/users/${user._id}/profile`, dataToUpdate);

if (response.data.success) {
    // Проверяем, получал ли пользователь уже награду
    const hasReceivedReward = response.data.user.profileCompletionReward?.claimed;

    // Проверяем заполненность профиля
    const isProfileComplete =
        response.data.user.displayName &&
        response.data.user.username &&
        response.data.user.email &&
        response.data.user.isEmailVerified &&
        response.data.user.userCountry &&
        response.data.user.gender &&
        response.data.user.ageRange;

    // Обновляем сначала основные данные пользователя в любом случае
    onProfileUpdate(response.data.user);

    // Выдаем награду только если профиль заполнен и награда ещё не была получена
    if (isProfileComplete && !hasReceivedReward) {
        try {
            const rewardResponse = await axios.post(
                `/api/users/${user._id}/claim-profile-reward`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (rewardResponse.data.success) {
                setShowRewardAnimation(true);

                const updatedUser = {
                    ...response.data.user,
                    coins: rewardResponse.data.newCoins,
                    profileCompletionReward: {
                        ...response.data.user.profileCompletionReward,
                        claimed: true,
                        claimedAt: new Date(),
                        isProfileCompleted: true,
                        rewardHistory: [
                            ...(response.data.user.profileCompletionReward?.rewardHistory || []),
                            {
                                type: 'profile_completion',
                                amount: 200,
                                claimedAt: new Date()
                            }
                        ]
                    }
                };

                // Снова обновляем данные пользователя с наградой
                onProfileUpdate(updatedUser);

                // Отправляем событие глобального обновления
                window.dispatchEvent(new CustomEvent('userUpdate', {
                    detail: { 
                        userId: user._id, 
                        coins: updatedUser.coins,
                        profileCompletionReward: updatedUser.profileCompletionReward 
                    }
                }));

                // Отправляем событие обновления баланса
                window.dispatchEvent(new CustomEvent('balanceUpdate', {
                    detail: { coins: updatedUser.coins }
                }));

                toast.success('Profile completed! You received 200 coins!');
            }
        } catch (rewardError) {
            console.error('Error claiming reward:', rewardError);
            toast.error('Failed to claim reward');
            setIsEditing(false);
        }
    } else {
        setIsEditing(false);
        toast.success('Profile updated successfully');
    }
}
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error(error.response?.data?.message || 'Error updating profile');
        }
    };

    return (
        <Card className="bg-zinc-900 text-white rounded-[20px] overflow-hidden">
            <CardHeader className="bg-zinc-800 px-6 py-4">
                <CardTitle className="text-xl font-bold flex items-center gap-2">
                    <User className="w-5 h-5 text-[#e0ff89]" />
                    Profile Settings
                </CardTitle>
            </CardHeader>
            <CardContent className="p-6">
{!isEditing ? (
    <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
            <div>
                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                    <User className="w-4 h-4" />
                    Display Name
                </p>
                <p className="font-medium">{formData.displayName}</p>
            </div>
            <div>
                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                    <AtSign className="w-4 h-4" />
                    Username
                </p>
                <p className="font-medium">@{formData.username}</p>
            </div>
            <div>
                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                    <Mail className="w-4 h-4" />
                    Email {isEmailVerified ? (
                        <span className="text-green-500 text-xs">(Verified)</span>
                    ) : (
                        <span className="text-yellow-500 text-xs">(Not Verified)</span>
                    )}
                </p>
                <p className="font-medium">{formData.email}</p>
            </div>
            <div>
                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                    <Globe className="w-4 h-4" />
                    Location
                </p>
                {formData.userCountry && (
                    <div className="flex items-center gap-2">
                        <img
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${formData.userCountry}.svg`}
                            alt=""
                            className="w-4 h-4 rounded-sm object-cover"
                        />
                        <p className="font-medium">
                            {COUNTRIES.find(c => c.value === formData.userCountry)?.label}
                        </p>
                    </div>
                )}
            </div>
            <div>
                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                    <User className="w-4 h-4" />
                    Gender
                </p>
                <p className="font-medium">
                    {GENDER_OPTIONS.find(o => o.value === formData.gender)?.label || 'Not specified'}
                </p>
            </div>
            <div>
                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                    <User className="w-4 h-4" />
                    Age Range
                </p>
                <p className="font-medium">
                    {AGE_RANGE_OPTIONS.find(o => o.value === formData.ageRange)?.label || 'Not specified'}
                </p>
            </div>
{formData.userLink && (
<div className="col-span-2">
    <p className="text-[#e0ff89] text-sm flex items-center gap-2">
        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1.95 14.5l-3.97-3.97 1.41-1.41 2.56 2.56 6.59-6.59 1.41 1.41-8 8z"/>
        </svg>
        Telegram
    </p>
    <a 
        href={formData.userLink.startsWith('https://') ? formData.userLink : `https://t.me/${formData.userLink}`} 
        target="_blank" 
        rel="noopener noreferrer nofollow" 
        className="font-medium text-blue-400 hover:underline"
    >
        {formData.userLink}
    </a>
</div>
)}
        </div>
                        <Button
                            onClick={() => setIsEditing(true)}
                            className="mt-4 w-full bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#d1ef7a] rounded-[30px] text-sm"
                        >
                            Edit Profile
                        </Button>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="space-y-4">
                            <div>
                                <Label htmlFor="displayName">Display Name *</Label>
                                <Input
                                    id="displayName"
                                    value={formData.displayName}
                                    onChange={(e) => handleInputChange(e, 'displayName')}
                                    className={`bg-black border-zinc-800 text-white p-2 rounded w-full ${errors.displayName ? 'border-red-500' : ''}`}
                                    placeholder="Enter your display name"
                                />
                                {errors.displayName && (
                                    <p className="text-red-500 text-sm mt-1">{errors.displayName}</p>
                                )}
                            </div>

                            <div>
                                <Label htmlFor="username">Username *</Label>
                                <Input
                                    id="username"
                                    value={formData.username}
                                    onChange={(e) => handleInputChange(e, 'username')}
                                    className={`bg-black border-[#282828] ${errors.username ? 'border-red-500' : ''}`}
                                    placeholder="Enter your username"
                                />
                                {errors.username && (
                                    <p className="text-red-500 text-sm mt-1">{errors.username}</p>
                                )}
                            </div>

                            <div>
                                <Label htmlFor="email">Email *</Label>
                                <div className="flex gap-2">
                                    <Input
                                        id="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={(e) => handleInputChange(e, 'email')}
                                        className={`bg-black border-[#282828] ${errors.email ? 'border-red-500' : ''}`}
                                        placeholder="Enter your email"
                                    />
                                    {!isEmailVerified && (
                                        <Button
                                            type="button"
                                            onClick={handleSendVerification}
                                            className="ml-2 bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#d1ef7a] rounded-[30px] text-sm min-w-[80px]"
                                        >
                                            Verify
                                        </Button>
                                    )}
                                </div>
                                {errors.email && (
                                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                                )}

                                {/* Поле для ввода кода верификации */}
                                {showVerificationInput && !isEmailVerified && (
                                    <div className="mt-4">
                                        <Label htmlFor="verificationCode">Verification Code</Label>
                                        <div className="flex items-center gap-2">
                                            <Input
                                                id="verificationCode"
                                                type="text"
                                                value={verificationCode}
                                                onChange={(e) => setVerificationCode(e.target.value)}
                                                className="bg-black border-zinc-800"
                                                placeholder="Enter 6-digit code"
                                                maxLength={6}
                                            />
                                            <Button
                                                type="button"
                                                onClick={handleVerifyEmail}
                                                className="bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#d1ef7a] rounded-[30px] text-sm min-w-[80px]"
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                type="button"
                                                onClick={handleSendVerification}
                                                className="bg-white text-black px-4 py-1 h-[34px] hover:bg-gray-200 rounded-[30px] text-sm"
                                            >
                                                Resend
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div>
                                <Label htmlFor="userCountry">Your Country</Label>
                                <div className="relative w-full">
                                    {/* Для мобильных */}
                                    <div className="block md:hidden relative">
                                        <select
                                            id="userCountry"
                                            name="userCountry"
                                            value={formData.userCountry || ''}
                                            onChange={(e) => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    userCountry: e.target.value
                                                }));
                                            }}
                                            className="bg-black border-zinc-800 text-white w-full p-2 appearance-none rounded"
                                        >
                                            <option value="" disabled>Select Country</option>
                                            {COUNTRIES.sort((a, b) => a.label.localeCompare(b.label)).map(country => (
                                                <option key={country.value} value={country.value}>
                                                    {country.label}
                                                </option>
                                            ))}
                                        </select>

                                        {/* Добавляем стрелку вниз */}
                                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                            <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    {/* Для десктопа */}
                                    <div className="hidden md:block">
                                        <Select
                                            value={formData.userCountry}
                                            onValueChange={(value) => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    userCountry: value
                                                }));
                                            }}
                                        >
                                            <SelectTrigger className="bg-black border-zinc-800">
                                                <SelectValue placeholder="Select Your Country">
                                                    {formData.userCountry ? (
                                                        <div className="flex items-center gap-2">
                                                            <img
                                                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${formData.userCountry}.svg`}
                                                                alt=""
                                                                className="w-4 h-4 rounded-sm object-cover"
                                                            />
                                                            <span>{COUNTRIES.find(c => c.value === formData.userCountry)?.label}</span>
                                                        </div>
                                                    ) : (
                                                        "Select Your Country"
                                                    )}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent
                                                className="z-[999] bg-zinc-900 max-h-[300px] overflow-y-auto border-zinc-800"
                                            >
                                                {COUNTRIES.sort((a, b) => a.label.localeCompare(b.label)).map(country => (
                                                    <SelectItem
                                                        key={country.value}
                                                        value={country.value}
                                                        className="py-2 px-4 hover:bg-zinc-800 cursor-pointer"
                                                    >
                                                        <div className="flex items-center gap-2">
                                                            <img
                                                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.value}.svg`}
                                                                alt=""
                                                                className="w-4 h-4 rounded-sm object-cover"
                                                            />
                                                            <span>{country.label}</span>
                                                        </div>
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <Label htmlFor="gender">Gender *</Label>
                            <div className="relative w-full">
                                {/* Для мобильных */}
                                <div className="block md:hidden relative">
                                    <select
                                        id="gender"
                                        name="gender"
                                        value={formData.gender || ''}
                                        onChange={(e) => {
                                            setFormData(prev => ({
                                                ...prev,
                                                gender: e.target.value
                                            }));
                                        }}
                                        className="bg-black border-zinc-800 text-white w-full p-2 appearance-none rounded"
                                    >
                                        <option value="" disabled>Select Gender</option>
                                        {GENDER_OPTIONS.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>

                                    {/* Добавляем стрелку вниз */}
                                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                        <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                        </svg>
                                    </div>
                                </div>

                                {/* Для десктопа */}
                                <div className="hidden md:block">
                                    <Select
                                        value={formData.gender}
                                        onValueChange={(value) => {
                                            setFormData(prev => ({
                                                ...prev,
                                                gender: value
                                            }));
                                        }}
                                    >
                                        <SelectTrigger className="bg-black border-zinc-800">
                                            <SelectValue placeholder="Select Your Gender" />
                                        </SelectTrigger>
                                        <SelectContent className="z-[999] bg-zinc-900 max-h-[300px] overflow-y-auto border-zinc-800">
                                            {GENDER_OPTIONS.map(option => (
                                                <SelectItem
                                                    key={option.value}
                                                    value={option.value}
                                                    className="py-2 px-4 hover:bg-zinc-800 cursor-pointer"
                                                >
                                                    {option.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>

<div>
    <Label htmlFor="ageRange">Age Range *</Label>
    <div className="relative w-full">
        {/* Для мобильных */}
        <div className="block md:hidden relative">
            <select
                id="ageRange"
                name="ageRange"
                value={formData.ageRange || ''}
                onChange={(e) => {
                    setFormData(prev => ({
                        ...prev,
                        ageRange: e.target.value
                    }));
                }}
                className="bg-black border-zinc-800 text-white w-full p-2 appearance-none rounded"
            >
                <option value="" disabled>Select Age Range</option>
                {AGE_RANGE_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>

            {/* Добавляем стрелку вниз */}
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </div>
        </div>

        {/* Для десктопа */}
        <div className="hidden md:block">
            <Select
                value={formData.ageRange}
                onValueChange={(value) => {
                    setFormData(prev => ({
                        ...prev,
                        ageRange: value
                    }));
                }}
            >
                <SelectTrigger className="bg-black border-zinc-800">
                    <SelectValue placeholder="Select Your Age Range" />
                </SelectTrigger>
                <SelectContent className="z-[999] bg-zinc-900 max-h-[300px] overflow-y-auto border-zinc-800">
                    {AGE_RANGE_OPTIONS.map(option => (
                        <SelectItem
                            key={option.value}
                            value={option.value}
                            className="py-2 px-4 hover:bg-zinc-800 cursor-pointer"
                        >
                            {option.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    </div>
</div>

<div>
    <Label htmlFor="userLink">Telegram Link (optional)</Label>
    <div className="flex items-center">
        <div className="bg-zinc-800 text-gray-400 px-2 py-2 rounded-l-lg border-r border-zinc-700">
            https://t.me/
        </div>
        <Input
            id="userLink"
            value={(formData.userLink || '').replace('https://t.me/', '')}
            onChange={(e) => {
                const username = e.target.value;
                // Сохраняем полную ссылку с префиксом
                setFormData(prev => ({
                    ...prev,
                    userLink: username ? `https://t.me/${username}` : ''
                }));
            }}
            className="bg-black border-zinc-800 rounded-l-none"
            placeholder="username_or_channel"
        />
    </div>
    <p className="text-gray-400 text-xs mt-1">Specify a Personal Profile, Channel, Chat or Bot. People will be able to follow the link in the Leaderboard.</p>
</div>

{canClaimReward && (
                            <div className="mb-4 p-4 bg-[#e0ff89] rounded-[20px] text-black">
                                <div className="flex justify-between items-center">
                                    <div>
                                        <h4 className="font-bold">Profile Complete!</h4>
                                        <p className="text-sm">Save changes to claim your reward</p>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-bold mr-1">+200</span>
                                        <img src="/images/general/coin.svg" alt="coin" className="w-4 h-4" />
                                    </div>
                                </div>
                            </div>
                        )}

                        {showRewardAnimation && (
                            <ProfileRewardAnimation
                                onClose={() => {
                                    setShowRewardAnimation(false);
                                    setIsEditing(false);
                                    toast.success('Profile updated successfully');
                                }}
                            />
                        )}

                        <div className="flex gap-4">
                            <Button
                                type="submit"
                                className="flex-1 bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#d1ef7a] rounded-[30px] text-sm"
                            >
                                Save Changes
                            </Button>
                            <Button
                                type="button"
                                onClick={() => {
                                    // Сбрасываем изменения в форме к исходным данным пользователя
                                    if (user) {
                                        setFormData({
                                            displayName: user.displayName || user.firstName || '',
                                            username: user.username || '',
                                            email: user.email || '',
                                            userCountry: user.userCountry || '',
                                            gender: user.gender || '',
                                            ageRange: user.ageRange || '',
                                            userLink: user.userLink || ''
                                        });
                                    }
                                    // Сбрасываем состояние показа поля ввода кода верификации
                                    setShowVerificationInput(false);
                                    // Сбрасываем код верификации
                                    setVerificationCode('');
                                    // Возвращаем статус верификации email
                                    setIsEmailVerified(user?.isEmailVerified || false);
                                    // Выходим из режима редактирования
                                    setIsEditing(false);
                                }}
                                className="flex-1 bg-white text-black px-4 py-1 h-[34px] hover:bg-gray-200 rounded-[30px] text-sm"
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                )}
            </CardContent>
        </Card>
    );
};

export default ProfileSettings;