import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import AdRewardComponent from './AdRewardComponent';

const DailyRewards = ({ streakDays: initialStreakDays, level: initialLevel, onClaimReward, onDoubleReward, onClose }) => {
  const [streakDays, setStreakDays] = useState(initialStreakDays || 0);
  const [currentReward, setCurrentReward] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [level, setLevel] = useState(initialLevel || 1);
  const [isAdWatching, setIsAdWatching] = useState(false);
  const [showAdReward, setShowAdReward] = useState(false);
  const [doubledRewardAmount, setDoubledRewardAmount] = useState(0);

  // Определяем награды для каждого уровня
  const rewards = {
    1: { title: 'Brand Rookie', amount: 10 },
    2: { title: 'Brand Pro', amount: 20 },
    3: { title: 'Brand Expert', amount: 30 },
    4: { title: 'Brand Master', amount: 40 },
    5: { title: 'Brand Legend', amount: 50 }
  };

  // Обычная награда
  const handleClaim = async () => {
    setShowAnimation(true);
    const baseReward = rewards[level].amount;
    const bonus = (streakDays + 1) % 10 === 0 ? Math.floor((streakDays + 1) / 10) * 10 : 0;
    const total = baseReward + bonus;

    try {
      await onClaimReward();
      setCurrentReward(total);
      setStreakDays(prev => prev + 1);
      
      setTimeout(() => {
        setShowAnimation(false);
        setTimeout(onClose, 500); // Закрываем после анимации
      }, 1500);
    } catch (error) {
      console.error('Error claiming reward:', error);
      setShowAnimation(false);
      onClose();
    }
  };

  // Удвоенная награда через рекламу
  const handleClaimDouble = async () => {
    setIsAdWatching(true);
    
    try {
      const baseReward = rewards[level].amount;
      const bonus = streakDays % 10 === 0 ? Math.floor(streakDays / 10) * 10 : 0;
      const standardReward = baseReward + bonus;
      // Удваиваем стандартную награду
      const doubleReward = standardReward * 2;
      
      // Проверяем доступность контроллера рекламы
      const hasAdsController = typeof window.TelegramAdsController !== 'undefined';
      console.log('TelegramAdsController available:', hasAdsController);

      // Глобальная переменная для отслеживания статуса награды
      window.rewardGiven = false;

      // Создаем единую функцию обработки закрытия
      const handleAdClosed = async () => {
        if (window.rewardGiven) return;
        window.rewardGiven = true;

        console.log('Ad event detected - giving doubled reward');
        
        // Вызываем функцию, которая добавит награду пользователю
        if (onDoubleReward) {
          try {
            const result = await onDoubleReward(doubleReward);
            
            if (result) {
              // Обновляем внутреннее состояние
              setStreakDays(prev => prev + 1);
              
              // Показываем анимацию с удвоенной наградой
              setIsAdWatching(false);
              setCurrentReward(doubleReward);
              setShowAnimation(true);
              
              setTimeout(() => {
                setShowAnimation(false);
                setTimeout(onClose, 500);
              }, 1500);
            } else {
              setIsAdWatching(false);
              onClose();
            }
          } catch (error) {
            console.error('Error processing double reward:', error);
            setIsAdWatching(false);
            onClose();
          }
        } else {
          // Если нет функции onDoubleReward, просто закрываем
          setIsAdWatching(false);
          onClose();
        }
      };

      // Функция для установки обработчиков событий
      const setupEventListeners = () => {
        // Используем единый обработчик для всех событий с задержкой
        window.addEventListener('focus', handleAdClosed, { once: true });
        
        // Отложенный запуск проверки на visibilitychange
        setTimeout(() => {
          if (document.visibilityState === 'visible' && !window.rewardGiven) {
            handleAdClosed();
          }
        }, 1000);

        // Страховочный таймер - выдаем награду через 15 секунд, если ничего не произошло
        setTimeout(() => {
          if (!window.rewardGiven) {
            console.log('Backup timer triggered - giving reward after 15 seconds');
            handleAdClosed();
          }
        }, 15000);
      };

      if (hasAdsController) {
        // Попытка инициализации контроллера рекламы
        if (typeof window.TelegramAdsController.initialize === 'function') {
          try {
            window.TelegramAdsController.initialize({
              pubId: "962094",
              appId: "1554",
              debug: true,
              disableBanner: true
            });
            console.log('TelegramAdsController initialized successfully');
          } catch (initError) {
            console.error('TelegramAdsController initialization error:', initError);
          }
        }

        setupEventListeners();

        if (typeof window.TelegramAdsController.triggerInterstitialBanner === 'function') {
          console.log('Showing interstitial ad...');
          
          window.TelegramAdsController.triggerInterstitialBanner()
            .then((result) => {
              console.log('Ad displayed successfully:', result);
              
              // Запускаем таймер для автоматической обработки через 2 секунды после успешного показа
              setTimeout(() => {
                if (!window.rewardGiven) {
                  console.log('Auto-triggering reward after ad displayed');
                  handleAdClosed();
                }
              }, 2000);
            })
            .catch((error) => {
              console.error('Error displaying ad:', error);
              setIsAdWatching(false);
              
              // Если не удалось показать рекламу, всё равно выдаём награду
              handleAdClosed();
            });
        } else if (typeof window.TelegramAdsController.triggerNativeNotification === 'function') {
          console.log('Trying native notification method...');
          
          window.TelegramAdsController.triggerNativeNotification()
            .then((result) => {
              console.log('Native ad displayed successfully:', result);
              
              setTimeout(() => {
                if (!window.rewardGiven) {
                  handleAdClosed();
                }
              }, 2000);
            })
            .catch((error) => {
              console.error('Error displaying native ad:', error);
              setIsAdWatching(false);
              
              // Если не удалось показать рекламу, всё равно выдаём награду
              handleAdClosed();
            });
        } else {
          // Если нет доступных методов показа рекламы, просто выдаём награду
          console.log('No ad methods available, giving reward directly');
          handleAdClosed();
        }
      } else {
        // Если контроллер недоступен, всё равно выдаём награду
        console.log('Ad controller not available, giving reward directly');
        setTimeout(() => {
          handleAdClosed();
        }, 2000);
      }
      
      // Добавляем автоматическое закрытие через таймаут, на случай если что-то пойдет не так
      setTimeout(() => {
        if (!window.rewardGiven) {
          console.log('Automatic closing after timeout - no reward received');
          handleAdClosed();
        }
      }, 20000); // 20 секунд максимальное время ожидания
      
    } catch (error) {
      console.error('Error showing ad:', error);
      setIsAdWatching(false);
      onClose(); // Закрываем диалог при любой ошибке
    }
  };

  // Создаем массив для отображения всех 50 дней
  const renderStreakDays = () => {
    // Показываем 50 дней (5 уровней по 10 дней)
    const days = [];
    const totalDays = 50;
    const visibleDays = 10; // Показываем только 10 дней за раз
    
    // Определяем, какой диапазон дней отображать (текущий уровень)
    const currentLevelStartDay = (level - 1) * 10 + 1;
    const currentLevelEndDay = level * 10;
    
    for (let i = currentLevelStartDay; i <= currentLevelEndDay; i++) {
      // Вычисляем, закрашен ли день (получена ли награда)
      // День закрашен, если номер дня <= текущий streakDays
      const isClaimed = i <= streakDays;
      
      days.push(
        <div 
          key={i} 
          className={`
            w-7 h-7 flex items-center justify-center rounded-full text-xs 
            transition-all duration-300
            ${isClaimed 
              ? 'bg-[#e0ff89] text-zinc-900 font-medium' 
              : 'bg-zinc-800 text-zinc-400'}
          `}
        >
          {i - currentLevelStartDay + 1}
        </div>
      );
    }
    
    return days;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50" onClick={(e) => e.stopPropagation()}>
      <div className="w-full max-w-md mx-4 p-6 bg-zinc-900 rounded-xl border border-[#e0ff89]/20">
        {/* Анимация получения награды */}
        {showAnimation && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-xl">
            <div className="animate-bounce flex items-center gap-2 text-4xl font-bold text-[#e0ff89]">
              <img src="/images/general/coin.svg" alt="Coin" className="w-8 h-8" />
              <span>+{currentReward}</span>
            </div>
          </div>
        )}

        {/* Индикатор загрузки рекламы */}
        {isAdWatching && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/80 rounded-xl z-20">
            <div className="flex flex-col items-center gap-4">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#e0ff89]"></div>
              <p className="text-white text-lg">Loading ad...</p>
            </div>
          </div>
        )}

        {/* Заголовок и статус */}
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold text-white">Daily Rewards</h2>
          <div className="flex items-center gap-2">
            <Star className="w-4 h-4 text-[#e0ff89]" />
            <span className="text-[#e0ff89] font-bold">{streakDays} streak</span>
          </div>
        </div>

        {/* Прогресс уровня */}
        <div className="mb-5">
          <div className="flex justify-between text-sm mb-2">
            <span className="text-[#e0ff89]">{rewards[level].title}</span>
            <span className="text-zinc-400">
              {level < 5 ? `${streakDays % 10}/10 to next level` : 'Max Level'}
            </span>
          </div>
          <div className="w-full bg-zinc-800 rounded-full h-2">
            <div
              className="bg-[#e0ff89] rounded-full h-2 transition-all duration-300"
              style={{ width: `${(streakDays % 10) * 10}%` }}
            />
          </div>
        </div>

        {/* Дни стрика */}
        <div className="mb-6">
          <h3 className="text-sm font-medium text-zinc-400 mb-3">Your streak days</h3>
          <div className="flex justify-between gap-1">
            {renderStreakDays()}
          </div>
        </div>

        {/* Кнопки в ряд */}
        <div className="flex gap-3">
          {/* Кнопка обычной награды - с обводкой */}
          <button
            onClick={handleClaim}
            className="flex-1 h-12 rounded-[30px] font-bold bg-zinc-900 border-2 border-[#e0ff89] text-[#e0ff89] hover:bg-[#e0ff89]/10 flex items-center justify-center gap-2 transition-all duration-300"
          >
            <span>Claim +{rewards[level].amount}</span>
            <img src="/images/general/coin.svg" alt="Coin" className="w-5 h-5" />
          </button>
          
          {/* Кнопка удвоенной награды - закрашенная */}
          <button
            onClick={handleClaimDouble}
            className="flex-1 h-12 rounded-[30px] font-bold bg-[#e0ff89] hover:bg-[#e0ff89]/80 text-zinc-900 flex items-center justify-center gap-2 transition-all duration-300"
          >
            <span>Watch ad +{rewards[level].amount * 2}</span>
            <img src="/images/general/coin.svg" alt="Coin" className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DailyRewards;