import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Card, CardContent, CardHeader } from "../ui/card";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";
import { Pencil, Trash2, ChevronLeft, ChevronRight, MoveVertical, MoveDown, MoveUp, Plus } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

const UserForm = ({ user, onSave, onCancel }) => {
  const [formData, setFormData] = useState(user || {
    firstName: '',
    lastName: '',
    username: '',
    coins: 0,
    battles: 0,
    level: 1,
    rank: '',
    referrals: [],
    completedVideoTasks: [],
    completedSocialTasks: [],
    completedSpecialTasks: [],
    tickets: [],
    country: '',
    city: '',
    tonWallet: { address: '', balance: 0 },
    isPremium: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Обработка вложенных полей (например, tonWallet.address)
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <Label htmlFor="firstName" className="text-zinc-300">First Name</Label>
          <Input
            id="firstName"
            name="firstName"
            value={formData.firstName || ''}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="lastName" className="text-zinc-300">Last Name</Label>
          <Input
            id="lastName"
            name="lastName"
            value={formData.lastName || ''}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="username" className="text-zinc-300">Username</Label>
          <Input
            id="username"
            name="username"
            value={formData.username || ''}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="coins" className="text-zinc-300">Coins</Label>
          <Input
            id="coins"
            name="coins"
            type="number"
            value={formData.coins || 0}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="battles" className="text-zinc-300">Battles</Label>
          <Input
            id="battles"
            name="battles"
            type="number"
            value={formData.battles || 0}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="level" className="text-zinc-300">Level</Label>
          <Input
            id="level"
            name="level"
            type="number"
            value={formData.level || 1}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="rank" className="text-zinc-300">Rank</Label>
          <Input
            id="rank"
            name="rank"
            value={formData.rank || ''}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="country" className="text-zinc-300">Country</Label>
          <Input
            id="country"
            name="country"
            value={formData.country || ''}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="city" className="text-zinc-300">City</Label>
          <Input
            id="city"
            name="city"
            value={formData.city || ''}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="tonWallet.address" className="text-zinc-300">Wallet Address</Label>
          <Input
            id="tonWallet.address"
            name="tonWallet.address"
            value={formData.tonWallet?.address || ''}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="tonWallet.balance" className="text-zinc-300">Wallet Balance</Label>
          <Input
            id="tonWallet.balance"
            name="tonWallet.balance"
            type="number"
            value={formData.tonWallet?.balance || 0}
            onChange={handleChange}
            className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
        </div>
        <div>
          <Label htmlFor="isPremium" className="text-zinc-300">Premium Status</Label>
          <Select
            value={formData.isPremium ? "true" : "false"}
            onValueChange={(value) => setFormData(prev => ({ ...prev, isPremium: value === "true" }))}
          >
            <SelectTrigger className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
              <SelectValue placeholder="Select premium status" />
            </SelectTrigger>
            <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
              <SelectItem value="true">Premium</SelectItem>
              <SelectItem value="false">Regular</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      
      <div className="flex justify-end space-x-2 mt-6">
        <Button type="button" onClick={onCancel} variant="outline" className="bg-zinc-900 text-white hover:bg-zinc-800 rounded-[30px] py-1 px-4 border-2 border-zinc-700">Cancel</Button>
        <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save User</Button>
      </div>
    </form>
  );
};

const UsersManagement = () => {
  const [users, setUsers] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [sortColumn, setSortColumn] = useState('username');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  // Новые состояния для блокировки пользователей
  const [isBlockDialogOpen, setIsBlockDialogOpen] = useState(false);
  const [blockingUserId, setBlockingUserId] = useState(null);
  const [blockReason, setBlockReason] = useState('');
  const [isUnblockDialogOpen, setIsUnblockDialogOpen] = useState(false);
  const [unblockingUserId, setUnblockingUserId] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      console.log('Fetching data with params:', { currentPage, usersPerPage, sortColumn, sortOrder, statusFilter });
      
      // Здесь предполагается, что API поддерживает параметры сортировки и пагинации
      const response = await axios.get(`/api/users?page=${currentPage}&limit=${usersPerPage}&sort=${sortColumn}&order=${sortOrder}&status=${statusFilter}`);
      
      console.log('Full users response:', response);
      console.log('Users data:', response.data);
      
      if (response.data && Array.isArray(response.data.users)) {
        setUsers(response.data.users);
        setTotalUsers(response.data.total);
      } else if (Array.isArray(response.data)) {
        // Поддержка старого формата API без пагинации
        // Сортируем здесь на клиенте, если API не поддерживает сортировку
        const sortedData = [...response.data].sort((a, b) => {
          // Обработка сортировки для вложенных полей (например, tonWallet.balance)
          if (sortColumn.includes('.')) {
            const [parent, child] = sortColumn.split('.');
            const valueA = a[parent] ? a[parent][child] : '';
            const valueB = b[parent] ? b[parent][child] : '';
            
            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return sortOrder === 'asc' 
                ? valueA.localeCompare(valueB) 
                : valueB.localeCompare(valueA);
            } else {
              return sortOrder === 'asc' 
                ? (valueA || 0) - (valueB || 0) 
                : (valueB || 0) - (valueA || 0);
            }
          } else {
            // Обычная сортировка для обычных полей
            const valueA = a[sortColumn] || '';
            const valueB = b[sortColumn] || '';
            
            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return sortOrder === 'asc' 
                ? valueA.localeCompare(valueB) 
                : valueB.localeCompare(valueA);
            } else {
              return sortOrder === 'asc' 
                ? (valueA || 0) - (valueB || 0) 
                : (valueB || 0) - (valueA || 0);
            }
          }
        });
        
        setUsers(sortedData);
        setTotalUsers(sortedData.length);
      } else {
        console.error('Invalid users data format:', response.data);
        setUsers([]);
        setTotalUsers(0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setUsers([]);
      setTotalUsers(0);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, usersPerPage, sortColumn, sortOrder, statusFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSave = useCallback(async (userData) => {
    try {
      console.log('Sending user data:', userData);
      let savedUser;
      if (editingUser) {
        const response = await axios.put(`/api/users/${editingUser._id}`, userData);
        savedUser = response.data;
      } else {
        const response = await axios.post('/api/users', userData);
        savedUser = response.data;
      }
      console.log('Saved user:', savedUser);
      await fetchData();
      setIsDialogOpen(false);
      setEditingUser(null);
    } catch (error) {
      console.error('Error saving user:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      alert('Failed to save user. Please try again.');
    }
  }, [editingUser, fetchData]);

  const handleDelete = useCallback((userId) => {
    setDeletingUserId(userId);
    setIsDeleteDialogOpen(true);
  }, []);

const confirmDelete = useCallback(async () => {
  if (deletingUserId) {
    try {
      const response = await axios.delete(`/api/users/${deletingUserId}`);
      if (response.status === 200) {
        console.log('User deleted successfully');
        await fetchData();
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Failed to delete user. Please try again.');
    }
    setIsDeleteDialogOpen(false);
    setDeletingUserId(null);
  }
}, [deletingUserId, fetchData]);

// Обработчик для открытия диалога блокировки
const handleBlock = useCallback((userId) => {
  setBlockingUserId(userId);
  setBlockReason('');
  setIsBlockDialogOpen(true);
}, []);

// Обработчик для блокировки пользователя
const confirmBlock = useCallback(async () => {
  if (blockingUserId) {
    try {
      const response = await axios.post(`/api/users/${blockingUserId}/block`, {
        reason: blockReason
      });
      
      if (response.status === 200) {
        console.log('User blocked successfully');
        await fetchData();
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error blocking user:', error);
      alert('Failed to block user. Please try again.');
    }
    setIsBlockDialogOpen(false);
    setBlockingUserId(null);
    setBlockReason('');
  }
}, [blockingUserId, blockReason, fetchData]);

// Обработчик для открытия диалога разблокировки
const handleUnblock = useCallback((userId) => {
  setUnblockingUserId(userId);
  setIsUnblockDialogOpen(true);
}, []);

// Обработчик для разблокировки пользователя
const confirmUnblock = useCallback(async () => {
  if (unblockingUserId) {
    try {
      const response = await axios.post(`/api/users/${unblockingUserId}/unblock`);
      
      if (response.status === 200) {
        console.log('User unblocked successfully');
        await fetchData();
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error unblocking user:', error);
      alert('Failed to unblock user. Please try again.');
    }
    setIsUnblockDialogOpen(false);
    setUnblockingUserId(null);
  }
}, [unblockingUserId, fetchData]);

// Обновляем функцию фильтрации пользователей
const filteredUsers = useMemo(() => {
  if (!users) return [];
  
  return users.filter(user => {
    // Фильтрация по поисковому запросу
    const matchesSearch = 
      user.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.country?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.rank?.toLowerCase().includes(searchQuery.toLowerCase());
    
    // Фильтрация по статусу
    let matchesStatus = true;
    if (statusFilter === 'premium') {
      matchesStatus = user.isPremium === true;
    } else if (statusFilter === 'regular') {
      matchesStatus = user.isPremium !== true;
    } else if (statusFilter === 'blocked') {
      matchesStatus = user.isBlocked === true;
    } else if (statusFilter === 'active') {
      matchesStatus = user.isBlocked !== true;
    }
    
    return matchesSearch && matchesStatus;
  });
}, [users, searchQuery, statusFilter]);

  const handleAddNewUser = () => {
    setEditingUser(null);
    setIsDialogOpen(true);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUsersPerPageChange = (value) => {
    setUsersPerPage(Number(value));
    setCurrentPage(1);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const renderSortIcon = (column) => {
    if (column === sortColumn) {
      return sortOrder === 'asc' 
        ? <svg className="ml-2 h-4 w-4 inline text-[#e0ff89]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
          </svg> 
        : <svg className="ml-2 h-4 w-4 inline text-[#e0ff89]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>;
    }
    return <svg className="ml-2 h-4 w-4 inline text-zinc-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
    </svg>;
  };

  return (
    <div className="space-y-6">
      {/* Поисковая строка и фильтры */}
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-4">
          <Input
            type="text"
            placeholder="Search User"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full sm:w-64 bg-zinc-950 text-white placeholder-zinc-500 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
          />
          <Select value={statusFilter} onValueChange={setStatusFilter}>
  <SelectTrigger className="w-full sm:w-[150px] bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
    <SelectValue placeholder="Filter by status" />
  </SelectTrigger>
  <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
    <SelectItem value="all">All</SelectItem>
    <SelectItem value="premium">Premium</SelectItem>
    <SelectItem value="regular">Regular</SelectItem>
    <SelectItem value="blocked">Blocked</SelectItem>
    <SelectItem value="active">Active</SelectItem>
  </SelectContent>
</Select>
          <div className="flex-1 flex justify-end">
            <Button onClick={handleAddNewUser} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">
              <Plus size={16} className="mr-2" />
              Add New User
            </Button>
          </div>
        </div>
      </div>
      
{/* Таблица пользователей */}
<div className="rounded-md border border-zinc-800 overflow-hidden">
  <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
    <Table>
      <TableHeader className="sticky top-0 bg-zinc-950 z-10">
        <TableRow className="hover:bg-zinc-900 border-b border-zinc-800">
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Avatar</TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('firstName')}>
            <div className="flex items-center">
              <span>Name</span>
              {renderSortIcon('firstName')}
            </div>
          </TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('username')}>
            <div className="flex items-center">
              <span>Username</span>
              {renderSortIcon('username')}
            </div>
          </TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('coins')}>
            <div className="flex items-center">
              <span>Coins</span>
              {renderSortIcon('coins')}
            </div>
          </TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('battles')}>
            <div className="flex items-center">
              <span>Battles</span>
              {renderSortIcon('battles')}
            </div>
          </TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('level')}>
            <div className="flex items-center">
              <span>Level</span>
              {renderSortIcon('level')}
            </div>
          </TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('rank')}>
            <div className="flex items-center">
              <span>Rank</span>
              {renderSortIcon('rank')}
            </div>
          </TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Referrals</TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Tasks</TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Tickets</TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('country')}>
            <div className="flex items-center">
              <span>Country</span>
              {renderSortIcon('country')}
            </div>
          </TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">City</TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('tonWallet.address')}>
            <div className="flex items-center">
              <span>Wallet</span>
              {renderSortIcon('tonWallet.address')}
            </div>
          </TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('tonWallet.balance')}>
            <div className="flex items-center">
              <span>Balance</span>
              {renderSortIcon('tonWallet.balance')}
            </div>
          </TableHead>
          <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('isPremium')}>
  <div className="flex items-center">
    <span>Status</span>
    {renderSortIcon('isPremium')}
  </div>
</TableHead>
<TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('isBlocked')}>
  <div className="flex items-center">
    <span>Block Status</span>
    {renderSortIcon('isBlocked')}
  </div>
</TableHead>
<TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {filteredUsers && filteredUsers.length > 0 ? (
          filteredUsers.map(user => (
            <TableRow key={user._id} className="hover:bg-zinc-900 border-b border-zinc-800">
              <TableCell className="p-2">
                <Avatar className="w-8 h-8">
                  <AvatarImage src={user.avatar} alt={user.username} />
                  <AvatarFallback className="bg-zinc-700 text-zinc-300">{user.username ? user.username[0] : ''}</AvatarFallback>
                </Avatar>
              </TableCell>
              <TableCell className="text-zinc-300 p-2">{`${user.firstName || ''} ${user.lastName || ''}`}</TableCell>
              <TableCell className="text-zinc-300 p-2">{user.username || 'N/A'}</TableCell>
              <TableCell className="text-zinc-300 p-2">{user.coins || 0}</TableCell>
              <TableCell className="text-zinc-300 p-2">{user.battles || 0}</TableCell>
              <TableCell className="text-zinc-300 p-2">{user.level || 1}</TableCell>
              <TableCell className="text-zinc-300 p-2">{user.rank || 'N/A'}</TableCell>
              <TableCell className="text-zinc-300 p-2">{Array.isArray(user.referrals) ? user.referrals.length : 0}</TableCell>
              <TableCell className="text-zinc-300 p-2">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <div className="flex space-x-1">
                        <span>V:{Array.isArray(user.completedVideoTasks) ? user.completedVideoTasks.length : 0}</span>
                        <span>S:{Array.isArray(user.completedSocialTasks) ? user.completedSocialTasks.length : 0}</span>
                        <span>Sp:{Array.isArray(user.completedSpecialTasks) ? user.completedSpecialTasks.length : 0}</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent className="bg-zinc-800 text-zinc-300 border-zinc-700">
                      <p>Video Tasks: {Array.isArray(user.completedVideoTasks) ? user.completedVideoTasks.length : 0}</p>
                      <p>Social Tasks: {Array.isArray(user.completedSocialTasks) ? user.completedSocialTasks.length : 0}</p>
                      <p>Special Tasks: {Array.isArray(user.completedSpecialTasks) ? user.completedSpecialTasks.length : 0}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
              <TableCell className="text-zinc-300 p-2">{Array.isArray(user.tickets) ? user.tickets.length : 0}</TableCell>
              <TableCell className="text-zinc-300 p-2">
                {user.country ? (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <img src={`https://flagcdn.com/w20/${user.country.toLowerCase()}.png`} alt={user.country} className="w-5 h-5" />
                      </TooltipTrigger>
                      <TooltipContent className="bg-zinc-800 text-zinc-300 border-zinc-700">
                        <p>{user.country}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : 'N/A'}
              </TableCell>
              <TableCell className="text-zinc-300 p-2">
                {user.city || 'N/A'}
              </TableCell>
              <TableCell className="text-zinc-300 p-2">
                {user.tonWallet && user.tonWallet.address ? (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <a 
                          href={`https://tonviewer.com/${user.tonWallet.address}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="text-blue-400 hover:text-blue-300 hover:underline"
                        >
                          <span className="truncate block max-w-[80px]">{user.tonWallet.address}</span>
                        </a>
                      </TooltipTrigger>
                      <TooltipContent className="bg-zinc-800 text-zinc-300 border-zinc-700">
                        <p>Address: {user.tonWallet.address}</p>
                        <p>Click to open in TonViewer</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : 'N/A'}
              </TableCell>
              <TableCell className="text-zinc-300 p-2">
                {user.tonWallet && user.tonWallet.balance ? (
                  <span className="font-semibold text-[#e0ff89]">{user.tonWallet.balance} TON</span>
                ) : '0 TON'}
              </TableCell>
              <TableCell className="p-2">
  <span className={user.isPremium ? "text-[#e0ff89]" : "text-zinc-300"}>
    {user.isPremium ? 'Premium' : 'Regular'}
  </span>
</TableCell>
<TableCell className="p-2">
  {user.isBlocked ? (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <span className="text-red-500 font-semibold">Blocked</span>
        </TooltipTrigger>
        <TooltipContent className="bg-zinc-800 text-zinc-300 border-zinc-700">
          <p>Reason: {user.blockReason || 'No reason provided'}</p>
          <p>Blocked at: {new Date(user.blockedAt).toLocaleString()}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <span className="text-emerald-500">Active</span>
  )}
</TableCell>
<TableCell className="p-2">
  <div className="flex gap-1">
    <Button 
      onClick={() => { setEditingUser(user); setIsDialogOpen(true); }} 
      className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
      title="Edit"
    >
      <Pencil size={14} />
    </Button>
    <Button 
      onClick={() => handleDelete(user._id)} 
      className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
      title="Delete"
    >
      <Trash2 size={14} />
    </Button>
    {user.isBlocked ? (
      <Button 
        onClick={() => handleUnblock(user._id)}
        className="p-1 h-8 w-8 bg-emerald-800 hover:bg-emerald-700 text-white rounded-full"
        title="Unblock User"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M12 2a10 10 0 0 1 10 10c0 5.5-4.5 10-10 10S2 17.5 2 12A10 10 0 0 1 12 2m3 8-6 6m0-6 6 6"/>
        </svg>
      </Button>
    ) : (
      <Button 
        onClick={() => handleBlock(user._id)}
        className="p-1 h-8 w-8 bg-amber-700 hover:bg-amber-600 text-white rounded-full"
        title="Block User"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="12" cy="12" r="10"/>
          <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"/>
        </svg>
      </Button>
    )}
  </div>
</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={16} className="text-center text-zinc-300 p-4">
              {users === null ? 'Loading...' : 'No users found'}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </div>
</div>
      
      {/* Пагинация и количество на странице */}
      <div className="flex flex-col sm:flex-row justify-between items-center gap-2">
        <div className="flex items-center gap-4">
          <div className="flex items-center space-x-2">
            <span className="text-zinc-400 text-sm">Per page:</span>
            <Select value={usersPerPage.toString()} onValueChange={handleUsersPerPageChange}>
              <SelectTrigger className="w-[70px] h-8 bg-zinc-950 text-zinc-300 border-zinc-800">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                {[10, 20, 50, 100].map(value => (
                  <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center space-x-4 text-sm">
            <span className="text-zinc-400">Total: {totalUsers}</span>
          </div>
        </div>
        
        <div className="flex items-center space-x-2">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
          >
            <ChevronLeft size={16} />
          </Button>
          <span className="text-zinc-400 text-sm">Page {currentPage} of {Math.max(1, Math.ceil(totalUsers / usersPerPage))}</span>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(totalUsers / usersPerPage) || totalUsers === 0}
            className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
          >
            <ChevronRight size={16} />
          </Button>
        </div>
      </div>
      
      {/* Диалоги */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-h-[90vh] overflow-y-auto bg-zinc-950 border border-zinc-800">
          <DialogHeader>
            <DialogTitle className="text-zinc-100">{editingUser ? 'Edit User' : 'Add New User'}</DialogTitle>
            <DialogDescription className="text-zinc-400">
              {editingUser ? 'Edit the details of the user.' : 'Enter the details of the new user.'}
            </DialogDescription>
          </DialogHeader>
          <UserForm
            user={editingUser}
            onSave={handleSave}
            onCancel={() => setIsDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
      
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
  <DialogContent className="bg-zinc-950 text-white border border-zinc-800 rounded-lg p-6">
    <DialogHeader>
      <DialogTitle className="text-zinc-100">Confirm Deletion</DialogTitle>
      <DialogDescription className="text-zinc-400">
        Are you sure you want to delete this user? This action cannot be undone.
      </DialogDescription>
    </DialogHeader>
    <DialogFooter className="mt-6 flex justify-end space-x-4">
      <Button
        onClick={() => setIsDeleteDialogOpen(false)}
        className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
      >
        Cancel
      </Button>
      <Button
        onClick={confirmDelete}
        className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
      >
        Delete
      </Button>
    </DialogFooter>
  </DialogContent>
</Dialog>

{/* Диалог блокировки пользователя */}
<Dialog open={isBlockDialogOpen} onOpenChange={setIsBlockDialogOpen}>
  <DialogContent className="bg-zinc-950 text-white border border-zinc-800 rounded-lg p-6">
    <DialogHeader>
      <DialogTitle className="text-zinc-100">Block User</DialogTitle>
      <DialogDescription className="text-zinc-400">
        Are you sure you want to block this user? They will not be able to use the platform.
      </DialogDescription>
    </DialogHeader>
    <div className="py-4">
      <Label htmlFor="blockReason" className="text-zinc-300">Reason for blocking</Label>
      <Input
        id="blockReason"
        value={blockReason}
        onChange={(e) => setBlockReason(e.target.value)}
        placeholder="Enter reason for blocking"
        className="mt-2 bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
      />
    </div>
    <DialogFooter className="mt-6 flex justify-end space-x-4">
      <Button
        onClick={() => setIsBlockDialogOpen(false)}
        className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
      >
        Cancel
      </Button>
      <Button
        onClick={confirmBlock}
        className="bg-amber-700 text-white hover:bg-amber-600 rounded-[30px] py-1 px-4"
      >
        Block User
      </Button>
    </DialogFooter>
  </DialogContent>
</Dialog>

{/* Диалог разблокировки пользователя */}
<Dialog open={isUnblockDialogOpen} onOpenChange={setIsUnblockDialogOpen}>
  <DialogContent className="bg-zinc-950 text-white border border-zinc-800 rounded-lg p-6">
    <DialogHeader>
      <DialogTitle className="text-zinc-100">Unblock User</DialogTitle>
      <DialogDescription className="text-zinc-400">
        Are you sure you want to unblock this user? They will regain access to the platform.
      </DialogDescription>
    </DialogHeader>
    <DialogFooter className="mt-6 flex justify-end space-x-4">
      <Button
        onClick={() => setIsUnblockDialogOpen(false)}
        className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
      >
        Cancel
      </Button>
      <Button
        onClick={confirmUnblock}
        className="bg-emerald-700 text-white hover:bg-emerald-600 rounded-[30px] py-1 px-4"
      >
        Unblock User
      </Button>
    </DialogFooter>
  </DialogContent>
</Dialog>
    </div>
  );
};

export default UsersManagement;