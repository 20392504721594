import React, { useState, useRef, useEffect } from 'react';

// Создаем кэш для загруженных изображений
const imageCache = new Set();
const imageFailed = new Set();

// Функция для предварительной загрузки изображения
const preloadImage = (src, fallbackSrc) => {
  return new Promise((resolve, reject) => {
    if (imageCache.has(src)) {
      resolve(src);
      return;
    }

    if (imageFailed.has(src) && fallbackSrc) {
      resolve(fallbackSrc);
      return;
    }

    const img = new Image();
    img.src = src;
    img.onload = () => {
      imageCache.add(src);
      sessionStorage.setItem(`image-${src}`, 'loaded');
      resolve(src);
    };
    img.onerror = () => {
      if (fallbackSrc) {
        imageFailed.add(src);
        sessionStorage.setItem(`image-${src}`, 'failed');
        preloadImage(fallbackSrc).then(() => resolve(fallbackSrc)).catch(reject);
      } else {
        reject(new Error(`Failed to load image: ${src}`));
      }
    };
  });
};

const OptimizedImage = ({ 
  src, 
  alt, 
  className, 
  priority = false, 
  fallbackSrc = '/images/general/default-ticket-bg.jpg',
  ...props 
}) => {
  const originalSrc = src;
  const isInitiallyLoaded = imageCache.has(src) || sessionStorage.getItem(`image-${src}`) === 'loaded';
  const isFailed = imageFailed.has(src) || sessionStorage.getItem(`image-${src}`) === 'failed';
  const [isLoaded, setIsLoaded] = useState(isInitiallyLoaded);
  const [currentSrc, setCurrentSrc] = useState(isFailed ? fallbackSrc : src);
  const imageRef = useRef();

  useEffect(() => {
    // Если изображение уже загружено, не делаем ничего
    if (isInitiallyLoaded || isFailed) {
      return;
    }

    const loadImage = async () => {
      try {
        const loadedSrc = await preloadImage(src, fallbackSrc);
        setCurrentSrc(loadedSrc);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error loading image:', error);
        // В случае ошибки и отсутствия запасного изображения,
        // устанавливаем стандартное запасное изображение
        setCurrentSrc('/images/general/default-ticket-bg.jpg');
        setIsLoaded(true);
      }
    };

    if (priority) {
      loadImage();
    } else {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            loadImage();
            observer.unobserve(entry.target);
          }
        },
        {
          rootMargin: '100px',
          threshold: 0.1
        }
      );

      if (imageRef.current) {
        observer.observe(imageRef.current);
      }

      return () => {
        if (imageRef.current) {
          observer.unobserve(imageRef.current);
        }
      };
    }
  }, [src, fallbackSrc, priority, isInitiallyLoaded, isFailed]);

  // Обработка случая, когда src изменился
  useEffect(() => {
    if (originalSrc !== src && !isInitiallyLoaded && !isFailed) {
      setIsLoaded(false);
      setCurrentSrc(src);
    }
  }, [src, originalSrc, isInitiallyLoaded, isFailed]);

  const imageStyle = {
    opacity: isInitiallyLoaded || isLoaded ? 1 : 0,
    filter: isInitiallyLoaded || isLoaded ? 'none' : 'blur(10px)',
    transform: isInitiallyLoaded || isLoaded ? 'scale(1)' : 'scale(1.1)',
    transition: isInitiallyLoaded ? 'none' : 'opacity 0.5s ease, filter 0.5s ease, transform 0.5s ease'
  };

  const placeholderStyle = {
    backgroundColor: '#1a1a1a',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: isInitiallyLoaded || isLoaded ? 0 : 1,
    transition: isInitiallyLoaded ? 'none' : 'opacity 0.5s ease'
  };

  return (
    <div ref={imageRef} className={`${className || ''} relative overflow-hidden`} {...props}>
      <div style={placeholderStyle} />
      <img
        src={currentSrc}
        alt={alt || 'Image'}
        className="w-full h-full object-cover"
        style={imageStyle}
        loading={priority ? "eager" : "lazy"}
        onError={(e) => {
          if (currentSrc !== fallbackSrc) {
            setCurrentSrc(fallbackSrc);
            imageFailed.add(src);
            sessionStorage.setItem(`image-${src}`, 'failed');
          }
        }}
      />
    </div>
  );
};

export const PreloadImages = ({ images = [] }) => {
  useEffect(() => {
    const preloadAll = async () => {
      try {
        const unloadedImages = images.filter(
          src => !imageCache.has(src) && !imageFailed.has(src) && 
                 !sessionStorage.getItem(`image-${src}`) &&
                 sessionStorage.getItem(`image-${src}`) !== 'failed'
        );
        
        await Promise.all(
          unloadedImages.map(src => preloadImage(src))
        );
      } catch (error) {
        console.error('Error preloading images:', error);
      }
    };

    preloadAll();
  }, [images]);

  return null;
};

export default OptimizedImage;