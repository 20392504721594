import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Loader2 } from "lucide-react";
import BoringAvatar from 'boring-avatars';

const DrawResults = ({ ticketId }) => {
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const response = await axios.get(`/api/draws/${ticketId}`);
                if (response.data && response.data.success) {
                    setResults(response.data.draw);
                } else if (response.data) {
                    setResults(response.data);
                } else {
                    setError('Failed to fetch draw results');
                }
            } catch (err) {
                console.error('Error fetching draw results:', err);
                setError('Failed to fetch draw results');
            } finally {
                setLoading(false);
            }
        };

        if (ticketId) {
            fetchResults();
        }
    }, [ticketId]);

    if (loading) {
        return (
            <div className="mt-2 bg-[#e0ff89] rounded-[20px] p-4 text-center border-2 border-black">
                <Loader2 className="h-8 w-8 animate-spin text-black mx-auto" />
                <p className="text-black mt-2 font-medium">Loading results...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="mt-2 bg-[#e0ff89] rounded-[20px] p-4 text-center border-2 border-black">
                <p className="text-red-500 font-medium">{error}</p>
            </div>
        );
    }

    if (!results || results.status !== 'completed') {
        return (
            <div className="mt-2 bg-[#e0ff89] rounded-[20px] p-4 text-center border-2 border-black">
                <p className="text-black font-medium">Draw results will be available soon</p>
            </div>
        );
    }

    return (
        <div className="mt-2 overflow-hidden">
            <div className="bg-[#e0ff89] rounded-t-[20px] p-4 border-x-2 border-t-2 border-black">
                <div className="text-center">
                    <h3 className="text-xl font-bold text-black mb-2">Draw Results</h3>
                    <div className="flex justify-center items-center gap-2 text-sm text-black">
                        <span>Total Tickets: {results.totalTickets}</span>
                        <span>•</span>
                        <span>Participants: {results.participantsCount}</span>
                    </div>
                    <p className="text-sm text-black/70 mt-1">
                        {new Date(results.drawDate).toLocaleString()}
                    </p>
                </div>
            </div>

            {results.winners && results.winners.length > 0 ? (
                <>
                    <div className="bg-black text-[#e0ff89] grid grid-cols-2 p-3 border-x-2 border-black">
                        <div className="text-left pl-14">User</div>
                        <div className="text-right pr-2">Prize</div>
                    </div>
                    
                    <div className="border-x-2 border-b-2 border-black rounded-b-[20px] overflow-hidden bg-[#e0ff89]">
                        {results.winners.map((winner, index) => (
                            <div 
                                key={index} 
                                className={`grid grid-cols-2 items-center p-3 hover:bg-black/5 transition-colors ${
                                    index !== results.winners.length - 1 ? 'border-b border-black/10' : ''
                                }`}
                            >
                                <div className="flex items-center gap-2">
                                    <div className="w-8 h-8 rounded-full overflow-hidden border-2 border-black">
                                        {winner.user?.avatar ? (
                                            <img 
                                                src={winner.user.avatar} 
                                                alt={winner.user.username}
                                                className="w-full h-full object-cover"
                                            />
                                        ) : (
                                            <BoringAvatar
                                                size={32}
                                                name={winner.user?.username || 'Unknown'}
                                                variant="beam"
                                                colors={["#E3EBFF", "#C8D5F9", "#AAC0F9", "#8DA8F2", "#7090EC"]}
                                            />
                                        )}
                                    </div>
                                    <span className="font-medium truncate">
                                        {winner.user?.username || 'Unknown User'}
                                    </span>
                                </div>
<div className="flex items-center justify-end gap-1">
    <span className="font-bold text-black">
        {winner.prize.value}
    </span>
    {winner.prize.type === 'money' && (
        <img 
            src={`/images/general/${winner.prize.currency?.toLowerCase() || 'usdt'}.png`}
            alt={winner.prize.currency || 'USDT'} 
            className="h-5 w-5"
        />
    )}
</div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className="bg-[#e0ff89] rounded-b-[20px] p-4 text-center border-x-2 border-b-2 border-black">
                    <p className="text-black font-medium">No winners have been drawn yet</p>
                </div>
            )}
        </div>
    );
};

export default DrawResults;