import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from './ui/button';

const InitialProfileSetup = ({ user, onProfileUpdate, onComplete }) => {
    // Состояния для хранения выбранных значений
    const [gender, setGender] = useState(user?.gender || '');
    const [ageRange, setAgeRange] = useState(user?.ageRange || '');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    // Для отладки - логируем данные пользователя
    useEffect(() => {
        console.log('InitialProfileSetup получил пользователя:', user);
    }, [user]);

    // Отслеживаем изменения токена для отладки
    useEffect(() => {
        console.log('Текущий токен:', localStorage.getItem('token'));
    }, []);

    useEffect(() => {
        // При инициализации проверяем наличие telegramInitData в localStorage
        const telegramInitData = localStorage.getItem('telegramInitData');
        console.log('TelegramInitData в localStorage:', telegramInitData ? 'присутствует' : 'отсутствует');

        // Если данных нет, но Telegram WebApp доступен, сохраняем initData
        if (!telegramInitData && window.Telegram && window.Telegram.WebApp) {
            const initData = window.Telegram.WebApp.initData;
            if (initData) {
                localStorage.setItem('telegramInitData', initData);
                console.log('TelegramInitData сохранен в localStorage');
            }
        }
    }, []);

    // Константы с вариантами выбора, такие же как в ProfileSettings
    const GENDER_OPTIONS = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' }
    ];

    const AGE_RANGE_OPTIONS = [
        { value: 'under_18', label: 'Under 18' },
        { value: '18-24', label: '18-24' },
        { value: '25-34', label: '25-34' },
        { value: '35-44', label: '35-44' },
        { value: '45-54', label: '45-54' },
        { value: '55_plus', label: '55+' }
    ];

    useEffect(() => {
        // Проверка существующих данных при монтировании
        try {
            const storedUser = JSON.parse(localStorage.getItem('user') || '{}');
            console.log('InitialProfileSetup: данные из localStorage:', storedUser);

            // Если у пользователя уже заполнены данные, используем их
            if (storedUser && storedUser.gender) {
                setGender(storedUser.gender);
            }

            if (storedUser && storedUser.ageRange) {
                setAgeRange(storedUser.ageRange);
            }
        } catch (e) {
            console.error('Ошибка при чтении localStorage:', e);
        }
    }, []);

    // Обработчик отправки формы
    const handleSubmit = async () => {
        // Проверка заполнения всех полей
        if (!gender || !ageRange) {
            setError('Please select both gender and age range');
            return;
        }

        const token = localStorage.getItem('token');
        console.log("Авторизационный токен:", token);
        const telegramInitData = localStorage.getItem('telegramInitData');
        console.log("Telegram InitData присутствует:", !!telegramInitData);

        setIsLoading(true);
        setError('');

        try {
            // Проверим структуру объекта user для отладки
            console.log('Объект user при обновлении профиля:', user);
            if (user && user.user) {
                console.log('Вложенный объект user.user:', user.user);
            }

            // Получаем все возможные идентификаторы для безопасного обновления
            let mongoId = null;
            let telegramId = null;

            // Извлекаем данные из разных источников
            if (user) {
                // Прямая структура
                if (user._id) {
                    mongoId = user._id;
                    console.log('MongoDB ID из user._id:', mongoId);
                }

                if (user.telegramId || user.telegram_id) {
                    telegramId = user.telegramId || user.telegram_id;
                    console.log('Telegram ID из user:', telegramId);
                }

                // Вложенная структура
                if (user.user && user.user._id) {
                    mongoId = user.user._id;
                    console.log('MongoDB ID из user.user._id:', mongoId);
                }

                if (user.user && (user.user.telegramId || user.user.telegram_id)) {
                    telegramId = user.user.telegramId || user.user.telegram_id;
                    console.log('Telegram ID из user.user:', telegramId);
                }
            }

            // Пробуем получить из localStorage
            try {
                const storedUser = JSON.parse(localStorage.getItem('user') || '{}');
                if (!mongoId && storedUser._id) {
                    mongoId = storedUser._id;
                    console.log('MongoDB ID из localStorage:', mongoId);
                }

                if (!telegramId && (storedUser.telegramId || storedUser.telegram_id)) {
                    telegramId = storedUser.telegramId || storedUser.telegram_id;
                    console.log('Telegram ID из localStorage:', telegramId);
                }
            } catch (e) {
                console.error('Ошибка при чтении localStorage:', e);
            }

            // Пробуем получить из Telegram WebApp
            if (!telegramId && window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
                telegramId = window.Telegram.WebApp.initDataUnsafe.user.id;
                console.log('Telegram ID из WebApp:', telegramId);
            }

            // Собираем данные для обновления
            const updatedProfile = {
                gender,
                ageRange
            };

            let response;

            // Попробуем сразу несколько методов обновления профиля
            try {
                // 1. Метод без указания ID через POST (наиболее безопасный)
                console.log('Попытка обновления через общий метод без ID...');
                response = await axios.post('/api/users/update-profile', updatedProfile, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'X-Telegram-Init-Data': localStorage.getItem('telegramInitData') || ''
                    }
                });
                console.log('Обновление профиля успешно (метод 1):', response.data);
            } catch (error1) {
                console.log('Метод 1 не сработал:', error1.message);

                // 2. Метод с MongoDB ID через PUT 
                if (mongoId) {
                    try {
                        console.log('Попытка обновления с MongoDB ID...');
                        response = await axios.put(`/api/users/${mongoId}/profile`, updatedProfile, {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                'Content-Type': 'application/json',
                                'X-Telegram-Init-Data': localStorage.getItem('telegramInitData') || ''
                            }
                        });
                        console.log('Обновление профиля успешно (метод 2):', response.data);
                    } catch (error2) {
                        console.log('Метод 2 не сработал:', error2.message);
                        throw error2;
                    }
                } else {
                    // Если метод 1 не сработал и нет MongoDB ID
                    throw error1;
                }
            }

            console.log('Ответ API при обновлении профиля:', response.data);

            if (response.data && (response.data.success || response.data.user)) {
                // Получаем полный объект пользователя из ответа
                const responseUser = response.data.user || {};

                // Определяем ID пользователя
                const userId = responseUser._id || mongoId || (user?.user?._id || user?._id);

                // Создаем полный обновленный объект пользователя
                const updatedUserData = {
                    ...(user?.user || user || {}), // Берем существующие данные
                    ...responseUser,               // Добавляем данные из ответа
                    gender: gender,                // Гарантируем, что выбранный пол сохранен
                    ageRange: ageRange,            // Гарантируем, что выбранный возраст сохранен
                    _id: userId                    // Гарантируем, что ID пользователя сохранен
                };

                console.log('Полные обновленные данные пользователя:', updatedUserData);

                // Сохраняем в localStorage для согласованности данных
                try {
                    localStorage.setItem('user', JSON.stringify(updatedUserData));
                    console.log('Данные пользователя сохранены в localStorage');
                } catch (e) {
                    console.error('Ошибка при сохранении в localStorage:', e);
                }

                // Отправляем событие глобального обновления с полным объектом пользователя
                window.dispatchEvent(new CustomEvent('userUpdate', {
                    detail: updatedUserData
                }));

                // Обновляем родительский компонент напрямую через callback
                if (onProfileUpdate && typeof onProfileUpdate === 'function') {
                    onProfileUpdate(updatedUserData);
                }

                // Закрываем модальное окно с небольшой задержкой
                if (onComplete && typeof onComplete === 'function') {
                    setTimeout(() => {
                        onComplete();
                    }, 300);
                }
            } else {
                throw new Error(response.data?.message || 'Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);

            // Если ошибка связана с ID пользователя, тогда используем прямую отправку данных через события
            if (gender && ageRange) {
                // Создаем обновленный объект пользователя локально
                const updatedUserData = {
                    ...(user?.user || user || {}),
                    gender: gender,
                    ageRange: ageRange
                };

                // Отправляем событие глобального обновления пользователя в любом случае
                window.dispatchEvent(new CustomEvent('userUpdate', {
                    detail: {
                        userId: user?._id || (user?.user?._id) || 'unknown',
                        gender: gender,
                        ageRange: ageRange,
                        isProfileSetupCompleted: true
                    }
                }));

                // Сохраняем в localStorage принудительно
                try {
                    const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
                    const updatedLocalUser = {
                        ...currentUser,
                        gender,
                        ageRange
                    };
                    localStorage.setItem('user', JSON.stringify(updatedLocalUser));
                } catch (e) {
                    console.error('Ошибка при сохранении в localStorage:', e);
                }

                // Принудительное закрытие окна даже в случае ошибки
                if (onComplete && typeof onComplete === 'function') {
                    setTimeout(() => {
                        onComplete();
                    }, 100);
                }

                // Обновляем родительский компонент
                if (onProfileUpdate && typeof onProfileUpdate === 'function') {
                    onProfileUpdate(updatedUserData);
                }

                return;
            } else {
                setError(error.response?.data?.message || error.message || 'An error occurred');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black/80 z-[100] profile-setup-container">
            <div className="bg-zinc-900 rounded-[30px] p-6 max-w-md w-full mx-4">
                <h2 className="text-2xl font-bold text-white mb-6 text-center">Welcome to BattleBrands!</h2>

                <p className="text-gray-300 mb-6 text-center">
                    Please select your gender and age range to help us provide better brand recommendations.
                </p>

                <div className="space-y-6">
                    {/* Выбор пола - нативный select */}
                    <div className="space-y-2">
                        <label className="text-white font-medium block">Gender</label>
                        <div className="relative">
                            <select
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className="w-full bg-zinc-800 border-zinc-700 text-white rounded-md py-2 px-3 appearance-none"
                            >
                                <option value="" disabled>Select Gender</option>
                                {GENDER_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                    {/* Выбор возрастного диапазона - нативный select */}
                    <div className="space-y-2">
                        <label className="text-white font-medium block">Age Range</label>
                        <div className="relative">
                            <select
                                value={ageRange}
                                onChange={(e) => setAgeRange(e.target.value)}
                                className="w-full bg-zinc-800 border-zinc-700 text-white rounded-md py-2 px-3 appearance-none"
                            >
                                <option value="" disabled>Select Age Range</option>
                                {AGE_RANGE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                    {/* Сообщение об ошибке */}
                    {error && (
                        <div className="text-red-500 text-sm text-center">{error}</div>
                    )}

                    {/* Кнопка продолжить */}
                    <Button
                        onClick={handleSubmit}
                        disabled={isLoading || !gender || !ageRange}
                        className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-3 font-medium disabled:opacity-50"
                    >
                        {isLoading ? (
                            <div className="flex items-center justify-center">
                                <div className="h-5 w-5 rounded-full border-2 border-t-transparent border-black animate-spin mr-2"></div>
                                <span>Processing...</span>
                            </div>
                        ) : (
                            'Continue'
                        )}
                    </Button>
                </div>

                <p className="text-xs text-gray-400 mt-4 text-center">
                    We respect your privacy. This information is used only for analytics and personalized recommendations.
                </p>
            </div>
        </div>
    );
};

export default InitialProfileSetup;