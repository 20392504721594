import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";
import { Label } from "../ui/label";
import { Pencil, Trash2, Upload, ChevronLeft, ChevronRight } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useDropzone } from 'react-dropzone';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Switch } from "../ui/switch";
import { MultiSelect } from "../ui/MultiSelect";
import { SiYoutube } from 'react-icons/si';
import {
    COUNTRIES,
    groupCountriesByRegion,
    getPopularCountries
} from '../../constants/countries';
import {
    LANGUAGES,
    groupLanguagesByFirstLetter,
    getCommonLanguages
} from '../../constants/languages';

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
                ${isDragActive ? 'border-zinc-500 bg-zinc-900' : 'border-zinc-700 hover:border-zinc-600'}
            `}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-zinc-500" size={48} />
            <p className="text-sm text-zinc-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
        </div>
    );
};

const VideoTaskForm = ({ task, onSave, onCancel }) => {
    const [formData, setFormData] = useState(task || {
        name: '',
        description: '',
        backgroundImage: '',
        coinsReward: '',
        videoUrl: '',
        confirmationCode: '',
        timerDuration: '',
        limitClicks: -1,
        limitCodeEntry: -1,
        targeting: 'all',
        allowedCountries: [],
        allowedLanguages: []
    });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(task?.backgroundImage || '');

    // Сгруппированные данные для селектов
    const groupedCountries = groupCountriesByRegion();
    const popularCountries = getPopularCountries();
    const commonLanguages = getCommonLanguages();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleTargetingChange = (checked) => {
        setFormData(prev => ({
            ...prev,
            targeting: checked ? 'specific' : 'all',
            ...(checked ? {} : { allowedCountries: [], allowedLanguages: [] })
        }));
    };

    const handleCountriesChange = (selectedOptions) => {
        console.log('Selected countries:', selectedOptions);
        const countryValues = selectedOptions
            .filter(option => option && option.value)
            .map(option => option.value);
        console.log('Country values to save:', countryValues);
        setFormData(prev => ({
            ...prev,
            allowedCountries: countryValues
        }));
    };

    const handleLanguagesChange = (selectedOptions) => {
        console.log('Selected languages:', selectedOptions);
        const languageValues = selectedOptions
            .filter(option => option && option.value)
            .map(option => option.value);
        console.log('Language values to save:', languageValues);
        setFormData(prev => ({
            ...prev,
            allowedLanguages: languageValues
        }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();

        console.log('Form data before processing:', formData);

        // Добавляем базовые поля
        const baseFields = [
            'name', 'description', 'coinsReward', 'videoUrl',
            'confirmationCode', 'timerDuration', 'limitClicks', 'limitCodeEntry', 'targeting'
        ];

        baseFields.forEach(field => {
            if (formData[field] !== undefined) {
                formDataToSend.append(field, formData[field]);
            }
        });

        // Обработка таргетинга и связанных массивов
        if (formData.targeting === 'specific') {
            // Обработка стран
            const countries = formData.allowedCountries ?
                (Array.isArray(formData.allowedCountries) ? formData.allowedCountries : [])
                    .filter(Boolean) : [];

            // Обработка языков
            const languages = formData.allowedLanguages ?
                (Array.isArray(formData.allowedLanguages) ? formData.allowedLanguages : [])
                    .filter(Boolean) : [];

            console.log('Processing countries:', countries);
            console.log('Processing languages:', languages);

            formDataToSend.append('allowedCountries', JSON.stringify(countries));
            formDataToSend.append('allowedLanguages', JSON.stringify(languages));
        } else {
            formDataToSend.append('allowedCountries', JSON.stringify([]));
            formDataToSend.append('allowedLanguages', JSON.stringify([]));
        }

        // Добавляем изображение, если оно есть
        if (imageFile) {
            formDataToSend.append('backgroundImage', imageFile);
        }

        console.log('Final form data contents:');
        for (let pair of formDataToSend.entries()) {
            if (pair[0] === 'allowedCountries' || pair[0] === 'allowedLanguages') {
                console.log(pair[0], 'parsed:', JSON.parse(pair[1]));
            } else {
                console.log(pair[0], pair[1]);
            }
        }

        onSave(formDataToSend);
    };

const inputClasses = "bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500 placeholder-zinc-500";

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="name" className="text-zinc-300">Name</Label>
                    <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="description" className="text-zinc-300">Description</Label>
                    <Input id="description" name="description" value={formData.description} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="coinsReward" className="text-zinc-300">Coins Reward</Label>
                    <Input id="coinsReward" name="coinsReward" type="number" value={formData.coinsReward} onChange={handleChange} className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="videoUrl" className="text-zinc-300">Video URL</Label>
                    <Input id="videoUrl" name="videoUrl" value={formData.videoUrl} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="confirmationCode" className="text-zinc-300">Confirmation Code</Label>
                    <Input id="confirmationCode" name="confirmationCode" value={formData.confirmationCode} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="timerDuration" className="text-zinc-300">Timer Duration (seconds)</Label>
                    <Input id="timerDuration" name="timerDuration" type="number" value={formData.timerDuration} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="limitClicks" className="text-zinc-300">Limit Number of Clicks</Label>
                    <Input id="limitClicks" name="limitClicks" type="number" value={formData.limitClicks} onChange={handleChange} className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="limitCodeEntry" className="text-zinc-300">Limit Code Entry</Label>
                    <Input id="limitCodeEntry" name="limitCodeEntry" type="number" value={formData.limitCodeEntry} onChange={handleChange} className={inputClasses} />
                </div>
            </div>
            <div>
                <Label className="text-zinc-300">Background Image</Label>
                <div className="flex flex-col space-y-2">
                    <ImageUpload onImageUpload={handleImageUpload} />
                </div>
                {imagePreview && (
                    <div className="relative mt-2">
                        <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => {
                                setImagePreview('');
                                setFormData(prev => ({ ...prev, backgroundImage: '' }));
                            }}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                        >
                            X
                        </Button>
                    </div>
                )}
            </div>
            {/* Targeting section */}
            <div className="border-t border-zinc-800 mt-6 pt-6">
                <div className="flex items-center justify-between mb-4">
                    <div>
                        <h3 className="text-lg font-medium text-zinc-200">Targeting Settings</h3>
                        <p className="text-sm text-zinc-400">Enable specific targeting for countries and languages</p>
                    </div>
                    <Switch
                        checked={formData.targeting === 'specific'}
                        onCheckedChange={handleTargetingChange}
                        className="data-[state=checked]:bg-[#e0ff89]"
                    />
                </div>

                {formData.targeting === 'specific' && (
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="allowedCountries" className="text-zinc-300">
                                Allowed Countries
                            </Label>
                            <MultiSelect
                                id="allowedCountries"
                                placeholder="Select countries (empty = all countries)"
                                selected={formData.allowedCountries.map(code => ({
                                    value: code,
                                    label: COUNTRIES.find(c => c.value === code)?.label || code,
                                    icon: COUNTRIES.find(c => c.value === code)?.icon
                                }))}
                                options={{
                                    'Popular Countries': getPopularCountries(),
                                    'Europe': COUNTRIES.filter(c => c.region === 'Europe'),
                                    'Asia': COUNTRIES.filter(c => c.region === 'Asia'),
                                    'Africa': COUNTRIES.filter(c => c.region === 'Africa'),
                                    'North America': COUNTRIES.filter(c => c.region === 'North America'),
                                    'South America': COUNTRIES.filter(c => c.region === 'South America'),
                                    'Oceania': COUNTRIES.filter(c => c.region === 'Oceania'),
                                    'Antarctica': COUNTRIES.filter(c => c.region === 'Antarctica')
                                }}
                                onChange={handleCountriesChange}
                                className="bg-zinc-950 text-zinc-300 border-zinc-800"
                            />
                        </div>

                        <div>
                            <Label htmlFor="allowedLanguages" className="text-zinc-300">
                                Allowed Languages
                            </Label>
                            <MultiSelect
                                id="allowedLanguages"
                                placeholder="Select languages (empty = all languages)"
                                selected={formData.allowedLanguages.map(code => ({
                                    value: code,
                                    label: `${LANGUAGES.find(l => l.value === code)?.label} (${LANGUAGES.find(l => l.value === code)?.nativeName})`,
                                }))}
                                options={{
                                    'Common Languages': getCommonLanguages(),
                                    'All Languages': LANGUAGES.map(lang => ({
                                        ...lang,
                                        label: `${lang.label} (${lang.nativeName})`
                                    }))
                                }}
                                onChange={handleLanguagesChange}
                                className="bg-zinc-950 text-zinc-300 border-zinc-800"
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex justify-end space-x-2">
                <Button 
                    type="button" 
                    onClick={onCancel} 
                    className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                >
                    Cancel
                </Button>
                <Button 
                    type="submit" 
                    className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                >
                    Save Video Task
                </Button>
            </div>
        </form>
    );
};

const VideoTasksManagement = () => {
    const [tasks, setTasks] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingTask, setEditingTask] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [tasksPerPage, setTasksPerPage] = useState(10);
    const [totalTasks, setTotalTasks] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletingTaskId, setDeletingTaskId] = useState(null);
    const [error, setError] = useState(null);

    const fetchTasks = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get(`/api/video-tasks?page=${currentPage}&limit=${tasksPerPage}`);
            console.log('Fetched video tasks:', response.data);
            if (Array.isArray(response.data)) {
                setTasks(response.data);
                setTotalTasks(response.data.length);
            } else if (response.data && Array.isArray(response.data.tasks)) {
                setTasks(response.data.tasks);
                setTotalTasks(response.data.totalTasks || 0);
            } else {
                console.error('Invalid response format:', response.data);
                setError('Received invalid data format from server');
                setTasks([]);
                setTotalTasks(0);
            }
        } catch (error) {
            console.error('Error fetching video tasks:', error);
            setError('Failed to load video tasks. Please try again.');
            setTasks([]);
            setTotalTasks(0);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, tasksPerPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleTasksPerPageChange = (value) => {
        setTasksPerPage(Number(value));
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    const handleSave = async (formDataToSend) => {
        try {
            console.log('Attempting to save video task with data:', formDataToSend);

            // Проверяем, что formDataToSend это экземпляр FormData
            if (!(formDataToSend instanceof FormData)) {
                throw new Error('Expected FormData instance');
            }

            let response;
            if (editingTask) {
                console.log('Updating existing video task:', editingTask._id);
                response = await axios.put(`/api/video-tasks/${editingTask._id}`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Update response:', response.data);
            } else {
                console.log('Creating new video task');
                response = await axios.post('/api/video-tasks', formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Create response:', response.data);
            }

            fetchTasks();
            setIsDialogOpen(false);
            setEditingTask(null);
        } catch (error) {
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                data: error.response?.data?.details
            });
            setError(`Error saving video task: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleDelete = (taskId) => {
        setDeletingTaskId(taskId);
        setIsDeleteDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (deletingTaskId) {
            try {
                await axios.delete(`/api/video-tasks/${deletingTaskId}`);
                fetchTasks();
            } catch (error) {
                console.error('Error deleting video task:', error);
                setError('Failed to delete video task. Please try again.');
            }
            setIsDeleteDialogOpen(false);
            setDeletingTaskId(null);
        }
    };

    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(tasks);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setTasks(items);

        try {
            await axios.post('/api/video-tasks/reorder', { tasks: items.map(item => item._id) });
        } catch (error) {
            console.error('Error reordering video tasks:', error);
            setError('Failed to reorder video tasks. Please try again.');
            fetchTasks();
        }
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold text-zinc-200">Video Tasks</h2>
                    <Button 
                        onClick={() => setIsDialogOpen(true)} 
                        className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                    >
                        Add New Video Task
                    </Button>
                </div>

                {/* Сообщение об ошибке */}
                {error && (
                    <div className="mb-4 p-4 bg-red-500 bg-opacity-10 border border-red-500 text-red-400 rounded-md">
                        {error}
                    </div>
                )}

                {/* Список задач */}
                <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
                    {isLoading ? (
                        <div className="flex items-center justify-center py-8">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-zinc-500"></div>
                            <span className="ml-2 text-zinc-400">Loading tasks...</span>
                        </div>
                    ) : (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="tasks">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                                        {tasks && tasks.length > 0 ? (
                                            tasks.map((task, index) => (
                                                <Draggable key={task._id} draggableId={task._id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="relative overflow-hidden rounded-lg shadow-lg bg-zinc-900 border border-zinc-800"
                                                        >
                                                            <div className="relative z-10 p-6 bg-zinc-900 bg-opacity-80 h-full flex flex-col justify-between">
                                                                <div className="flex justify-between items-start">
                                                                    <div>
                                                                        <div className="flex items-center gap-2">
                                                                            <SiYoutube className="w-6 h-6 text-zinc-300" />
                                                                            <h3 className="text-xl font-bold text-zinc-300">{task.name}</h3>
                                                                        </div>
                                                                        <p className="text-sm text-zinc-400 mt-1">{task.description}</p>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        <p className="text-2xl font-bold text-zinc-200 flex items-center">
                                                                            {task.coinsReward}
                                                                            <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                                                                        </p>
                                                                        <div className="flex items-center justify-end mt-1">
                                                                            <p className={`text-sm ${task.status === 'active' ? 'text-[#e0ff89]' : 'text-red-500'}`}>
                                                                                {task.status === 'active' ? 'Active' : 'Expired'}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <Button className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4">
                                                                        Watch Video
                                                                    </Button>
                                                                </div>
                                                                <div className="flex justify-between items-center mt-4">
                                                                    <div className="text-sm text-zinc-400">
                                                                        <p>Clicks: {task.clickCount}/{task.limitClicks === -1 ? '∞' : task.limitClicks}</p>
                                                                        <p>Code Entries: {task.codeEntryCount}/{task.limitCodeEntry === -1 ? '∞' : task.limitCodeEntry}</p>
                                                                    </div>
                                                                    <div className="text-sm text-zinc-400">
                                                                        {task.targeting === 'specific' && (
                                                                            <div className="text-right">
                                                                                {task.allowedCountries && task.allowedCountries.length > 0 ? (
                                                                                    <p>Countries: {task.allowedCountries.join(', ')}</p>
                                                                                ) : (
                                                                                    <p>Countries: All</p>
                                                                                )}
                                                                                {task.allowedLanguages && task.allowedLanguages.length > 0 ? (
                                                                                    <p>Languages: {task.allowedLanguages.join(', ')}</p>
                                                                                ) : (
                                                                                    <p>Languages: All</p>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="flex space-x-2">
                                                                        <Button 
                                                                            onClick={() => { setEditingTask(task); setIsDialogOpen(true); }} 
                                                                            className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                                                        >
                                                                            <Pencil size={16} />
                                                                        </Button>
                                                                        <Button 
                                                                            onClick={() => handleDelete(task._id)} 
                                                                            className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                                                        >
                                                                            <Trash2 size={16} />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {task.backgroundImage && (
                                                                <img
                                                                    src={task.backgroundImage}
                                                                    alt={task.name}
                                                                    className="absolute inset-0 w-full h-full object-cover opacity-20"
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        ) : (
                                            <div className="text-zinc-400 text-center py-8">
                                                No video tasks available. Add your first task using the button above.
                                            </div>
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </div>

                {/* Пагинация и статистика */}
                <div className="mt-4 flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <span className="text-zinc-400 text-sm">Tasks per page:</span>
                        <Select value={tasksPerPage.toString()} onValueChange={handleTasksPerPageChange}>
                            <SelectTrigger className="w-[70px] h-8 bg-zinc-950 text-zinc-300 border-zinc-800">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                                {[10, 20, 50, 100].map(value => (
                                    <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <span className="text-zinc-400 text-sm">Total Video Tasks: {totalTasks}</span>
                    </div>
                    
                    <div className="flex items-center space-x-2">
                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
                        >
                            <ChevronLeft size={16} />
                        </Button>
                        <span className="text-zinc-400 text-sm">Page {currentPage} of {Math.max(1, Math.ceil(totalTasks / tasksPerPage))}</span>
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === Math.ceil(totalTasks / tasksPerPage) || totalTasks === 0}
                            className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
                        >
                            <ChevronRight size={16} />
                        </Button>
                    </div>
                </div>
            </CardContent>

            {/* Диалог добавления/редактирования задачи */}
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="max-h-[80vh] overflow-y-auto bg-zinc-950 border border-zinc-800">
                    <DialogHeader>
                        <DialogTitle className="text-zinc-100">{editingTask ? 'Edit Video Task' : 'Add New Video Task'}</DialogTitle>
                        <DialogDescription className="text-zinc-400">
                            {editingTask ? 'Edit the details of the video task.' : 'Enter the details of the new video task.'}
                        </DialogDescription>
                    </DialogHeader>
                    <VideoTaskForm
                        task={editingTask}
                        onSave={handleSave}
                        onCancel={() => setIsDialogOpen(false)}
                    />
                </DialogContent>
            </Dialog>

            {/* Диалог подтверждения удаления */}
            <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                <DialogContent className="bg-zinc-950 border border-zinc-800 text-zinc-200 rounded-lg p-6">
                    <DialogHeader>
                        <DialogTitle className="text-zinc-100">Confirm Deletion</DialogTitle>
                        <DialogDescription className="text-zinc-400">
                            Are you sure you want to delete this video task? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="mt-6 flex justify-end space-x-4">
                        <Button
                            onClick={() => setIsDeleteDialogOpen(false)}
                            className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={confirmDelete}
                            className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
                        >
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default VideoTasksManagement;