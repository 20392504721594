import axios from 'axios';

export const dailyRewardsService = {
  getDailyRewardStatus: async () => {
    try {
      console.log('Starting daily reward status check');
      const token = localStorage.getItem('token');
      console.log('Auth token:', token ? 'Present' : 'Missing');

      if (!token) {
        console.error('No token available for daily rewards check');
        return null;
      }

      console.log('Making request to /api/daily-rewards/status');
      const response = await axios.get('/api/daily-rewards/status', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('Daily rewards response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Daily rewards error:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data
      });
      return null;
    }
  },

  claimDailyReward: async () => {
    try {
      console.log('Starting daily reward claim');
      const token = localStorage.getItem('token');
      console.log('Auth token:', token ? 'Present' : 'Missing');

      if (!token) {
        console.error('No token available for claiming reward');
        throw new Error('Authentication required');
      }

      console.log('Making request to /api/daily-rewards/claim');
      const response = await axios.post('/api/daily-rewards/claim', {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('Claim response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Claim error:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data
      });
      throw error;
    }
  },

  doubleDailyReward: async (amount) => {
    try {
      console.log('Starting daily reward doubling');
      const token = localStorage.getItem('token');
      console.log('Auth token:', token ? 'Present' : 'Missing');

      if (!token) {
        console.error('No token available for doubling reward');
        return { success: false, message: 'Authentication required' };
      }

      console.log('Making request to /api/daily-rewards/double with amount:', amount);
      const response = await axios.post('/api/daily-rewards/double', 
        { amount }, 
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          timeout: 30000 // Увеличиваем таймаут до 30 секунд
        }
      );

      console.log('Double reward response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Double reward error:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data
      });
      // Вместо выброса ошибки, возвращаем объект с информацией об ошибке
      return { 
        success: false, 
        message: error.response?.data?.message || error.message 
      };
    }
  }
};

export default dailyRewardsService;