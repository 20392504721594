import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

// Сервис для тостов
const toastService = (() => {
  let toastCounter = 0;
  const toasts = [];
  let setToastsState = null;

  // Регистрируем обновление состояния
  const registerSetToasts = (setter) => {
    setToastsState = setter;
  };

  // Обновление состояния
  const updateToasts = () => {
    if (setToastsState) {
      setToastsState([...toasts]);
    }
  };

  return {
    // Добавление тоста
    add: (message, options = {}) => {
      const id = `toast-${toastCounter++}`;
      const toast = {
        id,
        message,
        type: options.type || 'default',
        duration: options.duration || 4000,
        ...options
      };
      
      // Добавляем новый тост в начало массива, а не в конец
      toasts.unshift(toast);
      updateToasts();
      return id;
    },
    
    // Удаление тоста
    remove: (id) => {
      const index = toasts.findIndex(t => t.id === id);
      if (index !== -1) {
        toasts.splice(index, 1);
        updateToasts();
      }
    },
    
// Методы для разных типов
success: (message, options = {}) => {
  return toastService.add(message, { ...options, type: 'success' });
},

error: (message, options = {}) => {
  return toastService.add(message, { ...options, type: 'error' });
},

info: (message, options = {}) => {
  return toastService.add(message, { ...options, type: 'info' });
},

loading: (message, options = {}) => {
  return toastService.add(message, { ...options, type: 'loading' });
},
    
    // Работа с promise
    promise: (promise, messages, options = {}) => {
      const id = toastService.loading(messages.loading || 'Loading...', options);
      
      promise
        .then((data) => {
          toastService.remove(id);
          toastService.success(
            typeof messages.success === 'function' 
              ? messages.success(data) 
              : messages.success || 'Success',
            options
          );
          return data;
        })
        .catch((error) => {
          toastService.remove(id);
          toastService.error(
            typeof messages.error === 'function'
              ? messages.error(error)
              : messages.error || 'Error',
            options
          );
          return error;
        });
      
      return promise;
    },
    
    // Регистрация
    registerSetToasts,
    
    // Замена библиотеки react-hot-toast
    Toaster: () => <ToastContainer />
  };
})();

// Компонент отдельного тоста
const Toast = ({ id, message, type, duration, index = 0 }) => {
  const [progress, setProgress] = useState(100);
  const startTimeRef = useRef(Date.now());
  const timerRef = useRef(null);
  
  // Хук для обновления прогресса
  useEffect(() => {
    const tick = 10; // Обновление каждые 10мс для плавной анимации
    
    timerRef.current = setInterval(() => {
      const elapsed = Date.now() - startTimeRef.current;
      const newProgress = Math.max(0, 100 - (elapsed / duration) * 100);
      setProgress(newProgress);
      
      if (newProgress <= 0) {
        clearInterval(timerRef.current);
        toastService.remove(id);
      }
    }, tick);
    
    // Автоматическое удаление
    const removeTimer = setTimeout(() => {
      toastService.remove(id);
    }, duration);
    
    return () => {
      clearInterval(timerRef.current);
      clearTimeout(removeTimer);
    };
  }, [id, duration]);
  
// Цвет прогресс-бара
const getProgressColor = () => {
  switch (type) {
    case 'success': return 'rgba(46, 213, 115, 0.7)';
    case 'error': return 'rgba(239, 68, 68, 0.7)';
    case 'loading': return 'rgba(59, 130, 246, 0.7)';
    case 'info': return 'rgba(250, 204, 21, 0.7)'; // Желтый цвет для info
    default: return 'rgba(46, 213, 115, 0.7)';
  }
};
  
// Иконка
const getIcon = () => {
  switch (type) {
    case 'success':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill="#22c55e"/>
        </svg>
      );
    case 'error':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" fill="#ef4444"/>
        </svg>
      );
    case 'info':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" fill="#facc15"/>
        </svg>
      );
    case 'loading':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="loading-spinner">
          <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#3b82f6"/>
          <path d="M10 4V6" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round"/>
          <path d="M16 10L14 10" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round"/>
          <path d="M10 16V14" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round"/>
          <path d="M4 10L6 10" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round"/>
        </svg>
      );
    default:
      return null;
  }
};
  
  return (
    <div 
      className="custom-toast"
      style={{
        background: 'rgba(0, 0, 0, 0.8)',
        backdropFilter: 'blur(10px)',
        color: '#fff',
        borderRadius: '15px',
        padding: '12px 16px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        maxWidth: '420px',
        minWidth: '300px',
        zIndex: 9999,
        filter: `brightness(${1 - index * 0.1})`,
      }}
      onClick={() => toastService.remove(id)}
    >
      <div style={{ marginRight: '12px' }}>
        {getIcon()}
      </div>
      <div style={{ flex: 1 }}>
        {message}
      </div>
      <div 
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: '3px',
          width: `${progress}%`,
          background: `linear-gradient(to right, rgba(0, 0, 0, 0), ${getProgressColor()})`,
          transition: 'width 10ms linear',
        }}
      />
    </div>
  );
};

// Контейнер для тостов
const ToastContainer = () => {
  const [toasts, setToasts] = useState([]);
  
  // Регистрация обновления состояния
  useEffect(() => {
    toastService.registerSetToasts(setToasts);
    return () => toastService.registerSetToasts(null);
  }, []);
  
  if (toasts.length === 0) return null;
  
  return ReactDOM.createPortal(
    <div className="toast-container-root">
      {toasts.map((toast, index) => (
        <div
          key={toast.id}
          className={`toast-wrapper toast-item-${index}`}
          style={{
            position: 'absolute',
            top: `${index * 8}px`,
            left: '50%',
            transform: `translateX(-50%) scale(${1 - index * 0.08})`,
            transformOrigin: 'top center',
            zIndex: 9999 - index,
            width: 'auto',
            minWidth: '300px',
          }}
        >
          <Toast {...toast} index={index} />
        </div>
      ))}
      <style jsx global>{`
        .toast-container-root {
          position: fixed;
          top: 20px;
          left: 0;
          right: 0;
          z-index: 9999;
          display: flex;
          flex-direction: column;
          align-items: center;
          pointer-events: none;
        }
        
        .toast-wrapper {
          pointer-events: auto;
        }
        
        .toast-item-0 {
          animation: drop-in 0.5s cubic-bezier(0.21, 1.02, 0.73, 1) forwards;
        }
        
        .custom-toast {
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
        }
        
        @keyframes drop-in {
          0% {
            opacity: 0;
            transform: translateX(-50%) translateY(-50px) scale(1);
          }
          100% {
            opacity: 1;
            transform: translateX(-50%) translateY(0) scale(1);
          }
        }
        
        .loading-spinner {
          animation: spin 1.5s linear infinite;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>,
    document.body
  );
};

// Экспортируем сервис как замену для react-hot-toast
export default toastService;