import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BarChart2, Send, Box, Grid, Globe, CheckSquare, Star, HelpCircle, UserCircle, Settings, ChevronDown, Ticket, Award, Menu, X, ImageIcon } from 'lucide-react';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const location = useLocation();
  const [expandedMenuItem, setExpandedMenuItem] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const currentUser = useSelector((state) => state.auth.user);

  const menuItems = [
    { name: 'Dashboard', icon: <BarChart2 size={18} />, path: '/admin' },
    { name: 'Brands', icon: <Box size={18} />, path: '/admin/brands' },
    { name: 'Categories', icon: <Grid size={18} />, path: '/admin/categories' },
    { name: 'Countries', icon: <Globe size={18} />, path: '/admin/countries' },
    {
      name: 'Tasks',
      icon: <CheckSquare size={18} />,
      path: '/admin/tasks',
      subItems: [
        { name: 'Video', path: '/admin/tasks/video' },
        { name: 'Social', path: '/admin/tasks/social' },
        { name: 'Special', path: '/admin/tasks/special' },
        { name: 'Referrals', path: '/admin/tasks/referrals' },
      ],
    },
    { name: 'Stories', icon: <ImageIcon size={18} />, path: '/admin/stories' },
    { name: 'Ranks', icon: <Star size={18} />, path: '/admin/ranks' },
    { name: 'Messages', icon: <Send size={18} />, path: '/admin/messages' },
    { name: 'FAQ', icon: <HelpCircle size={18} />, path: '/admin/faq' },
    { name: 'Users', icon: <UserCircle size={18} />, path: '/admin/accounts/users' },
    { name: 'Tickets', icon: <Ticket size={18} />, path: '/admin/tickets' },
    { name: 'Draws', icon: <Award size={18} />, path: '/admin/draws' },
    { name: 'Settings', icon: <Settings size={18} />, path: '/admin/settings' },
  ];

  const toggleSubMenu = (menuItemName) => {
    setExpandedMenuItem(expandedMenuItem === menuItemName ? null : menuItemName);
  };

  const isMenuItemActive = (path) => {
    // Специальная проверка для Dashboard, чтобы она активировалась только на точном совпадении
    if (path === '/admin') {
      return location.pathname === '/admin';
    }
    // Для остальных пунктов меню используем стандартную проверку
    return location.pathname === path || location.pathname.startsWith(path + '/');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      {/* Mobile Menu Button */}
      <button
        onClick={toggleMobileMenu}
        className="lg:hidden fixed top-4 left-4 z-50 bg-zinc-900 p-2 rounded-md text-white"
      >
        {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {/* Sidebar */}
      <div className={`fixed inset-y-0 left-0 z-40 w-64 bg-zinc-950 text-white transform transition-transform duration-300 ease-in-out ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}>
        <div className="h-full flex flex-col p-4">
          <Link to="/admin" className="text-xl font-bold mb-6 flex items-center px-2 py-3">
            battle brands.
          </Link>
          
          <nav className="flex-1 overflow-y-auto space-y-1">
            {menuItems.map((item, index) => (
              <div key={index} className="mb-0.5">
                <Link
                  to={item.path}
                  className={`flex items-center justify-between px-2 py-2 rounded-md hover:bg-zinc-800 ${
                    isMenuItemActive(item.path) ? 'bg-zinc-800 text-white' : 'text-zinc-300'
                  }`}
                  onClick={(e) => {
                    if (item.subItems) {
                      e.preventDefault();
                      toggleSubMenu(item.name);
                    } else if (isMobileMenuOpen) {
                      setIsMobileMenuOpen(false);
                    }
                  }}
                >
                  <div className="flex items-center space-x-3">
                    {item.icon}
                    <span className="text-sm">{item.name}</span>
                  </div>
                  {item.subItems && (
                    <ChevronDown
                      size={16}
                      className={`transition-transform duration-200 ${
                        expandedMenuItem === item.name ? 'transform rotate-180' : ''
                      }`}
                    />
                  )}
                </Link>
                {item.subItems && expandedMenuItem === item.name && (
                  <div className="mt-1 ml-6 space-y-1">
                    {item.subItems.map((subItem, subIndex) => (
                      <Link
                        key={subIndex}
                        to={subItem.path}
                        className={`block px-2 py-1.5 text-sm rounded-md ${
                          isMenuItemActive(subItem.path)
                            ? 'bg-zinc-800 text-white'
                            : 'text-zinc-400 hover:text-white hover:bg-zinc-800'
                        }`}
                        onClick={() => isMobileMenuOpen && setIsMobileMenuOpen(false)}
                      >
                        {subItem.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
          
          <div className="mt-auto pt-4 border-t border-zinc-800">
            <Link
              to="/admin/profile"
              className="flex items-center p-3 rounded-md bg-zinc-800 hover:bg-zinc-700 transition-colors"
            >
              {currentUser && currentUser.avatar ? (
                <img src={currentUser.avatar} alt="Admin" className="w-6 h-6 rounded-full mr-3" />
              ) : (
                <UserCircle size={20} className="mr-3" />
              )}
              <span className="text-sm font-medium">{currentUser ? currentUser.name : 'Admin'}</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;