const telegramService = {
  initDataUnsafe: {},

  init() {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        console.log('Telegram WebApp found, initializing...');
        this.initDataUnsafe = window.Telegram.WebApp.initDataUnsafe || {};
        
        // Сохраняем данные пользователя в localStorage для отказоустойчивости
        if (this.initDataUnsafe.user) {
          localStorage.setItem('tg_user', JSON.stringify(this.initDataUnsafe.user));
          console.log('User data saved to localStorage');
        }
        
        // Сохраняем параметр запуска
        if (this.initDataUnsafe.start_param) {
          localStorage.setItem('start_param', this.initDataUnsafe.start_param);
          console.log('Start param saved:', this.initDataUnsafe.start_param);
        }
        
        window.Telegram.WebApp.ready();
        return true;
      } else {
        console.warn('Telegram WebApp not found or not properly initialized');
        return false;
      }
    } catch (error) {
      console.error('Error initializing Telegram WebApp:', error);
      return false;
    }
  },

  getInitData() {
    try {
      if (window.Telegram?.WebApp?.initData) {
        return window.Telegram.WebApp.initData;
      }
      
      // Проверяем URL параметры
      const urlParams = new URLSearchParams(window.location.search);
      const tgData = urlParams.get('tgWebAppData') || '';
      
      if (tgData) {
        return tgData;
      }
      
      // Если нет данных в URL, проверяем localStorage
      const savedInitData = localStorage.getItem('tg_initData');
      return savedInitData || '';
    } catch (error) {
      console.error('Error getting init data:', error);
      return '';
    }
  },

  getUserInfo() {
    try {
      // Сначала пытаемся получить из Telegram
      if (this.initDataUnsafe.user) {
        return this.initDataUnsafe.user;
      }
      
      // Если не получилось, пробуем из localStorage
      const savedUser = localStorage.getItem('tg_user');
      if (savedUser) {
        try {
          return JSON.parse(savedUser);
        } catch (e) {
          console.warn('Failed to parse saved user data');
        }
      }
      
      // Используем резервный вариант - вытаскиваем из URL
      const urlParams = new URLSearchParams(window.location.search);
      const userData = urlParams.get('user');
      if (userData) {
        try {
          return JSON.parse(decodeURIComponent(userData));
        } catch (e) {
          console.warn('Failed to parse user data from URL');
        }
      }
      
      return null;
    } catch (error) {
      console.error('Error getting user info:', error);
      return null;
    }
  },

  getStartParam() {
    try {
      // Сначала пытаемся получить из Telegram
      if (window.Telegram?.WebApp?.initDataUnsafe?.start_param) {
        const param = window.Telegram.WebApp.initDataUnsafe.start_param;
        return param !== undefined && param !== null ? param : '';
      }
      
      // Проверяем URL
      const urlParams = new URLSearchParams(window.location.search);
      const startParam = urlParams.get('start');
      if (startParam) {
        return startParam;
      }
      
      // Проверяем localStorage
      const savedStartParam = localStorage.getItem('start_param');
      if (savedStartParam) {
        return savedStartParam;
      }
      
      // Пытаемся получить из URL-хэша (если используется для referralCode)
      const referralCode = localStorage.getItem('referralCode');
      if (referralCode) {
        console.log('Использован сохраненный referralCode:', referralCode);
        return referralCode;
      }
      
      return '';
    } catch (error) {
      console.error('Error getting start param:', error);
      return '';
    }
  },

  close() {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.close();
    }
  }
};

export default telegramService;