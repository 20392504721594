import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";
import { Label } from "../ui/label";
import { Pencil, Trash2, Upload, ChevronLeft, ChevronRight, X, Plus } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useDropzone } from 'react-dropzone';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import toast from '../CustomToast';

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            console.log('File dropped:', file.name, file.type, file.size);
            
            // Нормализуем MIME тип если он не определен
            if (!file.type || file.type === 'application/octet-stream') {
                const ext = file.name.split('.').pop().toLowerCase();
                if (ext === 'png') {
                    file.type = 'image/png';
                } else if (ext === 'webp') {
                    file.type = 'image/webp';
                } else {
                    file.type = 'image/jpeg';
                }
                console.log('Normalized MIME type to:', file.type);
            }
            
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/webp': ['.webp']
        },
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
                ${isDragActive ? 'border-zinc-500 bg-zinc-900' : 'border-zinc-700 hover:border-zinc-600'}
            `}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-zinc-500" size={48} />
            <p className="text-sm text-zinc-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
            <p className="text-xs text-zinc-500 mt-1">
                Supported formats: JPG, PNG, WebP
            </p>
        </div>
    );
};

const TicketForm = ({ ticket, onSave, onCancel }) => {
    const [formData, setFormData] = useState(ticket || {
        name: '',
        description: '',
        prizes: [],
        sponsor: '',
        saleStartDate: '',
        saleEndDate: '',
        drawDate: '',
        status: 'upcoming',
        price: 0,
        link: '',
        totalTickets: -1
    });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(ticket?.backgroundImage || '');

    const handlePrizeChange = (index, field, value) => {
        const newPrizes = [...formData.prizes];
        newPrizes[index] = { ...newPrizes[index], [field]: value };
        setFormData(prev => ({ ...prev, prizes: newPrizes }));
    };

    const addPrize = () => {
        setFormData(prev => ({
            ...prev,
            prizes: [...prev.prizes, { 
                type: 'money', 
                value: '', 
                quantity: 1,
                currency: 'USDT' // Устанавливаем валюту по умолчанию
            }]
        }));
    };

    const removePrize = (index) => {
        setFormData(prev => ({
            ...prev,
            prizes: prev.prizes.filter((_, i) => i !== index)
        }));
    };

    useEffect(() => {
        if (ticket) {
            setFormData({
                ...ticket,
                saleStartDate: ticket.saleStartDate ? new Date(ticket.saleStartDate).toISOString().slice(0, 16) : '',
                saleEndDate: ticket.saleEndDate ? new Date(ticket.saleEndDate).toISOString().slice(0, 16) : '',
                drawDate: ticket.drawDate ? new Date(ticket.drawDate).toISOString().slice(0, 16) : '',
            });
        }
    }, [ticket]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: ['price', 'winningTicketsCount', 'prizeMoney', 'prizePerTicketMoney', 'totalTickets'].includes(name)
                ? Number(value)
                : value
        }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData, imageFile);
    };

    const inputClasses = "bg-zinc-950 text-zinc-300 border-zinc-800 focus:border-zinc-700 focus:ring-zinc-700 placeholder-zinc-500";
    const formGroupClasses = "p-4 bg-zinc-900 rounded-lg border border-zinc-800";
    const labelClasses = "block mb-2 font-medium text-zinc-300";

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className={formGroupClasses}>
                <h3 className="text-lg font-medium text-zinc-200 mb-4">Basic Information</h3>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="name" className={labelClasses}>Name</Label>
                        <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="description" className={labelClasses}>Description</Label>
                        <Input id="description" name="description" value={formData.description} onChange={handleChange} required className={inputClasses} />
                    </div>
                </div>
            </div>

            <div className={formGroupClasses}>
                <h3 className="text-lg font-medium text-zinc-200 mb-4">Prizes</h3>
                {formData.prizes.map((prize, index) => (
                    <div key={index} className="grid grid-cols-12 gap-2 mt-2 items-center bg-zinc-950 p-3 rounded-lg border border-zinc-800 mb-2">
                        <div className="col-span-3">
                            <Label className="text-xs text-zinc-400 mb-1">Prize Type</Label>
                            <Select
                                value={prize.type}
                                onValueChange={(value) => handlePrizeChange(index, 'type', value)}
                            >
                                <SelectTrigger className={inputClasses}>
                                    <SelectValue placeholder="Prize Type" />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                                    <SelectItem value="money">Money</SelectItem>
                                    <SelectItem value="item">Item</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        
                        <div className="col-span-3">
                            <Label className="text-xs text-zinc-400 mb-1">{prize.type === 'money' ? 'Amount' : 'Item name'}</Label>
                            <Input
                                placeholder={prize.type === 'money' ? 'Amount' : 'Item name'}
                                value={prize.value}
                                onChange={(e) => handlePrizeChange(index, 'value', e.target.value)}
                                className={inputClasses}
                            />
                        </div>
                        
                        {prize.type === 'money' && (
                            <div className="col-span-2">
                                <Label className="text-xs text-zinc-400 mb-1">Currency</Label>
                                <Select
                                    value={prize.currency || 'USDT'}
                                    onValueChange={(value) => handlePrizeChange(index, 'currency', value)}
                                >
                                    <SelectTrigger className={inputClasses}>
                                        <SelectValue placeholder="Currency" />
                                    </SelectTrigger>
                                    <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                                        <SelectItem value="USDT">USDT</SelectItem>
                                        <SelectItem value="TON">TON</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        )}
                        
                        <div className={prize.type === 'money' ? "col-span-2" : "col-span-4"}>
                            <Label className="text-xs text-zinc-400 mb-1">Quantity</Label>
                            <Input
                                type="number"
                                placeholder="Quantity"
                                value={prize.quantity}
                                onChange={(e) => handlePrizeChange(index, 'quantity', parseInt(e.target.value))}
                                className={inputClasses}
                            />
                        </div>
                        
                        <div className="col-span-2 flex justify-end items-end">
                            <Button 
                                type="button" 
                                onClick={() => removePrize(index)} 
                                className="bg-red-500 hover:bg-red-600 text-white rounded-full w-8 h-8 flex items-center justify-center"
                            >
                                <X size={16} />
                            </Button>
                        </div>
                    </div>
                ))}
                <Button 
                    type="button" 
                    onClick={addPrize} 
                    className="mt-2 bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-2 px-6"
                >
                    <Plus className="mr-2 h-4 w-4" /> Add Prize
                </Button>
            </div>

            <div className={formGroupClasses}>
                <h3 className="text-lg font-medium text-zinc-200 mb-4">Ticket Details</h3>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="price" className={labelClasses}>Price (in coins)</Label>
                        <Input id="price" name="price" type="number" value={formData.price} onChange={handleChange} required className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="link" className={labelClasses}>Link</Label>
                        <Input id="link" name="link" type="url" value={formData.link} onChange={handleChange} className={inputClasses} placeholder="https://example.com" />
                    </div>
                    <div>
                        <Label htmlFor="sponsor" className={labelClasses}>Sponsor</Label>
                        <Input id="sponsor" name="sponsor" value={formData.sponsor} onChange={handleChange} className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="totalTickets" className={labelClasses}>Total Tickets (-1 for unlimited)</Label>
                        <Input
                            id="totalTickets"
                            name="totalTickets"
                            type="number"
                            value={formData.totalTickets}
                            onChange={handleChange}
                            className={inputClasses}
                        />
                    </div>
                </div>
            </div>

            <div className={formGroupClasses}>
                <h3 className="text-lg font-medium text-zinc-200 mb-4">Dates & Status</h3>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="saleStartDate" className={labelClasses}>Sale Start Date</Label>
                        <Input id="saleStartDate" name="saleStartDate" type="datetime-local" value={formData.saleStartDate} onChange={handleChange} required className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="saleEndDate" className={labelClasses}>Sale End Date</Label>
                        <Input id="saleEndDate" name="saleEndDate" type="datetime-local" value={formData.saleEndDate} onChange={handleChange} required className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="drawDate" className={labelClasses}>Draw Date</Label>
                        <Input id="drawDate" name="drawDate" type="datetime-local" value={formData.drawDate} onChange={handleChange} required className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="status" className={labelClasses}>Status</Label>
                        <Select name="status" value={formData.status} onValueChange={(value) => setFormData(prev => ({ ...prev, status: value }))}>
                            <SelectTrigger className={inputClasses}>
                                <SelectValue placeholder="Select status" />
                            </SelectTrigger>
                            <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                                <SelectItem value="upcoming">Upcoming</SelectItem>
                                <SelectItem value="active">Active</SelectItem>
                                <SelectItem value="completed">Completed</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
            </div>

            <div className={formGroupClasses}>
                <Label className={labelClasses}>Background Image</Label>
                <div className="flex flex-col space-y-2">
                    <ImageUpload onImageUpload={handleImageUpload} />
                </div>
                {imagePreview && (
                    <div className="relative mt-2">
                        <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => {
                                setImagePreview('');
                                setFormData(prev => ({ ...prev, backgroundImage: '' }));
                            }}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                        >
                            <X size={16} />
                        </Button>
                    </div>
                )}
            </div>

            <div className="flex justify-end space-x-2">
                <Button 
                    type="button" 
                    onClick={onCancel} 
                    className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                >
                    Cancel
                </Button>
                <Button 
                    type="submit" 
                    className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-2 px-6"
                >
                    Save Ticket
                </Button>
            </div>
        </form>
    );
};

const TicketsManagement = () => {
    const [tickets, setTickets] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingTicket, setEditingTicket] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [ticketsPerPage, setTicketsPerPage] = useState(10);
    const [totalTickets, setTotalTickets] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletingTicketId, setDeletingTicketId] = useState(null);
    const [error, setError] = useState(null);

    const fetchTickets = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get(`/api/tickets?page=${currentPage}&limit=${ticketsPerPage}`);
            console.log('Fetched tickets:', response.data);
            if (Array.isArray(response.data)) {
                setTickets(response.data);
                setTotalTickets(response.data.length);
            } else if (response.data && Array.isArray(response.data.tickets)) {
                setTickets(response.data.tickets);
                setTotalTickets(response.data.totalTickets || 0);
            } else {
                console.error('Invalid response format:', response.data);
                setError('Received invalid data format from server');
                setTickets([]);
                setTotalTickets(0);
            }
        } catch (error) {
            console.error('Error fetching tickets:', error);
            setError('Failed to load tickets. Please try again.');
            setTickets([]);
            setTotalTickets(0);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, ticketsPerPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleTicketsPerPageChange = (value) => {
        setTicketsPerPage(Number(value));
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchTickets();
    }, [fetchTickets]);

    const handleSave = async (ticketData, imageFile) => {
        try {
            const formData = new FormData();
            Object.keys(ticketData).forEach(key => {
                if (key !== 'backgroundImage') {
                    if (key === 'prizes') {
                        formData.append(key, JSON.stringify(ticketData[key]));
                    } else if (key === 'saleStartDate' || key === 'saleEndDate' || key === 'drawDate') {
                        formData.append(key, new Date(ticketData[key]).toISOString());
                    } else if (key === 'price' || key === 'totalTickets') {
                        formData.append(key, Number(ticketData[key]));
                    } else {
                        formData.append(key, ticketData[key]);
                    }
                }
            });

            if (imageFile) {
                // Проверяем тип файла
                const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
                if (!validTypes.includes(imageFile.type)) {
                    // Если тип файла не поддерживается, конвертируем его в jpg
                    const canvas = document.createElement('canvas');
                    const img = new Image();
                    
                    // Создаем промис для загрузки изображения
                    await new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                        img.src = URL.createObjectURL(imageFile);
                    });
                    
                    // Устанавливаем размеры канваса
                    canvas.width = img.width;
                    canvas.height = img.height;
                    
                    // Рисуем изображение на канвасе
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    
                    // Конвертируем в blob формата jpeg
                    canvas.toBlob(async (blob) => {
                        try {
                            if (blob) {
                                // Создаем новый файл с правильным типом и расширением
                                const convertedFile = new File(
                                    [blob], 
                                    `ticket-image-${Date.now()}.jpg`, 
                                    { type: 'image/jpeg' }
                                );
                                
                                formData.append('backgroundImage', convertedFile);
                                
                                // Отправляем форму с конвертированным изображением
                                await submitForm(formData);
                            } else {
                                toast.error('Failed to convert image');
                            }
                        } catch (error) {
                            console.error('Error in image conversion:', error);
                            toast.error(`Image conversion error: ${error.message}`);
                        }
                    }, 'image/jpeg', 0.9);
                } else {
                    // Если тип файла поддерживается, просто переименовываем его
                    const fileExtension = imageFile.type === 'image/png' ? '.png' : 
                                        imageFile.type === 'image/webp' ? '.webp' : '.jpg';
                                        
                    const renamedFile = new File(
                        [imageFile], 
                        `ticket-image-${Date.now()}${fileExtension}`, 
                        { type: imageFile.type }
                    );
                    
                    formData.append('backgroundImage', renamedFile);
                    
                    // Отправляем форму с переименованным изображением
                    await submitForm(formData);
                }
            } else {
                // Если нет изображения, отправляем форму без него
                await submitForm(formData);
            }
        } catch (error) {
            console.error('Error saving ticket:', error);
            setError(`Error saving ticket: ${error.response?.data?.message || error.message}`);
            toast.error(`Error saving ticket: ${error.response?.data?.message || error.message}`);
        }
        
        // Функция для отправки формы
        async function submitForm(formData) {
            try {
                console.log('Sending data:', Object.fromEntries(formData));
                
                let response;
                if (editingTicket) {
                    response = await axios.put(`/api/tickets/${editingTicket._id}`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                } else {
                    response = await axios.post('/api/tickets', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                }
                
                console.log('Ticket saved:', response.data);
                
                // Проверяем доступность изображения
                if (response.data && response.data.backgroundImage) {
                    console.log('Image path:', response.data.backgroundImage);
                    
                    // Даем время на обработку изображения
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
                
                await fetchTickets();
                setIsDialogOpen(false);
                setEditingTicket(null);
                toast.success('Ticket saved successfully!');
            } catch (error) {
                console.error('Error in submitForm:', error);
                throw error;
            }
        }
    };

    const handleDelete = (ticketId) => {
        setDeletingTicketId(ticketId);
        setIsDeleteDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (deletingTicketId) {
            try {
                await axios.delete(`/api/tickets/${deletingTicketId}`);
                fetchTickets();
                toast.success('Ticket deleted successfully!');
            } catch (error) {
                console.error('Error deleting ticket:', error);
                setError('Failed to delete ticket. Please try again.');
                toast.error('Failed to delete ticket');
            }
            setIsDeleteDialogOpen(false);
            setDeletingTicketId(null);
        }
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold text-zinc-200">Tickets</h2>
                    <Button 
                        onClick={() => setIsDialogOpen(true)} 
                        className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                    >
                        Add New Ticket
                    </Button>
                </div>

                {/* Сообщение об ошибке */}
                {error && (
                    <div className="mb-4 p-4 bg-red-500 bg-opacity-10 border border-red-500 text-red-400 rounded-md">
                        {error}
                    </div>
                )}

                {/* Список билетов */}
                <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
                    {isLoading ? (
                        <div className="flex items-center justify-center py-8">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-zinc-500"></div>
                            <span className="ml-2 text-zinc-400">Loading tickets...</span>
                        </div>
                    ) : tickets.length > 0 ? (
                        <div className="grid grid-cols-2 gap-4">
                            {tickets.map((ticket) => (
                                <div 
                                    key={ticket._id} 
                                    className="relative overflow-hidden rounded-lg shadow-lg bg-zinc-900 border border-zinc-800"
                                >
                                    <div className="relative z-10 p-6 bg-zinc-900 bg-opacity-80 h-full flex flex-col justify-between">
                                        <div>
                                            <h3 className="text-xl font-bold text-zinc-300">{ticket.name}</h3>
                                            <p className="text-sm text-zinc-400 mt-1">{ticket.description}</p>
                                            <p className="text-sm text-[#e0ff89] mt-2">Sponsor: {ticket.sponsor}</p>
                                            <p className="text-sm text-zinc-400 mt-2">Clicks: {ticket.clickCount || 0}</p>
                                            
                                            <div className="mt-4 bg-zinc-800 p-3 rounded-lg">
                                                <h4 className="text-sm font-bold text-[#e0ff89] mb-2">Prizes</h4>
                                                <div className="space-y-2">
                                                    {ticket.prizes.map((prize, index) => (
                                                        <div key={index} className="flex justify-between items-center">
                                                            <div className="flex items-center">
                                                                <span className="inline-block w-5 h-5 bg-zinc-700 rounded-full text-[#e0ff89] text-xs flex items-center justify-center mr-2">{index + 1}</span>
                                                                <span className="text-sm text-zinc-300">
                                                                    {prize.type === 'money' 
                                                                        ? `${prize.value} ${prize.currency || 'USDT'}`
                                                                        : prize.value
                                                                    }
                                                                </span>
                                                            </div>
                                                            <span className="text-xs text-zinc-400 bg-zinc-900 px-2 py-1 rounded">
                                                                Quantity: {prize.quantity}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            
                                            <div className="mt-3">
                                                <p className="text-sm text-zinc-300">Total Winning Tickets: {ticket.prizes.reduce((sum, prize) => sum + prize.quantity, 0)}</p>
                                            </div>
                                            
                                            <div className="mt-3 bg-zinc-800 p-3 rounded-lg">
                                                <h4 className="text-sm font-bold text-[#e0ff89] mb-1">Schedule</h4>
                                                <p className="text-xs text-zinc-300">Sale Start: {new Date(ticket.saleStartDate).toLocaleString()}</p>
                                                <p className="text-xs text-zinc-300">Sale End: {new Date(ticket.saleEndDate).toLocaleString()}</p>
                                                <p className="text-xs text-zinc-300">Draw Date: {new Date(ticket.drawDate).toLocaleString()}</p>
                                            </div>
                                            
                                            <div className="mt-3">
                                                <span className={`inline-block px-3 py-1 rounded-full text-xs ${
                                                    ticket.status === 'active' ? 'bg-[#e0ff89] bg-opacity-20 text-[#e0ff89]' : 
                                                    ticket.status === 'completed' ? 'bg-red-500 bg-opacity-20 text-red-400' : 
                                                    'bg-yellow-500 bg-opacity-20 text-yellow-400'
                                                }`}>
                                                    {ticket.status.charAt(0).toUpperCase() + ticket.status.slice(1)}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div className="mt-4 flex justify-end space-x-2">
                                            <Button 
                                                onClick={() => { setEditingTicket(ticket); setIsDialogOpen(true); }} 
                                                className="p-1 h-8 w-8 bg-zinc-800 hover:bg-zinc-700 text-white rounded-full"
                                            >
                                                <Pencil size={16} />
                                            </Button>
                                            <Button 
                                                onClick={() => handleDelete(ticket._id)} 
                                                className="p-1 h-8 w-8 bg-zinc-800 hover:bg-zinc-700 text-white rounded-full"
                                            >
                                                <Trash2 size={16} />
                                            </Button>
                                        </div>
                                    </div>
                                    
                                    {ticket.backgroundImage && (
                                        <img
                                            src={ticket.backgroundImage}
                                            alt={ticket.name}
                                            className="absolute inset-0 w-full h-full object-cover opacity-20"
                                        />
                                    )}
                                </div>
                           ))}
                       </div>
                   ) : (
                       <div className="text-zinc-400 text-center py-8">
                           No tickets available. Add your first ticket using the button above.
                       </div>
                   )}
               </div>

               {/* Пагинация и статистика */}
               <div className="mt-4 flex justify-between items-center">
                   <div className="flex items-center space-x-2">
                       <span className="text-zinc-400 text-sm">Tickets per page:</span>
                       <Select value={ticketsPerPage.toString()} onValueChange={handleTicketsPerPageChange}>
                           <SelectTrigger className="w-[70px] h-8 bg-zinc-950 text-zinc-300 border-zinc-800">
                               <SelectValue />
                           </SelectTrigger>
                           <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                               {[10, 20, 50, 100].map(value => (
                                   <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                               ))}
                           </SelectContent>
                       </Select>
                       <span className="text-zinc-400 text-sm">Total Tickets: {totalTickets}</span>
                   </div>
                   
                   <div className="flex items-center space-x-2">
                       <Button
                           onClick={() => handlePageChange(currentPage - 1)}
                           disabled={currentPage === 1}
                           className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
                       >
                           <ChevronLeft size={16} />
                       </Button>
                       <span className="text-zinc-400 text-sm">Page {currentPage} of {Math.max(1, Math.ceil(totalTickets / ticketsPerPage))}</span>
                       <Button
                           onClick={() => handlePageChange(currentPage + 1)}
                           disabled={currentPage === Math.ceil(totalTickets / ticketsPerPage) || totalTickets === 0}
                           className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
                       >
                           <ChevronRight size={16} />
                       </Button>
                   </div>
               </div>
           </CardContent>
           
           {/* Диалог добавления/редактирования */}
           <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
               <DialogContent className="max-h-[80vh] overflow-y-auto bg-zinc-950 border border-zinc-800">
                   <DialogHeader>
                       <div className="flex items-center gap-3 mb-2">
                           <div className="bg-[#e0ff89] p-2 rounded-full">
                               {editingTicket ? <Pencil className="w-5 h-5 text-black" /> : <Plus className="w-5 h-5 text-black" />}
                           </div>
                           <DialogTitle className="text-lg font-semibold text-zinc-100">
                               {editingTicket ? 'Edit Ticket' : 'Add New Ticket'}
                           </DialogTitle>
                       </div>
                       <DialogDescription className="text-zinc-400 pl-10">
                           {editingTicket 
                               ? 'Update the details of your existing ticket. All fields marked with * are required.' 
                               : 'Create a new ticket by filling out the form below. All fields marked with * are required.'}
                       </DialogDescription>
                   </DialogHeader>
                   <TicketForm
                       ticket={editingTicket}
                       onSave={handleSave}
                       onCancel={() => setIsDialogOpen(false)}
                   />
               </DialogContent>
           </Dialog>
           
           {/* Диалог подтверждения удаления */}
           <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
               <DialogContent className="bg-zinc-950 border border-zinc-800 text-zinc-200 rounded-lg p-6">
                   <DialogHeader>
                       <DialogTitle className="text-zinc-100">Confirm Deletion</DialogTitle>
                       <DialogDescription className="text-zinc-400">
                           Are you sure you want to delete this ticket? This action cannot be undone.
                       </DialogDescription>
                   </DialogHeader>
                   <DialogFooter className="mt-6 flex justify-end space-x-4">
                       <Button
                           onClick={() => setIsDeleteDialogOpen(false)}
                           className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                       >
                           Cancel
                       </Button>
                       <Button
                           onClick={confirmDelete}
                           className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
                       >
                           Delete
                       </Button>
                   </DialogFooter>
               </DialogContent>
           </Dialog>
       </Card>
   );
};

export default TicketsManagement;