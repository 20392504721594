import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationData from '../animations/loading.json';

const LoadingScreen = () => {
  const [fadeOut, setFadeOut] = useState(false);
  
  useEffect(() => {
    // Предзагрузка важных изображений
    const preloadImages = [
      '/images/general/logo-loading.svg',
      '/images/general/vs.svg',
      '/images/general/coin.svg'
    ];
    
    Promise.all(preloadImages.map(src => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    })).catch(err => console.error('Error preloading images:', err));
    
    // Добавляем класс overflow-hidden к body
    document.body.classList.add('overflow-hidden');
    
    // Убираем класс при размонтировании
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#000000',
      zIndex: 9999,
      pointerEvents: 'all', // Changed from 'none' to 'all' to block interactions
      opacity: fadeOut ? 0 : 1,
      transition: 'opacity 0.5s ease-in-out',
      userSelect: 'none' // Prevent text selection
    }}>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <img 
          src="/images/general/logo-loading.svg" 
          alt="Logo" 
          style={{ 
            width: '100px', 
            height: '100px', 
            objectFit: 'contain',
            animation: 'pulse 2s infinite ease-in-out'
          }} 
        />
      </div>
      <div style={{ 
        marginBottom: '0px',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden'
      }}>
        <Lottie 
          options={defaultOptions} 
          height={300} 
          width={'100%'} 
          style={{
            minWidth: '100vw',
            objectFit: 'cover'
          }}
        />
      </div>
      
      <style jsx>{`
        @keyframes pulse {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.05); }
        }
      `}</style>
    </div>
  );
};

export default LoadingScreen;