import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/globals.css';
//import './services/loggerService';

// Предварительная загрузка ресурсов
const preloadResources = () => {
  // Предзагрузка изображения логотипа
  const logoLink = document.createElement('link');
  logoLink.rel = 'preload';
  logoLink.as = 'image';
  logoLink.href = '/images/general/logo-loading.svg';
  document.head.appendChild(logoLink);
};

// Вызываем предзагрузку
preloadResources();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
