import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import toast from './CustomToast';
import DrawResults from './DrawResults';
import OptimizedImage from './ui/OptimizedImage';
import { useTonWallet } from '@tonconnect/ui-react';

const TicketDisplay = ({ user, tickets, onPurchase }) => {
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [showResults, setShowResults] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
        // Получаем информацию о кошельке
    const wallet = useTonWallet();

    useEffect(() => {
        const timer = setInterval(() => {
            if (tickets && tickets.length > 0) {
                const now = new Date();
                const endDate = new Date(tickets[0].saleEndDate);
                const difference = endDate - now;

                if (difference > 0) {
                    setTimeLeft({
                        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                        minutes: Math.floor((difference / 1000 / 60) % 60),
                        seconds: Math.floor((difference / 1000) % 60)
                    });
                } else {
                    setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                    clearInterval(timer);
                }
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [tickets]);

    if (!tickets || tickets.length === 0) {
        return <div className="text-center text-white">No tickets available at the moment.</div>;
    }

    const ticket = tickets[0];

    const getUserTicketCount = () => {
        if (!user || !user.tickets || !Array.isArray(user.tickets) || !ticket) {
            return 0;
        }
        const userTicket = user.tickets.find(t => t.ticketId && t.ticketId.toString() === ticket._id.toString());
        return userTicket ? userTicket.quantity : 0;
    };

    // Проверка возможности покупки билета (только проверка монет)
    const canPurchaseTicket = () => {
        // Проверка наличия достаточного количества монет
        if (user.coins < ticket.price) {
            return { canBuy: false, message: 'Insufficient coins' };
        }
        
        // Проверка доступности билетов
        if (ticket.totalTickets !== -1 && ticket.soldTickets >= ticket.totalTickets) {
            return { canBuy: false, message: 'All tickets sold out' };
        }
        
        return { canBuy: true, message: 'Buy Ticket' };
    };

    // Получаем результат проверки
    const purchaseStatus = canPurchaseTicket();

    // Обработчик нажатия на кнопку покупки
const handlePurchase = async (e) => {
        e.stopPropagation();
        
        // Если нельзя купить билет, показываем сообщение
        if (!purchaseStatus.canBuy) {
            toast.error(purchaseStatus.message);
            return;
        }
        
        // Проверяем, подключен ли TON-кошелек
        if (!wallet) {
            toast.error("Please connect TON wallet first to purchase tickets", {
                duration: 3000,
                position: 'top-center',
            });
            return;
        }
        
        try {
            // Вызываем onPurchase без показа toast в этом компоненте
            await onPurchase(ticket._id);
        } catch (error) {
            console.error('Error purchasing ticket:', error);
            toast.error('Failed to purchase ticket');
        }
    };

    // Подготовка изображения с обработкой ошибок
    const getBackgroundImage = () => {
        // Если есть фоновое изображение у билета, используем его
        if (ticket.backgroundImage) {
            return ticket.backgroundImage;
        }
        // Иначе используем дефолтное изображение
        return '/images/general/default-ticket-bg.jpg';
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="space-y-6">
                <Card
                    className="w-full rounded-[20px] overflow-hidden relative cursor-pointer"
                    style={{
                        boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.5), 0 10px 10px -5px rgba(0, 0, 0, 0.04)'
                    }}
                    onClick={async () => {
                        try {
                            await axios.post(`/api/tickets/${ticket._id}/click`);
                            if (ticket.link) {
                                window.open(ticket.link, '_blank', 'noopener,noreferrer');
                            }
                        } catch (error) {
                            console.error('Error registering click:', error);
                            toast.error('Failed to register click');
                        }
                    }}
                >
                    {/* Фоновое изображение с запасным вариантом */}
                    <div className="absolute inset-0 w-full h-full overflow-hidden">
                        <OptimizedImage
                            src={getBackgroundImage()}
                            alt={ticket.name}
                            className="absolute inset-0 w-full h-full object-cover"
                            fallbackSrc="/images/general/default-ticket-bg.jpg"
                        />
                    </div>

                    <CardContent className="p-6 bg-black bg-opacity-50 relative z-10">
                        <div className="text-center">
                            <h2 className="text-2xl font-bold text-white">{ticket.name}</h2>
                            <p className="text-sm text-gray-300 mt-1">{ticket.description}</p>
                        </div>

<div className="mt-6 text-center">
    {ticket.prizes.map((prize, index) => (
        <div key={index} className="mb-2">
            {prize.type === 'money' ? (
                <p className="text-4xl font-bold text-white flex items-center justify-center">
                    {prize.value}
                    <img 
                        src={`/images/general/${prize.currency?.toLowerCase() || 'usdt'}.png`} 
                        alt={prize.currency || 'USDT'} 
                        className="h-8 ml-2" 
                        onError={(e) => {
                            e.target.src = '/images/general/usdt.png';
                            e.target.onerror = null;
                        }}
                    />
                </p>
            ) : (
                <p className="text-3xl font-bold text-white">
                    {prize.quantity}x {prize.value}
                </p>
            )}
        </div>
    ))}
</div>

                        <div className="mt-6 flex justify-center space-x-4">
                            {Object.entries(timeLeft).map(([unit, value]) => (
                                <div key={unit} className="text-center">
                                    <div className="text-2xl font-bold text-white bg-black bg-opacity-50 rounded-lg p-2 min-w-[60px]">
                                        {value.toString().padStart(2, '0')}
                                    </div>
                                    <div className="text-xs text-gray-300 mt-1">{unit}</div>
                                </div>
                            ))}
                        </div>

                        <div className="mt-6">
                            <img 
                                src="/images/general/barcode.png" 
                                alt="Barcode" 
                                className="w-full"
                                onError={(e) => {
                                    e.target.src = '/images/general/default-barcode.png';
                                    e.target.onerror = null;
                                }}
                            />
                        </div>

                        <div className="mt-4 flex justify-between items-center">
                            <div className="flex items-center">
                                <p className="text-white">{ticket.price}</p>
                                <img 
                                    src="/images/general/coin.svg" 
                                    alt="coin" 
                                    className="w-4 h-4 ml-1"
                                    onError={(e) => {
                                        e.target.src = '/images/general/default-coin.png';
                                        e.target.onerror = null;
                                    }}
                                />
                            </div>
                            <p className="text-gray-300 text-sm">Sponsor: {ticket.sponsor}</p>
                        </div>
                    </CardContent>
                </Card>
                <div className="mt-4">
    <div className="flex justify-between mb-4">
        <div className="text-center bg-transparent border-2 border-black text-white rounded-[20px] p-2 flex-1 mx-1">
            <p className="font-semibold text-black">Winning Passes</p>
            <p className="text-xl text-black">{ticket.prizes ? ticket.prizes.reduce((sum, prize) => sum + (prize.quantity || 0), 0) : 0}</p>
        </div>
        <div className="text-center bg-transparent border-2 border-black text-white rounded-[20px] p-2 flex-1 mx-1">
            <p className="font-semibold text-black">Your Passes</p>
            <p className="text-xl text-black">{getUserTicketCount()}</p>
        </div>
    </div>
    <div className="flex justify-between space-x-2">
        <Button
            onClick={handlePurchase}
            className={`flex-1 ${!purchaseStatus.canBuy ? 'bg-gray-800 hover:bg-gray-700' : 'bg-black hover:bg-gray-800'} text-white rounded-[30px] py-2 px-4`}
            disabled={!purchaseStatus.canBuy}
        >
            {purchaseStatus.message}
        </Button>
        <Button
            onClick={() => setShowResults(!showResults)}
            className="flex-1 bg-black text-white hover:bg-gray-800 rounded-[30px] py-2 px-4"
        >
            {showResults ? 'Hide Results' : 'Show Results'}
        </Button>
    </div>
    {showResults && ticket && ticket._id && <DrawResults ticketId={ticket._id} />}
</div>
            </div>
        </div>
    );
};

export default TicketDisplay;