import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";
import { Pencil, Trash2, ChevronLeft, ChevronRight, ArrowUpDown, MoveVertical, MoveDown, MoveUp } from "lucide-react";
import BrandForm from './BrandForm';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { SiApplemusic, SiBehance, SiDiscord, SiDribbble, SiFacebook, SiGithub, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiQuora, SiReddit, SiSnapchat, SiSoundcloud, SiSpotify, SiTelegram, SiTencentqq, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';
import { Input } from "../ui/input";

const socialIcons = {
  AppleMusic: SiApplemusic,
  Behance: SiBehance,
  Discord: SiDiscord,
  Dribbble: SiDribbble,
  Facebook: SiFacebook,
  GitHub: SiGithub,
  Instagram: SiInstagram,
  KakaoTalk: SiKakaotalk,
  Line: SiLine,
  LinkedIn: SiLinkedin,
  Medium: SiMedium,
  Ok: SiOdnoklassniki,
  Onlyfans: SiOnlyfans,
  Patreon: SiPatreon,
  Pinterest: SiPinterest,
  QQ: SiTencentqq,
  Quora: SiQuora,
  Reddit: SiReddit,
  Snapchat: SiSnapchat,
  SoundCloud: SiSoundcloud,
  Spotify: SiSpotify,
  Telegram: SiTelegram,
  Threads: SiThreads,
  TikTok: SiTiktok,
  Tumblr: SiTumblr,
  Twitch: SiTwitch,
  Viber: SiViber,
  Vk: SiVk,
  WeChat: SiWechat,
  Weibo: SiSinaweibo,
  WhatsApp: SiWhatsapp,
  X: SiX,
  YouTube: SiYoutube,
};

const BrandsManagement = () => {
  const [brands, setBrands] = useState(null);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingBrand, setEditingBrand] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [brandsPerPage, setBrandsPerPage] = useState(10);
  const [totalBrands, setTotalBrands] = useState(0);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deletingBrandId, setDeletingBrandId] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [pendingBrandsCount, setPendingBrandsCount] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [subCategoryFilter, setSubCategoryFilter] = useState('all');
  const [subSubCategoryFilter, setSubSubCategoryFilter] = useState('all');
  const [availableSubCategories, setAvailableSubCategories] = useState([]);
  const [availableSubSubCategories, setAvailableSubSubCategories] = useState([]);

  const getBrandStatus = (brand) => {
    if (brand.status === 'pending') return "pending";
    if (brand.activeDays === -1) return "active";
    const currentDate = new Date();
    const createdDate = new Date(brand.createdAt);
    const daysSinceCreation = Math.floor((currentDate - createdDate) / (1000 * 60 * 60 * 24));
    return daysSinceCreation < brand.activeDays ? "active" : "expired";
  };

  const fetchData = useCallback(async () => {
    try {
      // Проверяем корректность параметров перед запросом
      console.log('Состояние фильтров:', {
        categoryFilter,
        subCategoryFilter,
        subSubCategoryFilter,
        availableSubCategories,
        availableSubSubCategories
      });

      // Создаем объект с параметрами запроса
      const params = {
        page: currentPage,
        limit: brandsPerPage,
        sort: sortColumn,
        order: sortOrder,
        status: statusFilter
      };

      // Добавляем параметр категории только если он не "all"
      if (categoryFilter !== 'all') {
        params.category = categoryFilter;

        // Добавляем параметры подкатегорий, только если они выбраны
        if (subCategoryFilter !== 'all') {
          params.subCategory = subCategoryFilter;

          if (subSubCategoryFilter !== 'all') {
            params.subSubCategory = subSubCategoryFilter;
          }
        }
      }

      console.log('Fetching data with params:', params);

      // Преобразуем параметры в URL-строку запроса
      const queryParams = Object.entries(params)
        .filter(([key, value]) => value !== 'all' && value !== '')
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

      const [brandsRes, categoriesRes, countriesRes, pendingCountRes] = await Promise.all([
        axios.get(`/api/brands?${queryParams}`),
        axios.get('/api/categories'),
        axios.get('/api/countries'),
        axios.get('/api/brands/pending-count')
      ]);

      console.log('Full brands response:', brandsRes);
      console.log('Brands data:', brandsRes.data);

      if (brandsRes.data && Array.isArray(brandsRes.data.brands)) {
        setBrands(brandsRes.data.brands);
        setTotalBrands(brandsRes.data.total);
      } else {
        console.error('Invalid brands data format:', brandsRes.data);
        setBrands([]);
        setTotalBrands(0);
      }

      setCategories(categoriesRes.data || []);
      setCountries(countriesRes.data || []);
      setPendingBrandsCount(pendingCountRes.data.count);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setBrands([]);
      setTotalBrands(0);
      setPendingBrandsCount(0);
    }
  }, [currentPage, brandsPerPage, sortColumn, sortOrder, statusFilter, categoryFilter, subCategoryFilter, subSubCategoryFilter]);

  const handleCategoryChange = (value) => {
    setCategoryFilter(value);
    console.log('Изменена категория:', value);
  };

  const handleSubCategoryChange = (value) => {
    setSubCategoryFilter(value);
    console.log('Изменена подкатегория:', value);
  };

  const handleSubSubCategoryChange = (value) => {
    setSubSubCategoryFilter(value);
    console.log('Изменена подподкатегория:', value);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const previousCategoryRef = useRef('all');

  // Обновление доступных подкатегорий при изменении выбранной категории
  useEffect(() => {
    if (categoryFilter === 'all') {
      setAvailableSubCategories([]);
      setSubCategoryFilter('all');
      setSubSubCategoryFilter('all');
    } else {
      // Найти все уникальные подкатегории для выбранной категории
      const selectedCategory = categories.find(c => c._id === categoryFilter);

      if (selectedCategory && Array.isArray(selectedCategory.subCategories) && selectedCategory.subCategories.length > 0) {
        console.log('Найдены подкатегории в категории:', selectedCategory.subCategories);
        setAvailableSubCategories(selectedCategory.subCategories);
      } else {
        console.log('Используем подкатегории из брендов');
        // Собираем подкатегории из всех брендов, если категория выбрана
        const subCatsSet = new Set();

        if (brands && Array.isArray(brands)) {
          brands.forEach(brand => {
            if (brand.categories && Array.isArray(brand.categories)) {
              brand.categories.forEach(cat => {
                if (cat.category && cat.category._id === categoryFilter && cat.subCategory) {
                  subCatsSet.add(cat.subCategory);
                }
              });
            }
          });
        }

        const subCatsArray = Array.from(subCatsSet);
        console.log('Извлеченные подкатегории:', subCatsArray);
        setAvailableSubCategories(subCatsArray);
      }

      // Только сбрасываем подкатегорию, если мы ПЕРВЫЙ РАЗ установили категорию (а не когда меняются бренды)
      if (previousCategoryRef.current !== categoryFilter) {
        setSubCategoryFilter('all');
        setSubSubCategoryFilter('all');
      }

      // Сохраняем текущую категорию для сравнения при следующем запуске эффекта
      previousCategoryRef.current = categoryFilter;
    }
  }, [categoryFilter, categories, brands]);

  const previousSubCategoryRef = useRef('all');

  // Обновление доступных подподкатегорий при изменении выбранной подкатегории
  useEffect(() => {
    if (subCategoryFilter === 'all') {
      setAvailableSubSubCategories([]);
      setSubSubCategoryFilter('all');
    } else {
      // Собираем подподкатегории из всех брендов
      const subSubCats = new Set();
      if (brands && Array.isArray(brands)) {
        brands.forEach(brand => {
          if (brand.categories && Array.isArray(brand.categories)) {
            brand.categories.forEach(cat => {
              // Проверяем типы данных перед сравнением
              const subCat = cat.subCategory;
              const subCatValue = typeof subCat === 'string' ? subCat : (subCat && subCat.name ? subCat.name : '');

              if (cat.category &&
                cat.category._id === categoryFilter &&
                subCatValue === subCategoryFilter &&
                cat.subSubCategory) {
                // Преобразуем подподкатегорию в строку независимо от типа
                const subSubCat = cat.subSubCategory;
                const subSubCatValue = typeof subSubCat === 'string' ? subSubCat : (subSubCat && subSubCat.name ? subSubCat.name : '');

                if (subSubCatValue) {
                  subSubCats.add(subSubCatValue);
                }
              }
            });
          }
        });
      }

      const subSubCatsArray = Array.from(subSubCats);
      console.log('Найденные подподкатегории:', subSubCatsArray);
      setAvailableSubSubCategories(subSubCatsArray);

      // Сбрасываем подподкатегорию только при первом выборе подкатегории
      if (previousSubCategoryRef.current !== subCategoryFilter) {
        setSubSubCategoryFilter('all');
      }

      // Сохраняем текущую подкатегорию для следующего сравнения
      previousSubCategoryRef.current = subCategoryFilter;
    }
  }, [subCategoryFilter, categoryFilter, brands]);

  const handleApprove = useCallback(async (brandId) => {
    try {
      await axios.put(`/api/brands/${brandId}/approve`);
      fetchData();
    } catch (error) {
      console.error('Error approving brand:', error);
      alert('Failed to approve brand. Please try again.');
    }
  }, [fetchData]);

  const handleSave = useCallback(async (brandData) => {
    try {
      console.log('Sending brand data:', brandData);
      let savedBrand;
      if (editingBrand) {
        const response = await axios.put(`/api/brands/${editingBrand._id}`, brandData);
        savedBrand = response.data;
      } else {
        const response = await axios.post('/api/brands', brandData);
        savedBrand = response.data;
      }
      console.log('Saved brand:', savedBrand);
      await fetchData();
      setIsDialogOpen(false);
      setEditingBrand(null);
    } catch (error) {
      console.error('Error saving brand:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  }, [editingBrand, fetchData]);

  const handleDelete = useCallback((brandId) => {
    setDeletingBrandId(brandId);
    setIsDeleteDialogOpen(true);
  }, []);

  const confirmDelete = useCallback(async () => {
    if (deletingBrandId) {
      try {
        const response = await axios.delete(`/api/brands/${deletingBrandId}`);
        if (response.status === 200) {
          console.log('Brand deleted successfully');
          await fetchData();
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        console.error('Error deleting brand:', error);
        alert('Failed to delete brand. Please try again.');
      }
      setIsDeleteDialogOpen(false);
      setDeletingBrandId(null);
    }
  }, [deletingBrandId, fetchData]);

  const handleAddNewBrand = () => {
    setEditingBrand(null);
    setIsDialogOpen(true);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleBrandsPerPageChange = (value) => {
    setBrandsPerPage(Number(value));
    setCurrentPage(1);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const renderSortIcon = (column) => {
    if (column === sortColumn) {
      return sortOrder === 'asc' ? <MoveUp className="ml-2 h-4 w-4 inline" /> : <MoveDown className="ml-2 h-4 w-4 inline" />;
    }
    return <MoveVertical className="ml-2 h-4 w-4 inline" />;
  };

  const filteredBrands = brands ? brands.filter(brand => {
    // Фильтрация по поисковому запросу
    const matchesSearchQuery = brand.name.toLowerCase().includes(searchQuery.toLowerCase());

    // Локальная фильтрация по подкатегориям и подподкатегориям, если API не поддерживает эти параметры
    let matchesCategories = true;

    if (categoryFilter !== 'all') {
      // Проверяем, содержит ли бренд выбранную категорию
      const hasCategoryMatch = brand.categories && Array.isArray(brand.categories) && brand.categories.some(cat =>
        cat.category && cat.category._id === categoryFilter
      );

      if (!hasCategoryMatch) {
        matchesCategories = false;
      } else if (subCategoryFilter !== 'all') {
        // Проверяем, содержит ли бренд выбранную подкатегорию
        const hasSubCategory = brand.categories.some(cat => {
          // Проверка значения подкатегории (может быть строкой или объектом)
          const subCat = cat.subCategory;
          const subCatValue = typeof subCat === 'string' ? subCat : (subCat ? subCat.name : '');

          return cat.category &&
            cat.category._id === categoryFilter &&
            subCatValue === subCategoryFilter;
        });

        if (!hasSubCategory) {
          matchesCategories = false;
        } else if (subSubCategoryFilter !== 'all') {
          // Если выбрана подподкатегория, проверяем и её
          const hasSubSubCategory = brand.categories.some(cat => {
            // Проверка значения подподкатегории (может быть строкой или объектом)
            const subSubCat = cat.subSubCategory;
            const subSubCatValue = typeof subSubCat === 'string' ? subSubCat : (subSubCat ? subSubCat.name : '');

            const subCat = cat.subCategory;
            const subCatValue = typeof subCat === 'string' ? subCat : (subCat ? subCat.name : '');

            return cat.category &&
              cat.category._id === categoryFilter &&
              subCatValue === subCategoryFilter &&
              subSubCatValue === subSubCategoryFilter;
          });

          matchesCategories = hasSubSubCategory;
        }
      }
    }

    return matchesSearchQuery && matchesCategories;
  }) : [];

  return (
    <div className="space-y-6 pb-2">
      {/* Поисковая строка и фильтры */}
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-4 flex-wrap">
          <Input
            type="text"
            placeholder="Search Brand"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full sm:w-64 bg-zinc-950 text-white placeholder-zinc-500 border-zinc-800 focus:border-zinc-700"
          />
          <Select value={statusFilter} onValueChange={setStatusFilter}>
            <SelectTrigger className="w-full sm:w-[150px] bg-zinc-950 text-zinc-300 border-zinc-800">
              <SelectValue placeholder="Filter by status" />
            </SelectTrigger>
            <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="pending">Pending</SelectItem>
              <SelectItem value="active">Active</SelectItem>
              <SelectItem value="expired">Expired</SelectItem>
            </SelectContent>
          </Select>
          <Select value={categoryFilter} onValueChange={handleCategoryChange}>
            <SelectTrigger className="w-full sm:w-[150px] bg-zinc-950 text-zinc-300 border-zinc-800">
              <SelectValue placeholder="Filter by category" />
            </SelectTrigger>
            <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
              <SelectItem value="all">All Categories</SelectItem>
              {categories.map(category => (
                <SelectItem key={category._id} value={category._id}>{category.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>

          {/* Фильтр по подкатегории */}
          {categoryFilter !== 'all' && availableSubCategories.length > 0 && (
            <Select value={subCategoryFilter} onValueChange={handleSubCategoryChange}>
              <SelectTrigger className="w-full sm:w-[150px] bg-zinc-950 text-zinc-300 border-zinc-800">
                <SelectValue placeholder="Filter by subcategory" />
              </SelectTrigger>
              <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                <SelectItem value="all">All Subcategories</SelectItem>
                {availableSubCategories.map((subCat, index) => {
                  // Проверка, является ли subCat строкой или объектом
                  const subCatValue = typeof subCat === 'string' ? subCat : subCat.name;
                  const subCatDisplay = typeof subCat === 'string' ? subCat : subCat.name;
                  return (
                    <SelectItem key={index} value={subCatValue}>{subCatDisplay}</SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          )}

          {/* Фильтр по подподкатегории */}
          {subCategoryFilter !== 'all' && availableSubSubCategories.length > 0 && (
            <Select value={subSubCategoryFilter} onValueChange={handleSubSubCategoryChange}>
              <SelectTrigger className="w-full sm:w-[150px] bg-zinc-950 text-zinc-300 border-zinc-800">
                <SelectValue placeholder="Filter by sub-subcategory" />
              </SelectTrigger>
              <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                <SelectItem value="all">All Sub-subcategories</SelectItem>
                {availableSubSubCategories.map((subSubCat, index) => {
                  // Проверка, является ли subSubCat строкой или объектом
                  const subSubCatValue = typeof subSubCat === 'string' ? subSubCat : subSubCat.name;
                  const subSubCatDisplay = typeof subSubCat === 'string' ? subSubCat : subSubCat.name;
                  return (
                    <SelectItem key={index} value={subSubCatValue}>{subSubCatDisplay}</SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          )}

          <div className="flex-1 flex justify-end">
            <Button onClick={handleAddNewBrand} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">
              Add New Brand
            </Button>
          </div>
        </div>
      </div>

      {/* Таблица брендов */}
  <div className="rounded-md border border-zinc-800 overflow-hidden">
    <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}>
          <Table>
            <TableHeader className="sticky top-0 bg-zinc-950 z-10">
              <TableRow className="hover:bg-zinc-900 border-b border-zinc-800">
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Logo</TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('name')}>
                  <div className="flex items-center">
                    <span>Name</span>
                    {renderSortIcon('name')}
                  </div>
                </TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('category')}>
                  <div className="flex items-center">
                    <span>Category</span>
                    {renderSortIcon('category')}
                  </div>
                </TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Country</TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('global')}>
                  <div className="flex items-center">
                    <span>Global</span>
                    {renderSortIcon('global')}
                  </div>
                </TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Description</TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('frequency')}>
                  <div className="flex items-center">
                    <span>Freq</span>
                    {renderSortIcon('frequency')}
                  </div>
                </TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('activeDays')}>
                  <div className="flex items-center">
                    <span>Day</span>
                    {renderSortIcon('activeDays')}
                  </div>
                </TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Website</TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Social</TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('status')}>
                  <div className="flex items-center">
                    <span>Status</span>
                    {renderSortIcon('status')}
                  </div>
                </TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left cursor-pointer" onClick={() => handleSort('addedBy')}>
                  <div className="flex items-center">
                    <span>User</span>
                    {renderSortIcon('addedBy')}
                  </div>
                </TableHead>
                <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredBrands && filteredBrands.length > 0 ? (
                filteredBrands.map(brand => (
                  <TableRow key={brand._id} className="hover:bg-zinc-900 border-b border-zinc-800">
                    <TableCell className="p-2">
                      {brand.logo ? (
                        <img src={brand.logo} alt={`${brand.name} logo`} className="w-8 h-8 object-cover rounded-full" />
                      ) : (
                        <div className="w-8 h-8 bg-zinc-700 rounded-full"></div>
                      )}
                    </TableCell>
                    <TableCell className="text-zinc-300 p-2">{brand.name}</TableCell>
                    <TableCell className="text-zinc-300 p-2">
                      {brand.categories.map((cat, index) => (
                        <span key={index} className="text-xs">
                          {`${cat.category?.name || ''} > ${cat.subCategory || ''} ${cat.subSubCategory ? `> ${cat.subSubCategory}` : ''}`}
                          {index < brand.categories.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="flex space-x-1">
                        {brand.countries.map(country => (
                          <TooltipProvider key={country._id}>
                            <Tooltip>
                              <TooltipTrigger>
                                <div className="w-6 h-4 overflow-hidden border border-zinc-700">
                                  <img
                                    src={`https://flagcdn.com/32x24/${country.code.toLowerCase()}.png`}
                                    alt={country.name}
                                    className="w-full h-full object-cover"
                                  />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="bg-zinc-800 text-zinc-300 border-zinc-700">
                                <p>{country.name}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell className="text-zinc-300 p-2">{brand.global ? 'Yes' : 'No'}</TableCell>
                    <TableCell className="text-zinc-300 p-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <span className="truncate block max-w-[150px]">{brand.description || 'N/A'}</span>
                          </TooltipTrigger>
                          <TooltipContent className="bg-zinc-800 text-zinc-300 border-zinc-700">
                            <p>{brand.description || 'No description available'}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                    <TableCell className="text-zinc-300 p-2">{brand.frequency}</TableCell>
                    <TableCell className="text-zinc-300 p-2">{brand.activeDays === -1 ? '∞' : brand.activeDays}</TableCell>
                    <TableCell className="text-zinc-300 p-2">
                      <a href={brand.website} target="_blank" rel="noopener noreferrer" className="hover:text-[#e0ff89]">
                        {brand.website ? 'Link' : 'N/A'}
                      </a>
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="flex space-x-1 flex-wrap">
                        {brand.socialMedia.map(social => {
                          const Icon = socialIcons[social.platform];
                          return Icon ? (
                            <a
                              key={social.platform}
                              href={social.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-zinc-300 hover:text-[#e0ff89]"
                            >
                              <Icon size={16} />
                            </a>
                          ) : null;
                        })}
                      </div>
                    </TableCell>
                    <TableCell className="p-2">
                      <span className={
                        brand.status === "active" ? "text-[#e0ff89]" :
                          brand.status === "expired" ? "text-red-500" :
                            brand.status === "pending" ? "text-yellow-500" :
                              "text-zinc-300"
                      }>
                        {brand.status}
                      </span>
                    </TableCell>
                    <TableCell className="text-zinc-300 p-2">
                      {brand.owner && brand.owner.username ? '@' + brand.owner.username : 'Admin'}
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="flex gap-1">
                        {brand.status === 'pending' && (
                          <Button onClick={() => handleApprove(brand._id)} className="p-1 h-8 w-8 bg-green-500 text-white rounded-full" title="Approve">
                            ✓
                          </Button>
                        )}
                        <Button
                          onClick={() => { setEditingBrand(brand); setIsDialogOpen(true); }}
                          className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                          title="Edit"
                        >
                          <Pencil size={14} />
                        </Button>
                        <Button
                          onClick={() => handleDelete(brand._id)}
                          className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                          title="Delete"
                        >
                          <Trash2 size={14} />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={13} className="text-center text-zinc-300 p-4">
                    {brands === null ? 'Loading...' : 'No brands found'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>

      {/* Пагинация и количество на странице - компактная, без фона */}
      <div className="relative py-2 z-40">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-2">
          <div className="flex items-center gap-4">
            <div className="flex items-center space-x-2">
              <span className="text-zinc-400 text-sm">Per page:</span>
              <Select value={brandsPerPage.toString()} onValueChange={handleBrandsPerPageChange}>
                <SelectTrigger className="w-[70px] h-8 bg-zinc-950 text-zinc-300 border-zinc-800">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent className="bg-zinc-950 text-zinc-300 border-zinc-800">
                  {[10, 20, 50, 100].map(value => (
                    <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center space-x-4 text-sm">
              <span className="text-zinc-400">Pending: {pendingBrandsCount}</span>
              <span className="text-zinc-400">Total: {totalBrands}</span>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
            >
              <ChevronLeft size={16} />
            </Button>
            <span className="text-zinc-400 text-sm">Page {currentPage} of {Math.max(1, Math.ceil(totalBrands / brandsPerPage))}</span>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalBrands / brandsPerPage) || totalBrands === 0}
              className="bg-zinc-900 hover:bg-zinc-800 text-white rounded-full p-1 h-8 w-8"
            >
              <ChevronRight size={16} />
            </Button>
          </div>
        </div>
      </div>

      {/* Диалоги */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-h-[90vh] overflow-y-auto bg-zinc-950 border border-zinc-800">
          <DialogHeader>
            <DialogTitle className="text-zinc-100">{editingBrand ? 'Edit Brand' : 'Add New Brand'}</DialogTitle>
            <DialogDescription className="text-zinc-400">
              {editingBrand ? 'Edit the details of the brand.' : 'Enter the details of the new brand.'}
            </DialogDescription>
          </DialogHeader>
          <BrandForm
            brand={editingBrand}
            onSave={handleSave}
            onCancel={() => setIsDialogOpen(false)}
            categories={categories}
            countries={countries}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="bg-zinc-950 text-white border border-zinc-800 rounded-lg p-6">
          <DialogHeader>
            <DialogTitle className="text-zinc-100">Confirm Deletion</DialogTitle>
            <DialogDescription className="text-zinc-400">
              Are you sure you want to delete this brand? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-6 flex justify-end space-x-4">
            <Button
              onClick={() => setIsDeleteDialogOpen(false)}
              className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
            >
              Cancel
            </Button>
            <Button
              onClick={confirmDelete}
              className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BrandsManagement;