import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BlockedUserScreen = () => {
  const [blockInfo, setBlockInfo] = useState({
    isBlocked: true,
    blockReason: '',
    blockedAt: null
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Проверяем статус блокировки при загрузке компонента
    const checkBlockStatus = async () => {
      try {
        const response = await axios.get('/api/users/check-block-status');
        setBlockInfo(response.data);
        
        // Если пользователь не заблокирован, перенаправляем на главную страницу
        if (!response.data.isBlocked) {
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking block status:', error);
        
        // Если ошибка аутентификации, перенаправляем на страницу входа
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        }
      }
    };
    
    checkBlockStatus();
  }, [navigate]);

  const formatDate = (dateString) => {
    if (!dateString) return 'Unknown date';
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-zinc-950 p-4">
      <div className="bg-zinc-900 p-8 rounded-lg border-2 border-zinc-800 max-w-md w-full text-center">
        <div className="mb-6">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-20 w-20 text-red-500 mx-auto" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <circle cx="12" cy="12" r="10" strokeWidth="2" />
            <line x1="4.93" y1="4.93" x2="19.07" y2="19.07" strokeWidth="2" />
          </svg>
        </div>
        
        <h1 className="text-2xl font-bold text-red-500 mb-4">Account Blocked</h1>
        
        <p className="text-zinc-300 mb-6">
          Your account has been blocked and you cannot access the platform at this time.
        </p>
        
        {blockInfo.blockReason && (
          <div className="mb-4">
            <h2 className="text-lg font-semibold text-zinc-200 mb-2">Reason:</h2>
            <p className="text-zinc-300 bg-zinc-800 p-3 rounded-md">{blockInfo.blockReason}</p>
          </div>
        )}
        
        {blockInfo.blockedAt && (
          <div className="mb-6">
            <h2 className="text-lg font-semibold text-zinc-200 mb-2">Date of Block:</h2>
            <p className="text-zinc-300">{formatDate(blockInfo.blockedAt)}</p>
          </div>
        )}
        
        <div className="mt-4">
          <p className="text-zinc-400 mb-4">
            If you think this was a mistake, please contact our support team for assistance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlockedUserScreen;