import React, { useEffect, useState, useRef } from 'react';
import { TonConnectUIProvider, TonConnectButton, useTonAddress, useTonWallet } from '@tonconnect/ui-react';
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Wallet } from 'lucide-react'; // Добавляем иконку кошелька
import axios from 'axios';
import { Button } from "./ui/button";

const WalletContent = ({ userId }) => {
  const userAddress = useTonAddress();
  const wallet = useTonWallet();
  const [balance, setBalance] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const tonButtonRef = useRef(null);

  // Функция для очистки данных кошелька при отключении
  const handleDisconnect = async () => {
    try {
      await axios.post('/api/users/disconnect');
      setBalance(0);
      setLastUpdate(null);
      console.log('Wallet disconnected and data cleared');
    } catch (error) {
      console.error('Error disconnecting wallet:', error);
    }
  };

  // Функция проверки необходимости обновления
  const shouldUpdate = () => {
    if (!lastUpdate) return true;
    const timeSinceLastUpdate = Date.now() - new Date(lastUpdate).getTime();
    const oneDayInMs = 24 * 60 * 60 * 1000;
    return timeSinceLastUpdate > oneDayInMs;
  };

  // Обновляем информацию о кошельке
  const updateWallet = async (force = false) => {
    if (!userAddress || !userId || !wallet || (isUpdating && !force)) return;

    if (!force && !shouldUpdate()) {
      console.log('Skipping update - less than 24 hours passed');
      return;
    }

    try {
      setIsUpdating(true);
      const response = await axios.post('/api/users/update-wallet', {
        userId,
        walletAddress: userAddress
      });

      if (response.data?.tonWallet?.balance !== undefined) {
        setBalance(response.data.tonWallet.balance);
        setLastUpdate(new Date().toISOString());
      }
    } catch (error) {
      console.error('Error updating wallet:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  // Следим за изменением состояния подключения
  useEffect(() => {
    if (!wallet && balance !== 0) {
      handleDisconnect();
    }
  }, [wallet]);

  // Обновляем информацию о кошельке только когда он подключен
  useEffect(() => {
    if (wallet && userAddress && userId) {
      updateWallet();
    }
  }, [wallet, userAddress, userId]);

  // Функция для применения стилей к кнопке напрямую через DOM
  const applyButtonStyles = () => {
    if (!tonButtonRef.current) return;
    
    // Получаем все кнопки внутри контейнера
    const buttonElements = tonButtonRef.current.querySelectorAll('button');
    const tonButtonContainers = tonButtonRef.current.querySelectorAll('div');
    
    // Применяем стили ко всем контейнерам
    tonButtonContainers.forEach(container => {
      container.style.width = '100%';
      container.style.maxWidth = '100%';
    });
    
    // Применяем стили к кнопкам
    buttonElements.forEach(button => {
      button.style.width = '100%';
      button.style.maxWidth = '100%';
      button.style.borderRadius = '30px';
      
      // Стилизуем кнопку подключения
      if (button.hasAttribute('data-tc-button-connect')) {
        button.style.backgroundColor = '#0098EA';
        button.style.color = 'white';
      }
    });
  };

  // Добавляем MutationObserver для отслеживания изменений DOM
  useEffect(() => {
    if (!tonButtonRef.current) return;
    
    applyButtonStyles();

    // Создаем observer для отслеживания изменений
    const observer = new MutationObserver((mutations) => {
      applyButtonStyles();
    });

    // Наблюдаем за изменениями в DOM
    observer.observe(tonButtonRef.current, {
      childList: true,
      subtree: true,
      attributes: true
    });

    return () => {
      observer.disconnect();
    };
  }, [tonButtonRef.current]);

  return (
    <Card className="bg-zinc-900 text-white rounded-[20px] overflow-hidden">
      <CardHeader className="bg-zinc-800 px-6 py-4">
        <CardTitle className="text-xl font-bold flex items-center gap-2">
          <Wallet className="w-5 h-5 text-[#e0ff89]" />
          Connect TON Wallet
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <p className="text-gray-400 text-sm mb-4">
          Connect your TON wallet to receive rewards
        </p>

        {/* Всегда показываем баланс */}
        <div className="mb-4">
          <p className="text-[#e0ff89] text-sm">Balance:</p>
          <div className="flex items-center gap-2 mt-1">
            <p className="text-xl font-bold text-white">
              {balance.toFixed(2)} TON
            </p>
          </div>
          {lastUpdate && (
            <p className="text-zinc-500 text-sm mt-2">
              Last updated: {new Date(lastUpdate).toLocaleString()}
            </p>
          )}
          
          {wallet && (
            <Button
              onClick={() => updateWallet(true)}
              className="mt-3 w-full bg-white text-black px-4 py-1 h-[34px] hover:bg-gray-200 disabled:opacity-50 text-sm rounded-[30px]"
              disabled={isUpdating}
            >
              {isUpdating ? 'Updating...' : 'Update'}
            </Button>
          )}
        </div>

        {/* Кнопка подключения на всю ширину */}
        <div className="w-full" ref={tonButtonRef} style={{ width: '100%' }}>
          <TonConnectButton />
        </div>
      </CardContent>
    </Card>
  );
};

// Пользовательский компонент стилизации TonConnect
const CustomTonConnectProvider = ({ children }) => {
  useEffect(() => {
    // Добавляем глобальные стили для переопределения TonConnect элементов
    const style = document.createElement('style');
    style.textContent = `
      .ton-connect-ui, 
      [data-tc-root], 
      [data-tc-root] > div, 
      [data-tc-button],
      [data-tc-connect-button] {
        width: 100% !important;
        max-width: 100% !important;
      }
      
      button[data-tc-button-connect] {
        width: 100% !important;
        max-width: 100% !important;
        border-radius: 30px !important;
        background-color: #0098EA !important;
        color: white !important;
      }
      
      button[data-tc-button-connect]:hover {
        background-color: #0088D1 !important;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return children;
};

const TonWallet = ({ userId }) => {
  return (
    <CustomTonConnectProvider>
      <TonConnectUIProvider 
        manifestUrl="https://battlebrands.app/tonconnect-manifest.json" 
        connectorParameters={{
          manifestUrl: 'https://battlebrands.app/tonconnect-manifest.json',
          proxyUrl: 'https://proxy.tonconnect.org',
          actionsConfiguration: {
            twaReturnUrl: window.location.origin
          }
        }}
      >
        <WalletContent userId={userId} />
      </TonConnectUIProvider>
    </CustomTonConnectProvider>
  );
};

export default TonWallet;