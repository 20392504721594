import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './layout/Sidebar';
import Header from './layout/Header';
import Dashboard from './Dashboard';
import BrandsManagement from './BrandsManagement';
import CategoriesManagement from './CategoriesManagement';
import CountriesManagement from './CountriesManagement';
import TasksManagement from './TasksManagement';
import RankManagement from './RankManagement';
import FAQManagement from './FAQManagement';
import UsersManagement from './UsersManagement';
import Settings from './Settings';
import AdminProfile from './AdminProfile';
import ReferralsManagement from './ReferralsManagement';
import VideoTasksManagement from './VideoTasksManagement';
import SocialTasksManagement from './SocialTasksManagement';
import SpecialTasksManagement from './SpecialTasksManagement';
import TicketsManagement from './TicketsManagement';
import DrawsManagement from './DrawsManagement';
import StoriesManagement from './StoriesManagement';
import TelegramMessaging from './TelegramMessaging';

const AdminPanel = () => {
  const location = useLocation();
  
  return (
    <div className="min-h-screen bg-black text-white relative">
      <div className="fixed inset-0 bg-cover bg-center opacity-5 z-0 pointer-events-none" style={{backgroundImage: "url('/images/general/admin-background.webp')"}}></div>
      
      <Sidebar />
      
      <div className="lg:pl-64 min-h-screen flex flex-col relative z-10">
        <Header />
        
        <main className="flex-1 p-4 md:p-6 max-w-full overflow-x-auto">
          <div className="bg-zinc-900 rounded-lg shadow-md p-4 md:p-6 min-h-[calc(100vh-130px)]">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/brands" element={<BrandsManagement />} />
              <Route path="/categories" element={<CategoriesManagement />} />
              <Route path="/countries" element={<CountriesManagement />} />
              <Route path="/tasks" element={<TasksManagement />} />
              <Route path="/tasks/video" element={<VideoTasksManagement />} />
              <Route path="/tasks/social" element={<SocialTasksManagement />} />
              <Route path="/tasks/special" element={<SpecialTasksManagement />} />
              <Route path="/tasks/referrals" element={<ReferralsManagement />} />
              <Route path="/stories" element={<StoriesManagement />} />
              <Route path="/ranks" element={<RankManagement />} />
              <Route path="/faq" element={<FAQManagement />} />
              <Route path="/messages" element={<TelegramMessaging />} />
              <Route path="/accounts/users" element={<UsersManagement />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/profile" element={<AdminProfile />} />
              <Route path="/tickets" element={<TicketsManagement />} />
              <Route path="/draws" element={<DrawsManagement />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminPanel;