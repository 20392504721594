import React, { useState, useEffect } from 'react';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Label } from "./ui/label";
import axios from 'axios';
import { Upload, X } from 'lucide-react';
import toast from './CustomToast';
import { SiApplemusic, SiBehance, SiDiscord, SiDribbble, SiFacebook, SiGithub, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiQuora, SiReddit, SiSnapchat, SiSoundcloud, SiSpotify, SiTelegram, SiTencentqq, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiWhatsapp, SiX, SiYoutube, SiSinaweibo } from 'react-icons/si';
import { useDropzone } from 'react-dropzone';
import { Switch } from "./ui/switch"
import { useNavigate } from 'react-router-dom';

const socialIcons = {
  AppleMusic: SiApplemusic,
  Behance: SiBehance,
  Discord: SiDiscord,
  Dribbble: SiDribbble,
  Facebook: SiFacebook,
  GitHub: SiGithub,
  Instagram: SiInstagram,
  KakaoTalk: SiKakaotalk,
  Line: SiLine,
  LinkedIn: SiLinkedin,
  Medium: SiMedium,
  Ok: SiOdnoklassniki,
  Onlyfans: SiOnlyfans,
  Patreon: SiPatreon,
  Pinterest: SiPinterest,
  QQ: SiTencentqq,
  Quora: SiQuora,
  Reddit: SiReddit,
  Snapchat: SiSnapchat,
  SoundCloud: SiSoundcloud,
  Spotify: SiSpotify,
  Telegram: SiTelegram,
  Threads: SiThreads,
  TikTok: SiTiktok,
  Tumblr: SiTumblr,
  Twitch: SiTwitch,
  Viber: SiViber,
  Vk: SiVk,
  WeChat: SiWechat,
  Weibo: SiSinaweibo,
  WhatsApp: SiWhatsapp,
  X: SiX,
  YouTube: SiYoutube,
};

const AddBrand = ({ brand, onSave, onCancel, disableCategories = false }) => {
  const [formData, setFormData] = useState(brand || {
    name: '',
    logo: '',
    description: '',
    categories: [{ category: '', subCategory: '', subSubCategory: '' }],
    countries: [], // Инициализируем как пустой массив
    country: '', // Для выбора в форме
    global: true,
    website: '',
    socialMedia: [],
    frequency: 0.1, // Устанавливаем значение по умолчанию
    activeDays: 30 // Устанавливаем значение по умолчанию
  });

  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState('');
  const [logoOptions, setLogoOptions] = useState([]);
  const [logoPreview, setLogoPreview] = useState('');
  const [nameError, setNameError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const navigate = useNavigate();

useEffect(() => {
  if (brand && categories.length > 0) {
    console.log('Loading brand for edit with loaded categories:', brand);
    
    // Определяем ID страны из данных бренда
    let countryId = '';
    
    // Проверяем различные формы данных о стране
    if (brand.country && typeof brand.country === 'object' && brand.country._id) {
      countryId = brand.country._id;
    } else if (brand.country && typeof brand.country === 'string') {
      countryId = brand.country;
    } else if (Array.isArray(brand.countries) && brand.countries.length > 0) {
      if (typeof brand.countries[0] === 'object' && brand.countries[0]._id) {
        countryId = brand.countries[0]._id;
      } else if (typeof brand.countries[0] === 'string') {
        countryId = brand.countries[0];
      }
    }
    
    // Обработка категорий
    let processedCategories = [];
    
    if (Array.isArray(brand.categories) && brand.categories.length > 0) {
      processedCategories = brand.categories.map(cat => {
        // Создаем правильную структуру для каждой категории
        return {
          category: cat.category?._id || (typeof cat.category === 'string' ? cat.category : '') || '',
          subCategory: cat.subCategory || '',
          subSubCategory: cat.subSubCategory || ''
        };
      });
    } else {
      // Если категорий нет, добавляем пустую
      processedCategories = [{ category: '', subCategory: '', subSubCategory: '' }];
    }
    
    const newFormData = {
      ...brand,
      categories: processedCategories,
      country: countryId,
    };
    
    setFormData(newFormData);
    setLogoPreview(brand.logo || '');
    fetchLogoFromAPI(brand.name);
    console.log('Brand data:', brand);
    console.log('New form data with fixed categories and country:', newFormData);
  }
}, [brand, categories.length]);

  useEffect(() => {
    if (categories.length > 0 && countries.length > 0 && formData.id) {
      console.log('Categories:', categories);
      console.log('Countries:', countries);
      console.log('Current formData:', formData);

      const updatedFormData = {
        ...formData,
        category: formData.category || categories.find(c => c.name === formData.category || c._id === formData.category)?._id || '',
        country: formData.country || countries.find(c => c.name === formData.country || c._id === formData.country)?._id || ''
      };

      console.log('Updated formData:', updatedFormData);
      setFormData(updatedFormData);
    }
  }, [categories, countries, formData.id]);

  const logFormDataChanges = (changes) => {
    console.log('Form data changed:', changes);
  };

useEffect(() => {
  const loadInitialData = async () => {
    await Promise.all([
      fetchCategories(),
      fetchCountries()
    ]);
  };
  
  loadInitialData();
}, []);

  // Улучшенная функция загрузки подкатегорий
useEffect(() => {
  // Загружаем подкатегории при изменении категорий
  const loadSubcategories = async () => {
    if (!categories.length || !formData.categories) return;

    for (let i = 0; i < formData.categories.length; i++) {
      const cat = formData.categories[i];
      if (cat.category && cat.subCategory) {
        // Получаем доступные subSubCategories для данной категории и подкатегории
        const subSubOptions = getSubSubCategories(cat.category, cat.subCategory);
        // Проверяем, существует ли выбранная subSubCategory в списке доступных
        if (cat.subSubCategory && !subSubOptions.some(opt => opt.name === cat.subSubCategory)) {
          // Сбрасываем subSubCategory, если она не доступна для выбранных категории и подкатегории
          const newCategories = [...formData.categories];
          newCategories[i] = {
            ...newCategories[i],
            subSubCategory: ''
          };
          setFormData(prev => ({ ...prev, categories: newCategories }));
        }
      }
    }
  };

  loadSubcategories();
}, [categories, formData.categories]);

  useEffect(() => {
    // Проверяем доступность Telegram Web App при монтировании
    if (!window.Telegram?.WebApp) {
      console.error('Telegram WebApp not available');
      toast.error('Please open this page in Telegram');
    } else {
      console.log('Telegram WebApp available:', {
        version: window.Telegram.WebApp.version,
        platform: window.Telegram.WebApp.platform,
        initData: window.Telegram.WebApp.initData ? 'present' : 'missing'
      });
    }
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

const fetchCountries = async () => {
  try {
    console.log("Fetching countries...");
    const response = await axios.get('/api/countries');
    console.log("Countries fetched successfully:", response.data.length);
    setCountries(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching countries:', error);
    return [];
  }
};

  const getSubCategories = (categoryId) => {
    const category = categories.find(c => c._id === categoryId);
    return category?.subCategories || [];
  };

const getSubSubCategories = (categoryId, subCategory) => {
  console.log("Getting subSubCategories for:", { categoryId, subCategory });
  
  // Если любой из параметров отсутствует, возвращаем пустой массив
  if (!categoryId || !subCategory) {
    console.log("Missing parameters, returning empty array");
    return [];
  }
  
  const category = categories.find(c => c._id === categoryId);
  console.log("Found category:", category?.name);
  
  if (!category || !category.subCategories) {
    console.log("Category or subcategories not found");
    return [];
  }
  
  // Ищем подкатегорию независимо от регистра
  const subCatLowerCase = subCategory.toLowerCase();
  const subCat = category.subCategories.find(sc => 
    sc.name.toLowerCase() === subCatLowerCase
  );
  
  console.log("Found subcategory:", subCat?.name);
  console.log("Available subSubCategories:", subCat?.subSubCategories);
  
  return subCat?.subSubCategories || [];
};

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData(prev => {
      const newFormData = { ...prev, [name]: newValue };
      logFormDataChanges({ [name]: newValue });
      return newFormData;
    });

    if (name === 'name' && !brand) {
      fetchLogoFromAPI(value);
      try {
        const response = await axios.get(`/api/brands/check/${value}`);
        if (response.data.exists) {
          setNameError('A brand with this name already exists');
        } else {
          setNameError('');
        }
      } catch (error) {
        console.error('Error checking brand name:', error);
      }
    }
  };

  const fetchLogoFromAPI = async (brandName) => {
    try {
      const response = await axios.get(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${encodeURIComponent(brandName)}`);
      if (response.data && response.data.length > 0) {
        const logos = response.data.map(company => company.logo).filter(logo => logo);
        setLogoOptions(logos);
      } else {
        setLogoOptions([]);
      }
    } catch (error) {
      console.error('Error fetching logo from API:', error);
      setLogoOptions([]);
    }
  };

const handleImageUpload = (file) => {
  if (!file) return;
  
  if (file.size > 100 * 1024) {
    toast.error('File size should not exceed 100KB');
    return;
  }
  
  if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
    toast.error('Only JPEG, WEBP and PNG files are allowed');
    return;
  }
  
  const reader = new FileReader();
  reader.onload = (e) => {
    const img = new Image();
    img.onload = function () {
      if (this.width > 500 || this.height > 500) {
        toast.error('The image size should not exceed 500x500 pixels');
      } else {
        setFormData(prev => ({ ...prev, logo: e.target.result }));
        setLogoPreview(e.target.result);
        toast.success('Logo successfully uploaded');
      }
    };
    img.src = e.target.result;
  };
  reader.readAsDataURL(file);
};

const { getRootProps, getInputProps, isDragActive } = useDropzone({
  accept: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/webp': ['.webp'], 'image/png': ['.png'] },
  maxSize: 100 * 1024, // 100KB
  onDrop: acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleImageUpload(acceptedFiles[0]);
    }
  }
});

const handleSubmit = async (e) => {
  e.preventDefault();
  console.log('=== Starting form submission ===');

  if (submitting) return;

  try {
    setSubmitting(true);

    // Добавляем https:// к URL перед отправкой
    const websiteWithProtocol = formData.website ? 
      (formData.website.startsWith('http') ? formData.website : `https://${formData.website}`) : '';
    
    const socialMediaWithProtocol = formData.socialMedia.map(sm => ({
      ...sm,
      url: sm.url ? (sm.url.startsWith('http') ? sm.url : `https://${sm.url}`) : ''
    }));

    // Получаем имена категорий и стран
    const selectedCategories = formData.categories.map(cat => {
      const categoryData = categories.find(c => c._id === cat.category);
      return {
        category: cat.category,  // Отправляем только ID категории
        subCategory: cat.subCategory,
        subSubCategory: cat.subSubCategory
      };
    });

    const selectedCountry = countries.find(c => c._id === formData.country);

    // Формируем данные для запроса
    const requestData = {
      brandData: {
        name: formData.name,
        logo: formData.logo,
        description: formData.description,
        categories: selectedCategories,
        countries: [formData.country], // Отправляем только ID страны
        website: websiteWithProtocol,
        socialMedia: socialMediaWithProtocol,
        global: formData.global,
        status: 'pending',
        frequency: 0.1, // Устанавливаем значение frequency
        activeDays: 30 // Устанавливаем срок действия 30 дней
      },
      emailData: {
        to: 'diesfit@gmail.com',
        userEmail: formData.email,
        userName: formData.name,
        brandName: formData.name,
        brandDescription: formData.description,
        categories: selectedCategories.map(cat => {
          const categoryData = categories.find(c => c._id === cat.category);
          return {
            category: categoryData?.name, // Для email используем имя категории
            subCategory: cat.subCategory,
            subSubCategory: cat.subSubCategory
          };
        }),
        country: selectedCountry?.name || 'Global',
        website: websiteWithProtocol,
        socialMedia: socialMediaWithProtocol
      }
    };

    // Передаем данные в колбэк onSave
    if (onSave) {
      await onSave(requestData);
    }

  } catch (error) {
    console.error('Form submission error:', error);
    toast.error(error.message || 'Error creating brand');
  } finally {
    setSubmitting(false);
  }
};

  const handleCategoryChange = (selectedCategory, index) => {
    console.log('Selected category:', selectedCategory);
    const category = categories.find(cat => cat._id === selectedCategory);
    if (category) {
      setFormData(prev => {
        const newCategories = [...prev.categories];
        newCategories[index] = { ...newCategories[index], category: selectedCategory };
        return { ...prev, categories: newCategories };
      });
      setSelectedCategories(prev => {
        const isAlreadySelected = prev.some(cat => cat._id === category._id);
        if (isAlreadySelected) {
          return prev.filter(cat => cat._id !== category._id);
        } else {
          return [...prev, category];
        }
      });
    }
  };

  const addSocialMedia = () => {
    if (selectedSocialMedia && !formData.socialMedia.find(sm => sm.platform === selectedSocialMedia)) {
      setFormData(prev => ({
        ...prev,
        socialMedia: [...prev.socialMedia, { platform: selectedSocialMedia, url: '' }]
      }));
      setSelectedSocialMedia('');
    }
  };

  const updateSocialMediaUrl = (platform, url) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.map(sm =>
        sm.platform === platform ? { ...sm, url } : sm
      )
    }));
  };

  const removeSocialMedia = (platform) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.filter(sm => sm.platform !== platform)
    }));
  };

  const handleSubCategoryChange = (selectedSubCategory) => {
    setFormData(prev => ({ ...prev, subCategory: selectedSubCategory }));
  };

  const getSubCategoryOptions = (categoryId) => {
    const selectedCategory = categories.find(cat => cat._id === categoryId);
    if (selectedCategory && selectedCategory.subCategories) {
      return selectedCategory.subCategories.map(subCategory => ({ value: subCategory, label: subCategory }));
    }
    return [];
  };

const inputClasses = "bg-black text-white border-zinc-800 border-2 placeholder-zinc-500";

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex space-x-4">
        <div className="w-2/3 space-y-2">
          <Label htmlFor="name" className="text-white text-xl">Brand Name</Label>
          <div>
            <Input
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter brand name"
              required
              className={`${inputClasses} ${nameError ? 'border-red-500' : ''}`}
            />
            {nameError && <p className="text-red-500 text-sm mt-1">{nameError}</p>}
          </div>
          {logoOptions.length > 0 && (
            <div>
              <Label className="text-white">Logo Options</Label>
              <div className="grid grid-cols-5 gap-2">
                {logoOptions.slice(0, 10).map((logo, index) => (
                  <div
                    key={index}
                    className="w-full h-20 rounded-lg border-2 border-black overflow-hidden flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setLogoPreview(logo);
                      setFormData(prev => ({ ...prev, logo }));
                    }}
                  >
                    <img src={logo} alt={`Logo option ${index + 1}`} className="max-w-full max-h-full object-contain" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="w-1/3">
          <Label className="text-white">Logo Preview</Label>
          <div className="relative">
            <div className="border-2 border-black rounded-full p-1">
              <div className="w-full max-w-[8rem] aspect-square rounded-full overflow-hidden flex items-center justify-center bg-gray-200">
                {logoPreview ? (
                  <img src={logoPreview} alt="Logo preview" className="max-w-full max-h-full object-contain" />
                ) : (
                  <p className="text-gray-500 text-sm">No logo</p>
                )}
              </div>
            </div>
            {logoPreview && (
              <button
                type="button"
                className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                onClick={() => {
                  setLogoPreview(null);
                  setFormData(prev => ({ ...prev, logo: null }));
                }}
              >
                <span className="sr-only">Remove logo</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>

<div className="mt-4">
  <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
    <div {...getRootProps()}>
      <input
  {...getInputProps()}
  onChange={(e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleImageUpload(e.target.files[0]);
    }
  }}
/>
      <Button 
        type="button"
        className={`w-full bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#c0d866] rounded-[30px] text-sm flex items-center justify-center gap-2 ${isDragActive ? 'bg-[#d1ef7a]' : ''}`}
      >
        <Upload size={16} />
        {isDragActive ? 'Drop Logo Here' : 'Upload Logo'}
      </Button>
    </div>
    <div className="text-sm text-gray-400 mt-1 md:mt-0">
      <p>• Image should be square (max 500×500px)</p>
      <p>• File size limit: 100KB</p>
      <p>• Formats: JPEG, WEBP</p>
    </div>
  </div>
  {isDragActive && (
    <div className="mt-4 bg-[#1a1a1a] border border-[#e0ff89] rounded-md p-3 text-center text-[#e0ff89]">
      <p>Drop logo file here to upload...</p>
    </div>
  )}
</div>

<div>
  <div className="flex justify-between items-center mb-1">
    <Label htmlFor="description" className="text-white text-xl">Description</Label>
    <span className="text-sm text-gray-400">
      {formData.description?.length || 0}/200
    </span>
  </div>
  <textarea
    id="description"
    name="description"
    value={formData.description}
    onChange={(e) => {
      // Ограничение в 200 символов
      if (e.target.value.length <= 200) {
        handleChange(e);
      }
    }}
    maxLength={200}
    className={`w-full p-2 rounded ${inputClasses}`}
    rows={4}
    placeholder="Enter brand description (max 200 characters)"
  />
  <p className="text-xs text-gray-400 mt-1">
    Briefly describe your brand and its main activities
  </p>
</div>

<div className="space-y-4">
<div className="space-y-4">
  <Label htmlFor="categories" className="text-white text-xl">Categories</Label>
  {formData.categories.map((cat, index) => (
    <div key={index} className="mb-6 border-2 border-[#282828] rounded-md p-5 bg-black">
      {/* Заголовок категории и кнопка удаления */}
      <div className="flex items-center justify-between mb-3 pb-2 border-b border-[#282828]">
        <h3 className="font-medium text-[#e0ff89]">
          {index === 0 ? "Main Category" : `Additional Category ${index}`}
        </h3>
        {!disableCategories && index > 0 && (
          <Button
            type="button"
            onClick={() => {
              const newCategories = formData.categories.filter((_, i) => i !== index);
              setFormData(prev => ({ ...prev, categories: newCategories }));
            }}
            className="bg-transparent hover:bg-[#282828] text-red-400 hover:text-red-300 rounded-md px-2 py-1 flex items-center text-xs"
          >
            <X size={14} className="mr-1" /> Remove
          </Button>
        )}
      </div>
      
      {/* Основная категория */}
      <div className="relative w-full mb-3">
        <select
          value={cat.category || ''}
          onChange={(e) => {
            if (disableCategories) return;
            const value = e.target.value;
            const newCategories = [...formData.categories];
            newCategories[index] = {
              category: value,
              subCategory: '',
              subSubCategory: ''
            };
            setFormData(prev => ({ ...prev, categories: newCategories }));
          }}
          disabled={disableCategories}
          className={`${inputClasses} w-full p-2 appearance-none rounded md:hidden ${disableCategories ? 'opacity-60 cursor-not-allowed' : ''}`}
        >
          <option value="" disabled>Select Category</option>
          {categories.map(category => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>
        
        {/* Кастомный Select для десктопа */}
        <div className="hidden md:block">
          <Select
            value={cat.category || ''}
            onValueChange={(value) => {
              if (disableCategories) return;
              const newCategories = [...formData.categories];
              newCategories[index] = {
                category: value, 
                subCategory: '',
                subSubCategory: ''
              };
              setFormData(prev => ({ ...prev, categories: newCategories }));
            }}
            disabled={disableCategories}
          >
            <SelectTrigger className={`w-full h-12 ${inputClasses} ${disableCategories ? 'opacity-60 cursor-not-allowed' : ''}`}>
              <SelectValue placeholder="Select Category">
                {categories.find(c => c._id === cat.category)?.name || "Select Category"}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100] border-2 border-[#282828]">
              {categories.map(category => (
                <SelectItem 
                  key={category._id} 
                  value={category._id}
                  className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
                >
                  {category.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
          
          {/* Стилизуем нативный селект для мобильных устройств */}
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none md:hidden">
            <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </div>
        </div>

{/* Подкатегория */}
{cat.category && (
  <div className="relative w-full mb-3">
    {/* Мобильный селект */}
    <select
      value={cat.subCategory || ''}
      onChange={(e) => {
        if (disableCategories) return;
        const value = e.target.value;
        const newCategories = [...formData.categories];
        newCategories[index] = {
          ...newCategories[index],
          subCategory: value,
          subSubCategory: ''
        };
        setFormData(prev => ({ ...prev, categories: newCategories }));
      }}
      disabled={disableCategories}
      className={`${inputClasses} w-full p-2 appearance-none rounded md:hidden ${disableCategories ? 'opacity-60 cursor-not-allowed' : ''}`}
    >
              <option value="" disabled>Select Sub-Category</option>
              {getSubCategories(cat.category).map(subCat => (
                <option key={subCat.name} value={subCat.name}>
                  {subCat.name}
                </option>
              ))}
            </select>
            
{/* Десктопный селект */}
<div className="hidden md:block">
  <Select
    value={cat.subCategory || ''}
    onValueChange={(value) => {
      if (disableCategories) return;
      const newCategories = [...formData.categories];
      newCategories[index] = {
        ...newCategories[index],
        subCategory: value,
        subSubCategory: ''
      };
      setFormData(prev => ({ ...prev, categories: newCategories }));
    }}
    disabled={disableCategories}
  >
    <SelectTrigger className={`w-full ${inputClasses} ${disableCategories ? 'opacity-60 cursor-not-allowed' : ''}`}>
      <SelectValue placeholder="Select Sub-Category">
        {cat.subCategory || "Select Sub-Category"}
      </SelectValue>
    </SelectTrigger>
    <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
      {getSubCategories(cat.category).map(subCat => (
        <SelectItem 
          key={subCat.name} 
          value={subCat.name} 
          className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
        >
          {subCat.name}
        </SelectItem>
      ))}
    </SelectContent>
  </Select>
</div>
            
            {/* Стрелка для мобильного селекта */}
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none md:hidden">
              <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </div>
          </div>
        )}

{/* Под-подкатегория */}
{cat.subCategory && (
  <div className="relative w-full">
    {console.log("Rendering subSubCategory selector for:", cat)}
    {console.log("subSubCategory options:", getSubSubCategories(cat.category, cat.subCategory))}
    
    {/* Мобильный селект */}
    <select
      value={cat.subSubCategory || ''}
      onChange={(e) => {
        if (disableCategories) return;
        const value = e.target.value;
        console.log("Selected subSubCategory:", value);
        const newCategories = [...formData.categories];
        newCategories[index] = {
          ...newCategories[index],
          subSubCategory: value
        };
        setFormData(prev => ({ ...prev, categories: newCategories }));
      }}
      disabled={disableCategories}
      className={`${inputClasses} w-full p-2 appearance-none rounded md:hidden ${disableCategories ? 'opacity-60 cursor-not-allowed' : ''}`}
    >
              <option value="" disabled>Select Sub-Sub-Category</option>
              {getSubSubCategories(cat.category, cat.subCategory).map(subSubCat => (
                <option key={subSubCat.name} value={subSubCat.name}>
                  {subSubCat.name}
                </option>
              ))}
            </select>
            
{/* Десктопный селект */}
<div className="hidden md:block">
  <Select
    value={cat.subSubCategory || ''}
    onValueChange={(value) => {
      if (disableCategories) return;
      const newCategories = [...formData.categories];
      newCategories[index] = {
        ...newCategories[index],
        subSubCategory: value
      };
      setFormData(prev => ({ ...prev, categories: newCategories }));
    }}
    disabled={disableCategories}
  >
    <SelectTrigger className={`w-full ${inputClasses} ${disableCategories ? 'opacity-60 cursor-not-allowed' : ''}`}>
      <SelectValue placeholder="Select Sub-Sub-Category">
        {cat.subSubCategory || "Select Sub-Sub-Category"}
      </SelectValue>
    </SelectTrigger>
    <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
      {getSubSubCategories(cat.category, cat.subCategory).map(subSubCat => (
        <SelectItem 
          key={subSubCat.name} 
          value={subSubCat.name}
          className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
        >
          {subSubCat.name}
        </SelectItem>
      ))}
    </SelectContent>
  </Select>
</div>
            
            {/* Стрелка для мобильного селекта */}
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none md:hidden">
              <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </div>
          </div>
        )}
      </div>
    ))}

{/* Кнопка добавления категории */}
{!disableCategories && (
  <Button
    type="button"
    onClick={() => {
      setFormData(prev => ({
        ...prev,
        categories: [...prev.categories, { category: '', subCategory: '', subSubCategory: '' }]
      }));
    }}
    className="w-full bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#c0d866] rounded-[30px] text-sm mt-4"
  >
    Add Category
  </Button>
)}
  </div>
</div>
    

<div>
  <Label htmlFor="country" className="text-white text-xl">Country</Label>
  <div className="relative w-full">
    {/* Для мобильных */}
    <div className="block md:hidden relative">
<select
  id="country"
  name="country"
  value={formData.country || ''}
  onChange={(e) => {
    console.log('Selected country:', e.target.value);
    setFormData(prev => ({ ...prev, country: e.target.value }));
  }}
  className={`${inputClasses} w-full p-2 appearance-none rounded`}
>
        <option value="" disabled>Select Country</option>
        {countries.map(country => (
          <option key={country._id} value={country._id}>
            {country.name}
          </option>
        ))}
      </select>

      {/* Добавляем стрелку вниз */}
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </div>
    </div>

    {/* Для десктопа */}
    <div className="hidden md:block">
      <Select
        id="country"
        name="country"
        value={formData.country || ''}
        onValueChange={(value) => {
          console.log('Selected country:', value);
          setFormData(prev => ({ ...prev, country: value }));
        }}
      >
<SelectTrigger className={`w-full ${inputClasses}`}>
  <SelectValue placeholder="Select Country">
    {formData.country ? 
      countries.find(c => c._id === formData.country)?.name || 
      countries.find(c => c._id.toString() === formData.country.toString())?.name || 
      "Select Country" 
      : 
      "Select Country"
    }
  </SelectValue>
</SelectTrigger>
        <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100] max-h-[200px] overflow-y-auto">
          {countries.map(country => (
<SelectItem 
  key={country._id} 
  value={country._id}
  className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
>
  {country.name}
</SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  </div>
</div>

<div>
  <Label htmlFor="website" className="text-white text-xl">Website</Label>
  <div className="relative">
    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <span className="text-gray-500">https://</span>
    </div>
    <Input
      id="website"
      name="website"
      value={formData.website ? formData.website.replace(/^https?:\/\//i, '') : ''}
      onChange={(e) => {
        // Удаляем https:// если пользователь его ввел вручную
        const value = e.target.value.replace(/^https?:\/\//i, '');
        setFormData(prev => ({ ...prev, website: value }));
      }}
      className={`${inputClasses} pl-[70px]`}
      placeholder="example.com"
    />
  </div>
</div>

<div className="space-y-2">
  <Label className="text-white" className="text-white text-xl">Social Media</Label>
  <div className="relative w-full">
    {/* Мобильный селект */}
    <select
      value={selectedSocialMedia}
      onChange={(e) => {
        const platform = e.target.value;
        if (platform && !formData.socialMedia.find(sm => sm.platform === platform)) {
          setFormData(prev => ({
            ...prev,
            socialMedia: [...prev.socialMedia, { platform, url: '' }]
          }));
        }
        setSelectedSocialMedia('');
      }}
      className={`${inputClasses} w-full p-2 appearance-none rounded`}
    >
      <option value="">Select Social Media</option>
      {Object.keys(socialIcons)
        .filter(platform => !formData.socialMedia.find(sm => sm.platform === platform))
        .map(platform => (
          <option key={platform} value={platform}>{platform}</option>
        ))}
    </select>
    
    {/* Стрелка для селекта */}
    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
      <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
      </svg>
    </div>
  </div>
<div className="space-y-2 mt-2">
  {formData.socialMedia.map(sm => {
    const Icon = socialIcons[sm.platform];
    return (
      <div key={sm.platform} className="flex items-center space-x-2">
        <Icon className="w-8 h-8 text-[#e0ff89]" />
        <div className="relative flex-grow">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span className="text-gray-500">https://</span>
          </div>
          <Input
            value={sm.url ? sm.url.replace(/^https?:\/\//i, '') : ''}
            onChange={(e) => {
              // Удаляем https:// если пользователь его ввел вручную
              const value = e.target.value.replace(/^https?:\/\//i, '');
              updateSocialMediaUrl(sm.platform, value);
            }}
            placeholder={`${sm.platform.toLowerCase()}.com/username`}
            className={`${inputClasses} pl-[70px]`}
          />
        </div>
        <Button
          type="button"
          onClick={() => removeSocialMedia(sm.platform)}
          className="p-2 bg-transparent hover:bg-transparent text-red-300 rounded-full hover:text-red-500"
        >
          <X size={16} />
        </Button>
      </div>
    );
  })}
</div>
</div>

<div className="mt-12 space-y-6">
  {/* Информационное сообщение */}
  <div className="text-center">
    <p className="text-gray-400 text-sm mb-6">
      After submitting your brand request, we will contact you via email to discuss further details.
    </p>
  </div>
  
  <div className="flex justify-end space-x-4">
    <Button
      type="button"
      onClick={onCancel}
      variant="outline"
      className="w-1/2 bg-red-500 text-white hover:bg-red-600 rounded-[30px] px-4 py-1 h-[34px] text-sm"
    >
      Cancel
    </Button>
    <Button
      type="submit"
      className="w-1/2 bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#c0d866] rounded-[30px] text-sm"
      disabled={submitting}
    >
      {submitting ? 'Sending request...' : 'Submit Brand'}
    </Button>
  </div>
</div>
    </form>
  );
};

export default AddBrand;