import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import axios from 'axios';
import toast from './CustomToast';

const BattleTimer = ({ remainingBattles, battleLimit }) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const [loading, setLoading] = useState(false);
  const [adTask, setAdTask] = useState(null);
  const [dailyAdLimit, setDailyAdLimit] = useState({ used: 0, total: 20 });

  useEffect(() => {
    const calculateNextUpdate = () => {
      const now = new Date();
      const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      const utcReset = new Date(utcNow);
      utcReset.setUTCHours(0, 0, 0, 0);

      if (utcNow >= utcReset) {
        utcReset.setDate(utcReset.getDate() + 1);
      }

      return utcReset.getTime() - utcNow.getTime();
    };

    const formatTimeRemaining = (milliseconds) => {
      if (!milliseconds) return '';
      const hours = Math.floor(milliseconds / (1000 * 60 * 60));
      const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const updateTimer = () => {
      if (remainingBattles === 0) {
        const timeLeft = calculateNextUpdate();
        setTimeRemaining(formatTimeRemaining(timeLeft));
      }
    };

    const timer = setInterval(updateTimer, 1000);
    updateTimer();

    return () => clearInterval(timer);
  }, [remainingBattles]);

  // Проверка дневного лимита просмотра рекламы при загрузке компонента
  useEffect(() => {
    const checkAdLimit = async () => {
      try {
        // Проверяем наличие токена
        const token = localStorage.getItem('token');
        const telegramInitData = localStorage.getItem('telegramInitData');
        
        if (!token) {
          console.log('No auth token found, skipping ad limit check');
          return;
        }
        
        // Получаем базовый URL API
        const apiBaseUrl = process.env.REACT_APP_API_URL || '';
        
        // Получаем текущий статус пользователя
        const userResponse = await axios.get(`${apiBaseUrl}/api/users/me`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-Telegram-Init-Data': telegramInitData || ''
          }
        });
        
        // Проверяем наличие информации о просмотрах рекламы
        if (userResponse.data && userResponse.data.adViewHistory) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          
          // Считаем количество просмотров за сегодня
          const todayViews = userResponse.data.adViewHistory.filter(view => 
            new Date(view.viewedAt) >= today
          ).length;
          
          console.log('Today ad views:', todayViews);
          
          // Обновляем состояние лимита
          setDailyAdLimit({
            used: todayViews,
            total: userResponse.data.adDailyLimit || 20 // Используем лимит из ответа или 20 по умолчанию
          });
        }
      } catch (error) {
        // Более детальное логирование ошибки
        if (error.response) {
          // Сервер ответил кодом ошибки (не 2xx)
          console.error('Ad limit check error response:', {
            status: error.response.status,
            data: error.response.data
          });
        } else if (error.request) {
          // Запрос был сделан, но ответа не получено
          console.error('Ad limit check no response received');
        } else {
          // Произошла ошибка при настройке запроса
          console.error('Ad limit check error:', error.message);
        }
        
        // В любом случае, устанавливаем значения по умолчанию, чтобы компонент продолжал работать
        console.log('Using default ad limit values');
      }
    };
    
    checkAdLimit();
  }, []);
  
  // Функция для получения задания с рекламой
const fetchAdTask = async () => {
  try {
    // Получаем токен и telegram данные для аутентификации
    const token = localStorage.getItem('token');
    const telegramInitData = localStorage.getItem('telegramInitData');
    
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    if (telegramInitData) {
      headers['X-Telegram-Init-Data'] = telegramInitData;
    }
    
    console.log('Fetching ad tasks with headers:', !!token, !!telegramInitData);
    
    // Отправляем запрос с заголовками аутентификации
    const response = await axios.get('/api/special-tasks', { headers });
    
    // Если данные не являются массивом, выводим ошибку и возвращаем null
    if (!Array.isArray(response.data)) {
      console.error('Expected array in response but got:', typeof response.data);
      return null;
    }
    
    // Фильтруем задания типа "ad" и активные
    const adTasks = response.data.filter(task => 
      task && task.taskType === 'ad' && 
      task.status === 'active'
    );
    
    if (adTasks.length === 0) {
      console.log('No active ad tasks found');
      return null;
    }
    
    // Обновляем значение дневного лимита из задания
    if (adTasks[0].dailyAdClickLimit) {
      setDailyAdLimit(prev => ({
        ...prev,
        total: adTasks[0].dailyAdClickLimit
      }));
    }
    
    return adTasks[0];
  } catch (error) {
    console.error('Error fetching ad tasks:', error);
    
    // Проверяем статус ошибки и выводим более подробную информацию
    if (error.response) {
      console.error('Error status:', error.response.status);
      console.error('Error data:', error.response.data);
    }
    
    return null;
  }
};
  
  // Функция для записи клика на задание
const registerTaskClick = async (taskId) => {
  try {
    // Удаляем apiBaseUrl, так как axios уже настроен в App.js
    const token = localStorage.getItem('token');
    const telegramInitData = localStorage.getItem('telegramInitData');
    
    if (!token) {
      throw new Error('Authentication token not found');
    }
    
    // Исправляем URL, удаляя дублирование /api/
    const clickResponse = await axios.post(`/api/special-tasks/${taskId}/click`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Telegram-Init-Data': telegramInitData || ''
      }
    });
    
    return clickResponse.data;
  } catch (error) {
    console.error('Error registering task click:', error);
    
    // Проверяем, если ошибка связана с лимитом просмотров
    if (error.response && error.response.data && error.response.data.errorType === 'DAILY_LIMIT_REACHED') {
      return { 
        success: false, 
        errorType: 'DAILY_LIMIT_REACHED',
        message: error.response.data.message,
        dailyLimit: error.response.data.dailyLimit,
        dailyViews: error.response.data.dailyViews
      };
    }
    
    return { success: false, errorType: 'REQUEST_ERROR', message: error.message };
  }
};
  
  // Функция получения награды
const claimReward = async (taskId, battlesReward) => {
  try {
    // Удаляем apiBaseUrl, так как axios уже настроен в App.js
    const token = localStorage.getItem('token');
    const telegramInitData = localStorage.getItem('telegramInitData');
    
    if (!token) {
      throw new Error('Authentication token not found');
    }
    
    // Исправляем URL, удаляя дублирование /api/
    const rewardResponse = await axios.post(`/api/special-tasks/${taskId}/claim`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Telegram-Init-Data': telegramInitData || ''
      }
    });
      
      if (rewardResponse.data.success) {
        // Обновляем информацию о количестве просмотров
        if (rewardResponse.data.adViewInfo) {
          setDailyAdLimit({
            used: rewardResponse.data.adViewInfo.dailyViews,
            total: rewardResponse.data.adViewInfo.dailyLimit
          });
        }
        
        // Показываем toast-уведомление
        toast.success(`You received ${battlesReward} battles!`, {
          duration: 3000,
          position: 'top-center',
          icon: '⚡'
        });
        
        // Обновляем глобальное состояние через событие
        window.dispatchEvent(new CustomEvent('balanceUpdate', {
          detail: {
            battles: rewardResponse.data.updatedUser.availableBattles
          }
        }));
        
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Error claiming reward:', error);
      return false;
    }
  };

  const handleWatchAd = async () => {
    try {
      setLoading(true);
      
      // Проверяем дневной лимит просмотров
      if (dailyAdLimit.used >= dailyAdLimit.total) {
        toast.error(`You've reached your daily limit of ${dailyAdLimit.total} ad views. Come back tomorrow!`);
        setLoading(false);
        return;
      }
      
      // Получаем задание с рекламой
      const task = await fetchAdTask();
      if (!task) {
        toast.error("No ad tasks available right now. Please try again later.");
        setLoading(false);
        return;
      }
      
      setAdTask(task);
      
      // Регистрируем клик на задание
      const clickResult = await registerTaskClick(task._id);
      
      if (!clickResult.success) {
        if (clickResult.errorType === 'DAILY_LIMIT_REACHED') {
          // Обновляем состояние лимита
          setDailyAdLimit({
            used: clickResult.dailyViews || dailyAdLimit.used,
            total: clickResult.dailyLimit || dailyAdLimit.total
          });
          
          toast.error(`You've reached your daily limit of ${dailyAdLimit.total} ad views. Come back tomorrow!`);
        } else {
          toast.error("Failed to start ad. Please try again.");
        }
        setLoading(false);
        return;
      }
      
      // Сохраняем ID задания для последующего получения награды
      const taskId = task._id;
      const battlesReward = task.battlesReward || 5;
      
      // Флаг для отслеживания статуса награды
      window.rewardGiven = false;
      
      // Глобальный обработчик получения награды
      const processReward = () => {
        if (window.rewardGiven) return;
        window.rewardGiven = true;
        
        console.log('Ad event detected - waiting for reward');
        
        // Получаем награду после просмотра рекламы с задержкой
        setTimeout(async () => {
          const success = await claimReward(taskId, battlesReward);
          
          if (!success) {
            toast.error("Failed to get reward. Please try again.");
          }
          
          setLoading(false);
        }, 500);
      };
      
      // Устанавливаем обработчики событий
      window.addEventListener('focus', processReward, { once: true });
      
      // Страховочный таймер - гарантированное получение награды
      setTimeout(() => {
        if (!window.rewardGiven) {
          console.log('Backup timer triggered - giving reward after timeout');
          processReward();
        }
      }, 15000);
      
      // Проверяем доступность контроллера рекламы
      if (typeof window.TelegramAdsController !== 'undefined') {
        try {
          // Переинициализируем контроллер рекламы, чтобы избежать ошибок
          if (typeof window.TelegramAdsController.initialize === 'function') {
            window.TelegramAdsController.initialize({
              pubId: "962094",
              appId: "1554",
              debug: true,
              disableBanner: true
            });
          }
          
          let adShown = false;
          
          // Показываем interstitial баннер, если доступен
          if (typeof window.TelegramAdsController.triggerInterstitialBanner === 'function') {
            try {
              await window.TelegramAdsController.triggerInterstitialBanner();
              adShown = true;
            } catch (adError) {
              console.error('Error displaying interstitial ad:', adError);
              // Продолжаем выполнение и пробуем другой метод
            }
          }
          
          // Если interstitial не сработал, пробуем native notification
          if (!adShown && typeof window.TelegramAdsController.triggerNativeNotification === 'function') {
            try {
              await window.TelegramAdsController.triggerNativeNotification();
              adShown = true;
            } catch (adError) {
              console.error('Error displaying native ad:', adError);
            }
          }
          
          // Если ни один метод не сработал, все равно даем награду
          if (!adShown) {
            toast.error("Failed to load ad, but you'll still get the reward!");
            // Короткая задержка перед выдачей награды
            setTimeout(processReward, 2000);
          }
        } catch (controllerError) {
          console.error('Error with TelegramAdsController:', controllerError);
          toast.error("Ad controller error, but you'll still get the reward!");
          // Выдаем награду даже при ошибке контроллера
          setTimeout(processReward, 2000);
        }
      } else {
        // Контроллер недоступен, но все равно даем награду
        toast.error("Ad controller is unavailable, but you'll still get the reward!");
        setTimeout(processReward, 2000);
      }
    } catch (error) {
      console.error('Error in handleWatchAd:', error);
      setLoading(false);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="flex items-center justify-between w-full space-x-2">
        {/* Прогресс бар слева */}
        <div className="flex-grow bg-zinc-400 rounded-full h-6 relative">
          <div
            className="bg-white h-6 rounded-full transition-all duration-300"
            style={{ width: `${(remainingBattles / battleLimit) * 100}%` }}
          ></div>
          <div className="absolute inset-0 flex items-center justify-center text-xs text-zinc-900 font-medium">
            {remainingBattles > 0 ? (
              `${remainingBattles} / ${battleLimit} Battles`
            ) : (
              <div className="flex items-center space-x-1">
                <span>Next battles in:</span>
                <span className="font-bold">{timeRemaining}</span>
              </div>
            )}
          </div>
        </div>
        
        {/* Кнопка справа */}
        {remainingBattles === 0 && (
          <Button
            onClick={handleWatchAd}
            disabled={loading || dailyAdLimit.used >= dailyAdLimit.total}
            className="h-6 border-2 border-[#e0ff89] bg-transparent text-[#e0ff89] hover:bg-[#e0ff89]/10 rounded-[30px] px-3 text-sm font-medium whitespace-nowrap"
            title={dailyAdLimit.used >= dailyAdLimit.total ? `Daily limit reached (${dailyAdLimit.used}/${dailyAdLimit.total})` : ""}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-[#e0ff89] mr-1"></div>
                <span>Loading...</span>
              </div>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Get Battles
              </>
            )}
          </Button>
        )}
      </div>
    </>
  );
};

export default React.memo(BattleTimer);