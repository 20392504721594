import React, { useEffect, useState, useMemo } from 'react';
import { Avatar, AvatarImage, AvatarFallback } from "./ui/avatar";
import { 
  Crown, Star, Gem, Gamepad2, HandMetal, Magnet, Lollipop, 
  PawPrint, Pill, Spade, Zap, Rocket, Shell, Bitcoin, 
  Cookie, Donut, Banana, Bone, Dices, Ghost, Heart, 
  Puzzle, Skull, Swords, AudioWaveform, Drama, Brain, 
  Radiation, Webhook 
} from "lucide-react";
import BoringAvatar from 'boring-avatars';

// Глобальный кеш частиц - сохраняется между рендерами
const particlesCache = new Map();

// Функция для генерации фоновых частиц
const generateBackgroundParticles = (config) => {
  if (!config || !config.enabled) return [];
  
  // Создаем стабильный набор частиц
  return Array.from({ length: config.density * 5 || 10 }).map((_, i) => {
    const uniqueId = `bg-particle-${i}-${Math.random().toString(36).substr(2, 9)}`;
    
    return {
      id: uniqueId,
      style: {
        left: `${Math.random() * 80 + 10}%`,
        top: `${Math.random() * 80 + 10}%`,
        color: config.color || '#FFFFFF',
        opacity: Math.random() * 0.7 + 0.3,
        animation: `float${i % 3} ${Math.random() * 3 + 2}s infinite ease-in-out`,
        transform: `translateY(0px) scale(${Math.random() * 0.4 + 0.3})`,
        width: '12px',
        height: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none'
      },
      type: config.type
    };
  });
};

// Функция для генерации частиц поверх
const generateForegroundParticles = (config) => {
  if (!config || !config.enabled) return [];
  
  return Array.from({ length: (config.density || 1) * 5 }).map((_, i) => {
    const uniqueId = `fg-particle-${i}-${Math.random().toString(36).substr(2, 9)}`;
    
    return {
      id: uniqueId,
      style: {
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
        color: config.color || '#FFFFFF',
        opacity: Math.random() * 0.7 + 0.3,
        animation: `float${i % 3} ${Math.random() * 2 + 1}s infinite ease-in-out`,
        transform: `translateY(0px) scale(${Math.random() * 0.5 + 0.5})`,
        width: '10px',
        height: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      type: config.type
    };
  });
};

// Основной компонент UserAvatar
const UserAvatar = ({ user, currentRank, ranks = [], size = 'default' }) => {
  const [rank, setRank] = useState(null);
  
  // Размеры в зависимости от параметра size
  const dimensions = useMemo(() => {
    switch(size) {
      case 'sm':
        return { avatar: 'w-10 h-10', badgeSize: 16 };
      case 'lg':
        return { avatar: 'w-24 h-24', badgeSize: 32 };
      case 'xl':
        return { avatar: 'w-32 h-32', badgeSize: 40 };
      default: // 'default'
        return { avatar: 'w-16 h-16', badgeSize: 24 };
    }
  }, [size]);
  
  // Находим ранг пользователя среди всех рангов
  useEffect(() => {
    if (currentRank && ranks && ranks.length > 0) {
      const foundRank = ranks.find(r => r.name === currentRank);
      setRank(foundRank);
    }
  }, [currentRank, ranks]);
  
  // Получаем и кешируем частицы
  const { bgParticles, fgParticles } = useMemo(() => {
    if (!rank || !rank.name) return { bgParticles: [], fgParticles: [] };
    
    // Проверяем, есть ли в кеше частицы для данного ранга
    if (particlesCache.has(rank.name)) {
      return particlesCache.get(rank.name);
    }
    
    // Если нет, генерируем новые частицы
    const bgParticles = generateBackgroundParticles(rank.effects?.backgroundParticles);
    const fgParticles = generateForegroundParticles(rank.effects?.particles);
    
    // Сохраняем в кеш
    const particles = { bgParticles, fgParticles };
    particlesCache.set(rank.name, particles);
    
    return particles;
  }, [rank?.name]); // Зависимость только от имени ранга, не от всего объекта
  
  // Создаем стиль для аватара
  const avatarBorderStyle = useMemo(() => {
    // Значения по умолчанию, если rank равен null или не имеет avatarBorder
    const defaultStyle = {
      border: '2px solid #FFFFFF',
    };
    
    if (!rank || !rank.avatarBorder) {
      return defaultStyle;
    }
    
    const border = rank.avatarBorder;
    const style = {};
    
    if (border.style === 'gradient' && border.gradient?.colors?.length) {
      style.background = `linear-gradient(${border.gradient.direction || 'to right'}, ${border.gradient.colors.join(', ')})`;
      style.padding = border.width || 2;
      style.display = 'flex';
      style.alignItems = 'center';
      style.justifyContent = 'center';
      style.borderRadius = '9999px';
    } else {
      style.border = `${border.width || 2}px ${border.style || 'solid'} ${border.color || '#FFFFFF'}`;
    }
    
    // Добавляем свечение, если оно включено
    if (border.halo?.enabled) {
      style.boxShadow = `0 0 ${border.halo.intensity}px ${border.halo.color || '#FFFFFF'}`;
    }
    
    return style;
  }, [rank]);
  
  // CSS класс для анимации границы
  const borderAnimationClass = useMemo(() => {
    if (!rank || !rank.avatarBorder || !rank.avatarBorder.animation?.enabled) {
      return '';
    }
    
    switch(rank.avatarBorder.animation.type) {
      case 'pulse':
        return 'animate-pulse-infinite';
      case 'rotate':
        return 'animate-rotate-infinite';
      case 'shine':
        return 'animate-shine-infinite';
      default:
        return '';
    }
  }, [rank]);
  
  // Настройки эффекта ореола
  const haloEffect = useMemo(() => {
    const defaultEffect = { enabled: false };
    
    if (!rank || !rank.avatarBorder?.halo?.enabled) {
      return defaultEffect;
    }
    
    const halo = rank.avatarBorder.halo;
    let haloClass = '';
    
    if (halo.animation === 'pulse') {
      haloClass = 'animate-pulse-infinite';
    } else if (halo.animation === 'wave') {
      haloClass = 'animate-wave-infinite';
    } else if (halo.animation === 'rings') {
      haloClass = 'animate-rings-infinite';
    }
    
    return {
      enabled: true,
      style: {
        boxShadow: `0 0 ${halo.intensity * 3}px ${halo.intensity}px ${halo.color || '#e0ff89'}`,
      },
      className: haloClass
    };
  }, [rank]);
  
  // Функция для получения компонента иконки бейджа
  const getBadgeIcon = (type, props) => {
    switch(type) {
      case 'crown': return <Crown {...props} />;
      case 'star': return <Star {...props} />;
      case 'gem': return <Gem {...props} />;
      case 'gamepad-2': return <Gamepad2 {...props} />;
      case 'hand-metal': return <HandMetal {...props} />;
      case 'magnet': return <Magnet {...props} />;
      case 'lollipop': return <Lollipop {...props} />;
      case 'paw-print': return <PawPrint {...props} />;
      case 'pill': return <Pill {...props} />;
      case 'spade': return <Spade {...props} />;
      case 'zap': return <Zap {...props} />;
      case 'rocket': return <Rocket {...props} />;
      case 'shell': return <Shell {...props} />;
      case 'bitcoin': return <Bitcoin {...props} />;
      case 'cookie': return <Cookie {...props} />;
      case 'donut': return <Donut {...props} />;
      case 'banana': return <Banana {...props} />;
      case 'bone': return <Bone {...props} />;
      case 'dices': return <Dices {...props} />;
      case 'ghost': return <Ghost {...props} />;
      case 'heart': return <Heart {...props} />;
      case 'puzzle': return <Puzzle {...props} />;
      case 'skull': return <Skull {...props} />;
      case 'swords': return <Swords {...props} />;
      case 'audio-waveform': return <AudioWaveform {...props} />;
      case 'drama': return <Drama {...props} />;
      case 'brain': return <Brain {...props} />;
      case 'radiation': return <Radiation {...props} />;
      case 'webhook': return <Webhook {...props} />;
      default: return <Star {...props} />;
    }
  };
  
  // Компонент для отображения бейджа
  const BadgeComponent = useMemo(() => {
    if (!rank || !rank.badge?.enabled) {
      return null;
    }
    
    const badge = rank.badge;
    
    // Класс для анимации
    const animationClass = badge.animation?.enabled ? 
      badge.animation.type === 'pulse' ? 'animate-pulse-infinite' : 
      badge.animation.type === 'rotate' ? 'animate-rotate-infinite' : 
      badge.animation.type === 'bounce' ? 'animate-bounce-infinite' : '' 
      : '';
    
    // Определяем точные значения для позиционирования
    let positionClass = '';
    let transformClass = '';
    
    switch(badge.position) {
      case 'top-right':
        positionClass = 'top-0 right-0';
        transformClass = 'translate-x-1/3 -translate-y-1/3';
        break;
      case 'top-left':
        positionClass = 'top-0 left-0';
        transformClass = '-translate-x-1/3 -translate-y-1/3';
        break;
      case 'bottom-right':
        positionClass = 'bottom-0 right-0';
        transformClass = 'translate-x-1/3 translate-y-1/3';
        break;
      case 'bottom-left':
        positionClass = 'bottom-0 left-0';
        transformClass = '-translate-x-1/3 translate-y-1/3';
        break;
      default:
        positionClass = 'top-0 right-0';
        transformClass = 'translate-x-1/3 -translate-y-1/3';
    }

    // Создаем стили для фона и тени
    const backgroundStyle = badge.background?.enabled 
      ? { backgroundColor: badge.background.color, padding: '3px', borderRadius: '50%' }
      : {};
      
    const shadowStyle = badge.shadow?.enabled
      ? { boxShadow: `0 0 5px ${badge.shadow.color}` }
      : {};
    
    return (
      <div 
        className={`absolute ${positionClass} ${animationClass} z-20 transform ${transformClass}`}
        style={{ 
          color: badge.color || '#e0ff89',
          ...backgroundStyle,
          ...shadowStyle,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {badge.type === 'custom' && badge.customImage ? (
          <img 
            src={badge.customImage} 
            alt="Badge" 
            style={{
              width: `${badge.size || dimensions.badgeSize}px`,
              height: `${badge.size || dimensions.badgeSize}px`
            }}
          />
        ) : (
          getBadgeIcon(badge.type, { size: badge.size || dimensions.badgeSize })
        )}
      </div>
    );
  }, [rank, dimensions.badgeSize]);
  
  // Эффект для имени пользователя (для стилизации текста)
  const nameEffect = useMemo(() => {
    if (!rank || !rank.effects?.nameEffect?.enabled) {
      return {};
    }
    
    const effect = rank.effects.nameEffect;
    const style = {
      color: effect.textColor || '#FFFFFF' // Добавлен цвет текста по умолчанию
    };
    
    switch(effect.type) {
      case 'shadow':
        style.textShadow = `0 0 ${effect.intensity}px ${effect.color}`;
        break;
      case 'glow':
        style.textShadow = `0 0 ${effect.intensity * 2}px ${effect.color}, 0 0 ${effect.intensity}px ${effect.color}`;
        break;
      case 'gradient':
        if (effect.gradientColors?.length >= 2) {
          style.background = `linear-gradient(to right, ${effect.gradientColors.join(', ')})`;
        } else {
          style.background = `linear-gradient(to right, ${effect.textColor || 'white'}, ${effect.color})`;
        }
        style.WebkitBackgroundClip = 'text';
        style.WebkitTextFillColor = 'transparent';
        style.backgroundClip = 'text';
        style.textFillColor = 'transparent';
        break;
      case 'highlight':
        style.backgroundColor = effect.color;
        style.color = effect.textColor || 'black'; // Использование цвета текста 
        style.padding = '0 8px';
        style.borderRadius = '4px';
        break;
    }
    
    return style;
  }, [rank]);

  // Эффект для фона (если применяется к фону позади аватара)
  const getBackgroundEffectStyle = (effect) => {
    if (!effect || !effect.enabled) return {};
    
    const style = {};
    
    switch(effect.type) {
      case 'blur':
        style.backdropFilter = `blur(${effect.intensity}px)`;
        style.WebkitBackdropFilter = `blur(${effect.intensity}px)`;
        break;
      case 'overlay':
        if (effect.useGradient && effect.gradientColors?.length >= 2) {
          style.background = `linear-gradient(${effect.gradientDirection || 'to bottom'}, ${effect.gradientColors.join(', ')})`;
        } else {
          style.backgroundColor = effect.color || 'rgba(0,0,0,0.5)';
          style.opacity = effect.intensity / 10;
        }
        break;
      case 'glow':
        style.boxShadow = `inset 0 0 ${effect.intensity * 5}px ${effect.color || '#FFFFFF'}`;
        style.opacity = 0.8;
        break;
      case 'tint':
        style.backgroundColor = effect.color || 'rgba(0,0,0,0.5)';
        style.opacity = effect.intensity / 10;
        style.mixBlendMode = 'color';
        break;
      case 'gradient':
        if (effect.gradientColors?.length >= 2) {
          style.background = `linear-gradient(${effect.gradientDirection || 'to bottom'}, ${effect.gradientColors.join(', ')})`;
        } else {
          style.background = `linear-gradient(to bottom, transparent, ${effect.color || 'rgba(0,0,0,0.8)'})`;
        }
        break;
    }
    
    // Для анимации shine нужен специальный размер фона
    if (effect.animation?.enabled && effect.animation.type === 'shine') {
      style.backgroundSize = '200% auto';
    }
    
    return style;
  };

  // Рендеринг фоновых частиц из кеша
  const renderBackgroundParticles = () => {
    if (!rank?.effects?.backgroundParticles?.enabled || !bgParticles || bgParticles.length === 0) return null;
    
    return (
      <div className="absolute inset-0 z-5 overflow-hidden pointer-events-none rounded-full">
        {bgParticles.map(particle => (
          <div
            key={particle.id}
            className="absolute animate-particle"
            style={particle.style}
          >
            {particle.type === 'sparkles' && (
              <svg width="10" height="10" viewBox="0 0 24 24" fill="currentColor" className="animate-spin-slow">
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            )}
            {particle.type === 'bubbles' && (
              <svg width="8" height="8" viewBox="0 0 24 24" fill="currentColor" className="animate-pulse-slow">
                <circle cx="12" cy="12" r="10" />
              </svg>
            )}
            {particle.type === 'stars' && (
              <svg width="12" height="12" viewBox="0 0 24 24" fill="currentColor" className="animate-twinkle">
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            )}
            {particle.type === 'fire' && (
              <svg width="12" height="12" viewBox="0 0 24 24" fill="currentColor" className="animate-flicker">
                <path d="M12 2C10.97 2 9.94 2.16 8.92 2.5C9.61 3.43 10 4.63 10 5.9C10 8.15 8.21 10 6 10C5.07 10 4.21 9.72 3.5 9.22C3.18 10.13 3 11.05 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 6.42 16.97 2 12 2Z" />
              </svg>
            )}
          </div>
        ))}
      </div>
    );
  };
  
  // Рендеринг частиц поверх из кеша
  const renderForegroundParticles = () => {
    if (!rank?.effects?.particles?.enabled || !fgParticles || fgParticles.length === 0) return null;
    
    return (
      <div className="absolute inset-0 z-10 overflow-hidden pointer-events-none">
        {fgParticles.map(particle => (
          <div
            key={particle.id}
            className="absolute animate-particle"
            style={particle.style}
          >
            {particle.type === 'sparkles' && (
              <svg width="10" height="10" viewBox="0 0 24 24" fill="currentColor">
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            )}
            {particle.type === 'bubbles' && (
              <svg width="8" height="8" viewBox="0 0 24 24" fill="currentColor">
                <circle cx="12" cy="12" r="10" />
              </svg>
            )}
            {particle.type === 'stars' && (
              <svg width="12" height="12" viewBox="0 0 24 24" fill="currentColor">
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            )}
            {particle.type === 'fire' && (
              <svg width="12" height="12" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2C10.97 2 9.94 2.16 8.92 2.5C9.61 3.43 10 4.63 10 5.9C10 8.15 8.21 10 6 10C5.07 10 4.21 9.72 3.5 9.22C3.18 10.13 3 11.05 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 6.42 16.97 2 12 2Z" />
              </svg>
            )}
          </div>
        ))}
      </div>
    );
  };

  // Если нет ранга или пользователя, отображаем базовый аватар
  if (!rank || !user) {
    return (
      <Avatar className={dimensions.avatar}>
        {user?.avatar ? (
          <AvatarImage
            src={user.avatar}
            alt="User Avatar"
            className="object-cover"
          />
        ) : (
          <div className="w-full h-full">
            <BoringAvatar
              size="100%"
              name={user?.displayName || user?.firstName || user?.username || 'User'}
              variant="beam"
              colors={["#a1a6aa", "#bd928b", "#de7571", "#ff4e44", "#282634"]}
            />
          </div>
        )}
        <AvatarFallback>
          <div className="w-full h-full">
            <BoringAvatar
              size="100%"
              name={user?.displayName || user?.firstName || user?.username || 'User'}
              variant="beam"
              colors={["#a1a6aa", "#bd928b", "#de7571", "#ff4e44", "#282634"]}
            />
          </div>
        </AvatarFallback>
      </Avatar>
    );
  }

  // Отрисовка аватара с эффектами
  return (
    <div className="relative group overflow-visible" style={{ zIndex: 10 }}>
      {/* Основной контейнер аватара */}
      <div className="relative">
        {/* Фоновые частицы из кеша */}
        {renderBackgroundParticles()}
        
        {/* Эффект фона */}
        {rank?.effects?.backgroundEffect?.enabled && (
          <div className="absolute inset-0 z-4 rounded-full overflow-hidden">
            {/* Используем отдельные условия для каждой анимации */}
            {rank.effects.backgroundEffect.animation?.enabled && rank.effects.backgroundEffect.animation.type === 'pulse' && (
              <div 
                className="absolute inset-0 w-full h-full animate-pulse-bg"
                style={getBackgroundEffectStyle(rank.effects.backgroundEffect)}
              ></div>
            )}
            {rank.effects.backgroundEffect.animation?.enabled && rank.effects.backgroundEffect.animation.type === 'fade' && (
              <div 
                className="absolute inset-0 w-full h-full animate-fade-bg"
                style={getBackgroundEffectStyle(rank.effects.backgroundEffect)}
              ></div>
            )}
            {rank.effects.backgroundEffect.animation?.enabled && rank.effects.backgroundEffect.animation.type === 'wave' && (
              <div 
                className="absolute inset-0 w-full h-full animate-wave-bg"
                style={getBackgroundEffectStyle(rank.effects.backgroundEffect)}
              ></div>
            )}
            {rank.effects.backgroundEffect.animation?.enabled && rank.effects.backgroundEffect.animation.type === 'shine' && (
              <div 
                className="absolute inset-0 w-full h-full animate-shine-bg"
                style={getBackgroundEffectStyle(rank.effects.backgroundEffect)}
              ></div>
            )}
            {(!rank.effects.backgroundEffect.animation?.enabled || !rank.effects.backgroundEffect.animation.type) && (
              <div 
                className="absolute inset-0 w-full h-full"
                style={getBackgroundEffectStyle(rank.effects.backgroundEffect)}
              ></div>
            )}
          </div>
        )}
        
        {/* Ореол */}
        {haloEffect.enabled && (
          <div 
            className={`absolute inset-0 rounded-full z-3 ${haloEffect.className || ''}`}
            style={{ 
              ...haloEffect.style,
              transform: 'scale(1.0)',
            }}
          ></div>
        )}
        
        {/* Аватар с границей */}
        <div 
          className={`rounded-full ${borderAnimationClass || ''} flex items-center justify-center overflow-hidden z-6 relative`} 
          style={avatarBorderStyle}
        >
          <Avatar className={dimensions.avatar}>
            {user?.avatar ? (
              <AvatarImage
                src={user.avatar}
                alt="User Avatar"
                className="object-cover"
              />
            ) : (
              <div className="w-full h-full">
                <BoringAvatar
                  size="100%"
                  name={user?.displayName || user?.firstName || user?.username || 'User'}
                  variant="beam"
                  colors={["#a1a6aa", "#bd928b", "#de7571", "#ff4e44", "#282634"]}
                />
              </div>
            )}
            <AvatarFallback>
              <div className="w-full h-full">
                <BoringAvatar
                  size="100%"
                  name={user?.displayName || user?.firstName || user?.username || 'User'}
                  variant="beam"
                  colors={["#a1a6aa", "#bd928b", "#de7571", "#ff4e44", "#282634"]}
                />
              </div>
            </AvatarFallback>
          </Avatar>
        </div>
        
        {/* Частицы поверх из кеша */}
        {renderForegroundParticles()}
        
        {/* Бейдж пользователя */}
        {BadgeComponent}
      </div>
      
      {/* Добавляем глобальные стили для анимаций */}
      <style jsx global>{`
        @keyframes float0 {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-10px) rotate(5deg); }
        }
        
        @keyframes float1 {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-15px) rotate(-5deg); }
        }
        
        @keyframes float2 {
          0%, 100% { transform: translateY(0px) rotate(0deg); }
          50% { transform: translateY(-8px) rotate(10deg); }
        }
        
        @keyframes pulse-bg {
          0%, 100% { opacity: 0.7; }
          50% { opacity: 0.4; }
        }
        
        @keyframes fade-bg {
          0%, 100% { opacity: 0.7; }
          50% { opacity: 0; }
        }
        
        @keyframes wave-bg {
          0% { transform: translateY(0); }
          50% { transform: translateY(-5px); }
          100% { transform: translateY(0); }
        }
        
        @keyframes shine-bg {
          0%, 100% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
        }

        .animate-pulse-bg {
          animation: pulse-bg 3s ease-in-out infinite;
        }
        
        .animate-fade-bg {
          animation: fade-bg 3s ease-in-out infinite;
        }
        
        .animate-wave-bg {
          animation: wave-bg 3s ease-in-out infinite;
        }
        
        .animate-shine-bg {
          animation: shine-bg 3s linear infinite;
          background-size: 200% auto;
        }
        
        @keyframes twinkle {
          0%, 100% { opacity: 1; transform: scale(1); }
          50% { opacity: 0.7; transform: scale(1.2); }
        }
        
        @keyframes flicker {
          0%, 100% { opacity: 1; }
          25% { opacity: 0.8; }
          50% { opacity: 0.9; }
          75% { opacity: 0.7; }
        }
        
        @keyframes spin-slow {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
        
        @keyframes pulse-slow {
          0%, 100% { transform: scale(1); opacity: 1; }
          50% { transform: scale(1.1); opacity: 0.8; }
        }
        
        .animate-twinkle {
          animation: twinkle 3s infinite ease-in-out;
        }
        
        .animate-flicker {
          animation: flicker 2s infinite;
        }
        
        .animate-spin-slow {
          animation: spin-slow 10s linear infinite;
        }
        
        .animate-pulse-slow {
          animation: pulse-slow 3s infinite ease-in-out;
        }
        
        .animate-particle {
          will-change: transform;
          transform-style: preserve-3d;
        }
        
        @keyframes pulse-infinite {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.5; }
        }
        
        @keyframes rotate-infinite {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        
        @keyframes shine-infinite {
          0%, 100% { opacity: 0.5; }
          25% { opacity: 0.8; }
          50% { opacity: 1; }
          75% { opacity: 0.8; }
        }
        
        @keyframes bounce-infinite {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-5px); }
        }
        
        @keyframes wave-infinite {
          0%, 100% { transform: scale(1.15); }
          25% { transform: scale(1.2) rotate(5deg); }
          50% { transform: scale(1.25); }
          75% { transform: scale(1.2) rotate(-5deg); }
        }
        
        @keyframes rings-infinite {
          0% { transform: scale(1.15); opacity: 1; }
          50% { transform: scale(1.5); opacity: 0; }
          51% { transform: scale(1.15); opacity: 1; }
          100% { transform: scale(1.15); opacity: 1; }
        }
        
        @keyframes titleShine {
          0% { background-position: -200% center; }
          100% { background-position: 200% center; }
        }
        
        .animate-pulse-infinite {
          animation: pulse-infinite 2s infinite;
        }
        
        .animate-rotate-infinite {
          animation: rotate-infinite 3s linear infinite;
        }
        
        .animate-shine-infinite {
          animation: shine-infinite 3s infinite;
        }
        
        .animate-bounce-infinite {
          animation: bounce-infinite 1.5s infinite;
        }
        
        .animate-wave-infinite {
          animation: wave-infinite 3s infinite;
        }
        
        .animate-rings-infinite {
          animation: rings-infinite 3s infinite;
        }
      `}</style>
    </div>
  );
};

export default React.memo(UserAvatar);