import React, { useState, useEffect } from 'react';
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { MultiSelect } from "../ui/MultiSelect";
import { Label } from "../ui/label";
import ImageUpload from '../ui/ImageUpload';
import axios from 'axios';
import toast from '../CustomToast';
import { X } from 'lucide-react';
import { SiApplemusic, SiBehance, SiDiscord, SiDribbble, SiFacebook, SiGithub, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiQuora, SiReddit, SiSnapchat, SiSoundcloud, SiSpotify, SiTelegram, SiTencentqq, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiWhatsapp, SiX, SiYoutube, SiSinaweibo } from 'react-icons/si';

const socialIcons = {
  AppleMusic: SiApplemusic,
  Behance: SiBehance,
  Discord: SiDiscord,
  Dribbble: SiDribbble,
  Facebook: SiFacebook,
  GitHub: SiGithub,
  Instagram: SiInstagram,
  KakaoTalk: SiKakaotalk,
  Line: SiLine,
  LinkedIn: SiLinkedin,
  Medium: SiMedium,
  Ok: SiOdnoklassniki,
  Onlyfans: SiOnlyfans,
  Patreon: SiPatreon,
  Pinterest: SiPinterest,
  QQ: SiTencentqq,
  Quora: SiQuora,
  Reddit: SiReddit,
  Snapchat: SiSnapchat,
  SoundCloud: SiSoundcloud,
  Spotify: SiSpotify,
  Telegram: SiTelegram,
  Threads: SiThreads,
  TikTok: SiTiktok,
  Tumblr: SiTumblr,
  Twitch: SiTwitch,
  Viber: SiViber,
  Vk: SiVk,
  WeChat: SiWechat,
  Weibo: SiSinaweibo,
  WhatsApp: SiWhatsapp,
  X: SiX,
  YouTube: SiYoutube,
};

const BrandForm = ({ brand, onSave, onCancel, categories, countries }) => {
  const [formData, setFormData] = useState({
    name: '',
    logo: '',
    description: '',
    categories: [{ category: '', subCategory: '' }],
    countries: [],
    website: '',
    socialMedia: [],
    global: true,
    frequency: 0.1,
    activeDays: -1,
    status: 'active',
    owner: ''
  });
  const [users, setUsers] = useState([]);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState('');
  const [logoPreview, setLogoPreview] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [searchQuery, setSearchQuery] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [countrySearchQuery, setCountrySearchQuery] = useState('');

  useEffect(() => {
    if (brand) {
      console.log('Received brand data:', brand);
      console.log('Brand categories:', brand.categories);
      console.log('Brand owner:', brand.owner);
      console.log('Categories available:', categories);

      const formattedCategories = Array.isArray(brand.categories)
        ? brand.categories.map(cat => {
          console.log('Processing category:', cat);
          return {
            category: cat.category?._id || cat.category || '',
            subCategory: cat.subCategory || '',
            subSubCategory: cat.subSubCategory || ''
          };
        })
        : [{ category: '', subCategory: '', subSubCategory: '' }];

      const formattedCountries = Array.isArray(brand.countries)
        ? brand.countries.map(country => {
          console.log('Processing country:', country);
          if (typeof country === 'object' && country !== null) {
            console.log('Country is object:', country);
            console.log('  -> Name:', country.name);
            console.log('  -> ID:', country._id);
            console.log('  -> Code:', country.code);
            return country._id;
          } else {
            console.log('Country is string/ID:', country);
            // Проверяем, можем ли мы найти страну по ID
            const foundCountry = countries.find(c => c._id === country);
            console.log('  -> Found country object:', foundCountry);
            return country;
          }
        }).filter(id => !!id)  // Добавляем фильтрацию пустых ID
        : [];

      // Убеждаемся, что все страны существуют в доступном списке
      const validCountries = formattedCountries.filter(countryId =>
        countries.some(c => c._id === countryId)
      );

      console.log('Formatted countries after processing:', validCountries);

      let formattedOwner;
      console.log('Processing owner:', brand.owner);
      if (brand.owner === null) {
        formattedOwner = 'admin';
        console.log('Owner is null, setting as admin');
      } else if (typeof brand.owner === 'object' && brand.owner !== null) {
        formattedOwner = brand.owner._id;
        console.log('Owner is object, setting ID:', formattedOwner);
      } else {
        formattedOwner = brand.owner;
        console.log('Owner is string/ID:', formattedOwner);
      }

      // Удаляем префиксы https:// из website и социальных медиа
      const processedWebsite = brand.website ? brand.website.replace(/^https?:\/\//i, '') : '';

      const processedSocialMedia = Array.isArray(brand.socialMedia)
        ? brand.socialMedia.map(sm => ({
          ...sm,
          url: sm.url ? sm.url.replace(/^https?:\/\//i, '') : ''
        }))
        : [];

      const newFormData = {
        ...brand,
        website: processedWebsite,
        socialMedia: processedSocialMedia,
        categories: formattedCategories,
        countries: formattedCountries,
        owner: formattedOwner
      };

      console.log('Formatted owner:', formattedOwner);
      console.log('Setting form data:', newFormData);
      setFormData(newFormData);
      setLogoPreview(brand.logo || '');

      console.log('Formatted categories:', formattedCategories);

      // Находим соответствующие категории для выбора в форме
      const selectedCats = formattedCategories
        .map(cat => {
          const foundCategory = categories.find(c => c._id === cat.category);
          console.log('Finding category in available list:', cat.category, 'Found:', foundCategory);
          return foundCategory;
        })
        .filter(Boolean);

      setSelectedCategories(selectedCats);
      console.log('Selected categories set to:', selectedCats);
    } else {
      // Сброс формы, если brand не предоставлен
      const initialFormData = {
        name: '',
        logo: '',
        description: '',
        categories: [{ category: '', subCategory: '', subSubCategory: '' }],
        countries: [],
        website: '',
        socialMedia: [],
        global: false,
        frequency: 0.1,
        activeDays: -1,
        status: 'active',
        owner: 'admin' // Начальное значение для нового бренда
      };

      console.log('Initializing new form with owner:', initialFormData.owner);
      setFormData(initialFormData);
      setSelectedCategories([]);
      setLogoPreview('');
    }
    setIsLoading(false);
  }, [brand, categories]);

  // Добавьте этот useEffect для отладки
  useEffect(() => {
    console.log('formData updated:', formData);
    console.log('Current owner:', formData.owner);
  }, [formData]);

  useEffect(() => {
    if (formData) {
      console.log('Current formData:', formData);
      console.log('Selected categories:', selectedCategories);
    }
  }, [formData, selectedCategories]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error when retrieving users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Добавим функцию расчета даты окончания
  const calculateExpirationDate = (days, startDate = null) => {
    if (days === 0) return 'Бренд неактивен (expired)';
    if (days < 0) return 'Неограниченный срок';

    // Если есть существующая дата создания/начала, используем ее
    // Иначе используем текущую дату
    const baseDate = startDate || (formData.createdAt ? new Date(formData.createdAt) : new Date());

    const expDate = new Date(baseDate);
    expDate.setDate(expDate.getDate() + days);

    return expDate.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  // Добавим useEffect для обновления даты при изменении formData.activeDays
  useEffect(() => {
    // Если у бренда есть дата создания и activeDays
    if (formData.createdAt && formData.activeDays > 0) {
      setExpirationDate(calculateExpirationDate(formData.activeDays, new Date(formData.createdAt)));
    } else {
      setExpirationDate(calculateExpirationDate(formData.activeDays));
    }
  }, [formData.activeDays, formData.createdAt]);

  // Модифицируем обработчик изменения activeDays
  const handleActiveDaysChange = (e) => {
    // Разрешаем ввод 0
    const inputValue = e.target.value;
    const newValue = inputValue === '0' ? 0 : (parseInt(inputValue) || -1);

    // Всегда явно определяем статус на основе количества дней
    const newStatus = newValue === 0 ? 'expired' : 'active';

    console.log(`Setting activeDays=${newValue}, status=${newStatus}`);

    setFormData(prev => ({
      ...prev,
      activeDays: newValue,
      status: newStatus // Всегда устанавливаем статус в соответствии с днями
    }));

    // Обновляем расчетную дату окончания
    if (newValue === 0) {
      setExpirationDate('Бренд неактивен (expired)');
    } else {
      setExpirationDate(calculateExpirationDate(newValue, formData.createdAt ? new Date(formData.createdAt) : null));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name === 'name') {
      fetchLogoFromAPI(value);
    }
  };

  const [logoOptions, setLogoOptions] = useState([]);

  const fetchLogoFromAPI = async (brandName) => {
    try {
      const response = await axios.get(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${encodeURIComponent(brandName)}`);
      if (response.data && response.data.length > 0) {
        const logos = response.data.map(company => company.logo).filter(logo => logo);
        setLogoOptions(logos);
      } else {
        setLogoOptions([]);
      }
    } catch (error) {
      console.error('Error fetching logo from API:', error);
      setLogoOptions([]);
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await axios.post('/api/upload-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      const imageUrl = response.data.imageUrl;
      setLogoPreview(imageUrl);
      setFormData(prev => ({ ...prev, logo: imageUrl }));
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleCountriesChange = (selectedCountries) => {
    console.log('handleCountriesChange called with:', selectedCountries);
    console.log('Previous countries state:', formData.countries);

    // Проверим, что selectedCountries - это массив
    if (!Array.isArray(selectedCountries)) {
      console.error('selectedCountries is not an array:', selectedCountries);
      selectedCountries = [];
    }

    // Нормализуем выбранные страны - убедимся, что это массив ID
    const normalizedCountries = selectedCountries.map(country => {
      if (typeof country === 'object' && country !== null && country._id) {
        return country._id;
      }
      return country;
    });

    console.log('Normalized countries:', normalizedCountries);

    setFormData(prev => {
      const newFormData = { ...prev, countries: normalizedCountries };
      console.log('Updated formData.countries:', newFormData.countries);
      return newFormData;
    });
  };

  const handleCategoryChange = (index, selectedCategory) => {
    console.log('Category change attempt:', index, selectedCategory);
    setFormData(prev => {
      const newCategories = [...prev.categories];
      newCategories[index] = { ...newCategories[index], category: selectedCategory };
      console.log('New categories after change:', newCategories);
      return { ...prev, categories: newCategories };
    });
  };

  const handleSubCategoryChange = (index, selectedSubCategory) => {
    console.log('Subcategory change attempt:', index, selectedSubCategory);
    setFormData(prev => {
      const newCategories = [...prev.categories];
      newCategories[index] = { ...newCategories[index], subCategory: selectedSubCategory };
      console.log('New categories after subcategory change:', newCategories);
      return { ...prev, categories: newCategories };
    });
  };

  const addCategory = () => {
    setFormData(prev => ({
      ...prev,
      categories: [...prev.categories, { category: '', subCategory: '' }]
    }));
  };

  const removeCategory = (index) => {
    setFormData(prev => ({
      ...prev,
      categories: prev.categories.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Original formData:', formData);

    // Проверяем, нужно ли обновить статус на основе даты активности
    let currentStatus = formData.activeDays === 0 ? 'expired' :
      formData.activeDays > 0 ? 'active' :
        'active'; // Для дней < 0 (бесконечный срок)

    // Подготавливаем website с префиксом https://
    const processedWebsite = formData.website ?
      (formData.website.startsWith('http') ? formData.website : `https://${formData.website}`) :
      '';

    // Подготавливаем socialMedia с префиксом https://
    const processedSocialMedia = formData.socialMedia.map(sm => ({
      ...sm,
      url: sm.url ? (sm.url.startsWith('http') ? sm.url : `https://${sm.url}`) : ''
    }));

    // Проверяем владельца и выводим информацию
    console.log('Owner before saving:', formData.owner);

    // Убедимся, что owner сохраняется правильно
    let owner = formData.owner;
    if (typeof owner === 'object' && owner !== null && owner._id) {
      owner = owner._id;
    }

    // Фильтруем категории и добавляем необходимые данные
    const dataToSave = {
      ...formData,
      website: processedWebsite,
      socialMedia: processedSocialMedia,
      categories: formData.categories.filter(cat => cat.category && cat.subCategory),
      createdAt: formData.createdAt,
      approvedAt: formData.approvedAt,
      lastActiveUpdate: formData.lastActiveUpdate || new Date().toISOString(),
      status: currentStatus,
      owner: owner // Явно указываем сохраняемое значение
    };

    console.log('Data being saved with status:', dataToSave.status);
    console.log('Data being saved with owner:', dataToSave.owner);
    await onSave(dataToSave);
  };

  const getCategoryOptions = () => {
    return categories.map(category => ({ value: category._id, label: category.name }));
  };

  const getSubCategoryOptions = (categoryId) => {
    const selectedCategory = categories.find(cat => cat._id === categoryId);
    if (selectedCategory && selectedCategory.subCategories) {
      return selectedCategory.subCategories.map(subCategory => ({ value: subCategory, label: subCategory }));
    }
    return [];
  };

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const handleSocialMediaChange = (e) => {
    setSelectedSocialMedia(e.target.value);
  };

  const addSocialMedia = () => {
    if (selectedSocialMedia && !formData.socialMedia.find(sm => sm.platform === selectedSocialMedia)) {
      setFormData(prev => ({
        ...prev,
        socialMedia: [...prev.socialMedia, { platform: selectedSocialMedia, url: '' }]
      }));
      setSelectedSocialMedia('');
    }
  };

  const updateSocialMediaUrl = (platform, url) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.map(sm =>
        sm.platform === platform ? { ...sm, url } : sm
      )
    }));
  };

  const removeSocialMedia = (platform) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.filter(sm => sm.platform !== platform)
    }));
  };

  useEffect(() => {
    if (formData.categories.length === 0) {
      setFormData(prev => ({
        ...prev,
        categories: [{ category: '', subCategory: '' }]
      }));
    }
  }, [formData.categories]);

  useEffect(() => {
    console.log('Current formData:', formData);
  }, [formData]);

  const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!formData) {
    return <div>No data available</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex space-x-4">
        <div className="w-2/3 space-y-2">
          <Label htmlFor="name" className="text-white">Brand Name</Label>
          <Input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter brand name"
            required
            className={inputClasses}
          />
          {logoOptions.length > 0 && (
            <div>
              <Label className="text-white">Logo Options</Label>
              <div className="grid grid-cols-5 gap-2">
                {logoOptions.slice(0, 10).map((logo, index) => (
                  <div
                    key={index}
                    className="w-full h-20 rounded-lg border-2 border-black overflow-hidden flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setLogoPreview(logo);
                      setFormData(prev => ({ ...prev, logo }));
                    }}
                  >
                    <img src={logo} alt={`Logo option ${index + 1}`} className="max-w-full max-h-full object-contain" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="w-1/3">
          <Label className="text-white">Logo Preview</Label>
          <div className="relative">
            <div className="border-2 border-black rounded-full p-1">
              <div className="w-32 h-32 rounded-full overflow-hidden flex items-center justify-center bg-gray-200">
                {logoPreview ? (
                  <img src={logoPreview} alt="Logo preview" className="max-w-full max-h-full object-contain" />
                ) : (
                  <p className="text-gray-500 text-sm">No logo</p>
                )}
              </div>
            </div>
            {logoPreview && (
              <button
                type="button"
                className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                onClick={() => {
                  setLogoPreview(null);
                  setFormData(prev => ({ ...prev, logo: null }));
                }}
              >
                <span className="sr-only">Remove logo</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <Label className="text-white">Upload Logo</Label>
        <ImageUpload onImageUpload={handleImageUpload} />
      </div>

      <div className="space-y-2">
        <Label htmlFor="description" className="text-white">Brand Description</Label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Enter brand description"
          className={`w-full p-2 rounded ${inputClasses}`}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="activeDays" className="text-white text-xl">Active Days</Label>
        <div className="flex flex-col">
          <div className="flex items-center space-x-3">
            <Input
              id="activeDays"
              name="activeDays"
              type="number"
              value={formData.activeDays}
              onChange={handleActiveDaysChange}
              placeholder="Enter number of active days (-1 for unlimited)"
              className={inputClasses}
            />
            <Button
              type="button"
              className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] px-4 py-1 h-[34px] whitespace-nowrap"
              onClick={() => {
                // Если activeDays = 0, просто обновляем статус на expired
                if (formData.activeDays === 0) {
                  setFormData(prev => ({
                    ...prev,
                    status: 'expired',
                    lastActiveUpdate: new Date().toISOString()
                  }));
                  setExpirationDate('Бренд неактивен (expired)');
                  toast.success('Brand status set to expired. Save to apply changes.');
                  return;
                }

                // Пересчитываем срок от текущей даты для других значений
                const now = new Date();
                const newExpDate = calculateExpirationDate(formData.activeDays, now);
                setExpirationDate(newExpDate);

                // Обновляем дату создания и approvedAt на текущую, и меняем статус на active
                setFormData(prev => ({
                  ...prev,
                  createdAt: now.toISOString(),
                  approvedAt: now.toISOString(),
                  lastActiveUpdate: now.toISOString(),
                  status: formData.activeDays > 0 ? 'active' : 'active' // Для -1 и >0 статус active
                }));

                // Показываем уведомление
                toast.success('Date updated and brand ' + (formData.activeDays > 0 ? 'activated' : 'set to unlimited') + '. Save to apply changes.');
              }}
            >
              Update current date
            </Button>
          </div>

          {/* Информация о дате окончания */}
          <div className="mt-2">
            <div className="text-sm text-gray-400">
              {formData.activeDays > 0 ? (
                <>
                  <span>End date: </span>
                  <span className="font-semibold text-[#e0ff89]">{expirationDate}</span>
                  {formData.createdAt && (
                    <span className="ml-2 text-xs">
                      (from the date of creation: {new Date(formData.createdAt).toLocaleDateString('ru-RU')})
                    </span>
                  )}
                </>
              ) : formData.activeDays === 0 ? (
                <span className="font-semibold text-red-400">Brand is inactive (expired)</span>
              ) : (
                <span>The brand is active without a time limit</span>
              )}
            </div>
          </div>
        </div>
        <p className="text-sm text-gray-400">Enter -1 for unlimited active days</p>
      </div>

      <div className="space-y-2">
        <Label htmlFor="frequency" className="text-white">Appearance Frequency</Label>
        <Input
          id="frequency"
          type="number"
          name="frequency"
          value={formData.frequency}
          onChange={(e) => setFormData(prev => ({ ...prev, frequency: parseFloat(e.target.value) }))}
          placeholder="Enter frequency (0-1)"
          min="0"
          max="1"
          step="0.1"
          className={inputClasses}
        />
      </div>

      <div className="space-y-4">
        <Label htmlFor="categories" className="text-white text-xl">Categories</Label>
        {formData.categories.map((cat, index) => (
          <div key={index} className="mb-6 border-2 border-[#282828] rounded-md p-5 bg-black">
            {/* Заголовок категории и кнопка удаления */}
            <div className="flex items-center justify-between mb-3 pb-2 border-b border-[#282828]">
              <h3 className="font-medium text-[#e0ff89]">
                {index === 0 ? "Main Category" : `Additional Category ${index}`}
              </h3>
              {index > 0 && (
                <Button
                  type="button"
                  onClick={() => {
                    const newCategories = formData.categories.filter((_, i) => i !== index);
                    setFormData(prev => ({ ...prev, categories: newCategories }));
                    console.log('Category removed');
                  }}
                  className="bg-transparent hover:bg-[#282828] text-red-400 hover:text-red-300 rounded-md px-2 py-1 flex items-center text-xs"
                >
                  <X size={14} className="mr-1" /> Remove
                </Button>
              )}
            </div>

            {/* Основная категория */}
            <div className="relative w-full mb-3">
              <Select
                value={cat.category || ''}
                onValueChange={(value) => {
                  console.log('Category selection changed to:', value);
                  const newCategories = [...formData.categories];
                  newCategories[index] = { category: value, subCategory: '', subSubCategory: '' };
                  setFormData(prev => ({ ...prev, categories: newCategories }));
                }}
              >
                <SelectTrigger className={`w-full h-12 ${inputClasses}`}>
                  <SelectValue placeholder="Select Category">
                    {categories.find(c => c._id === cat.category)?.name || "Select Category"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100] border-2 border-[#282828]">
                  {categories.map(category => (
                    <SelectItem
                      key={category._id}
                      value={category._id}
                      className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
                    >
                      {category.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {/* Подкатегория */}
            {cat.category && (
              <div className="relative w-full mb-3">
                <Select
                  value={cat.subCategory || ''}
                  onValueChange={(value) => {
                    console.log('Subcategory selection changed to:', value);
                    const newCategories = [...formData.categories];
                    newCategories[index] = { ...newCategories[index], subCategory: value, subSubCategory: '' };
                    setFormData(prev => ({ ...prev, categories: newCategories }));
                  }}
                >
                  <SelectTrigger className={`w-full ${inputClasses}`}>
                    <SelectValue placeholder="Select Sub-Category">
                      {cat.subCategory || "Select Sub-Category"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
                    {categories.find(c => c._id === cat.category)?.subCategories?.map((subCat, idx) => (
                      <SelectItem
                        key={idx}
                        value={subCat.name}
                        className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
                      >
                        {subCat.name}
                      </SelectItem>
                    )) || []}
                  </SelectContent>
                </Select>
              </div>
            )}

            {/* Под-подкатегория */}
            {cat.subCategory && (
              <div className="relative w-full">
                <Select
                  value={cat.subSubCategory || ''}
                  onValueChange={(value) => {
                    console.log('Sub-subcategory selection changed to:', value);
                    const newCategories = [...formData.categories];
                    newCategories[index] = { ...newCategories[index], subSubCategory: value };
                    setFormData(prev => ({ ...prev, categories: newCategories }));
                  }}
                >
                  <SelectTrigger className={`w-full ${inputClasses}`}>
                    <SelectValue placeholder="Select Sub-Sub-Category">
                      {cat.subSubCategory || "Select Sub-Sub-Category"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
                    {(() => {
                      const selectedCategory = categories.find(c => c._id === cat.category);
                      const selectedSubCategory = selectedCategory?.subCategories?.find(
                        sub => sub.name === cat.subCategory
                      );
                      return selectedSubCategory?.subSubCategories?.map((subSubCat, idx) => (
                        <SelectItem
                          key={idx}
                          value={subSubCat.name}
                          className="hover:bg-[#e0ff89] hover:text-black focus:bg-[#e0ff89] focus:text-black data-[state=checked]:bg-[#e0ff89] data-[state=checked]:text-black"
                        >
                          {subSubCat.name}
                        </SelectItem>
                      )) || []
                    })()}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
        ))}

        {/* Кнопка добавления новой категории */}
        <Button
          type="button"
          onClick={() => {
            setFormData(prev => ({
              ...prev,
              categories: [...prev.categories, { category: '', subCategory: '', subSubCategory: '' }]
            }));
            console.log('New category added');
          }}
          className="w-full bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#c0d866] rounded-[30px] text-sm mt-4"
        >
          Add Category
        </Button>
      </div>

      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <Label htmlFor="global" className="text-white">Global Brand</Label>
          <input
            type="checkbox"
            id="global"
            checked={formData.global}
            onChange={(e) => setFormData(prev => ({ ...prev, global: e.target.checked }))}
            className="form-checkbox h-5 w-5 text-[#e0ff89] bg-black border-[#282828] rounded"
          />
        </div>

<div className="space-y-2">
  <Label className="text-white">Countries</Label>
  <Select
    value=""
    onValueChange={(value) => {
      if (!value) return;

      const countryExists = formData.countries.includes(value);
      if (countryExists) return;

      const updatedCountries = [...formData.countries, value];
      setFormData(prev => ({
        ...prev,
        countries: updatedCountries
      }));
      console.log('Country added:', value);
      
      // Сбрасываем поиск после добавления страны
      setCountrySearchQuery('');
    }}
  >
    <SelectTrigger className={`w-full ${inputClasses}`}>
      <SelectValue placeholder="Add a country" />
    </SelectTrigger>
    <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-50 max-h-60 overflow-y-auto">
      {/* Поле поиска стран */}
      <div className="p-2 sticky top-0 bg-[#0f1014] border-b border-[#282828]">
        <input
          type="text"
          placeholder="Search countries..."
          value={countrySearchQuery || ''}
          onChange={(e) => setCountrySearchQuery(e.target.value.toLowerCase())}
          className="w-full p-2 bg-black text-[#d3d3d3] border-[#282828] border-[2px] rounded focus:outline-none focus:border-[#e0ff89]"
        />
      </div>
      
      {/* Отфильтрованный список стран */}
      {countries
        .filter(country => 
          !countrySearchQuery || 
          country.name.toLowerCase().includes(countrySearchQuery)
        )
        .map(country => (
          <SelectItem key={country._id} value={country._id}>
            <div className="flex items-center">
              <div className="w-5 h-4 mr-2 overflow-hidden rounded-sm flex-shrink-0">
                <img
                  src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                  alt={`${country.name} flag`}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    console.log(`Failed to load flag for ${country.code}`);
                    e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_None.svg';
                  }}
                />
              </div>
              <span>{country.name}</span>
            </div>
          </SelectItem>
        ))}
    </SelectContent>
  </Select>

  {/* Отображение выбранных стран */}
  {formData.countries && formData.countries.length > 0 && (
    <div className="flex flex-wrap gap-2 mt-2">
      {formData.countries.map(countryId => {
        const country = countries.find(c => c._id === countryId);
        if (!country) return null;

        return (
          <div key={countryId} className="inline-flex items-center bg-[#15171c] text-white rounded px-2 py-1">
            <div className="w-5 h-4 mr-2 overflow-hidden rounded-sm flex-shrink-0">
              <img
                src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                alt={`${country.name} flag`}
                className="w-full h-full object-cover"
                onError={(e) => {
                  console.log(`Failed to load flag for ${country.code}`);
                  e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_None.svg';
                }}
              />
            </div>
            <span>{country.name}</span>
            <button
              type="button"
              className="ml-2 text-white hover:text-red-500"
              onClick={() => {
                const updatedCountries = formData.countries.filter(id => id !== countryId);
                setFormData(prev => ({
                  ...prev,
                  countries: updatedCountries
                }));
                console.log('Country removed:', countryId);
              }}
            >
              <X size={14} />
            </button>
          </div>
        );
      })}
    </div>
  )}
</div>


        <div className="space-y-2">
          <Label htmlFor="owner" className="text-white">Brand Owner</Label>
          {console.log('Current owner value when rendering select:', formData.owner)}
          {console.log('Available users:', users)}
          <Select
            value={typeof formData.owner === 'object' && formData.owner !== null
              ? formData.owner._id
              : formData.owner || ''}
            onValueChange={(value) => {
              console.log('Owner selection changed to:', value);

              // Дополнительная проверка и логирование
              console.log('Previous owner:', formData.owner);
              console.log('New owner selected:', value);

              // Обновляем состояние
              setFormData(prev => {
                const newData = { ...prev, owner: value };
                console.log('Updated formData with new owner:', newData);
                return newData;
              });

              // Проверяем, обновилось ли состояние
              setTimeout(() => {
                console.log('Verify owner was updated in formData:', formData.owner);
              }, 0);
            }}
          >
            <SelectTrigger className={`w-full ${inputClasses}`}>
              <SelectValue>
                {(() => {
                  console.log('Rendering owner value in trigger:', formData.owner);
                  if (formData.owner === 'admin') {
                    return 'Admin';
                  }
                  // Если owner это объект, используем его username
                  if (typeof formData.owner === 'object' && formData.owner !== null) {
                    return formData.owner.username || formData.owner.telegramUsername || 'Unknown User';
                  }
                  // Иначе ищем пользователя по ID
                  const foundUser = users.find(u => u._id === formData.owner);
                  console.log('Found user for owner:', foundUser);
                  return foundUser
                    ? (foundUser.username || foundUser.telegramUsername)
                    : 'Select Owner';
                })()}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
              <div className="p-2">
                <input
                  type="text"
                  placeholder="Search users..."
                  value={searchQuery}
                  onChange={(e) => {
                    const query = e.target.value.toLowerCase();
                    setSearchQuery(query);
                    const filtered = users.filter(user =>
                      (user.username && user.username.toLowerCase().includes(query)) ||
                      (user.telegramUsername && user.telegramUsername.toLowerCase().includes(query))
                    );
                    setFilteredUsers(filtered);
                    console.log('Filtered users:', filtered);
                  }}
                  className="w-full p-2 mb-2 bg-[#0f1014] text-[#d3d3d3] border border-[#282828] rounded"
                />
              </div>
              <div className="max-h-48 overflow-y-auto">
                <SelectItem value="admin">Admin</SelectItem>
                {filteredUsers.map(user => (
                  <SelectItem key={user._id} value={user._id}>
                    {user.username || user.telegramUsername || 'Unnamed User'}
                  </SelectItem>
                ))}
              </div>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="website" className="text-white">Website</Label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span className="text-gray-500">https://</span>
          </div>
          <Input
            id="website"
            name="website"
            value={formData.website || ''}
            onChange={(e) => {
              // Удаляем https:// если пользователь его ввел вручную
              const value = e.target.value.replace(/^https?:\/\//i, '');
              setFormData(prev => ({ ...prev, website: value }));
            }}
            placeholder="example.com"
            className={`${inputClasses} pl-[70px]`}
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label className="text-white text-xl">Social Media</Label>
        <div className="relative w-full">
          <select
            value={selectedSocialMedia}
            onChange={(e) => {
              const platform = e.target.value;
              if (platform && !formData.socialMedia.find(sm => sm.platform === platform)) {
                setFormData(prev => ({
                  ...prev,
                  socialMedia: [...prev.socialMedia, { platform, url: '' }]
                }));
                setSelectedSocialMedia('');
              }
            }}
            className={`${inputClasses} w-full p-2 appearance-none rounded`}
          >
            <option value="">Select Social Media</option>
            {Object.keys(socialIcons)
              .filter(platform => !formData.socialMedia.find(sm => sm.platform === platform))
              .map(platform => (
                <option key={platform} value={platform}>{platform}</option>
              ))}
          </select>

          {/* Стрелка для селекта */}
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </div>
        </div>
        <div className="space-y-2 mt-2">
          {formData.socialMedia.map(sm => {
            const Icon = socialIcons[sm.platform];
            return (
              <div key={sm.platform} className="flex items-center space-x-2">
                <Icon className="w-8 h-8 text-[#e0ff89]" />
                <div className="relative flex-grow">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <span className="text-gray-500">https://</span>
                  </div>
                  <Input
                    value={sm.url}
                    onChange={(e) => {
                      // Удаляем https:// если пользователь его ввел вручную
                      const value = e.target.value.replace(/^https?:\/\//i, '');
                      updateSocialMediaUrl(sm.platform, value);
                    }}
                    placeholder={`${sm.platform.toLowerCase()}.com/username`}
                    className={`${inputClasses} pl-[70px]`}
                  />
                </div>
                <Button
                  type="button"
                  onClick={() => removeSocialMedia(sm.platform)}
                  className="p-2 bg-transparent hover:bg-transparent text-red-300 rounded-full hover:text-red-500"
                >
                  <X size={16} />
                </Button>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-end mt-6 space-x-2">
        <Button
          type="button"
          onClick={onCancel}
          variant="outline"
          className="bg-black text-white border-[#282828] hover:bg-white hover:text-black transition-colors duration-200 rounded-[30px] py-1 px-4"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
        >
          Save Brand
        </Button>
      </div>
    </form>
  );
};

export default BrandForm;