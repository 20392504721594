import axios from 'axios';
import { format } from 'date-fns';

// Функция для получения цвета платформы
const getPlatformColor = (platform) => {
  const colors = {
    AppleMusic: "#FC3C44",
    Behance: "#1769FF",
    Discord: "#5865F2",
    Dribbble: "#EA4C89",
    Facebook: "#1877F2",
    GitHub: "#181717",
    Instagram: "#E4405F",
    KakaoTalk: "#FAE100",
    Line: "#00B900",
    LinkedIn: "#0A66C2",
    Medium: "#000000",
    Odnoklassniki: "#EE8208",
    OnlyFans: "#00AFF0",
    Patreon: "#FF424D",
    Pinterest: "#BD081C",
    QQ: "#EB1923",
    Quora: "#B92B27",
    Reddit: "#FF4500",
    Snapchat: "#FFFC00",
    SoundCloud: "#FF3300",
    Spotify: "#1DB954",
    Telegram: "#26A5E4",
    Threads: "#000000",
    TikTok: "#000000",
    Tumblr: "#36465D",
    Twitch: "#9146FF",
    Viber: "#7360F2",
    Vk: "#0077FF",
    WeChat: "#07C160",
    Weibo: "#E6162D",
    WhatsApp: "#25D366",
    X: "#000000",
    YouTube: "#FF0000"
  };

  return colors[platform] || "#808080"; // Серый цвет по умолчанию
};

// Получение общей статистики бренда
export const getBrandStatistics = async (brandId, params = {}) => {
  try {
    const { startDate, endDate } = params;
    let url = `/api/brands/${brandId}/statistics`;

    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append('startDate', startDate);
    if (endDate) queryParams.append('endDate', endDate);

    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    console.log('=== getBrandStatistics Service START ===');
    console.log('Request URL:', url);

    const response = await axios.get(url);
    console.log('Raw API Response:', response.data);

    if (!response.data.battleHistory) {
      console.warn('battleHistory missing in API response');
    }

    // Создаем массив дат между startDate и endDate
    const start = new Date(startDate);
    const end = new Date(endDate);
    let currentDate = new Date(start);

    // Создаем пустую статистику
    const dailyStats = {};

    // Получаем список всех платформ из данных
    const platforms = (response.data.socialMediaClicks || []).map(p => {
      if (!p || !p.platform) return null;
      return p.platform;
    }).filter(Boolean);

    while (currentDate <= end) {
      const dateKey = format(currentDate, 'yyyy-MM-dd');

      // Создаем базовую структуру
      const dayStats = {
        date: dateKey,
        name: format(currentDate, 'MMM dd'),
        totalBattles: 0,
        wins: 0,
        losses: 0,
        websiteClicks: 0,
      };

      // Динамически добавляем поля для каждой соц сети
      platforms.forEach(platform => {
        if (platform) {
          dayStats[`${platform.toLowerCase()}Clicks`] = 0;
        }
      });

      dailyStats[dateKey] = dayStats;
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Обрабатываем клики по веб-сайту
    if (response.data.websiteClicks?.history) {
      response.data.websiteClicks.history.forEach(click => {
        const clickDate = new Date(click.timestamp);
        const dateKey = format(clickDate, 'yyyy-MM-dd');

        if (!dailyStats[dateKey]) {
          dailyStats[dateKey] = {
            date: dateKey,
            websiteClicks: 0
          };
        }

        dailyStats[dateKey].websiteClicks++;
      });
    }

    // Добавляем общее количество кликов в статистику
    Object.values(dailyStats).forEach(day => {
      if (day.websiteClicks === 0) {
      }
    });

    // Обработка кликов по соц. сетям
    if (response.data.socialMediaClicks) {
      // Сначала инициализируем поля для всех платформ
      Object.keys(dailyStats).forEach(dateKey => {
        platforms.forEach(platform => {
          if (platform) {
            dailyStats[dateKey][`${platform.toLowerCase()}Clicks`] = 0;
          }
        });
      });

      // Теперь добавляем клики
      response.data.socialMediaClicks.forEach(platform => {
        if (platform?.platform && platform.history) {
          const platformKey = `${platform.platform.toLowerCase()}Clicks`;
          platform.history.forEach(click => {
            const clickDate = new Date(click.timestamp);
            if (clickDate >= start && clickDate <= end) {
              const dateKey = format(clickDate, 'yyyy-MM-dd');

              if (dailyStats[dateKey]) {
                if (dailyStats[dateKey][platformKey] === undefined) {
                  dailyStats[dateKey][platformKey] = 0;
                }
                dailyStats[dateKey][platformKey]++;
              }
            }
          });
        }
      });
    }

    // Заполняем статистику из истории битв
    response.data.battleHistory?.forEach(battle => {
      const battleDate = new Date(battle.timestamp);
      if (battleDate >= start && battleDate <= end) {
        const dateKey = format(battleDate, 'yyyy-MM-dd');

        dailyStats[dateKey].totalBattles++;
        if (battle.result === 'win') {
          dailyStats[dateKey].wins++;
        } else {
          dailyStats[dateKey].losses++;
        }
      }
    });

    // Создаем финальный объект с данными
    const processedData = {
      ...response.data, // Сохраняем все исходные данные
      battleHistory: response.data.battleHistory || [], // Явно добавляем battleHistory
      dailyStats: Object.values(dailyStats).sort((a, b) => new Date(a.date) - new Date(b.date)),
      countryStats: response.data.countryStats || [], // Добавляем статистику стран
      socialPlatforms: response.data.socialMediaClicks?.map(platform => {
        if (!platform || !platform.platform) return null;
        return {
          platform: platform.platform.toLowerCase(),
          total: platform.total || 0,
          color: getPlatformColor(platform.platform)
        };
      }).filter(Boolean) || [],
      demographics: response.data.demographics || {
        genderStats: [],
        ageStats: []
      }
    };

    // Добавляем дополнительную проверку возвращаемых данных
    console.log('Final data check before return:', {
      hasBattleHistory: Array.isArray(processedData.battleHistory),
      battleHistoryLength: processedData.battleHistory?.length || 0,
      hasCountryStats: Array.isArray(processedData.countryStats),
      countryStatsLength: processedData.countryStats?.length || 0,
      hasDailyStats: Array.isArray(processedData.dailyStats),
      dailyStatsLength: processedData.dailyStats?.length || 0
    });

    console.log('Processed data before return:', processedData);
    return processedData;

  } catch (error) {
    console.error('Error fetching brand statistics:', error);
    throw error;
  }
};

// Получение демографической статистики
export const getBrandDemographics = async (brandId, params = {}) => {
  try {
    const { startDate, endDate, country } = params;
    let url = `/api/brands/${brandId}/demographics`;

    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append('startDate', startDate);
    if (endDate) queryParams.append('endDate', endDate);
    if (country) queryParams.append('country', country);

    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching brand demographics:', error);
    throw error;
  }
};

// Получение статистики сравнений  
export const getComparisonStatistics = async (brandId) => {
  try {
    const response = await axios.get(`/api/brands/${brandId}/comparisons`);
    return response.data;
  } catch (error) {
    console.error('Error fetching comparison statistics:', error);
    throw error;
  }
};

// Получение статистики за период
export const getBrandStatisticsByPeriod = async (brandId, period, startDate, endDate) => {
  try {
    let url = `/api/brands/${brandId}/statistics/${period}`;
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching period statistics:', error);
    throw error;
  }
};

// Получение статистики по конкретной стране
export const getBrandCountryStatistics = async (brandId, country) => {
  try {
    const response = await axios.get(`/api/brands/${brandId}/statistics/${country}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching country statistics:', error);
    throw error;
  }
};

// Получение аналитики с группировкой
export const getBrandAnalytics = async (brandId, params = {}) => {
  try {
    const { startDate, endDate, groupBy = 'day', gender, ageRange } = params;
    const queryParams = new URLSearchParams({
      groupBy,
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(gender && { gender }),
      ...(ageRange && { ageRange })
    });

    const response = await axios.get(`/api/brands/${brandId}/analytics?${queryParams}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching brand analytics:', error);
    throw error;
  }
};

// Форматирование данных для графиков
export const formatTimelineData = (data, type) => {
  if (!data || !data.battles) return [];

  return Object.entries(data.battles).map(([date, battles]) => ({
    date,
    value: type === 'battles'
      ? battles.length
      : battles.filter(b => b.result === 'win').length,
    demographics: battles.reduce((acc, battle) => {
      const { gender, ageRange } = battle.userDemographics || {};
      if (gender) {
        acc.genderStats[gender] = (acc.genderStats[gender] || 0) + 1;
      }
      if (ageRange) {
        acc.ageStats[ageRange] = (acc.ageStats[ageRange] || 0) + 1;
      }
      return acc;
    }, { genderStats: {}, ageStats: {} })
  }));
};

// Форматирование данных по странам
export const formatCountryData = (countryStats) => {
  if (!countryStats) return [];

  return countryStats.map(stat => ({
    country: stat.country,
    totalBattles: stat.totalBattles,
    winRate: stat.totalBattles > 0 ? (stat.wins / stat.totalBattles * 100).toFixed(2) : 0,
    rating: stat.rating,
    demographics: {
      genderStats: stat.demographics?.genderStats || [],
      ageStats: stat.demographics?.ageStats || []
    }
  }));
};

// Форматирование демографических данных
export const formatDemographicStats = (stats) => {
  if (!stats) return { genderStats: [], ageStats: [] };

  const formatGroup = (group) => {
    return Object.entries(group).map(([key, value]) => ({
      type: key,
      total: value.total,
      wins: value.wins,
      losses: value.losses,
      winRate: value.total > 0 ? ((value.wins / value.total) * 100).toFixed(2) : '0'
    }));
  };

  return {
    genderStats: formatGroup(stats.genderStats),
    ageStats: formatGroup(stats.ageStats)
  };
};

// Группировка данных по периоду
export const groupDataByPeriod = (data, periodType) => {
  if (!Array.isArray(data)) return [];

  const periods = {
    day: d => d.toISOString().split('T')[0],
    week: d => {
      const date = new Date(d);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - date.getDay());
      return date.toISOString().split('T')[0];
    },
    month: d => d.toISOString().slice(0, 7),
    year: d => d.toISOString().slice(0, 4)
  };

  return Object.entries(
    data.reduce((acc, item) => {
      const period = periods[periodType](new Date(item.timestamp));
      if (!acc[period]) {
        acc[period] = {
          period,
          battles: 0,
          wins: 0,
          socialClicks: 0,
          websiteClicks: 0,
          demographics: {
            genderStats: {},
            ageStats: {}
          }
        };
      }

      // Обновляем основную статистику
      if (item.type === 'battle') {
        acc[period].battles++;
        if (item.result === 'win') acc[period].wins++;

        // Обновляем демографическую статистику
        const { gender, ageRange } = item.userDemographics || {};
        if (gender) {
          if (!acc[period].demographics.genderStats[gender]) {
            acc[period].demographics.genderStats[gender] = { total: 0, wins: 0, losses: 0 };
          }
          acc[period].demographics.genderStats[gender].total++;
          if (item.result === 'win') {
            acc[period].demographics.genderStats[gender].wins++;
          } else {
            acc[period].demographics.genderStats[gender].losses++;
          }
        }

        if (ageRange) {
          if (!acc[period].demographics.ageStats[ageRange]) {
            acc[period].demographics.ageStats[ageRange] = { total: 0, wins: 0, losses: 0 };
          }
          acc[period].demographics.ageStats[ageRange].total++;
          if (item.result === 'win') {
            acc[period].demographics.ageStats[ageRange].wins++;
          } else {
            acc[period].demographics.ageStats[ageRange].losses++;
          }
        }
      } else if (item.type === 'social') {
        acc[period].socialClicks++;
      } else if (item.type === 'website') {
        acc[period].websiteClicks++;
      }

      return acc;
    }, {})
  ).map(([_, value]) => value);
};

export default {
  getBrandStatistics,
  getComparisonStatistics,
  getBrandStatisticsByPeriod,
  getBrandCountryStatistics,
  getBrandAnalytics,
  getBrandDemographics,
  formatTimelineData,
  formatCountryData,
  formatDemographicStats,
  groupDataByPeriod
};