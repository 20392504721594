import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import './Landing.css';

const Landing = () => {
    const [currentState, setCurrentState] = useState(0);

    // Настройки
    const SHOW_LABELS = true; // Установите false чтобы скрыть плашки FOR BRAND/USER
    const AUTO_CHANGE_INTERVAL = 4000; // Интервал автосмены в миллисекундах

    const states = [
        {
            title: 'EARN',
            subtitle: 'Rate top brands and earn points while discovering new favorites',
            background: '#e0ff89',
            textColor: '#000',
            type: 'user' // Добавляем тип для плашки
        },
        {
            title: 'BOOST',
            subtitle: 'Access comprehensive data about your brand perception',
            background: '#000',
            textColor: '#e0ff89',
            type: 'brand'
        },
        {
            title: 'WIN',
            subtitle: 'Convert your points into tickets for massive cash prizes',
            background: '#e0ff89',
            textColor: '#000',
            type: 'user'
        },
        {
            title: 'DATA',
            subtitle: 'Access comprehensive data about your brand perception',
            background: '#000',
            textColor: '#e0ff89',
            type: 'brand'
        }
    ];

    useEffect(() => {
        setupGlitchEffect();

        // Автоматическое переключение слайдов
        const interval = setInterval(() => {
            const nextState = (currentState + 1) % states.length;
            changeState(nextState);
        }, AUTO_CHANGE_INTERVAL);

        return () => clearInterval(interval);
    }, [currentState]);

    const setupGlitchEffect = () => {
        const title = document.querySelector('.landing-title');
        const titleContainer = document.querySelector('.title-container');

        if (!title || !titleContainer) return;

        const glitchContent = title.textContent;

        titleContainer.innerHTML = `
      <div class="glitch" data-text="${glitchContent}">${glitchContent}</div>
    `;
    };

    const changeState = (nextState) => {
        gsap.to('.landing-page', {
            backgroundColor: states[nextState].background,
            duration: 0.5
        });

        gsap.to([
            '.landing-title',
            '.landing-subtitle',
            '.landing-auth-btn',
            '.landing-footer-link',
            '.landing-footer-right',
            '.landing-logo',
            '.landing-footer-separator',
            '.glitch',
            '.type-label'
        ], {
            color: states[nextState].textColor,
            duration: 0.5
        });

        setCurrentState(nextState);

        const glitch = document.querySelector('.glitch');
        if (glitch) {
            glitch.setAttribute('data-text', states[nextState].title);
            glitch.textContent = states[nextState].title;
        }
    };

    const handlePageClick = (e) => {
        if (
            !e.target.closest('.landing-auth-btn') &&
            !e.target.closest('.landing-footer-link') &&
            !e.target.closest('.landing-logo')
        ) {
            const nextState = (currentState + 1) % states.length;
            changeState(nextState);
        }
    };

    return (
        <div
            className="landing-page"
            onClick={handlePageClick}
            style={{
                backgroundColor: states[currentState].background,
                color: states[currentState].textColor
            }}
        >
            {/* Добавляем SVG фильтр */}
            <svg width="0" height="0" style={{ position: 'absolute' }}>
                <filter id="goo">
                    /* Увеличиваем размытие для более плавного эффекта желе */
                    <feGaussianBlur in="SourceGraphic" stdDeviation="16" result="blur" />
                    <feColorMatrix
                        in="blur"
                        mode="matrix"
                        /* Последние два числа (30 -9) влияют на "желейность" */
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 15 -9"
                        result="goo"
                    />
                    <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                </filter>
            </svg>
            <div className="landing-top">
                <div
                    className="landing-logo"
                    style={{ color: states[currentState].textColor }}
                >
                    Battle Brands
                </div>
                <div className="landing-auth">
                    <Link
                        to="#"
                        className="landing-auth-btn"
                        style={{
                            '--button-bg-color': states[currentState].background === '#000' ? '#e0ff89' : '#000',
                            '--button-text-color': states[currentState].background === '#000' ? '#000' : '#e0ff89'
                        }}
                    >
                        <span>Join Now</span>
                    </Link>
                </div>
            </div>

            <div className="landing-content">
                <div className="title-container">
                    {SHOW_LABELS && (
                        <div
                            className="type-label"
                            style={{ color: states[currentState].textColor }}
                        >
                            FOR {states[currentState].type.toUpperCase()}
                        </div>
                    )}
                    <h1
                        className="landing-title"
                        style={{ color: states[currentState].textColor }}
                    >
                        {states[currentState].title}
                    </h1>
                </div>
                <p
                    className="landing-subtitle"
                    style={{ color: states[currentState].textColor }}
                >
                    {states[currentState].subtitle}
                </p>
            </div>

            <div className="landing-footer">
                <div className="landing-footer-left">
                    <Link
                        to="/privacy"
                        className="landing-footer-link"
                        style={{ color: states[currentState].textColor }}
                    >
                        Privacy
                    </Link>
                    <span
                        className="landing-footer-separator"
                        style={{ color: states[currentState].textColor }}
                    >
                        /
                    </span>
                    <Link
                        to="/terms"
                        className="landing-footer-link"
                        style={{ color: states[currentState].textColor }}
                    >
                        Terms
                    </Link>
                </div>
                <div
                    className="landing-footer-right"
                    style={{ color: states[currentState].textColor }}
                >
                    Copyright 2025 battplebrands.app
                </div>
            </div>
        </div>
    );
};

export default Landing;