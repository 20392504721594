import React from 'react';
import { useLocation } from 'react-router-dom';
import { Search, Bell } from 'lucide-react';

const Header = () => {
  const location = useLocation();

  const getHeaderTitle = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length <= 1) return 'Dashboard';
    
    const lastPart = pathParts[pathParts.length - 1];
    // Convert kebab-case to Title Case
    return lastPart
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <header className="sticky top-0 z-30 flex items-center justify-between h-16 px-4 md:px-6 border-b border-zinc-800 bg-zinc-950 bg-opacity-80 backdrop-filter backdrop-blur-lg">
      <h1 className="text-xl font-semibold">{getHeaderTitle()}</h1>
      
      <div className="flex items-center space-x-4">
        <div className="hidden md:flex items-center relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-zinc-400" size={16} />
          <input 
            type="text" 
            placeholder="Search..." 
            className="pl-10 pr-4 py-1.5 bg-zinc-900 border border-zinc-800 rounded-md focus:outline-none focus:ring-2 focus:ring-zinc-600 text-sm w-64"
          />
        </div>
        
        <button className="p-2 rounded-md hover:bg-zinc-800 relative">
          <Bell size={18} />
          <span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full"></span>
        </button>
      </div>
    </header>
  );
};

export default Header;