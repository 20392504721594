import React, { useState, useEffect, useRef } from 'react';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, CartesianGrid } from 'recharts';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { X, Info, Star } from 'lucide-react';
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/avatar";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import axios from 'axios';
import toast from './CustomToast';
import ReactCountryFlag from "react-country-flag";
import { SiApplemusic, SiBehance, SiDiscord, SiDribbble, SiFacebook, SiGithub, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiQuora, SiReddit, SiSnapchat, SiSoundcloud, SiSpotify, SiTelegram, SiTencentqq, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';

const BOTTOM_MENU_HEIGHT = '4rem';

const gameContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: BOTTOM_MENU_HEIGHT,
    backgroundColor: 'black',
    zIndex: 50,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
};

const HOUSE_EDGE = 0.03;
const CRASH_CHANCE = 0.07;
const GROWTH_CHANCE = 0.9;
const MAX_MULTIPLIER = 10;

const generateData = (length) => {
    let data = [];
    let value = 1;
    for (let i = 0; i < length; i++) {
        if (Math.random() < CRASH_CHANCE) {
            value = 1;
        } else if (Math.random() < GROWTH_CHANCE) {
            value = Math.min(value * (1 + (Math.random() * 0.2 - HOUSE_EDGE)), MAX_MULTIPLIER);
        } else {
            value = Math.max(value * (1 - Math.random() * 0.1), 1);
        }
        data.push({ time: i, value: value });
    }
    return data;
};

const BrandGrowthGame = ({ user, setUser, setGlobalUser, onClose, updateUserData }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentMultiplier, setCurrentMultiplier] = useState(1);
    const [data, setData] = useState(generateData(1000));
    const [visibleData, setVisibleData] = useState([]);
    const [bet, setBet] = useState(100);
    const [result, setResult] = useState(null);
    const [winAmount, setWinAmount] = useState(0);
    const [cashedOut, setCashedOut] = useState(false);
    const [crashed, setCrashed] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(user.coins);
    const [gameEnded, setGameEnded] = useState(false);
    const timerRef = useRef(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [isBrandInfoOpen, setIsBrandInfoOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [animatingBalance, setAnimatingBalance] = useState(currentBalance);
    const [isAnimating, setIsAnimating] = useState(false);
    const [showingAd, setShowingAd] = useState(false);
    const [gamesPlayedToday, setGamesPlayedToday] = useState(0);
    const [dailyGameLimit, setDailyGameLimit] = useState(5);
    const [remainingGames, setRemainingGames] = useState(5);
    const [isAdDialogOpen, setIsAdDialogOpen] = useState(false);
    const [adProcessing, setAdProcessing] = useState(false);
    const [readyToStart, setReadyToStart] = useState(false);
    const [adWatched, setAdWatched] = useState(false);
    const [buttonText, setButtonText] = useState('Loading...');

const socialIcons = {
  AppleMusic: SiApplemusic,
  Behance: SiBehance,
  Discord: SiDiscord,
  Dribbble: SiDribbble,
  Facebook: SiFacebook,
  GitHub: SiGithub,
  Instagram: SiInstagram,
  KakaoTalk: SiKakaotalk,
  Line: SiLine,
  LinkedIn: SiLinkedin,
  Medium: SiMedium,
  Ok: SiOdnoklassniki,
  Onlyfans: SiOnlyfans,
  Patreon: SiPatreon,
  Pinterest: SiPinterest,
  QQ: SiTencentqq,
  Quora: SiQuora,
  Reddit: SiReddit,
  Snapchat: SiSnapchat,
  SoundCloud: SiSoundcloud,
  Spotify: SiSpotify,
  Telegram: SiTelegram,
  Threads: SiThreads,
  TikTok: SiTiktok,
  Tumblr: SiTumblr,
  Twitch: SiTwitch,
  Viber: SiViber,
  Vk: SiVk,
  WeChat: SiWechat,
  Weibo: SiSinaweibo,
  WhatsApp: SiWhatsapp,
  X: SiX,
  YouTube: SiYoutube,
};

    const handleWebsiteClick = async (brandId, websiteUrl) => {
        try {
            await axios.post(`/api/brands/${brandId}/click/website`);
            window.open(websiteUrl, '_blank');
        } catch (error) {
            console.error('Error registering website click:', error);
            toast.error("Failed to register click", {
                duration: 3000,
                position: 'top-center',
            });
        }
    };

    const handleSocialMediaClick = async (brandId, platform, socialUrl) => {
        try {
            await axios.post(`/api/brands/${brandId}/click/social`, { platform });
            window.open(socialUrl, '_blank');
        } catch (error) {
            console.error('Error registering social media click:', error);
            toast.error("Failed to register click", {
                duration: 3000,
                position: 'top-center',
            });
        }
    };

    const [brands, setBrands] = useState([]);

    const animateBalance = (start, end, duration) => {
        setIsAnimating(true);
        const startTime = Date.now();
        const animate = () => {
            const now = Date.now();
            const progress = Math.min((now - startTime) / duration, 1);
            const current = Math.floor(start + progress * (end - start));
            setAnimatingBalance(current);
            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                setIsAnimating(false);
            }
        };
        requestAnimationFrame(animate);
    };

    useEffect(() => {
        setCurrentBalance(user.coins);
    }, [user.coins]);

    // Получаем количество сыгранных игр с сервера
    useEffect(() => {
        const fetchGameStats = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No authentication token found');
                    return;
                }

                console.log('==== FETCHING GAME STATS ====');

                // Пытаемся получить информацию из локального хранилища
                const localGamesPlayed = parseInt(localStorage.getItem('brandGrowthGamesPlayed') || '0');
                const localLastPlayDate = localStorage.getItem('brandGrowthLastPlayDate');

                // Проверяем, не начался ли новый день
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                let defaultGamesPlayed = localGamesPlayed;

                // Если начался новый день, сбрасываем счетчик
                if (localLastPlayDate) {
                    const lastPlayDate = new Date(localLastPlayDate);
                    lastPlayDate.setHours(0, 0, 0, 0);

                    if (lastPlayDate < today) {
                        defaultGamesPlayed = 0;
                        localStorage.setItem('brandGrowthGamesPlayed', '0');
                        localStorage.setItem('brandGrowthLastPlayDate', today.toISOString());
                    }
                }

                // Устанавливаем начальные значения из локального хранилища
                setGamesPlayedToday(defaultGamesPlayed);
                setDailyGameLimit(5);
                setRemainingGames(5 - defaultGamesPlayed);
                setButtonText(`Watch Ad & Play (${defaultGamesPlayed}/5)`);

                try {
                    // Пытаемся получить данные от сервера через метод update-game-count
                    // Указываем skipIncrement: true, чтобы не увеличивать счетчик при загрузке
                    const countResponse = await axios.post('/api/users/update-game-count', {
                        skipIncrement: true  // Важно: указываем, что нужно пропустить увеличение счетчика
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    console.log('Game stats from server:', countResponse.data);

                    if (countResponse.data.success) {
                        const serverGamesPlayed = countResponse.data.gamesPlayed || 0;
                        const serverDailyLimit = countResponse.data.dailyLimit || 5;
                        const serverRemaining = countResponse.data.remaining || (serverDailyLimit - serverGamesPlayed);

                        // Обновляем состояние из ответа сервера
                        setGamesPlayedToday(serverGamesPlayed);
                        setDailyGameLimit(serverDailyLimit);
                        setRemainingGames(serverRemaining);
                        setButtonText(`Watch Ad & Play (${serverGamesPlayed}/${serverDailyLimit})`);

                        // Синхронизируем локальное хранилище с сервером
                        localStorage.setItem('brandGrowthGamesPlayed', String(serverGamesPlayed));
                        localStorage.setItem('brandGrowthLastPlayDate', new Date().toISOString());
                    }
                } catch (apiError) {
                    console.error('API error:', apiError);
                    // Уже установили локальные значения выше, продолжаем с ними
                }
            } catch (error) {
                console.error('Error in fetchGameStats:', error);
            }
        };

        fetchGameStats();
    }, []);

    useEffect(() => {
        const fetchBrands = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get('/api/brands');
                console.log('Fetched brands:', response.data);
                if (response.data && response.data.brands && response.data.brands.length > 0) {
                    const activeBrands = response.data.brands.filter(brand => brand.status === 'active');
                    setBrands(activeBrands);
                    if (activeBrands.length > 0) {
                        const randomBrand = activeBrands[Math.floor(Math.random() * activeBrands.length)];
                        setSelectedBrand(randomBrand);
                    }
                } else {
                    console.warn('No active brands found');
                    toast.warning("No active brands available for the game", {
                        duration: 3000,
                        position: 'top-center',
                    });
                }
            } catch (error) {
                console.error('Error fetching brands:', error);
                toast.error("Failed to load brands. Please try again.", {
                    duration: 3000,
                    position: 'top-center',
                });
            } finally {
                setIsLoading(false);
            }
        };
        fetchBrands();
    }, [toast]);

    const showAdThenStartGame = () => {
        // Проверка баланса
        if (currentBalance < bet) {
            toast.error("Not enough funds for a bet!", {
                duration: 3000,
                position: 'top-center',
            });
            return;
        }

        // Проверка лимита игр
        if (remainingGames <= 0) {
            toast.error(`You've reached your daily limit of ${dailyGameLimit} games. Come back tomorrow!`, {
                duration: 3000,
                position: 'top-center',
            });
            return;
        }

        // Если реклама уже просмотрена, запускаем игру
        if (adWatched) {
            initiateGameStart();
            return;
        }

        // Показываем рекламу
        handleShowAd();
    };

    const handleShowAd = async () => {
        setAdProcessing(true);
        setShowingAd(true);
        setButtonText('Loading ad...');

        try {
            // Проверяем доступность контроллера рекламы
            const hasAdsController = typeof window.TelegramAdsController !== 'undefined';
            console.log('TelegramAdsController available:', hasAdsController);

            if (hasAdsController) {
                console.log('TelegramAdsController initialized:', typeof window.TelegramAdsController.initialize === 'function');
                console.log('triggerInterstitialBanner available:', typeof window.TelegramAdsController.triggerInterstitialBanner === 'function');

                // Попытка инициализации/реинициализации контроллера рекламы
                if (typeof window.TelegramAdsController.initialize === 'function') {
                    try {
                        window.TelegramAdsController.initialize({
                            pubId: "962094",
                            appId: "1554",
                            debug: true,
                            disableBanner: true // Отключаем баннерную рекламу
                        });
                        console.log('TelegramAdsController re-initialized successfully');
                    } catch (initError) {
                        console.error('TelegramAdsController re-initialization error:', initError);
                    }
                }

                window.rewardGiven = false;

                const handleAdClosed = () => {
                    if (window.rewardGiven) return;
                    window.rewardGiven = true;

                    console.log('Ad event detected - ad watched');

                    setTimeout(() => {
                        setShowingAd(false);
                        setAdProcessing(false);
                        setAdWatched(true);
                        setButtonText('Start Game Now!');
                        // Убираем тост уведомление полностью
                    }, 500);
                };

                const setupEventListeners = () => {
                    const eventHandler = () => {
                        handleAdClosed();
                    };

                    window.removeEventListener('focus', eventHandler);
                    document.removeEventListener('visibilitychange', eventHandler);

                    window.addEventListener('focus', eventHandler, { once: true });

                    setTimeout(() => {
                        if (document.visibilityState === 'visible' && !window.rewardGiven) {
                            handleAdClosed();
                        }
                    }, 1000);

                    setTimeout(() => {
                        if (!window.rewardGiven) {
                            console.log('Backup timer triggered - ad considered watched after 15 seconds');
                            handleAdClosed();
                        }
                    }, 15000);
                };

                // Показываем interstitial баннер
                if (typeof window.TelegramAdsController.triggerInterstitialBanner === 'function') {
                    console.log('Showing interstitial ad...');
                    setButtonText('Watching ad...');

                    setupEventListeners();

                    try {
                        await window.TelegramAdsController.triggerInterstitialBanner();
                        console.log('Ad displayed successfully');

                        setTimeout(() => {
                            if (!window.rewardGiven) {
                                console.log('Auto-triggering reward after ad displayed');
                                handleAdClosed();
                            }
                        }, 5000); // Даем время на просмотр рекламы
                    } catch (adError) {
                        console.error('Error displaying ad:', adError);
                        // В случае ошибки показа рекламы, все равно даем пользователю играть
                        handleAdClosed();
                    }
                } else {
                    // Запасной вариант - native notification или сразу даем играть
                    console.log('InterstitialBanner not available, simulating ad view');
                    handleAdClosed();
                }
            } else {
                // Если контроллер недоступен, симулируем просмотр рекламы
                console.log('Ad controller unavailable, allowing game start');
                setTimeout(() => {
                    setShowingAd(false);
                    setAdProcessing(false);
                    setAdWatched(true);
                    setButtonText('Start Game Now!');
                }, 1000);
            }
        } catch (error) {
            console.error('Error showing ad:', error);
            // В случае любой ошибки, просто позволяем играть
            setShowingAd(false);
            setAdProcessing(false);
            setAdWatched(true);
            setButtonText('Start Game Now!');
        }
    };

    const initiateGameStart = async () => {
        try {
            // Сбрасываем флаг просмотра рекламы для следующей игры
            setAdWatched(false);

            // Получаем текущую дату для сохранения
            const now = new Date();

            // Обновляем локальные счетчики сразу
            const newGamesPlayed = gamesPlayedToday + 1;
            const newRemaining = Math.max(0, dailyGameLimit - newGamesPlayed);

            setGamesPlayedToday(newGamesPlayed);
            setRemainingGames(newRemaining);

            // Обновляем также локальное хранилище на случай ошибок с API
            localStorage.setItem('brandGrowthGamesPlayed', String(newGamesPlayed));
            localStorage.setItem('brandGrowthLastPlayDate', now.toISOString());

            // Обновляем баланс для ставки
            const newBalance = currentBalance - bet;

            // Получаем токен авторизации
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            // Обновляем счетчик игр через API
            try {
                console.log('Sending update-game-count request with token:', token.substring(0, 10) + '...');
                const gameStatsResponse = await axios.post('/api/users/update-game-count', {
                    skipIncrement: false // Установите в true, если не хотите увеличивать счетчик
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                console.log('Game count updated successfully:', gameStatsResponse.data);

                // Получаем актуальные данные от сервера
                if (gameStatsResponse.data.success) {
                    const serverGamesPlayed = gameStatsResponse.data.gamesPlayed || 0;
                    const serverDailyLimit = gameStatsResponse.data.dailyLimit || 5;
                    const serverRemaining = gameStatsResponse.data.remaining || (serverDailyLimit - serverGamesPlayed);

                    // Обновляем состояние из ответа сервера
                    setGamesPlayedToday(serverGamesPlayed);
                    setDailyGameLimit(serverDailyLimit);
                    setRemainingGames(serverRemaining);

                    // Обновляем локальное хранилище
                    localStorage.setItem('brandGrowthGamesPlayed', String(serverGamesPlayed));
                    localStorage.setItem('brandGrowthLastPlayDate', new Date().toISOString());
                }
            } catch (gameCountError) {
                console.warn('Failed to update game count, continuing with local counts:', gameCountError);
                // При ошибке мы уже сохранили данные в localStorage выше
            }

            try {
                // Обновляем баланс пользователя
                const response = await axios.post('/api/users/update-balance',
                    { coins: newBalance },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                const updatedUser = response.data.user;
                setUser(updatedUser);
                setGlobalUser(updatedUser);
                setCurrentBalance(updatedUser.coins);

                // Диспатчим событие обновления баланса
                window.dispatchEvent(new Event('balanceUpdate'));
            } catch (balanceError) {
                console.error('Failed to update balance:', balanceError);
                // Обновляем баланс локально в крайнем случае
                setCurrentBalance(newBalance);
            }

            // Запускаем игру
            setIsPlaying(true);
            setVisibleData([]);
            setResult(null);
            setWinAmount(0);
            setCashedOut(false);
            setCurrentMultiplier(1);
            setData(generateData(1000));
            setCrashed(false);
            setGameEnded(false);

            if (brands.length > 0) {
                const randomBrand = brands[Math.floor(Math.random() * brands.length)];
                setSelectedBrand(randomBrand);
            }
        } catch (error) {
            console.error("Error starting game:", error);

            toast.error("Failed to start game", {
                duration: 2000,
                position: 'top-center',
            });

            // Восстанавливаем стандартное состояние кнопки после ошибки
            setButtonText(`Watch Ad & Play (${gamesPlayedToday}/${dailyGameLimit})`);
        }
    };

    const cashOut = async () => {
        const winAmount = Math.floor(bet * currentMultiplier);
        const newBalance = currentBalance + winAmount;
        try {
            console.log('Attempting to cash out with new balance:', newBalance);
            const token = localStorage.getItem('token');
            const telegramInitData = localStorage.getItem('telegramInitData');

            if (!token) {
                throw new Error('No authentication token found');
            }

            console.log('Sending update balance request...');
            const response = await axios.post('/api/users/update-balance',
                { coins: newBalance },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-Telegram-Init-Data': telegramInitData,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log('Update balance response:', response.data);
            const updatedUser = response.data.user;
            setUser(updatedUser);
            setGlobalUser(updatedUser);
            setCurrentBalance(updatedUser.coins);
            animateBalance(currentBalance, newBalance, 1000);

            // Диспатчим событие для обновления баланса в родительском компоненте
            const event = new CustomEvent('balanceUpdate', {
                detail: { balance: updatedUser.coins }
            });
            window.dispatchEvent(event);

            setResult(winAmount);
            setWinAmount(winAmount);
            setCashedOut(true);

            toast.success(`You won ${winAmount} coins!`, {
                duration: 3000,
                position: 'top-center',
            });
        } catch (error) {
            console.error("Error updating balance:", error);
            if (error.response) {
                console.error("Error response:", error.response.data);
            }
            toast.error("Failed to update balance. Please try again.", {
                duration: 3000,
                position: 'top-center',
            });
            setCurrentBalance(currentBalance);
        }
    };

    // Добавляем useEffect для обработки закрытия игры
    useEffect(() => {
        return () => {
            const updateFinalBalance = async () => {
                try {
                    const token = localStorage.getItem('token');
                    if (!token) return;

                    // Обновляем баланс пользователя используя текущий баланс из игры
                    const response = await axios.post('/api/users/update-balance',
                        { coins: currentBalance },
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    const updatedUser = response.data.user;

                    // Обновляем состояние в родительских компонентах
                    setUser(updatedUser);
                    setGlobalUser(updatedUser);

                    // Обновляем через колбэк если он предоставлен
                    if (updateUserData) {
                        await updateUserData(updatedUser);
                    }

                } catch (error) {
                    console.error("Error updating final balance:", error);
                    toast.error("Failed to sync game balance", {
                        duration: 3000,
                        position: 'top-center',
                    });
                }
            };

            // Вызываем обновление только если баланс изменился
            if (currentBalance !== user.coins) {
                updateFinalBalance();
            }
        };
    }, [currentBalance, user.coins, setUser, setGlobalUser, updateUserData]);

    const endGame = () => {
        clearInterval(timerRef.current);
        setIsPlaying(false);
        setCrashed(true);
        setGameEnded(true);

        // Показываем сообщение о проигрыше только если не было выигрыша
        if (!cashedOut) {
            setResult(0);
        }

        // Сбрасываем флаг просмотра рекламы
        setAdWatched(false);

        // Получаем актуальные данные от сервера через /api/users/update-game-count
        // Получаем актуальные данные от сервера после окончания игры
        const updateGameStats = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No authentication token found');
                    return;
                }

                console.log('Fetching updated game stats after game end...');

                try {
                    // Используем POST вместо GET и передаем skipIncrement: true
                    const response = await axios.post('/api/users/update-game-count', {
                        skipIncrement: true // Не увеличиваем счетчик, только получаем данные
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.data.success) {
                        const gamesPlayed = response.data.gamesPlayed || 0;
                        const dailyLimit = response.data.dailyLimit || 5;
                        const remaining = response.data.remaining || (dailyLimit - gamesPlayed);

                        console.log('Server returned game stats:', {
                            gamesPlayed,
                            dailyLimit,
                            remaining
                        });

                        // Обновляем состояние напрямую из ответа сервера
                        setGamesPlayedToday(gamesPlayed);
                        setDailyGameLimit(dailyLimit);
                        setRemainingGames(remaining);
                        setButtonText(`Watch Ad & Play (${gamesPlayed}/${dailyLimit})`);

                        // Обновляем локальное хранилище
                        localStorage.setItem('brandGrowthGamesPlayed', String(gamesPlayed));
                        localStorage.setItem('brandGrowthLastPlayDate', new Date().toISOString());
                    } else {
                        // Используем текущие значения из состояния
                        setButtonText(`Watch Ad & Play (${gamesPlayedToday}/${dailyGameLimit})`);
                    }
                } catch (error) {
                    console.error('Error fetching game stats:', error);

                    // Обрабатываем ошибку без вывода в консоль полного стека
                    console.log('Using local game stats instead');

                    // Используем текущие значения из состояния
                    setButtonText(`Watch Ad & Play (${gamesPlayedToday}/${dailyGameLimit})`);
                }
            } catch (error) {
                console.error('Error updating game stats after game end:', error);
            }
        };

        // Запускаем обновление данных
        updateGameStats();
    };

    useEffect(() => {
        if (isPlaying) {
            timerRef.current = setInterval(() => {
                setVisibleData(prevData => {
                    if (prevData.length >= data.length - 1) {
                        endGame();
                        return prevData;
                    }
                    const newDataPoint = data[prevData.length];
                    setCurrentMultiplier(newDataPoint.value);
                    if (newDataPoint.value === 1 && prevData[prevData.length - 1]?.value > 1) {
                        endGame();
                    }
                    return [...prevData, newDataPoint];
                });
            }, 100);
        }
        return () => clearInterval(timerRef.current);
    }, [isPlaying, data]);

    const renderButton = () => {
        const isDisabled = !isPlaying || cashedOut;
        const showLoss = gameEnded && !cashedOut;
        const buttonClass = `w-full ${showLoss ? 'bg-red-500 text-white' : 'bg-[#e0ff89] text-black'} hover:bg-[#d1ef7a] font-bold rounded-[30px] h-12 flex items-center justify-center`;

        return (
            <Button
                onClick={cashOut}
                disabled={isDisabled}
                className={buttonClass}
            >
                {isPlaying
                    ? (cashedOut ? `Win: ${winAmount}` : 'Cash Out')
                    : (gameEnded
                        ? (cashedOut ? `Win: ${winAmount}` : 'Loss')
                        : 'Cash Out')}
            </Button>
        );
    };

    const openBrandInfo = () => {
        setIsBrandInfoOpen(true);
    };

    return (
        <div style={gameContainerStyle}>
            <div className="text-white flex flex-col h-full p-4">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-2xl font-bold">Brand Growth</h2>
                    <button
                        onClick={onClose}
                        className="text-white cursor-pointer focus:outline-none"
                    >
                        <X size={24} />
                    </button>
                </div>
                <div className="flex items-center mb-4">
                    {isLoading ? (
                        <h2 className="text-lg font-bold">Loading brand...</h2>
                    ) : (
                        <>
                            <div onClick={openBrandInfo} className="flex items-center cursor-pointer">
                                <Avatar className="w-8 h-8 mr-2">
                                    <AvatarImage src={selectedBrand?.logo || '/default-logo.png'} alt={selectedBrand?.name || 'Default Brand'} />
                                    <AvatarFallback>{selectedBrand?.name?.[0] || 'D'}</AvatarFallback>
                                </Avatar>
                                <h2 className="text-lg font-bold flex-grow mr-2">{selectedBrand?.name || 'Default Brand'}</h2>
                                <div className="w-6 h-6 rounded-full flex items-center justify-center">
                                    <Info className="h-4 w-4 text-gray-400" />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {!isLoading && (
                    <div className="flex-grow mb-4 min-h-[200px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart data={visibleData.length > 0 ? visibleData : [{ time: 0, value: 1 }, { time: 100, value: MAX_MULTIPLIER }]}>
                                <XAxis dataKey="time" hide />
                                <YAxis hide domain={[1, MAX_MULTIPLIER]} />
                                <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                                <Area
                                    type="monotone"
                                    dataKey="value"
                                    stroke={crashed ? "#FF0000" : "#e0ff89"}
                                    fill={crashed ? "#FF0000" : "#e0ff89"}
                                    fillOpacity={0.3}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                )}
                <div className="flex justify-between items-center mb-4">
                    <div className="text-3xl font-bold">x{currentMultiplier.toFixed(2)}</div>
                    <div className="text-xl font-bold flex items-center">
                        {isAnimating ? animatingBalance : currentBalance}
                        <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                    </div>
                </div>
                <div className="flex justify-between gap-4 mb-4">
                    <div className="flex-grow">
                        <div className="w-full bg-black text-white border-2 border-white rounded-[30px] h-12 flex items-center justify-between px-2">
                            <button
                                onClick={() => setBet(Math.max(100, bet - 100))}
                                disabled={isPlaying}
                                className="w-8 h-8 bg-white rounded-full flex items-center justify-center"
                            >
                                <span className="text-black text-2xl font-bold leading-none">-</span>
                            </button>
                            <span className="text-xl font-bold">{bet}</span>
                            <button
                                onClick={() => setBet(bet + 100)}
                                disabled={isPlaying}
                                className="w-8 h-8 bg-white rounded-full flex items-center justify-center"
                            >
                                <span className="text-black text-2xl font-bold leading-none">+</span>
                            </button>
                        </div>
                    </div>
                    <div className="w-1/3">
                        {renderButton()}
                    </div>
                </div>
                <Button
                    onClick={isPlaying ? null : showAdThenStartGame}
                    disabled={isPlaying || remainingGames <= 0 || adProcessing}
                    className={`w-full font-bold rounded-[30px] h-12 ${isPlaying ? 'bg-blue-500 text-white' :
                            remainingGames <= 0 ? 'bg-gray-500 text-gray-200' :
                                adWatched ? 'bg-green-500 text-white' : 'bg-[#e0ff89] hover:bg-[#d1ef7a] text-black'
                        }`}
                >
                    {isPlaying ? 'Game in progress...' : (
                        remainingGames <= 0 ?
                            `Daily limit reached (${gamesPlayedToday}/${dailyGameLimit})` :
                            buttonText
                    )}
                </Button>
            </div>
<Dialog open={isBrandInfoOpen} onOpenChange={setIsBrandInfoOpen}>
    <DialogContent className="bg-black rounded-[30px] p-6">
        <DialogHeader>
            <DialogTitle className="text-white flex items-center justify-between">
                <div className="flex items-center">
                    <Avatar className="w-12 h-12 mr-4">
                        <AvatarImage src={selectedBrand?.logo} alt={selectedBrand?.name} />
                        <AvatarFallback>{selectedBrand?.name?.[0]}</AvatarFallback>
                    </Avatar>
                    <div>
                        <h2 className="text-2xl font-bold text-left">{selectedBrand?.name}</h2>
                        <div className="flex items-center mt-1">
                            {[1, 2, 3, 4, 5].map((star) => {
                                const rating = selectedBrand?.rating || 0;
                                const fillPercentage = Math.min(100, Math.max(0, (rating - (star - 1)) * 100));
                                return (
                                    <div key={star} className="relative w-4 h-4">
                                        <Star className="w-4 h-4 text-gray-400" />
                                        <div className="absolute top-0 left-0 overflow-hidden" style={{ width: `${fillPercentage}%` }}>
                                            <Star className="w-4 h-4 text-yellow-400 fill-current" />
                                        </div>
                                    </div>
                                );
                            })}
                            <span className="ml-2 text-sm text-gray-400">
                                ({selectedBrand?.rating?.toFixed(1) || '0.0'})
                            </span>
                        </div>
                    </div>
                </div>
            </DialogTitle>
        </DialogHeader>
        <div className="text-white mt-2 space-y-4">
            <div className="flex">
                <span className="font-semibold w-20">Country:</span>
                {selectedBrand?.countries && selectedBrand.countries.length > 0 && (
                    <span>
                        {selectedBrand.countries.map((country, index) => (
                            <React.Fragment key={country.code}>
                                <ReactCountryFlag countryCode={country.code} svg className="mr-1" />
                                <span>{country.name}</span>
                                {index < selectedBrand.countries.length - 1 && ', '}
                            </React.Fragment>
                        ))}
                    </span>
                )}
            </div>
            
<div className="flex flex-col">
  <span className="font-semibold mb-1">Category:</span>
  <div className="flex flex-wrap">
    {selectedBrand?.categories && selectedBrand.categories.length > 0 && selectedBrand.categories.map((cat, index) => (
      <div key={cat.category._id} className="flex flex-wrap items-center mb-1 mr-2">
        <span className="text-[#e0ff89] break-words">{cat.category.name}</span>
        {cat.subCategory && (
          <>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 mx-1 text-gray-400 flex-shrink-0" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
            <span className="text-[#e0ff89] break-words">{cat.subCategory}</span>
          </>
        )}
        {cat.subSubCategory && (
          <>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 mx-1 text-gray-400 flex-shrink-0" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
            <span className="text-[#e0ff89] break-words">{cat.subSubCategory}</span>
          </>
        )}
      </div>
    ))}
  </div>
</div>
            
            <div>
                <span className="font-semibold">Description: </span>
                <p className="text-gray-300">{selectedBrand?.description}</p>
            </div>
            
{selectedBrand?.socialMedia && selectedBrand.socialMedia.length > 0 && (
    <div className="pb-8">
        <span className="font-semibold block mb-2">Social: </span>
        <div className="flex space-x-6">
            {selectedBrand.socialMedia.map(social => {
                const Icon = socialIcons[social.platform];
                return Icon ? (
                    <Button
                        key={social.platform}
                        onClick={() => handleSocialMediaClick(selectedBrand._id, social.platform, social.url)}
                        className="text-gray-400 hover:text-white transition-colors"
                    >
                        <Icon size={24} />
                    </Button>
                ) : null;
            })}
        </div>
    </div>
)}
            
            {selectedBrand?.website && (
                <div>
                    <Button
                        onClick={() => handleWebsiteClick(selectedBrand._id, selectedBrand.website)}
                        className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 text-center font-bold transition-colors"
                    >
                        Go to {selectedBrand.name}
                    </Button>
                </div>
            )}
        </div>
    </DialogContent>
</Dialog>
        </div>
    );
};

export default BrandGrowthGame;