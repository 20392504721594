import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import toast from '../CustomToast';

/**
 * Компонент для отображения отдельного элемента истории (фото/видео)
 * @param {Object} props - свойства компонента
 * @param {Object} props.item - элемент истории (фото или видео)
 * @param {Object} props.story - родительская история
 * @param {Function} props.onEnd - функция для перехода к следующему элементу
 * @param {boolean} props.isPaused - остановлен ли просмотр истории
 */
const StoryItem = ({ item, story, onEnd, isPaused }) => {
  // Тип контента (изображение или видео)
  const [contentType, setContentType] = useState(null);
  // Загружен ли контент
  const [isLoaded, setIsLoaded] = useState(false);
  // Таймер для автоматического перехода
  const timerRef = useRef(null);
  // Время начала отображения элемента
  const startTimeRef = useRef(null);
  // Оставшееся время до автоперехода
  const remainingTimeRef = useRef(null);

  // Определяем тип контента и загружаем его
  useEffect(() => {
    if (!item) return;

    // Сбрасываем состояние при смене элемента
    setIsLoaded(false);

    // Определяем тип контента по расширению или типу
    if (item.type === 'video' || (item.url && item.url.match(/\.(mp4|mov|webm)$/i))) {
      setContentType('video');
    } else {
      setContentType('image');
    }

    // Сбрасываем таймеры
    clearTimeout(timerRef.current);
    startTimeRef.current = null;
    remainingTimeRef.current = null;
  }, [item]);

  // Управление таймером для автоматического перехода (упрощенная версия)
  useEffect(() => {
    // Для видео элементов добавляем управление паузой
    if (contentType === 'video') {
      const videoElement = document.querySelector(`video[src="${item.url}"]`);
      if (videoElement) {
        if (isPaused) {
          videoElement.pause();
        } else {
          videoElement.play().catch(e => console.error('Error playing video:', e));
        }
      }
    }

    // Все остальное (таймеры и т.д.) управляется родительским компонентом Stories
  }, [isPaused, contentType, item]);

  // Устанавливаем isLoaded=true через 0.5 секунды, если контент не загрузился
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoaded) {
        console.log('Force setting isLoaded=true after timeout');
        setIsLoaded(true);
      }
    }, 500); // Полсекунды

    return () => clearTimeout(timer);
  }, [isLoaded]);

  // Обработчик окончания видео
  const handleVideoEnded = () => {
    // Просто вызываем onEnd для перехода к следующему элементу
    onEnd();
  };

  // Обработчик клика на элементе с внешней ссылкой
const handleExternalLinkClick = async (e) => {
  if (item.externalLink) {
    // Останавливаем распространение события, чтобы не сработал клик на родительском элементе
    e.stopPropagation();
    e.preventDefault();
    
    console.log('External link clicked:', item.externalLink);
    console.log('Story data:', story);
    
    try {
      // Важно: используем правильное ID истории
      const storyId = story?._id || story?.id; // Пробуем получить ID в двух форматах
      
      if (storyId) {
        console.log(`Registering link click for story: ${storyId}`);
        
        // Получаем токен аутентификации для идентификации пользователя
        const token = localStorage.getItem('token');
        const telegramInitData = localStorage.getItem('telegramInitData');
        
        // Отправляем запрос с заголовками аутентификации
        try {
          const response = await axios.post(`/api/stories/${storyId}/click`, {}, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'X-Telegram-Init-Data': telegramInitData
            }
          });
          
          console.log('Click registration response:', response.data);
          
          // Проверяем результат запроса
          if (response.data.success === false) {
            console.warn(`Click request failed: ${response.data.message}`);
            
            // Показываем уведомление о превышении лимита кликов
            if (response.data.message && response.data.message.includes('User click limit reached')) {
              console.warn(`User has reached maximum clicks limit for this story: ${response.data.clickCount}/${response.data.maxClicks}`);
              
              // Можно добавить визуальное уведомление для пользователя
              if (typeof toast !== 'undefined' && typeof toast.error === 'function') {
                toast.error('Вы достигли максимального количества переходов по ссылке');
              }
              
              // Вызываем событие для обновления данных и закрытия истории
              if (window.dispatchEvent) {
                window.dispatchEvent(new CustomEvent('userDataUpdate', {
                  detail: { 
                    type: 'storyLinkClicked', 
                    storyId: storyId,
                    limitReached: true
                  }
                }));
              }
              
              // Не открываем ссылку при превышении лимита
              return;
            }
            
            // Проверяем другие ошибки
            if (response.data.message && response.data.message.includes('expired')) {
              if (typeof toast !== 'undefined' && typeof toast.error === 'function') {
                toast.error('История больше недоступна');
              }
              return;
            }
          }
          
          // Проверяем, не истек ли лимит кликов
          if (response.data.status === 'expired') {
            console.log('Story expired due to click limit');
            
            // Вызываем событие для обновления данных истории в интерфейсе
            if (window.dispatchEvent) {
              window.dispatchEvent(new CustomEvent('storyStatusUpdate', {
                detail: { 
                  storyId: storyId,
                  status: 'expired'
                }
              }));
            }
          }
          
          // Обновляем данные пользователя после клика по ссылке
          if (response.data.success) {
            if (window.dispatchEvent) {
              window.dispatchEvent(new CustomEvent('userDataUpdate', {
                detail: { 
                  type: 'storyLinkClicked', 
                  storyId: storyId,
                  userData: response.data.user
                }
              }));
            }
            
            // Открываем ссылку только в случае успеха
            console.log('Opening link:', item.externalLink);
            window.open(item.externalLink, '_blank');
          }
        } catch (clickError) {
          console.error('Link click registration error:', clickError);
          console.error('Error details:', clickError.response?.data || clickError.message);
          
          // Проверяем ответ сервера на ошибки
          if (clickError.response && clickError.response.data) {
            // Если сервер вернул сообщение о превышении лимита
            if (clickError.response.data.message && (
                clickError.response.data.message.includes('User click limit reached') ||
                clickError.response.data.message.includes('Link clicks limit reached') ||
                clickError.response.data.message.includes('Story is expired')
            )) {
              console.warn('Click limit error:', clickError.response.data.message);
              
              if (typeof toast !== 'undefined' && typeof toast.error === 'function') {
                toast.error('Превышен лимит кликов для этой истории');
              }
              
              // Не открываем ссылку при ошибке лимита
              return;
            }
          }
          
          // В случае других ошибок все равно открываем ссылку
          window.open(item.externalLink, '_blank');
        }
      } else {
        console.warn('Cannot register click: missing story ID');
        // Если нет ID истории, просто открываем ссылку
        window.open(item.externalLink, '_blank');
      }
    } catch (error) {
      console.error('Error in handleExternalLinkClick:', error);
      
      // В случае необработанной ошибки все равно открываем ссылку
      window.open(item.externalLink, '_blank');
    }
  }
};

  // Рендер содержимого в зависимости от типа
  const renderContent = () => {
    if (!item) return null;

    const isClickable = !!item.externalLink;
    // Убираем класс cursor-pointer, так как кликабельна только кнопка
    const clickableClass = '';

    switch (contentType) {
      case 'video':
        return (
          <div className={`w-full h-full ${clickableClass}`}>
            <video
              className="w-full h-full object-contain bg-black"
              src={item.url}
              autoPlay
              playsInline
              muted={item.muted}
              onLoadedData={() => setIsLoaded(true)}
              onEnded={handleVideoEnded}
              controls={false}
              poster={item.thumbnail}
              onError={(e) => {
                console.error(`Error loading video: ${item.url}`, e);
                setIsLoaded(true); // Считаем загруженным даже при ошибке чтобы продолжить просмотр
              }}
            />
          </div>
        );

      case 'image':
      default:
        return (
          <div className={`w-full h-full ${clickableClass}`}>
            <img
              className="w-full h-full object-contain bg-black"
              src={item.url}
              alt={item.caption || `Story`}
              onLoad={() => setIsLoaded(true)}
              onError={(e) => {
                console.error(`Error loading image: ${item.url}`, e);
                // Пытаемся загрузить плейсхолдер при ошибке
                e.target.src = 'https://via.placeholder.com/400x600?text=Image+not+found';
                setIsLoaded(true); // Считаем загруженным даже при ошибке чтобы продолжить просмотр
              }}
            />
          </div>
        );
    }
  };

  return (
    <div className="relative w-full h-full flex items-center justify-center bg-zinc-900">
      {renderContent()}

      {/* Отображаем только иконку для внешних ссылок - ИЗМЕНЕНО: выше z-index и дополнительная обработка клика */}
      {item.externalLink && (
        <div className="absolute bottom-12 left-0 right-0 p-4 text-white text-center z-50 pointer-events-auto">
          <div className="mt-2 bg-black bg-opacity-20 py-3 rounded-xl">
            <button
              className="bg-[#e0ff89] text-black rounded-full px-4 py-2 text-sm flex items-center mx-auto external-link-button"
              onClick={handleExternalLinkClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
              Go to
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoryItem;