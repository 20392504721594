import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Card, CardContent } from "../ui/card";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog";
import {
    Pencil, Trash2, Upload,
    // Обновленные иконки для бейджей
    Crown, Star, Gem, Gamepad2, HandMetal, Magnet, Lollipop,
    PawPrint, Pill, Spade, Zap, Rocket, Shell, Bitcoin,
    Cookie, Donut, Banana, Bone, Dices, Ghost, Heart,
    Puzzle, Skull, Swords, AudioWaveform, Drama, Brain,
    Radiation, Webhook
} from "lucide-react";
import { Label } from "../ui/label";
import { useDropzone } from 'react-dropzone';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Switch } from "../ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

// Компонент для предварительного просмотра рангового интерфейса
const RankPreview = ({ rank }) => {
    const getBackgroundEffectStyle = () => {
    if (!rank.effects?.backgroundEffect?.enabled) {
        return {};
    }

    const effect = rank.effects.backgroundEffect;
    const style = {};

        switch (effect.type) {
            case 'blur':
                style.backdropFilter = `blur(${effect.intensity}px)`;
                style.WebkitBackdropFilter = `blur(${effect.intensity}px)`;
                break;
            case 'overlay':
                if (effect.useGradient && effect.gradientColors?.length >= 2) {
                    style.background = `linear-gradient(${effect.gradientDirection || 'to bottom'}, ${effect.gradientColors.join(', ')})`;
                } else {
                    style.backgroundColor = effect.color;
                    style.opacity = effect.intensity / 10;
                }
                break;
            case 'glow':
                style.boxShadow = `inset 0 0 ${effect.intensity * 5}px ${effect.color}`;
                style.opacity = 0.8;
                break;
            case 'tint':
                style.backgroundColor = effect.color;
                style.opacity = effect.intensity / 10;
                style.mixBlendMode = 'color';
                break;
            case 'gradient':
                if (effect.gradientColors?.length >= 2) {
                    style.background = `linear-gradient(${effect.gradientDirection || 'to bottom'}, ${effect.gradientColors.join(', ')})`;
                } else {
                    style.background = `linear-gradient(to bottom, transparent, ${effect.color})`;
                }
                break;
        }

        if (effect.animation?.enabled) {
            style.animation = `${effect.animation.type}-bg ${effect.animation.duration}s infinite`;
        }

        return style;
    };

    // Получаем компонент иконки бейджа
    const getBadgeIcon = (type, size = 24, color = '#e0ff89') => {
        const props = { size, color };

        switch (type) {
            case 'crown': return <Crown {...props} />;
            case 'star': return <Star {...props} />;
            case 'gem': return <Gem {...props} />;
            case 'gamepad-2': return <Gamepad2 {...props} />;
            case 'hand-metal': return <HandMetal {...props} />;
            case 'magnet': return <Magnet {...props} />;
            case 'lollipop': return <Lollipop {...props} />;
            case 'paw-print': return <PawPrint {...props} />;
            case 'pill': return <Pill {...props} />;
            case 'spade': return <Spade {...props} />;
            case 'zap': return <Zap {...props} />;
            case 'rocket': return <Rocket {...props} />;
            case 'shell': return <Shell {...props} />;
            case 'bitcoin': return <Bitcoin {...props} />;
            case 'cookie': return <Cookie {...props} />;
            case 'donut': return <Donut {...props} />;
            case 'banana': return <Banana {...props} />;
            case 'bone': return <Bone {...props} />;
            case 'dices': return <Dices {...props} />;
            case 'ghost': return <Ghost {...props} />;
            case 'heart': return <Heart {...props} />;
            case 'puzzle': return <Puzzle {...props} />;
            case 'skull': return <Skull {...props} />;
            case 'swords': return <Swords {...props} />;
            case 'audio-waveform': return <AudioWaveform {...props} />;
            case 'drama': return <Drama {...props} />;
            case 'brain': return <Brain {...props} />;
            case 'radiation': return <Radiation {...props} />;
            case 'webhook': return <Webhook {...props} />;
            default: return <Star {...props} />;
        }
    };

    // Получаем стиль для имени ранга
    const getNameEffectStyle = () => {
        if (!rank.effects?.nameEffect?.enabled) {
            return {};
        }

        const effect = rank.effects.nameEffect;
        const style = {
            color: effect.textColor || '#FFFFFF' // Добавлен цвет текста по умолчанию
        };

        switch (effect.type) {
            case 'shadow':
                style.textShadow = `0 0 ${effect.intensity}px ${effect.color}`;
                break;
            case 'glow':
                style.textShadow = `0 0 ${effect.intensity * 2}px ${effect.color}, 0 0 ${effect.intensity}px ${effect.color}`;
                break;
            case 'gradient':
                if (effect.gradientColors?.length >= 2) {
                    style.background = `linear-gradient(to right, ${effect.gradientColors.join(', ')})`;
                } else {
                    style.background = `linear-gradient(to right, ${effect.textColor || 'white'}, ${effect.color})`;
                }
                style.WebkitBackgroundClip = 'text';
                style.WebkitTextFillColor = 'transparent';
                style.backgroundClip = 'text';
                style.textFillColor = 'transparent';
                break;
            case 'highlight':
                style.backgroundColor = effect.color;
                style.color = effect.textColor || 'black'; // Использование цвета текста 
                style.padding = '0 8px';
                style.borderRadius = '4px';
                break;
        }

        return style;
    };

    // CSS класс для анимации аватара
    const getAvatarBorderClass = () => {
        if (!rank.avatarBorder?.animation?.enabled) {
            return '';
        }

        switch (rank.avatarBorder.animation.type) {
            case 'pulse':
                return 'animate-pulse-infinite';
            case 'rotate':
                return 'animate-rotate-infinite';
            case 'shine':
                return 'animate-shine-infinite';
            default:
                return '';
        }
    };

    // CSS класс для анимации бейджа
    const getBadgeClass = () => {
        if (!rank.badge?.animation?.enabled) {
            return '';
        }

        switch (rank.badge.animation.type) {
            case 'pulse':
                return 'animate-pulse-infinite';
            case 'rotate':
                return 'animate-rotate-infinite';
            case 'bounce':
                return 'animate-bounce-infinite';
            default:
                return '';
        }
    };

    // Стиль для названия ранга с анимацией
    const getTitleStyle = () => {
        if (!rank.customTitle?.style) {
            return {};
        }

        const titleStyle = rank.customTitle.style;
        const style = {
            fontSize: `${rank.customTitle.fontSize || 18}px`,
            fontWeight: rank.customTitle.fontWeight || 'bold',
            fontFamily: rank.customTitle.fontFamily || 'Inter',
            letterSpacing: `${rank.customTitle.letterSpacing || 0}px`,
            color: titleStyle.color || '#FFFFFF'
        };

        // Добавление эффектов для названия
        if (titleStyle.effect === 'shadow') {
            style.textShadow = `0 0 ${titleStyle.intensity || 3}px ${titleStyle.effectColor || '#000000'}`;
        } else if (titleStyle.effect === 'glow') {
            style.textShadow = `0 0 ${titleStyle.intensity * 2 || 6}px ${titleStyle.effectColor || '#e0ff89'}, 0 0 ${titleStyle.intensity || 3}px ${titleStyle.effectColor || '#e0ff89'}`;
        } else if (titleStyle.effect === 'gradient') {
            if (titleStyle.gradientColors?.length >= 2) {
                style.background = `linear-gradient(to right, ${titleStyle.gradientColors.join(', ')})`;
            } else {
                style.background = `linear-gradient(to right, ${titleStyle.color || '#FFFFFF'}, ${titleStyle.effectColor || '#e0ff89'})`;
            }
            style.WebkitBackgroundClip = 'text';
            style.WebkitTextFillColor = 'transparent';
            style.backgroundClip = 'text';
            style.textFillColor = 'transparent';
        }

        // Добавление анимации для названия
        if (titleStyle.animation) {
            if (titleStyle.animation === 'pulse') {
                style.animation = 'titlePulse 2s infinite';
            } else if (titleStyle.animation === 'wave') {
                style.animation = 'titleWave 2s infinite';
            } else if (titleStyle.animation === 'shine') {
                style.animation = 'titleShine 2s linear infinite';
                style.backgroundSize = '200% auto';
                if (!style.background) {
                    style.background = `linear-gradient(to right, ${titleStyle.color || '#FFFFFF'} 0%, ${titleStyle.effectColor || '#e0ff89'} 50%, ${titleStyle.color || '#FFFFFF'} 100%)`;
                    style.WebkitBackgroundClip = 'text';
                    style.WebkitTextFillColor = 'transparent';
                    style.backgroundClip = 'text';
                    style.textFillColor = 'transparent';
                }
            }
        }

        return style;
    };

    // Стиль для границы аватара
    const getAvatarBorderStyle = () => {
        if (!rank.avatarBorder) {
            return { border: '2px solid #FFFFFF' };
        }

        const border = rank.avatarBorder;
        const style = {};

        if (border.style === 'gradient' && border.gradient?.colors?.length) {
            style.background = `linear-gradient(${border.gradient.direction || 'to right'}, ${border.gradient.colors.join(', ')})`;
            style.padding = border.width || 2;
        } else {
            style.border = `${border.width || 2}px ${border.style || 'solid'} ${border.color || '#FFFFFF'}`;
        }

        // Добавляем свечение, если оно включено
        if (border.halo?.enabled) {
            style.boxShadow = `0 0 ${border.halo.intensity}px ${border.halo.color || '#FFFFFF'}`;
        }

        return style;
    };

    // Создаем частицы для эффекта
    const renderParticles = () => {
        if (!rank.effects?.particles?.enabled) {
            return null;
        }

        const particles = [];
        const count = rank.effects.particles.density * 5; // Умножаем на 5, чтобы получить реальное количество частиц

        for (let i = 0; i < count; i++) {
            const size = Math.random() * 5 + 2; // Случайный размер от 2 до 7px
            particles.push(
                <div
                    key={i}
                    className="absolute animate-float"
                    style={{
                        left: `${Math.random() * 100}%`,
                        top: `${Math.random() * 100}%`,
                        color: rank.effects.particles.color,
                        opacity: Math.random() * 0.7 + 0.3,
                        animation: `float ${Math.random() * 2 + 1}s infinite ease-in-out`,
                        transform: `translateY(0px) scale(${Math.random() * 0.5 + 0.5})`,
                        zIndex: 5, // Установка z-index для отображения над аватаром
                    }}
                >
                    {rank.effects.particles.type === 'sparkles' && (
                        <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                        </svg>
                    )}
                    {rank.effects.particles.type === 'bubbles' && (
                        <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                            <circle cx="12" cy="12" r="10" />
                        </svg>
                    )}
                    {rank.effects.particles.type === 'stars' && (
                        <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                        </svg>
                    )}
                    {rank.effects.particles.type === 'fire' && (
                        <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12 2C10.97 2 9.94 2.16 8.92 2.5C9.61 3.43 10 4.63 10 5.9C10 8.15 8.21 10 6 10C5.07 10 4.21 9.72 3.5 9.22C3.18 10.13 3 11.05 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 6.42 16.97 2 12 2Z" />
                        </svg>
                    )}
                </div>
            );
        }

        return (
            <div className="absolute inset-0 overflow-hidden pointer-events-none z-5">
                {particles}
            </div>
        );
    };

    // Отрисовка бейджа
    const Badge = () => {
        if (!rank.badge?.enabled) {
            return null;
        }

        const badge = rank.badge;
        let positionClass = '';
        let transformClass = '';

        switch (badge.position) {
            case 'top-right':
                positionClass = 'top-0 right-0';
                transformClass = 'translate-x-1/3 -translate-y-1/3';
                break;
            case 'top-left':
                positionClass = 'top-0 left-0';
                transformClass = '-translate-x-1/3 -translate-y-1/3';
                break;
            case 'bottom-right':
                positionClass = 'bottom-0 right-0';
                transformClass = 'translate-x-1/3 translate-y-1/3';
                break;
            case 'bottom-left':
                positionClass = 'bottom-0 left-0';
                transformClass = '-translate-x-1/3 translate-y-1/3';
                break;
        }

        const backgroundStyle = badge.background?.enabled
            ? { backgroundColor: badge.background.color, padding: '3px', borderRadius: '50%' }
            : {};

        const shadowStyle = badge.shadow?.enabled
            ? { boxShadow: `0 0 5px ${badge.shadow.color}` }
            : {};

        return (
            <div
                className={`absolute ${positionClass} ${getBadgeClass()} z-20 transform ${transformClass}`}
                style={{
                    color: badge.color || '#e0ff89',
                    ...backgroundStyle,
                    ...shadowStyle,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {badge.type === 'custom' && badge.customImage ? (
                    <img
                        src={badge.customImage}
                        alt="Badge"
                        style={{
                            width: `${badge.size || 24}px`,
                            height: `${badge.size || 24}px`
                        }}
                    />
                ) : (
                    getBadgeIcon(badge.type, badge.size || 24, badge.color)
                )}
            </div>
        );
    };

    // Генерация частиц для фона
const renderBackgroundParticles = () => {
    if (!rank.effects?.backgroundParticles?.enabled) {
        return null;
    }

    const particles = [];
    const count = rank.effects.backgroundParticles.density * 5;

    for (let i = 0; i < count; i++) {
        const size = Math.random() * 5 + 2;
        particles.push(
            <div
                key={`bg-particle-${i}`}
                className="absolute animate-float"
                style={{
                    left: `${Math.random() * 100}%`,
                    top: `${Math.random() * 100}%`,
                    color: rank.effects.backgroundParticles.color,
                    opacity: Math.random() * 0.7 + 0.3,
                    animation: `float ${Math.random() * 2 + 1}s infinite ease-in-out`,
                    transform: `translateY(0px) scale(${Math.random() * 0.5 + 0.5})`,
                    zIndex: 1, // Ставим ниже, чем частицы аватара
                    pointerEvents: 'none', // Добавляем, чтобы частицы не мешали взаимодействию
                }}
            >
                {rank.effects.backgroundParticles.type === 'sparkles' && (
                    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                    </svg>
                )}
                {rank.effects.backgroundParticles.type === 'bubbles' && (
                    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                        <circle cx="12" cy="12" r="10" />
                    </svg>
                )}
                {rank.effects.backgroundParticles.type === 'stars' && (
                    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                    </svg>
                )}
                {rank.effects.backgroundParticles.type === 'fire' && (
                    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 2C10.97 2 9.94 2.16 8.92 2.5C9.61 3.43 10 4.63 10 5.9C10 8.15 8.21 10 6 10C5.07 10 4.21 9.72 3.5 9.22C3.18 10.13 3 11.05 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 6.42 16.97 2 12 2Z" />
                    </svg>
                )}
            </div>
        );
    }

    return (
        <div className="absolute inset-0 overflow-hidden pointer-events-none z-1">
            {particles}
        </div>
    );
};

    return (
        <div className="overflow-hidden rounded-lg relative" style={{ height: '220px', backgroundColor: rank.colorScheme?.secondary || '#1a1a1a' }}>
            {/* Фоновое изображение */}
            {rank.backgroundImage && (
                <>
                    <div
                        className="absolute inset-0 w-full h-full bg-center bg-cover z-0"
                        style={{
                            backgroundImage: `url(${rank.backgroundImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                    />

                    {/* Частицы на фоне */}
                    {renderBackgroundParticles()}

                    {/* Эффект фонового изображения */}
                    {rank.effects?.backgroundEffect?.enabled && (
                        <div
                            className="absolute inset-0 z-1"
                            style={getBackgroundEffectStyle()}
                        />
                    )}
                </>
            )}

            {/* Содержимое превью */}
            <div className="relative z-10 p-4 h-full flex flex-col">
                <div className="flex items-center space-x-4 mb-4">
                    {/* Аватар пользователя */}
                    <div className="relative">
                        {/* Частицы над аватаром (теперь с более высоким z-index) */}
                        {renderParticles()}

{/* Ореол (если включен) */}
                        {rank.avatarBorder?.halo?.enabled && (
                            <div
                                className={`absolute inset-0 rounded-full z-2 ${rank.avatarBorder.halo.animation === 'pulse' ? 'animate-pulse-infinite' :
                                        rank.avatarBorder.halo.animation === 'wave' ? 'animate-wave-infinite' :
                                            rank.avatarBorder.halo.animation === 'rings' ? 'animate-rings-infinite' : ''
                                        }`}
                                style={{
                                    boxShadow: `0 0 ${rank.avatarBorder.halo.intensity * 3}px ${rank.avatarBorder.halo.intensity}px ${rank.avatarBorder.halo.color || '#e0ff89'}`,
                                    transform: 'scale(1.1)',
                                }}
                            ></div>
                        )}

                        {/* Аватар с границей */}
                        <div
                            className={`rounded-full ${getAvatarBorderClass()} flex items-center justify-center overflow-hidden z-3`}
                            style={getAvatarBorderStyle()}
                        >
                            <div className="w-16 h-16 bg-zinc-800 rounded-full flex items-center justify-center">
                                <span className="text-white text-2xl">A</span>
                            </div>
                        </div>

                        {/* Бейдж */}
                        <Badge />
                    </div>

{/* Информация о ранге */}
<div>
    {/* Имя пользователя с применением Name Effect */}
    <div 
        className="text-sm text-white"
        style={{
            ...(rank.effects?.nameEffect?.enabled ? getNameEffectStyle() : {})
        }}
    >
        User Name
    </div>
    
    {/* Название ранга с применением Title Effect */}
    <h3
        className="text-lg font-semibold"
        style={{
            ...getTitleStyle(),
            ...(!rank.customTitle?.style && {
                color: rank.colorScheme?.text || '#FFFFFF'
            })
        }}
    >
        {rank.customTitle?.text || rank.name || 'Sample Rank'}
    </h3>

    <div className="flex items-center space-x-2 text-zinc-400 text-sm">
        <span>Level {rank.level || 1}</span>
        <span>•</span>
        <span>{rank.requiredCoins || 1000} coins</span>
    </div>
</div>
                </div>

                {/* Прогресс-бар */}
                <div className="w-full bg-zinc-800 rounded-full h-3 mt-auto">
                    <div
                        className="h-3 rounded-full transition-all duration-300"
                        style={{ width: '70%', backgroundColor: rank.ui?.progressBarColor || '#e0ff89' }}
                    ></div>
                </div>

                {/* Кнопка с выбранным стилем */}
                <button
                    className={`px-4 py-2 rounded-[30px] mt-4 ${rank.ui?.buttonStyle === 'standard' ? 'bg-[#e0ff89] text-black' :
                            rank.ui?.buttonStyle === 'gradient' ? 'bg-gradient-to-r from-[#e0ff89] to-[#a0cc50] text-black' :
                                rank.ui?.buttonStyle === 'glow' ? 'bg-[#e0ff89] text-black shadow-lg' :
                                    'bg-[#e0ff89] text-black animate-pulse-infinite'
                        }`}
                    style={{
                        backgroundColor: rank.colorScheme?.primary || '#e0ff89',
                        color: '#000000',
                        ...(rank.ui?.buttonStyle === 'glow' && {
                            boxShadow: `0 0 15px ${rank.colorScheme?.primary || '#e0ff89'}`
                        }),
                        ...(rank.ui?.customCursor && {
                            cursor: `url(${rank.ui.customCursor}), auto`
                        })
                    }}
                >
                    Battle Now
                </button>
            </div>

            {/* Анимационные стили */}
<style jsx global>{`
    @keyframes pulse-infinite {
      0%, 100% { opacity: 1; }
      50% { opacity: 0.5; }
    }
    
    @keyframes rotate-infinite {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    @keyframes shine-infinite {
      0%, 100% { opacity: 0.5; }
      25% { opacity: 0.8; }
      50% { opacity: 1; }
      75% { opacity: 0.8; }
    }
    
    @keyframes bounce-infinite {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-5px); }
    }
    
    @keyframes wave-infinite {
      0%, 100% { transform: scale(1.1); }
      25% { transform: scale(1.15) rotate(5deg); }
      50% { transform: scale(1.2); }
      75% { transform: scale(1.15) rotate(-5deg); }
    }
    
    @keyframes rings-infinite {
      0% { transform: scale(1.1); opacity: 1; }
      50% { transform: scale(1.5); opacity: 0; }
      51% { transform: scale(1.1); opacity: 1; }
      100% { transform: scale(1.1); opacity: 1; }
    }
    
    @keyframes pulse-bg {
      0%, 100% { opacity: 0.7; }
      50% { opacity: 0.4; }
    }
    
    @keyframes fade-bg {
      0%, 100% { opacity: 0.7; }
      50% { opacity: 0; }
    }
    
    @keyframes slide-bg {
      0% { transform: translateX(-100%); }
      100% { transform: translateX(100%); }
    }
    
    @keyframes float {
      0%, 100% { transform: translateY(0px); }
      50% { transform: translateY(-8px); }
    }
    
    @keyframes titlePulse {
      0%, 100% { opacity: 1; }
      50% { opacity: 0.7; }
    }
    
    @keyframes titleWave {
      0% { transform: translate(0px, 0px); }
      25% { transform: translate(2px, -2px); }
      50% { transform: translate(0px, -4px); }
      75% { transform: translate(-2px, -2px); }
      100% { transform: translate(0px, 0px); }
    }
    
    @keyframes titleShine {
      0% { background-position: 0% center; }
      100% { background-position: 200% center; }
    }
    
    .animate-pulse-infinite {
      animation: pulse-infinite 2s infinite;
    }
    
    .animate-rotate-infinite {
      animation: rotate-infinite 3s linear infinite;
    }
    
    .animate-shine-infinite {
      animation: shine-infinite 3s infinite;
    }
    
    .animate-bounce-infinite {
      animation: bounce-infinite 1.5s infinite;
    }
    
    .animate-wave-infinite {
      animation: wave-infinite 3s infinite;
    }
    
    .animate-rings-infinite {
      animation: rings-infinite 3s infinite;
    }
  `}</style>
        </div>
    );
};

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
                ${isDragActive ? 'border-zinc-500 bg-zinc-900' : 'border-zinc-700 hover:border-zinc-600'}
            `}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-zinc-500" size={48} />
            <p className="text-sm text-zinc-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
        </div>
    );
};

// Компонент для выбора цвета с превью
const ColorPicker = ({ value, onChange, label }) => {
    return (
        <div className="flex items-center space-x-2">
            <input
                type="color"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="w-10 h-10 rounded border-2 border-zinc-700 cursor-pointer"
            />
            <Input
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
            />
        </div>
    );
};

// Компонент для выбора нескольких цветов (градиент)
const GradientColorPicker = ({ colors, onChange, maxColors = 4 }) => {
    const addColor = () => {
        if (colors.length < maxColors) {
            onChange([...colors, colors[colors.length - 1]]);
        }
    };

    const removeColor = (index) => {
        if (colors.length > 2) {
            const newColors = [...colors];
            newColors.splice(index, 1);
            onChange(newColors);
        }
    };

    const updateColor = (index, color) => {
        const newColors = [...colors];
        newColors[index] = color;
        onChange(newColors);
    };

    return (
        <div className="space-y-2">
            {colors.map((color, index) => (
                <div key={index} className="flex items-center space-x-2">
                    <ColorPicker
                        value={color}
                        onChange={(value) => updateColor(index, value)}
                    />
                    {colors.length > 2 && (
                        <Button
                            type="button"
                            onClick={() => removeColor(index)}
                            variant="destructive"
                            className="h-10 w-10 p-0"
                        >
                            <Trash2 size={16} />
                        </Button>
                    )}
                </div>
            ))}
            {colors.length < maxColors && (
                <Button
                    type="button"
                    onClick={addColor}
                    className="mt-2 w-full bg-zinc-800"
                >
                    Добавить цвет
                </Button>
            )}
        </div>
    );
};

// Функция для преобразования hex-цвета в RGB
const hexToRgb = (hex) => {
    // Удаляем # из начала строки, если есть
    hex = hex.replace(/^#/, '');

    // Парсим hex-строку в объект RGB
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgb(${r}, ${g}, ${b})`;
};
// Компонент для предпросмотра аватара с обводкой
const AvatarPreview = ({ avatarBorder }) => {
    const borderStyle = avatarBorder.style === 'gradient'
        ? {
            background: `linear-gradient(${avatarBorder.gradient.direction || 'to right'}, ${avatarBorder.gradient.colors.join(', ')})`,
            padding: avatarBorder.width,
            borderRadius: '9999px', // Круглая форма
            width: '100px',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
        : {
            border: `${avatarBorder.width}px ${avatarBorder.style} ${avatarBorder.color}`,
            borderRadius: '9999px', // Круглая форма
            width: '80px',
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        };

    const haloStyle = avatarBorder.halo?.enabled
        ? { boxShadow: `0 0 ${avatarBorder.halo.intensity}px ${avatarBorder.halo.color}` }
        : {};

    const animationClass = avatarBorder.animation?.enabled
        ? avatarBorder.animation.type === 'pulse' ? 'animate-pulse-infinite' :
            avatarBorder.animation.type === 'rotate' ? 'animate-rotate-infinite' :
                avatarBorder.animation.type === 'shine' ? 'animate-shine-infinite' : ''
        : '';

    return (
        <div className="mx-auto my-4">
            <div
                className={`relative ${animationClass}`}
                style={{ ...borderStyle, ...haloStyle }}
            >
                <div className="w-64 h-64 bg-zinc-800 rounded-full flex items-center justify-center overflow-hidden"
                    style={{ width: avatarBorder.style === 'gradient' ? '90px' : '70px', height: avatarBorder.style === 'gradient' ? '90px' : '70px' }}>
                    <span className="text-white text-2xl">A</span>
                </div>
            </div>
        </div>
    );
};

// Компонент для выбора бейджа
const BadgeSelector = ({ badge, setBadge }) => {
    // Получение компонента иконки бейджа
    const getBadgeIcon = (type, props = {}) => {
        switch (type) {
            case 'crown': return <Crown {...props} />;
            case 'star': return <Star {...props} />;
            case 'gem': return <Gem {...props} />;
            case 'gamepad-2': return <Gamepad2 {...props} />;
            case 'hand-metal': return <HandMetal {...props} />;
            case 'magnet': return <Magnet {...props} />;
            case 'lollipop': return <Lollipop {...props} />;
            case 'paw-print': return <PawPrint {...props} />;
            case 'pill': return <Pill {...props} />;
            case 'spade': return <Spade {...props} />;
            case 'zap': return <Zap {...props} />;
            case 'rocket': return <Rocket {...props} />;
            case 'shell': return <Shell {...props} />;
            case 'bitcoin': return <Bitcoin {...props} />;
            case 'cookie': return <Cookie {...props} />;
            case 'donut': return <Donut {...props} />;
            case 'banana': return <Banana {...props} />;
            case 'bone': return <Bone {...props} />;
            case 'dices': return <Dices {...props} />;
            case 'ghost': return <Ghost {...props} />;
            case 'heart': return <Heart {...props} />;
            case 'puzzle': return <Puzzle {...props} />;
            case 'skull': return <Skull {...props} />;
            case 'swords': return <Swords {...props} />;
            case 'audio-waveform': return <AudioWaveform {...props} />;
            case 'drama': return <Drama {...props} />;
            case 'brain': return <Brain {...props} />;
            case 'radiation': return <Radiation {...props} />;
            case 'webhook': return <Webhook {...props} />;
            default: return <Star {...props} />;
        }
    };

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <Label className="text-zinc-300">Enable Badge</Label>
                <Switch
                    checked={badge.enabled}
                    onCheckedChange={(value) => setBadge(prev => ({ ...prev, enabled: value }))}
                />
            </div>

            {badge.enabled && (
                <>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="badgeType" className="text-zinc-300">Badge Type</Label>
                            <Select
                                value={badge.type}
                                onValueChange={(value) => setBadge(prev => ({ ...prev, type: value }))}
                            >
                                <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                    <SelectValue placeholder="Select badge type" />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 border-zinc-800 max-h-60 overflow-auto">
                                    <SelectItem value="crown">Crown</SelectItem>
                                    <SelectItem value="star">Star</SelectItem>
                                    <SelectItem value="gem">Gem</SelectItem>
                                    <SelectItem value="gamepad-2">Gamepad</SelectItem>
                                    <SelectItem value="hand-metal">Hand Metal</SelectItem>
                                    <SelectItem value="magnet">Magnet</SelectItem>
                                    <SelectItem value="lollipop">Lollipop</SelectItem>
                                    <SelectItem value="paw-print">Paw Print</SelectItem>
                                    <SelectItem value="pill">Pill</SelectItem>
                                    <SelectItem value="spade">Spade</SelectItem>
                                    <SelectItem value="zap">Zap</SelectItem>
                                    <SelectItem value="rocket">Rocket</SelectItem>
                                    <SelectItem value="shell">Shell</SelectItem>
                                    <SelectItem value="bitcoin">Bitcoin</SelectItem>
                                    <SelectItem value="cookie">Cookie</SelectItem>
                                    <SelectItem value="donut">Donut</SelectItem>
                                    <SelectItem value="banana">Banana</SelectItem>
                                    <SelectItem value="bone">Bone</SelectItem>
                                    <SelectItem value="dices">Dices</SelectItem>
                                    <SelectItem value="ghost">Ghost</SelectItem>
                                    <SelectItem value="heart">Heart</SelectItem>
                                    <SelectItem value="puzzle">Puzzle</SelectItem>
                                    <SelectItem value="skull">Skull</SelectItem>
                                    <SelectItem value="swords">Swords</SelectItem>
                                    <SelectItem value="audio-waveform">Audio Wave</SelectItem>
                                    <SelectItem value="drama">Drama</SelectItem>
                                    <SelectItem value="brain">Brain</SelectItem>
                                    <SelectItem value="radiation">Radiation</SelectItem>
                                    <SelectItem value="webhook">Webhook</SelectItem>
                                    <SelectItem value="custom">Custom</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                        <div>
                            <Label htmlFor="badgePosition" className="text-zinc-300">Position</Label>
                            <Select
                                value={badge.position}
                                onValueChange={(value) => setBadge(prev => ({ ...prev, position: value }))}
                            >
                                <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                    <SelectValue placeholder="Select position" />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 border-zinc-800">
                                    <SelectItem value="top-right">Top Right</SelectItem>
                                    <SelectItem value="top-left">Top Left</SelectItem>
                                    <SelectItem value="bottom-right">Bottom Right</SelectItem>
                                    <SelectItem value="bottom-left">Bottom Left</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>

                    <div>
                        <Label htmlFor="badgeColor" className="text-zinc-300">Badge Color</Label>
                        <ColorPicker
                            value={badge.color}
                            onChange={(value) => setBadge(prev => ({ ...prev, color: value }))}
                        />
                    </div>

                    <div>
                        <Label htmlFor="badgeBackgroundColor" className="text-zinc-300">Badge Background</Label>
                        <div className="flex items-center mt-2">
                            <Switch
                                checked={badge.background?.enabled || false}
                                onCheckedChange={(value) => setBadge(prev => ({
                                    ...prev,
                                    background: { ...(prev.background || {}), enabled: value }
                                }))}
                                className="mr-2"
                            />
                            {badge.background?.enabled && (
                                <ColorPicker
                                    value={badge.background?.color || "#ffffff"}
                                    onChange={(value) => setBadge(prev => ({
                                        ...prev,
                                        background: { ...(prev.background || {}), color: value }
                                    }))}
                                />
                            )}
                        </div>
                    </div>

                    <div>
                        <Label htmlFor="badgeShadow" className="text-zinc-300">Badge Shadow</Label>
                        <div className="flex items-center mt-2">
                            <Switch
                                checked={badge.shadow?.enabled || false}
                                onCheckedChange={(value) => setBadge(prev => ({
                                    ...prev,
                                    shadow: { ...(prev.shadow || {}), enabled: value }
                                }))}
                                className="mr-2"
                            />
                            {badge.shadow?.enabled && (
                                <ColorPicker
                                    value={badge.shadow?.color || "#000000"}
                                    onChange={(value) => setBadge(prev => ({
                                        ...prev,
                                        shadow: { ...(prev.shadow || {}), color: value }
                                    }))}
                                />
                            )}
                        </div>
                    </div>

                    <div>
                        <Label htmlFor="badgeSize" className="text-zinc-300">Size (px)</Label>
                        <Input
                            id="badgeSize"
                            type="number"
                            value={badge.size}
                            onChange={(e) => setBadge(prev => ({ ...prev, size: parseInt(e.target.value) }))}
                            className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                        />
                    </div>

                    {badge.type === 'custom' && (
                        <div>
                            <Label className="text-zinc-300">Custom Badge Image</Label>
                            <ImageUpload
                                onImageUpload={(file) => {
                                    // В реальном приложении здесь нужно будет загрузить изображение на сервер
                                    // и получить URL для сохранения в badge.customImage
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                        setBadge(prev => ({ ...prev, customImage: reader.result }));
                                    };
                                    reader.readAsDataURL(file);
                                }}
                            />
                            {badge.customImage && (
                                <div className="mt-2 relative">
                                    <img src={badge.customImage} alt="Custom badge" className="h-16 w-16 object-contain" />
                                </div>
                            )}
                        </div>
                    )}

                    <div className="flex items-center justify-between">
                        <Label className="text-zinc-300">Animation</Label>
                        <Switch
                            checked={badge.animation.enabled}
                            onCheckedChange={(value) => setBadge(prev => ({
                                ...prev,
                                animation: { ...prev.animation, enabled: value }
                            }))}
                        />
                    </div>

                    {badge.animation.enabled && (
                        <div>
                            <Label htmlFor="animationType" className="text-zinc-300">Animation Type</Label>
                            <Select
                                value={badge.animation.type}
                                onValueChange={(value) => setBadge(prev => ({
                                    ...prev,
                                    animation: { ...prev.animation, type: value }
                                }))}
                            >
                                <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                    <SelectValue placeholder="Select animation" />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 border-zinc-800">
                                    <SelectItem value="rotate">Rotate</SelectItem>
                                    <SelectItem value="pulse">Pulse</SelectItem>
                                    <SelectItem value="bounce">Bounce</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    )}

                    <div className="p-4 border-2 border-zinc-700 rounded-lg mt-4">
                        <p className="text-zinc-400 text-sm mb-2">Preview:</p>
                        <div className="relative w-24 h-24 bg-zinc-800 rounded-full mx-auto flex items-center justify-center">
                            <span className="text-white text-2xl">A</span>

                            {badge.enabled && (
                                <div
                                    className={`absolute ${badge.position.includes('top') ? 'top-0' : 'bottom-0'} ${badge.position.includes('right') ? 'right-0' : 'left-0'} ${badge.animation.enabled ? `animate-${badge.animation.type}-infinite` : ''} transform ${badge.position === 'top-right' ? 'translate-x-1/3 -translate-y-1/3' :
                                            badge.position === 'top-left' ? '-translate-x-1/3 -translate-y-1/3' :
                                                badge.position === 'bottom-right' ? 'translate-x-1/3 translate-y-1/3' :
                                                    '-translate-x-1/3 translate-y-1/3'
                                        }`}
                                    style={{
                                        backgroundColor: badge.background?.enabled ? badge.background.color : 'transparent',
                                        padding: badge.background?.enabled ? '3px' : '0',
                                        borderRadius: badge.background?.enabled ? '50%' : '0',
                                        boxShadow: badge.shadow?.enabled ? `0 0 5px ${badge.shadow.color}` : 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {badge.type === 'custom' && badge.customImage ? (
                                        <img
                                            src={badge.customImage}
                                            alt="Badge"
                                            style={{
                                                width: `${badge.size}px`,
                                                height: `${badge.size}px`
                                            }}
                                        />
                                    ) : (
                                        getBadgeIcon(badge.type, {
                                            size: badge.size,
                                            style: { color: badge.color }
                                        })
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

// Компонент для управления цветовой схемой
const ColorSchemeEditor = ({ colorScheme, setColorScheme }) => {
    return (
        <div className="space-y-4">
            <div>
                <Label htmlFor="primaryColor" className="text-zinc-300">Primary Color</Label>
                <p className="text-zinc-500 text-xs mb-1">Используется для кнопок, выделенных элементов</p>
                <ColorPicker
                    value={colorScheme.primary}
                    onChange={(value) => setColorScheme(prev => ({ ...prev, primary: value }))}
                />
            </div>

            <div>
                <Label htmlFor="secondaryColor" className="text-zinc-300">Secondary Color</Label>
                <p className="text-zinc-500 text-xs mb-1">Используется для фонов, контейнеров</p>
                <ColorPicker
                    value={colorScheme.secondary}
                    onChange={(value) => setColorScheme(prev => ({ ...prev, secondary: value }))}
                />
            </div>

            <div>
                <Label htmlFor="textColor" className="text-zinc-300">Text Color</Label>
                <p className="text-zinc-500 text-xs mb-1">Основной цвет текста</p>
                <ColorPicker
                    value={colorScheme.text}
                    onChange={(value) => setColorScheme(prev => ({ ...prev, text: value }))}
                />
            </div>

            <div className="p-4 border-2 border-zinc-700 rounded-lg mt-4">
                <p className="text-zinc-400 text-sm mb-2">Preview:</p>
                <div
                    className="p-4 rounded-lg"
                    style={{ backgroundColor: colorScheme.secondary }}
                >
                    <h3
                        className="text-lg font-bold mb-2"
                        style={{ color: colorScheme.text }}
                    >
                        Sample Rank
                    </h3>
                    <div
                        className="p-2 rounded-lg text-center"
                        style={{ backgroundColor: colorScheme.primary, color: '#000000' }}
                    >
                        Button Example
                    </div>
                </div>
            </div>
        </div>
    );
};

// Компонент для настройки названия ранга
const CustomTitleEditor = ({ customTitle, setCustomTitle }) => {
    // Состояние для стиля названия
const [titleStyle, setTitleStyle] = useState(customTitle.style || {
    color: '#FFFFFF',
    effect: 'none',
    effectColor: '#e0ff89',
    intensity: 3,
    animation: 'none',
    gradientColors: ['#FFFFFF', '#e0ff89']
});

    // Обновление стиля названия
    useEffect(() => {
        setCustomTitle(prev => ({
            ...prev,
            style: titleStyle
        }));
    }, [titleStyle, setCustomTitle]);

    return (
        <div className="space-y-4">
            <div>
                <Label htmlFor="titleText" className="text-zinc-300">Title Text</Label>
                <Input
                    id="titleText"
                    value={customTitle.text || ''}
                    onChange={(e) => setCustomTitle(prev => ({ ...prev, text: e.target.value }))}
                    placeholder="Custom rank title"
                    className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                />
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="fontSize" className="text-zinc-300">Font Size (px)</Label>
                    <Input
                        id="fontSize"
                        type="number"
                        value={customTitle.fontSize || 18}
                        onChange={(e) => setCustomTitle(prev => ({ ...prev, fontSize: parseInt(e.target.value) }))}
                        className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                    />
                </div>

                <div>
                    <Label htmlFor="fontWeight" className="text-zinc-300">Font Weight</Label>
                    <Select
                        value={customTitle.fontWeight || 'bold'}
                        onValueChange={(value) => setCustomTitle(prev => ({ ...prev, fontWeight: value }))}
                    >
                        <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                            <SelectValue placeholder="Select weight" />
                        </SelectTrigger>
                        <SelectContent className="bg-zinc-950 border-zinc-800">
                            <SelectItem value="normal">Normal</SelectItem>
                            <SelectItem value="bold">Bold</SelectItem>
                            <SelectItem value="500">Medium (500)</SelectItem>
                            <SelectItem value="600">Semi-Bold (600)</SelectItem>
                            <SelectItem value="800">Extra-Bold (800)</SelectItem>
                            <SelectItem value="900">Black (900)</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="fontFamily" className="text-zinc-300">Font Family</Label>
                    <Select
                        value={customTitle.fontFamily || 'Inter'}
                        onValueChange={(value) => setCustomTitle(prev => ({ ...prev, fontFamily: value }))}
                    >
                        <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                            <SelectValue placeholder="Select font" />
                        </SelectTrigger>
                        <SelectContent className="bg-zinc-950 border-zinc-800">
                            <SelectItem value="Inter">Inter</SelectItem>
                            <SelectItem value="Arial">Arial</SelectItem>
                            <SelectItem value="Helvetica">Helvetica</SelectItem>
                            <SelectItem value="Times New Roman">Times New Roman</SelectItem>
                            <SelectItem value="Georgia">Georgia</SelectItem>
                            <SelectItem value="Verdana">Verdana</SelectItem>
                            <SelectItem value="monospace">Monospace</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Label htmlFor="letterSpacing" className="text-zinc-300">Letter Spacing (px)</Label>
                    <Input
                        id="letterSpacing"
                        type="number"
                        value={customTitle.letterSpacing || 0}
                        onChange={(e) => setCustomTitle(prev => ({ ...prev, letterSpacing: parseInt(e.target.value) }))}
                        className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                    />
                </div>
            </div>

            {/* Добавляем настройки стиля и эффектов для названия */}
            <div className="mt-4 border-t-2 border-zinc-700 pt-4">
                <Label className="text-zinc-300 text-lg mb-2 block">Rank Title Style & Effects</Label>

                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="titleColor" className="text-zinc-300">Title Color</Label>
                        <ColorPicker
                            value={titleStyle.color}
                            onChange={(value) => setTitleStyle(prev => ({ ...prev, color: value }))}
                        />
                    </div>

                    <div>
                        <Label htmlFor="titleEffect" className="text-zinc-300">Effect</Label>
                        <Select
                            value={titleStyle.effect}
                            onValueChange={(value) => setTitleStyle(prev => ({ ...prev, effect: value }))}
                        >
                            <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                <SelectValue placeholder="Select effect" />
                            </SelectTrigger>
                            <SelectContent className="bg-zinc-950 border-zinc-800">
    <SelectItem value="none">None</SelectItem>
    <SelectItem value="shadow">Shadow</SelectItem>
    <SelectItem value="glow">Glow</SelectItem>
    <SelectItem value="gradient">Gradient</SelectItem>
</SelectContent>
                        </Select>
                    </div>
                </div>

                {titleStyle.effect && (
                    <>
                        <div className="mt-2">
                            {titleStyle.effect === 'gradient' ? (
                                <div>
                                    <Label className="text-zinc-300 mb-2 block">Gradient Colors</Label>
                                    <GradientColorPicker
                                        colors={titleStyle.gradientColors || ['#FFFFFF', '#e0ff89']}
                                        onChange={(colors) => setTitleStyle(prev => ({ ...prev, gradientColors: colors }))}
                                    />
                                </div>
                            ) : (
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="effectColor" className="text-zinc-300">Effect Color</Label>
                                        <ColorPicker
                                            value={titleStyle.effectColor}
                                            onChange={(value) => setTitleStyle(prev => ({ ...prev, effectColor: value }))}
                                        />
                                    </div>

                                    <div>
                                        <Label htmlFor="effectIntensity" className="text-zinc-300">Intensity (1-10)</Label>
                                        <Input
                                            id="effectIntensity"
                                            type="number"
                                            min="1"
                                            max="10"
                                            value={titleStyle.intensity || 3}
                                            onChange={(e) => setTitleStyle(prev => ({ ...prev, intensity: parseInt(e.target.value) }))}
                                            className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}

                <div className="mt-4">
                    <Label htmlFor="titleAnimation" className="text-zinc-300">Animation</Label>
                    <Select
                        value={titleStyle.animation}
                        onValueChange={(value) => setTitleStyle(prev => ({ ...prev, animation: value }))}
                    >
                        <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                            <SelectValue placeholder="Select animation" />
                        </SelectTrigger>
                        <SelectContent className="bg-zinc-950 border-zinc-800">
    <SelectItem value="none">None</SelectItem>
    <SelectItem value="pulse">Pulse</SelectItem>
    <SelectItem value="wave">Wave</SelectItem>
    <SelectItem value="shine">Shine</SelectItem>
</SelectContent>
                    </Select>
                </div>
            </div>

            <div className="p-4 border-2 border-zinc-700 rounded-lg mt-4">
                <p className="text-zinc-400 text-sm mb-2">Preview:</p>
                <div className="p-4 bg-zinc-800 rounded-lg">
                    <h3 style={{
                        fontSize: `${customTitle.fontSize}px`,
                        fontWeight: customTitle.fontWeight || 'bold',
                        fontFamily: customTitle.fontFamily || 'Inter',
                        letterSpacing: `${customTitle.letterSpacing}px`,
                        color: titleStyle.color || '#FFFFFF',
                        // Эффекты
                        ...(titleStyle.effect === 'shadow' && {
                            textShadow: `0 0 ${titleStyle.intensity}px ${titleStyle.effectColor}`
                        }),
                        ...(titleStyle.effect === 'glow' && {
                            textShadow: `0 0 ${titleStyle.intensity * 2}px ${titleStyle.effectColor}, 0 0 ${titleStyle.intensity}px ${titleStyle.effectColor}`
                        }),
                        ...(titleStyle.effect === 'gradient' && {
                            background: `linear-gradient(to right, ${titleStyle.gradientColors?.join(', ') || `${titleStyle.color}, ${titleStyle.effectColor}`})`,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            backgroundClip: 'text',
                            textFillColor: 'transparent'
                        }),
                        // Анимации
                        ...(titleStyle.animation === 'pulse' && {
                            animation: 'titlePulse 2s infinite'
                        }),
                        ...(titleStyle.animation === 'wave' && {
                            animation: 'titleWave 2s infinite'
                        }),
                        ...(titleStyle.animation === 'shine' && {
                            animation: 'titleShine 2s linear infinite',
                            backgroundSize: '200% auto',
                            background: titleStyle.effect !== 'gradient'
                                ? `linear-gradient(to right, ${titleStyle.color} 0%, ${titleStyle.effectColor || '#e0ff89'} 50%, ${titleStyle.color} 100%)`
                                : undefined,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            backgroundClip: 'text',
                            textFillColor: 'transparent'
                        })
                    }}>
                        {customTitle.text || 'Sample Rank Title'}
                    </h3>
                </div>
            </div>
           <style jsx global>{`
    @keyframes titlePulse {
        0%, 100% { opacity: 1; }
        50% { opacity: 0.7; }
    }
    
    @keyframes titleWave {
        0% { transform: translate(0px, 0px); }
        25% { transform: translate(2px, -2px); }
        50% { transform: translate(0px, -4px); }
        75% { transform: translate(-2px, -2px); }
        100% { transform: translate(0px, 0px); }
    }
    
    @keyframes titleShine {
        0% { background-position: 0% center; }
        100% { background-position: 200% center; }
    }
    
    @keyframes pulse-infinite {
        0%, 100% { opacity: 1; }
        50% { opacity: 0.5; }
    }
    
    @keyframes rotate-infinite {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    
    @keyframes shine-infinite {
        0%, 100% { opacity: 0.5; }
        25% { opacity: 0.8; }
        50% { opacity: 1; }
        75% { opacity: 0.8; }
    }
    
    @keyframes bounce-infinite {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-5px); }
    }
    
    @keyframes wave-infinite {
        0%, 100% { transform: scale(1.1); }
        25% { transform: scale(1.15) rotate(5deg); }
        50% { transform: scale(1.2); }
        75% { transform: scale(1.15) rotate(-5deg); }
    }
    
    @keyframes rings-infinite {
        0% { transform: scale(1.1); opacity: 1; }
        50% { transform: scale(1.5); opacity: 0; }
        51% { transform: scale(1.1); opacity: 1; }
        100% { transform: scale(1.1); opacity: 1; }
    }
`}</style>
        </div>
    );
};

// Компонент для управления эффектами
const EffectsEditor = ({ effects, setEffects }) => {
    return (
        <div className="space-y-4">

            {/* Particles Effect */}
            <div className="border-2 border-zinc-700 rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                    <Label className="text-zinc-300 text-lg">Particles Effect</Label>
                    <Switch
                        checked={effects.particles.enabled}
                        onCheckedChange={(value) => setEffects(prev => ({
                            ...prev,
                            particles: { ...prev.particles, enabled: value }
                        }))}
                    />
                </div>

                {effects.particles.enabled && (
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="particlesType" className="text-zinc-300">Particle Type</Label>
                            <Select
                                value={effects.particles.type}
                                onValueChange={(value) => setEffects(prev => ({
                                    ...prev,
                                    particles: { ...prev.particles, type: value }
                                }))}
                            >
                                <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                    <SelectValue placeholder="Select type" />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 border-zinc-800">
                                    <SelectItem value="sparkles">Sparkles</SelectItem>
                                    <SelectItem value="bubbles">Bubbles</SelectItem>
                                    <SelectItem value="stars">Stars</SelectItem>
                                    <SelectItem value="fire">Fire</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                        <div>
                            <Label htmlFor="particlesColor" className="text-zinc-300">Color</Label>
                            <ColorPicker
                                value={effects.particles.color}
                                onChange={(value) => setEffects(prev => ({
                                    ...prev,
                                    particles: { ...prev.particles, color: value }
                                }))}
                            />
                        </div>

                        <div>
                            <Label htmlFor="particlesDensity" className="text-zinc-300">Density (1-10)</Label>
                            <Input
                                id="particlesDensity"
                                type="number"
                                min="1"
                                max="10"
                                value={effects.particles.density}
                                onChange={(e) => setEffects(prev => ({
                                    ...prev,
                                    particles: { ...prev.particles, density: parseInt(e.target.value) }
                                }))}
                                className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Name Effect */}
            <div className="border-2 border-zinc-700 rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                    <Label className="text-zinc-300 text-lg">Name Effect</Label>
                    <Switch
                        checked={effects.nameEffect.enabled}
                        onCheckedChange={(value) => setEffects(prev => ({
                            ...prev,
                            nameEffect: { ...prev.nameEffect, enabled: value }
                        }))}
                    />
                </div>

                {effects.nameEffect.enabled && (
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="nameEffectType" className="text-zinc-300">Effect Type</Label>
                            <Select
                                value={effects.nameEffect.type}
                                onValueChange={(value) => setEffects(prev => ({
                                    ...prev,
                                    nameEffect: { ...prev.nameEffect, type: value }
                                }))}
                            >
                                <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                    <SelectValue placeholder="Select type" />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 border-zinc-800">
                                    <SelectItem value="shadow">Shadow</SelectItem>
                                    <SelectItem value="glow">Glow</SelectItem>
                                    <SelectItem value="gradient">Gradient</SelectItem>
                                    <SelectItem value="highlight">Highlight</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                        {effects.nameEffect.type === 'gradient' ? (
                            <div>
                                <Label className="text-zinc-300">Gradient Colors</Label>
                                <GradientColorPicker
                                    colors={effects.nameEffect.gradientColors || ['#FFFFFF', effects.nameEffect.color || '#e0ff89']}
                                    onChange={(colors) => setEffects(prev => ({
                                        ...prev,
                                        nameEffect: {
                                            ...prev.nameEffect,
                                            gradientColors: colors
                                        }
                                    }))}
                                />
                            </div>
                        ) : (
                            <div>
                                <Label htmlFor="nameEffectColor" className="text-zinc-300">Effect Color</Label>
                                <ColorPicker
                                    value={effects.nameEffect.color}
                                    onChange={(value) => setEffects(prev => ({
                                        ...prev,
                                        nameEffect: { ...prev.nameEffect, color: value }
                                    }))}
                                />
                            </div>
                        )}

                        {/* Добавляем выбор цвета текста для эффекта Highlight */}
                        {effects.nameEffect.type === 'highlight' && (
                            <div>
                                <Label htmlFor="nameTextColor" className="text-zinc-300">Text Color</Label>
                                <ColorPicker
                                    value={effects.nameEffect.textColor || '#000000'}
                                    onChange={(value) => setEffects(prev => ({
                                        ...prev,
                                        nameEffect: { ...prev.nameEffect, textColor: value }
                                    }))}
                                />
                            </div>
                        )}

                        <div>
                            <Label htmlFor="nameEffectIntensity" className="text-zinc-300">Intensity (1-10)</Label>
                            <Input
                                id="nameEffectIntensity"
                                type="number"
                                min="1"
                                max="10"
                                value={effects.nameEffect.intensity}
                                onChange={(e) => setEffects(prev => ({
                                    ...prev,
                                    nameEffect: { ...prev.nameEffect, intensity: parseInt(e.target.value) }
                                }))}
                                className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Background Effect */}
            <div className="border-2 border-zinc-700 rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                    <Label className="text-zinc-300 text-lg">Background Effect</Label>
                    <Switch
                        checked={effects.backgroundEffect?.enabled || false}
                        onCheckedChange={(value) => setEffects(prev => ({
                            ...prev,
                            backgroundEffect: { ...(prev.backgroundEffect || {}), enabled: value }
                        }))}
                    />
                </div>

                {effects.backgroundEffect?.enabled && (
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="backgroundEffectType" className="text-zinc-300">Effect Type</Label>
                            <Select
                                value={effects.backgroundEffect.type || 'overlay'}
                                onValueChange={(value) => setEffects(prev => ({
                                    ...prev,
                                    backgroundEffect: { ...prev.backgroundEffect, type: value }
                                }))}
                            >
                                <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                    <SelectValue placeholder="Select type" />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 border-zinc-800">
                                    <SelectItem value="blur">Blur</SelectItem>
                                    <SelectItem value="overlay">Overlay</SelectItem>
                                    <SelectItem value="glow">Glow</SelectItem>
                                    <SelectItem value="tint">Tint</SelectItem>
                                    <SelectItem value="gradient">Gradient</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                        {/* Добавляем переключатель для выбора между одним цветом и градиентом */}
                        {(effects.backgroundEffect.type === 'overlay' || effects.backgroundEffect.type === 'gradient') && (
                            <div className="flex items-center">
                                <Label className="text-zinc-300 mr-2">Use Gradient</Label>
                                <Switch
                                    checked={effects.backgroundEffect.useGradient || false}
                                    onCheckedChange={(value) => setEffects(prev => ({
                                        ...prev,
                                        backgroundEffect: {
                                            ...prev.backgroundEffect,
                                            useGradient: value,
                                            gradientColors: value ?
                                                prev.backgroundEffect.gradientColors || ['rgba(0,0,0,0.5)', prev.backgroundEffect.color || 'rgba(0,0,0,0.8)'] :
                                                prev.backgroundEffect.gradientColors
                                        }
                                    }))}
                                />
                            </div>
                        )}

                        {(effects.backgroundEffect.type === 'overlay' || effects.backgroundEffect.type === 'gradient' || effects.backgroundEffect.type === 'glow' || effects.backgroundEffect.type === 'tint') && (
                            <>
                                {effects.backgroundEffect.useGradient ? (
                                    <>
                                        <div>
                                            <Label className="text-zinc-300">Gradient Colors</Label>
                                            <GradientColorPicker
                                                colors={effects.backgroundEffect.gradientColors || ['rgba(0,0,0,0.5)', 'rgba(0,0,0,0.8)']}
                                                onChange={(colors) => setEffects(prev => ({
                                                    ...prev,
                                                    backgroundEffect: {
                                                        ...prev.backgroundEffect,
                                                        gradientColors: colors
                                                    }
                                                }))}
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="gradientDirection" className="text-zinc-300">Gradient Direction</Label>
                                            <Select
                                                value={effects.backgroundEffect.gradientDirection || 'to bottom'}
                                                onValueChange={(value) => setEffects(prev => ({
                                                    ...prev,
                                                    backgroundEffect: {
                                                        ...prev.backgroundEffect,
                                                        gradientDirection: value
                                                    }
                                                }))}
                                            >
                                                <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                                    <SelectValue placeholder="Select direction" />
                                                </SelectTrigger>
                                                <SelectContent className="bg-zinc-950 border-zinc-800">
                                                    <SelectItem value="to right">To Right</SelectItem>
                                                    <SelectItem value="to left">To Left</SelectItem>
                                                    <SelectItem value="to bottom">To Bottom</SelectItem>
                                                    <SelectItem value="to top">To Top</SelectItem>
                                                    <SelectItem value="to bottom right">To Bottom Right</SelectItem>
                                                    <SelectItem value="to bottom left">To Bottom Left</SelectItem>
                                                    <SelectItem value="to top right">To Top Right</SelectItem>
                                                    <SelectItem value="to top left">To Top Left</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <Label htmlFor="backgroundEffectColor" className="text-zinc-300">Color</Label>
                                        <ColorPicker
                                            value={effects.backgroundEffect.color || 'rgba(0,0,0,0.5)'}
                                            onChange={(value) => setEffects(prev => ({
                                                ...prev,
                                                backgroundEffect: { ...prev.backgroundEffect, color: value }
                                            }))}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        <div>
                            <Label htmlFor="backgroundEffectIntensity" className="text-zinc-300">Intensity (1-10)</Label>
                            <Input
                                id="backgroundEffectIntensity"
                                type="number"
                                min="1"
                                max="10"
                                value={effects.backgroundEffect.intensity || 5}
                                onChange={(e) => setEffects(prev => ({
                                    ...prev,
                                    backgroundEffect: { ...prev.backgroundEffect, intensity: parseInt(e.target.value) }
                                }))}
                                className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                            />
                        </div>

                        <div className="border-t-2 border-zinc-700 pt-4 mt-4">
                            <div className="flex items-center justify-between mb-2">
                                <Label className="text-zinc-300">Animation</Label>
                                <Switch
                                    checked={effects.backgroundEffect.animation?.enabled || false}
                                    onCheckedChange={(value) => setEffects(prev => ({
                                        ...prev,
                                        backgroundEffect: {
                                            ...prev.backgroundEffect,
                                            animation: { ...(prev.backgroundEffect.animation || {}), enabled: value }
                                        }
                                    }))}
                                />
                            </div>

                            {effects.backgroundEffect.animation?.enabled && (
                                <div className="space-y-4">
                                    <div>
                                        <Label htmlFor="bgAnimationType" className="text-zinc-300">Animation Type</Label>
                                        <Select
                                            value={effects.backgroundEffect.animation.type || 'pulse'}
                                            onValueChange={(value) => setEffects(prev => ({
                                                ...prev,
                                                backgroundEffect: {
                                                    ...prev.backgroundEffect,
                                                    animation: { ...prev.backgroundEffect.animation, type: value }
                                                }
                                            }))}
                                        >
                                            <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                                <SelectValue placeholder="Select type" />
                                            </SelectTrigger>
                                            <SelectContent className="bg-zinc-950 border-zinc-800">
                                                <SelectItem value="pulse">Pulse</SelectItem>
                                                <SelectItem value="fade">Fade</SelectItem>
                                                <SelectItem value="wave">Wave</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <div>
                                        <Label htmlFor="bgAnimationDuration" className="text-zinc-300">Duration (seconds)</Label>
                                        <Input
                                            id="bgAnimationDuration"
                                            type="number"
                                            min="1"
                                            max="10"
                                            value={effects.backgroundEffect.animation.duration || 3}
                                            onChange={(e) => setEffects(prev => ({
                                                ...prev,
                                                backgroundEffect: {
                                                    ...prev.backgroundEffect,
                                                    animation: { ...prev.backgroundEffect.animation, duration: parseInt(e.target.value) }
                                                }
                                            }))}
                                            className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* Background Particles */}
            <div className="border-2 border-zinc-700 rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                    <Label className="text-zinc-300 text-lg">Background Particles</Label>
                    <Switch
                        checked={effects.backgroundParticles?.enabled || false}
                        onCheckedChange={(value) => setEffects(prev => ({
                            ...prev,
                            backgroundParticles: { ...(prev.backgroundParticles || {}), enabled: value }
                        }))}
                    />
                </div>

                {effects.backgroundParticles?.enabled && (
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="bgParticlesType" className="text-zinc-300">Particle Type</Label>
                            <Select
                                value={effects.backgroundParticles.type || 'sparkles'}
                                onValueChange={(value) => setEffects(prev => ({
                                    ...prev,
                                    backgroundParticles: { ...prev.backgroundParticles, type: value }
                                }))}
                            >
                                <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                    <SelectValue placeholder="Select type" />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 border-zinc-800">
                                    <SelectItem value="sparkles">Sparkles</SelectItem>
                                    <SelectItem value="bubbles">Bubbles</SelectItem>
                                    <SelectItem value="stars">Stars</SelectItem>
                                    <SelectItem value="fire">Fire</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                        <div>
                            <Label htmlFor="bgParticlesColor" className="text-zinc-300">Color</Label>
                            <ColorPicker
                                value={effects.backgroundParticles.color || '#FFFFFF'}
                                onChange={(value) => setEffects(prev => ({
                                    ...prev,
                                    backgroundParticles: { ...prev.backgroundParticles, color: value }
                                }))}
                            />
                        </div>

                        <div>
                            <Label htmlFor="bgParticlesDensity" className="text-zinc-300">Density (1-10)</Label>
                            <Input
                                id="bgParticlesDensity"
                                type="number"
                                min="1"
                                max="10"
                                value={effects.backgroundParticles.density || 3}
                                onChange={(e) => setEffects(prev => ({
                                    ...prev,
                                    backgroundParticles: { ...prev.backgroundParticles, density: parseInt(e.target.value) }
                                }))}
                                className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Preview */}
            <div className="p-4 border-2 border-zinc-700 rounded-lg mt-4">
                <p className="text-zinc-400 text-sm mb-4">Effects Preview:</p>
                <div className="flex justify-center">
                    <div className="relative">
                        {/* Background particles */}
                        {effects.backgroundParticles?.enabled && (
                            <div className="absolute inset-0 overflow-hidden pointer-events-none">
                                {Array.from({ length: (effects.backgroundParticles.density || 3) * 5 }).map((_, i) => {
                                    const size = Math.random() * 5 + 2;
                                    return (
                                        <div
                                            key={i}
                                            className="absolute animate-float"
                                            style={{
                                                left: `${Math.random() * 100}%`,
                                                top: `${Math.random() * 100}%`,
                                                color: effects.backgroundParticles.color || '#FFFFFF',
                                                opacity: Math.random() * 0.7 + 0.3,
                                                animation: `float ${Math.random() * 2 + 1}s infinite ease-in-out`,
                                                transform: `translateY(0px) scale(${Math.random() * 0.5 + 0.5})`,
                                            }}
                                        >
                                            {effects.backgroundParticles.type === 'sparkles' && (
                                                <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                            )}
                                            {effects.backgroundParticles.type === 'bubbles' && (
                                                <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                                                    <circle cx="12" cy="12" r="10" />
                                                </svg>
                                            )}
                                            {effects.backgroundParticles.type === 'stars' && (
                                                <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                            )}
                                            {effects.backgroundParticles.type === 'fire' && (
                                                <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                                                    <path d="M12 2C10.97 2 9.94 2.16 8.92 2.5C9.61 3.43 10 4.63 10 5.9C10 8.15 8.21 10 6 10C5.07 10 4.21 9.72 3.5 9.22C3.18 10.13 3 11.05 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 6.42 16.97 2 12 2Z" />
                                                </svg>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        {/* Avatar with halo */}
                        <div className="relative w-24 h-24 bg-zinc-800 rounded-full mx-auto flex items-center justify-center">

                            {/* Particles */}
                            {effects.particles.enabled && (
                                <div className="absolute inset-0 overflow-hidden pointer-events-none">
                                    {Array.from({ length: effects.particles.density * 5 }).map((_, i) => {
                                        const size = Math.random() * 5 + 2;
                                        return (
                                            <div
                                                key={i}
                                                className="absolute animate-float"
                                                style={{
                                                    left: `${Math.random() * 100}%`,
                                                    top: `${Math.random() * 100}%`,
                                                    color: effects.particles.color,
                                                    opacity: Math.random() * 0.7 + 0.3,
                                                    animation: `float ${Math.random() * 2 + 1}s infinite ease-in-out`,
                                                    transform: `translateY(0px) scale(${Math.random() * 0.5 + 0.5})`,
                                                }}
                                            >
                                                {effects.particles.type === 'sparkles' && (
                                                    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                    </svg>
                                                )}
                                                {effects.particles.type === 'bubbles' && (
                                                    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                                                        <circle cx="12" cy="12" r="10" />
                                                    </svg>
                                                )}
                                                {effects.particles.type === 'stars' && (
                                                    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                    </svg>
                                                )}
                                                {effects.particles.type === 'fire' && (
                                                    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
                                                        <path d="M12 2C10.97 2 9.94 2.16 8.92 2.5C9.61 3.43 10 4.63 10 5.9C10 8.15 8.21 10 6 10C5.07 10 4.21 9.72 3.5 9.22C3.18 10.13 3 11.05 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 6.42 16.97 2 12 2Z" />
                                                    </svg>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            <span className="text-white text-2xl">A</span>
                        </div>

                        {/* Name with effect */}
                        <div className="mt-4 text-center">
                            <h3
                                className="text-white text-xl font-bold"
                                style={{
                                    ...(effects.nameEffect.enabled && effects.nameEffect.type === 'shadow' && {
                                        textShadow: `0 0 ${effects.nameEffect.intensity}px ${effects.nameEffect.color}`
                                    }),
                                    ...(effects.nameEffect.enabled && effects.nameEffect.type === 'glow' && {
                                        textShadow: `0 0 ${effects.nameEffect.intensity * 2}px ${effects.nameEffect.color}, 0 0 ${effects.nameEffect.intensity}px ${effects.nameEffect.color}`
                                    }),
                                    ...(effects.nameEffect.enabled && effects.nameEffect.type === 'gradient' && {
                                        background: effects.nameEffect.gradientColors?.length >= 2
                                            ? `linear-gradient(to right, ${effects.nameEffect.gradientColors.join(', ')})`
                                            : `linear-gradient(to right, white, ${effects.nameEffect.color})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        backgroundClip: 'text'
                                    }),
                                    ...(effects.nameEffect.enabled && effects.nameEffect.type === 'highlight' && {
                                        backgroundColor: `${effects.nameEffect.color}`,
                                        color: effects.nameEffect.textColor || 'black',
                                        padding: '0 8px',
                                        borderRadius: '4px'
                                    })
                                }}
                            >
                                Sample Rank
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

<style jsx global>{`
    @keyframes float {
        0%, 100% { transform: translateY(0px); }
        50% { transform: translateY(-8px); }
    }
    
    @keyframes shine {
        0% { opacity: 0.5; }
        50% { opacity: 1; }
        100% { opacity: 0.5; }
    }
    
    @keyframes wave-infinite {
        0%, 100% { transform: scale(1.1); }
        25% { transform: scale(1.15) rotate(5deg); }
        50% { transform: scale(1.2); }
        75% { transform: scale(1.15) rotate(-5deg); }
    }
    
    @keyframes rings-infinite {
        0% { transform: scale(1.1); opacity: 1; }
        50% { transform: scale(1.5); opacity: 0; }
        51% { transform: scale(1.1); opacity: 1; }
        100% { transform: scale(1.1); opacity: 1; }
    }
    
    @keyframes pulse-infinite {
        0%, 100% { opacity: 1; }
        50% { opacity: 0.5; }
    }
    
    @keyframes rotate-infinite {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    
    @keyframes shine-infinite {
        0%, 100% { opacity: 0.5; }
        25% { opacity: 0.8; }
        50% { opacity: 1; }
        75% { opacity: 0.8; }
    }
    
    @keyframes bounce-infinite {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-5px); }
    }
    
    .animate-pulse-infinite {
        animation: pulse-infinite 2s infinite;
    }
    
    .animate-rotate-infinite {
        animation: rotate-infinite 3s linear infinite;
    }
    
    .animate-shine-infinite {
        animation: shine-infinite 3s infinite;
    }
    
    .animate-bounce-infinite {
        animation: bounce-infinite 1.5s infinite;
    }
    
    .animate-wave-infinite {
        animation: wave-infinite 3s infinite;
    }
    
    .animate-rings-infinite {
        animation: rings-infinite 3s infinite;
    }
`}</style>
        </div>
    );
};

// Компонент для настройки интерфейса ранга
const UIEditor = ({ ui, setUI }) => {
    return (
        <div className="space-y-4">
            <div>
                <Label htmlFor="progressBarColor" className="text-zinc-300">Progress Bar Color</Label>
                <ColorPicker
                    value={ui.progressBarColor}
                    onChange={(value) => setUI(prev => ({ ...prev, progressBarColor: value }))}
                />
            </div>

            <div>
                <Label htmlFor="buttonStyle" className="text-zinc-300">Button Style</Label>
                <Select
                    value={ui.buttonStyle}
                    onValueChange={(value) => setUI(prev => ({ ...prev, buttonStyle: value }))}
                >
                    <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                        <SelectValue placeholder="Select style" />
                    </SelectTrigger>
                    <SelectContent className="bg-zinc-950 border-zinc-800">
                        <SelectItem value="standard">Standard</SelectItem>
                        <SelectItem value="gradient">Gradient</SelectItem>
                        <SelectItem value="glow">Glow</SelectItem>
                        <SelectItem value="animated">Animated</SelectItem>
                    </SelectContent>
                </Select>
            </div>

            <div>
                <Label htmlFor="customCursor" className="text-zinc-300">Custom Cursor</Label>
                <ImageUpload
                    onImageUpload={(file) => {
                        // В реальном приложении здесь нужно будет загрузить изображение на сервер
                        // и получить URL для сохранения в ui.customCursor
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setUI(prev => ({ ...prev, customCursor: reader.result }));
                        };
                        reader.readAsDataURL(file);
                    }}
                />
                {ui.customCursor && (
                    <div className="mt-2 relative">
                        <img src={ui.customCursor} alt="Custom cursor" className="h-8 object-contain" />
                        <Button
                            type="button"
                            onClick={() => setUI(prev => ({ ...prev, customCursor: '' }))}
                            className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                            size="sm"
                        >
                            X
                        </Button>
                    </div>
                )}
            </div>

            <div className="p-4 border-2 border-zinc-700 rounded-lg mt-4">
                <p className="text-zinc-400 text-sm mb-2">Preview:</p>
                <div className="space-y-4">
                    <div className="w-full bg-zinc-800 rounded-full h-3">
                        <div
                            className="h-3 rounded-full transition-all duration-300"
                            style={{ width: '70%', backgroundColor: ui.progressBarColor }}
                        ></div>
                    </div>

                    <button
                        className={`px-4 py-2 rounded-[30px] ${ui.buttonStyle === 'standard' ? 'bg-[#e0ff89] text-black' :
                                ui.buttonStyle === 'gradient' ? 'bg-gradient-to-r from-[#e0ff89] to-[#a0cc50] text-black' :
                                    ui.buttonStyle === 'glow' ? 'bg-[#e0ff89] text-black shadow-lg' :
                                        'bg-[#e0ff89] text-black animate-pulse-infinite'
                            }`}
                        style={{
                            ...(ui.buttonStyle === 'glow' && {
                                boxShadow: '0 0 15px #e0ff89'
                            }),
                            ...(ui.customCursor && {
                                cursor: `url(${ui.customCursor}), auto`
                            })
                        }}
                    >
                        Button Example
                    </button>
                </div>
            </div>
        </div>
    );
};

// Компонент для настройки границы аватара
const AvatarBorderEditor = ({ avatarBorder, setAvatarBorder }) => {
    // Состояние для цветов градиента
    const [gradientColor1, setGradientColor1] = useState(avatarBorder.gradient.colors[0] || '#e0ff89');
    const [gradientColor2, setGradientColor2] = useState(avatarBorder.gradient.colors[1] || '#ffffff');

    // Обновляем цвета градиента при изменении
    useEffect(() => {
        if (avatarBorder.style === 'gradient') {
            setAvatarBorder(prev => ({
                ...prev,
                gradient: {
                    ...prev.gradient,
                    colors: [gradientColor1, gradientColor2]
                }
            }));
        }
    }, [gradientColor1, gradientColor2, avatarBorder.style, setAvatarBorder]);

    return (
        <div className="space-y-4">
            <div className="grid grid-cols-1 gap-4">
                <div>
                    <Label htmlFor="borderStyle" className="text-zinc-300">Border Style</Label>
                    <Select
                        value={avatarBorder.style}
                        onValueChange={(value) => setAvatarBorder(prev => ({ ...prev, style: value }))}
                    >
                        <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                            <SelectValue placeholder="Select style" />
                        </SelectTrigger>
                        <SelectContent className="bg-zinc-950 border-zinc-800">
                            <SelectItem value="solid">Solid</SelectItem>
                            <SelectItem value="dashed">Dashed</SelectItem>
                            <SelectItem value="dotted">Dotted</SelectItem>
                            <SelectItem value="double">Double</SelectItem>
                            <SelectItem value="gradient">Gradient</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                {avatarBorder.style !== 'gradient' && (
                    <div>
                        <Label htmlFor="borderColor" className="text-zinc-300">Border Color</Label>
                        <ColorPicker
                            value={avatarBorder.color}
                            onChange={(value) => setAvatarBorder(prev => ({ ...prev, color: value }))}
                        />
                    </div>
                )}

                {avatarBorder.style === 'gradient' && (
                    <>
                        <div>
                            <Label htmlFor="gradientDirection" className="text-zinc-300">Gradient Direction</Label>
                            <Select
                                value={avatarBorder.gradient.direction}
                                onValueChange={(value) => setAvatarBorder(prev => ({
                                    ...prev,
                                    gradient: { ...prev.gradient, direction: value }
                                }))}
                            >
                                <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                    <SelectValue placeholder="Select direction" />
                                </SelectTrigger>
                                <SelectContent className="bg-zinc-950 border-zinc-800">
                                    <SelectItem value="to right">To Right</SelectItem>
                                    <SelectItem value="to left">To Left</SelectItem>
                                    <SelectItem value="to bottom">To Bottom</SelectItem>
                                    <SelectItem value="to top">To Top</SelectItem>
                                    <SelectItem value="to bottom right">To Bottom Right</SelectItem>
                                    <SelectItem value="to bottom left">To Bottom Left</SelectItem>
                                    <SelectItem value="to top right">To Top Right</SelectItem>
                                    <SelectItem value="to top left">To Top Left</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <Label htmlFor="gradientColor1" className="text-zinc-300">Color 1</Label>
                                <ColorPicker
                                    value={gradientColor1}
                                    onChange={setGradientColor1}
                                />
                            </div>

                            <div>
                                <Label htmlFor="gradientColor2" className="text-zinc-300">Color 2</Label>
                                <ColorPicker
                                    value={gradientColor2}
                                    onChange={setGradientColor2}
                                />
                            </div>
                        </div>
                    </>
                )}

                <div>
                    <Label htmlFor="borderWidth" className="text-zinc-300">Border Width (px)</Label>
                    <Input
                        id="borderWidth"
                        type="number"
                        value={avatarBorder.width}
                        onChange={(e) => setAvatarBorder(prev => ({ ...prev, width: parseInt(e.target.value) }))}
                        className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                    />
                </div>

                <div className="border-2 border-zinc-700 rounded-lg p-4">
  <div className="flex items-center justify-between mb-4">
    <Label className="text-zinc-300">Halo Effect</Label>
    <Switch
      checked={avatarBorder.halo?.enabled || false}
      onCheckedChange={(value) => setAvatarBorder(prev => ({
        ...prev,
        halo: { ...(prev.halo || {}), enabled: value }
      }))}
    />
  </div>

  {avatarBorder.halo?.enabled && (
    <div className="space-y-4">
      <div>
        <Label htmlFor="haloColor" className="text-zinc-300">Halo Color</Label>
        <ColorPicker
          value={avatarBorder.halo.color || '#FFFFFF'}
          onChange={(value) => setAvatarBorder(prev => ({
            ...prev,
            halo: { ...prev.halo, color: value }
          }))}
        />
      </div>

      <div>
        <Label htmlFor="haloIntensity" className="text-zinc-300">Intensity (1-20)</Label>
        <Input
          id="haloIntensity"
          type="number"
          min="1"
          max="20"
          value={avatarBorder.halo.intensity || 5}
          onChange={(e) => setAvatarBorder(prev => ({
            ...prev,
            halo: { ...prev.halo, intensity: parseInt(e.target.value) }
          }))}
          className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
        />
      </div>

      <div>
        <Label htmlFor="haloAnimation" className="text-zinc-300">Animation</Label>
        <Select
          value={avatarBorder.halo.animation || 'static'}
          onValueChange={(value) => setAvatarBorder(prev => ({
            ...prev,
            halo: { ...prev.halo, animation: value }
          }))}
        >
          <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
            <SelectValue placeholder="Select animation" />
          </SelectTrigger>
          <SelectContent className="bg-zinc-950 border-zinc-800">
            <SelectItem value="static">Static</SelectItem>
            <SelectItem value="pulse">Pulse</SelectItem>
            <SelectItem value="wave">Wave</SelectItem>
            <SelectItem value="rings">Expanding Rings</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  )}
</div>

                <div className="border-2 border-zinc-700 rounded-lg p-4">
                    <div className="flex items-center justify-between mb-4">
                        <Label className="text-zinc-300">Animation</Label>
                        <Switch
                            checked={avatarBorder.animation.enabled}
                            onCheckedChange={(value) => setAvatarBorder(prev => ({
                                ...prev,
                                animation: { ...prev.animation, enabled: value }
                            }))}
                        />
                    </div>

                    {avatarBorder.animation.enabled && (
                        <div className="space-y-4">
                            <div>
                                <Label htmlFor="animationType" className="text-zinc-300">Animation Type</Label>
                                <Select
                                    value={avatarBorder.animation.type}
                                    onValueChange={(value) => setAvatarBorder(prev => ({
                                        ...prev,
                                        animation: { ...prev.animation, type: value }
                                    }))}
                                >
                                    <SelectTrigger className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500">
                                        <SelectValue placeholder="Select type" />
                                    </SelectTrigger>
                                    <SelectContent className="bg-zinc-950 border-zinc-800">
                                        <SelectItem value="pulse">Pulse</SelectItem>
                                        <SelectItem value="rotate">Rotate</SelectItem>
                                        <SelectItem value="shine">Shine</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div>
                                <Label htmlFor="animationDuration" className="text-zinc-300">Duration (seconds)</Label>
                                <Input
                                    id="animationDuration"
                                    type="number"
                                    min="0.5"
                                    step="0.5"
                                    value={avatarBorder.animation.duration}
                                    onChange={(e) => setAvatarBorder(prev => ({
                                        ...prev,
                                        animation: { ...prev.animation, duration: parseFloat(e.target.value) }
                                    }))}
                                    className="bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <AvatarPreview avatarBorder={avatarBorder} />
        </div>
    );
};

const RankForm = ({ rank, onSave, onCancel }) => {
    const defaultRank = {
        level: '',
        name: '',
        requiredCoins: '',
        battleLimit: '',
        status: '',
        backgroundImage: '',
        battlesRequired: '',
        customTitle: {
            text: '',
            fontSize: 18,
            fontWeight: 'bold',
            fontFamily: 'Inter',
            letterSpacing: 0,
            style: {
                color: '#FFFFFF',
                effect: '',
                effectColor: '#e0ff89',
                intensity: 3,
                animation: '',
                gradientColors: ['#FFFFFF', '#e0ff89']
            }
        },
        avatarBorder: {
            color: '#FFFFFF',
            width: 2,
            style: 'solid',
            gradient: {
                colors: ['#e0ff89', '#ffffff'],
                direction: 'to right'
            },
            halo: {
                enabled: false,
                color: '#e0ff89',
                intensity: 5
            },
            animation: {
                enabled: false,
                type: 'pulse',
                duration: 2
            }
        },
        badge: {
            enabled: false,
            type: 'star',
            position: 'top-right',
            color: '#e0ff89',
            size: 24,
            customImage: '',
            animation: {
                enabled: false,
                type: 'pulse'
            },
            background: {
                enabled: false,
                color: '#000000'
            },
            shadow: {
                enabled: false,
                color: '#000000'
            }
        },
        colorScheme: {
            primary: '#e0ff89',
            secondary: '#1a1a1a',
            text: '#FFFFFF'
        },
        effects: {
            halo: {
                enabled: false,
                color: '#e0ff89',
                intensity: 5,
                animation: 'pulse'
            },
            particles: {
                enabled: false,
                type: 'sparkles',
                color: '#e0ff89',
                density: 5
            },
            nameEffect: {
                enabled: false,
                type: 'glow',
                color: '#e0ff89',
                textColor: '#FFFFFF',
                intensity: 3,
                gradientColors: ['#FFFFFF', '#e0ff89']
            },
            backgroundEffect: {
                enabled: false,
                type: 'overlay',
                color: 'rgba(0,0,0,0.5)',
                intensity: 5,
                useGradient: false,
                gradientColors: ['rgba(0,0,0,0.5)', 'rgba(0,0,0,0.8)'],
                gradientDirection: 'to bottom',
                animation: {
                    enabled: false,
                    type: 'pulse',
                    duration: 3
                }
            },
            backgroundParticles: {
                enabled: false,
                type: 'sparkles',
                color: '#FFFFFF',
                density: 3
            }
        },
        ui: {
            progressBarColor: '#e0ff89',
            buttonStyle: 'standard',
            customCursor: ''
        }
    };

    const [formData, setFormData] = useState(rank || defaultRank);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(rank?.backgroundImage || '');
    const [activeTab, setActiveTab] = useState("basic");

    // Состояния для косметических элементов
    const [avatarBorder, setAvatarBorder] = useState(formData.avatarBorder || defaultRank.avatarBorder);
    const [badge, setBadge] = useState(formData.badge || defaultRank.badge);
    const [colorScheme, setColorScheme] = useState(formData.colorScheme || defaultRank.colorScheme);
    const [effects, setEffects] = useState(formData.effects || defaultRank.effects);
    const [ui, setUI] = useState(formData.ui || defaultRank.ui);
    const [customTitle, setCustomTitle] = useState(formData.customTitle || defaultRank.customTitle);

    // Инициализируем background и shadow для бейджа, если их нет
    useEffect(() => {
        if (!badge.background) {
            setBadge(prev => ({
                ...prev,
                background: { enabled: false, color: '#000000' }
            }));
        }
        if (!badge.shadow) {
            setBadge(prev => ({
                ...prev,
                shadow: { enabled: false, color: '#000000' }
            }));
        }
    }, []);

    // Обновляем formData при изменении косметических элементов
    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            avatarBorder,
            badge,
            colorScheme,
            effects,
            ui,
            customTitle
        }));
    }, [avatarBorder, badge, colorScheme, effects, ui, customTitle]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData, imageFile);
    };

    const inputClasses = "bg-zinc-950 text-zinc-300 border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500 placeholder-zinc-500";

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <Tabs value={activeTab} onValueChange={setActiveTab}>
                <TabsList className="w-full grid grid-cols-6 bg-zinc-900 rounded-lg">
                    <TabsTrigger value="basic" className="px-3 py-2">Basic</TabsTrigger>
                    <TabsTrigger value="title" className="px-3 py-2">Title</TabsTrigger>
                    <TabsTrigger value="avatarBorder" className="px-3 py-2">Avatar Border</TabsTrigger>
                    <TabsTrigger value="badge" className="px-3 py-2">Badge</TabsTrigger>
                    <TabsTrigger value="colorScheme" className="px-3 py-2">Colors</TabsTrigger>
                    <TabsTrigger value="effects" className="px-3 py-2">Effects</TabsTrigger>
                </TabsList>

                <TabsContent value="basic" className="space-y-4 mt-4">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="level" className="text-zinc-300">Level</Label>
                            <Input id="level" name="level" value={formData.level} onChange={handleChange} required type="number" className={inputClasses} />
                        </div>
                        <div>
                            <Label htmlFor="name" className="text-zinc-300">Rank Name</Label>
                            <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                        </div>
                        <div>
                            <Label htmlFor="requiredCoins" className="text-zinc-300">Required Coins</Label>
                            <Input id="requiredCoins" name="requiredCoins" value={formData.requiredCoins} onChange={handleChange} required type="number" className={inputClasses} />
                        </div>
                        <div>
                            <Label htmlFor="battleLimit" className="text-zinc-300">Battle Limit</Label>
                            <Input id="battleLimit" name="battleLimit" value={formData.battleLimit} onChange={handleChange} required type="number" className={inputClasses} />
                        </div>
                        <div>
                            <Label htmlFor="status" className="text-zinc-300">Status</Label>
                            <Input id="status" name="status" value={formData.status} onChange={handleChange} required className={inputClasses} />
                        </div>
                        <div>
                            <Label htmlFor="battlesRequired" className="text-zinc-300">Battles Required</Label>
                            <Input id="battlesRequired" name="battlesRequired" value={formData.battlesRequired} onChange={handleChange} required type="number" className={inputClasses} />
                        </div>
                    </div>
                    <div>
                        <Label className="text-zinc-300">Background Image</Label>
                        <div className="mt-2">
                            <ImageUpload onImageUpload={handleImageUpload} />
                        </div>
                        {imagePreview && (
                            <div className="relative mt-2">
                                <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setImagePreview('');
                                        setFormData(prev => ({ ...prev, backgroundImage: '' }));
                                    }}
                                    className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                                >
                                    X
                                </Button>
                            </div>
                        )}
                    </div>
                </TabsContent>

                <TabsContent value="title" className="space-y-4 mt-4">
                    <CustomTitleEditor
                        customTitle={customTitle}
                        setCustomTitle={setCustomTitle}
                    />
                </TabsContent>

                <TabsContent value="avatarBorder" className="space-y-4 mt-4">
                    <AvatarBorderEditor
                        avatarBorder={avatarBorder}
                        setAvatarBorder={setAvatarBorder}
                    />
                </TabsContent>

                <TabsContent value="badge" className="space-y-4 mt-4">
                    <BadgeSelector
                        badge={badge}
                        setBadge={setBadge}
                    />
                </TabsContent>

                <TabsContent value="colorScheme" className="space-y-4 mt-4">
                    <ColorSchemeEditor
                        colorScheme={colorScheme}
                        setColorScheme={setColorScheme}
                    />
                </TabsContent>

                <TabsContent value="effects" className="space-y-4 mt-4">
                    <Tabs defaultValue="visualEffects">
                        <TabsList className="w-full grid grid-cols-2 bg-zinc-900 rounded-lg">
                            <TabsTrigger value="visualEffects" className="px-3 py-2">Visual Effects</TabsTrigger>
                            <TabsTrigger value="uiElements" className="px-3 py-2">UI Elements</TabsTrigger>
                        </TabsList>

                        <TabsContent value="visualEffects" className="space-y-4 mt-4">
                            <EffectsEditor
                                effects={effects}
                                setEffects={setEffects}
                            />
                        </TabsContent>

                        <TabsContent value="uiElements" className="space-y-4 mt-4">
                            <UIEditor
                                ui={ui}
                                setUI={setUI}
                            />
                        </TabsContent>
                    </Tabs>
                </TabsContent>
            </Tabs>

            {/* Общее превью на всех вкладках */}
            <div className="mt-8 border-2 border-zinc-700 rounded-lg p-4">
                <p className="text-zinc-400 text-sm mb-4">Rank Preview:</p>
                <RankPreview rank={formData} />
            </div>

            <div className="flex justify-end space-x-2 mt-8">
                <Button
                    type="button"
                    onClick={onCancel}
                    className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                >
                    Save Rank
                </Button>
            </div>
        </form>
    );
};

const RankManagement = () => {
    const [ranks, setRanks] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingRank, setEditingRank] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletingRankId, setDeletingRankId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchRanks = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get('/api/ranks');
            console.log('Fetched ranks:', response.data);
            setRanks(response.data);
        } catch (error) {
            console.error('Error fetching ranks:', error);
            setError('Failed to load ranks. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchRanks();
    }, [fetchRanks]);

    const handleSave = async (rankData, imageFile) => {
        try {
            const formData = new FormData();
            Object.keys(rankData).forEach(key => {
                if (key === 'avatarBorder' || key === 'badge' || key === 'colorScheme' || key === 'effects' || key === 'ui' || key === 'customTitle') {
                    formData.append(key, JSON.stringify(rankData[key]));
                } else if (key !== 'backgroundImage') {
                    formData.append(key, rankData[key]);
                }
            });

            if (imageFile) {
                formData.append('backgroundImage', imageFile);
            }

            let response;
            if (editingRank) {
                response = await axios.put(`/api/ranks/${editingRank._id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else {
                response = await axios.post('/api/ranks', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            console.log('Rank saved:', response.data);
            fetchRanks();
            setIsDialogOpen(false);
            setEditingRank(null);
        } catch (error) {
            console.error('Error saving rank:', error.response ? error.response.data : error.message);
            setError(`Error saving rank: ${error.response ? error.response.data.message : error.message}`);
        }
    };

    const handleDelete = (rankId) => {
        setDeletingRankId(rankId);
        setIsDeleteDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (deletingRankId) {
            try {
                await axios.delete(`/api/ranks/${deletingRankId}`);
                fetchRanks();
            } catch (error) {
                console.error('Error deleting rank:', error);
                setError('Failed to delete rank. Please try again.');
            }
            setIsDeleteDialogOpen(false);
            setDeletingRankId(null);
        }
    };

    // Функция для определения стиля бейджа для таблицы
    const getBadgeStyle = (rank) => {
        const badge = rank.badge || { enabled: false };
        if (!badge.enabled) return null;

        // Получение компонента иконки бейджа
        const getBadgeIcon = (type, props = {}) => {
            switch (type) {
                case 'crown': return <Crown {...props} />;
                case 'star': return <Star {...props} />;
                case 'gem': return <Gem {...props} />;
                case 'gamepad-2': return <Gamepad2 {...props} />;
                case 'hand-metal': return <HandMetal {...props} />;
                case 'magnet': return <Magnet {...props} />;
                case 'lollipop': return <Lollipop {...props} />;
                case 'paw-print': return <PawPrint {...props} />;
                case 'pill': return <Pill {...props} />;
                case 'spade': return <Spade {...props} />;
                case 'zap': return <Zap {...props} />;
                case 'rocket': return <Rocket {...props} />;
                case 'shell': return <Shell {...props} />;
                case 'bitcoin': return <Bitcoin {...props} />;
                case 'cookie': return <Cookie {...props} />;
                case 'donut': return <Donut {...props} />;
                case 'banana': return <Banana {...props} />;
                case 'bone': return <Bone {...props} />;
                case 'dices': return <Dices {...props} />;
                case 'ghost': return <Ghost {...props} />;
                case 'heart': return <Heart {...props} />;
                case 'puzzle': return <Puzzle {...props} />;
                case 'skull': return <Skull {...props} />;
                case 'swords': return <Swords {...props} />;
                case 'audio-waveform': return <AudioWaveform {...props} />;
                case 'drama': return <Drama {...props} />;
                case 'brain': return <Brain {...props} />;
                case 'radiation': return <Radiation {...props} />;
                case 'webhook': return <Webhook {...props} />;
                default: return <Star {...props} />;
            }
        };

        let iconComponent = getBadgeIcon(badge.type, { size: 16, style: { color: badge.color } });

        return (
            <div className="flex items-center">
                {iconComponent}
                <span className="ml-1">{badge.enabled ? 'Enabled' : 'Disabled'}</span>
            </div>
        );
    };

    // Функция для определения стиля границы аватара для таблицы
    const getAvatarBorderStyle = (rank) => {
        const border = rank.avatarBorder || { style: 'solid', width: 2, color: '#FFFFFF' };

        let borderPreview;
        if (border.style === 'gradient' && border.gradient?.colors?.length) {
            borderPreview = (
                <div
                    className="w-6 h-6 rounded-full"
                    style={{
                        background: `linear-gradient(${border.gradient.direction || 'to right'}, ${border.gradient.colors.join(', ')})`,
                    }}
                ></div>
            );
        } else {
            borderPreview = (
                <div
                    className="w-6 h-6 rounded-full"
                    style={{
                        border: `${border.width}px ${border.style} ${border.color}`,
                        backgroundColor: 'rgba(255, 255, 255, 0.2)'
                    }}
                ></div>
            );
        }

        return (
            <div className="flex items-center space-x-2">
                {borderPreview}
                <span>{border.style}</span>
            </div>
        );
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold text-zinc-200">Ranks</h2>
                    <Button
                        onClick={() => {
                            setEditingRank(null);
                            setIsDialogOpen(true);
                        }}
                        className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                    >
                        Add New Rank
                    </Button>
                </div>

                {/* Сообщение об ошибке */}
                {error && (
                    <div className="mb-4 p-4 bg-red-500 bg-opacity-10 border border-red-500 text-red-400 rounded-md">
                        {error}
                    </div>
                )}

                {/* Таблица рангов */}
                <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
                    {isLoading ? (
                        <div className="flex items-center justify-center py-8">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-zinc-500"></div>
                            <span className="ml-2 text-zinc-400">Loading ranks...</span>
                        </div>
                    ) : ranks.length > 0 ? (
                        <Table>
                            <TableHeader>
                                <TableRow className="border-zinc-800">
                                    <TableHead className="text-zinc-300">Level</TableHead>
                                    <TableHead className="text-zinc-300">Name</TableHead>
                                    <TableHead className="text-zinc-300">Required Coins</TableHead>
                                    <TableHead className="text-zinc-300">Battle Limit</TableHead>
                                    <TableHead className="text-zinc-300">Status</TableHead>
                                    <TableHead className="text-zinc-300">Avatar Border</TableHead>
                                    <TableHead className="text-zinc-300">Badge</TableHead>
                                    <TableHead className="text-zinc-300">Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {ranks.map(rank => (
                                    <TableRow key={rank._id} className="border-zinc-800 hover:bg-zinc-900">
                                        <TableCell className="text-zinc-300">{rank.level}</TableCell>
                                        <TableCell className="text-zinc-300">{rank.customTitle?.text || rank.name}</TableCell>
                                        <TableCell className="text-zinc-300">{rank.requiredCoins}</TableCell>
                                        <TableCell className="text-zinc-300">{rank.battleLimit}</TableCell>
                                        <TableCell className="text-zinc-300">
                                            <span className={`px-2 py-1 rounded-full text-xs ${rank.status === 'active' ? 'bg-[#e0ff89] bg-opacity-20 text-[#e0ff89]' :
                                                    rank.status === 'locked' ? 'bg-red-500 bg-opacity-20 text-red-400' :
                                                        'bg-zinc-700 bg-opacity-20 text-zinc-300'
                                                }`}>
                                                {rank.status}
                                            </span>
                                        </TableCell>
                                        <TableCell className="text-zinc-300">
                                            {getAvatarBorderStyle(rank)}
                                        </TableCell>
                                        <TableCell className="text-zinc-300">
                                            {getBadgeStyle(rank)}
                                        </TableCell>
                                        <TableCell>
                                            <div className="flex space-x-2">
                                                <Button
                                                    onClick={() => { setEditingRank(rank); setIsDialogOpen(true); }}
                                                    className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                                >
                                                    <Pencil size={16} />
                                                </Button>
                                                <Button
                                                    onClick={() => handleDelete(rank._id)}
                                                    className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
                                                >
                                                    <Trash2 size={16} />
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div className="text-zinc-400 text-center py-8">
                            No ranks available. Add your first rank using the button above.
                        </div>
                    )}
                </div>

                {/* Диалоги */}
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogContent className="max-h-[80vh] overflow-y-auto bg-zinc-950 border-2 border-zinc-700">
                        <DialogHeader>
                            <DialogTitle className="text-zinc-100">{editingRank ? 'Edit Rank' : 'Add New Rank'}</DialogTitle>
                            <DialogDescription className="text-zinc-400">
                                {editingRank ? 'Edit the details of the rank.' : 'Enter the details of the new rank.'}
                            </DialogDescription>
                        </DialogHeader>
                        <RankForm
                            rank={editingRank}
                            onSave={handleSave}
                            onCancel={() => setIsDialogOpen(false)}
                        />
                    </DialogContent>
                </Dialog>

                <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                    <DialogContent className="bg-zinc-950 border-2 border-zinc-700 text-zinc-200 rounded-lg p-6">
                        <DialogHeader>
                            <DialogTitle className="text-zinc-100">Confirm Deletion</DialogTitle>
                            <DialogDescription className="text-zinc-400">
                                Are you sure you want to delete this rank? This action cannot be undone.
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter className="mt-6 flex justify-end space-x-4">
                            <Button
                                onClick={() => setIsDeleteDialogOpen(false)}
                                className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={confirmDelete}
                                className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
                            >
                                Delete
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default RankManagement;