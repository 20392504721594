import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Pencil, Trash2, GripVertical, Plus, X, ChevronRight, ChevronDown } from "lucide-react";
import * as LucideIcons from 'lucide-react';

const ItemTypes = {
  CATEGORY: 'category',
  SUBCATEGORY: 'subcategory',
  SUBSUBCATEGORY: 'subsubcategory'
};

const CategoryForm = ({ category, onSave, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryName, setCategoryName] = useState(category?.name || '');
  const [subCategories, setSubCategories] = useState(
    category?.subCategories?.map(sub => ({
      ...sub,
      subSubCategories: sub.subSubCategories || []
    })) || []
  );
  const [newSubCategoryName, setNewSubCategoryName] = useState('');
  const [categoryColor, setCategoryColor] = useState(category?.color || '#000000');
  const [categoryTextColor, setCategoryTextColor] = useState(category?.textColor || '#ffffff');
  const [categoryIcon, setCategoryIcon] = useState(category?.icon || 'Folder');
  const [categoryPrice, setCategoryPrice] = useState(category?.price || 0);

  // Быстрая загрузка формы при открытии
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      _id: category?._id,
      name: categoryName,
      subCategories: subCategories,
      color: categoryColor,
      textColor: categoryTextColor,
      icon: categoryIcon,
      price: categoryPrice
    });
  };

  const addSubCategory = () => {
    if (newSubCategoryName.trim()) {
      setSubCategories([
        ...subCategories,
        {
          name: newSubCategoryName.trim(),
          subSubCategories: [],
          order: subCategories.length
        }
      ]);
      setNewSubCategoryName('');
    }
  };

  const addSubSubCategory = (subCategoryIndex, name) => {
    if (name.trim()) {
      const updatedSubCategories = [...subCategories];
      if (!updatedSubCategories[subCategoryIndex].subSubCategories) {
        updatedSubCategories[subCategoryIndex].subSubCategories = [];
      }
      updatedSubCategories[subCategoryIndex].subSubCategories.push({
        name: name.trim(),
        order: updatedSubCategories[subCategoryIndex].subSubCategories.length
      });
      setSubCategories(updatedSubCategories);
    }
  };

  const removeSubCategory = (index) => {
    const updated = [...subCategories];
    updated.splice(index, 1);
    // Пересчитываем order после удаления
    updated.forEach((cat, idx) => {
      cat.order = idx;
    });
    setSubCategories(updated);
  };

  const removeSubSubCategory = (subCategoryIndex, subSubCategoryIndex) => {
    const updated = [...subCategories];
    updated[subCategoryIndex].subSubCategories.splice(subSubCategoryIndex, 1);
    // Пересчитываем order после удаления
    updated[subCategoryIndex].subSubCategories.forEach((subCat, idx) => {
      subCat.order = idx;
    });
    setSubCategories(updated);
  };

  const moveSubCategory = useCallback((dragIndex, hoverIndex) => {
    setSubCategories(prevSubCategories => {
      const updatedSubCategories = [...prevSubCategories];
      const draggedItem = updatedSubCategories[dragIndex];
      updatedSubCategories.splice(dragIndex, 1);
      updatedSubCategories.splice(hoverIndex, 0, draggedItem);
      // Обновляем order для всех подкатегорий
      return updatedSubCategories.map((item, index) => ({
        ...item,
        order: index
      }));
    });
  }, []);

  const moveSubSubCategory = useCallback((subCategoryIndex, dragIndex, hoverIndex) => {
    setSubCategories(prevSubCategories => {
      const newSubCategories = [...prevSubCategories];
      const subCategory = newSubCategories[subCategoryIndex];
      
      if (!subCategory.subSubCategories) {
        subCategory.subSubCategories = [];
      }
      
      const dragItem = subCategory.subSubCategories[dragIndex];
      subCategory.subSubCategories.splice(dragIndex, 1);
      subCategory.subSubCategories.splice(hoverIndex, 0, dragItem);
      
      // Обновляем порядок для всех под-подкатегорий
      subCategory.subSubCategories = subCategory.subSubCategories.map((item, idx) => ({
        ...item,
        order: idx
      }));
      
      return newSubCategories;
    });
  }, []);

  // Стили из UsersManagement
  const inputClasses = "bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500";
  
  // Компонент иконки 
  const IconComponent = LucideIcons[categoryIcon] || LucideIcons.Folder;

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#e0ff89]"></div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Основная информация */}
      <div className="space-y-4">
        <div>
          <label htmlFor="categoryName" className="block text-zinc-300 mb-2 font-medium">
            Category Name
          </label>
          <Input
            id="categoryName"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            placeholder="Enter category name"
            required
            className={inputClasses}
          />
        </div>

        <div>
          <label htmlFor="categoryPrice" className="block text-zinc-300 mb-2 font-medium">
            Price
          </label>
          <Input
            id="categoryPrice"
            type="number"
            value={categoryPrice}
            onChange={(e) => setCategoryPrice(Number(e.target.value))}
            placeholder="Enter price"
            required
            min="0"
            step="0.01"
            className={inputClasses}
          />
        </div>
      </div>

      {/* Секция цветов */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="categoryColor" className="block text-zinc-300 mb-2 font-medium">
            Category Color
          </label>
          <div className="flex items-center space-x-2">
            <div 
              className="w-10 h-10 rounded border-2 border-zinc-700" 
              style={{ backgroundColor: categoryColor }}
            ></div>
            <Input
              id="categoryColor"
              type="color"
              value={categoryColor}
              onChange={(e) => setCategoryColor(e.target.value)}
              className="w-12 h-10 p-1 cursor-pointer"
            />
            <Input
              type="text"
              value={categoryColor}
              onChange={(e) => setCategoryColor(e.target.value)}
              className={inputClasses}
              placeholder="#000000"
            />
          </div>
        </div>
        
        <div>
          <label htmlFor="categoryTextColor" className="block text-zinc-300 mb-2 font-medium">
            Text Color
          </label>
          <div className="flex items-center space-x-2">
            <div 
              className="w-10 h-10 rounded border-2 border-zinc-700 flex items-center justify-center" 
              style={{ backgroundColor: categoryTextColor }}
            >
              <span style={{ color: categoryColor }}>Aa</span>
            </div>
            <Input
              id="categoryTextColor"
              type="color"
              value={categoryTextColor}
              onChange={(e) => setCategoryTextColor(e.target.value)}
              className="w-12 h-10 p-1 cursor-pointer"
            />
            <Input
              type="text"
              value={categoryTextColor}
              onChange={(e) => setCategoryTextColor(e.target.value)}
              className={inputClasses}
              placeholder="#ffffff"
            />
          </div>
        </div>
      </div>

      {/* Секция выбора иконки - простая версия с select */}
<div>
  <h4 className="font-semibold mb-2 text-zinc-200">Icon:</h4>
  <select
    value={categoryIcon}
    onChange={(e) => setCategoryIcon(e.target.value)}
    className="bg-zinc-950 text-white border-2 border-zinc-700 focus:border-zinc-500 focus:ring-1 focus:ring-zinc-500 w-full p-2 rounded-md"
  >
    {Object.keys(LucideIcons).map((iconName) => (
      <option key={iconName} value={iconName}>
        {iconName}
      </option>
    ))}
  </select>
</div>

      {/* Секция подкатегорий */}
      <div className="space-y-4 pt-2 border-t border-zinc-800">
        <h4 className="font-semibold text-zinc-200">Subcategories</h4>
        <DndProvider backend={HTML5Backend}>
          <div className="space-y-3 max-h-60 overflow-y-auto pr-2 pb-2">
            {subCategories.map((subCategory, index) => (
              <DraggableSubCategory
                key={index}
                subCategory={subCategory}
                index={index}
                moveSubCategory={moveSubCategory}
                moveSubSubCategory={moveSubSubCategory}
                onRemove={() => removeSubCategory(index)}
                onRemoveSubSub={(subSubIndex) => removeSubSubCategory(index, subSubIndex)}
                onAddSubSub={(name) => addSubSubCategory(index, name)}
                onChange={(updatedSubCategory) => {
                  const updated = [...subCategories];
                  updated[index] = updatedSubCategory;
                  setSubCategories(updated);
                }}
                inputClasses={inputClasses}
              />
            ))}
            
            {subCategories.length === 0 && (
              <div className="p-4 text-center border border-dashed border-zinc-700 rounded-md">
                <p className="text-zinc-400">No subcategories added yet. Add your first one below.</p>
              </div>
            )}
          </div>
        </DndProvider>

        {/* Добавление новой подкатегории */}
        <div className="flex items-center space-x-2">
          <Input
            value={newSubCategoryName}
            onChange={(e) => setNewSubCategoryName(e.target.value)}
            placeholder="New Subcategory"
            className={inputClasses}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                addSubCategory();
              }
            }}
          />
          <Button
            type="button"
            onClick={addSubCategory}
            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
          >
            <Plus size={16} />
          </Button>
        </div>
      </div>

      {/* Кнопки действий */}
      <div className="flex justify-end space-x-3 pt-4 border-t border-zinc-800">
        <Button
          type="button"
          onClick={onCancel}
          className="bg-zinc-900 text-white hover:bg-zinc-800 rounded-[30px] py-1 px-4 border-2 border-zinc-700"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
        >
          Save Category
        </Button>
      </div>
    </form>
  );
};

// Компонент для перетаскиваемой подкатегории
const DraggableSubCategory = ({ 
  subCategory, 
  index, 
  moveSubCategory, 
  moveSubSubCategory,
  onRemove, 
  onRemoveSubSub, 
  onAddSubSub, 
  onChange,
  inputClasses 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [newSubSubName, setNewSubSubName] = useState('');
  const ref = React.useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.SUBCATEGORY,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveSubCategory(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.SUBCATEGORY,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div 
      ref={ref} 
      style={{ opacity }} 
      className="border border-zinc-800 rounded-lg bg-zinc-900 overflow-hidden"
    >
      <div className="flex items-center p-2 hover:bg-zinc-800 group">
        <GripVertical 
          size={16} 
          className="text-zinc-500 group-hover:text-zinc-300 mr-2 cursor-move" 
        />
        <button
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
          className="p-1 mr-2 hover:bg-zinc-700 rounded text-zinc-400 hover:text-zinc-200"
        >
          {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        </button>
        <Input
          value={subCategory.name}
          onChange={(e) => onChange({ ...subCategory, name: e.target.value })}
          className={`${inputClasses} flex-1`}
          placeholder="Subcategory name"
        />
        <Button
          type="button"
          onClick={onRemove}
          className="ml-2 p-1 h-8 w-8 bg-red-900 hover:bg-red-700 text-white rounded-full"
          title="Remove subcategory"
        >
          <X size={14} />
        </Button>
      </div>

      {isExpanded && (
        <div className="bg-zinc-950 p-3 space-y-3 border-t border-zinc-800">
          <div className="space-y-2 max-h-40 overflow-y-auto pr-1">
            {subCategory.subSubCategories?.map((subSubCategory, subSubIndex) => (
              <DraggableSubSubCategory
                key={subSubIndex}
                subSubCategory={subSubCategory}
                subCategoryIndex={index}
                index={subSubIndex}
                moveSubSubCategory={moveSubSubCategory}
                onRemove={() => onRemoveSubSub(subSubIndex)}
                onChange={(updatedSubSub) => {
                  const updatedSubSubCategories = [...subCategory.subSubCategories];
                  updatedSubSubCategories[subSubIndex] = updatedSubSub;
                  onChange({ ...subCategory, subSubCategories: updatedSubSubCategories });
                }}
                inputClasses={inputClasses}
              />
            ))}
          </div>

          <div className="flex items-center space-x-2 pt-2">
            <Input
              value={newSubSubName}
              onChange={(e) => setNewSubSubName(e.target.value)}
              placeholder="New sub-subcategory"
              className={inputClasses}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (newSubSubName.trim()) {
                    onAddSubSub(newSubSubName);
                    setNewSubSubName('');
                  }
                }
              }}
            />
            <Button
              type="button"
              onClick={() => {
                if (newSubSubName.trim()) {
                  onAddSubSub(newSubSubName);
                  setNewSubSubName('');
                }
              }}
              className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-full p-1 h-8 w-8"
            >
              <Plus size={14} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

// Компонент для перетаскиваемой под-подкатегории
const DraggableSubSubCategory = ({
  subSubCategory,
  subCategoryIndex,
  index,
  moveSubSubCategory,
  onRemove,
  onChange,
  inputClasses
}) => {
  const ref = React.useRef(null);

  // Обработчик drop
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.SUBSUBCATEGORY,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;
      
      // Убедимся, что мы перетаскиваем в пределах той же подкатегории
      if (item.subCategoryIndex !== subCategoryIndex) return;
      
      const dragIndex = item.index;
      const hoverIndex = index;
      
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveSubSubCategory(subCategoryIndex, dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  // Обработчик drag
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.SUBSUBCATEGORY,
    item: () => ({
      index,
      subCategoryIndex,
      type: ItemTypes.SUBSUBCATEGORY
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Объединяем drag и drop
  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="flex items-center space-x-2 bg-zinc-800 p-2 rounded-lg"
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move'
      }}
    >
      <GripVertical size={12} className="text-zinc-400" />
      <Input
        value={subSubCategory.name}
        onChange={(e) => onChange({ ...subSubCategory, name: e.target.value })}
        className={`${inputClasses} flex-1`}
        placeholder="Sub-subcategory name"
      />
      <Button
        type="button"
        onClick={onRemove}
        className="p-1 h-6 w-6 bg-red-900 hover:bg-red-700 text-white rounded-full"
      >
        <X size={10} />
      </Button>
    </div>
  );
};

// Компонент для отображения категории в таблице
const CategoryItem = ({ category, index, moveCategory, onEdit, onDelete }) => {
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CATEGORY,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCategory(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CATEGORY,
    item: () => ({ id: category._id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const IconComponent = LucideIcons[category.icon] || LucideIcons.Folder;

  // Функция для форматирования подкатегорий и под-подкатегорий
  const formatSubCategories = (subCategories) => {
    return subCategories.map(sub => {
      const subSubText = sub.subSubCategories?.length > 0
        ? ` (${sub.subSubCategories.map(subsub => subsub.name).join(', ')})`
        : '';
      return sub.name + subSubText;
    }).join('; ');
  };

  return (
    <TableRow 
      ref={ref} 
      style={{ opacity }} 
      data-handler-id={handlerId} 
      className="hover:bg-zinc-900 border-b border-zinc-800"
    >
      <TableCell className="w-8 p-2">
        <GripVertical size={16} className="text-zinc-400 cursor-move" />
      </TableCell>
      <TableCell className="text-zinc-300 p-2">{category.name}</TableCell>
      <TableCell className="text-zinc-300 p-2">{formatSubCategories(category.subCategories)}</TableCell>
      <TableCell className="text-zinc-300 p-2">${category.price}</TableCell>
      <TableCell className="p-2">
        <div className="flex space-x-2">
          <div className="w-6 h-6 rounded-full" style={{ backgroundColor: category.color }}></div>
          <div className="w-6 h-6 rounded-full" style={{ backgroundColor: category.textColor }}></div>
        </div>
      </TableCell>
      <TableCell className="p-2">
        <IconComponent size={24} className="text-zinc-300" />
      </TableCell>
      <TableCell className="p-2">
        <div className="flex space-x-2">
          <Button 
            onClick={() => onEdit(category)} 
            className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
          >
            <Pencil size={16} />
          </Button>
          <Button 
            onClick={() => onDelete(category._id)} 
            className="p-1 h-8 w-8 bg-zinc-900 hover:bg-zinc-800 text-white rounded-full"
          >
            <Trash2 size={16} />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

const CategoriesManagement = () => {
  const [categories, setCategories] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/categories');
      const sortedCategories = response.data.sort((a, b) => a.order - b.order);
      
      // Сортируем подкатегории и под-подкатегории
      const processedCategories = sortedCategories.map(category => ({
        ...category,
        subCategories: category.subCategories
          .sort((a, b) => a.order - b.order)
          .map(subCat => ({
            ...subCat,
            subSubCategories: (subCat.subSubCategories || [])
              .sort((a, b) => a.order - b.order)
          }))
      }));

      setCategories(processedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to load categories. Please try again.');
      setCategories([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (categoryData) => {
    try {
      // Обрабатываем порядок для всех уровней
      const processedData = {
        ...categoryData,
        subCategories: categoryData.subCategories.map((subCat, index) => ({
          ...subCat,
          order: index,
          subSubCategories: (subCat.subSubCategories || []).map((subSubCat, subIndex) => ({
            ...subSubCat,
            order: subIndex
          }))
        }))
      };

      if (processedData._id) {
        await axios.put(`/api/categories/${processedData._id}`, processedData);
      } else {
        await axios.post('/api/categories', processedData);
      }
      
      await fetchCategories();
      setIsDialogOpen(false);
      setEditingCategory(null);
    } catch (error) {
      console.error('Error saving category:', error);
      setError('Failed to save category. Please try again.');
    }
  };

  const handleDelete = async (categoryId) => {
    try {
      await axios.delete(`/api/categories/${categoryId}`);
      await fetchCategories();
    } catch (error) {
      console.error('Error deleting category:', error);
      setError('Failed to delete category. Please try again.');
    }
  };

  const moveCategory = useCallback(async (dragIndex, hoverIndex) => {
    const dragCategory = categories[dragIndex];
    const newCategories = [...categories];
    newCategories.splice(dragIndex, 1);
    newCategories.splice(hoverIndex, 0, dragCategory);
    
    // Обновляем order для всех категорий
    const updatedCategories = newCategories.map((category, index) => ({
      ...category,
      order: index
    }));
    
    setCategories(updatedCategories);

    try {
      const dataToSend = { 
        categories: updatedCategories.map(category => ({
          _id: category._id,
          order: category.order,
          subCategories: category.subCategories.map((subCat, subIndex) => ({
            ...subCat,
            order: subIndex,
            subSubCategories: (subCat.subSubCategories || []).map((subSubCat, subSubIndex) => ({
              ...subSubCat,
              order: subSubIndex
            }))
          }))
        }))
      };
      
      await axios.put('/api/categories/reorder', dataToSend);
    } catch (error) {
      console.error('Error reordering categories:', error);
      setError('Failed to reorder categories. Please try again.');
      await fetchCategories(); // Восстанавливаем порядок в случае ошибки
    }
  }, [categories]);

  const handleSubCategoryReorder = useCallback(async (categoryId, dragIndex, hoverIndex) => {
    try {
      const category = categories.find(cat => cat._id === categoryId);
      if (!category) return;

      const newSubCategories = [...category.subCategories];
      const [draggedItem] = newSubCategories.splice(dragIndex, 1);
      newSubCategories.splice(hoverIndex, 0, draggedItem);

      const updatedSubCategories = newSubCategories.map((subCat, index) => ({
        ...subCat,
        order: index
      }));

      await axios.put(`/api/categories/${categoryId}/reorder-subcategories`, {
        subCategories: updatedSubCategories
      });

      await fetchCategories();
    } catch (error) {
      console.error('Error reordering subcategories:', error);
      setError('Failed to reorder subcategories. Please try again.');
    }
  }, [categories]);

  const handleSubSubCategoryReorder = useCallback(async (
    categoryId,
    subCategoryId,
    dragIndex,
    hoverIndex
  ) => {
    try {
      const category = categories.find(cat => cat._id === categoryId);
      if (!category) return;

      const subCategory = category.subCategories.find(sub => sub._id === subCategoryId);
      if (!subCategory) return;

      const newSubSubCategories = [...subCategory.subSubCategories];
      const [draggedItem] = newSubSubCategories.splice(dragIndex, 1);
      newSubSubCategories.splice(hoverIndex, 0, draggedItem);

      const updatedSubSubCategories = newSubSubCategories.map((subSubCat, index) => ({
        ...subSubCat,
        order: index
      }));

      await axios.put(
        `/api/categories/${categoryId}/subcategories/${subCategoryId}/reorder`,
        { subSubCategories: updatedSubSubCategories }
      );

      await fetchCategories();
    } catch (error) {
      console.error('Error reordering sub-subcategories:', error);
      setError('Failed to reorder sub-subcategories. Please try again.');
    }
  }, [categories]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-6">
        {/* Заголовок и кнопка добавления */}
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-zinc-200">Categories</h2>
          <Button 
            onClick={() => {
              setEditingCategory(null);
              setIsDialogOpen(true);
            }} 
            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
          >
            Add New Category
          </Button>
        </div>

        {/* Сообщение об ошибке */}
        {error && (
          <div className="mb-4 p-4 bg-red-500 bg-opacity-10 border border-red-500 text-red-400 rounded-md">
            {error}
          </div>
        )}

        {/* Таблица категорий */}
        <div className="rounded-md border border-zinc-800 overflow-hidden">
          <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
            <Table>
              <TableHeader className="sticky top-0 bg-zinc-950 z-10">
                <TableRow className="hover:bg-zinc-900 border-b border-zinc-800">
                  <TableHead className="w-8 text-zinc-400 font-medium h-10 px-2 text-left"></TableHead>
                  <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Name</TableHead>
                  <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Structure</TableHead>
                  <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Price</TableHead>
                  <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Colors</TableHead>
                  <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Icon</TableHead>
                  <TableHead className="text-zinc-400 font-medium h-10 px-2 text-left">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} className="text-center py-8">
                      <div className="flex items-center justify-center space-x-2">
                        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-zinc-500"></div>
                        <span className="text-zinc-400">Loading categories...</span>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : categories.length > 0 ? (
                  categories.map((category, index) => (
                    <CategoryItem
                      key={category._id}
                      category={category}
                      index={index}
                      moveCategory={moveCategory}
                      onEdit={(category) => {
                        setEditingCategory(category);
                        setIsDialogOpen(true);
                      }}
                      onDelete={handleDelete}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} className="text-center py-8">
                      <span className="text-zinc-400">No categories available.</span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>

        {/* Диалог добавления/редактирования категории */}
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="bg-zinc-950 border border-zinc-800 text-zinc-200 max-h-[90vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle className="text-zinc-100">
                {editingCategory ? 'Edit Category' : 'Add New Category'}
              </DialogTitle>
              <DialogDescription className="text-zinc-400">
                {editingCategory 
                  ? 'Edit the details of the category including its subcategories and sub-subcategories.' 
                  : 'Enter the details for the new category. You can add subcategories and sub-subcategories after creating the main category.'}
              </DialogDescription>
            </DialogHeader>
            <CategoryForm
              category={editingCategory}
              onSave={handleSave}
              onCancel={() => {
                setIsDialogOpen(false);
                setEditingCategory(null);
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    </DndProvider>
  );
};

export default CategoriesManagement;