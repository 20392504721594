import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { 
  Table, TableBody, TableCell, TableHead, TableHeader, TableRow 
} from "../ui/table";
import { 
  Card, CardContent, CardHeader, CardTitle, CardDescription 
} from "../ui/card";
import { 
  Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter 
} from "../ui/dialog";
import { 
  Tabs, TabsContent, TabsList, TabsTrigger 
} from "../ui/tabs";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { 
  Select, SelectContent, SelectItem, SelectTrigger, SelectValue 
} from "../ui/select";
import toast from '../CustomToast';
import { 
  RefreshCcw, Send, Trash2, Edit, Calendar, Image, FileVideo, 
  MessageSquare, Clock, CheckCircle, XCircle, AlertCircle, Eye, EyeOff
} from "lucide-react";
import { Progress } from "../ui/progress";

// Компонент для отображения статуса сообщения
const StatusBadge = ({ status }) => {
  const getStatusConfig = (status) => {
    switch (status) {
      case 'draft':
        return { 
          color: 'bg-zinc-500', 
          text: 'Draft', 
          icon: <MessageSquare size={14} className="mr-1" /> 
        };
      case 'scheduled':
        return { 
          color: 'bg-amber-500', 
          text: 'Scheduled', 
          icon: <Clock size={14} className="mr-1" /> 
        };
      case 'sending':
        return { 
          color: 'bg-blue-500', 
          text: 'Sending', 
          icon: <Send size={14} className="mr-1" /> 
        };
      case 'completed':
        return { 
          color: 'bg-green-500', 
          text: 'Completed', 
          icon: <CheckCircle size={14} className="mr-1" /> 
        };
      case 'failed':
        return { 
          color: 'bg-red-500', 
          text: 'Failed', 
          icon: <XCircle size={14} className="mr-1" /> 
        };
      default:
        return { 
          color: 'bg-zinc-400', 
          text: 'Unknown', 
          icon: <AlertCircle size={14} className="mr-1" /> 
        };
    }
  };

  const { color, text, icon } = getStatusConfig(status);

  return (
    <span className={`flex items-center px-2 py-1 text-xs font-semibold text-white rounded-full ${color}`}>
      {icon}
      {text}
    </span>
  );
};

// Компонент формы для создания/редактирования сообщения
const MessageForm = ({ message, onSave, onCancel }) => {
const [formData, setFormData] = useState({
  text: '',
  mediaType: 'none',
  mediaFile: null,
  mediaPreview: null,
  mediaCaption: '',
  scheduledFor: null,
  buttonText: '',
  buttonUrl: '',
  buttonType: 'url'
});
  
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  // Инициализация формы при редактировании
useEffect(() => {
  if (message) {
    setFormData({
      text: message.content.text || '',
      mediaType: message.content.mediaType || 'none',
      mediaFile: null,
      mediaPreview: message.content.mediaUrl ? `${message.content.mediaUrl}` : null,
      mediaCaption: message.content.mediaCaption || '',
      scheduledFor: message.scheduledFor ? new Date(message.scheduledFor) : null,
      buttonText: message.content.buttonText || '',
      buttonUrl: message.content.buttonUrl || '',
      buttonType: message.content.buttonType || 'url'
    });
  }
}, [message]);

  // Обработчик изменения полей формы
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  // Обработчик изменения файла медиа
const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (!file) return;

  // Определяем тип медиа по MIME-типу файла
  let mediaType = 'none';
  if (file.type.startsWith('image/')) {
    mediaType = 'photo';
  } else if (file.type.startsWith('video/')) {
    mediaType = 'video';
  }

  // Используем FileReader вместо URL.createObjectURL для предотвращения проблем с CSP
  const reader = new FileReader();
  reader.onload = (event) => {
    setFormData(prev => ({
      ...prev,
      mediaFile: file,
      mediaPreview: event.target.result, // Используем data URL вместо blob URL
      mediaType
    }));
  };
  reader.readAsDataURL(file); // Читаем файл как data URL
};

  // Обработчик удаления файла медиа
const handleRemoveMedia = () => {
  // Для data URL не требуется вызов revokeObjectURL
  setFormData(prev => ({
    ...prev,
    mediaFile: null,
    mediaPreview: null,
    mediaType: 'none'
  }));
};

  // Обработчик изменения даты запланированной отправки
  const handleDateChange = (date) => {
    setFormData(prev => ({ ...prev, scheduledFor: date }));
  };

  // Обработчик отправки формы
const handleSubmit = (e) => {
  e.preventDefault();
  
  // Создаем FormData для отправки файлов
  const submitData = new FormData();
  submitData.append('text', formData.text);
  submitData.append('mediaType', formData.mediaType);
  submitData.append('mediaCaption', formData.mediaCaption);
  
  if (formData.scheduledFor) {
    submitData.append('scheduledFor', formData.scheduledFor.toISOString());
  }
  
  if (formData.mediaFile) {
    submitData.append('media', formData.mediaFile);
  }
  
  // Добавляем данные кнопки, если они существуют
  if (formData.buttonText && formData.buttonUrl) {
    submitData.append('buttonText', formData.buttonText);
    submitData.append('buttonUrl', formData.buttonUrl);
    submitData.append('buttonType', formData.buttonType);
  }
  
  onSave(submitData);
};

  // Переключатель режима предпросмотра
  const togglePreview = () => {
    setIsPreviewMode(!isPreviewMode);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Режим предпросмотра */}
      {isPreviewMode ? (
        <div className="p-4 mb-4 border border-zinc-800 rounded-lg bg-zinc-900">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-white">Message Preview</h3>
            <Button
              type="button"
              variant="ghost"
              size="sm"
              onClick={togglePreview}
              className="text-zinc-400 hover:text-white"
            >
              <EyeOff size={16} className="mr-1" /> Hide Preview
            </Button>
          </div>
          
          <div className="max-w-sm mx-auto bg-zinc-950 rounded-lg overflow-hidden shadow-lg border border-zinc-800">
            {/* Медиа-контент в предпросмотре */}
            {formData.mediaPreview && (
              <div className="w-full">
                {formData.mediaType === 'photo' && (
                  <img 
                    src={formData.mediaPreview} 
                    alt="Preview" 
                    className="w-full h-auto max-h-64 object-cover"
                  />
                )}
                {formData.mediaType === 'video' && (
                  <video 
                    src={formData.mediaPreview} 
                    controls 
                    className="w-full h-auto max-h-64"
                  >
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            )}
            
            {/* Текст сообщения в предпросмотре */}
            <div className="p-4">
              <p className="text-white text-sm whitespace-pre-wrap break-words">
                {formData.text || formData.mediaCaption || 'No message content'}
              </p>
            </div>
{/* Кнопка в предпросмотре */}
{formData.buttonText && formData.buttonUrl && (
  <div className="pt-2 mt-2 border-t border-zinc-800">
    <div className={`${formData.buttonType === 'web_app' ? 'bg-green-600' : 'bg-blue-600'} text-white py-2 px-4 rounded text-center`}>
      {formData.buttonText}
      {formData.buttonType === 'web_app' && (
        <span className="ml-1 text-xs">(Web App)</span>
      )}
    </div>
    <p className="text-xs text-zinc-400 mt-1 text-center">{formData.buttonUrl}</p>
  </div>
)}
          </div>
        </div>
      ) : (
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={togglePreview}
          className="mb-4 text-zinc-400 hover:text-white bg-zinc-900 border-zinc-700"
        >
          <Eye size={16} className="mr-1" /> Show Preview
        </Button>
      )}
      
      {/* Текст сообщения */}
      <div className="space-y-2">
        <Label htmlFor="text" className="text-zinc-300">Message Text</Label>
        <textarea
          id="text"
          name="text"
          value={formData.text}
          onChange={handleChange}
          rows={5}
          placeholder="Enter your message text here..."
          className="w-full bg-zinc-900 text-white border-zinc-700 focus:border-zinc-500 p-2 rounded"
        />
      </div>
      
      {/* Выбор медиа */}
      <div className="space-y-2">
        <Label className="text-zinc-300">Media</Label>
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-4">
            <div className="flex items-center">
              <input
                type="radio"
                id="mediaType-none"
                name="mediaType"
                value="none"
                checked={formData.mediaType === 'none'}
                onChange={() => setFormData(prev => ({ ...prev, mediaType: 'none' }))}
                className="mr-2"
              />
              <Label htmlFor="mediaType-none" className="text-zinc-300">No Media</Label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="mediaType-photo"
                name="mediaType"
                value="photo"
                checked={formData.mediaType === 'photo'}
                onChange={() => setFormData(prev => ({ ...prev, mediaType: 'photo' }))}
                className="mr-2"
              />
              <Label htmlFor="mediaType-photo" className="text-zinc-300">Photo</Label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="mediaType-video"
                name="mediaType"
                value="video"
                checked={formData.mediaType === 'video'}
                onChange={() => setFormData(prev => ({ ...prev, mediaType: 'video' }))}
                className="mr-2"
              />
              <Label htmlFor="mediaType-video" className="text-zinc-300">Video</Label>
            </div>
          </div>
          
          {formData.mediaType !== 'none' && (
            <div className="space-y-4">
              <div>
                <Label htmlFor="media" className="text-zinc-300 block mb-2">
                  Upload {formData.mediaType === 'photo' ? 'Photo' : 'Video'}
                </Label>
                <div className="flex items-center">
                  <Input
                    id="media"
                    name="media"
                    type="file"
                    accept={formData.mediaType === 'photo' ? "image/*" : "video/*"}
                    onChange={handleFileChange}
                    className="bg-zinc-900 text-white border-zinc-700 focus:border-zinc-500"
                  />
                  {formData.mediaPreview && (
                    <Button
                      type="button"
                      variant="ghost"
                      onClick={handleRemoveMedia}
                      className="ml-2 text-red-400 hover:text-red-300"
                    >
                      <Trash2 size={16} />
                    </Button>
                  )}
                </div>
              </div>
              
              {formData.mediaPreview && (
                <div className="border border-zinc-800 rounded-lg overflow-hidden p-2 bg-zinc-900">
                  {formData.mediaType === 'photo' ? (
                    <img 
                      src={formData.mediaPreview} 
                      alt="Preview" 
                      className="max-h-40 max-w-full object-contain mx-auto"
                    />
                  ) : (
                    <video 
                      src={formData.mediaPreview} 
                      controls 
                      className="max-h-40 max-w-full mx-auto"
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              )}
              
              <div>
                <Label htmlFor="mediaCaption" className="text-zinc-300">Media Caption (optional)</Label>
                <Input
                  id="mediaCaption"
                  name="mediaCaption"
                  value={formData.mediaCaption}
                  onChange={handleChange}
                  placeholder="Add a caption for your media..."
                  className="bg-zinc-900 text-white border-zinc-700 focus:border-zinc-500"
                />
              </div>
            </div>
          )}
        </div>
      </div>

{/* Кнопка со ссылкой */}
<div className="space-y-2">
  <Label className="text-zinc-300">Button</Label>
  <div className="flex flex-col space-y-2">
    <div className="flex items-center">
      <input
        type="checkbox"
        id="has-button"
        name="hasButton"
        checked={!!(formData.buttonText && formData.buttonUrl)}
        onChange={(e) => {
          if (!e.target.checked) {
            setFormData(prev => ({ ...prev, buttonText: '', buttonUrl: '', buttonType: 'url' }));
          } else {
            setFormData(prev => ({ ...prev, buttonText: 'Click here', buttonUrl: 'https://' }));
          }
        }}
        className="mr-2"
      />
      <Label htmlFor="has-button" className="text-zinc-300">Add a button</Label>
    </div>
    
    {!!(formData.buttonText || formData.buttonUrl) && (
      <div className="flex flex-col space-y-4">
        <div>
          <Label htmlFor="buttonText" className="text-zinc-300">Button Text</Label>
          <Input
            id="buttonText"
            name="buttonText"
            value={formData.buttonText || ''}
            onChange={handleChange}
            placeholder="Click here"
            className="bg-zinc-900 text-white border-zinc-700 focus:border-zinc-500"
          />
        </div>
        
        <div>
          <Label htmlFor="buttonUrl" className="text-zinc-300">Button URL</Label>
          <Input
            id="buttonUrl"
            name="buttonUrl"
            value={formData.buttonUrl || ''}
            onChange={handleChange}
            placeholder="https://example.com"
            className="bg-zinc-900 text-white border-zinc-700 focus:border-zinc-500"
          />
        </div>
        
        <div className="space-y-2">
          <Label className="text-zinc-300">Button Type</Label>
          <div className="flex space-x-4">
            <div className="flex items-center">
              <input
                type="radio"
                id="buttonType-url"
                name="buttonType"
                value="url"
                checked={formData.buttonType === 'url'}
                onChange={() => setFormData(prev => ({ ...prev, buttonType: 'url' }))}
                className="mr-2"
              />
              <Label htmlFor="buttonType-url" className="text-zinc-300">External Link (Browser)</Label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="buttonType-web_app"
                name="buttonType"
                value="web_app"
                checked={formData.buttonType === 'web_app'}
                onChange={() => setFormData(prev => ({ ...prev, buttonType: 'web_app' }))}
                className="mr-2"
              />
              <Label htmlFor="buttonType-web_app" className="text-zinc-300">Telegram Web App</Label>
            </div>
          </div>
          {formData.buttonType === 'web_app' && (
            <div className="ml-6 mt-2 text-xs text-amber-500">
              <p>URL should be your web app address (e.g., https://battlebrands.app/account)</p>
              <p>This will open the app directly inside Telegram</p>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
</div>
      
      {/* Планирование отправки */}
      <div className="space-y-2">
        <Label className="text-zinc-300">Schedule Message</Label>
        <div className="flex flex-col space-y-2">
          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <input
                type="radio"
                id="schedule-no"
                name="scheduleType"
                checked={!formData.scheduledFor}
                onChange={() => setFormData(prev => ({ ...prev, scheduledFor: null }))}
                className="mr-2"
              />
              <Label htmlFor="schedule-no" className="text-zinc-300">Send Immediately</Label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="schedule-yes"
                name="scheduleType"
                checked={!!formData.scheduledFor}
                onChange={() => setFormData(prev => ({ 
                  ...prev, 
                  scheduledFor: prev.scheduledFor || new Date(Date.now() + 3600000) // +1 hour
                }))}
                className="mr-2"
              />
              <Label htmlFor="schedule-yes" className="text-zinc-300">Schedule for Later</Label>
            </div>
          </div>
          
          {formData.scheduledFor && (
            <div className="flex space-x-2 items-center">
              <Calendar size={16} className="text-zinc-400" />
              <div className="bg-zinc-900 border border-zinc-700 rounded p-2">
                <input
                  type="datetime-local"
                  value={formData.scheduledFor ? new Date(formData.scheduledFor.getTime() - formData.scheduledFor.getTimezoneOffset() * 60000).toISOString().slice(0, 16) : ''}
                  onChange={(e) => {
                    const date = e.target.value ? new Date(e.target.value) : null;
                    handleDateChange(date);
                  }}
                  min={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 16)}
                  className="bg-zinc-900 text-white focus:outline-none w-full"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Кнопки действий */}
      <DialogFooter>
        <Button
          type="button"
          variant="outline"
          onClick={onCancel}
          className="bg-zinc-900 text-white hover:bg-zinc-800 rounded-[30px] py-1 px-4 border-2 border-zinc-700"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
        >
          {message ? 'Update Message' : 'Create Message'}
        </Button>
      </DialogFooter>
    </form>
  );
};

// Компонент статистики доставки
const DeliveryStats = ({ message, onRefresh }) => {
  if (!message || !message.deliveryStats) {
    return (
      <div className="text-center text-zinc-400 py-4">
        No delivery statistics available
      </div>
    );
  }

  const { totalUsers, delivered, failed, pending } = message.deliveryStats;
  
  const deliveredPercent = totalUsers > 0 ? Math.round((delivered / totalUsers) * 100) : 0;
  const failedPercent = totalUsers > 0 ? Math.round((failed / totalUsers) * 100) : 0;
  const pendingPercent = totalUsers > 0 ? Math.round((pending / totalUsers) * 100) : 0;

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-white">Delivery Statistics</h3>
        <Button
          size="sm"
          variant="ghost"
          onClick={onRefresh}
          className="text-zinc-400 hover:text-white"
        >
          <RefreshCcw size={14} className="mr-1" />
          Refresh
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card className="bg-zinc-900 border-zinc-800">
          <CardContent className="pt-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-zinc-400">Total Recipients</p>
                <p className="text-2xl font-bold text-white">{totalUsers}</p>
              </div>
              <MessageSquare size={24} className="text-zinc-500" />
            </div>
          </CardContent>
        </Card>

        <Card className="bg-zinc-900 border-zinc-800">
          <CardContent className="pt-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-zinc-400">Delivered</p>
                <p className="text-2xl font-bold text-green-500">
                  {delivered} <span className="text-sm text-green-400">({deliveredPercent}%)</span>
                </p>
              </div>
              <CheckCircle size={24} className="text-green-500" />
            </div>
          </CardContent>
        </Card>

        <Card className="bg-zinc-900 border-zinc-800">
          <CardContent className="pt-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-zinc-400">Failed</p>
                <p className="text-2xl font-bold text-red-500">
                  {failed} <span className="text-sm text-red-400">({failedPercent}%)</span>
                </p>
              </div>
              <XCircle size={24} className="text-red-500" />
            </div>
          </CardContent>
        </Card>
      </div>

      {pending > 0 && (
        <div className="mt-4 space-y-2">
          <div className="flex justify-between text-sm mb-1">
            <span className="text-zinc-300">Sending in progress...</span>
            <span className="text-zinc-300">{delivered + failed} of {totalUsers}</span>
          </div>
          <Progress value={((delivered + failed) / totalUsers) * 100} className="h-2" />
          <p className="text-sm text-zinc-400 text-right">
            {pending} messages still pending
          </p>
        </div>
      )}
    </div>
  );
};

// Основной компонент для отправки сообщений через Telegram
const TelegramMessaging = () => {
  const [messages, setMessages] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isStatsDialogOpen, setIsStatsDialogOpen] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [deletingMessageId, setDeletingMessageId] = useState(null);
  const [statsRefreshInterval, setStatsRefreshInterval] = useState(null);

  // Загрузка списка сообщений
  const fetchMessages = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/api/telegram-messages');
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
      toast.error('Failed to load messages. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Загрузка данных при монтировании компонента
  useEffect(() => {
    fetchMessages();
    
    // Очистка интервала при размонтировании
    return () => {
      if (statsRefreshInterval) {
        clearInterval(statsRefreshInterval);
      }
    };
  }, []);

  // Обработчик создания нового сообщения
  const handleCreateMessage = async (formData) => {
    try {
      const response = await axios.post('/api/telegram-messages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (response.data && response.data.success) {
        toast.success('Message created successfully');
        setIsDialogOpen(false);
        setEditingMessage(null);
        fetchMessages();
      } else {
        toast.error(response.data?.message || 'Failed to create message');
      }
    } catch (error) {
      console.error('Error creating message:', error);
      // Безопасный доступ к сообщению об ошибке
      const errorMessage = error.response?.data?.message || 'Failed to create message. Please try again.';
      toast.error(errorMessage);
    }
  };

  // Обработчик обновления сообщения
  const handleUpdateMessage = async (formData) => {
    try {
      if (!editingMessage || !editingMessage._id) {
        toast.error('No message selected for editing');
        return;
      }
      
      const response = await axios.put(`/api/telegram-messages/${editingMessage._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      toast.success('Message updated successfully');
      setIsDialogOpen(false);
      setEditingMessage(null);
      fetchMessages();
    } catch (error) {
      console.error('Error updating message:', error);
      toast.error('Failed to update message. Please try again.');
    }
  };

  // Обработчик сохранения сообщения (создание или обновление)
  const handleSaveMessage = (formData) => {
    if (editingMessage) {
      handleUpdateMessage(formData);
    } else {
      handleCreateMessage(formData);
    }
  };

  // Обработчик удаления сообщения
  const handleDeleteMessage = async () => {
    if (!deletingMessageId) return;
    
    try {
      await axios.delete(`/api/telegram-messages/${deletingMessageId}`);
      
      toast.success('Message deleted successfully');
      setIsDeleteDialogOpen(false);
      setDeletingMessageId(null);
      fetchMessages();
    } catch (error) {
      console.error('Error deleting message:', error);
      toast.error('Failed to delete message. Please try again.');
    }
  };

  // Обработчик отправки сообщения
  const handleSendMessage = async (messageId) => {
    try {
      if (!messageId) {
        toast.error('Invalid message ID');
        return;
      }
      
      const response = await axios.post(`/api/telegram-messages/${messageId}/send`);
      
      if (response.data && response.data.success) {
        toast.success('Message sending process started');
        
        // Обновляем список сообщений
        fetchMessages();
        
        // Находим сообщение и открываем диалог статистики
        const message = messages.find(m => m._id === messageId);
        if (message) {
          setCurrentMessage({...message});
          setIsStatsDialogOpen(true);
          
          // Очищаем предыдущий интервал, если он существует
          if (statsRefreshInterval) {
            clearInterval(statsRefreshInterval);
          }
          
          // Настраиваем новый интервал обновления
          const intervalId = setInterval(async () => {
            try {
              const statsResponse = await axios.get(`/api/telegram-messages/${messageId}/stats`);
              if (statsResponse.data && statsResponse.data.success) {
                setCurrentMessage(prev => {
                  if (prev && prev._id === messageId) {
                    return {
                      ...prev,
                      status: statsResponse.data.data.status,
                      deliveryStats: statsResponse.data.data.deliveryStats
                    };
                  }
                  return prev;
                });
                
                // Если сообщение отправлено или произошла ошибка, останавливаем обновление
                if (['completed', 'failed'].includes(statsResponse.data.data.status)) {
                  clearInterval(intervalId);
                  setStatsRefreshInterval(null);
                  // Обновляем список сообщений для отображения финального статуса
                  fetchMessages();
                }
              }
            } catch (err) {
              console.error('Error fetching message stats:', err);
            }
          }, 3000); // Обновляем каждые 3 секунды
          
          setStatsRefreshInterval(intervalId);
        }
      } else {
        toast.error(response.data?.message || 'Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error(error.response?.data?.message || 'Failed to send message. Please try again.');
    }
  };

  // Обработчик просмотра статистики
  const handleViewStats = async (messageId) => {
    try {
      const response = await axios.get(`/api/telegram-messages/${messageId}/stats`);
      
      if (response.data && response.data.success) {
        const message = messages.find(m => m._id === messageId);
        if (message) {
          setCurrentMessage({
            ...message,
            status: response.data.data.status,
            deliveryStats: response.data.data.deliveryStats
          });
          setIsStatsDialogOpen(true);
        }
      } else {
        toast.error('Failed to load message statistics');
      }
    } catch (error) {
      console.error('Error fetching message stats:', error);
      toast.error('Failed to load message statistics. Please try again.');
    }
  };

  // Обработчик обновления статистики
  const handleRefreshStats = async () => {
    if (!currentMessage || !currentMessage._id) return;
    
    try {
      const response = await axios.get(`/api/telegram-messages/${currentMessage._id}/stats`);
      
      if (response.data && response.data.success) {
        setCurrentMessage(prev => {
          if (!prev) return prev;
          return {
            ...prev,
            status: response.data.data.status,
            deliveryStats: response.data.data.deliveryStats
          };
        });
      }
    } catch (error) {
      console.error('Error refreshing stats:', error);
      toast.error('Failed to refresh statistics');
    }
  };

  // Фильтрация сообщений по статусу
  const filteredMessages = messages.filter(message => {
    if (activeTab === 'all') return true;
    return message.status === activeTab;
  });

  return (
    <div className="space-y-6">
      <Card className="bg-zinc-950 border-zinc-800">
        <CardHeader>
          <CardTitle className="text-white flex justify-between items-center">
            <span>Telegram Messaging</span>
            <Button
              onClick={() => { setEditingMessage(null); setIsDialogOpen(true); }}
              className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
            >
              <MessageSquare size={16} className="mr-2" />
              New Message
            </Button>
          </CardTitle>
          <CardDescription className="text-zinc-400">
            Create, schedule, and send messages to all users via Telegram bot
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="all" value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="bg-zinc-900 border border-zinc-800 p-1 mb-4">
              <TabsTrigger value="all" className="data-[state=active]:bg-zinc-800 data-[state=active]:text-white data-[state=inactive]:text-zinc-400">
                All
              </TabsTrigger>
              <TabsTrigger value="draft" className="data-[state=active]:bg-zinc-800 data-[state=active]:text-white data-[state=inactive]:text-zinc-400">
                Draft
              </TabsTrigger>
              <TabsTrigger value="scheduled" className="data-[state=active]:bg-zinc-800 data-[state=active]:text-white data-[state=inactive]:text-zinc-400">
                Scheduled
              </TabsTrigger>
              <TabsTrigger value="sending" className="data-[state=active]:bg-zinc-800 data-[state=active]:text-white data-[state=inactive]:text-zinc-400">
                Sending
              </TabsTrigger>
              <TabsTrigger value="completed" className="data-[state=active]:bg-zinc-800 data-[state=active]:text-white data-[state=inactive]:text-zinc-400">
                Completed
              </TabsTrigger>
              <TabsTrigger value="failed" className="data-[state=active]:bg-zinc-800 data-[state=active]:text-white data-[state=inactive]:text-zinc-400">
                Failed
              </TabsTrigger>
            </TabsList>
            
            <TabsContent value="all" className="mt-0">
              {isLoading ? (
                <div className="text-center py-8">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-zinc-400 border-t-[#e0ff89]"></div>
                  <p className="mt-2 text-zinc-400">Loading messages...</p>
                </div>
              ) : filteredMessages.length > 0 ? (
                <div className="border border-zinc-800 rounded-md overflow-hidden">
                  <Table>
                    <TableHeader className="bg-zinc-900">
                      <TableRow>
                        <TableHead className="text-zinc-400 font-medium">Status</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Content</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Media</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Created</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Scheduled For</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Statistics</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredMessages.map((message) => (
                        <TableRow key={message._id} className="border-b border-zinc-800 hover:bg-zinc-900">
                          <TableCell>
                            <StatusBadge status={message.status} />
                          </TableCell>
                          <TableCell className="max-w-[200px] truncate">
                            <span className="text-zinc-300">{message.content.text}</span>
                          </TableCell>
                          <TableCell>
                            {message.content.mediaType !== 'none' ? (
                              <div className="flex items-center space-x-1">
                                {message.content.mediaType === 'photo' ? (
                                  <Image size={16} className="text-blue-400" />
                                ) : (
                                  <FileVideo size={16} className="text-purple-400" />
                                )}
                                <span className="text-zinc-300 capitalize">{message.content.mediaType}</span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">No media</span>
                            )}
                          </TableCell>
                          <TableCell className="text-zinc-300">
                            {message.createdAt && format(new Date(message.createdAt), 'dd MMM yyyy, HH:mm')}
                          </TableCell>
                          <TableCell>
                            {message.scheduledFor ? (
                              <div className="flex items-center space-x-1">
                                <Clock size={16} className="text-amber-400" />
                                <span className="text-zinc-300">
                                  {format(new Date(message.scheduledFor), 'dd MMM yyyy, HH:mm')}
                                </span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">-</span>
                            )}
                          </TableCell>
                          <TableCell>
                            {message.status === 'sending' || message.status === 'completed' || message.status === 'failed' ? (
                              <div className="flex items-center space-x-2">
                                {message.deliveryStats && (
                                  <>
                                    <span className="text-green-500">{message.deliveryStats.delivered}</span>
                                    <span className="text-zinc-500">/</span>
                                    <span className="text-red-500">{message.deliveryStats.failed}</span>
                                    <span className="text-zinc-500">/</span>
                                    <span className="text-blue-500">{message.deliveryStats.pending}</span>
                                  </>
                                )}
                                <Button
                                  size="sm"
                                  variant="ghost"
                                  onClick={() => handleViewStats(message._id)}
                                  className="text-zinc-400 hover:text-zinc-300 p-1 h-6"
                                >
                                  <Eye size={14} />
                                </Button>
                              </div>
                            ) : (
                              <span className="text-zinc-500">-</span>
                            )}
                          </TableCell>
                          <TableCell>
                            <div className="flex space-x-1">
                              {message.status === 'draft' || message.status === 'scheduled' ? (
                                <>
                                  <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => { setEditingMessage(message); setIsDialogOpen(true); }}
                                    className="text-zinc-400 hover:text-zinc-300 p-1 h-6"
                                    title="Edit"
                                  >
                                    <Edit size={14} />
                                  </Button>
                                  <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => handleSendMessage(message._id)}
                                    className="text-[#e0ff89] hover:text-[#c0d866] p-1 h-6"
                                    title="Send"
                                  >
                                    <Send size={14} />
                                  </Button>
                                </>
                              ) : null}
                              
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => { setDeletingMessageId(message._id); setIsDeleteDialogOpen(true); }}
                                className="text-red-400 hover:text-red-300 p-1 h-6"
                                title="Delete"
                              >
                                <Trash2 size={14} />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div className="text-center py-8 text-zinc-400">
                  {activeTab === 'all' ? 'No messages found' : `No ${activeTab} messages found`}
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="draft" className="mt-0">
              {isLoading ? (
                <div className="text-center py-8">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-zinc-400 border-t-[#e0ff89]"></div>
                  <p className="mt-2 text-zinc-400">Loading messages...</p>
                </div>
              ) : filteredMessages.length > 0 ? (
                <div className="border border-zinc-800 rounded-md overflow-hidden">
                  <Table>
                    <TableHeader className="bg-zinc-900">
                      <TableRow>
                        <TableHead className="text-zinc-400 font-medium">Status</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Content</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Media</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Created</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Scheduled For</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredMessages.map((message) => (
                        <TableRow key={message._id} className="border-b border-zinc-800 hover:bg-zinc-900">
                          <TableCell>
                            <StatusBadge status={message.status} />
                          </TableCell>
                          <TableCell className="max-w-[200px] truncate">
                            <span className="text-zinc-300">{message.content.text}</span>
                          </TableCell>
                          <TableCell>
                            {message.content.mediaType !== 'none' ? (
                              <div className="flex items-center space-x-1">
                                {message.content.mediaType === 'photo' ? (
                                  <Image size={16} className="text-blue-400" />
                                ) : (
                                  <FileVideo size={16} className="text-purple-400" />
                                )}
                                <span className="text-zinc-300 capitalize">{message.content.mediaType}</span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">No media</span>
                            )}
                          </TableCell>
                          <TableCell className="text-zinc-300">
                            {message.createdAt && format(new Date(message.createdAt), 'dd MMM yyyy, HH:mm')}
                          </TableCell>
                          <TableCell>
                            {message.scheduledFor ? (
                              <div className="flex items-center space-x-1">
                                <Clock size={16} className="text-amber-400" />
                                <span className="text-zinc-300">
                                  {format(new Date(message.scheduledFor), 'dd MMM yyyy, HH:mm')}
                                </span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">-</span>
                            )}
                          </TableCell>
                          <TableCell>
                            <div className="flex space-x-1">
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => { setEditingMessage(message); setIsDialogOpen(true); }}
                                className="text-zinc-400 hover:text-zinc-300 p-1 h-6"
                                title="Edit"
                              >
                                <Edit size={14} />
                              </Button>
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => handleSendMessage(message._id)}
                                className="text-[#e0ff89] hover:text-[#c0d866] p-1 h-6"
                                title="Send"
                              >
                                <Send size={14} />
                              </Button>
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => { setDeletingMessageId(message._id); setIsDeleteDialogOpen(true); }}
                                className="text-red-400 hover:text-red-300 p-1 h-6"
                                title="Delete"
                              >
                                <Trash2 size={14} />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div className="text-center py-8 text-zinc-400">
                  <p className="mb-4">No draft messages found</p>
                  <Button
                    onClick={() => { setEditingMessage(null); setIsDialogOpen(true); }}
                    className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                  >
                    Create New Message
                  </Button>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="scheduled" className="mt-0">
              {isLoading ? (
                <div className="text-center py-8">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-zinc-400 border-t-[#e0ff89]"></div>
                  <p className="mt-2 text-zinc-400">Loading messages...</p>
                </div>
              ) : filteredMessages.length > 0 ? (
                <div className="border border-zinc-800 rounded-md overflow-hidden">
                  <Table>
                    <TableHeader className="bg-zinc-900">
                      <TableRow>
                        <TableHead className="text-zinc-400 font-medium">Status</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Content</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Media</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Created</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Scheduled For</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredMessages.map((message) => (
                        <TableRow key={message._id} className="border-b border-zinc-800 hover:bg-zinc-900">
                          <TableCell>
                            <StatusBadge status={message.status} />
                          </TableCell>
                          <TableCell className="max-w-[200px] truncate">
                            <span className="text-zinc-300">{message.content.text}</span>
                          </TableCell>
                          <TableCell>
                            {message.content.mediaType !== 'none' ? (
                              <div className="flex items-center space-x-1">
                                {message.content.mediaType === 'photo' ? (
                                  <Image size={16} className="text-blue-400" />
                                ) : (
                                  <FileVideo size={16} className="text-purple-400" />
                                )}
                                <span className="text-zinc-300 capitalize">{message.content.mediaType}</span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">No media</span>
                            )}
                          </TableCell>
                          <TableCell className="text-zinc-300">
                            {message.createdAt && format(new Date(message.createdAt), 'dd MMM yyyy, HH:mm')}
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center space-x-1">
                              <Clock size={16} className="text-amber-400" />
                              <span className="text-zinc-300">
                                {format(new Date(message.scheduledFor), 'dd MMM yyyy, HH:mm')}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex space-x-1">
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => { setEditingMessage(message); setIsDialogOpen(true); }}
                                className="text-zinc-400 hover:text-zinc-300 p-1 h-6"
                                title="Edit"
                              >
                                <Edit size={14} />
                              </Button>
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => handleSendMessage(message._id)}
                                className="text-[#e0ff89] hover:text-[#c0d866] p-1 h-6"
                                title="Send"
                              >
                                <Send size={14} />
                              </Button>
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => { setDeletingMessageId(message._id); setIsDeleteDialogOpen(true); }}
                                className="text-red-400 hover:text-red-300 p-1 h-6"
                                title="Delete"
                              >
                                <Trash2 size={14} />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div className="text-center py-8 text-zinc-400">
                  <p>No scheduled messages found</p>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="sending" className="mt-0">
              {isLoading ? (
                <div className="text-center py-8">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-zinc-400 border-t-[#e0ff89]"></div>
                  <p className="mt-2 text-zinc-400">Loading messages...</p>
                </div>
              ) : filteredMessages.length > 0 ? (
                <div className="border border-zinc-800 rounded-md overflow-hidden">
                  <Table>
                    <TableHeader className="bg-zinc-900">
                      <TableRow>
                        <TableHead className="text-zinc-400 font-medium">Status</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Content</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Media</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Created</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Scheduled For</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Statistics</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredMessages.map((message) => (
                        <TableRow key={message._id} className="border-b border-zinc-800 hover:bg-zinc-900">
                          <TableCell>
                            <StatusBadge status={message.status} />
                          </TableCell>
                          <TableCell className="max-w-[200px] truncate">
                            <span className="text-zinc-300">{message.content.text}</span>
                          </TableCell>
                          <TableCell>
                            {message.content.mediaType !== 'none' ? (
                              <div className="flex items-center space-x-1">
                                {message.content.mediaType === 'photo' ? (
                                  <Image size={16} className="text-blue-400" />
                                ) : (
                                  <FileVideo size={16} className="text-purple-400" />
                                )}
                                <span className="text-zinc-300 capitalize">{message.content.mediaType}</span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">No media</span>
                            )}
                          </TableCell>
                          <TableCell className="text-zinc-300">
                            {message.createdAt && format(new Date(message.createdAt), 'dd MMM yyyy, HH:mm')}
                          </TableCell>
                          <TableCell>
                            {message.scheduledFor ? (
                              <div className="flex items-center space-x-1">
                                <Clock size={16} className="text-amber-400" />
                                <span className="text-zinc-300">
                                  {format(new Date(message.scheduledFor), 'dd MMM yyyy, HH:mm')}
                                </span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">-</span>
                            )}
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center space-x-2">
                              {message.deliveryStats && (
                                <>
                                  <span className="text-green-500">{message.deliveryStats.delivered}</span>
                                  <span className="text-zinc-500">/</span>
                                  <span className="text-red-500">{message.deliveryStats.failed}</span>
                                  <span className="text-zinc-500">/</span>
                                  <span className="text-blue-500">{message.deliveryStats.pending}</span>
                                </>
                              )}
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => handleViewStats(message._id)}
                                className="text-zinc-400 hover:text-zinc-300 p-1 h-6"
                              >
                                <Eye size={14} />
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex space-x-1">
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => { setDeletingMessageId(message._id); setIsDeleteDialogOpen(true); }}
                                className="text-red-400 hover:text-red-300 p-1 h-6"
                                title="Delete"
                              >
                                <Trash2 size={14} />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div className="text-center py-8 text-zinc-400">
                  <p>No sending messages found</p>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="completed" className="mt-0">
              {isLoading ? (
                <div className="text-center py-8">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-zinc-400 border-t-[#e0ff89]"></div>
                  <p className="mt-2 text-zinc-400">Loading messages...</p>
                </div>
              ) : filteredMessages.length > 0 ? (
                <div className="border border-zinc-800 rounded-md overflow-hidden">
                  <Table>
                    <TableHeader className="bg-zinc-900">
                      <TableRow>
                        <TableHead className="text-zinc-400 font-medium">Status</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Content</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Media</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Created</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Scheduled For</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Statistics</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredMessages.map((message) => (
                        <TableRow key={message._id} className="border-b border-zinc-800 hover:bg-zinc-900">
                          <TableCell>
                            <StatusBadge status={message.status} />
                          </TableCell>
                          <TableCell className="max-w-[200px] truncate">
                            <span className="text-zinc-300">{message.content.text}</span>
                          </TableCell>
                          <TableCell>
                            {message.content.mediaType !== 'none' ? (
                              <div className="flex items-center space-x-1">
                                {message.content.mediaType === 'photo' ? (
                                  <Image size={16} className="text-blue-400" />
                                ) : (
                                  <FileVideo size={16} className="text-purple-400" />
                                )}
                                <span className="text-zinc-300 capitalize">{message.content.mediaType}</span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">No media</span>
                            )}
                          </TableCell>
                          <TableCell className="text-zinc-300">
                            {message.createdAt && format(new Date(message.createdAt), 'dd MMM yyyy, HH:mm')}
                          </TableCell>
                          <TableCell>
                            {message.scheduledFor ? (
                              <div className="flex items-center space-x-1">
                                <Clock size={16} className="text-amber-400" />
                                <span className="text-zinc-300">
                                  {format(new Date(message.scheduledFor), 'dd MMM yyyy, HH:mm')}
                                </span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">-</span>
                            )}
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center space-x-2">
                              {message.deliveryStats && (
                                <>
                                  <span className="text-green-500">{message.deliveryStats.delivered}</span>
                                  <span className="text-zinc-500">/</span>
                                  <span className="text-red-500">{message.deliveryStats.failed}</span>
                                </>
                              )}
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => handleViewStats(message._id)}
                                className="text-zinc-400 hover:text-zinc-300 p-1 h-6"
                              >
                                <Eye size={14} />
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex space-x-1">
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => { setDeletingMessageId(message._id); setIsDeleteDialogOpen(true); }}
                                className="text-red-400 hover:text-red-300 p-1 h-6"
                                title="Delete"
                              >
                                <Trash2 size={14} />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div className="text-center py-8 text-zinc-400">
                  <p>No completed messages found</p>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="failed" className="mt-0">
              {isLoading ? (
                <div className="text-center py-8">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-zinc-400 border-t-[#e0ff89]"></div>
                  <p className="mt-2 text-zinc-400">Loading messages...</p>
                </div>
              ) : filteredMessages.length > 0 ? (
                <div className="border border-zinc-800 rounded-md overflow-hidden">
                  <Table>
                    <TableHeader className="bg-zinc-900">
                      <TableRow>
                        <TableHead className="text-zinc-400 font-medium">Status</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Content</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Media</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Created</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Scheduled For</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Statistics</TableHead>
                        <TableHead className="text-zinc-400 font-medium">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredMessages.map((message) => (
                        <TableRow key={message._id} className="border-b border-zinc-800 hover:bg-zinc-900">
                          <TableCell>
                            <StatusBadge status={message.status} />
                          </TableCell>
                          <TableCell className="max-w-[200px] truncate">
                            <span className="text-zinc-300">{message.content.text}</span>
                          </TableCell>
                          <TableCell>
                            {message.content.mediaType !== 'none' ? (
                              <div className="flex items-center space-x-1">
                                {message.content.mediaType === 'photo' ? (
                                  <Image size={16} className="text-blue-400" />
                                ) : (
                                  <FileVideo size={16} className="text-purple-400" />
                                )}
                                <span className="text-zinc-300 capitalize">{message.content.mediaType}</span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">No media</span>
                            )}
                          </TableCell>
                          <TableCell className="text-zinc-300">
                            {message.createdAt && format(new Date(message.createdAt), 'dd MMM yyyy, HH:mm')}
                          </TableCell>
                          <TableCell>
                            {message.scheduledFor ? (
                              <div className="flex items-center space-x-1">
                                <Clock size={16} className="text-amber-400" />
                                <span className="text-zinc-300">
                                  {format(new Date(message.scheduledFor), 'dd MMM yyyy, HH:mm')}
                                </span>
                              </div>
                            ) : (
                              <span className="text-zinc-500">-</span>
                            )}
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center space-x-2">
                              {message.deliveryStats && (
                                <>
                                  <span className="text-green-500">{message.deliveryStats.delivered}</span>
                                  <span className="text-zinc-500">/</span>
                                  <span className="text-red-500">{message.deliveryStats.failed}</span>
                                </>
                              )}
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => handleViewStats(message._id)}
                                className="text-zinc-400 hover:text-zinc-300 p-1 h-6"
                              >
                                <Eye size={14} />
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex space-x-1">
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => { setDeletingMessageId(message._id); setIsDeleteDialogOpen(true); }}
                                className="text-red-400 hover:text-red-300 p-1 h-6"
                                title="Delete"
                              >
                                <Trash2 size={14} />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div className="text-center py-8 text-zinc-400">
                  <p>No failed messages found</p>
                </div>
              )}
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
      
      {/* Диалог создания/редактирования сообщения */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-3xl bg-zinc-950 border border-zinc-800 text-white max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold">
              {editingMessage ? 'Edit Message' : 'Create New Message'}
            </DialogTitle>
          </DialogHeader>
          <MessageForm
            message={editingMessage}
            onSave={handleSaveMessage}
            onCancel={() => setIsDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
      
      {/* Диалог подтверждения удаления */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="bg-zinc-950 border border-zinc-800 text-white">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold">Delete Message</DialogTitle>
          </DialogHeader>
          <p className="py-4">Are you sure you want to delete this message? This action cannot be undone.</p>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsDeleteDialogOpen(false)}
              className="bg-zinc-900 text-white hover:bg-zinc-800 rounded-[30px] py-1 px-4 border-2 border-zinc-700"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteMessage}
              className="bg-red-600 text-white hover:bg-red-700 rounded-[30px] py-1 px-4"
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      
      {/* Диалог статистики отправки */}
      <Dialog open={isStatsDialogOpen} onOpenChange={setIsStatsDialogOpen}>
        <DialogContent className="max-w-2xl bg-zinc-950 border border-zinc-800 text-white">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold">Message Delivery Status</DialogTitle>
          </DialogHeader>
          {currentMessage && (
            <div className="space-y-4">
              <div className="bg-zinc-900 p-4 rounded-lg border border-zinc-800">
                <div className="flex justify-between items-start mb-2">
                  <StatusBadge status={currentMessage.status} />
                  <div className="text-sm text-zinc-400">
                    {currentMessage.scheduledFor ? (
                      <div className="flex items-center">
                        <Calendar size={14} className="mr-1" />
                        {format(new Date(currentMessage.scheduledFor), 'dd MMM yyyy, HH:mm')}
                      </div>
                    ) : currentMessage.createdAt ? (
                      <div className="flex items-center">
                        <Clock size={14} className="mr-1" />
                        {format(new Date(currentMessage.createdAt), 'dd MMM yyyy, HH:mm')}
                      </div>
                    ) : null}
                  </div>
                </div>
                <p className="text-zinc-300 text-sm whitespace-pre-wrap">
                  {currentMessage.content.text}
                </p>
                {currentMessage.content.mediaType !== 'none' && (
                  <div className="mt-2 flex items-center text-zinc-400 text-sm">
                    {currentMessage.content.mediaType === 'photo' ? (
                      <Image size={14} className="mr-1" />
                    ) : (
                      <FileVideo size={14} className="mr-1" />
                    )}
                    <span className="capitalize">{currentMessage.content.mediaType} attached</span>
                  </div>
                )}
              </div>
              
              <DeliveryStats message={currentMessage} onRefresh={handleRefreshStats} />
            </div>
          )}
          <DialogFooter>
            <Button
              onClick={() => {
                setIsStatsDialogOpen(false);
                // При закрытии диалога статистики очищаем интервал обновления
                if (statsRefreshInterval) {
                  clearInterval(statsRefreshInterval);
                  setStatsRefreshInterval(null);
                }
              }}
              className="bg-zinc-800 text-white hover:bg-zinc-700 rounded-[30px] py-1 px-4"
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TelegramMessaging;