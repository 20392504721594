import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import toast from './components/CustomToast';
import store from './redux/store';
import BrandBattle from './BrandBattle';
import AdminLogin from './components/admin/AdminLogin';
import AdminPanel from './components/admin/AdminPanel';
import './styles/globals.css';
import Auth from './components/Auth';
import telegramService from './services/telegramService';
import LoadingScreen from './components/LoadingScreen';
import DailyRewards from './components/DailyRewards';
import { dailyRewardsService } from './services/dailyRewardsService';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Landing from './components/Landing';
import InitialProfileSetup from './components/InitialProfileSetup';
import BlockedUserScreen from './components/BlockedUserScreen';

// Обновленный компонент для проверки текущего пути и отображения LoadingScreen
const LoadingWrapper = React.memo(({ appReady }) => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  const [isRemoved, setIsRemoved] = useState(false);

  // Определяем, нужно ли показывать LoadingScreen
  const shouldShowLoading = !appReady && (location.pathname === '/account' || location.pathname === '/');

  useEffect(() => {
    if (!shouldShowLoading && isVisible) {
      // Начинаем плавное исчезновение
      setTimeout(() => {
        setIsVisible(false);

        // После завершения анимации удаляем компонент из DOM
        setTimeout(() => {
          setIsRemoved(true);
        }, 500); // Время должно соответствовать transition в LoadingScreen
      }, 300); // Задержка перед началом исчезновения
    }
  }, [shouldShowLoading, isVisible]);

  // Если компонент отмечен как удаленный, не рендерим его вообще
  if (isRemoved && !shouldShowLoading) {
    return null;
  }

  // Контролируем видимость через состояние isVisible
  return <LoadingScreen fadeOut={!isVisible} />;
});

// Безопасный парсинг JSON
const safeJsonParse = (data, fallback = null) => {
  if (!data || data === "undefined" || data === undefined) return fallback;
  try {
    // Проверяем, не является ли data уже объектом
    if (typeof data === 'object') return data;
    return JSON.parse(data);
  } catch (e) {
    console.warn('JSON parsing error:', e);
    return fallback;
  }
};

// Безопасное сохранение в localStorage
const safeLocalStorageSave = (key, data) => {
  if (!key) return false;

  try {
    if (data === undefined || data === null) {
      localStorage.removeItem(key);
      return false;
    }

    const dataStr = typeof data === 'string' ? data : JSON.stringify(data);
    localStorage.setItem(key, dataStr);
    return true;
  } catch (e) {
    console.warn('LocalStorage save error:', e);
    return false;
  }
};

// Защищенный маршрут для админ-панели
const ProtectedRoute = React.memo(({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/admin/login" replace />;
  }
  return children;
});

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [appReady, setAppReady] = useState(false);
  const [showDailyReward, setShowDailyReward] = useState(false);
  const [dailyRewardStatus, setDailyRewardStatus] = useState(null);
  const [authAttempted, setAuthAttempted] = useState(false);

  // Новое состояние для показа окна начальной настройки профиля
  const [showInitialProfileSetup, setShowInitialProfileSetup] = useState(false);

  // Состояние для отслеживания порядка показа
  const [storyShown, setStoryShown] = useState(false);
  const [profileSetupShown, setProfileSetupShown] = useState(false);

  // Состояние для отслеживания блокировки пользователя
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [blockInfo, setBlockInfo] = useState({
    blockReason: '',
    blockedAt: null
  });

  // Предотвращаем множественные одновременные запросы
  window._initAppInProgress = window._initAppInProgress || false;
  window._claimRewardInProgress = window._claimRewardInProgress || false;
  window._doubleRewardInProgress = window._doubleRewardInProgress || false;
  window._authInProgress = window._authInProgress || false;
  window._referralProcessed = window._referralProcessed || false;

  // Мемоизированная функция проверки ежедневных наград
  const checkDailyRewards = useMemo(() => {
    return async (userData) => {
      // Более детальная проверка данных пользователя
      if (!userData) {
        console.warn('Невозможно проверить награды: пользователь не определен');
        return;
      }

      if (!userData._id) {
        console.warn('Невозможно проверить награды: отсутствует ID пользователя', userData);
        return;
      }

      // Не показываем награды заблокированным пользователям
      if (userData.isBlocked) {
        console.warn('Невозможно проверить награды: пользователь заблокирован');
        return;
      }

      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.warn('Невозможно проверить награды: отсутствует токен');
          return;
        }

        console.log('Проверка наград для пользователя:', userData._id);
        // Добавляем дебаунс, чтобы избежать частых запросов
        if (window._dailyRewardCheckTimeout) {
          clearTimeout(window._dailyRewardCheckTimeout);
        }

        window._dailyRewardCheckTimeout = setTimeout(async () => {
          try {
            const response = await axios.get('/api/daily-rewards/status', {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });

            console.log('Ответ от сервера о наградах:', response.data);

            if (response.data && response.data.canClaim) {
              console.log('Награды доступны для получения:', response.data);
              setDailyRewardStatus(response.data);

              // Если профиль уже настроен, сразу показываем награду
              console.log('Профиль настроен, показываем награду');
              setShowDailyReward(true);
            } else {
              console.log('Награды недоступны или уже получены');
            }
          } catch (error) {
            console.warn('Ошибка при проверке наград:', error);
          }
        }, 1000);
      } catch (error) {
        console.warn('Ошибка при настройке проверки наград:', error);
      }
    };
  }, []);

  // Функция для проверки необходимости показа окна настройки профиля
  const checkInitialProfileSetup = (userData) => {
    if (!userData) return false;

    // Если у пользователя не выбран пол или возрастной диапазон, показываем окно
    return (!userData.gender || !userData.ageRange);
  };

  // Мемоизированная функция для обновления пользователя
  const updateUserData = useMemo(() => {
    return (updatedUser) => {
      if (!updatedUser) {
        console.warn('Попытка обновить пользователя с пустыми данными');
        return;
      }

      try {
        // Проверяем, что updatedUser - объект
        if (typeof updatedUser !== 'object') {
          console.warn('Неверный формат данных пользователя:', typeof updatedUser);
          return;
        }

        // Создаем копию объекта для безопасности
        const userCopy = { ...updatedUser };

        // Предотвращаем обновление, если данные не изменились
        if (user && JSON.stringify(user) === JSON.stringify(userCopy)) {
          return;
        }

        // Проверяем, заблокирован ли пользователь
        if (userCopy.isBlocked) {
          setIsUserBlocked(true);
          setBlockInfo({
            blockReason: userCopy.blockReason || 'Violation of terms of service',
            blockedAt: userCopy.blockedAt || new Date()
          });
        } else {
          setIsUserBlocked(false);
        }

        setUser(userCopy);
        safeLocalStorageSave('user', userCopy);

        // Уведомить другие компоненты об изменении баланса
        if (userCopy.coins !== undefined) {
          window.dispatchEvent(new CustomEvent('balanceUpdate', {
            detail: { coins: userCopy.coins }
          }));
        }

        // Проверяем необходимость показа окна настройки профиля
        // Не показываем настройку профиля для заблокированных пользователей
        if (!userCopy.isBlocked && checkInitialProfileSetup(userCopy) && !profileSetupShown) {
          // Откладываем показ, чтобы дождаться загрузки историй
          setTimeout(() => {
            if (storyShown) {
              setShowInitialProfileSetup(true);
            }
          }, 500);
        }
      } catch (error) {
        console.warn('Ошибка при обновлении данных пользователя:', error);
      }
    };
  }, [user, profileSetupShown, storyShown]);

  // Обработчик завершения настройки профиля
  const handleProfileSetupComplete = () => {
    console.log('Настройка профиля завершена, закрываем окно');
    setShowInitialProfileSetup(false);
    setProfileSetupShown(true);

    // Получаем актуальные данные пользователя из localStorage
    try {
      const updatedUser = JSON.parse(localStorage.getItem('user'));
      if (updatedUser && updatedUser._id) {
        console.log('Проверка наград после настройки профиля с актуальными данными пользователя');
        // Используем короткую задержку для обеспечения обновления UI
        setTimeout(() => {
          checkDailyRewards(updatedUser);
        }, 800);
      } else {
        console.warn('Нет актуальных данных пользователя в localStorage');
      }
    } catch (e) {
      console.error('Ошибка при получении пользователя из localStorage:', e);
    }
  };

  // Мемоизированная функция для обработки получения награды
  const handleClaimReward = useMemo(() => {
    return async () => {
      if (window._claimRewardInProgress) return 0;

      window._claimRewardInProgress = true;
      try {
        const result = await dailyRewardsService.claimDailyReward();
        window._claimRewardInProgress = false;

        if (result) {
          setDailyRewardStatus(prevStatus => ({
            ...prevStatus,
            streakDays: result.streakDays,
            level: result.level,
            canClaim: false
          }));

          if (user) {
            const updatedUser = {
              ...user,
              coins: result.totalCoins
            };

            updateUserData(updatedUser);
          }

          return result.reward;
        }
        return 0;
      } catch (error) {
        window._claimRewardInProgress = false;
        console.warn('Error claiming reward:', error);
        return 0;
      }
    };
  }, [user, updateUserData]);

  // Мемоизированная функция для удвоения награды
  const handleDoubleReward = useMemo(() => {
    return async (doubleReward) => {
      if (window._doubleRewardInProgress) return 0;

      window._doubleRewardInProgress = true;
      try {
        const claimResult = await dailyRewardsService.claimDailyReward();

        if (!claimResult) {
          window._doubleRewardInProgress = false;
          return 0;
        }

        const additionalReward = claimResult.reward;
        const doubleResult = await dailyRewardsService.doubleDailyReward(additionalReward);
        window._doubleRewardInProgress = false;

        if (doubleResult && doubleResult.success) {
          const updatedUser = {
            ...user,
            coins: doubleResult.totalCoins
          };

          updateUserData(updatedUser);

          setDailyRewardStatus(prevStatus => ({
            ...prevStatus,
            streakDays: claimResult.streakDays,
            level: claimResult.level,
            canClaim: false
          }));

          return doubleReward;
        }
        return 0;
      } catch (error) {
        window._doubleRewardInProgress = false;
        console.warn('Error doubling reward:', error);
        return 0;
      }
    };
  }, [user, updateUserData]);

  // Инициализация приложения
  useEffect(() => {
    // Предотвращаем повторную инициализацию приложения
    if (authAttempted || window._initAppInProgress) return;

    window._initAppInProgress = true;

    const initApp = async () => {
      try {
        setAuthAttempted(true);

        // Проверяем сначала админский маршрут
        if (window.location.pathname.startsWith('/admin')) {
          // Безопасное получение пользователя с проверкой
          const savedUser = safeJsonParse(localStorage.getItem('user'));
          const savedToken = localStorage.getItem('token');

          if (savedUser && savedToken) {
            setUser(savedUser);
            axios.defaults.headers.common['Authorization'] = `Bearer ${savedToken}`;
          }

          setLoading(false);
          setAppReady(true);
          window._initAppInProgress = false;
          return;
        }

        // Приоритизируем параметры URL
        const urlParams = new URLSearchParams(window.location.search);
        let startParam = urlParams.get('start');

        // Проверяем, есть ли Telegram WebApp и загружаем его при необходимости
        let telegramInitSuccess = false;
        try {
          // Проверяем наличие Telegram в window
          if (!window.Telegram || !window.Telegram.WebApp) {
            console.warn('Telegram WebApp не обнаружен, пробуем инициализировать');

            // Проверяем, загружен ли скрипт
            const scriptExists = document.querySelector('script[src*="telegram-web-app.js"]');
            if (!scriptExists) {
              const script = document.createElement('script');
              script.src = 'https://telegram.org/js/telegram-web-app.js';
              script.async = true;
              document.head.appendChild(script);

              // Ждем загрузку скрипта
              await new Promise(resolve => {
                script.onload = () => resolve(true);
                script.onerror = () => resolve(false);
                setTimeout(() => resolve(false), 3000); // Таймаут 3 секунды
              });
            }
          }

          // Пытаемся инициализировать Telegram
          telegramInitSuccess = await telegramService.init();
        } catch (err) {
          console.warn('Telegram init error:', err);
          telegramInitSuccess = false;
        }

        // Получаем данные Telegram только если инициализация прошла успешно
        const initData = telegramInitSuccess ? telegramService.getInitData() : null;

        // Проверяем, не является ли startParam строковым литералом
        if (startParam === '${startParam}') {
          startParam = null;
          console.log('Ignoring literal startParam string');
        }

        // Если startParam не установлен из URL, пытаемся получить из Telegram
        if (!startParam) {
          const tgStartParam = telegramInitSuccess ? telegramService.getStartParam() : null;
          if (tgStartParam && tgStartParam !== '${startParam}') {
            startParam = tgStartParam;
            console.log('Using startParam from Telegram:', startParam);
          }
        }

        // Если startParam всё еще не установлен или невалидный, проверяем localStorage
        if (!startParam || startParam === '${startParam}') {
          const localStartParam = localStorage.getItem('start_param');
          if (localStartParam && localStartParam !== '${startParam}') {
            startParam = localStartParam;
            console.log('Using startParam from localStorage:', startParam);
          } else {
            // Проверяем альтернативный ключ в localStorage
            const referralCode = localStorage.getItem('referralCode');
            if (referralCode && referralCode !== '${startParam}') {
              startParam = referralCode;
              console.log('Using referralCode from localStorage:', startParam);
            }
          }
        }

        // Если startParam валидный, сохраняем его для будущего использования
        if (startParam && startParam !== '${startParam}') {
          localStorage.setItem('start_param', startParam);
          localStorage.setItem('referralCode', startParam);
        }

        console.log('Final start parameter:', startParam);

        // Безопасно проверяем сохраненные данные пользователя
        const savedUser = safeJsonParse(localStorage.getItem('user'));
        const savedToken = localStorage.getItem('token');

        if (savedUser && savedToken) {
          setUser(savedUser);
          axios.defaults.headers.common['Authorization'] = `Bearer ${savedToken}`;

          // Отметим, что истории были показаны
          // В реальном коде вам нужно определить, показаны ли истории и вызвать setStoryShown(true)
          // Для упрощения, мы устанавливаем это через setTimeout
          setTimeout(() => {
            setStoryShown(true);

            // Проверяем, нужно ли показать окно настройки профиля
            if (checkInitialProfileSetup(savedUser) && !profileSetupShown) {
              setShowInitialProfileSetup(true);
            } else {
              setProfileSetupShown(true);
              // Используем setTimeout для задержки проверки наград
              setTimeout(() => {
                checkDailyRewards(savedUser);
              }, 1000);
            }
          }, 1000);

          setLoading(false);
          setAppReady(true);
          window._initAppInProgress = false;
          return;
        }

        // Проверка наличия данных Telegram
        if (!initData) {
          if (window.location.pathname === '/account') {
            window.location.href = 'https://t.me/BattleBrandsBot';
            window._initAppInProgress = false;
            return;
          }
          setLoading(false);
          setAppReady(true);
          window._initAppInProgress = false;
          return;
        }

        // Обработка реферальной программы с лимитом на повторные запросы
        if (startParam && startParam !== '${startParam}' && !window._referralProcessed) {
          window._referralProcessed = true;
          try {
            await axios.post('/api/users/process-referral', {
              startParam: startParam,
              initData: initData
            });
          } catch (error) {
            console.warn('Error processing referral:', error);
          }
        }

        // Аутентификация пользователя - с предотвращением повторных запросов
        const userData = telegramService.getUserInfo();

        if (!window._authInProgress && userData) {
          window._authInProgress = true;
          try {
            const response = await axios.post('/api/users/auth', {
              start_param: (startParam && startParam !== '${startParam}') ? startParam : '',
              userData: userData
            }, {
              headers: {
                'X-Telegram-Init-Data': initData || '',
                'X-Start-Param': (startParam && startParam !== '${startParam}') ? startParam : ''
              }
            });

            window._authInProgress = false;

            if (response.data && response.data.user) {
              const newUser = response.data.user;
              const token = response.data.token;

              // Проверяем данные пользователя перед сохранением
              if (newUser && typeof newUser === 'object') {
                // Проверяем, заблокирован ли пользователь
                if (newUser.isBlocked) {
                  setIsUserBlocked(true);
                  setBlockInfo({
                    blockReason: newUser.blockReason || 'Violation of terms of service',
                    blockedAt: newUser.blockedAt || new Date()
                  });
                }

                setUser(newUser);
                safeLocalStorageSave('user', newUser);

                if (token) {
                  localStorage.setItem('token', token);
                  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                }

                // Отметим, что истории были показаны
                // В реальном коде вам нужно определить, показаны ли истории и вызвать setStoryShown(true)
                // Для упрощения, мы устанавливаем это через setTimeout
                setTimeout(() => {
                  setStoryShown(true);

                  // Проверяем, нужно ли показать окно настройки профиля
                  // Не показываем настройку профиля для заблокированных пользователей
                  if (!newUser.isBlocked && checkInitialProfileSetup(newUser) && !profileSetupShown) {
                    setShowInitialProfileSetup(true);
                  } else {
                    setProfileSetupShown(true);
                    // Отложенная проверка наград только для незаблокированных пользователей
                    if (!newUser.isBlocked) {
                      setTimeout(() => {
                        checkDailyRewards(newUser);
                      }, 1000);
                    }
                  }
                }, 1000);
              }
            }
          } catch (err) {
            window._authInProgress = false;
            console.warn('Authentication failed:', err);

            // Пробуем использовать данные из localStorage, если аутентификация не удалась
            const fallbackUser = safeJsonParse(localStorage.getItem('user'));
            if (fallbackUser) {
              setUser(fallbackUser);

              // Отметим, что истории были показаны
              setTimeout(() => {
                setStoryShown(true);

                // Проверяем, нужно ли показать окно настройки профиля
                if (checkInitialProfileSetup(fallbackUser) && !profileSetupShown) {
                  setShowInitialProfileSetup(true);
                } else {
                  setProfileSetupShown(true);
                  setTimeout(() => {
                    checkDailyRewards(fallbackUser);
                  }, 1000);
                }
              }, 1000);
            }
          }
        } else if (!userData) {
          window._authInProgress = false;
          console.warn('Не удалось получить данные пользователя из Telegram');
        }
      } catch (error) {
        console.warn('Error during app initialization:', error);
      } finally {
        setLoading(false);
        setAppReady(true);
        window._initAppInProgress = false;
      }
    };

    initApp();
  }, [authAttempted, checkDailyRewards, profileSetupShown]); // Зависимости для предотвращения повторных вызовов

  // Слушатель для установки флага storyShown из StoriesContainer
  useEffect(() => {
    const handleStoryClose = () => {
      console.log('Stories closed, setting storyShown to true');
      setStoryShown(true);

      // После закрытия историй проверяем, нужно ли показать настройку профиля
      if (user && checkInitialProfileSetup(user) && !profileSetupShown) {
        console.log('Показываем окно настройки профиля после закрытия историй');
        setShowInitialProfileSetup(true);
      } else if (user && !checkInitialProfileSetup(user)) {
        // Если профиль уже настроен, проверяем доступность наград
        setProfileSetupShown(true);
        console.log('Профиль уже настроен, проверяем награды');
        setTimeout(() => {
          checkDailyRewards(user);
        }, 500);
      }
    };

    window.addEventListener('storiesClosed', handleStoryClose);

    return () => {
      window.removeEventListener('storiesClosed', handleStoryClose);
    };
  }, [user, profileSetupShown, checkDailyRewards]);

  useEffect(() => {
    const syncUserData = (event) => {
      if (!event.detail) return;

      console.log('App: перехватили событие обновления пользователя', event.detail);

      // Если получено полное обновление пользователя
      if (typeof event.detail === 'object') {
        console.log('App: синхронизация данных пользователя');

        // Проверяем, что event.detail - это объект пользователя с _id
        if (!event.detail._id) {
          console.warn('Получен неполный объект пользователя без _id:', event.detail);
          return;
        }

        // Сохраняем в localStorage для всех компонентов
        try {
          localStorage.setItem('user', JSON.stringify(event.detail));
        } catch (e) {
          console.error('App: ошибка сохранения в localStorage', e);
        }

        // Обновляем состояние пользователя
        setUser(event.detail);
      }
    };

    window.addEventListener('userUpdate', syncUserData);

    return () => {
      window.removeEventListener('userUpdate', syncUserData);
    };
  }, []);

  // Добавьте этот хук useEffect в компонент App
  useEffect(() => {
    // Функция для настройки прогресс-бара
    const setupProgressBars = () => {
      // Находим все активные toast-элементы
      const toastElements = document.querySelectorAll('.toast-with-progress');

      toastElements.forEach(toast => {
        // Проверяем, не обработан ли уже этот toast
        if (toast.getAttribute('data-progress-setup') === 'true') return;

        // Помечаем toast как обработанный
        toast.setAttribute('data-progress-setup', 'true');

        // Получаем данные о toast из атрибутов
        const toastId = toast.closest('[id]')?.id;
        if (!toastId) return;

        // Находим прогресс-бар (псевдоэлемент ::after)
        const progressBar = toast.querySelector('::after');
        if (!progressBar) return;

        // Находим текущее время создания toast (или устанавливаем текущее)
        const now = Date.now();
        toast.setAttribute('data-created', now);

        // Получаем длительность toast из атрибутов react-hot-toast
        // Или используем стандартную длительность
        let duration = 4000; // Стандартная длительность

        // Определяем, когда toast должен исчезнуть
        const removeTime = now + duration;

        // Устанавливаем transition-duration для прогресс-бара
        toast.style.setProperty('--progress-duration', `${duration}ms`);

        // Запускаем анимацию прогресс-бара
        requestAnimationFrame(() => {
          toast.querySelector('::after').style.transform = 'scaleX(0)';
          toast.querySelector('::after').style.transitionDuration = `${duration}ms`;
        });
      });
    };

    // Создаем MutationObserver для отслеживания изменений в DOM
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.addedNodes.length) {
          setupProgressBars();
        }
      });
    });

    // Начинаем наблюдение за DOM
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    // Запускаем первую проверку
    setupProgressBars();

    // Очищаем наблюдателя при размонтировании
    return () => observer.disconnect();
  }, []);

  // Уведомление StoriesContainer о необходимости отправить событие после закрытия
  useEffect(() => {
    if (storyShown && !window.notifiedStoryContainer && window.storiesFinished) {
      window.notifiedStoryContainer = true;

      // Имитируем событие закрытия историй, если они автоматически закрылись
      window.dispatchEvent(new CustomEvent('storiesClosed'));
    }
  }, [storyShown]);

  return (
    <Provider store={store}>
      <TonConnectUIProvider manifestUrl="https://battlebrands.app/tonconnect-manifest.json">
        <Router>
          <div className="App">
            <LoadingWrapper appReady={appReady} />
            <toast.Toaster />

            <Routes>
              {/* Основной маршрут для лендинга */}
              <Route path="/" element={<Landing />} />

              {/* Маршрут для основного приложения */}
              <Route path="/account" element={
                user ? (
                  isUserBlocked ? (
                    <BlockedUserScreen blockReason={blockInfo.blockReason} blockedAt={blockInfo.blockedAt} />
                  ) : (
                    <BrandBattle user={user} setUser={updateUserData} />
                  )
                ) : (
                  <Auth onLogin={updateUserData} />
                )
              } />

              {/* Админ маршруты */}
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin/*" element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              } />

              {/* Редирект на телеграм бота для всех остальных маршрутов */}
              <Route path="*" element={
                <Navigate to="https://t.me/BattleBrandsBot" replace />
              } />
            </Routes>

            {/* Окно первичной настройки профиля */}
            {showInitialProfileSetup && user && (
              <InitialProfileSetup
                user={user}
                onProfileUpdate={updateUserData}
                onComplete={handleProfileSetupComplete}
              />
            )}

            {/* Окно ежедневной награды (не показываем заблокированным пользователям) */}
            {showDailyReward && dailyRewardStatus && !isUserBlocked && (
              <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
                <DailyRewards
                  streakDays={dailyRewardStatus.streakDays}
                  level={dailyRewardStatus.level}
                  onClaimReward={handleClaimReward}
                  onDoubleReward={handleDoubleReward}
                  onClose={() => setShowDailyReward(false)}
                />
              </div>
            )}
          </div>
        </Router>
      </TonConnectUIProvider>
    </Provider>
  );
};

export default App;